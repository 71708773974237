import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { useTraderRoomRoutesConditions } from '../../Traders-Room/TradersRoomRoutes'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useWindowResize } from '../../utils/domUtils'
import { getPath, isNoTabsPath } from '../../utils/path'
import { useProductReadContext } from '../context/ProductContext'
import { Tabs, defineTab } from '../tabs/Tabs'

import styles from './MobileTabs.module.scss'

export const MobileTabs: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const isMobile = useWindowResize()

  const { account } = useAccountReadContext()
  const { t } = useTranslation()
  const { isDefaultCFDProductType } = useProductReadContext()
  const isProductTypeCFD = isDefaultCFDProductType()
  const { isTradersRoomVisible } = useTraderRoomRoutesConditions()
  const [currentTab, setCurrentTab] = useState<string>('')

  const tabs = useMemo(() => {
    function* getTabs() {
      if (isProductTypeCFD && isTradersRoomVisible) {
        yield defineTab('traders-room-wallets', t('Tabs.Wallets'), 'traders-room/wallets')

        yield defineTab(
          'traders-room-trading-accounts',
          t('Tabs.Trading Accounts'),
          'traders-room/trading-accounts'
        )
      }

      if (!isProductTypeCFD && isTradersRoomVisible) {
        yield defineTab('traders-room-balances', t('Tabs.Balances'), 'traders-room/balances')

        yield defineTab(
          'subscriptions-market-data',
          t('Tabs.Subscriptions'),
          'subscriptions/market-data'
        )
      }
    }

    return [...getTabs()]
  }, [isProductTypeCFD, isTradersRoomVisible, t])

  useEffect(() => {
    const path = getPath(location.pathname) ?? {}
    const currentPath = `${path.sub}/${path.tab}`.replace(/\/$/, '')
    const matchingTab = tabs.find((tab) => tab?.path === currentPath)
    setCurrentTab(matchingTab?.id || '')
  }, [location.pathname, tabs, isProductTypeCFD])

  if (!isMobile) {
    return null
  }

  if (
    isMobile &&
    ['/sign-up', '/register2/:leadId'].some((path) => matchPath({ path }, location.pathname))
  ) {
    return null
  }

  if (
    !account ||
    tabs.length < 2 ||
    isNoTabsPath(location.pathname, [
      '/introducing-broker',
      '/statements',
      '/users/register',
      '/profile',
      '/payment-agent',
      '/trading-accounts/new',
      '/trading-accounts/new/demo',
      '/dashboard/closed-account',
      '/dashboard/inactive-account',
      '/dashboard/legal-documents',
      '/dashboard/take-app-test',
      '/dashboard/product-registration/appropriateness-test',
      '/dashboard/first-deposit',
      '/first-time-deposit/loading',
    ])
  ) {
    return <div className={styles.noTabs} />
  }

  return (
    <div id={ScrollToIds.MobileTabs}>
      <Tabs
        wrapperClassName={styles.tabsWrapper}
        tabs={tabs}
        currentTab={currentTab}
        onTabChanged={(tab) => {
          const path = tabs.find((x) => x?.id === tab)
          if (path) {
            return navigate(`/dashboard/${path.path}`)
          }
          return navigate(`/dashboard/${tab}`)
        }}
      />
    </div>
  )
}
