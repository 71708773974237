import { useCallback } from 'react'

import { useProductReadContext } from '../global/context/ProductContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { TestAnswersDto } from '../model/ClientTestAnswerDto'
import { TestSectionsDto } from '../model/TestSectionsDto'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { useFetchOne } from '../utils/useFetch'

export interface AccountAppTestResponseProps {
  accountAppTest: TestSectionsDto | undefined
  accountAppTestAnswers: TestAnswersDto | undefined
  isLoading: boolean
}

export const useAccountAppTestFetch = (): AccountAppTestResponseProps => {
  const apiClient = useApiClient(ClientApiClient)

  const { product } = useProductReadContext()
  const locale = useSessionLanguage()

  const callback = useCallback(async () => {
    const accountAppTestResult = await apiClient.getAccountAppTest(locale)
    try {
      const accountAppTestAnswersResult: TestAnswersDto = await apiClient.getAccountAppTestAnswers(
        locale,
        product
      )
      return {
        accountAppTest: accountAppTestResult,
        accountAppTestAnswers: accountAppTestAnswersResult,
      }
    } catch (error: unknown) {
      return {
        accountAppTest: accountAppTestResult,
        accountAppTestAnswers: undefined,
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data, isLoading } = useFetchOne(callback)
  const { accountAppTest, accountAppTestAnswers } = data || {}

  return { accountAppTest, accountAppTestAnswers, isLoading }
}
