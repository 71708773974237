import React from 'react'
import classNames from 'classnames'

import styles from './Icons.module.scss'

export const RewardsIcon = ({ className }: { className?: string }) => (
  <svg
    width='276'
    className={classNames(className)}
    height='195'
    viewBox='0 0 276 195'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='25.8937'
      cy='101.816'
      r='21.5783'
      fill='white'
      stroke={styles.text}
      strokeWidth='2.5'
    />
    <path
      d='M32.3675 93.8709C32.3675 93.8709 31.3605 89.9485 27.3326 89.9476C23.2712 89.9466 21.2906 91.9092 21.2906 95.8325V100.737M32.3675 111.526H19.6456C19.5111 111.526 19.4378 111.356 19.5248 111.253C20.0633 110.618 21.2906 108.919 21.2906 106.622V100.737M21.2906 100.737H28.3396M21.2906 100.737H17.2627'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <circle
      cx='64.4313'
      cy='101.815'
      r='21.5783'
      fill='white'
      stroke={styles.text}
      strokeWidth='2.5'
    />
    <path
      d='M70.802 95.4401C70.1236 93.0447 66.918 91.5961 64.4302 91.615M64.4302 91.615C61.2444 91.6392 57.2649 94.9029 58.061 97.9902C59.3775 103.095 69.4858 100.536 70.802 105.641C71.5981 108.728 67.6161 111.992 64.4302 112.016M64.4302 91.615L64.3284 87.7896M64.4302 112.016C61.9425 112.035 58.7395 110.586 58.061 108.191M64.4302 112.016V115.841'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <circle
      cx='103.373'
      cy='101.815'
      r='21.5783'
      fill='white'
      stroke={styles.text}
      strokeWidth='2.5'
    />
    <path
      d='M110.926 94.2631C109.432 92.7693 107.529 91.7521 105.457 91.34C103.385 90.9279 101.238 91.1394 99.286 91.9478C97.3344 92.7562 95.6663 94.1252 94.4927 95.8816C93.3191 97.638 92.6927 99.703 92.6927 101.815C92.6927 103.928 93.3191 105.993 94.4927 107.749C95.6663 109.506 97.3344 110.875 99.286 111.683C101.238 112.492 103.385 112.703 105.457 112.291C107.529 111.879 109.432 110.862 110.926 109.368'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M89.2666 104.664H104.656'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M89.2666 98.2517H104.656'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M146.53 101.816H206.95M206.95 101.816L191.845 86.7108M206.95 101.816L191.845 116.92'
      stroke={styles.primary}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M237.159 91.0265H261.526C265.945 91.0265 269.526 94.6082 269.526 99.0265V111.078C269.526 115.497 265.945 119.078 261.526 119.078H238.686C234.267 119.078 230.686 115.497 230.686 111.078V92.553C230.686 88.1347 234.267 84.553 238.686 84.553H263.053'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M261.974 100.737V107.21'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M125.861 139.971L180.948 166.543M180.948 166.543L173.381 145.155M180.948 166.543L160.972 174.646'
      stroke={styles.primary}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M125.863 64.8825L180.951 38.3126M180.951 38.3126L160.975 30.2093M180.951 38.3126L173.382 59.7009'
      stroke={styles.primary}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M209.209 15.5025H233.576C237.995 15.5025 241.576 19.0842 241.576 23.5025V35.5543C241.576 39.9725 237.995 43.5543 233.576 43.5543H210.735C206.317 43.5543 202.735 39.9725 202.735 35.5543V17.029C202.735 12.6107 206.317 9.02896 210.735 9.02896H235.103'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M234.024 25.2127V31.6862'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M209.209 157.919H233.576C237.995 157.919 241.576 161.501 241.576 165.919V177.971C241.576 182.389 237.995 185.971 233.576 185.971H210.735C206.317 185.971 202.735 182.389 202.735 177.971V159.446C202.735 155.028 206.317 151.446 210.735 151.446H235.103'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M234.024 167.63V174.103'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
  </svg>
)
