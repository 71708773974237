import React from 'react'

import { WalletDepositProviderNoneFields } from '../../../Wallets/WalletDeposit/WalletDepositForm/WalletDepositProviderNoneFields'

interface TradingAccountDepositProviderNoneFieldsProps {
  onCancel(): void
  onClickTermsConditions(): void
}

export const TradingAccountDepositProviderNoneFields: React.FC<
  TradingAccountDepositProviderNoneFieldsProps
> = (props) => {
  return <WalletDepositProviderNoneFields {...props} />
}
