import React from 'react'
import classNames from 'classnames'

import styles from './CheckboxSwitch.module.scss'

export interface CheckboxSwitchProps {
  name: string
  value: boolean
  disabled?: boolean
  onChange: (value: boolean) => void
  className?: string
  size?: 'S' | 'M'
}

export const CheckboxSwitch: React.FC<CheckboxSwitchProps> = ({
  name,
  value,
  disabled,
  onChange,
  className,
  size = 'S',
}) => {
  return (
    <div
      className={classNames(
        styles.checkbox,
        styles[`size-${size}`],
        {
          [styles.active]: !!value,
          [styles.disabled]: disabled,
        },
        className
      )}
    >
      <input
        type='checkbox'
        id={name}
        className={value ? 'checked' : ''}
        checked={value}
        disabled={disabled}
        onChange={(e) => {
          onChange(e.target.checked)
        }}
      />
      <div className={styles.selector} />
    </div>
  )
}
