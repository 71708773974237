import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { useAccountReadContext } from '../AccountContextContext'

type AccessCallBack = () => void

export const useAccountActionAccessCheck = () => {
  const navigate = useNavigate()
  const { account } = useAccountReadContext()

  const accountActionAccessLocks = getAccountActionAccessLocks(account)

  const checkAccountTradersRoomActionAccess = (callback: AccessCallBack) => {
    checkActionAccess(callback)(accountActionAccessLocks.isAccountTradersRoomActionAccessLocked)
  }

  const checkAccountOpenAccountActionAccess = (callback: AccessCallBack) => {
    checkActionAccess(callback)(accountActionAccessLocks.isAccountOpenAccountActionAccessLocked)
  }

  const checkAccountIBActionAccess = (callback: AccessCallBack) => {
    checkActionAccess(callback)(
      accountActionAccessLocks.isAccountIntroducingBrokerActionAccessLocked
    )
  }

  const checkActionAccess = (callback: AccessCallBack) => (locked: boolean) => {
    locked ? navigate('/profile/document-management') : callback()
  }

  return {
    ...accountActionAccessLocks,
    checkAccountTradersRoomAccess: checkAccountTradersRoomActionAccess,
    checkAccountOpenAccountAccess: checkAccountOpenAccountActionAccess,
    checkAccountIBAccess: checkAccountIBActionAccess,
  }
}

export const getAccountActionAccessLocks = (account: AccountDetailedDto | undefined) => {
  return {
    isAccountTradersRoomActionAccessLocked: isAccountTradersRoomActionAccessLocked(account),
    isAccountOpenAccountActionAccessLocked: isAccountOpenAccountActionAccessLocked(account),
    isAccountIntroducingBrokerActionAccessLocked:
      isAccountIntroducingBrokerActionAccessLocked(account),
  }
}

export const useAccountTradersRoomAccessCheck = () => {
  const { isAccountTradersRoomActionAccessLocked } = useAccountActionAccessCheck()
  return useAccessCheck(isAccountTradersRoomActionAccessLocked)
}

export const isAccountTradersRoomActionAccessLocked = (
  account: AccountDetailedDto | undefined
): boolean => {
  return !!account?.actionRestrictions.tradersRoomRestricted
}

export const useAccountOpenAccountAccessCheck = () => {
  const { isAccountOpenAccountActionAccessLocked } = useAccountActionAccessCheck()
  return useAccessCheck(isAccountOpenAccountActionAccessLocked)
}

export const isAccountOpenAccountActionAccessLocked = (
  account: AccountDetailedDto | undefined
): boolean => {
  return !!account?.actionRestrictions.openAccountRestricted
}

export const useAccountIBAccessCheck = () => {
  const { isAccountTradersRoomActionAccessLocked } = useAccountActionAccessCheck()
  return useAccessCheck(isAccountTradersRoomActionAccessLocked)
}

export const isAccountIntroducingBrokerActionAccessLocked = (
  account: AccountDetailedDto | undefined
): boolean => {
  return !!account?.actionRestrictions.introducingBrokerRestricted
}

const useAccessCheck = (isLocked: boolean) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (isLocked) {
      navigate('/profile/document-management')
    }
  }, [isLocked, navigate])

  return isLocked
}
