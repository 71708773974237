import React from 'react'
import classNames from 'classnames'

export const CheckBoxOn = ({ className }: { className?: string }) => (
  <svg
    width='18'
    height='18'
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={classNames(className)}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M2 0C0.895431 0 0 0.89543 0 2V16C0 17.1046 0.89543 18 2 18H16C17.1046 18 18 17.1046 18 16V2C18 0.895431 17.1046 0 16 0H2ZM14.1 4L15.5 5.4L8.9 12L7.5 13.4L6.1 12L2.5 8.4L3.9 7L7.5 10.6L14.1 4Z'
    />
  </svg>
)
