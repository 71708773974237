import React from 'react'
import { useFormikContext } from 'formik'

import { FormFieldComponentType } from '../../../../global/formField/formFieldsFactory/FormFieldComponentType'
import { FormFieldsFactory } from '../../../../global/formField/formFieldsFactory/FormFieldsFactory'
import { WalletWithdrawFormValues } from './WalletWithdrawForm'

interface WalletFormFieldsFactoryProps {
  disabled?: boolean
}

export const WalletFormFieldsFactory: React.FC<WalletFormFieldsFactoryProps> = (props) => {
  const { disabled = false } = props

  const { values } = useFormikContext<WalletWithdrawFormValues>()

  return (
    <FormFieldsFactory
      fields={values.paymentProvider.parameters.fields.filter(isFormFieldCurrency)}
      disabled={disabled}
    />
  )
}

// TODO: backend by some reason provide "client currency field"
const isFormFieldCurrency = (field: FormFieldComponentType) => {
  return field.name !== 'fields.payment_currency_id'
}
