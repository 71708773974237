import React from 'react'
import classNames from 'classnames'

export const CheckBoxOff = ({ className }: { className?: string }) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    className={classNames(className)}
  >
    <rect x='1' y='1' width='24' height='24' rx='1' strokeWidth='2' />
  </svg>
)
