import { IconProps } from './IconProps'

import styles from './Icons.module.scss'

export const ArrowLeftIcon: React.FC<IconProps> = (props) => {
  return (
    <svg width='27' height='15' viewBox='0 0 27 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M25.1667 7.33333H1.83341M1.83341 7.33333L7.66675 1.5M1.83341 7.33333L7.66675 13.1667'
        stroke={props.color ? styles[props.color] : styles.contrastMediumLow}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}
