import { Color } from './index'

import styles from './Icons.module.scss'

export function SupportLiveChatIcon(props: { color?: Color; size?: number }) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.1765 18.2L17.7052 17.668L17.4858 17.45H17.1765V18.2ZM6.5 7.75C6.08579 7.75 5.75 8.08579 5.75 8.5C5.75 8.91421 6.08579 9.25 6.5 9.25V7.75ZM17.5 9.25C17.9142 9.25 18.25 8.91421 18.25 8.5C18.25 8.08579 17.9142 7.75 17.5 7.75V9.25ZM9.5 12.25C9.08579 12.25 8.75 12.5858 8.75 13C8.75 13.4142 9.08579 13.75 9.5 13.75V12.25ZM17.5 13.75C17.9142 13.75 18.25 13.4142 18.25 13C18.25 12.5858 17.9142 12.25 17.5 12.25V13.75ZM20.1475 21.1528L19.6188 21.6847L20.1475 21.1528ZM5.5 4.75H18.5V3.25H5.5V4.75ZM20.25 6.5V20.7981H21.75V6.5H20.25ZM3.75 15.7V6.5H2.25V15.7H3.75ZM20.6762 20.6208L17.7052 17.668L16.6478 18.732L19.6188 21.6847L20.6762 20.6208ZM6.5 9.25H17.5V7.75H6.5V9.25ZM9.5 13.75H17.5V12.25H9.5V13.75ZM17.1765 17.45H5.5V18.95H17.1765V17.45ZM2.25 15.7C2.25 17.4949 3.70507 18.95 5.5 18.95V17.45C4.5335 17.45 3.75 16.6665 3.75 15.7H2.25ZM20.25 20.7981C20.25 20.5758 20.5185 20.4641 20.6762 20.6208L19.6188 21.6847C20.4074 22.4685 21.75 21.9099 21.75 20.7981H20.25ZM18.5 4.75C19.4665 4.75 20.25 5.5335 20.25 6.5H21.75C21.75 4.70507 20.2949 3.25 18.5 3.25V4.75ZM5.5 3.25C3.70508 3.25 2.25 4.70507 2.25 6.5H3.75C3.75 5.5335 4.5335 4.75 5.5 4.75V3.25Z'
        fill={props.color ? styles[props.color] : styles.textSecondary}
      />
    </svg>
  )
}
