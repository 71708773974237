import { NameDto } from './NameDto'

export enum TradingAccountType {
  Pro = 1,
  Classic = 2,
  VIP = 5,
  Raw = 3,
}

export enum PlanValuesKeys {
  Pro = 'Pro',
  Classic = 'Classic',
  VIP = 'VIP',
  Raw = 'Raw',
}

export interface CommissionPlan {
  amount: number
  tradingAccountType: NameDto<TradingAccountType>
}

export interface UpdateCommissionPlan {
  amount: number
  tradingAccountTypeId: TradingAccountType
}

export interface PlanValues {
  [PlanValuesKeys.Classic]?: number
  [PlanValuesKeys.Pro]?: number
  [PlanValuesKeys.VIP]?: number
  [PlanValuesKeys.Raw]?: number
}

export const customOrder: string[] = [
  PlanValuesKeys.Classic,
  PlanValuesKeys.Pro,
  PlanValuesKeys.VIP,
  PlanValuesKeys.Raw,
]

export interface AssignedCommissionPlan {
  id: string
  name: string
  commissionPlans: CommissionPlan[]
  isDefault: boolean
}

export interface SubIBAccount {
  account: NameDto
  commissionPlanGroup: NameDto<string>
  clientName: string
  ibCommission: number
  volume: number
}

export interface SubIBAccountOption {
  id: string
  name: string
  commissionPlans: CommissionPlan[]
  isDefault: boolean
}

export interface IBNetworkPlans {
  introducingBroker: NameDto<string>
  level: number
  commissionPlanGroup: NameDto<string>
  numberOfClients: number
  subAccounts: IBNetworkPlans[]
}
