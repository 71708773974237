import { FC, SyntheticEvent } from 'react'

import IconButton from '../global/iconButton/IconButton'
import { WarningIcon } from '../icons/WarningIcon'

interface RiskWarningProps {
  onClick(event: SyntheticEvent): void
}
export const ProductRiskWarning: FC<RiskWarningProps> = ({ onClick }) => {
  return (
    <IconButton onClick={onClick}>
      <WarningIcon color='warning' />
    </IconButton>
  )
}
