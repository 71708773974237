import { PaymentProviderType } from './PaymentProviderType'
import {
  WalletPaymentProviderDto,
  WalletPaymentProviderMethodResponseDto,
} from './WalletPaymentProviderDto'

export interface WalletPaymentProvider {
  name: string
  id?: string
  description: string
  method: {
    id: string
    name: string
  }
  currency: {
    id: string
  }
  providerCategory: { id: PaymentProviderType }
  regulator: string | undefined
  fundingTime: string
  minAmount: number
  maxAmount: number
  fee: number
  maintenanceMessage: string | undefined
  listingDescription: string | undefined
  logo: string
  method_name: string | undefined
}

export const normalizePaymentProviders = (
  dto: WalletPaymentProviderMethodResponseDto | null | undefined
): WalletPaymentProvider[] => {
  return (dto?.reply || []).map(normalizePaymentProvider)
}

export const normalizePaymentProvider = (dto: WalletPaymentProviderDto): WalletPaymentProvider => {
  return {
    name: dto.name,
    description: dto.description,
    method: {
      id: dto.method_id,
      name: dto.method_name,
    },
    currency: {
      id: dto.ccy,
    },
    providerCategory: { id: Number.parseInt(dto.categoryId, 10) },
    regulator: dto?.regulator || undefined,
    fundingTime: dto.funding_time,
    minAmount: Number.parseFloat(dto.min_amount),
    maxAmount: Number.parseFloat(dto.max_amount),
    fee: Number.parseFloat(dto.fee),
    maintenanceMessage: dto?.maintenance_message || undefined,
    listingDescription: dto?.listing_description || undefined,
    logo: dto.logo_url,
    method_name: dto.method_name,
  }
}
