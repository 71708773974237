export interface CreateLead {
  typeId: string
  genderId: string
  firstName: string
  lastName: string
  nativeName?: string
  middleName: string
  birthDay: string
  email: string
  phoneNumber: string
  countryCode: string
  countryId: string
  languageId: string
  optIn: boolean
  productIds: number[]
  source: string
  campaign: string
  campaignSource: string
  campaignMedium: string
  campaignTerm: string
  campaignContent: string
  referrer: string
  referralCode: string
  tickmillCompanyId: number
  tickmillPublicDomain: string
  affToken?: string
  agreedLegalDocuments: AgreedLegalDocument[]
}

interface AgreedLegalDocument {
  id: string
  openedOn: Date
  agreedOn: Date
}

export enum LeadMembersEnum {
  TYPE_ID = 'typeId',
  GENDER_ID = 'genderId',
  FIRST_NAME = 'firstName',
  NATIVE_NAME = 'nativeName',
  LAST_NAME = 'lastName',
  MIDDLE_NAME = 'middleName',
  EMAIL = 'email',
  BIRTHDAY = 'birthDay',
  COUNTRY_ID = 'countryId',
  COUNTRY_CODE = 'countryCode',
  LANGUAGE_ID = 'languageId',
  PHONE_NUMBER = 'phoneNumber',
  OPT_IN = 'optIn',
  SOURCE = 'source',

  CAMPAIGN = 'campaign',
  CAMPAIGN_SOURCE = 'campaignSource',
  CAMPAIGN_MEDIUM = 'campaignMedium',
  CAMPAIGN_TERM = 'campaignTerm',
  CAMPAIGN_CONTENT = 'campaignContent',
  REFERRER = 'referrer',
  REFERRER_CODE = 'referrerCode',
  TICKMILL_COMPANY_ID = 'tickmillCompanyId',
  TICKMILL_PUBLIC_DOMAIN = 'tickmillPublicDomain',
}
