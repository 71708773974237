import { IconProps, iconColor } from './IconProps'

export function SearchNoContentIcon(props: IconProps): JSX.Element {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      {...props}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='11' cy='11' r='6' stroke={iconColor(props)} strokeWidth='1.25' />
      <path
        d='M19.9697 21.0303C20.2626 21.3232 20.7374 21.3232 21.0303 21.0303C21.3232 20.7374 21.3232 20.2626 21.0303 19.9697L19.9697 21.0303ZM21.0303 19.9697L15.5303 14.4697L14.4697 15.5303L19.9697 21.0303L21.0303 19.9697Z'
        fill={iconColor(props)}
      />
      <path d='M13 13L9 9' stroke={iconColor(props)} strokeWidth='1.25' strokeLinecap='round' />
      <path d='M9 13L13 9' stroke={iconColor(props)} strokeWidth='1.25' strokeLinecap='round' />
    </svg>
  )
}
