import React, { useState } from 'react'
import { t } from 'i18next'

import {
  FilterOptions,
  FilterQueryModal,
  FilterQueryProps,
} from '../../global/filter/FilterQueryModal'
import { Modal } from '../../global/modal/Modal'
import { DateFilterPresets, IDateFilter } from '../../utils/filter.utils'

const datePresets = [
  DateFilterPresets.Today,
  DateFilterPresets.ThisWeek,
  DateFilterPresets.ThisMonth,
  DateFilterPresets.ThisYear,
  DateFilterPresets.All,
  DateFilterPresets.Custom,
]

const filterOptions = [FilterOptions.Date]

interface Props {
  searchFilters?: FilterQueryProps
  onConfirm(currentFilter?: IDateFilter, searchFilters?: FilterQueryProps): void
  onClose(): void
}

export const StockDividendsFilterModal: React.FC<Props> = ({
  searchFilters,
  onConfirm,
  onClose,
}) => {
  const [search, setSearch] = useState<FilterQueryProps | undefined>(searchFilters)

  return (
    <Modal
      closeModal={onClose}
      render={() => (
        <FilterQueryModal
          searchFilters={search}
          setSearchFilters={setSearch}
          onConfirm={({ currentFilter, searchFilters }) => {
            onConfirm(currentFilter, searchFilters)
            onClose()
          }}
          allowFutureDate
          datePresets={datePresets}
          customLabels={{
            [FilterOptions.Date]: t('Period'),
          }}
          filterOptions={filterOptions}
        />
      )}
    />
  )
}
