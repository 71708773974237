import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { useTraderRoomRoutesConditions } from '../Traders-Room/TradersRoomRoutes'
import { BackButton } from '../global/backButton/BackButton'
import { useProductReadContext } from '../global/context/ProductContext'
import { Tabs } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { PageHeader } from '../ui/Table/Header/PageHeader'
import { getAccountAccessStatuses } from '../utils/AccountAccess/accountAccessStatuses'
import { isDocumentManagementNotificationNeeded } from '../utils/AccountAccess/accountDocumentStatuses'
import { isDepositBeforeKYC } from '../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { isNoTabsPath } from '../utils/path'
import { DocumentManagementNotification } from './DocumentManagement/DocumentManagementPage'
import { useProfileTabs } from './ProfileTabs'

import styles from './ProfileRoutes.module.scss'

const useTabsAllowed = () => {
  const location = useLocation()
  const { product } = useProductReadContext()
  const { account } = useAccountReadContext()

  const { isAccountActivatedStatus } = getAccountAccessStatuses(account, product)

  const routes = [
    'personal-info',
    'first-time-deposit',
    'appropriateness-test',
    ...(isAccountActivatedStatus || isDepositBeforeKYC(account) ? ['document-management'] : []),
    'client-classification',
    'market-data-classification',
    'introducing-broker',
    'account-settings',
    'security-settings',
    'bank-accounts',
    'phone-numbers',
    'tax-form',
    'e-tax-form',
  ]

  return routes.some((path) => location.pathname.includes(path))
}

export const Profile: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const { isDefaultCFDProductType } = useProductReadContext()

  const { account } = useAccountReadContext()

  const tabsDefinition = useProfileTabs()
  const currentTabId = useCurrentTab('profile')
  const currentTab = tabsDefinition.find((tab) => tab.id === currentTabId)
  const isTabsAllowed = useTabsAllowed()

  const isProductTypeCFD = isDefaultCFDProductType()
  const { isTradersRoomVisible } = useTraderRoomRoutesConditions()

  const pageHeaderRef = useRef<HTMLDivElement>(null)
  const navigationBarRef = useRef<HTMLDivElement>(null)

  const updateLayoutHeight = useCallback(() => {
    const pageHeaderHeight = pageHeaderRef.current?.clientHeight || 0
    const navigationBarHeight = navigationBarRef.current?.clientHeight || 0

    document.documentElement.style.setProperty('--page-header-height', `${pageHeaderHeight}px`)
    document.documentElement.style.setProperty('--nav-bar-height', `${navigationBarHeight}px`)
  }, [])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateLayoutHeight()
    })

    if (pageHeaderRef.current) {
      resizeObserver.observe(pageHeaderRef.current)
    }
    if (navigationBarRef.current) {
      resizeObserver.observe(navigationBarRef.current)
    }

    return () => {
      if (pageHeaderRef.current) {
        resizeObserver.unobserve(pageHeaderRef.current)
      }
      if (navigationBarRef.current) {
        resizeObserver.unobserve(navigationBarRef.current)
      }
    }
  }, [updateLayoutHeight])

  const getBackUrl = useMemo(() => {
    if (!isProductTypeCFD) {
      return '/dashboard/traders-room/balances'
    }

    return isTradersRoomVisible
      ? '/dashboard/traders-room/wallets'
      : '/dashboard/introducing-broker/wallets'
  }, [isProductTypeCFD, isTradersRoomVisible])

  return (
    <>
      {isDocumentManagementNotificationNeeded(account) && <DocumentManagementNotification />}
      {isTabsAllowed ? (
        <div ref={navigationBarRef}>
          <div className={classNames(styles.backButton)}>
            <BackButton section={t('My Profile')} backUrl={getBackUrl} />
          </div>
          {!isNoTabsPath(location.pathname) && (
            <div className={classNames('section', styles.adminRoutes)}>
              <Tabs
                tabs={tabsDefinition.filter((x) => !x.hidden)}
                currentTab={currentTabId}
                appearance='light'
                onTabChanged={(tab) => navigate(`/profile/${tab}`)}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.emptySpace} />
      )}

      {['personal-info', 'account-settings'].some((path) => location.pathname.includes(path)) &&
        currentTab && (
          <div ref={pageHeaderRef}>
            <PageHeader title={currentTab.name} />
          </div>
        )}
      <Outlet />
    </>
  )
}
