import React from 'react'
import { useFormikContext } from 'formik'

import { FormFieldComponentType } from '../../../../global/formField/formFieldsFactory/FormFieldComponentType'
import { FormFieldsFactory } from '../../../../global/formField/formFieldsFactory/FormFieldsFactory'
import { WalletDepositFormValues } from './WalletDepositForm'

export const WalletFormFieldsFactory: React.FC = () => {
  const { values } = useFormikContext<WalletDepositFormValues>()

  return (
    <FormFieldsFactory
      fields={values.paymentProvider.parameters.fields.filter(isFormFieldCurrency)}
    />
  )
}

// TODO: backend by some reason provide "client currency field"
const isFormFieldCurrency = (field: FormFieldComponentType) => {
  return field.name !== 'fields.payment_currency_id'
}
