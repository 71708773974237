import React from 'react'

import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'
import { Loading } from '../Loading/Loading'

interface CurrencyAmountRateProps {
  fromCurrency: string | undefined
  toCurrency: string | undefined
  amount: number | undefined
}

export const useCurrencyAmountRate = (props: CurrencyAmountRateProps) => {
  const apiClient = useApiClient(ClientApiClient)

  const { fromCurrency, toCurrency, amount = 0 } = props
  const isSameCurrency = fromCurrency === toCurrency

  const { callback } = useCallbackWithForceRefresh(async () => {
    if (fromCurrency && toCurrency && !isSameCurrency) {
      return apiClient.currencyConverterFromTo(fromCurrency, toCurrency)
    }
  }, [isSameCurrency, fromCurrency, toCurrency])
  const { data: value, isLoading } = useFetchOne(callback)

  return {
    amount: (value?.rate || 0) * amount,
    rate: value?.rate,
    currency: value?.baseCurrency,
    isLoading,
  }
}

export const CurrencyAmountRate: React.FC<CurrencyAmountRateProps> = (props) => {
  const { amount, currency, isLoading } = useCurrencyAmountRate(props)

  return (
    <Loading isLoading={isLoading}>
      {amount > 0 && (
        <p>
          {amount} {currency}
        </p>
      )}
    </Loading>
  )
}
