import React from 'react'
import cx from 'classnames'

import { CloseIcon } from '../../icons/CloseIcon'

import styles from './Chip.module.scss'

export type ChipVariantType = 'light' | 'dark'

export interface ChipProps {
  text: string | number
  appearance?: ChipVariantType
  isActive?: boolean
  onDelete?(): void
  onClick?(): void
  disabled?: boolean
}

export const Chip: React.FC<ChipProps> = (props) => {
  const { text, appearance = 'light', isActive, onDelete, onClick, disabled } = props

  return (
    <span
      className={cx(styles?.[appearance], {
        [styles.dark]: isActive,
        [styles.disabled]: disabled,
      })}
      onClick={disabled ? () => {} : onDelete || onClick}
    >
      {text}
      {!!onDelete && (
        <span className={cx('has-cursor-pointer', styles.icon, styles.iconRight)}>
          <CloseIcon size={10} />
        </span>
      )}
    </span>
  )
}

export const ChipLight: React.FC<ChipProps> = (props) => {
  return <Chip {...props} appearance='light' />
}

export const ChipDark: React.FC<ChipProps> = (props) => {
  return <Chip {...props} appearance='dark' />
}
