import React from 'react'
import { useNavigate } from 'react-router-dom'

import { WalletDepositTransactionFailedPage } from './WalletDepositTransactionFailedPage'

export const TradersRoomWalletDepositTransactionFailedPage: React.FC = () => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/dashboard/traders-room/wallets')
  }

  return <WalletDepositTransactionFailedPage onCancel={handleCancel} />
}
