import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMGenericLogo: FC = () => (
  <svg width='160' height='34' viewBox='0 0 160 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M70.7879 21.9512C72.7758 21.9512 74.4243 21.1754 75.6849 19.5754L78.3031 22.2421C76.2182 24.5694 73.794 25.733 71.0303 25.733C68.2182 25.733 65.9394 24.8603 64.097 23.0664C62.3031 21.3209 61.3818 19.0421 61.3818 16.3754C61.3818 13.6603 62.3031 11.43 64.1455 9.63605C65.9879 7.84211 68.2667 6.9209 70.9334 6.9209C73.9394 6.9209 76.4121 8.03605 78.4485 10.3148L75.9273 13.1754C74.6667 11.5754 73.0667 10.7997 71.1273 10.7997C69.6243 10.7997 68.3152 11.2845 67.2 12.3027C66.1334 13.3209 65.5515 14.63 65.5515 16.327C65.5515 18.0239 66.0849 19.3815 67.1031 20.3997C68.1212 21.4179 69.3334 21.9512 70.7879 21.9512Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M45.8181 25.5391V10.8481H50.9575V7.35724H36.606V10.8481H41.7454V25.5391H45.8181Z'
      fill='var(--logo-text-color)'
    />
    <path d='M57.9878 7.35724H53.915V25.5391H57.9878V7.35724Z' fill='var(--logo-text-color)' />
    <path
      d='M85.5757 7.35724H81.5029V25.5391H85.5757V20.3997L87.4181 18.3633L92.7514 25.5391H97.5029C97.0518 24.9022 96.47 24.0911 95.7575 23.0978L95.7517 23.0897C95.1637 22.2699 94.4868 21.3262 93.7211 20.2542C92.2209 18.0922 91.1223 16.5726 90.4617 15.6589L90.2787 15.4057L97.5029 7.35724H92.4605L85.5757 14.8239V7.35724Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M116.266 14.0966L111.37 24.036H108.945L104.048 14.0966V25.5391H99.9756V7.35724H105.454L110.157 17.3451L114.86 7.35724H120.339V25.5391H116.266V14.0966Z'
      fill='var(--logo-text-color)'
    />
    <path d='M128.873 7.35724H124.8V25.5391H128.873V7.35724Z' fill='var(--logo-text-color)' />
    <path
      d='M133.333 25.5391V7.35724H137.406V21.9027H145.163V25.5391H133.333Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M148.121 7.35724V25.5391H159.951V21.9027H152.194V7.35724H148.121Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M7.70909 12.0121C7.41818 12.2061 7.17576 12.6424 7.17576 12.9818H7.27273V18.9939L0 23.1636V9.58788C0 8.71515 0.630303 7.64849 1.35758 7.21212L12.9939 0.327273C13.7212 -0.109091 14.9333 -0.109091 15.6606 0.327273L27.297 7.21212C27.3455 7.21212 27.3939 7.26061 27.4424 7.30909L20.0727 11.5758L14.8364 8.52121C14.497 8.32727 14.0121 8.32727 13.7212 8.52121L7.70909 12.0121Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M21.3336 19.9152V13.9031L28.5093 9.7334V23.3092C28.5093 24.1819 27.879 25.2486 27.1517 25.6849L15.5154 32.5698C14.7881 33.0061 13.576 33.0061 12.8487 32.5698L1.21235 25.6849C1.16387 25.6849 1.11539 25.6364 1.0669 25.588L8.43659 21.3213L13.673 24.3758C14.0124 24.5698 14.4972 24.5698 14.7881 24.3758L20.8002 20.8849C21.0911 20.691 21.3336 20.2546 21.3336 19.9152Z'
      fill='var(--logo-custom-color)'
    />
  </svg>
)
