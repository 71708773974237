import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { detectThemeClientSide } from '../../global/theme/Theme'

import styles from './Popover.module.scss'

const getAnimationVariants = (isArabic: boolean) => ({
  wrapper: {
    open: { backgroundColor: 'rgba(0, 0, 0, 0.24)' },
    closed: { backgroundColor: 'rgba(0, 0, 0, 0.0)' },
  },
  inner: {
    open: { clipPath: 'inset(-20% -20% -20% -20%)' },
    closed: {
      clipPath: isArabic ? 'inset(0% 100% 100% 0%)' : 'inset(0% 0% 100% 100%)',
    },
  },
})

interface PopoverProps {
  className?: string
  children: React.ReactNode
  onClick(): void
  isOpen?: boolean
}

export const Popover: React.FC<PopoverProps> = (props) => {
  const { children, className, onClick, isOpen } = props
  const isArabic = useArabicSessionLanguage()
  const theme = detectThemeClientSide()

  return (
    <motion.div
      onClick={onClick}
      variants={getAnimationVariants(isArabic).wrapper}
      animate={isOpen ? 'open' : 'closed'}
      className={classNames(styles.popover, className, {
        [styles.isOpen]: isOpen,
      })}
    >
      <motion.div
        onClick={(e) => e.stopPropagation()}
        className={classNames(styles.popoverInner, {
          [styles.isOpen]: isOpen,
          [styles.isDark]: theme === 'dark',
        })}
        variants={getAnimationVariants(isArabic).inner}
        transition={{ type: 'spring', bounce: 0, duration: 0.5 }}
        animate={isOpen ? 'open' : 'closed'}
      >
        {children}
      </motion.div>
    </motion.div>
  )
}
