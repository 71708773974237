import { IconProps } from './IconProps'

import styles from './Icons.module.scss'

export function PlaceholderIcon(props: IconProps) {
  const size = props.size || 6

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 6 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='3' cy='3' r='3' fill={props.color ? styles[props.color] : styles.contrastLow} />
    </svg>
  )
}
