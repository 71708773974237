import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'

import { TextSmall } from '../ui/Typography/Typography'

import styles from './DepositFormWarningBox.module.scss'

interface DepositFormWarningBoxProps extends PropsWithChildren {
  className?: string
}

export const DepositFormWarningBox: FC<DepositFormWarningBoxProps> = (props) => {
  const { children, className } = props

  return (
    <TextSmall isParagraph className={classNames(styles.warningMessage, className)}>
      {children}
    </TextSmall>
  )
}
