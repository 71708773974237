import { IconProps, iconColor, strokeIconClassName } from './IconProps'

export function WebIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={strokeIconClassName(props)}
    >
      <path
        d='M10 19C6.08134 19 2.74763 16.4956 1.51212 13M10 19C10 19 7.33333 17 6.35465 13M10 19C10 19 12.6667 17 13.6454 13M10 19C13.9187 19 17.2524 16.4956 18.4879 13M10 1C6.46628 1 3.40822 3.03656 1.93552 6C1.7751 6.32281 1.63348 6.65662 1.51212 7M10 1C10 1 7.33333 3 6.35465 7M10 1C10 1 12.6667 3 13.6454 7M10 1C13.9187 1 17.2524 3.50442 18.4879 7M1.51212 7C1.18046 7.93834 1 8.94809 1 10C1 11.0519 1.18046 12.0617 1.51212 13M1.51212 7H6.35465M6.35465 7C6.13469 7.89898 6 8.89898 6 10C6 11.101 6.13469 12.101 6.35465 13M6.35465 7H13.6454M13.6454 7C13.8653 7.89898 14 8.89898 14 10C14 11.101 13.8653 12.101 13.6454 13M13.6454 7H18.4879M18.4879 7C18.8195 7.93834 19 8.94809 19 10C19 11.0519 18.8195 12.0617 18.4879 13M1.51212 13L6.35465 13M6.35465 13H13.6454M13.6454 13H18.4879'
        stroke={iconColor(props)}
        strokeWidth='1.5'
      />
    </svg>
  )
}
