import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { useHomePageUrl } from '../../hooks/useHomePageUrl'
import { Text, TextStrong } from '../../ui/Typography/Typography'

interface Props {
  onCancel: () => void
  onConfirm: () => void
  title: string
  message: string
}
export const PasswordRestrictionMessageModal: React.FC<Props> = ({
  onCancel,
  onConfirm,
  title,
  message,
}) => {
  const { t } = useTranslation()

  const homePageUrl = useHomePageUrl()

  return (
    <Fragment>
      <header className={'modal-card-head'}>
        <p className='modal-card-title'>{title}</p>
      </header>
      <section className='modal-card-body'>
        <Text isParagraph>{message}</Text>
      </section>
      <footer className='modal-card-foot'>
        <button
          className='button'
          onClick={() => {
            window.open(
              `${homePageUrl}/about/contact-us`,
              '_blank',
              'location=yes,height=570,width=520,scrollbars=yes,status=yes'
            )
            onCancel()
          }}
          type='button'
        >
          {t('Contact support')}
        </button>

        <button className='button' onClick={onConfirm} type='button'>
          <TextStrong>{t('Got It')}</TextStrong>
        </button>
      </footer>
    </Fragment>
  )
}
