import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import * as XLSX from 'xlsx'

import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
  useScrollToElementIds,
} from '../../hooks/useScrollToElementIds'
import { BrokerSummaryDto } from '../../model/BrokerSummaryDto'
import { DealDto } from '../../model/DealDto'
import { FilterOptionsDto } from '../../model/FilterOptionsDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { PageQuery, SortOrder, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { getAppliedFiltersLength, setQuery } from '../../utils/filter.utils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import { IAppendableFetchResult, useFetchAppendablePage, useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { IBIncomeCards } from './IBIncomeCard'
import { IBIncomeFilterModal } from './IBIncomeFilterModal'
import { IBIncomeTable } from './IBIncomeTable'

const initialQuery = {
  ca_search_TextSearch: undefined,
}

export interface IBIncomeQuery extends FilterQueryProps {
  VolumeFrom?: string
  VolumeTo?: string
  ca_search_AccountType?: number
}

interface IBIncomePageProps {
  TradingAccountName?: string
}

const useIBIncomeFilterOptions = () => {
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(
    async () => {
      return await apiClient.getIBIncomeFilterOptions()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const { data: filterOptions } = useFetchOne(callback)

  return { filterOptions }
}

interface IbContestCampaignsFetchProps {
  search: SearchResponseProps
}

const useIntroducingBrokerIncomeSummary = (props: IbContestCampaignsFetchProps) => {
  const { search } = props

  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(
    async (query?: PageQuery) => {
      return apiClient.getIntroducingBrokerIncomeSummary({
        ...query,
        ...search.pageQuery,
        search: search.search,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search.search]
  )
  const introducingBrokerSummary = useFetchOne(callback)

  return { introducingBrokerSummary }
}

const useIntroducingBrokerIncome = (props: IbContestCampaignsFetchProps) => {
  const { search } = props

  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(
    async (query?: PageQuery) => {
      return await apiClient.getIntroducingBrokerIncome({
        ...query,
        ...search.pageQuery,
        search: search.search,
        ca_search_TextSearch: search.searchTerm.searchTerm,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      search.search,
      search.pageQuery?.pageIndex,
      search.pageQuery?.pageSize,
      search.pageQuery?.sort,
      search.pageQuery?.sortOrder,
    ]
  )
  const introducingBrokerIncome = useFetchAppendablePage(callback)

  useEffect(
    () => {
      const timer = setTimeout(() => {
        if (search?.searchTerm?.searchTerm !== undefined) {
          search.setSearch({
            ca_search_TextSearch: search.searchTerm.searchTerm,
          })
          introducingBrokerIncome.setPageQuery!({
            ...introducingBrokerIncome.pageQuery,
            pageIndex: 1,
          })
        }
      }, 500)
      return () => clearTimeout(timer)
    },
    [search.searchTerm.searchTerm] // eslint-disable-line react-hooks/exhaustive-deps
  )

  return { introducingBrokerIncome }
}

export interface SearchResponseProps {
  clearSearch(callback: () => void): void
  search: FilterQueryProps
  searchTerm: SearchTermState
  showSearchTerm(value: SearchTermState): void
  setSearch(value: FilterQueryProps): void
  pageQuery: PageQuery | undefined
  setPageQuery(value: PageQuery): void
  isFilterModal: boolean
  setFilterModalOpen(value: boolean): void
}

const useSearch = (props: SortResponseProps): SearchResponseProps => {
  const { sort, sortOrder } = props

  const location = useLocation()
  const { clearFilter } = useDateFilterWriteContext()

  const [isFilterModal, setFilterModalOpen] = useState<boolean>(false)
  const [search, setSearch] = useState<FilterQueryProps>(initialQuery)
  const [searchTerm, showSearchTerm] = useState<SearchTermState>({
    show: false,
    searchTerm: undefined,
  })
  const [pageQuery, setPageQuery] = useState<PageQuery>({ sort, sortOrder })

  useEffect(() => {
    setSearch(initialQuery)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const clearSearch = (callback: () => void) => {
    callback()
    setSearch(initialQuery)
    clearFilter()
  }

  return {
    clearSearch,
    search,
    searchTerm,
    showSearchTerm,
    setSearch,
    pageQuery,
    setPageQuery,
    isFilterModal,
    setFilterModalOpen,
  }
}

interface SortResponseProps {
  getSortName(): string | undefined
  closeSortModal(): void
  isSortModalOpen: boolean
  setSort(value: string): void
  sort: string | undefined
  sortOrder: SortOrder | undefined
  setSortOrder(value: SortOrder): void
  setSortModalOpen(value: boolean): void
  sortOptions: { id: string; name: string }[]
}

const useSort = (): SortResponseProps => {
  const { t } = useTranslation()

  const [sort, setSort] = useState<string>()
  const [sortOrder, setSortOrder] = useState<SortOrder>()
  const [isSortModalOpen, setSortModalOpen] = useState(false)

  const sortOptions = [
    { id: 'TradingAccount.Name', name: t('Trading Account.Trading Account') },
    { id: 'TradingAccount.ClientTradingAccount.Wallet.Client.Name', name: t('IB.Name') },
    { id: 'TradedAt', name: t('IB.Date & Time') },
    { id: 'CurrencyId', name: t('IB.Currency') },
    {
      id: 'TradingAccount.AccountGroupServer.AccountGroup.AccountGroupTypeId',
      name: t('Trading Account.Account Type'),
    },
    { id: 'Instrument.Name', name: t('IB.Instrument') },
    { id: 'Volume', name: t('IB.Volume') },
    { id: 'Amount', name: t('IB.Commission') },
    {
      id: 'TradingAccount.ClientTradingAccount.Wallet.Client.TickmillCompany.Name',
      name: t('IB.Entity'),
    },
  ]

  const getSortName = () => {
    return sortOptions.find((x) => x.id === sort)?.name
  }

  const closeSortModal = () => {
    setSortModalOpen(false)
  }

  return {
    getSortName,
    closeSortModal,
    isSortModalOpen,
    setSort,
    sort,
    sortOrder,
    setSortOrder,
    setSortModalOpen,
    sortOptions,
  }
}

interface GenerateDocumentFileProps {
  data: DealDto[]
}

const useGenerateDocumentFile = (props: GenerateDocumentFileProps) => {
  const { data } = props

  const { t } = useTranslation()

  const exportFilename = 'income_report'

  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const { formatNumber } = useFormatNumber()

  const [isOptionsModalOpen, setOptionsModalOpen] = useState<boolean>(false)

  const handleGeneratePDF = () => {
    const data = tableBody()
    generatePDFTable({ data, fileName: exportFilename })
    setOptionsModalOpen(false)
  }

  const handleGenerateExcel = () => {
    const data = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(data)

    XLSX.utils.book_append_sheet(wb, ws, exportFilename)
    XLSX.writeFile(wb, `${exportFilename}.xlsx`)

    setOptionsModalOpen(false)
  }

  const tableBody = () => {
    const headerCsvData = [
      [
        t('Trading Account.Trading Account'),
        t('IB.Name'),
        t('IB.Date & Time'),
        t('IB.Currency'),
        t('Trading Account.Account Type'),
        t('IB.Instrument'),
        t('IB.Volume'),
        t('IB.Commission'),
        t('IB.Entity'),
      ],
    ]

    return data.reduce((previousValue, currentValue) => {
      return previousValue.concat([
        [
          currentValue.tradingAccount.name,
          currentValue.client.name,
          formatDate(currentValue.tradedAt, { formatType: dateFormat }),
          currentValue.currency.id,
          currentValue.tradingAccountType.name,
          currentValue.instrument.name,
          formatNumber(currentValue.volume),
          formatNumber(currentValue.amount),
          currentValue.tickmillCompany?.name || 'N/A',
        ],
      ])
    }, headerCsvData)
  }

  return {
    handleGenerateExcel,
    handleGeneratePDF,
    tableBody,
    isOptionsModalOpen,
    exportFilename,
    setOptionsModalOpen,
  }
}

export const IBIncomePage: React.FC<IBIncomePageProps> = () => {
  useScrollToTop()
  const isMobile = useWindowResize()
  const { scrollIntoView } = useScrollToElementIds()
  const [hasScrolledToHeader, setHasScrolledToHeader] = useState(false)

  const sort = useSort()
  const search = useSearch(sort)
  const { introducingBrokerSummary } = useIntroducingBrokerIncomeSummary({ search })
  const { introducingBrokerIncome } = useIntroducingBrokerIncome({
    search,
  })
  const { filterOptions } = useIBIncomeFilterOptions()
  const generateDocumentFile = useGenerateDocumentFile({ data: introducingBrokerIncome.data })
  const location = useLocation()
  const tradingAccountName = location.state?.TradingAccountName

  const handleClearSearch = () => {
    search.clearSearch(() => {
      introducingBrokerIncome.setPageQuery?.(undefined)
    })
  }

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.IBIncomeHeader,
    isPaginationEntrySelected,
    introducingBrokerIncome.isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(
    ScrollToIds.IBIncomeHeader,
    introducingBrokerIncome.isLoading,
    introducingBrokerIncome.meta?.pageSize
  )

  const handlePageChange = (
    pageIndex: number,
    pageSize: number,
    pagingEventType?: PagingEventType
  ) => {
    if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
      setIsPaginationEntrySelected(true)
    }
    introducingBrokerIncome.setPageQuery!({
      ...introducingBrokerIncome.pageQuery,
      pageIndex,
      pageSize,
    })
  }

  const handleFilter = (q: IBIncomeQuery): void => {
    search.setSearch(q)
  }

  const handleSortOpen = () => {
    sort.setSortModalOpen(true)
  }

  const handleOptionsOpen = () => {
    generateDocumentFile.setOptionsModalOpen(true)
  }

  const handleFilterOpen = () => {
    search.setFilterModalOpen(true)
  }

  useEffect(() => {
    if (tradingAccountName) {
      search.setSearch({
        ...search.search,
        ca_search_TextSearch: tradingAccountName,
      })
      search.showSearchTerm({
        show: true,
        searchTerm: tradingAccountName,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tradingAccountName])

  useEffect(() => {
    if (!introducingBrokerIncome.isLoading && tradingAccountName && !hasScrolledToHeader) {
      scrollIntoView([ScrollToIds.IBIncomeHeader])
      setHasScrolledToHeader(true)
    }
  }, [introducingBrokerIncome.isLoading, tradingAccountName, hasScrolledToHeader])

  return (
    <React.Fragment>
      {search.isFilterModal && (
        <Modal
          closeModal={() => search.setFilterModalOpen(false)}
          render={({ closeModal }) => (
            <FilterModal
              filterOptions={filterOptions}
              currentQuery={search.search}
              onConfirm={handleFilter}
              onClose={closeModal}
            />
          )}
        />
      )}
      {isMobile && sort.isSortModalOpen && (
        <Modal
          closeModal={sort.closeSortModal}
          render={() => <SortModal sort={sort} search={search} />}
        />
      )}
      {generateDocumentFile.isOptionsModalOpen && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              fileName={generateDocumentFile.exportFilename}
              csvData={generateDocumentFile.tableBody()}
              onExportToCSV={closeModal}
              onExportToPdf={generateDocumentFile.handleGeneratePDF}
              onExportToExcel={generateDocumentFile.handleGenerateExcel}
              onCloseModal={closeModal}
            />
          )}
          closeModal={() => generateDocumentFile.setOptionsModalOpen(false)}
        />
      )}
      <Header
        introducingBrokerIncome={introducingBrokerIncome}
        introducingBrokerSummary={introducingBrokerSummary.data}
        search={search}
        sort={sort}
        onSortOpen={handleSortOpen}
        onOptionsOpen={handleOptionsOpen}
        onFilterOpen={handleFilterOpen}
        clearSearch={handleClearSearch}
      />
      <Loading
        showLoadingIcon
        isLoading={introducingBrokerIncome.isLoading || introducingBrokerSummary.isLoading}
      >
        {!isMobile && <IBIncomeTable data={introducingBrokerIncome.data} search={search} />}
        {isMobile && <IBIncomeCards data={introducingBrokerIncome.data} search={search} />}
        {introducingBrokerIncome.meta && (
          <Paging
            maxPageSize={500}
            pageData={introducingBrokerIncome.meta}
            isLoading={introducingBrokerIncome.isLoading}
            onPageChanged={handlePageChange}
            scrollToHeaderId={ScrollToIds.IBIncomeHeader}
          />
        )}
      </Loading>
    </React.Fragment>
  )
}

interface HeaderProps {
  introducingBrokerIncome: IAppendableFetchResult<DealDto[]>
  introducingBrokerSummary?: BrokerSummaryDto
  search: SearchResponseProps
  sort: SortResponseProps
  onSortOpen(): void
  onFilterOpen(): void
  onOptionsOpen(): void
  clearSearch(): void
}

const Header: React.FC<HeaderProps> = (props) => {
  const {
    sort,
    introducingBrokerIncome: query,
    introducingBrokerSummary,
    search,
    onSortOpen,
    onOptionsOpen,
    onFilterOpen,
    clearSearch,
  } = props

  const { t } = useTranslation()
  const { formatNumber } = useFormatNumber()

  if (query.hasInitialResults) {
    return (
      <PageHeader
        id={ScrollToIds.IBIncomeHeader}
        title={t('IB.Income')}
        optionsToggle={onOptionsOpen}
        calculations={[
          {
            title: t('IB.Total Volume'),
            value: formatNumber(introducingBrokerSummary?.volume),
          },
          {
            title: t('IB.Total Commission'),
            value: formatNumber(introducingBrokerSummary?.amount),
          },
        ]}
        search={{
          show: search.searchTerm.show,
          searchTerm: search.searchTerm.searchTerm,
          setShow: (v) => search.showSearchTerm({ ...search.searchTerm, show: v }),
          setSearchTerm: (v) => search.showSearchTerm({ ...search.searchTerm, searchTerm: v }),
        }}
        filterToggles={{
          sortLabel: normalizeSortLabel(t, sort?.sortOptions, search?.pageQuery?.sort),
          openSortModal: onSortOpen,
          openFilterModal: onFilterOpen,
          resetFilters: clearSearch,
          activeFilters: getAppliedFiltersLength({
            ...search.search,
          }),
        }}
      />
    )
  }

  return <PageHeader title={t('IB.Income')} id={ScrollToIds.IBIncomeHeader} />
}

interface SortModalProps {
  sort: SortResponseProps
  search: SearchResponseProps
}

const SortModal: React.FC<SortModalProps> = (props) => {
  const { sort, search } = props

  return (
    <SortByModal
      onConfirm={(ibSort: string, sortOrder: SortOrder) => {
        search.setPageQuery?.({
          ...search.pageQuery,
          sort: ibSort,
          sortOrder: sortOrder,
        })
        sort.closeSortModal()
      }}
      options={sort.sortOptions}
      onCancel={sort.closeSortModal}
    />
  )
}

interface FilterModalProps {
  filterOptions?: FilterOptionsDto
  currentQuery?: IBIncomeQuery
  onConfirm(q: IBIncomeQuery): void
  onClose(): void
}

export const FilterModal: React.FC<FilterModalProps> = (props) => {
  const { onConfirm, onClose, currentQuery, filterOptions } = props

  return (
    <IBIncomeFilterModal
      filterOptions={filterOptions}
      currentQuery={currentQuery}
      onConfirm={({ searchFilters, currentFilter }) => {
        const q: IBIncomeQuery = {
          ...setQuery(currentFilter),
          ...searchFilters,
        }

        if (!currentFilter) {
          delete q.DateCreated
          delete q.DateFrom
          delete q.DateTo
        }

        onConfirm(q)
        onClose()
      }}
    />
  )
}
