import { FormFieldType } from './FormFieldType'

export type FormFieldComponentType = FormFieldText | FormFieldTextArea | FormFieldSelect

export interface FormFieldText extends Omit<FormFieldBase, 'value'> {
  value: FormFieldTextValue
}

export interface FormFieldTextArea extends Omit<FormFieldBase, 'value'> {
  value: FormFieldTextAreaValue
}

export interface FormFieldSelect extends Omit<FormFieldBase, 'value'> {
  value: FormFieldSelectValue[]
}

interface FormFieldBase {
  name: string
  readonly: boolean
  label: string
  type: string
  required: boolean
  disabled: boolean
  maxlength?: number
  value: FormFieldTextValue | FormFieldTextAreaValue | FormFieldSelectValue
}

type FormFieldTextValue = string | null
type FormFieldTextAreaValue = string | null
type FormFieldSelectValue = {
  name: string
  value: string
  default: boolean
}

export const isFormFieldSelect = (field: FormFieldComponentType): field is FormFieldSelect => {
  return FormFieldType.SELECT === field.type
}

export const isFormFieldText = (field: FormFieldComponentType): field is FormFieldText => {
  return FormFieldType.TEXT === field.type
}

export const isFormFieldTextArea = (field: FormFieldComponentType): field is FormFieldTextArea => {
  return FormFieldType.TEXTAREA === field.type
}

export const isFormFieldDisabled = (field: FormFieldComponentType): field is FormFieldText => {
  return FormFieldType.DISABLED === field.type
}

export const isFormFieldHidden = (field: FormFieldComponentType): field is FormFieldSelect => {
  return FormFieldType.HIDDEN === field.type
}
