import { isCampaignCalculationAccountType } from './CampaignCalculationType'
import {
  isCampaignClientContestType,
  isCampaignClientRebateType,
  isCampaignLiveRebateAccountType,
} from './CampaignType'
import { NameDto } from './NameDto'
import { PlatformTypeEnum } from './PlatformTypeEnum'

export interface ClientRebateCampaignDetailDto {
  id: string
  name: string | null
  type: NameDto
  calculationType: NameDto
  totalRebate: number
  tradingAccount: ClientRebateCampaignDetailTradingAccountDto
  disqualified: boolean
}

interface ClientRebateCampaignDetailTradingAccountDto {
  id: string
  name: string | null
  currencyId: string | null
  platformType: NameDto<PlatformTypeEnum>
}

export const isCampaignClientRebate = (
  campaign?: ClientRebateCampaignDetailDto | undefined
): campaign is ClientRebateCampaignDetailDto => {
  return isCampaignClientRebateType(campaign?.type?.id)
}

export const isCampaignClientContest = (
  campaign?: ClientRebateCampaignDetailDto | undefined
): campaign is ClientRebateCampaignDetailDto => {
  return isCampaignClientContestType(campaign?.type?.id)
}

export const isCampaignLiveRebateAccount = (
  campaign?: ClientRebateCampaignDetailDto | undefined
): campaign is ClientRebateCampaignDetailDto => {
  return isCampaignLiveRebateAccountType(campaign?.type?.id)
}

export const isCampaignCalculationAccount = (
  campaign?: ClientRebateCampaignDetailDto | undefined
) => {
  return isCampaignCalculationAccountType(campaign?.calculationType.id)
}
