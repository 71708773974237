import { isDocumentStatusExpired, isDocumentStatusPendingApproval } from './DocumentStatus'
import { NameDto } from './NameDto'

export interface DocumentClientAreaDto {
  id: string
  type: NameDto
  category: NameDto
  name: string | null
  uploadedDate: string
  reviewedDate: string | null
  expiryDate: string | null
  status: NameDto
  statusHistory: DocumentStatusHistoryDto[]
  downloadHyperlink: string | null
  inlineHyperlink: string | null
}

export const isDocumentClientAreaPendingStatus = (
  documentClientArea: DocumentClientAreaDto
): boolean => {
  return isDocumentStatusPendingApproval(documentClientArea.status.id)
}

export const isDocumentClientAreaExpiredStatus = (
  documentClientArea: DocumentClientAreaDto
): boolean => {
  return isDocumentStatusExpired(documentClientArea.status.id)
}

export interface DocumentStatusHistoryDto {
  createdDate: string
  category: NameDto
  status: NameDto
  statusReason: NameDto
  reason: string | null
}

export enum DocumentCategoryStatus {
  Personal = 1,
  Corporate = 3,
  AppropriatenessTest = 9,
  PaymentAgent = 10,
}

export const isDocumentCategoryAppropriatenessTestStatus = (
  document: DocumentClientAreaDto | undefined
) => {
  return DocumentCategoryStatus.AppropriatenessTest === document?.category?.id
}
