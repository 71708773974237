import { initReactI18next } from 'react-i18next'
import i18n from 'i18next'

import { AllLocales } from './global/locale/Locale'

const ns = ['common']

i18n.use(initReactI18next).init({
  debug: process.env.NODE_ENV === 'development',
  react: { useSuspense: false },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  defaultNS: ns[0],
  ns,
  supportedLngs: AllLocales,
  returnNull: false,
})

AllLocales.forEach((lang) => {
  ns.forEach((n) => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    i18n.addResourceBundle(lang, n, require(`../public/locales/${lang}/${n}.json`))
  })
})

export default i18n
