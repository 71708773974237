import styles from './Icons.module.scss'

export function WalletIcon({ color, ...props }: { size?: number; color?: string }) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 7H20C20.5523 7 21 7.44772 21 8V19C21 19.5523 20.5523 20 20 20H4C3.44772 20 3 19.5523 3 19V5C3 4.44772 3.44772 4 4 4H18'
        stroke={color || styles.contrastMediumLow}
        strokeWidth='0.9'
        strokeLinecap='round'
      />
      <path
        d='M17.5 11.5V14.5'
        stroke={color || styles.contrastMediumLow}
        strokeWidth='0.9'
        strokeLinecap='round'
      />
    </svg>
  )
}
