import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormTemplate } from '../../../global/templates/FormTemplate'
import { PaymentProvider } from '../../../model/PaymentProviderDto'
import { useIsLangSelected } from '../../../utils/language.utils'
import { TermsConditions } from '../../TermsConditions/TermsConditions'
import { Wallet } from './WalletDepositForm/WalletDepositForm'
import { useGetDepositCurrencyLimits } from './WalletDepositForm/hooks/useGetDepositCurrencyLimits'
import { walletDepositTermsConditionsMessageFormat } from './WalletDepositTermsModal'

import styles from './WalletDepositTermsConditions.module.scss'

interface Props {
  wallet: Wallet
  paymentProvider: PaymentProvider
  onCancel(): void
}
export const WalletDepositTermsConditions: FC<Props> = ({ wallet, paymentProvider, onCancel }) => {
  const { t } = useTranslation()

  useIsLangSelected(onCancel)

  const { depositCurrencyLimits } = useGetDepositCurrencyLimits({
    wallet: wallet,
    paymentProvider: paymentProvider,
  })

  const formattedTerms = useMemo((): string[] => {
    return paymentProvider?.parameters?.termsConditions?.map((term, index) =>
      wallet && paymentProvider
        ? walletDepositTermsConditionsMessageFormat(
            `${index + 1}. ${term.name}`,
            paymentProvider,
            depositCurrencyLimits
          )
        : `${index + 1}. ${term.name}`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentProvider])

  return (
    <FormTemplate
      title={t('Wallet.General Terms and Conditions for Deposits')}
      goBack={onCancel}
      headerDisplayType={'flex'}
      titleClassName={styles.customTitle}
    >
      <TermsConditions terms={formattedTerms} onCancel={onCancel} />
    </FormTemplate>
  )
}
