import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useRedirectToSupport } from '../../utils/useRedirectToSupport'
import { CancelActionModal } from './CancleActionModal'

interface Props {
  title?: string
  description?: string
  onCancel: () => void
  onConfirm: () => void
}

export const RestrictionActionModalBody: React.FC<Props> = ({ description }) => (
  <p dangerouslySetInnerHTML={{ __html: description as string }} />
)

export const RestrictionActionModal: React.FC<Props> = (props) => {
  const { t } = useTranslation()
  const { supportUrl } = useRedirectToSupport()
  const {
    onCancel,
    onConfirm,
    title = t('Temporarily unavailable'),
    description = t('Please contact Support for assistance.'),
  } = props

  return (
    <CancelActionModal
      title={title}
      footer={[
        <Link to={supportUrl}>
          <button className='button' onClick={() => onCancel()} type='button'>
            {t('Contact Support')}
          </button>
        </Link>,
        <button className='button' onClick={onConfirm} type='button'>
          {t('Got It')}
        </button>,
      ]}
    >
      <RestrictionActionModalBody
        description={description}
        onCancel={onCancel}
        onConfirm={onConfirm}
      />
    </CancelActionModal>
  )
}
