import { IconProps, iconColor } from './IconProps'

export function IDdocsIcon(props: IconProps): JSX.Element {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='8' cy='9.5' r='2' stroke={iconColor(props)} strokeWidth='1.5' />
      <path
        d='M12 17C12 17 11.7568 15.9204 11.4286 15C11.1003 14.0796 9.05057 14 8 14C6.94942 14 4.8997 14.0796 4.57143 15C4.24316 15.9204 4 17 4 17'
        stroke={iconColor(props)}
        strokeWidth='1.5'
      />
      <path d='M14.5 8H20' stroke={iconColor(props)} strokeWidth='1.5' strokeLinecap='round' />
      <path d='M14.5 12H20' stroke={iconColor(props)} strokeWidth='1.5' strokeLinecap='round' />
      <path d='M14.5 16H17.5' stroke={iconColor(props)} strokeWidth='1.5' strokeLinecap='round' />
      <rect x='1' y='4' width='22' height='16' rx='1' stroke={iconColor(props)} strokeWidth='1.5' />
    </svg>
  )
}
