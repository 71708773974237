import { SortOrder } from './ApiClient'

export const compareNumbers = (a: number, b: number, nextSort: SortOrder) => {
  if (nextSort === 'ASC') {
    return a > b ? 1 : -1
  }
  return a > b ? -1 : 1
}

export function compareBrowserVersions(version1: string, version2: string) {
  const v1parts = version1.split('.').map(Number)
  const v2parts = version2.split('.').map(Number)
  for (let i = 0; i < v1parts.length; ++i) {
    if (v2parts.length === i) {
      return 1
    }
    if (v1parts[i] === v2parts[i]) {
      continue
    } else if (v1parts[i] > v2parts[i]) {
      return 1
    } else {
      return -1
    }
  }
  if (v1parts.length !== v2parts.length) {
    return -1
  }
  return 0
}
