import { useLocation } from 'react-router'

import { useProductReadContext } from '../global/context/ProductContext'
import { isSignUpPath } from '../utils/path'
import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export function HamburgerIcon(props: IconProps) {
  const size = props.size || 24
  const { isDefaultCFDProductType } = useProductReadContext()
  const isProductTypeCFD = isDefaultCFDProductType()
  const location = useLocation()
  const isSignUp = isSignUpPath(location.pathname)

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17C21 16.4477 20.5523 16 20 16H4C3.44772 16 3 16.4477 3 17ZM3 12C3 12.5523 3.44772 13 4 13H20C20.5523 13 21 12.5523 21 12C21 11.4477 20.5523 11 20 11H4C3.44772 11 3 11.4477 3 12ZM4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H20C20.5523 8 21 7.55228 21 7C21 6.44772 20.5523 6 20 6H4Z'
        fill={!isProductTypeCFD ? (!isSignUp ? '#FFFFFF' : iconColor(props)) : iconColor(props)}
      />
    </svg>
  )
}
