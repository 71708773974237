import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { createFormRadioField } from '../../../global/formField/RadioGroupField'
import { RadioButton } from '../../../global/radioButton/RadioButton'
import { NameDto } from '../../../model/NameDto'
import { TextStrong } from '../../../ui/Typography/Typography'
import { YearlyKycUpdateDetailsFormValues } from './YearlyKycUpdateDetailsForm'

const FormRadioGroupField = createFormRadioField()

export const TaxIdUnavailableReasons: FC<{ reasons: NameDto[] }> = ({ reasons }) => {
  const { values } = useFormikContext<YearlyKycUpdateDetailsFormValues>()
  const { taxIdUnavailableReasonId } = values
  const { t } = useTranslation()
  return (
    <div className='my-4'>
      <div className='mb-4'>
        <TextStrong>{`${t('Profile.Please select a reason')} *`}</TextStrong>
      </div>
      <FormRadioGroupField
        name={'taxIdUnavailableReasonId'}
        value={taxIdUnavailableReasonId}
        required
      >
        {reasons.map(({ id, name }) => {
          return (
            <RadioButton
              key={name}
              name='taxIdUnavailableReasonId'
              label={name}
              value={id}
              checked={id === Number(taxIdUnavailableReasonId)}
            />
          )
        })}
      </FormRadioGroupField>
    </div>
  )
}
