import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Loading } from '../../global/Loading/Loading'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { LegalDocumentDto } from '../../model/CompanyLegalDocumentDto'
import { TickmillProductType } from '../../model/TickmillProductType'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { filterDocumentsByClient } from '../../utils/documents'
import { filterDuplicatesById } from '../../utils/duplicates'
import { useFetchOne } from '../../utils/useFetch'
import { LegalDocumentsForm, LegalDocumentsValues } from './LegalDocumentsForm'
import { useDocumentMarketDataIdState } from './ReadDocumentMarketDataPage'

const useProductLegalDocumentsFetch = (props?: ProductLegalDocumentsProps) => {
  const apiClient = useApiClient(ClientApiClient)
  const entity = useSessionEntity()
  const locale = useSessionLanguage()

  const { account } = useAccountReadContext()
  const { isDefaultCFDProductType } = useProductReadContext()

  const isDefaultCFDProduct = isDefaultCFDProductType()
  const productIds = [isDefaultCFDProduct ? TickmillProductType.CFD : TickmillProductType.ETD]

  const legalDocumentsCallback = useCallback(async () => {
    return props?.documents
      ? props?.documents
      : await apiClient.getLegalDocumentsQuery(locale, entity, productIds)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data: documents = [], isLoading } = useFetchOne(legalDocumentsCallback)

  const accountAgreedDocuments = new Set(account?.agreedLegalDocuments.map((doc) => doc.id))

  const docs = documents
    .filter(filterDocumentsByClient)
    .filter(filterDuplicatesById)
    .filter((doc) => !accountAgreedDocuments.has(doc.id))
    .sort((a, b) => a.order - b.order)

  return { data: docs, isLoading }
}

export const useProductLegalDocumentsMutate = () => {
  const apiClient = useApiClient(ClientApiClient)

  const [isLoading, setLoading] = useState(false)
  const [isSuccess, setSuccess] = useState(false)

  const mutate = async (values: LegalDocumentsValues, callback?: () => Promise<void>) => {
    try {
      setLoading(true)

      await apiClient.updateClientLegalDocuments({
        agreedLegalDocuments: values.agreedLegalDocuments,
      })

      setSuccess(true)
      await callback?.()
    } catch {
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  return { mutate, isLoading, isSuccess }
}

interface ProductLegalDocumentsProps {
  documents?: LegalDocumentDto[]
  onSubmit?(values: LegalDocumentsValues): void
}

export const useProductLegalDocumentsStepPage = (props?: ProductLegalDocumentsProps) => {
  const { marketDataClassificationId } = useDocumentMarketDataIdState()

  const documents = useProductLegalDocumentsFetch(props)
  const mutation = useProductLegalDocumentsMutate()

  const handleSubmit = async (values: LegalDocumentsValues) => {
    if (props?.onSubmit) {
      await props.onSubmit(values)
    }
  }

  return { marketDataClassificationId, documents, mutation, handleSubmit }
}

export const ProductLegalDocumentsPage: React.FC<ProductLegalDocumentsProps> = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const locale = useSessionLanguage()
  const { isDefaultCFDProductType } = useProductReadContext()

  const { refreshAccount } = useAccountWriteContext()

  const { documents, mutation, marketDataClassificationId } =
    useProductLegalDocumentsStepPage(props)

  const handleSubmit = async (values: LegalDocumentsValues) => {
    await mutation.mutate(values, async () => {
      await refreshAccount(locale)
      navigate('/dashboard/product-registration/appropriateness-test')
    })
  }

  return (
    <Loading isLoading={documents.isLoading}>
      <LegalDocumentsForm
        title={isDefaultCFDProductType() ? t('Legal') : t('Sign up.Registration Futures Trading')}
        documents={documents.data}
        groupReferences={groupReferences(documents.data)}
        marketDataClassificationId={marketDataClassificationId}
        onSubmit={handleSubmit}
      />
    </Loading>
  )
}

const groupReferences = (documents: LegalDocumentDto[] = []): (string | null)[] => {
  const groupReferences = documents.map((x) => x.groupReference)

  return [...new Set(groupReferences)]
}
