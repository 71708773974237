import { Color } from './index'

import styles from './Icons.module.scss'

export function SupportSmartPhoneIcon(props: {
  color?: Color
  size?: number
  strokeWidth?: number
}) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10.5 5H13.5M7 21.5H17C17.5523 21.5 18 21.0523 18 20.5V3.5C18 2.94772 17.5523 2.5 17 2.5H7C6.44772 2.5 6 2.94771 6 3.5V20.5C6 21.0523 6.44772 21.5 7 21.5Z'
        stroke={props.color ? styles[props.color] : styles.textSecondary}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <circle
        cx='12'
        cy='18.5'
        r='1'
        fill={props.color ? styles[props.color] : styles.textSecondary}
      />
    </svg>
  )
}
