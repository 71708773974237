import { RefObject, useCallback, useEffect, useLayoutEffect, useRef } from 'react'

export function useAutoselect<E extends HTMLInputElement | HTMLTextAreaElement>(
  shouldFocus: boolean
): RefObject<E> {
  const ref = useRef<E>(null)
  const select = useCallback(() => ref.current!.select(), [])

  useEffect(() => {
    ref.current!.addEventListener('focus', select)

    if (shouldFocus) {
      ref.current!.focus()
    }
  }, [shouldFocus, select])

  useLayoutEffect(() => {
    return () => {
      ref.current!.removeEventListener('focus', select) // eslint-disable-line react-hooks/exhaustive-deps
    }
  }, [shouldFocus, select])

  return ref
}
