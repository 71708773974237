import styles from './Icons.module.scss'

export function StarIcon(props: { size?: number }) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.991 3.51859C11.9946 3.51107 12.0054 3.51107 12.009 3.51859L14.7232 9.12551C14.7246 9.12854 14.7275 9.13064 14.7309 9.13107L20.979 9.94294C20.9874 9.94403 20.9907 9.95439 20.9845 9.96016L16.414 14.237C16.4115 14.2393 16.4104 14.2428 16.411 14.2461L17.5585 20.3545C17.56 20.3627 17.5513 20.3691 17.5439 20.3652L12.0047 17.4016C12.0018 17.4001 11.9982 17.4001 11.9953 17.4016L6.45609 20.3652C6.44869 20.3691 6.44 20.3627 6.44155 20.3545L7.58899 14.2461C7.58962 14.2428 7.58849 14.2393 7.58599 14.237L3.01548 9.96016C3.00931 9.95439 3.01264 9.94403 3.02103 9.94294L9.26911 9.13107C9.27246 9.13064 9.27536 9.12854 9.27682 9.12551L11.991 3.51859Z'
        stroke={styles.contrastMediumLow}
        strokeWidth='0.9'
      />
    </svg>
  )
}
