import { IconProps } from './IconProps'

import styles from './Icons.module.scss'

export const ArrowRightIcon: React.FC<IconProps> = (props) => {
  return (
    <svg width='27' height='15' viewBox='0 0 27 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M1.8335 7.66668H25.1668M25.1668 7.66668L19.3335 1.83334M25.1668 7.66668L19.3335 13.5'
        stroke={props.color ? styles[props.color] : styles.contrastMediumLow}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}
