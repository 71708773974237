import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../../../../global/button/Button'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { createFormField } from '../../../../global/formField/FormField'
import { createFormNumericField } from '../../../../global/formField/FormNumericField'
import { CancelActionModal } from '../../../../global/modal/CancleActionModal'
import { ConfirmationModal } from '../../../../global/modal/ConfirmationModal'
import { Modal } from '../../../../global/modal/Modal'
import { FormTemplate } from '../../../../global/templates/FormTemplate'
import { useFormatNumber } from '../../../../hooks/useFormatNumber'
import { isTMTPlatformType } from '../../../../model/PlatformTypeEnum'
import { DemoTradingAccount } from '../../../../model/TradingAccount'

import styles from './DemoTradingAccountDepositForm.module.scss'

export interface DemoTradingAccountDepositFormValues {
  transferTo: DemoTradingAccount
  amount: number
}

interface OuterProps {
  demoTradingAccounts: DemoTradingAccount
  handleFormSubmit: (values: DemoTradingAccountDepositFormValues) => void
}

const FormNumericField = createFormNumericField<{ amount: number }>()
const FormField = createFormField<{ currency: string }>()

const DemoTradingAccountDepositFormUI: React.FC<
  FormikProps<DemoTradingAccountDepositFormValues> & OuterProps
> = (props) => {
  const [confirmationModal, setConfirmationModal] = useState(false)
  const { values, isValid, handleSubmit } = props
  const { transferTo } = values

  const [cancelModal, setCancelModal] = useState(false)

  const navigate = useNavigate()
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const handleSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (isTMTPlatformType(transferTo.platformType.id)) {
      setConfirmationModal(true)
    } else {
      handleSubmit()
    }
  }

  const locale = useSessionLanguage()

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const handlePageExitConfirmation = () => {
    navigate('/dashboard/traders-room/trading-accounts')
  }

  return (
    <React.Fragment>
      {cancelModal && (
        <Modal
          closeModal={() => setCancelModal(false)}
          render={() => (
            <CancelActionModal
              onConfirm={handlePageExitConfirmation}
              onCancel={() => setCancelModal(false)}
            />
          )}
        />
      )}
      {confirmationModal && (
        <Modal
          closeModal={() => setConfirmationModal(false)}
          render={() => (
            <ConfirmationModal
              onCancel={() => setConfirmationModal(false)}
              onConfirm={handleSubmit}
              title={t('Subscriptions.Attention')}
              renderFooter={({ onConfirm, onCancel }) => (
                <>
                  <button className='button' onClick={onCancel} type='button'>
                    {t('No')}
                  </button>
                  <button className='button' type='button' onClick={onConfirm}>
                    {t('Yes')}
                  </button>
                </>
              )}
            >
              <p>
                {t('Trading Account.If you confirm this action, your trading activity will reset')}
              </p>
            </ConfirmationModal>
          )}
        />
      )}
      <FormTemplate
        title={t('Trading Account.Add Balance to Demo Trading Account', {
          currency: transferTo.currency.id,
          account: transferTo.name,
        })}
        goBack={() => setCancelModal(true)}
      >
        <Form className={styles.wrapper} onSubmit={handleSubmitForm} autoComplete='off'>
          <div className={styles.formWithCurrency}>
            <FormNumericField
              type='number'
              name='amount'
              label={t('Wallet.Amount')}
              placeholder={t('Wallet.Amount')}
              required
              hint={
                values.transferTo &&
                `${t('Trading Account.Balance')} ${formatMoney(
                  values.transferTo.platformOverview.balance,
                  values.transferTo.currency.id
                )}`
              }
              step={1}
              error={props.errors.amount}
            />
            <FormField
              name={'currency'}
              label={t('Wallet.Currency')}
              placeholder={t('Wallet.Currency')}
              value={values.transferTo.currency.id}
              disabled
            />
          </div>
          <div className={classNames(styles.action, 'mt-6')}>
            <Button
              appearance='secondary'
              type='button'
              onClick={() => setCancelModal(true)}
              size='L'
            >
              {t('Cancel')}
            </Button>
            <Button disabled={!isValid} type='submit' appearance='primary' size='L'>
              {t('Confirm')}
            </Button>
          </div>
        </Form>
      </FormTemplate>
    </React.Fragment>
  )
}

export const DemoTradingAccountDepositForm = withFormik<
  OuterProps,
  DemoTradingAccountDepositFormValues
>({
  mapPropsToValues: ({ demoTradingAccounts }) => {
    return {
      amount: 0,
      transferTo: demoTradingAccounts,
    }
  },
  handleSubmit: async (values, { props }) => {
    props.handleFormSubmit(values)
  },
  validate: (values) => {
    const errors: FormikErrors<DemoTradingAccountDepositFormValues> = {}
    if (!values.amount) {
      errors.amount = t('Validation.Required')
    }
    if (values.amount < 100 || values.amount > 5000000) {
      errors.amount = t(
        'Validation.Virtual Deposit cannot be smaller than 100 or greater than 5,000,000'
      )
    }
    return errors
  },
  enableReinitialize: true,
  validateOnChange: true,
  isInitialValid: false,
})(DemoTradingAccountDepositFormUI)
