import React, { CSSProperties, PropsWithChildren, Ref, forwardRef } from 'react'
import classNames from 'classnames'

import { SortOrder } from '../../utils/ApiClient'

import styles from './SortHeader.module.scss'

interface Props {
  id?: string
  className?: string
  style?: CSSProperties
  align?: SortHeaderAlignType
  sort?: string
  sortOrder?: SortOrder

  startWithOrder?: SortOrder

  setSort?(id: string, order: SortOrder): void | undefined
}

export type SortHeaderAlignType = 'center' | 'left' | 'right' | 'justify' | 'char'

export const SortHeader = forwardRef(
  (props: PropsWithChildren<Props>, ref: Ref<HTMLTableHeaderCellElement>) => {
    const { id, sort, sortOrder, startWithOrder, style, className } = props

    const selected = sort === id
    const isASC = sortOrder === 'ASC'
    const isDESC = sortOrder === 'DESC'

    let nextSort: SortOrder = isASC ? 'DESC' : 'ASC'
    if (id !== sort) {
      nextSort = startWithOrder || 'ASC'
    }

    return (
      <th
        style={style}
        ref={ref}
        className={className}
        onClick={() => id && props.setSort?.(id, nextSort)}
      >
        <span
          className={classNames(styles.header, {
            [styles.alignLeft]: props.align === 'left',
            [styles.alignCenter]: props.align === 'center',
            [styles.alignRight]: props.align === 'right',
          })}
        >
          <span
            className={classNames(styles.arrow, {
              [styles.asc]: selected && isASC,
              [styles.desc]: selected && isDESC,
            })}
          >
            {props.children}
          </span>
        </span>
      </th>
    )
  }
)
