export enum WalletFormFieldType {
  SELECT = 'select',
  TEXT = 'text',
  TEXTAREA = 'textarea',
  DISABLED = 'disabled',
  HIDDEN = 'hidden',
}

export const isWalletFormFieldSelectType = (value: string) => {
  return WalletFormFieldType.SELECT === value
}

export const isWalletFormFieldTextType = (value: string) => {
  return WalletFormFieldType.TEXT === value
}

export const isWalletFormFieldTextAreaType = (value: string) => {
  return WalletFormFieldType.TEXTAREA === value
}

export const isWalletFormFieldDisabledType = (value: string) => {
  return WalletFormFieldType.DISABLED === value
}

export const isWalletFormFieldHiddenType = (value: string) => {
  return WalletFormFieldType.HIDDEN === value
}
