import { AccountDetailedDto } from './AccountDetailedDto'

export enum AccountDetailedStatus {
  AppropriatenessTestSubmitted = 1,
  EmailVerified = 2,
  PendingDocumentVerification = 3,
  Activated = 4,
  ValidationDenied = 5,
  Blocked = 6,
  Dormant = 7,
  Closed = 8,
}

export const isAccountAppropriatenessTestSubmittedStatus = (status?: number): boolean => {
  return AccountDetailedStatus.AppropriatenessTestSubmitted === status
}

export const isAccountPendingDocumentVerificationStatusType = (status: number): boolean => {
  return AccountDetailedStatus.PendingDocumentVerification === status
}

export const isAccountPendingDocumentVerificationStatus = (status?: number): boolean => {
  return AccountDetailedStatus.PendingDocumentVerification === status
}

export const isAccountDetailedActivatedStatus = (status: number | undefined): boolean => {
  return AccountDetailedStatus.Activated === status
}

export const isAccountDetailedValidationDeniedStatus = (status: number): boolean => {
  return AccountDetailedStatus.ValidationDenied === status
}

export const isAccountActivatedStatus = (account: AccountDetailedDto | undefined): boolean => {
  return AccountDetailedStatus.Activated === account?.status?.id
}
