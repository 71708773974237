import { NameDto } from './NameDto'

export enum TwoFAProviderEnum {
  Email = 1,
  SMS = 2,
  GoogleAuthenticator = 3,
}

export interface Change2FAProvidersData {
  type: NameDto
  password: string
  authMedium?: string
}

export interface Update2FAProvidersData {
  typeId: number
  password: string
  authMedium?: string
}

export interface VerifyFistTimeUseData {
  typeId: number
  code: string
}

export interface TwoFAProviderItem {
  authMedium: string
  type: NameDto
}

export interface GoogleAuthenticator2FAProviderDataDto {
  data: {
    qrCode: string
    manualToken: string
  }
}

export interface TwoFactorAuthRecoveryCodeDto {
  recoveryCode: string | null
  isInvalid: boolean
}

export interface TwoFactorAuthRecoveryCodesDto {
  recoveryCodesDto: TwoFactorAuthRecoveryCodeDto[]
}
