import React, { useState } from 'react'

import { useProductReadContext } from '../../global/context/ProductContext'
import { GuideTypes } from '../../model/ClientGuideDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { CarouselProvider } from '../Carousel/CarouselProvider'
import {
  CheckEmailInstructionsCarouselChild,
  CongratulationsCarouselChild,
  FundYourWalletCarouselChild,
  SubscribeToMarketDataCarouselChild,
  SubscribeToTradingPlatformCarouselChild,
  TransferFundsFromWalletToTradingAccountCarouselChild,
} from './FirstTimeGuideCarouselChild'
import { GuideConfirmationModal } from './GuideConfirmationModal'

import styles from './FirstTimeGuide.module.scss'

interface FirstTimeGuideCarouselProps {
  onConfirmationModalOpen(): void
}

interface FirstTimeGuideCarouselCFDProps {
  reloadGuide(): void
  handleDeposit: () => void
}

export const FirstTimeGuideCarouselETD = (props: FirstTimeGuideCarouselCFDProps) => {
  const apiClient = useApiClient(ClientApiClient)
  const { product } = useProductReadContext()
  const { handleDeposit } = props

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false)

  const handleGuideDismiss = async () => {
    await apiClient.updateClientGuideDismiss(GuideTypes.IntroductionGuide, {
      tickmillProductId: product,
    })
    props.reloadGuide()
    setConfirmationModalOpen(false)
  }

  const handleConfirmationModalOpen = () => {
    setConfirmationModalOpen(true)
  }

  const handleConfirmationModalClose = () => {
    setConfirmationModalOpen(false)
  }

  const carousels = [
    { component: <FundYourWalletCarouselChild onDeposit={handleDeposit} /> },
    { component: <TransferFundsFromWalletToTradingAccountCarouselChild /> },
    { component: <SubscribeToTradingPlatformCarouselChild /> },
    { component: <SubscribeToMarketDataCarouselChild /> },
    { component: <CheckEmailInstructionsCarouselChild /> },
    { component: <CongratulationsCarouselChild /> },
  ]

  const carouselSettings = {
    hideDots: true,
    interval: 5000,
    items: carousels,
    loop: false,
  }

  return (
    <div className={styles.wrapper}>
      {isConfirmationModalOpen && (
        <GuideConfirmationModal
          onGuideClose={handleGuideDismiss}
          onClose={handleConfirmationModalClose}
        />
      )}
      <CarouselProvider<FirstTimeGuideCarouselProps>
        {...carouselSettings}
        onConfirmationModalOpen={handleConfirmationModalOpen}
      />
    </div>
  )
}
