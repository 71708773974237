import { ReactElement } from 'react'

import { Color } from './index'

import styles from './Icons.module.scss'

export const CircleSuccessIcon = (props: { size?: number; color?: Color }): ReactElement => {
  const size = props.size || 24
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icon'>
        <circle
          id='Ellipse 22'
          cx='12'
          cy='12'
          r='10'
          fill={props.color ? styles[props.color] : styles.success}
        />
        <path
          id='Vector 2 (Stroke)'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M17.0964 9.5889C17.5035 10.0001 17.5018 10.6686 17.0926 11.0819L12.4211 15.8009C11.5332 16.6977 10.0984 16.7046 9.21121 15.8163L6.84837 13.4502C6.43951 13.0408 6.43833 12.3724 6.84574 11.9572C7.25315 11.5421 7.91488 11.5375 8.32375 11.9469L10.6866 14.313C10.7585 14.385 10.8749 14.3844 10.9468 14.3117L15.6184 9.59274C16.0276 9.17939 16.6893 9.17768 17.0964 9.5889Z'
          fill='white'
        />
      </g>
    </svg>
  )
}
