import { FormFieldComponentType } from '../global/formField/formFieldsFactory/FormFieldComponentType'
import { NameDto } from './NameDto'
import { isWalletFormFieldDisabledType, isWalletFormFieldHiddenType } from './WalletFormFieldType'
import type {
  FormItemDto,
  FormItemInputDto,
  WalletPaymentProviderMethodParametersResponseDto,
  WalletPaymentProviderParameterMiscDto,
} from './WalletPaymentProviderParametersDto'
import { isFormItemInputDto, isFormItemSelectDto } from './WalletPaymentProviderParametersDto'

export interface WalletPaymentProviderParameters {
  form: FormItemDto[]
  fields: FormFieldComponentType[]
  currencies: WalletPaymentProviderCurrency[]
  currenciesLimits: WalletPaymentProviderMethodCurrency[]
  messages: WalletPaymentProviderParameterMessage[]
  termsConditions: NameDto[]
}

export interface WalletPaymentProviderCurrency {
  id: string
  name: string
  minAmount?: number
  description?: string
}

export interface WalletPaymentProviderMethodCurrency {
  id: string
  walletType: {
    id: number
  }
  minAmount: number
  maxAmount: number
}

export interface WalletPaymentProviderParameterMessage {
  title: string[]
  top: string[]
  list: string[]
  bottom: string[]
}

export const normalizeWalletPaymentProviderParameters = (
  dto: WalletPaymentProviderMethodParametersResponseDto | null | undefined
): WalletPaymentProviderParameters => {
  const messages = dto?.reply?.messages
  const form = dto?.reply?.form || []
  const messagesList = messages || []
  const misc = dto?.reply?.misc || {}
  const tc = dto?.reply?.tc || []

  const currencies = normalizeWalletPaymentProviderCurrency(form)
  const currenciesLimits = normalizeWalletPaymentProviderMethodParameterCurrencyLimits(misc)
  const fields = normalizeFormFields(form)

  return {
    form,
    fields,
    currencies,
    currenciesLimits,
    messages: messagesList.filter((x) => !!x),
    termsConditions: tc
      .filter((x) => !!x)
      .map((x) =>
        x
          .replace(/{currency}/g, '{paymentProviderCurrency}')
          .replace(/{minimum_amount}/g, '{minAmount}')
          .replace(/{maximum_amount}/g, '{maxAmount}')
          .replace(/{payment_currency}/g, '{paymentProviderCurrency}')
      )
      .map((name, index) => {
        const id = index + 1
        return { id, name }
      }),
  }
}

const normalizeWalletPaymentProviderCurrency = (
  formItems: FormItemDto[] = []
): WalletPaymentProviderCurrency[] => {
  const paymentCurrencyField = 'payment_currency_id'
  const filteredCurrencies = formItems.filter((x) => x.field === paymentCurrencyField)

  return filteredCurrencies
    .reduce((previousValue: FormItemInputDto[], currentValue) => {
      if (isFormItemInputDto(currentValue)) {
        return previousValue.concat({
          ...currentValue,
          value: currentValue.value,
          name: currentValue.name,
        })
      }

      if (isFormItemSelectDto(currentValue)) {
        const values = currentValue.value.map((x) => ({
          ...currentValue,
          value: x.value,
          name: x.name,
        }))
        return previousValue.concat(values)
      }

      return previousValue
    }, [])
    .sort((value) => (value ? -1 : 1))
    .map((x) => ({ id: x.value, name: x.name }))
}

const normalizeWalletPaymentProviderMethodParameterCurrencyLimits = (
  walletDepositMethodParameterMiscDto: WalletPaymentProviderParameterMiscDto
): WalletPaymentProviderMethodCurrency[] => {
  return Object.entries(walletDepositMethodParameterMiscDto).reduce(
    (previousValue: WalletPaymentProviderMethodCurrency[], [walletTypeId, wallet]) => {
      const currencies: WalletPaymentProviderMethodCurrency[] = Object.entries(wallet)
        .map(([currencyId, currency]): WalletPaymentProviderMethodCurrency => {
          return {
            id: currencyId,
            walletType: {
              id: Number.parseInt(walletTypeId, 10),
            },
            minAmount: currency.min_amount,
            maxAmount: currency.max_amount,
          }
        })
        .filter((x) => !!x?.id)

      return previousValue.concat(currencies)
    },
    []
  )
}

const normalizeFormFields = (form: FormItemDto[]): FormFieldComponentType[] => {
  return form.filter(isFormFieldVisible).map((x) => ({
    name: x.grouping ? `fields.${x.grouping}_${x.field}` : `fields.${x.field}`,
    readonly: !x.clientFilled,
    label: x.name,
    type: x.type,
    required: x.mandatory,
    value: x.value,
    disabled: isWalletFormFieldDisabledType(x.type),
    maxlength: getFieldMaxLength(x.field),
  }))
}

const getFieldMaxLength = (field: string): number | undefined => {
  switch (field) {
    case 'bank_name':
      return 255
    case 'bank_address':
      return 255
    case 'external_account':
      return 64
    case 'bank_code':
      return 32
    case 'bank_code_sort':
      return 32
    default:
      return undefined
  }
}

const isFormFieldVisible = (field: FormItemDto): field is FormItemInputDto => {
  return !isWalletFormFieldHiddenType(field.type)
}
