import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export function WarningIcon(props: IconProps): JSX.Element {
  const size = props.size || 24

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 20 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 7.5V11.5M10 13V14.5M1.0171 17H18.9829C18.9905 17 18.9954 16.9918 18.9916 16.9851L10.0087 1.01549C10.0049 1.0087 9.99511 1.0087 9.99128 1.0155L1.00838 16.9851C1.00463 16.9918 1.00945 17 1.0171 17Z'
        stroke={props.color ? styles[props.color] : iconColor(props)}
        strokeWidth='1.75'
      />
    </svg>
  )
}
