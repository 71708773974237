import React from 'react'
import { Navigate } from 'react-router-dom'

import { isDepositBeforeKYC } from '../../../../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../../../../utils/AccountContextContext'
import { FirstTimeDepositPageSignup } from './FirstTimeDepositPageSignup'

export const FirstTimeDepositOrDocumentsResolver: React.FC = () => {
  const { account } = useAccountReadContext()

  if (!isDepositBeforeKYC(account)) {
    return <Navigate to={`/sign-up/documents-info/${account?.id}`} replace />
  }

  return <FirstTimeDepositPageSignup />
}
