import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../../global/Paging/Paging'
import { SelectableModal } from '../../../global/field/SelectableModal'
import IconButton from '../../../global/iconButton/IconButton'
import { Modal } from '../../../global/modal/Modal'
import { Table } from '../../../global/table/Table'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../../hooks/useScrollToElementIds'
import { DotsIcon } from '../../../icons/DotsIcon'
import { EditIcon } from '../../../icons/EditIcon'
import { SubIBAccount } from '../../../model/MultiTierDto'
import { PageHeader } from '../../../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { PageQuery, useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useWindowResize } from '../../../utils/domUtils'
import { getCentralRowIdConditionally, getScrollToCardId } from '../../../utils/getItemId'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage } from '../../../utils/useFetch'
import { MultiTierSubAccountsCard } from '../MultiTierCards/MultiTierSubAccountsCard'
import { AddEditSubIBModal } from '../MultiTierModals/AddEditSubIBModal'
import { SubAccountsTableInfoModal } from '../MultiTierModals/SubAccountsTableInfoModal'

import styles from './MultiTierParts.module.scss'

export const MultiTierSubAccounts: FC = () => {
  const [searchTerm, showSearchTerm] = useState<{
    show: boolean
    searchTerm?: string
  }>({
    show: false,
    searchTerm: undefined,
  })
  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const [infoModal, setInfoModal] = useState(false)
  const [actionsModal, setActionsModal] = useState<SubIBAccount>()
  const [editPlanModal, setEditPlanModal] = useState<SubIBAccount>()
  const apiClient = useApiClient(ClientApiClient)
  const isMobile = useWindowResize()
  const introducingBrokerId = account?.clientIntroducingBroker?.id || ''

  const { callback, forceRefresh } = useCallbackWithForceRefresh(async (query?: PageQuery) => {
    return await apiClient.getIBSubAccounts(introducingBrokerId, {
      ...query,
      caller: 'ca',
    })
  }, [])

  const { data, meta, setPageQuery, pageQuery, isLoading } = useFetchAppendablePage(callback)

  const handleSubIB = async (subAccountId: number, commissionPlanGroupId: string) => {
    const introducingBrokerId = account?.clientIntroducingBroker?.id || ''
    await apiClient.updateIBSubAccount(introducingBrokerId, subAccountId, commissionPlanGroupId)
    setEditPlanModal(undefined)
    forceRefresh()
  }

  const handleEdit = () => {
    setEditPlanModal(actionsModal)
    setActionsModal(undefined)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      searchTerm.searchTerm !== undefined &&
        setPageQuery!((oldPageQuery: PageQuery) => ({
          ...oldPageQuery,
          pageIndex: 1,
          ca_search_TextSearch: searchTerm.searchTerm,
        }))
    }, 500)
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm.searchTerm])

  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.IBMultitierSubAccountsHeader,
    isPaginationEntrySelected,
    isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(ScrollToIds.IBMultitierSubAccountsHeader, isLoading, meta?.pageSize)

  return (
    <div>
      {editPlanModal && (
        <AddEditSubIBModal
          data={editPlanModal}
          onCancel={() => setEditPlanModal(undefined)}
          onSubmit={handleSubIB}
        />
      )}
      {!!actionsModal && (
        <Modal
          closeModal={() => setActionsModal(undefined)}
          render={() => (
            <SelectableModal
              title={t('Actions')}
              onCancel={() => setActionsModal(undefined)}
              renderOptions={() => (
                <button className={styles.editButton} onClick={handleEdit}>
                  <EditIcon />
                  {t('Edit')}
                </button>
              )}
            />
          )}
        />
      )}
      {infoModal && <SubAccountsTableInfoModal setInfoModal={setInfoModal} />}
      <PageHeader
        id={ScrollToIds.IBMultitierSubAccountsHeader}
        search={{
          show: searchTerm.show,
          searchTerm: searchTerm.searchTerm,
          setShow: (v) => showSearchTerm({ ...searchTerm, show: v }),
          setSearchTerm: (v) => {
            showSearchTerm({ ...searchTerm, searchTerm: v })
          },
        }}
        title={t('IB.MultiTier.Sub-IB Accounts')}
        titleInfoToggle={() => setInfoModal(true)}
      />
      {isMobile &&
        data?.map((x, index) => (
          <MultiTierSubAccountsCard
            key={x.commissionPlanGroup.id}
            cardId={getScrollToCardId(index, data.length, 'ib-multitier-sub-accounts')}
            data={x}
            setActionsModal={setActionsModal}
          />
        ))}

      <Table size='small'>
        <thead>
          <tr>
            <th align='center'>{t('IB.MultiTier.Sub-IB Accounts')}</th>
            <th align='center'>{t('IB.Name')}</th>
            <th align='center'>{t('IB.MultiTier.IB Commission')}</th>
            <th align='center'>{t('IB.MultiTier.Plan')}</th>
            <th align='center'>{t('IB.Volume')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {data?.map((x, index) => {
            return (
              <tr
                key={x.account.id}
                {...getCentralRowIdConditionally(index, data.length, 'ib-multitier-sub-accounts')}
              >
                <td align='center'>{x.account.name}</td>
                <td align='center' width={130}>
                  {x.clientName}
                </td>
                <td align='center'>{x.ibCommission}</td>
                <td align='center'>{x.commissionPlanGroup.name}</td>
                <td align='center'>{x.volume}</td>
                <td>
                  <div className='has-text-right pr-2 is-flex'>
                    <IconButton onClick={() => setActionsModal(x)}>
                      <DotsIcon />
                    </IconButton>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <Loading isLoading={isLoading} showLoadingIcon />
      {meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.IBMultitierSubAccountsHeader}
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsPaginationEntrySelected(true)
            }
            return setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </div>
  )
}
