import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export function TickmillWalletIcon(props: IconProps) {
  const width = props.width || 121
  const height = props.height || 153

  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 121 153'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M30.5 35H97.5C101.918 35 105.5 38.5817 105.5 43V92C105.5 96.4183 101.918 100 97.5 100H23.5C19.0817 100 15.5 96.4183 15.5 92V28C15.5 23.5817 19.0817 20 23.5 20H90.5'
        stroke={iconColor(props)}
        strokeWidth='4'
        strokeLinecap='round'
      />
      <path d='M88 57.5V72.5' stroke={iconColor(props)} strokeWidth='4' strokeLinecap='round' />
      <path
        d='M35.5567 135.9V146.632H32.592V135.9H28.8247V133.311H39.324V135.9H35.5567Z'
        fill={iconColor(props)}
      />
      <path d='M41.4917 133.336H44.4628V146.632H41.4917V133.336Z' fill={iconColor(props)} />
      <path
        d='M53.8428 144.01C54.5329 144.023 55.2163 143.873 55.8372 143.571C56.4581 143.27 56.999 142.826 57.4159 142.276L59.319 144.218C58.6913 144.963 57.9182 145.573 57.0471 146.009C56.1759 146.446 55.2249 146.701 54.252 146.758C53.2792 146.815 52.3049 146.673 51.3888 146.341C50.4726 146.008 49.6337 145.493 48.9233 144.826C47.6428 143.506 46.9298 141.738 46.9371 139.899C46.9443 138.06 47.6712 136.297 48.9621 134.987C49.6159 134.349 50.3909 133.848 51.2414 133.515C52.092 133.181 53.0007 133.021 53.914 133.045C54.9554 133.028 55.9875 133.243 56.9357 133.674C57.8839 134.105 58.7245 134.742 59.3967 135.537L57.5518 137.628C57.1463 137.082 56.618 136.64 56.0096 136.336C55.4012 136.032 54.7299 135.876 54.0499 135.88C52.9899 135.857 51.9627 136.249 51.1889 136.974C50.799 137.349 50.4938 137.803 50.294 138.305C50.0941 138.808 50.0042 139.347 50.0302 139.887C50.0052 140.433 50.0916 140.979 50.2841 141.491C50.4766 142.003 50.7712 142.47 51.15 142.865C51.4977 143.228 51.9157 143.517 52.3786 143.714C52.8416 143.911 53.3397 144.012 53.8428 144.01Z'
        fill={iconColor(props)}
      />
      <path
        d='M61.6816 133.336H64.6463V138.793L69.6694 133.336H73.3331L68.0317 139.24C68.4891 139.874 69.3328 141.057 70.5626 142.787L73.3201 146.671H69.8571L65.9733 141.421L64.6398 142.904V146.671H61.6816V133.336Z'
        fill={iconColor(props)}
      />
      <path
        d='M87.0749 138.262L83.4824 145.532H81.7088L78.1357 138.262V146.632H75.1646V133.336H79.1584L82.5826 140.638L86.0263 133.336H90.0266V146.632H87.0555L87.0749 138.262Z'
        fill={iconColor(props)}
      />
      <path d='M93.3149 133.336H96.2861V146.632H93.3149V133.336Z' fill={iconColor(props)} />
      <path
        d='M99.5547 146.632V133.336H102.526V143.991H108.19V146.632H99.5547Z'
        fill={iconColor(props)}
      />
      <path
        d='M110.365 146.632V133.336H113.33V143.991H119V146.632H110.365Z'
        fill={iconColor(props)}
      />
      <path
        d='M7.30788 137.453C7.3153 137.314 7.35549 137.177 7.4252 137.056C7.49492 136.935 7.5922 136.831 7.70921 136.754L12.0914 134.217C12.2145 134.153 12.351 134.12 12.4895 134.12C12.6281 134.12 12.7646 134.153 12.8876 134.217L16.7067 136.431L22.1052 133.324L21.9887 133.239L13.4508 128.236C13.1506 128.081 12.8177 128 12.4798 128C12.142 128 11.8091 128.081 11.5089 128.236L2.9839 133.259C2.69534 133.454 2.45618 133.713 2.28538 134.016C2.11459 134.32 2.01688 134.659 2 135.007V144.93L7.30788 141.829V137.453Z'
        fill={styles.primary}
      />
      <path
        d='M17.6648 138.139V142.541C17.6576 142.679 17.6181 142.814 17.5496 142.934C17.481 143.054 17.3852 143.157 17.2699 143.234L12.8747 145.778C12.7517 145.841 12.6152 145.874 12.4766 145.874C12.3381 145.874 12.2016 145.841 12.0785 145.778L8.25299 143.557L2.85449 146.671L2.97748 146.755L11.5025 151.772C11.8026 151.927 12.1356 152.007 12.4734 152.007C12.8112 152.007 13.1442 151.927 13.4444 151.772L21.9693 146.755C22.2565 146.559 22.4946 146.299 22.6652 145.996C22.8358 145.693 22.9344 145.355 22.9532 145.007V135.078L17.6648 138.139Z'
        fill={styles.primary}
      />
    </svg>
  )
}
