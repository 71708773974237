import { InstrumentCommissionDto } from './InstrumentCommissionDto'
import { NameDto } from './NameDto'
import { CurrencyType } from './WalletDto'

export interface IntroducingBrokerDetailsDto {
  profile?: IBProfile
  instrumentCommissions?: InstrumentCommissionDto[]
  id: string
  name: string | null
  status: IntroducingBrokerStatus
  client?: Client
  parent?: null | { id: string; name: string | null }
  registeredAt: string
  multiLevelRegisteredAt?: string | null
  multiLevelPercentage?: number | null
  commissionCalculationStatus: NameDto<number>
  assignee?: {
    id: string
    name: string
    status: NameDto<number>
  } | null
  ibUserGetsClients?: boolean
  loyaltyProgramEnabled: boolean
  loyaltyProgramEligible: boolean
  multiTierRegisteredAt: string | null
  loyaltyDetails: LoyaltyDetails
  restrictions?: any | null
  tradingAccountsCount: number
}

export interface IntroducingBrokerReferralDetailsDto {
  isDefaultCommissionPlan: boolean
  mainIbScheme: NameDto<IntroducingBrokerReferralTypes>
  code: string
}

export enum IntroducingBrokerReferralTypes {
  Default = 0,
  MultiTier = 1,
}

export enum loyaltyBonusStatus {
  Created = 1,
  Approved = 2,
  Rejected = 3,
  Reversal = 4,
  Reversed = 5,

  Redeemed = 6,
}
export interface LoyaltyDetails {
  loyaltyLots: number
  currentTier?: LoyaltyTierDto | null
  nextTier?: LoyaltyTierDto
  nextSubTier: LoyaltyNextSubTierDto | null
  loyaltyRewards: LoyaltyRewardDto[]
  redeemableRewards: LoyaltyRewardDto[]
  tiers: LoyaltyTierDto[]
  loyaltyLotsToNextTier: number
}

export type LoyaltyTier =
  | 'Default'
  | 'Bronze'
  | 'Silver'
  | 'Gold'
  | 'Platinum'
  | 'Diamond'
  | 'Elite'
  | 'Elite Plus'
  | 'Elite Plus 1'
  | 'Elite Plus 2'
  | 'Elite Plus 3'
  | 'Elite Plus 4'
  | 'Elite Plus 5'
  | 'Elite Plus 6'
  | 'Elite Plus 7'
  | 'Elite Plus 8'
  | 'Elite Plus 9'
  | 'Elite Plus 10'
  | 'Elite Plus 11'
  | 'Elite Plus 12'
  | 'Elite Plus 13'
  | 'Elite Plus 14'
  | 'Elite Plus 15'
  | 'Elite Plus 16'
  | 'Elite Plus 17'
  | 'Elite Plus 18'
  | 'Legend'
  | 'Ultimate'
export interface LoyaltyTierDto {
  id: number
  name: LoyaltyTier
  groupId: number
  label: string
  htmlPrizeText: string | null
  minLots: number
  amount: number
  maxLots: number
  order: number
  subTiers: LoyaltySubTierDto[] | null
}

interface LoyaltyRewardDto {
  amount: number
  currencyId: CurrencyType
  id: string
  loyaltyBonusStatus: NameDto<number>
  tierId: number
  transactionId: string | null
  walletId: string
}
interface LoyaltySubTierDto {
  id: number
  name: string | null
  htmlPrizeText: string | null
  lots: number
  recurring: boolean
  recurringInterval: number
}

interface LoyaltyNextSubTierDto {
  id: number
  name: string | null
  htmlPrizeText: string | null
  lots: number
  available: boolean
}

export interface Client {
  id: string
  name: string | null
  introducingBroker: IntroducingBroker
  tickmillCompany: TickmillCompany
  teams: { id: string; team: string | null }[]
}

interface TickmillCompany {
  id: number
  name: string
}

interface IntroducingBroker {
  name: string | null
  id: string
}

export interface IntroducingBrokerStatus {
  name: string | null
  id: number
}

export interface IBProfile {
  accountGroups: {
    id: string
    name: string | null
    currency: NameDto<string>
  }[]
  standardCommissions: []
  id: string
  name: string | null
  description: string | null
  tickmillCompany: { id: number; name: string | null }
}
