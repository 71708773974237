import React from 'react'

export const preventPaste = (event: React.ChangeEvent) => {
  event.preventDefault()
  return false
}

export const isAllowedNumericKey = (event: React.KeyboardEvent<HTMLInputElement>): boolean => {
  const allowedKeys = [
    '.',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    'Backspace',
    'ArrowLeft',
    'ArrowRight',
    'Tab',
    'Delete',
  ]

  if (event.key === '.' && event.currentTarget.value.includes('.')) {
    return false
  }

  return allowedKeys.includes(event.key)
}
