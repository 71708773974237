import { IconProps, iconColor } from './IconProps'

export function ChevronDownIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 15.4004L6.7 10.1004C6.3134 9.71379 6.3134 9.08699 6.7 8.70039C7.0866 8.31379 7.7134 8.31379 8.1 8.70039L12 12.6004L15.9 8.70039C16.2866 8.31379 16.9134 8.31379 17.3 8.70039C17.6866 9.08699 17.6866 9.71379 17.3 10.1004L12 15.4004Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
