import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMSeychellesLogoMobile: FC = () => (
  <svg width='120' height='30' viewBox='0 0 120 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M53.091 16.4632C54.582 16.4632 55.8183 15.8813 56.7638 14.6813L58.7274 16.6813C57.1638 18.4268 55.3456 19.2995 53.2729 19.2995C51.1638 19.2995 49.4547 18.645 48.0729 17.2995C46.7274 15.9904 46.0365 14.2813 46.0365 12.2813C46.0365 10.245 46.7274 8.57225 48.1092 7.22679C49.491 5.88134 51.2001 5.19043 53.2001 5.19043C55.4547 5.19043 57.3092 6.02679 58.8365 7.73588L56.9456 9.88134C56.0001 8.68134 54.8001 8.09952 53.3456 8.09952C52.2183 8.09952 51.2365 8.46316 50.4001 9.22679C49.6001 9.99043 49.1638 10.9722 49.1638 12.245C49.1638 13.5177 49.5638 14.5359 50.3274 15.2995C51.091 16.0632 52.0001 16.4632 53.091 16.4632Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M34.3637 19.154V8.13587H38.2182V5.51768H27.4546V8.13587H31.3091V19.154H34.3637Z'
      fill='var(--logo-text-color)'
    />
    <path d='M43.4909 5.51768H40.4364V19.154H43.4909V5.51768Z' fill='var(--logo-text-color)' />
    <path
      d='M64.1819 5.51768H61.1273V19.154H64.1819V15.2995L65.5637 13.7722L69.5637 19.154H73.1273C72.789 18.6764 72.3526 18.0681 71.8183 17.3231L71.8139 17.317C71.3729 16.7022 70.8652 15.9944 70.291 15.1904C69.1658 13.5689 68.3418 12.4292 67.8464 11.744L67.7091 11.554L73.1273 5.51768H69.3455L64.1819 11.1177V5.51768Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M87.2 10.5722L83.5273 18.0268H81.7091L78.0364 10.5722V19.154H74.9818V5.51768H79.0909L82.6182 13.0086L86.1454 5.51768H90.2545V19.154H87.2V10.5722Z'
      fill='var(--logo-text-color)'
    />
    <path d='M96.6545 5.51768H93.6V19.154H96.6545V5.51768Z' fill='var(--logo-text-color)' />
    <path
      d='M99.9999 19.154V5.51768H103.054V16.4268H108.873V19.154H99.9999Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M111.091 5.51768V19.154H119.964V16.4268H114.145V5.51768H111.091Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M5.78182 9.00909C5.56364 9.15455 5.38182 9.48182 5.38182 9.73636H5.45455V14.2455L0 17.3727V7.19091C0 6.53636 0.472727 5.73636 1.01818 5.40909L9.74546 0.245455C10.2909 -0.0818182 11.2 -0.0818182 11.7455 0.245455L20.4727 5.40909C20.5091 5.40909 20.5455 5.44546 20.5818 5.48182L15.0545 8.68182L11.1273 6.39091C10.8727 6.24545 10.5091 6.24545 10.2909 6.39091L5.78182 9.00909Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M16.0002 14.9364V10.4273L21.382 7.30005V17.4819C21.382 18.1364 20.9093 18.9364 20.3638 19.2637L11.6365 24.4273C11.0911 24.7546 10.182 24.7546 9.63653 24.4273L0.909262 19.2637C0.872901 19.2637 0.836539 19.2273 0.800178 19.191L6.32744 15.991L10.2547 18.2819C10.5093 18.4273 10.8729 18.4273 11.0911 18.2819L15.6002 15.6637C15.8184 15.5182 16.0002 15.191 16.0002 14.9364Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M71.01 29.37C70.615 29.37 70.2325 29.31 69.8625 29.19C69.4975 29.065 69.21 28.905 69 28.71L69.2775 28.125C69.4775 28.305 69.7325 28.4525 70.0425 28.5675C70.3575 28.6775 70.68 28.7325 71.01 28.7325C71.445 28.7325 71.77 28.66 71.985 28.515C72.2 28.365 72.3075 28.1675 72.3075 27.9225C72.3075 27.7425 72.2475 27.5975 72.1275 27.4875C72.0125 27.3725 71.8675 27.285 71.6925 27.225C71.5225 27.165 71.28 27.0975 70.965 27.0225C70.57 26.9275 70.25 26.8325 70.005 26.7375C69.765 26.6425 69.5575 26.4975 69.3825 26.3025C69.2125 26.1025 69.1275 25.835 69.1275 25.5C69.1275 25.22 69.2 24.9675 69.345 24.7425C69.495 24.5175 69.72 24.3375 70.02 24.2025C70.32 24.0675 70.6925 24 71.1375 24C71.4475 24 71.7525 24.04 72.0525 24.12C72.3525 24.2 72.61 24.315 72.825 24.465L72.5775 25.065C72.3575 24.925 72.1225 24.82 71.8725 24.75C71.6225 24.675 71.3775 24.6375 71.1375 24.6375C70.7125 24.6375 70.3925 24.715 70.1775 24.87C69.9675 25.025 69.8625 25.225 69.8625 25.47C69.8625 25.65 69.9225 25.7975 70.0425 25.9125C70.1625 26.0225 70.31 26.11 70.485 26.175C70.665 26.235 70.9075 26.3 71.2125 26.37C71.6075 26.465 71.925 26.56 72.165 26.655C72.405 26.75 72.61 26.895 72.78 27.09C72.955 27.285 73.0425 27.5475 73.0425 27.8775C73.0425 28.1525 72.9675 28.405 72.8175 28.635C72.6675 28.86 72.44 29.04 72.135 29.175C71.83 29.305 71.455 29.37 71.01 29.37Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M78.2482 28.6575V29.31H74.4382V24.06H78.1432V24.7125H75.1882V26.325H77.8207V26.9625H75.1882V28.6575H78.2482Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M81.714 27.495V29.31H80.9715V27.48L78.8865 24.06H79.689L81.369 26.8275L83.0565 24.06H83.799L81.714 27.495Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M86.9804 29.37C86.4554 29.37 85.9804 29.255 85.5554 29.025C85.1354 28.79 84.8054 28.47 84.5654 28.065C84.3254 27.655 84.2054 27.195 84.2054 26.685C84.2054 26.175 84.3254 25.7175 84.5654 25.3125C84.8054 24.9025 85.1379 24.5825 85.5629 24.3525C85.9879 24.1175 86.4629 24 86.9879 24C87.3979 24 87.7729 24.07 88.1129 24.21C88.4529 24.345 88.7429 24.5475 88.9829 24.8175L88.4954 25.29C88.1004 24.875 87.6079 24.6675 87.0179 24.6675C86.6279 24.6675 86.2754 24.755 85.9604 24.93C85.6454 25.105 85.3979 25.3475 85.2179 25.6575C85.0429 25.9625 84.9554 26.305 84.9554 26.685C84.9554 27.065 85.0429 27.41 85.2179 27.72C85.3979 28.025 85.6454 28.265 85.9604 28.44C86.2754 28.615 86.6279 28.7025 87.0179 28.7025C87.6029 28.7025 88.0954 28.4925 88.4954 28.0725L88.9829 28.545C88.7429 28.815 88.4504 29.02 88.1054 29.16C87.7654 29.3 87.3904 29.37 86.9804 29.37Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M94.8679 24.06V29.31H94.1179V26.97H91.1029V29.31H90.3529V24.06H91.1029V26.3175H94.1179V24.06H94.8679Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M100.549 28.6575V29.31H96.7393V24.06H100.444V24.7125H97.4893V26.325H100.122V26.9625H97.4893V28.6575H100.549Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M102.064 24.06H102.814V28.6575H105.656V29.31H102.064V24.06Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M106.817 24.06H107.567V28.6575H110.409V29.31H106.817V24.06Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M115.38 28.6575V29.31H111.57V24.06H115.275V24.7125H112.32V26.325H114.952V26.9625H112.32V28.6575H115.38Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M118.424 29.37C118.029 29.37 117.647 29.31 117.277 29.19C116.912 29.065 116.624 28.905 116.414 28.71L116.692 28.125C116.892 28.305 117.147 28.4525 117.457 28.5675C117.772 28.6775 118.094 28.7325 118.424 28.7325C118.859 28.7325 119.184 28.66 119.399 28.515C119.614 28.365 119.722 28.1675 119.722 27.9225C119.722 27.7425 119.662 27.5975 119.542 27.4875C119.427 27.3725 119.282 27.285 119.107 27.225C118.937 27.165 118.694 27.0975 118.379 27.0225C117.984 26.9275 117.664 26.8325 117.419 26.7375C117.179 26.6425 116.972 26.4975 116.797 26.3025C116.627 26.1025 116.542 25.835 116.542 25.5C116.542 25.22 116.614 24.9675 116.759 24.7425C116.909 24.5175 117.134 24.3375 117.434 24.2025C117.734 24.0675 118.107 24 118.552 24C118.862 24 119.167 24.04 119.467 24.12C119.767 24.2 120.024 24.315 120.239 24.465L119.992 25.065C119.772 24.925 119.537 24.82 119.287 24.75C119.037 24.675 118.792 24.6375 118.552 24.6375C118.127 24.6375 117.807 24.715 117.592 24.87C117.382 25.025 117.277 25.225 117.277 25.47C117.277 25.65 117.337 25.7975 117.457 25.9125C117.577 26.0225 117.724 26.11 117.899 26.175C118.079 26.235 118.322 26.3 118.627 26.37C119.022 26.465 119.339 26.56 119.579 26.655C119.819 26.75 120.024 26.895 120.194 27.09C120.369 27.285 120.457 27.5475 120.457 27.8775C120.457 28.1525 120.382 28.405 120.232 28.635C120.082 28.86 119.854 29.04 119.549 29.175C119.244 29.305 118.869 29.37 118.424 29.37Z'
      fill='var(--logo-text-color)'
    />
  </svg>
)
