import { IconProps } from './IconProps'

export function MessengerIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2C6.3675 2 2 6.12739 2 11.6997C2 14.6147 3.195 17.1346 5.14 18.8745C5.3025 19.0195 5.4025 19.2245 5.4075 19.4445L5.4625 21.2245C5.4665 21.3554 5.50262 21.4834 5.56767 21.5972C5.63271 21.7109 5.72471 21.8069 5.83556 21.8768C5.94641 21.9467 6.07272 21.9882 6.2034 21.9978C6.33407 22.0075 6.46511 21.9848 6.585 21.932L8.57 21.057C8.7375 20.982 8.9275 20.9695 9.105 21.017C10.0175 21.267 10.9875 21.402 12 21.402C17.6325 21.402 22 17.2746 22 11.7022C22 6.12989 17.6325 2 12 2Z'
        fill='url(#paint0_radial_885_257269)'
      />
      <path
        d='M6.07641 14.3289L8.97377 10.0517C9.08276 9.89066 9.22645 9.7524 9.39576 9.64565C9.56507 9.53889 9.75632 9.46597 9.95744 9.43147C10.1586 9.39697 10.3652 9.40164 10.5643 9.4452C10.7634 9.48876 10.9506 9.57026 11.1141 9.68455L13.4197 11.2931C13.5226 11.3648 13.6478 11.4034 13.7764 11.403C13.905 11.4025 14.0299 11.3632 14.1323 11.2908L17.2442 9.09253C17.6584 8.79881 18.2009 9.26233 17.9247 9.67308L15.0249 13.948C14.9159 14.1091 14.7722 14.2473 14.6029 14.3541C14.4336 14.4608 14.2424 14.5337 14.0412 14.5682C13.8401 14.6027 13.6335 14.5981 13.4344 14.5545C13.2353 14.511 13.0481 14.4295 12.8846 14.3152L10.579 12.7066C10.4761 12.6349 10.3509 12.5963 10.2223 12.5968C10.0937 12.5972 9.96879 12.6365 9.8664 12.7089L6.75451 14.9072C6.34025 15.2009 5.79777 14.7397 6.07641 14.3289Z'
        fill='white'
      />
      <defs>
        <radialGradient
          id='paint0_radial_885_257269'
          cx='0'
          cy='0'
          r='1'
          gradientUnits='userSpaceOnUse'
          gradientTransform='translate(5.35 21.9995) scale(22 21.9994)'
        >
          <stop stop-color='#0099FF' />
          <stop offset='0.6' stop-color='#A033FF' />
          <stop offset='0.9' stop-color='#FF5280' />
          <stop offset='1' stop-color='#FF7061' />
        </radialGradient>
      </defs>
    </svg>
  )
}
