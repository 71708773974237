import { useTranslation } from 'react-i18next'

import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { Modal } from '../../global/modal/Modal'

interface Props {
  setResubscribeModal(visible: boolean): void
  handleOnClickResubscribeConfirm(): void
  amount: number | undefined
}
export const ResubscribeModal: React.FC<Props> = ({
  setResubscribeModal,
  handleOnClickResubscribeConfirm,
  amount,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={() => setResubscribeModal(false)}
      render={({ closeModal }) => (
        <CancelActionModal
          title={t('Subscriptions.Resubscribe') + '?'}
          onCancel={closeModal}
          confirmLabel={t('Subscriptions.Resubscribe')}
          cancelLabel={t('Cancel')}
          onConfirm={handleOnClickResubscribeConfirm}
        >
          <p className='text'>
            {t('Market Data.You subscription will be Active again', {
              amount: amount,
            })}
          </p>
        </CancelActionModal>
      )}
    />
  )
}
