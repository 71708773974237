import { AccountDetailedDto, ClientProductState } from '../../model/AccountDetailedDto'
import { AccountDetailedStatus } from '../../model/AccountDetailedStatus'
import { TickmillProductType } from '../../model/TickmillProductType'

export const isProductAppropriatenessValid = (
  product: TickmillProductType,
  account: AccountDetailedDto | undefined
): boolean => {
  if (!account) {
    return false
  }

  return (
    !hasClearedAppropriatenessTest(account, product) ||
    isProductAppropriatenessTestFailed(product, account) ||
    hasProductDormantStatus(account, product) ||
    hasProductClosedStatus(account, product) ||
    hasProductEmailVerifiedStatus(account, product)
  )
}

export const isProductAppropriatenessTestFailed = (
  product: TickmillProductType,
  account: AccountDetailedDto | undefined
): boolean => {
  return (
    hasProductAppropriatenessTestSubmittedStatus(account, product) &&
    !hasClearedAppropriatenessTest(account, product)
  )
}

export const isProductAppropriatenessTestNotFailed = (
  product: TickmillProductType,
  account: AccountDetailedDto | undefined
): boolean => {
  return (
    hasProductAppropriatenessTestSubmittedStatus(account, product) &&
    hasClearedAppropriatenessTest(account, product)
  )
}

export const hasClearedAppropriatenessTest = (
  account: AccountDetailedDto | undefined,
  tickmillProductType: TickmillProductType
): boolean => {
  return evaluateProductStateAttribute(
    account,
    tickmillProductType,
    (productState) => !!productState.passedAppropriatenessTest
  )
}

export const isAppropriatenessTestMaximumRetakeLimitReached = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean => {
  if (!account) {
    return false
  }

  const productState = account.clientProductStates?.find(
    (state) => state.tickmillProduct.id === product
  )

  return !!productState?.appropriatenessTestMaximumRetakeLimitReached
}

export const hasSubmittedAppropriatenessTest = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean => {
  return evaluateProductStateAttribute(
    account,
    product,
    (productState) => !!productState.submittedAppropriatenessTest
  )
}

export const hasProductEmailVerifiedStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType | undefined
): boolean => {
  return evaluateProductStateAttribute(
    account,
    product,
    (productState) => productState.calculatedStatus.id === AccountDetailedStatus.EmailVerified
  )
}

export const isProductCalculatedStatusEmailVerified = (
  account: AccountDetailedDto | undefined,
  isProductTypeCFD: boolean
): boolean => {
  if (!account) {
    return false
  }

  const targetProductType = isProductTypeCFD ? TickmillProductType.CFD : TickmillProductType.ETD
  const productState = account.clientProductStates?.find(
    (state) => state.tickmillProduct.id === targetProductType
  )

  return productState?.calculatedStatus.id === AccountDetailedStatus.EmailVerified
}

export const hasProductDormantStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType | undefined
): boolean => {
  return evaluateProductStateAttribute(
    account,
    product,
    (productState) => productState.calculatedStatus.id === AccountDetailedStatus.Dormant
  )
}

export const hasProductClosedStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean => {
  return evaluateProductStateAttribute(
    account,
    product,
    (productState) => productState.calculatedStatus.id === AccountDetailedStatus.Closed
  )
}

export const hasProductAppropriatenessTestSubmittedStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType | undefined
): boolean => {
  return evaluateProductStateAttribute(
    account,
    product,
    (productState) =>
      productState.calculatedStatus.id === AccountDetailedStatus.AppropriatenessTestSubmitted
  )
}

export const hasProductActivatedStatus = (
  account: AccountDetailedDto | undefined,
  productType: TickmillProductType | undefined
): boolean => {
  return evaluateProductStateAttribute(
    account,
    productType,
    (productState) => productState.calculatedStatus.id === AccountDetailedStatus.Activated
  )
}

export const isProductRegistered = (
  account: AccountDetailedDto | undefined,
  productType: TickmillProductType
): boolean => {
  return evaluateProductStateAttribute(account, productType, () => true)
}

export const isTargetProductRegistered = (
  account: AccountDetailedDto | undefined,
  isProductTypeCFD: boolean
): boolean => {
  if (!account) {
    return false
  }

  const targetProduct = isProductTypeCFD ? TickmillProductType.ETD : TickmillProductType.CFD
  return isProductRegistered(account, targetProduct)
}

export const areAllProductRequirementsMet = (
  account: AccountDetailedDto | undefined,
  tickmillProductType: TickmillProductType
): boolean => {
  return evaluateProductStateAttribute(
    account,
    tickmillProductType,
    (productState) => !!productState.allRequirementsMet
  )
}

export const determineProductState = (
  account: AccountDetailedDto | undefined,
  productType: TickmillProductType | undefined
): ClientProductState | undefined => {
  if (!account) {
    return undefined
  }

  return account.clientProductStates?.find(
    (productState) => productState.tickmillProduct.id === productType
  )
}

export const evaluateProductStateAttribute = (
  account: AccountDetailedDto | undefined,
  productType: TickmillProductType | undefined,
  attributeEvaluator: (productState: ClientProductState) => boolean
): boolean => {
  const productState = determineProductState(account, productType)
  return productState ? attributeEvaluator(productState) : false
}
