import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { AccountSummaryDto } from '../../model/AccountSummaryDto'
import { NameDto } from '../../model/NameDto'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { PageQuery } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { getCompanyName } from '../../utils/companyName.utils'
import { formatDate } from '../../utils/date.utils'
import { getAppliedFiltersLength } from '../../utils/filter.utils'
import { getCentralRowIdConditionally } from '../../utils/getItemId'
import { addSwapFreePostfix } from '../../utils/text'
import { IBNoData } from '../IBNoData'

interface IBClientsTableProps {
  data: AccountSummaryDto[]
  sortOptions?: NameDto<string>[]
  search: SearchResponseProps
}

interface SearchResponseProps {
  search: FilterQueryProps
  searchTerm: SearchTermState
  pageQuery: PageQuery | undefined
  setPageQuery(value: PageQuery): void
}

export const IBClientsTable: React.FC<IBClientsTableProps> = (props) => {
  const { data, search, sortOptions = [] } = props
  const { pageQuery, setPageQuery } = search

  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const { formatNumber, formatMoney } = useFormatNumber()
  const { t } = useTranslation()

  if (data.length <= 0) {
    return <NoData search={search} />
  }

  return (
    <Table>
      <thead>
        <tr>
          {sortOptions?.map((x) => (
            <SortHeader
              id={x.id}
              key={x.id}
              sort={pageQuery?.sort || undefined}
              sortOrder={pageQuery?.sortOrder}
              setSort={(sort, sortOrder) => {
                setPageQuery!({
                  ...pageQuery,
                  sort,
                  sortOrder,
                })
              }}
            >
              {x.name}
            </SortHeader>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((x, index) => (
          <tr key={x.login} {...getCentralRowIdConditionally(index, data.length, 'ib-clients')}>
            <td>
              <Link
                className='is-link'
                to='/dashboard/introducing-broker/income'
                state={{
                  TradingAccountName: x.login,
                }}
              >
                {x.login}
              </Link>
            </td>
            <td>{x.clientName}</td>
            <td>{addSwapFreePostfix(x.isSwapFree, x.accountType, t)}</td>
            <td>{formatDate(x.registrationDate, { formatType: dateFormat })}</td>
            <td>{formatNumber(x.volume)}</td>
            <td>{formatMoney(x.amount, x.currency)}</td>
            <td>{x.currency}</td>
            <td>{x.country}</td>
            <td>{x.tickmillCompany ? getCompanyName(x.tickmillCompany?.id) : null}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  )
}

interface NoDataProps {
  search: SearchResponseProps
}

export const NoData: React.FC<NoDataProps> = (props) => {
  const { search } = props

  const { t } = useTranslation()

  const areFiltersActive =
    getAppliedFiltersLength({
      ...search.search,
      ca_search_TradingAccountName: undefined,
    }) > 0

  if (search.searchTerm.searchTerm || areFiltersActive) {
    return <NoResults hideLink subtitle={t('No results')} />
  } else {
    return <IBNoData title={t('IB.No Clients yet')} />
  }
}
