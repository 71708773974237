import { NameDto } from './NameDto'
import { TickmillProductDto } from './TickmillProductDto'
import { TradingAccount } from './TradingAccount'

export type CurrencyType =
  | 'USD'
  | 'GBP'
  | 'EUR'
  | 'PLN'
  | 'CHF'
  | 'HKD'
  | 'SGD'
  | 'AUD'
  | 'CAD'
  | 'JPY'
  | 'CNH'
  | 'MYR'
  | 'ZAR'
  | 'BTC'
  | 'CRC'
  | 'CLP'

export interface WalletCurrency {
  id: CurrencyType
  name: string
}

export interface WalletType {
  id: 1 | 2 | 10 | 100
  name: 'FX/CFD' | 'ETD' | 'IB' | 'PA'
  tickmillProduct: TickmillProductDto
}

export enum WalletTypeEnum {
  CFD = 1,
  ETD = 2,
  IB = 10,
  PAYMENT_AGENT = 100,
}

export enum WalletStateEnum {
  Active = 1,
  NotActive = 2,
  Hidden = 3,
  Frozen = 4,
}

export const filterWalletsByType = (
  wallets: WalletDto[],
  excpectedType: WalletTypeEnum
): WalletDto[] => {
  return wallets.filter((x) => excpectedType === x.walletType.id)
}

export const isWalletETDType = (value: number): boolean => {
  return WalletTypeEnum.ETD === value
}

export const isWalletFX_CFDType = (value: number): boolean => {
  return WalletTypeEnum.CFD === value
}

export const isWalletPAType = (value: number): boolean => {
  return WalletTypeEnum.PAYMENT_AGENT === value
}

export const isWalletOrTradingAccount = (data: WalletDto | TradingAccount): data is WalletDto => {
  return 'walletType' in data
}

export const isWalletIBType = (value: number): boolean => {
  return WalletTypeEnum.IB === value
}

export type SimplifyWallet = Pick<WalletDto, 'id' | 'name' | 'currency' | 'depositAction'>

export interface WalletDepositAction {
  availableToDeposit: number
  canDeposit: boolean
}

export interface WalletDto {
  id: string
  name: string
  client: {
    id: string
    name: string
  }
  company: {
    id: number
    name: string
  }
  currency: WalletCurrency
  walletType: WalletType
  walletState: {
    id: number
    name: string
  }
  tradingAccounts: WalletTradingAccount[]
  balance: number
  reserved: number
  bonusReserved: number
  pending: number
  restrictions: NameDto[]
  createdDate: string
  isDefault: boolean
  depositAction: WalletDepositAction
}

export interface WalletTradingAccount {
  id: string
  name: string
  tickmillProduct: TickmillProductDto
  isMultiCurrency: boolean
}

export const isWallet = (
  data: WalletDto | { id: string; name: string } | undefined | null
): data is WalletDto => {
  return !!data?.id
}
