import { isTransactionTradingAccountBalanceConversionType } from '../utils/transaction.utils'
import { BonusType } from './BonusTransactionDto'
import { NameDto } from './NameDto'
import { PlatformTypeEnum } from './PlatformTypeEnum'
import { TransactionLinksDto } from './TransactionLinksDto'
import { CurrencyType, WalletDto } from './WalletDto'

export interface TradingAccountTransactionDto {
  id: string
  directionType: {
    id: 200 | 100
    name: 'Credit' | 'Debit'
  }
  amount: number
  tradingAccount: {
    id: string
    name: string
    tickmillProduct: {
      id: 1
      name: 'FX & CFDs'
    }
    isMultiCurrency: false
    platformType: NameDto<PlatformTypeEnum>
  }
  wallet: NameDto
  client: NameDto
  tickmillCompany: {
    id: 2
    name: string
  }
  currency: {
    id: CurrencyType
    name: string
  }
  currencyRate: 1.0
  otherAmount: 11.0
  otherCurrency: {
    id: CurrencyType
    name: string
  }
}

export interface TransactionDto {
  id: string
  amount: number
  referenceId: string
  type: {
    id: number
    name: string
  }
  state: {
    id: number
    name: string
  }
  groupReference?: {
    id: string
    name: string
  }
  linkedFrom: TransactionLinksDto[]
  linkedTo: TransactionLinksDto[]
  comment: string | null
  createdDate: string
  updatedDate: string
  reverseTransaction?: {
    id: string
    name: string
  }
  reversedTransactions?: {
    id: string
    name: string
    type: NameDto
    state: NameDto
  }[]
  wallet?: { id: string; name: string }
  walletTransactions: WalletTransactionDto[]
  tradingAccountTransactions: TradingAccountTransactionDto[]
}

export const isTransactionTradingAccountBalanceConversion = (transaction: TransactionDto) => {
  return isTransactionTradingAccountBalanceConversionType(transaction?.type.id)
}

export interface WalletTransactionDto {
  id: string
  directionType: {
    id: number
    name: string // Credit
  }
  otherCurrency: {
    id: CurrencyType
    name: string
  }
  amount: number
  bonusTransaction: {
    bonusReserved: number
    bonusTransactionStatus: { id: number; name: string }
    bonusType: BonusType
    expiryDate: string
    id: string
    lotsRequired: number
    lotsTraded: number
    tradedProfit: number
  }
  wallet: WalletDto
  currencyRate: number
  otherAmount: number
  paymentProviderTransaction: null | PaymentProviderDetails
  paymentAgentTransaction?: {
    account: string
    documentId: string
    id: string
    paymentAgent: { id: string; name: string }
    system: string
  }
  ibCommisionTransaction?: {
    introducingBroker: {
      name: string
      id: string
    }
  }
}

interface PaymentProviderDetails {
  id: string
  providerReference: null | any
  paymentProvider: {
    id: string
    name: string
    externalName: string
  }
  isConnectedToService: boolean
  currency: {
    id: string
    name: string
  }
  request: {
    [key: string]: string | null
    user_ip: string
    swift: string
    bank_name: string
    user_zip: string
    method_id: string
    reference: string
    user_city: string
    company_id: string
    user_email: string
    user_phone: string
    language_id: string
    user_gender: string
    user_address: string
    wallet_amount: string
    payment_amount: string
    user_birthdate: string
    user_last_name: string
    wallet_type_id: string
    user_country_id: string
    user_first_name: string
    external_account: null
    wallet_currency_id: CurrencyType
    payment_currency_id: CurrencyType
  } | null
  response: {
    update: {
      externalAccount?: string | null
      providerReference?: string | null
      providerResponse?: string | null
      paymentCurrencyId?: string | null
      details: {
        bankAddress: string | null
        bankBranch: string | null
        bankCode: string | null
        bankName: string | null
        billingCityCode: string | null
        billingCityName: string | null
        billingStateCode: string | null
        billingStateName: string | null
        cardMonth: string | null
        cardYear: string | null
        nameOfAccount: string | null
      } | null
    }
  } | null
}

export enum TransactionPaymentProviderType {
  Bank = 'Bank',
  Skrill = 'Skrill',
}

export interface TransactionStateHistoryDto {
  translation: {
    id: string
    name: string
  }
  history: {
    id: string
    reason: string | null
    createdDate: string
    transactionState: {
      id: number
      name: string
    }
    transactionStateReason: {
      id: number
      name: string
    }
  }[]
}

export enum TransferTypeEnum {
  WALLET_TO_WALLET = 1,
  WALLET_TO_TA = 2,
  TA_TO_WALLET = 3,
  TA_TO_TA = 4,
}
