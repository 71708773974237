import { PropsWithChildren, ReactNode, useState } from 'react'

import { Button } from '../button/Button'

interface ActionIconProps {
  renderOnHover?(): ReactNode
  renderOnClick(): ReactNode
  onClick: () => void
  className?: string
}

export const ActionIcon: React.FC<PropsWithChildren<ActionIconProps>> = ({
  onClick,
  renderOnClick,
  renderOnHover,
  children,
  className,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [isClicked, setIsClicked] = useState(false)

  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }

  const handleClick = () => {
    setIsClicked(true)
    onClick()
  }

  return (
    <Button
      className={className}
      appearance='plain'
      onClick={handleClick}
      onMouseOver={handleMouseEnter}
      onMouseOut={handleMouseLeave}
    >
      {isHovered && renderOnHover ? renderOnHover() : isClicked ? renderOnClick() : children}
    </Button>
  )
}
