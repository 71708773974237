import { NameDto } from '../model/NameDto'

export enum TradingAccountRestrictions {
  TRANSFER_FROM = 14,
  TRANSFER_TO = 15,
  LEVERAGE_DOWN = 21,
  LEVERAGE_UP = 22,
  CHANGE_INVESTOR_PASSWORD = 23,
  CHANGE_MASTER_PASSWORD = 24,
  RESET_INVESTOR_PASSWORD = 25,
  RESET_MASTER_PASSWORD = 26,
  // AUTOMATIC_TRANSFER_ID = 27,
  // AUTOMATIC_TRANSFER_OUT = 28
}

const TradingAccountRestriction: Record<TradingAccountRestrictions, string> = {
  [TradingAccountRestrictions.TRANSFER_FROM]: 'Transfer From',
  [TradingAccountRestrictions.TRANSFER_TO]: 'Transfer To',
  [TradingAccountRestrictions.LEVERAGE_DOWN]: 'Leverage Down',
  [TradingAccountRestrictions.LEVERAGE_UP]: 'Leverage Up',
  [TradingAccountRestrictions.CHANGE_INVESTOR_PASSWORD]: 'Change Investor Password',
  [TradingAccountRestrictions.CHANGE_MASTER_PASSWORD]: 'Change Master Password',
  [TradingAccountRestrictions.RESET_INVESTOR_PASSWORD]: 'Reset Investor Password',
  [TradingAccountRestrictions.RESET_MASTER_PASSWORD]: 'Reset Master Password',
  // [TradingAccountRestrictions.AUTOMATIC_TRANSFER_ID]: "Automatic Transfer In",
  // [TradingAccountRestrictions.AUTOMATIC_TRANSFER_OUT]: "Automatic Transfer Out",
}

export function isRestricted(
  restrictionId: TradingAccountRestrictions,
  restrictions: NameDto[] = []
) {
  return (
    !!TradingAccountRestriction[restrictionId] &&
    !!restrictions.find((x) => x.name === TradingAccountRestriction[restrictionId])
  )
}

// 3: {id: 14, name: "Transfer From"}
// 1: {id: 15, name: "Transfer To"}
// 4: {id: 21, name: "Leverage Down"}
// 2: {id: 22, name: "Leverage Up"}
// 6: {id: 23, name: "Change Investor Password"}
// 7: {id: 24, name: "Change Master Password"}
// 5: {id: 25, name: "Reset Investor Password"}
// 0: {id: 26, name: "Reset Master Password"}

export function isTradingAccountSuspended(restrictions: NameDto[]) {
  return (
    !!restrictions.find((x) => x.id === TradingAccountRestrictions.TRANSFER_FROM) &&
    !!restrictions.find((x) => x.id === TradingAccountRestrictions.TRANSFER_TO) &&
    !!restrictions.find((x) => x.id === TradingAccountRestrictions.LEVERAGE_DOWN) &&
    !!restrictions.find((x) => x.id === TradingAccountRestrictions.LEVERAGE_UP) &&
    !!restrictions.find((x) => x.id === TradingAccountRestrictions.CHANGE_INVESTOR_PASSWORD) &&
    !!restrictions.find((x) => x.id === TradingAccountRestrictions.CHANGE_MASTER_PASSWORD) &&
    !!restrictions.find((x) => x.id === TradingAccountRestrictions.RESET_INVESTOR_PASSWORD) &&
    !!restrictions.find((x) => x.id === TradingAccountRestrictions.RESET_MASTER_PASSWORD)
  )
  // && !!restrictions.find((x) => x.id === TradingAccountRestrictions.AUTOMATIC_TRANSFER_ID)
  // && !!restrictions.find((x) => x.id === TradingAccountRestrictions.AUTOMATIC_TRANSFER_OUT)
}
