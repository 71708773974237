import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Loading } from '../../../../global/Loading/Loading'
import { Button } from '../../../../global/button/Button'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { AgentInformation, useAgentInformationFetch } from '../../../WalletAgentInformation'
import { FormTermsConditionsPaymentAgent } from './FormTermsConditionsFactory'
import { TradingAccountDepositFormValues } from './TradingAccountDepositForm'

interface TradingAccountDepositPaymentAgentFieldsProps {
  onCancel(): void
  onClickTermsConditions(): void
}

export const TradingAccountDepositPaymentAgentFields: React.FC<
  TradingAccountDepositPaymentAgentFieldsProps
> = (props) => {
  const { onCancel, onClickTermsConditions } = props

  const apiClient = useApiClient(ClientApiClient)

  const { values } = useFormikContext<TradingAccountDepositFormValues>()

  const { paymentAgent, isLoading, forceRefresh } = useAgentInformationFetch(
    values.tradingAccount.wallet.id!,
    values.paymentProvider.id!
  )

  const handleExitPageConfirmationModalOpen = () => {
    onCancel()
  }

  const handlePaymentAcceptAgentTerms = async () => {
    if (values.paymentProvider.id) {
      await apiClient.paymentAgentAcceptTerms(values.paymentProvider.id)
      forceRefresh()
    }
  }

  const { t } = useTranslation()

  return (
    <Loading isLoading={isLoading}>
      {paymentAgent && (
        <React.Fragment>
          <AgentInformation type='deposit' paymentAgent={paymentAgent} />

          <FormTermsConditionsPaymentAgent
            paymentAgent={paymentAgent}
            onClickTermsConditions={onClickTermsConditions}
          />

          <TransactionFormActionButtonTemplate>
            <Button
              appearance='secondary'
              size='L'
              type='button'
              onClick={handleExitPageConfirmationModalOpen}
            >
              {t('Cancel')}
            </Button>
            {!paymentAgent.powerOfAttorney.accepted && (
              <Button
                appearance='primary'
                size='L'
                type='button'
                onClick={handlePaymentAcceptAgentTerms}
                disabled={!values.terms.paymentOperations || !values.terms.powerOfAttorney}
              >
                {t('Trading Account.Accept Agent')}
              </Button>
            )}
          </TransactionFormActionButtonTemplate>
        </React.Fragment>
      )}
    </Loading>
  )
}
