import { IconProps, iconColor } from './IconProps'

export function ChevronRightIcon(props: IconProps) {
  const size = props.size || 12

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 8 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
      onClick={props.onClick}
    >
      <path
        d='M0.699994 2.1C0.313395 1.7134 0.313395 1.0866 0.699994 0.7C1.08659 0.313401 1.71339 0.313401 2.09999 0.7L7.39999 6L2.09999 11.3C1.71339 11.6866 1.08659 11.6866 0.699994 11.3C0.313395 10.9134 0.313395 10.2866 0.699994 9.9L4.59999 6L0.699994 2.1Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
