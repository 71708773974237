import React from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '../../../../global/button/Button'
import { FieldGroup } from '../../../../global/form/FieldGroup'
import { createFormField } from '../../../../global/formField/FormField'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import { FormTermsConditionsPSP } from './FormTermsConditionsFactory'

import styles from './WalletDepositForm.module.scss'

const FormField = createFormField()

interface WalletDepositProviderNoneFieldsProps {
  onCancel(): void
  onClickTermsConditions(): void
}

export const WalletDepositProviderNoneFields: React.FC<WalletDepositProviderNoneFieldsProps> = (
  props
) => {
  const { onCancel, onClickTermsConditions } = props

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <FieldGroup>
        <FormField
          name='amount'
          label={t('Wallet.Amount')}
          placeholder={t('Wallet.Amount')}
          disabled
          rightIcon={<DropArrowDownIcon />}
        />
        <div className={styles.currencyFieldWrapper}>
          <FormField
            name='paymentProvider.currency.id'
            label={t('Currency')}
            disabled
            rightIcon={<DropArrowDownIcon />}
          />
        </div>
      </FieldGroup>

      <FormField
        name='comment'
        label={t('Wallet.Additional Comment')}
        placeholder={t('Wallet.Additional Comment')}
        disabled
      />

      <div className='my-5'>
        <FormTermsConditionsPSP onClickTermsConditions={onClickTermsConditions} />
      </div>

      <TransactionFormActionButtonTemplate>
        <Button appearance='secondary' size='L' type='button' onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button appearance='primary' size='L' type='button' disabled>
          {t('Confirm')}
        </Button>
      </TransactionFormActionButtonTemplate>
    </React.Fragment>
  )
}
