import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Tabs, defineTab } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { isNoTabsPath } from '../utils/path'
import { useLearningRoutesConditions } from './LearningRoutes'

import styles from './Learning.module.scss'

export const LearningETD: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const currentTab = useCurrentTab('learning')

  const { isLearningVisible, isGettingStartedVisible } = useLearningRoutesConditions()

  function* getTabs() {
    if (isLearningVisible) {
      if (isGettingStartedVisible) {
        yield {
          ...defineTab('getting-started', t('Learning.Getting Started')),
        }
      }
      yield {
        ...defineTab('cqg-education-videos', t('Learning.CQG Education Videos')),
      }
      yield {
        ...defineTab('tutorial-videos', t('Learning.Tutorial Videos')),
      }
    }
  }

  const TabsDefinition = [...getTabs()]

  return (
    <div>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={currentTab}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/learning/${tab}`)}
          />
        </div>
      )}
      <Outlet />
    </div>
  )
}
