import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Tabs } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { ScrollToIds } from '../hooks/useScrollToElementIds'
import { PageHeader } from '../ui/Table/Header/PageHeader'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { useIBCampaignsResultsReadContext } from '../utils/IBCampaignsResultsContext'
import { isNoTabsPath } from '../utils/path'
import { useIBRoutesConditions } from './IntroducingBrokerRoutes'
import { IBSignUpPage } from './SignUp/IBSignUpPage'

import styles from './IntroducingBroker.module.scss'

export const IntroducingBroker: React.FC = () => {
  const location = useLocation()
  const currentTab = useCurrentTab('introducing-broker')
  const navigate = useNavigate()
  const visible = useIBRoutesConditions()
  const { account } = useAccountReadContext()

  const { t } = useTranslation()
  const { ibCampaignsResults } = useIBCampaignsResultsReadContext()

  const TabsDefinition = [
    // { name: t('Tabs.Dashboard'), id: 'dashboard' },
    { name: t('Tabs.Wallets'), id: 'wallets' },
    { name: t('Tabs.Clients'), id: 'clients' },
    { name: t('Tabs.Income'), id: 'income' },
    {
      name: t('Tabs.Reports'),
      id: 'reports',
    },
    {
      name: t('Tabs.Transaction History'),
      id: 'transaction-history',
    },
    {
      name: t('Tabs.Loyalty Program'),
      id: 'loyalty-program',
    },

    {
      name: t('Tabs.Legal Documents'),
      id: 'legal-documents',
    },
  ]

  if (visible.isMultiTierVisible) {
    TabsDefinition.push({
      name: t('Tabs.Multi-Tier'),
      id: 'multi-tier',
    })
  }

  if (visible.isReferralMaterialsVisible) {
    TabsDefinition.push({
      name: t('Tabs.Referral Materials'),
      id: 'referral-materials',
    })
  }

  if (ibCampaignsResults.length) {
    TabsDefinition.push({
      name: t('Tabs.IB Contest'),
      id: 'ib-contest',
    })
  }

  if (!account?.clientIntroducingBroker?.id) {
    return (
      <div className='pt-5'>
        <PageHeader title={t('IB.Introducing Broker Program')} id={ScrollToIds.IBSignupHeader} />
        <IBSignUpPage />
      </div>
    )
  }

  return (
    <React.Fragment>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={currentTab}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/introducing-broker/${tab}`)}
          />
        </div>
      )}
      <Outlet />
    </React.Fragment>
  )
}
