import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Tabs } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { isNoTabsPath } from '../utils/path'

import styles from './Tools.module.scss'

export const Tools: React.FC = () => {
  const { t } = useTranslation()

  const currentTab = useCurrentTab('tools')
  const navigate = useNavigate()
  const location = useLocation()
  const TabsDefinition = [{ name: t('Platforms'), id: 'platforms', disabled: false }]

  return (
    <div>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={currentTab}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/tools/${tab}`)}
          />
        </div>
      )}
      <Outlet />
    </div>
  )
}
