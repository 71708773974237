import styles from './Icons.module.scss'

export function TradingAccountIcon(props: { size?: number }) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 25 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='7' r='4' stroke={styles.contrastMediumLow} strokeWidth='1' />
      <path
        d='M4 20.9998C4 20.9998 4.48632 19.4808 5.14286 17.3331C5.58564 15.8847 7.59438 15.3286 9.5 15.1201L11.5 15'
        stroke={styles.contrastMediumLow}
        strokeWidth='1'
        strokeLinecap='round'
      />
      <path
        d='M19.9682 15.4545C19.7586 14.686 18.7682 14.2213 17.9996 14.2273M17.9996 14.2273C17.0153 14.2351 15.7859 15.2822 16.0318 16.2727C16.4385 17.9107 19.5615 17.0896 19.9682 18.7273C20.2141 19.7178 18.9839 20.765 17.9996 20.7728M17.9996 14.2273L17.9681 13M17.9996 20.7728C17.231 20.7788 16.2414 20.314 16.0318 19.5455M17.9996 20.7728V22'
        stroke={styles.contrastMediumLow}
        strokeWidth='1'
        strokeLinecap='round'
      />
    </svg>
  )
}
