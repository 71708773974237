import { NameDto } from './NameDto'

export interface WalletPaymentAgentProfileDto {
  id: string
  firstName: string | null
  lastName: string | null
  email: string | null
  phone: string | null
  socialMedia: WalletPaymentAgentProfileSocialMediaDto[] | null
}

export enum SocialMediaTypes {
  WhatsApp = 1,
  Facebook = 2,
  Instagram = 3,
  Telegram = 4,
  TikTok = 5,
}

export interface WalletPaymentAgentProfileSocialMediaDto {
  id: string
  socialMediaType: NameDto
  value: string | null
}
