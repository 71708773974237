import { IconProps, iconColor } from './IconProps'

export function LogoAppleIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.4246 2C15.7876 4.18 13.5126 5.83 12.2406 6.571C11.8656 6.79 11.4416 6.511 11.5066 6.082C11.7236 4.64 12.5186 2 15.4246 2Z'
        stroke={iconColor(props)}
        strokeWidth='1.5'
      />
      <path
        d='M8.5 6.5C9.397 6.5 10.19 6.7 10.794 6.92C11.556 7.199 12.444 7.199 13.206 6.92C13.9404 6.64757 14.7167 6.50543 15.5 6.5C16.585 6.5 17.965 7.089 19 8.267C15.5 11 16.5 15.5 19.769 16.692C18.725 19.559 16.741 21.5 14.999 21.5C13.499 21.5 13.5 20.8 12 20.8C10.5 20.8 10.5 21.5 9 21.5C6.5 21.5 3.5 17.5 3.5 12.5C3.5 8.5 6.5 6.5 8.5 6.5V6.5Z'
        stroke={iconColor(props)}
        strokeWidth='1.5'
      />
    </svg>
  )
}
