import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { PasswordIcon } from '../../icons/PasswordIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { NameDto } from '../../model/NameDto'
import { TradingAccountRestrictions, isRestricted } from '../../utils/trading-account.utils'

import styles from './ManagePasswordsModal.module.scss'

interface Props {
  onCancel: () => void
  setResetPassword: (visible: boolean) => void
  handleChangeTradingAccountPassword: () => void
  handleChangeInvestorPassword: () => void
  restrictions: NameDto[]
  setChangeInvestorPasswordRestrictionMessageModal: () => void
  setChangePasswordRestrictionMessageModal: () => void
  setResetPasswordRestrictionMessageModal: () => void
}

interface BodyProps {
  onCancel: () => void
  setResetPassword: (visible: boolean) => void
  handleChangeTradingAccountPassword: () => void
  handleChangeInvestorPassword: () => void
  restrictions: NameDto[]

  setChangeInvestorPasswordRestrictionMessageModal: () => void
  setChangePasswordRestrictionMessageModal: () => void
  setResetPasswordRestrictionMessageModal: () => void
}
const ManagePasswordsModalBody: React.FC<BodyProps> = ({
  onCancel,
  setResetPassword,
  handleChangeTradingAccountPassword,
  handleChangeInvestorPassword,
  restrictions,
  setChangeInvestorPasswordRestrictionMessageModal,
  setChangePasswordRestrictionMessageModal,
  setResetPasswordRestrictionMessageModal,
}) => {
  const hasResetMasterPasswordRestriction = isRestricted(
    TradingAccountRestrictions.RESET_MASTER_PASSWORD,
    restrictions
  )
  const hasChangeMasterPasswordRestriction = isRestricted(
    TradingAccountRestrictions.CHANGE_MASTER_PASSWORD,
    restrictions
  )
  const hasChangeInvestorPasswordRestriction = isRestricted(
    TradingAccountRestrictions.CHANGE_INVESTOR_PASSWORD,
    restrictions
  )

  const { t } = useTranslation()

  return (
    <section className={classNames(styles.body, 'modal-card-body')}>
      <ul>
        <li
          onClick={() => {
            if (hasResetMasterPasswordRestriction) {
              return setResetPasswordRestrictionMessageModal()
            }
            setResetPassword(true)
            onCancel()
          }}
        >
          <div className={styles.wrapper}>
            <div>
              <PasswordIcon />
            </div>
            <div>{t('Trading Account.Reset Trading Account Password')}</div>
            {hasResetMasterPasswordRestriction && (
              <>
                <div className={styles.restricted}>{t('(Restricted)')}</div>
                <div className={styles.errorIcon}>
                  <WarningIcon size={24} color={'error'} />
                </div>
              </>
            )}
          </div>
        </li>
        <li
          onClick={() => {
            if (hasChangeMasterPasswordRestriction) {
              return setChangePasswordRestrictionMessageModal()
            }
            handleChangeTradingAccountPassword()
            onCancel()
          }}
        >
          <div className={styles.wrapper}>
            <div>
              <PasswordIcon />
            </div>
            <div>{t('Trading Account.Change Trading Account Password')}</div>
            {hasChangeMasterPasswordRestriction && (
              <>
                <div className={styles.restricted}>{t('(Restricted)')}</div>
                <div className={styles.errorIcon}>
                  <WarningIcon size={24} color={'error'} />
                </div>
              </>
            )}
          </div>
        </li>
        <li
          onClick={() => {
            if (hasChangeInvestorPasswordRestriction) {
              return setChangeInvestorPasswordRestrictionMessageModal()
            }
            handleChangeInvestorPassword()
          }}
        >
          <div className={styles.wrapper}>
            <div>
              <PasswordIcon />
            </div>
            <div>{t('Trading Account.Change Investor Password')}</div>
            {hasChangeInvestorPasswordRestriction && (
              <>
                <div className={styles.restricted}>{t('(Restricted)')}</div>
                <div className={styles.errorIcon}>
                  <WarningIcon color={'error'} />
                </div>
              </>
            )}
          </div>
        </li>
      </ul>
    </section>
  )
}

export const ManagePasswordsModal: React.FC<Props> = ({
  onCancel,
  setResetPassword,
  handleChangeTradingAccountPassword,
  handleChangeInvestorPassword,
  restrictions,
  setChangeInvestorPasswordRestrictionMessageModal,
  setChangePasswordRestrictionMessageModal,
  setResetPasswordRestrictionMessageModal,
}) => {
  const { t } = useTranslation()
  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('Trading Account.Manage Trading Account Passwords')}</p>
      </header>
      <ManagePasswordsModalBody
        handleChangeTradingAccountPassword={handleChangeTradingAccountPassword}
        handleChangeInvestorPassword={handleChangeInvestorPassword}
        setResetPassword={setResetPassword}
        onCancel={onCancel}
        restrictions={restrictions}
        setChangeInvestorPasswordRestrictionMessageModal={
          setChangeInvestorPasswordRestrictionMessageModal
        }
        setChangePasswordRestrictionMessageModal={setChangePasswordRestrictionMessageModal}
        setResetPasswordRestrictionMessageModal={setResetPasswordRestrictionMessageModal}
      />
      <footer className='modal-card-foot'>
        <button className='button' onClick={onCancel}>
          {t('Cancel')}
        </button>
      </footer>
    </>
  )
}
