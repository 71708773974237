import styles from './Icons.module.scss'

export function GettingStartedCongratulationsIcon() {
  return (
    <svg
      width='592'
      height='264'
      viewBox='0 0 592 264'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='592' height='264' rx='16' fill={styles.background} />
      <rect
        x='220'
        y='56'
        width='152'
        height='152'
        rx='76'
        stroke={styles.primary}
        strokeWidth='16'
        strokeDasharray='3 45'
      />
      <rect
        x='253.5'
        y='89.5'
        width='85'
        height='85'
        rx='42.5'
        stroke={styles.text}
        strokeWidth='3'
      />
      <path
        d='M275 131.222L289.509 146L317 118'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}
