import React, { useState } from 'react'

import {
  FilterOptions,
  FilterQueryModal,
  FilterQueryProps,
} from '../../global/filter/FilterQueryModal'
import { IDateFilter } from '../../utils/filter.utils'
import { ISubscriptionsHistoryQuery } from './SubscriptionsHistoryPageETD'

interface Props {
  currentQuery?: ISubscriptionsHistoryQuery
  onConfirm({
    searchFilters,
    currentFilter,
  }: {
    searchFilters?: FilterQueryProps
    currentFilter?: IDateFilter
  }): void
}

export const SubscriptionsHistoryFilterModal: React.FC<Props> = ({ onConfirm, currentQuery }) => {
  const [searchFilters, setSearchFilters] = useState(currentQuery)

  return (
    <FilterQueryModal
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      filterOptions={[
        FilterOptions.Date,
        FilterOptions.Price,
        FilterOptions.SubscriptionType,
        FilterOptions.SubscriptionLevel,
        FilterOptions.SubscriptionClassificationType,
        FilterOptions.SubscriptionStatus,
        FilterOptions.SubscriptionRequestType,
      ]}
      onConfirm={onConfirm}
    />
  )
}
