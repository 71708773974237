import { ReactElement } from 'react'

export const RedXIcon = (props: { size?: number }): ReactElement => {
  const size = props.size || 24
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
    >
      <circle cx='12' cy='12' r='10' fill='#EF0000' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M7.39886 7.39886C7.06692 7.73081 7.06692 8.269 7.39886 8.60094L15.8841 17.0862C16.2161 17.4182 16.7543 17.4182 17.0862 17.0862C17.4182 16.7543 17.4182 16.2161 17.0862 15.8841L8.60094 7.39886C8.269 7.06692 7.73081 7.06692 7.39886 7.39886Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.0862 7.39886C17.4182 7.73081 17.4182 8.269 17.0862 8.60094L8.60094 17.0862C8.269 17.4182 7.73081 17.4182 7.39886 17.0862C7.06692 16.7543 7.06692 16.2161 7.39886 15.8841L15.8841 7.39886C16.2161 7.06692 16.7543 7.06692 17.0862 7.39886Z'
        fill='white'
      />
    </svg>
  )
}
