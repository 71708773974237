import { Color } from './index'

import styles from './Icons.module.scss'

export function EmailIcon(props: { color?: Color; size?: number; strokeWidth?: number }) {
  const size = props.size || 20
  const strokeWidth = props.strokeWidth || 1.5

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 22 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M4.5 4L11 9.5L17.5 4M2.5 15H19.5C20.0523 15 20.5 14.5523 20.5 14V2C20.5 1.44772 20.0523 1 19.5 1H2.5C1.94772 1 1.5 1.44772 1.5 2V14C1.5 14.5523 1.94771 15 2.5 15Z'
        stroke={props.color ? styles[props.color] : styles.textSecondary}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  )
}
