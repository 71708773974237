import { IconProps, iconColor } from './IconProps'

export function LogoAndroidIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.5 9V18.5C17.5 18.7761 17.2761 19 17 19H15.5M17.5 9C17.5 6.57851 16.5655 4.49205 14.5 3.54404M17.5 9H6.5M6.5 9V18.5C6.5 18.7761 6.72386 19 7 19H8.5M6.5 9C6.5 6.57851 7.43447 4.49205 9.5 3.54404M14.5 3.54404C13.7389 3.19474 12.8922 3 12 3C11.1078 3 10.2611 3.19474 9.5 3.54404M14.5 3.54404L15.5 2M9.5 3.54404L8.5 2M8.5 19V21.5C8.5 22.0523 8.94772 22.5 9.5 22.5C10.0523 22.5 10.5 22.0523 10.5 21.5V19M8.5 19H10.5M10.5 19H13.5M15.5 19V21.5C15.5 22.0523 15.0523 22.5 14.5 22.5C13.9477 22.5 13.5 22.0523 13.5 21.5V19M15.5 19H13.5M20 9V15M4 9V15'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <circle cx='10.25' cy='6.5' r='0.75' fill={iconColor(props)} />
      <circle cx='13.75' cy='6.5' r='0.75' fill={iconColor(props)} />
    </svg>
  )
}
