import { useCallback, useContext } from 'react'

import { useEntitySettings } from '../global/context/EntityContext'
import { useSignup } from '../global/context/SignupContext'
import { useAccountWriteContext } from '../utils/AccountContextContext'
import { AuthSessionContext } from '../utils/AuthContext'
import { SharedContext, initialSharedContext } from '../utils/SharedContext'
import { getTickmillCompanyByHostname } from '../utils/companyName.utils'
import { resetHubSpotWidget } from '../utils/hubSpot'
import { clearLocalStorage } from '../utils/useStorage'

export const useLogout = (): { logout(): void } => {
  const [, setSharedState] = useContext(SharedContext)
  const { clearAccount } = useAccountWriteContext()
  const [, setAuth] = useContext(AuthSessionContext)
  const [, setEntity] = useEntitySettings()
  const { resetLead, resetValues } = useSignup()

  const logout = useCallback(async () => {
    setSharedState(initialSharedContext)
    clearAccount()
    clearLocalStorage()
    resetLead()
    resetValues()
    setAuth(null)
    resetHubSpotWidget()
    setEntity({ entity: getTickmillCompanyByHostname() })
  }, [clearAccount, resetValues, resetLead, setAuth, setEntity, setSharedState])

  return { logout }
}
