import { useState } from 'react'
import { AxiosError } from 'axios'

import { ResponseError } from './ApiClient'

interface Handlers<Request, Response> {
  onSuccess?(data: Response, request: Request, context: MutationContext): Promise<void> | void
  onError?(error: AxiosError): Promise<void> | void
}

export interface MutationContext {
  setLoading(value: boolean): void
}

export const useMutation = <Request, Response>(
  fn: (values: Request) => Promise<Response>,
  handlers?: Handlers<Request, Response>
) => {
  const [values, setValues] = useState<Request>()
  const [data, setData] = useState<Response | undefined>(undefined)
  const [isLoading, setLoading] = useState(false)

  const handleMutate = async (
    request: Request,
    mutateHandlers?: Handlers<Request, Response>
  ): Promise<Response> => {
    try {
      setLoading(true)

      const response = await fn(request)

      await handleSuccess<Request, Response>(
        request,
        response,
        { setLoading },
        handlers,
        mutateHandlers
      )

      setData(response)
      setValues(request)
      setLoading(false)

      return response
    } catch (error: unknown) {
      await handleError<Request, Response>(error as ResponseError, handlers, mutateHandlers)

      setValues(request)
      setLoading(false)
      throw error
    }
  }

  return {
    mutate: handleMutate,
    data,
    values,
    isLoading,
  }
}

const handleSuccess = async <Request, Response>(
  request: Request,
  response: Response,
  context: MutationContext,
  handlers?: Handlers<Request, Response>,
  mutateHandlers?: Handlers<Request, Response>
) => {
  if (mutateHandlers?.onSuccess) {
    await mutateHandlers.onSuccess(response, request, context)
  } else if (handlers?.onSuccess) {
    await handlers.onSuccess(response, request, context)
  }
}

const handleError = async <Request, Response>(
  error: ResponseError,
  handlers?: Handlers<Request, Response>,
  mutateHandlers?: Handlers<Request, Response>
) => {
  if (mutateHandlers?.onError) {
    await mutateHandlers.onError(error?.response)
  } else if (handlers?.onError) {
    await handlers.onError(error?.response)
  }
}
