import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Loading } from '../../../../global/Loading/Loading'
import { MasterTransaction } from '../../../../model/MasterTransaction'
import {
  isTransactionStatusSuccessful,
  isTransactionStatusUnSuccessful,
} from '../../../../model/TransactionStatus'
import { WalletTypeEnum } from '../../../../model/WalletDto'
import { WalletWithdrawFormValues } from '../WalletWithdrawForm/WalletWithdrawForm'
import { WalletWithdrawPage, useWalletWithdrawFetch } from './WalletWithdrawPage'

interface Props {
  walletType: WalletTypeEnum
}

export const TradersRoomWalletWithdrawPage: React.FC<Props> = (props) => {
  const { walletType } = props

  const navigate = useNavigate()

  const handlePageExitConfirmation = () => {
    navigate(`/dashboard/traders-room/wallets`)
  }

  const { data, isLoading } = useWalletWithdrawFetch({
    onCancel: handlePageExitConfirmation,
  })

  const handleSubmit = (data: MasterTransaction, values: WalletWithdrawFormValues) => {
    if (isTransactionStatusSuccessful(data.state.id)) {
      navigate(
        `/dashboard/traders-room/wallets/${values.wallet.id}/withdrawal/transaction/succeed`,
        {
          state: {
            data,
            providerCategoryId: values.paymentProvider.providerCategory.id,
          },
        }
      )
    }

    if (isTransactionStatusUnSuccessful(data.state.id)) {
      navigate(
        `/dashboard/traders-room/wallets/${values.wallet.id}/withdrawal/transaction/failed`,
        {
          state: {
            data,
            providerCategoryId: values.paymentProvider.providerCategory.id,
          },
        }
      )
    }
  }

  return (
    <Loading isLoading={isLoading}>
      {data && (
        <WalletWithdrawPage
          wallet={data}
          walletType={walletType}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
        />
      )}
    </Loading>
  )
}
