import styles from './Icons.module.scss'

export function CheckOutlinedIcon(props: { size?: number }) {
  const size = props.size || 64

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M26.9993 39.0002L21.166 33.1668C20.5217 32.5225 19.477 32.5225 18.8327 33.1668C18.1883 33.8112 18.1884 34.8558 18.8327 35.5002L26.9993 43.6668L45.8327 24.8335C46.477 24.1892 46.477 23.1445 45.8327 22.5002C45.1884 21.8558 44.1437 21.8558 43.4994 22.5002L26.9993 39.0002Z'
        fill={styles.success}
      />
      <rect
        x='1.5'
        y='1.5'
        width='61'
        height='61'
        rx='30.5'
        stroke={styles.success}
        strokeWidth='3'
      />
    </svg>
  )
}
