import { IconProps, iconColor } from './IconProps'

export function FormatPDFIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M22.4 13.2V12H18.8V18H20V15.6H21.8V14.4H20V13.2H22.4Z' fill={iconColor(props)} />
      <path
        d='M15.8 18H13.4V12H15.8C16.2773 12.0005 16.7348 12.1903 17.0723 12.5277C17.4098 12.8652 17.5995 13.3228 17.6 13.8V16.2C17.5995 16.6772 17.4098 17.1348 17.0723 17.4723C16.7348 17.8097 16.2773 17.9995 15.8 18ZM14.6 16.8H15.8C15.9591 16.7998 16.1116 16.7366 16.2241 16.6241C16.3366 16.5116 16.3999 16.3591 16.4 16.2V13.8C16.3999 13.6409 16.3366 13.4884 16.2241 13.3759C16.1116 13.2634 15.9591 13.2002 15.8 13.2H14.6V16.8Z'
        fill={iconColor(props)}
      />
      <path
        d='M11 12H8V18H9.2V16.2H11C11.3181 16.1995 11.6231 16.0729 11.848 15.848C12.0729 15.6231 12.1995 15.3181 12.2 15V13.2C12.1997 12.8818 12.0732 12.5768 11.8482 12.3518C11.6232 12.1268 11.3182 12.0003 11 12ZM9.2 15V13.2H11L11.0006 15H9.2Z'
        fill={iconColor(props)}
      />
      <path
        d='M18.9998 10V7.14301C19.0021 7.05853 18.9858 6.97455 18.9522 6.89687C18.9185 6.81919 18.8682 6.74964 18.8048 6.69302L14.2549 2.19307C14.1976 2.1303 14.1273 2.08054 14.0488 2.04725C13.9702 2.01395 13.8853 1.99791 13.7999 2.00022H4.29998C3.95552 2.00124 3.62546 2.13702 3.38189 2.37792C3.13832 2.61881 3.00103 2.94524 3 3.28592V20.7143C3 21.0553 3.13696 21.3823 3.38075 21.6234C3.62455 21.8645 3.9552 22 4.29998 22H18V20.7143H4.29998V3.28592H12.4999V7.14301C12.5009 7.48369 12.6382 7.81012 12.8818 8.05102C13.1254 8.29191 13.4554 8.42769 13.7999 8.42871H17.6998V10H18.9998ZM13.7999 7.14301V3.54306L17.4398 7.14301H13.7999Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
