import React from 'react'
import { useTranslation } from 'react-i18next'

import { TransactionFailedDialogueTemplate } from '../../../../global/templates/TransactionFailedDialogueTemplate'

interface WalletDepositTransactionFailedPageProps {
  onCancel?(): void
}

export const WalletDepositTransactionFailedPage: React.FC<
  WalletDepositTransactionFailedPageProps
> = ({ onCancel }) => {
  const { t } = useTranslation()

  return (
    <TransactionFailedDialogueTemplate title={t('Wallet.Funds not deposited')} goBack={onCancel} />
  )
}
