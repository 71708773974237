import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import { Loading } from '../global/Loading/Loading'
import { LocaleModule } from '../global/LocaleModule'
import { Nav } from '../global/nav/Nav'
import { Toast, ToastBanner } from '../global/toast/Toast'
import { SnackbarContainer } from '../ui/Popups/Snackbar/Snackbar'
import { PathHandler } from '../utils/PathHandler'

interface LayoutProps {
  toast?: Toast
  setCurrentPath: (path: string) => void
}

export const MinimalLayout: React.FC<LayoutProps> = ({ toast, setCurrentPath }) => {
  return (
    <div className='expander'>
      <SnackbarContainer />
      <Nav />
      <main id='mainContainer' className='container'>
        <Suspense fallback={<Loading isLoading showLoadingIcon />}>
          <ToastBanner of={toast} />
          <PathHandler setCurrentPath={setCurrentPath} />
          <LocaleModule />
          <Outlet />
        </Suspense>
      </main>
    </div>
  )
}
