import { useEffect } from 'react'

export const usePeriodic = (
  callback: () => void,
  time: { milliseconds?: number; seconds?: number; minutes?: number; hours?: number }
): void => {
  useEffect(() => {
    const interval = setInterval(() => {
      callback()
    }, (time.seconds || 0) * 1000 + (time.minutes || 0) * 60 * 1000 + (time.hours || 0) * 60 * 60 * 1000 + (time.milliseconds || 0))

    return () => clearInterval(interval)
  }, [callback, time.hours, time.milliseconds, time.minutes, time.seconds])
}
