export function TickmillBackgroundIcon() {
  return (
    <svg
      width='228'
      height='653'
      viewBox='0 0 228 653'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        opacity='0.2'
        d='M-198.302 257.363L-198.303 257.377V257.391V376.769L-341.5 459.205V190.396C-341.026 181.026 -338.38 171.894 -333.772 163.721C-329.164 155.548 -322.72 148.557 -314.947 143.299L-83.2426 6.87473C-75.1269 2.68586 -66.1259 0.5 -56.992 0.5C-47.8582 0.5 -38.8571 2.68585 -30.7415 6.8747C-30.7373 6.87684 -30.7332 6.87898 -30.729 6.88112L200.933 143.346C200.934 143.346 200.935 143.347 200.936 143.347C201.839 143.889 202.709 144.485 203.541 145.132L57.7621 229.129L-45.9157 168.976L-45.9277 168.969L-45.9401 168.963C-49.3705 167.22 -53.1642 166.311 -57.0122 166.311C-60.8603 166.311 -64.6539 167.22 -68.0843 168.963L-68.0967 168.969L-68.1088 168.976L-187.198 238.085L-187.211 238.092L-187.223 238.1C-190.444 240.225 -193.123 243.074 -195.047 246.418C-196.97 249.763 -198.085 253.511 -198.302 257.363ZM84.3022 395.637L84.3029 395.623V395.609V276.312L227.5 193.775V462.583C227.026 471.953 224.38 481.085 219.771 489.259C215.164 497.432 208.719 504.423 200.947 509.681L-30.7249 646.119C-30.7289 646.121 -30.7329 646.123 -30.737 646.125C-38.8527 650.314 -47.8539 652.5 -56.9879 652.5C-66.1218 652.5 -75.123 650.314 -83.2387 646.125C-83.2427 646.123 -83.2468 646.121 -83.2508 646.119L-314.923 509.681C-314.925 509.679 -314.927 509.678 -314.93 509.677C-315.82 509.113 -316.686 508.514 -317.527 507.88L-171.863 423.891L-68.1045 484.044L-68.0925 484.051L-68.0802 484.058C-64.6498 485.8 -60.8561 486.709 -57.0081 486.709C-53.16 486.709 -49.3663 485.8 -45.9359 484.058L-45.9236 484.051L-45.9115 484.044L73.1981 414.935L73.2106 414.928L73.2226 414.92C76.4463 412.793 79.1272 409.941 81.0511 406.592C82.9749 403.244 84.0883 399.492 84.3022 395.637Z'
        stroke='#8E8E8E'
      />
    </svg>
  )
}
