import React from 'react'
import { useNavigate } from 'react-router-dom'

import { WalletDepositTransactionCompletedPage } from './WalletDepositTransactionCompletedPage'

export const TradersRoomWalletDepositTransactionCompletedPage: React.FC = (props) => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/dashboard/traders-room/transaction-history')
  }

  return <WalletDepositTransactionCompletedPage {...props} onCancel={handleCancel} />
}
