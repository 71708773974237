import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Button } from '../../../../global/button/Button'
import { createFormCheckBoxField } from '../../../../global/formField/FormCheckBoxField'
import { WalletPaymentAgentTransferInformationDto } from '../../../../model/WalletPaymentAgentTransferInformationDto'
import { WalletWithdrawFormValues } from './WalletWithdrawForm'

import styles from './WalletWithdrawForm.module.scss'

const FormCheckBoxField = createFormCheckBoxField<WalletWithdrawFormValues>()

export const FormTermsConditionsPSP: FC<{ onClickTermsConditions(): void }> = ({
  onClickTermsConditions,
}) => {
  const { t } = useTranslation()

  const { values } = useFormikContext<WalletWithdrawFormValues>()

  const SC_FacilitaPay_PIX = 'abc449d5-b2a2-4a84-a065-2b167467dcf4'
  const SC_Pagsmile_Latin_America_BR_PIX = '308cee02-93f8-11ec-b909-0242ac120002'

  return (
    <React.Fragment>
      {(values.paymentProvider.id === SC_FacilitaPay_PIX ||
        values.paymentProvider.id === SC_Pagsmile_Latin_America_BR_PIX) && (
        <div className={styles.additionalInfo}>
          <p>
            <span className={styles.warningInfo}>
              {t(
                'Wallet.Warning your withdrawal will be processed using your CPF number as PIX key'
              )}
            </span>
          </p>
          <p className='mt-3'>
            <span className={styles.warningInfo}>
              {t(
                'Wallet.We will use the CPF number you provided during your registration with Tickmill'
              )}
            </span>
          </p>
        </div>
      )}
      <FormCheckBoxField isTextSmall name='terms.paymentOperations'>
        {t('I have read all instructions and agree with')}{' '}
        <Button type='button' appearance='link' onClick={onClickTermsConditions}>
          {t('Terms & Conditions')}
        </Button>{' '}
        {t('of payment operations')}
      </FormCheckBoxField>
    </React.Fragment>
  )
}

interface FormTermsConditionsPaymentAgentProps {
  paymentAgent: WalletPaymentAgentTransferInformationDto

  onClickTermsConditions(): void
}

export const FormTermsConditionsPaymentAgent: React.FC<FormTermsConditionsPaymentAgentProps> = (
  props
) => {
  const { paymentAgent, onClickTermsConditions } = props

  const { t } = useTranslation()

  const { values, setValues } = useFormikContext<WalletWithdrawFormValues>()

  useEffect(() => {
    setValues({
      ...values,
      terms: {
        ...values.terms,
        paymentOperations: paymentAgent.powerOfAttorney.accepted,
        powerOfAttorney: paymentAgent.powerOfAttorney.accepted,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAgent.powerOfAttorney.accepted])

  return (
    <React.Fragment>
      {!paymentAgent.powerOfAttorney.accepted && <br />}
      <FormCheckBoxField
        name='terms.powerOfAttorney'
        value={values.terms.powerOfAttorney}
        disabled={paymentAgent.powerOfAttorney.accepted}
      >
        {t('I have read, understood and consent to the')}{' '}
        <a
          href={paymentAgent.powerOfAttorneyUrl}
          target='_blank'
          rel='noreferrer'
          className='is-link'
        >
          {t('Payment Agent.Power of Attorney')}
        </a>
        {'.'}
      </FormCheckBoxField>
      <FormCheckBoxField
        name='terms.paymentOperations'
        value={values.terms.paymentOperations}
        disabled={paymentAgent.powerOfAttorney.accepted}
        isTextSmall
      >
        {t('I have read all instructions and agree with')}{' '}
        <Button type='button' appearance='link' onClick={onClickTermsConditions}>
          {t('Terms & Conditions')}
        </Button>{' '}
        {t('of payment operations')}
      </FormCheckBoxField>
    </React.Fragment>
  )
}
