import React from 'react'
import { useNavigate } from 'react-router-dom'

import { WalletWithdrawTransactionCompletedPage } from './WalletWithdrawTransactionCompletedPage'

export const TradersRoomWalletWithdrawTransactionCompletedPage: React.FC = (props) => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/dashboard/traders-room/transaction-history')
  }

  return <WalletWithdrawTransactionCompletedPage {...props} onCancel={handleCancel} />
}
