import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Table } from '../../global/table/Table'
import { SmartphoneIcon } from '../../icons/SmartphoneIcon'
import { TrashIcon } from '../../icons/TrashIcon'
import { ClientPhoneNumberDto } from '../../model/AccountDetailedDto'
import { TextSmall } from '../../ui/Typography/Typography'
import { PhoneNumberStatus } from './PhoneNumberStatus'

import styles from './PhoneNumbersTable.module.scss'

interface PhoneNumbersTableProps {
  phoneNumbers: ClientPhoneNumberDto[]
  isLoading: boolean
  deleteNumber(number: ClientPhoneNumberDto): void
  verifyNumber(number: ClientPhoneNumberDto): void
  makeNumberPrimary(number: ClientPhoneNumberDto): void
}

interface PhoneNumbersTableBodyProps {
  headers: string[]
  filteredPhoneNumbers: ClientPhoneNumberDto[]
  isPrimary?: boolean
}

export const PhoneNumbersTable: React.FC<PhoneNumbersTableProps> = (props) => {
  const { phoneNumbers } = props

  const { t } = useTranslation()

  const primaryNumbers = useMemo(
    () => phoneNumbers.filter((phoneNumber) => phoneNumber.isPrimary),
    [phoneNumbers]
  )
  const secondaryNumbers = useMemo(
    () => phoneNumbers.filter((phoneNumber) => !phoneNumber.isPrimary),
    [phoneNumbers]
  )

  return (
    <React.Fragment>
      <Table>
        <PhoneNumbersTableBody
          {...props}
          headers={[t('PhoneNumbers.Primary phone')]}
          isPrimary
          filteredPhoneNumbers={primaryNumbers}
        />
        <PhoneNumbersTableBody
          {...props}
          headers={[t('PhoneNumbers.Secondary phone')]}
          filteredPhoneNumbers={secondaryNumbers}
        />
      </Table>
    </React.Fragment>
  )
}

const PhoneNumbersTableBody = (props: PhoneNumbersTableBodyProps & PhoneNumbersTableProps) => {
  const {
    headers,
    filteredPhoneNumbers,
    isPrimary,
    verifyNumber,
    deleteNumber,
    makeNumberPrimary,
    isLoading,
  } = props

  const { t } = useTranslation()
  if (filteredPhoneNumbers.length) {
    return (
      <>
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredPhoneNumbers.map((phoneNumber) => (
            <tr key={phoneNumber.id}>
              <td
                className={styles.phoneColumn}
              >{`${phoneNumber.countryCode} ${phoneNumber.number}`}</td>
              <td className={styles.statusColumn}>
                <span>
                  <PhoneNumberStatus
                    shouldVerify={phoneNumber.shouldVerify}
                    isVerified={phoneNumber.isVerified}
                    showIcon
                    verify={() => verifyNumber(phoneNumber)}
                  />
                </span>
              </td>
              <td />
              {!isPrimary && !phoneNumber.isUsedIn2FA ? (
                <>
                  <td />
                  <td className={styles.primaryColumn}>
                    <div
                      className={styles.innerPrimary}
                      onClick={(e) => {
                        if (isLoading) {
                          e.preventDefault()
                        } else {
                          makeNumberPrimary(phoneNumber)
                        }
                      }}
                    >
                      <SmartphoneIcon color='textInteractive' />
                      <TextSmall className={styles.setPrimary}>
                        {t('PhoneNumbers.Set as primary')}
                      </TextSmall>
                    </div>
                  </td>
                  <td className={styles.deleteColumn}>
                    <span className='is-flex is-align-items-center'>
                      <span
                        className='has-cursor-pointer'
                        onClick={() => deleteNumber(phoneNumber)}
                      >
                        <TrashIcon color='error' />
                      </span>
                    </span>
                  </td>
                </>
              ) : (
                <>
                  <td>{phoneNumber.isUsedIn2FA && t('PhoneNumbers.2FA')}</td>
                  <td className={styles.primaryColumn}>
                    {!isPrimary && (
                      <div
                        className={styles.innerPrimary}
                        onClick={(e) => {
                          if (isLoading) {
                            e.preventDefault()
                          } else {
                            makeNumberPrimary(phoneNumber)
                          }
                        }}
                      >
                        <SmartphoneIcon color='textInteractive' />
                        <TextSmall className={styles.setPrimary}>
                          {t('PhoneNumbers.Set as primary')}
                        </TextSmall>
                      </div>
                    )}
                  </td>

                  <td />
                </>
              )}
            </tr>
          ))}
        </tbody>
      </>
    )
  }

  return null
}
