import React, { Dispatch, SetStateAction } from 'react'

import { LeverageTypeDto } from '../model/LeverageTypeDto'
import { NameDto } from '../model/NameDto'
import { Notification } from '../model/Notification'
import { SimplifyWallet, WalletType } from '../model/WalletDto'

export interface SharedState {
  walletTypes: WalletType[]
  wallets: SimplifyWallet[]
  tradingAccounts: NameDto<string>[]
  transactionTypes: NameDto[]
  transactionStates: NameDto[]
  leverageTypes: LeverageTypeDto[]
  notifications: Notification[]
  hasUnreadNotifications?: boolean
}

export type StateSharedState = SharedState
export type SharedContextType = [StateSharedState, Dispatch<SetStateAction<StateSharedState>>]

export const initialSharedContext: SharedState = {
  walletTypes: [],
  wallets: [],
  tradingAccounts: [],
  transactionTypes: [],
  transactionStates: [],
  leverageTypes: [],
  notifications: [],
  hasUnreadNotifications: undefined,
}

export const SharedContext = React.createContext<SharedContextType>([
  initialSharedContext,
  () => {},
])
