import { ReactNode, useEffect, useState } from 'react'
import { LiveChatWidget, WidgetState } from '@livechat/widget-react'

import { useSessionEntity } from '../global/context/EntityContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { Locale } from '../global/locale/Locale'
import { TickmillCompaniesEnum } from './companyName.utils'
import { useOnCloseModal, useOnEntitySelected, useOnOpenChat, useOnOpenModal } from './cookie.utils'
import { useWindowResize } from './domUtils'

export const useLiveChatWidget = (): ReactNode | null => {
  const [liveChatClientEmail, setLiveChatClientEmail] = useState<string | undefined>(undefined)
  const [liveChatClientName, setLiveChatClientName] = useState<string | undefined>(undefined)
  const [chatVisibility, setChatVisibility] = useState<WidgetState['visibility']>('minimized')
  const [widgetLoaded, setWidgetLoaded] = useState(false)
  const [liveChatGroup, setLiveChatGroup] = useState<string | undefined>(undefined)
  const entity = useSessionEntity()
  const locale = useSessionLanguage()
  const isMobile = useWindowResize()
  useOnOpenChat(() => {
    setChatVisibility('maximized')
  })

  useOnCloseModal(() => {
    const chatContainer = document.getElementById('chat-widget-container')
    if (chatContainer) {
      chatContainer.style.display = 'block'
    }
  })

  useOnOpenModal(() => {
    const chatContainer = document.getElementById('chat-widget-container')
    if (chatContainer && isMobile) {
      chatContainer.style.display = 'none'
    }
  })

  useEffect(() => {
    const entityRecord = EntityToGroupId[entity]
    if (entityRecord) {
      setLiveChatGroup(entityRecord[locale] ?? undefined)
    }
  }, [entity, locale])

  useOnEntitySelected((entity, locale, email, firstName) => {
    const entityRecord = EntityToGroupId[entity]
    if (entityRecord) {
      setLiveChatGroup(entityRecord[locale] ?? undefined)
    }
    if (email) {
      setLiveChatClientEmail(email)
    }
    if (firstName) {
      setLiveChatClientName(firstName)
    }
  })

  // if (entity === TickmillCompaniesEnum.TICKMILL_EU) {
  //   return null
  // }

  return (
    <LiveChatWidget
      customerEmail={liveChatClientEmail}
      customerName={liveChatClientName}
      sessionVariables={{ chatId: '1' }}
      onReady={() => {
        setWidgetLoaded(true)
      }}
      visibility={chatVisibility && widgetLoaded ? chatVisibility : 'minimized'}
      license='11464573'
      group={liveChatGroup}
      onVisibilityChanged={({ visibility }) => setChatVisibility(visibility)}
    />
  )
}

const EntityToGroupId: Record<TickmillCompaniesEnum, Record<Locale, string> | undefined> = {
  [TickmillCompaniesEnum.TICKMILL_SC]: {
    en: '2',
    pl: '13',
    ar: '5',
    ru: '19',
    id: '8',
    de: '4',
    zh: '6',
    tr: '17',
    pt: '36',
    it: '9',
    es: '15',
    vi: '18',
    ms: '12',
    ko: '11',
    th: '16',
  },
  [TickmillCompaniesEnum.TICKMILL_UK]: {
    en: '38',
    pl: '43',
    de: '41',
    zh: '40',
    ar: '39',
    ru: '46',
    tr: '49',
    pt: '44',
    it: '42',
    es: '45',
    ko: '38',
    th: '38',
    id: '38',
    ms: '38',
    vi: '38',
  },
  [TickmillCompaniesEnum.TICKMILL_EU]: {
    en: '22',
    pl: '20',
    de: '52',
    ru: '29',
    pt: '31',
    it: '30',
    es: '32',
    ar: '22',
    zh: '22',
    tr: '22',
    id: '22',
    ms: '22',
    vi: '22',
    ko: '22',
    th: '22',
  },
  [TickmillCompaniesEnum.TICKMILL_AS]: {
    th: '16',
    en: '2',
    pl: '2',
    de: '2',
    ru: '2',
    it: '2',
    es: '2',
    ar: '2',
    zh: '2',
    tr: '2',
    id: '2',
    pt: '2',
    ms: '2',
    vi: '2',
    ko: '2',
  },
  [TickmillCompaniesEnum.TICKMILL_PA]: undefined,
  [TickmillCompaniesEnum.GENERIC]: undefined,
}
