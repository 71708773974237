import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button } from '../../../../global/button/Button'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { TextH1 } from '../../../../ui/Typography/Typography'
import {
  isDepositBeforeKYC,
  isDepositPlaced,
} from '../../../../utils/AccountAccess/accountKYCStatuses'
import {
  useAccountReadContext,
  useAccountWriteContext,
} from '../../../../utils/AccountContextContext'
import { useLandingPage } from '../../../../utils/useLandingPage'
import { SignupFormWrapper } from '../../Components/SignupFormWrapper'
import { SignupSections, SignupStep } from '../../Components/SignupStep'
import { SignupWrapper } from '../../Components/SignupWrapper'

import styles from './ConfirmPage.module.scss'

export const ConfirmPage: React.FC = () => {
  const { t } = useTranslation()
  const { account } = useAccountReadContext()

  const navigate = useNavigate()

  const locale = useSessionLanguage()
  const { getLandingPage } = useLandingPage()

  const { refreshAccount } = useAccountWriteContext()

  useEffect(() => {
    return () => localStorage.removeItem('hasAdditionalId')
  }, [])

  const handleGoToDashboard = async () => {
    await refreshAccount(locale)

    const shouldNavigateToTransactionHistory =
      isDepositBeforeKYC(account) && isDepositPlaced(account)

    const path = shouldNavigateToTransactionHistory
      ? '/dashboard/traders-room/transaction-history'
      : getLandingPage()

    navigate(path)
  }

  return (
    <SignupFormWrapper label={t('Sign up.Sign Up')}>
      <SignupStep section={SignupSections.ReviewConfirm} />
      <SignupWrapper className={styles.wrapper}>
        <TextH1>{t('Sign up.Thank you for Registering!')}</TextH1>
        <span>{t('Sign up.Give us max 24 hours to verify your identity.')}</span>
        <div className={styles.buttonWrapper}>
          <Button
            type='button'
            appearance='primary'
            className={styles.button}
            size='L'
            onClick={handleGoToDashboard}
          >
            {t('Sign up.Go to Dashboard')}
          </Button>
        </div>
      </SignupWrapper>
    </SignupFormWrapper>
  )
}
