import documents from './documents.json'
import links from './links.json'

interface DocumentsConfigProps {
  documents: typeof documents
}
interface LinksConfigProps {
  links: typeof links
}

export const useDocumentsConfig = (): DocumentsConfigProps => {
  return {
    documents,
  }
}

export const useLinksConfig = (): LinksConfigProps => {
  return {
    links,
  }
}
