import { ReactElement } from 'react'

import styles from './Icons.module.scss'

export const RedInfoIcon = (props: { size?: number }): ReactElement => {
  const size = props.size || 24
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        fill={styles.error}
      />
      <ellipse cx='12' cy='15.5' rx='1.5' ry='1.5' fill='white' />
      <path
        d='M10.3288 8.97279C10.1567 7.94009 10.9531 7 12 7V7C13.047 7 13.8433 7.94009 13.6712 8.97279L13.1644 12.0136C13.0696 12.5828 12.5771 13 12 13V13C11.423 13 10.9305 12.5828 10.8356 12.0136L10.3288 8.97279Z'
        fill='white'
      />
    </svg>
  )
}
