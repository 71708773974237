import { IconProps, iconColor } from './IconProps'

export function BottomLeftCornerLineIcon(props: IconProps) {
  const size = props.size || 10

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 10 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 1L1 5.5C1 8.26142 3.23858 10.5 6 10.5H9'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
