import React, { PropsWithChildren, ReactNode } from 'react'
import classNames from 'classnames'

import { CheckBoxOff } from '../../icons/CheckBoxOffIcon'
import { CheckBoxOn } from '../../icons/CheckBoxOnIcon'
import { Text, TextSmall } from '../../ui/Typography/Typography'

import styles from './CheckBox.module.scss'

export interface CheckBoxProps {
  label?: string
  value: boolean
  disabled?: boolean
  hint?: string
  required?: boolean
  onChange?(value: React.ChangeEvent<HTMLInputElement>): void
  onClick?(value: React.MouseEvent<HTMLInputElement>): void
  error?: string
  verticallyCentered?: boolean
  wrapperClassName?: string
  contentWrapperClassName?: string
  isTextSmall?: boolean
}

export const CheckBox: React.FunctionComponent<PropsWithChildren<CheckBoxProps>> = (props) => {
  const {
    label,
    value = false,
    disabled = false,
    hint,
    required = false,
    error,
    onChange = () => {},
    onClick,
    children,
    verticallyCentered,
    wrapperClassName,
    contentWrapperClassName,
    isTextSmall,
  } = props

  const hasTitle = label || hint || children

  const renderText = (content: ReactNode): ReactNode => {
    if (isTextSmall) {
      return <TextSmall>{content}</TextSmall>
    }

    return <Text isParagraph>{content}</Text>
  }

  return (
    <React.Fragment>
      <div
        className={classNames(styles.wrapper, wrapperClassName, {
          [styles.centered]: verticallyCentered,
        })}
      >
        <span className={styles.checkboxWrapper}>
          <input
            type='checkbox'
            checked={value}
            disabled={disabled}
            required={required}
            onChange={onChange}
            onClick={onClick}
            className={classNames(styles.input, {
              [styles.disabled]: disabled,
            })}
          />
          <span
            className={classNames(styles.icon, {
              [styles.active]: value,
              [styles.disabled]: disabled,
            })}
          >
            {value ? (
              <CheckBoxOn className={styles.checkboxIcon} />
            ) : (
              <CheckBoxOff className={styles.checkboxIcon} />
            )}
          </span>
        </span>

        {hasTitle && (
          <span
            className={classNames(styles.contentWrapper, contentWrapperClassName, {
              [styles.disabled]: disabled,
            })}
          >
            {label && renderText(label)}
            {hint && <TextSmall className={styles.hint}>{hint}</TextSmall>}
            {children && renderText(children)}
          </span>
        )}
      </div>
      {!!error && (
        <TextSmall isParagraph className={styles.errorMessage}>
          {error}
        </TextSmall>
      )}
    </React.Fragment>
  )
}
