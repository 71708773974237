import { DependencyList, useCallback, useEffect, useRef, useState } from 'react'

const DefaultRefreshIntervalMs = 1000000

interface UseCallbackWithForceRefresh<T extends (...args: any[]) => any> {
  callback: T
  forceRefresh: (skipLoadingFlag?: boolean) => void
  skipLoadingFlag: boolean
}

export function useCallbackWithForceRefresh<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList
) {
  const [refresher, setRefresher] = useState({
    skipLoadingFlag: false,
    trigger: 0,
  })

  return {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    callback: useCallback(callback, [...deps, refresher]),
    forceRefresh: useCallback(
      (skipLoadingFlag?: boolean) => {
        setRefresher((r) => ({
          skipLoadingFlag: skipLoadingFlag || false,
          trigger: r.trigger + 1,
        }))
      },
      [setRefresher]
    ),
    skipLoadingFlag: refresher.skipLoadingFlag,
  }
}

export function useCallbackWithAutoRefresh<T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList,
  refreshIntervalMs: number = DefaultRefreshIntervalMs
): UseCallbackWithForceRefresh<T> {
  const result = useCallbackWithForceRefresh(callback, deps)

  const timerRef = useRef<any>()
  const refreshSkipped = useRef(false)

  const onVisibilityChanged = () => {
    if (refreshSkipped.current && document.visibilityState !== 'hidden') {
      result.forceRefresh(true)
      refreshSkipped.current = false
    }
  }

  if (typeof document !== 'undefined') {
    document.addEventListener('visibilitychange', onVisibilityChanged)

    if (!timerRef.current) {
      timerRef.current = setInterval(() => {
        if (document.visibilityState !== 'hidden') {
          result.forceRefresh(true)
        } else {
          refreshSkipped.current = true
        }
      }, refreshIntervalMs)
    }
  }

  useEffect(() => {
    return () => {
      clearInterval(timerRef.current)
      if (typeof document !== 'undefined') {
        document.removeEventListener('visibilitychange', onVisibilityChanged)
      }
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return result
}
