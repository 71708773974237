import React from 'react'
import { useTranslation } from 'react-i18next'

import { SelectModal, SelectModalOption } from '../global/field/SelectModal'
import { Modal } from '../global/modal/Modal'
import { subscriptionTypeOptions } from '../model/SubscriptionTypeOptions'
import { SubscriptionTimeInForce } from '../model/UpdateSubscriptionRequest'

interface Props {
  subscriptionTimeInForceType?: number

  handleOnSelectSubscriptionTimeInForceType(timeInForceId: SubscriptionTimeInForce): void
  levelDowngrade?: boolean

  cancelTimeInForceSelection(): void
}

export const SubscriptionTimeInForceModal: React.FC<Props> = ({
  subscriptionTimeInForceType,
  handleOnSelectSubscriptionTimeInForceType,
  levelDowngrade,
  cancelTimeInForceSelection,
}) => {
  const [option, setOption] = React.useState<number | undefined>(subscriptionTimeInForceType)
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={cancelTimeInForceSelection}
      render={({ closeModal }) => (
        <SelectModal
          onConfirm={() => (option ? handleOnSelectSubscriptionTimeInForceType(option) : {})}
          onCancel={closeModal}
          title={t('Market Data.When do you like the subscription to start')}
          renderOptions={() => (
            <div className='control'>
              {subscriptionTypeOptions.map(({ id, displayName }, idx) => (
                <SelectModalOption
                  label={t(displayName)}
                  value={option === id}
                  onClick={() => setOption(id)}
                  key={id}
                  disabled={idx > 0 ? false : levelDowngrade}
                />
              ))}
            </div>
          )}
        />
      )}
    />
  )
}
