import { useTranslation } from 'react-i18next'

import IconButton from '../../global/iconButton/IconButton'
import { CloseIcon } from '../../icons/CloseIcon'
import { useWindowResize } from '../../utils/domUtils'
import { Text, TextStrong } from '../Typography/Typography'

import styles from './Note.module.scss'

interface NoteProps {
  renderLeft?: JSX.Element
  text: string
  mobileTitle?: string
  close?: () => void
  renderRight?: JSX.Element
}

export const Note: React.FC<NoteProps> = (props) => {
  const { renderLeft, mobileTitle, text, close, renderRight } = props
  const isMobile = useWindowResize()
  const { t } = useTranslation()

  if (isMobile) {
    return (
      <div className={styles.note}>
        <div className={styles.content}>
          {close && (
            <IconButton onClick={close} className={styles.close}>
              <CloseIcon />
            </IconButton>
          )}
          <div className={styles.text}>
            {mobileTitle && <TextStrong>{mobileTitle}</TextStrong>}
            <Text isParagraph>{t('Tap for more details')}</Text>
          </div>
        </div>
        {renderRight && <div className={styles.right}>{renderRight}</div>}
      </div>
    )
  }
  return (
    <div className={styles.note}>
      <div className={styles.content}>
        {renderLeft && <div className={styles.left}>{renderLeft}</div>}
        <Text isParagraph>{text}</Text>
        {renderRight && <div className={styles.right}>{renderRight}</div>}
      </div>
      {close && (
        <IconButton onClick={close}>
          <CloseIcon />
        </IconButton>
      )}
    </div>
  )
}
