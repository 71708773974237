import React, { useCallback } from 'react'
import { Outlet } from 'react-router-dom'

import { Loading } from '../global/Loading/Loading'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { useFetchOne } from '../utils/useFetch'
import { TradersRoomModals } from './TradersRoomModals'
import { TradersRoomTabs } from './TradersRoomTabs'

export const TradersRoom: React.FC = () => {
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => apiClient.getClientCampaigns(), [apiClient])
  const listCallback = useCallback(async () => apiClient.getClientCampaignsList(), [apiClient])

  const { data: campaigns, isLoading } = useFetchOne(callback)
  const { data: campaignsList, isLoading: isLoadingList } = useFetchOne(listCallback)

  return (
    <Loading showLoadingIcon isLoading={isLoading || isLoadingList}>
      <TradersRoomModals />
      <TradersRoomTabs clientCampaigns={campaigns} clientCampaignsList={campaignsList?.items} />
      <Outlet />
    </Loading>
  )
}
