import React from 'react'

import { IconProps, iconColor, iconSecondaryColor } from './IconProps'

export function CircleForwardIcon(props: IconProps): React.ReactElement {
  const size = props.size || 32

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.000183105' width='32' height='32' rx='16' fill={iconSecondaryColor(props)} />
      <path
        d='M8 15.0002H20.2L15.3 10.1002C14.9134 9.71358 14.9134 9.08678 15.3 8.70018C15.6866 8.31358 16.3134 8.31358 16.7 8.70018L24 16.0002L16.7 23.3002C16.3134 23.6868 15.6866 23.6868 15.3 23.3002C14.9134 22.9136 14.9134 22.2868 15.3 21.9002L20.2 17.0002H8V15.0002Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
