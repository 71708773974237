import React, { FormEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'
import { v4 as uuid } from 'uuid'

import { Button } from '../../../../../global/button/Button'
import { useArabicSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { createFormDatePickerField } from '../../../../../global/formField/FieldDatePicker'
import { createFormCheckBoxField } from '../../../../../global/formField/FormCheckBoxField'
import { createFormField } from '../../../../../global/formField/FormField'
import { RawSelectFormField } from '../../../../../global/formField/FormSelectField'
import { Modal } from '../../../../../global/modal/Modal'
import { PhoneCodeSelectModal } from '../../../../../global/modal/PhoneCodeModal'
import { BackIcon } from '../../../../../icons/BackIcon'
import { DropArrowDownIcon } from '../../../../../icons/DropArrowDownIcon'
import { ForwardIcon } from '../../../../../icons/ForwardIcon'
import { CountryPreferenceStrippedDto } from '../../../../../model/CountryDto'
import { NameDto } from '../../../../../model/NameDto'
import { CountryCodeFlagIcon } from '../../../../../ui/CountryCodeFlagIcon/CountryCodeFlagIcon'
import { TextH3 } from '../../../../../ui/Typography/Typography'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { EmailPattern, EnglishLettersPattern } from '../../../../../utils/formValidation'
import { preventPaste } from '../../../../../utils/input.utils'

import styles from '../CompanyDetailsStep.module.scss'

const FormField = createFormField<CompanyDetailsStep1FormValues>()
const FormCheckBoxField = createFormCheckBoxField<CompanyDetailsStep1FormValues>()
const FormDatePickerField = createFormDatePickerField<CompanyDetailsStep1FormValues>()

export interface CompanyDetailsStep1FormValues {
  companyName: string
  companyAddressCountry: string
  companyRegistrationDate: string
  companyRegistrationNumber: string
  companyTypeId: string
  companyPhoneNumberCountryCode: string
  companyPhoneNumber: string
  companyEmail: string
  registrationAddress: {
    companyAddressPostalCode: string
    companyAddressStreet: string
    companyAddressCity: string
    companyAddressState: string
  }

  physicalAddress: {
    companyAddressPostalCode: string
    companyAddressStreet: string
    companyAddressCity: string
    companyAddressState: string
  }

  registrationAddressCopy: boolean
}

interface OuterProps {
  companyTypes: NameDto[]
  countries: CountryPreferenceStrippedDto[]
  initialValues: CompanyDetailsStep1FormValues | undefined
  onSubmit(values: CompanyDetailsStep1FormValues): void
}

const CompanyDetailsStep1FormUI: React.FC<
  FormikProps<CompanyDetailsStep1FormValues> & OuterProps
> = (props) => {
  const {
    handleSubmit,
    validateForm,
    values,
    setFieldValue,
    setSubmitting,
    errors,
    isSubmitting,
    isValid,
  } = props
  const [isCountryModalOpen, setIsCountryModelOpen] = useState(false)

  const [phoneNumberError, setPhoneNumberError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCountry, setSelectedCountry] = useState<CountryPreferenceStrippedDto>()

  const apiClient = useApiClient(ClientApiClient)

  const onSubmit: FormEventHandler = (e) => {
    validateForm()
    e.preventDefault()
    if (!Object.keys(errors).length) {
      setSubmitting(true)
      handleSubmit()
    }
  }

  useEffect(() => {
    const country = (props.countries || []).find(
      (x) => x.phoneCode === values.companyPhoneNumberCountryCode
    )

    if (country) {
      setSelectedCountry(country)
    }
  }, [props.countries, setFieldValue, values.companyPhoneNumberCountryCode])

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const { validateCity, validateStreet, validateState } = useFormValidation()

  useEffect(() => {
    if (
      !values.companyPhoneNumber ||
      !values.companyPhoneNumberCountryCode ||
      values.companyPhoneNumber.toString().length < 4 ||
      errors.companyPhoneNumber
    ) {
      return
    }

    setIsLoading(true)

    const timeOut: NodeJS.Timeout = setTimeout(async () => {
      try {
        const resp = await apiClient.validatePhoneNumber(
          values.companyPhoneNumber,
          values.companyPhoneNumberCountryCode
        )
        if (resp.phoneVerificationRequired) {
          await apiClient.validateBasePhoneNumber(
            values.companyPhoneNumber,
            values.companyPhoneNumberCountryCode
          )
        }
        setPhoneNumberError('')
      } catch {
        setPhoneNumberError(t('Sign up.Please check your phone number'))
      } finally {
        setIsLoading(false)
      }
    }, 1000)

    return () => clearTimeout(timeOut)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.companyPhoneNumberCountryCode, values.companyPhoneNumber, errors.companyPhoneNumber])

  const companyCountryCode = props.values.companyPhoneNumberCountryCode

  const handleOnChangeOption = (country: CountryPreferenceStrippedDto) => {
    props.setFieldValue('companyPhoneNumberCountryCode', country.phoneCode)
    setSelectedCountry(country)
    setIsCountryModelOpen(false)
  }

  return (
    <React.Fragment>
      {isCountryModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setIsCountryModelOpen(false)}
          render={() => (
            <PhoneCodeSelectModal
              title={t('Sign up.Phone Code')}
              options={props.countries}
              onCancel={() => setIsCountryModelOpen(false)}
              handleOnChangeOption={handleOnChangeOption}
              selectedCountry={values.companyPhoneNumberCountryCode}
            />
          )}
        />
      )}
      <Form className={styles.form} onSubmit={onSubmit} autoComplete='off'>
        <TextH3>{t('Sign up.Company')}</TextH3>
        <FormField
          type='text'
          required
          showLabel
          name='companyName'
          label={t('Sign up.Company Name')}
        />
        <RawSelectFormField
          showLabel
          component='select'
          name={'companyAddressCountry'}
          label={t('Sign up.Country of Registration')}
          required
        >
          {(props.countries || []).map((country) => (
            <option value={country.country.id} key={country.country.id}>
              {country.country.name}
            </option>
          ))}
        </RawSelectFormField>
        <div className='field'>
          <FormDatePickerField
            name='companyRegistrationDate'
            label={t('Sign up.Registration Date')}
            maxDate={new Date()}
            required
            showLabel
            showYearDropdown
          />
        </div>
        <FormField
          type='text'
          required
          showLabel
          name='companyRegistrationNumber'
          label={t('Sign up.Registration Number')}
        />
        <RawSelectFormField
          showLabel
          component='select'
          name={'companyTypeId'}
          label={t('Sign up.Company Type')}
          required
        >
          {(props.companyTypes || []).map((companyType) => (
            <option value={companyType.id} key={companyType.id}>
              {companyType.name}
            </option>
          ))}
        </RawSelectFormField>
        <TextH3>{t('Sign up.Company Registration Address')}</TextH3>
        <FormField
          type='text'
          required
          showLabel
          name='registrationAddress.companyAddressStreet'
          label={t('Sign up.Street Name, Street Number, Apt. Number')}
          validate={validateStreet}
        />
        <FormField
          type='text'
          required
          showLabel
          name='registrationAddress.companyAddressCity'
          label={t('Sign up.City')}
          validate={validateCity}
        />
        <div className={styles.row}>
          <FormField
            type='text'
            required
            showLabel
            name='registrationAddress.companyAddressState'
            label={t('Sign up.State/Region')}
            validate={validateState}
          />
          <FormField
            type='text'
            name='registrationAddress.companyAddressPostalCode'
            label={t('Sign up.ZIP/Postal Code')}
            required
            showLabel
          />
        </div>
        <TextH3>{t(`Sign up.Company's Physical Address`)}</TextH3>
        <FormCheckBoxField name='registrationAddressCopy'>
          {t('Sign up.Same as registration address')}
        </FormCheckBoxField>
        {!values.registrationAddressCopy && (
          <React.Fragment>
            <FormField
              name='physicalAddress.companyAddressStreet'
              label={t('Sign up.Street Name, Street Number, Apt. Number')}
              type='text'
              validate={validateStreet}
              required
              showLabel
            />
            <FormField
              name='physicalAddress.companyAddressCity'
              label={t('Sign up.City')}
              type='text'
              validate={validateCity}
              required
              showLabel
            />
            <div className={styles.row}>
              <FormField
                name='physicalAddress.companyAddressState'
                label={t('Sign up.State/Region')}
                type='text'
                validate={validateState}
                required
                showLabel
              />
              <FormField
                type='text'
                name='physicalAddress.companyAddressPostalCode'
                label={t('Sign up.ZIP/Postal Code')}
                required
                showLabel
              />
            </div>
          </React.Fragment>
        )}
        <TextH3>{t('Sign up.Phone and Email')}</TextH3>
        <div className={styles.rowPhone}>
          <FormField
            showLabel
            label={t('Sign up.Country Code')}
            autoComplete={`companyCountryCode-${uuid()}`}
            name='companyPhoneNumberCountryCode'
            leftIcon={
              companyCountryCode !== '' ? (
                <CountryCodeFlagIcon
                  countries={props.countries}
                  selectedCountry={selectedCountry}
                />
              ) : null
            }
            onClick={() => setIsCountryModelOpen(true)}
            rightIcon={<DropArrowDownIcon />}
            required
          />
          <div className='is-flex-direction-column'>
            <FormField
              type='tel'
              disabled={!values.companyPhoneNumberCountryCode}
              required
              onPaste={preventPaste}
              onKeyDown={(e) => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
              name='companyPhoneNumber'
              showLabel
              label={t('PhoneNumbers.Phone Number')}
              autoComplete={`companyPhoneNumber-${uuid()}`}
              error={errors.companyPhoneNumber || phoneNumberError}
              validateOnType
            />
          </div>
        </div>
        <FormField type='email' showLabel required name='companyEmail' label={t('Sign up.Email')} />

        <Button
          className={styles.button}
          type='submit'
          appearance='primary'
          size='L'
          onClick={() => validateForm()}
          renderRightIcon={() => (
            <span className='is-flex is-align-items-center'>
              {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
            </span>
          )}
          disabled={isSubmitting || !isValid || isLoading || !!phoneNumberError}
        >
          {t('Next')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

const useFormValidation = () => {
  const { t } = useTranslation()

  const validateStreet = (value: string) => {
    if (!value.trim()) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid street')}`
    }
    return undefined
  }

  const validateCity = (value: string) => {
    if (!value.trim()) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid city')}`
    }
  }

  const validateState = (value: string) => {
    if (!value.trim()) {
      return `${t('Validation.Required')}`
    }
    if (!EnglishLettersPattern.test(value)) {
      return `${t('Validation.Invalid state')}`
    }
  }

  return { validateStreet, validateCity, validateState }
}

export const CompanyDetailsStep1Form = withFormik<OuterProps, CompanyDetailsStep1FormValues>({
  mapPropsToValues: ({ initialValues }) => {
    return {
      companyAddressCountry: initialValues?.companyAddressCountry || '',
      companyEmail: initialValues?.companyEmail || '',
      companyName: initialValues?.companyName || '',
      companyPhoneNumber: initialValues?.companyPhoneNumber || '',
      companyPhoneNumberCountryCode: initialValues?.companyPhoneNumberCountryCode || '',
      companyRegistrationDate: initialValues?.companyRegistrationDate || '',
      companyRegistrationNumber: initialValues?.companyRegistrationNumber || '',
      companyTypeId: initialValues?.companyTypeId || '',

      registrationAddress: {
        companyAddressPostalCode:
          initialValues?.registrationAddress?.companyAddressPostalCode || '',
        companyAddressState: initialValues?.registrationAddress?.companyAddressState || '',
        companyAddressStreet: initialValues?.registrationAddress?.companyAddressStreet || '',
        companyAddressCity: initialValues?.registrationAddress?.companyAddressCity || '',
      },

      physicalAddress: {
        companyAddressPostalCode: initialValues?.physicalAddress?.companyAddressPostalCode || '',
        companyAddressStreet: initialValues?.physicalAddress?.companyAddressStreet || '',
        companyAddressCity: initialValues?.physicalAddress?.companyAddressCity || '',
        companyAddressState: initialValues?.physicalAddress?.companyAddressState || '',
      },

      registrationAddressCopy: initialValues?.registrationAddressCopy || false,
    }
  },

  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)

      props.onSubmit({
        ...values,
        physicalAddress: {
          companyAddressPostalCode: values?.registrationAddressCopy
            ? values.registrationAddress.companyAddressPostalCode
            : values.physicalAddress.companyAddressPostalCode,
          companyAddressStreet: values?.registrationAddressCopy
            ? values.registrationAddress.companyAddressStreet
            : values.physicalAddress.companyAddressStreet,
          companyAddressCity: values?.registrationAddressCopy
            ? values.registrationAddress.companyAddressCity
            : values.physicalAddress.companyAddressCity,
          companyAddressState: values?.registrationAddressCopy
            ? values.registrationAddress.companyAddressState
            : values.physicalAddress.companyAddressState,
        },
      })
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, props) => {
    const errors: FormikErrors<CompanyDetailsStep1FormValues> = {}
    if (!values.companyTypeId) {
      errors.companyTypeId = t('Validation.Required')
    }

    if (!values.companyAddressCountry) {
      errors.companyAddressCountry = t('Validation.Required')
    }

    // RegistrationAddress
    if (!values.registrationAddress.companyAddressPostalCode.trim()) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressPostalCode = t('Validation.Required')
      } else {
        errors.registrationAddress = {
          companyAddressPostalCode: t('Validation.Required'),
        }
      }
    }
    if (values.registrationAddress.companyAddressPostalCode.length > 32) {
      errors.registrationAddress = {
        ...errors.registrationAddress,
        companyAddressPostalCode: t('Validation.Maximum character limit is 32'),
      }
    }
    if (!EnglishLettersPattern.test(values.registrationAddress.companyAddressPostalCode)) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressPostalCode = t('Validation.Invalid postal code')
      } else {
        errors.registrationAddress = {
          companyAddressPostalCode: t('Validation.Invalid postal code'),
        }
      }
    }
    if (!EnglishLettersPattern.test(values.registrationAddress.companyAddressState.trim())) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressState = t('Validation.Invalid state')
      } else {
        errors.registrationAddress = {
          companyAddressState: t('Validation.Invalid state'),
        }
      }
    }
    if (!values.registrationAddress.companyAddressState) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressState = t('Validation.Required')
      } else {
        errors.registrationAddress = {
          companyAddressState: t('Validation.Required'),
        }
      }
    }

    if (!EnglishLettersPattern.test(values.registrationAddress.companyAddressCity)) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressCity = t('Validation.Invalid city')
      } else {
        errors.registrationAddress = {
          companyAddressCity: t('Validation.Invalid city'),
        }
      }
    }
    if (!values.registrationAddress.companyAddressCity) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressCity = t('Validation.Required')
      } else {
        errors.registrationAddress = {
          companyAddressCity: t('Validation.Required'),
        }
      }
    }

    if (!EnglishLettersPattern.test(values.registrationAddress.companyAddressStreet)) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressStreet = t('Validation.Invalid street')
      } else {
        errors.registrationAddress = {
          companyAddressStreet: t('Validation.Invalid street'),
        }
      }
    }
    if (!values.registrationAddress.companyAddressStreet) {
      if (errors?.registrationAddress) {
        errors.registrationAddress.companyAddressStreet = t('Validation.Required')
      } else {
        errors.registrationAddress = {
          companyAddressStreet: t('Validation.Required'),
        }
      }
    }

    // PhysicalAddress
    if (!values.registrationAddressCopy) {
      if (!values.physicalAddress.companyAddressPostalCode.trim()) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressPostalCode = t('Validation.Required')
        } else {
          errors.physicalAddress = {
            companyAddressPostalCode: t('Validation.Required'),
          }
        }
      }

      if (values.physicalAddress.companyAddressPostalCode.length > 32) {
        errors.physicalAddress = {
          ...errors.physicalAddress,
          companyAddressPostalCode: t('Validation.Maximum character limit is 32'),
        }
      }
      if (!EnglishLettersPattern.test(values.physicalAddress.companyAddressPostalCode)) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressPostalCode = t('Validation.Invalid postal code')
        } else {
          errors.physicalAddress = {
            companyAddressPostalCode: t('Validation.Invalid postal code'),
          }
        }
      }

      if (!EnglishLettersPattern.test(values.physicalAddress.companyAddressState)) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressState = t('Validation.Invalid state')
        } else {
          errors.physicalAddress = {
            companyAddressState: t('Validation.Invalid state'),
          }
        }
      }
      if (!values.physicalAddress.companyAddressState) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressState = t('Validation.Required')
        } else {
          errors.physicalAddress = {
            companyAddressState: t('Validation.Required'),
          }
        }
      }

      if (!EnglishLettersPattern.test(values.physicalAddress.companyAddressCity)) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressCity = t('Validation.Invalid city')
        } else {
          errors.physicalAddress = {
            companyAddressCity: t('Validation.Invalid city'),
          }
        }
      }
      if (!values.physicalAddress.companyAddressCity) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressCity = t('Validation.Required')
        } else {
          errors.physicalAddress = {
            companyAddressCity: t('Validation.Required'),
          }
        }
      }

      if (!EnglishLettersPattern.test(values.physicalAddress.companyAddressStreet)) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressStreet = t('Validation.Invalid street')
        } else {
          errors.physicalAddress = {
            companyAddressStreet: t('Validation.Invalid street'),
          }
        }
      }
      if (!values.physicalAddress.companyAddressStreet) {
        if (errors?.physicalAddress) {
          errors.physicalAddress.companyAddressStreet = t('Validation.Required')
        } else {
          errors.physicalAddress = {
            companyAddressStreet: t('Validation.Required'),
          }
        }
      }
    }

    if (!values.companyEmail) {
      errors.companyEmail = t('Validation.Required')
    } else if (values.companyEmail && !EmailPattern.test(values.companyEmail)) {
      errors.companyEmail = t('Validation.Invalid email address')
    }

    if (!values.companyEmail) {
      errors.companyEmail = t('Validation.Required')
    }
    if (!values.companyName.trim()) {
      errors.companyName = t('Validation.Required')
    }

    if (!values.companyPhoneNumber) {
      errors.companyPhoneNumber = t('Validation.Required')
    }

    if (!values.companyPhoneNumberCountryCode) {
      errors.companyPhoneNumberCountryCode = t('Validation.Required')
    }
    if (!values.companyRegistrationDate) {
      errors.companyRegistrationDate = t('Validation.Required')
    }
    if (!values.companyRegistrationNumber.trim()) {
      errors.companyRegistrationNumber = t('Validation.Required')
    }
    if (!values.companyTypeId) {
      errors.companyTypeId = t('Validation.Required')
    }

    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(CompanyDetailsStep1FormUI)
