export enum CampaignBusinessRule {
  MaximumDrawdownPercent = 1,
  MinimumActiveTradingDaysPerCampaign = 2,
  MinimumActiveTradingDaysPerMonth = 3,
  TransferToPercentageProfit = 101,
  TransferFromPercentageProfit = 102,
}

export const isCampaignBusinessRuleMaxDrawdownType = (value?: CampaignBusinessRule) => {
  return value === CampaignBusinessRule.MaximumDrawdownPercent
}

export const isCampaignBusinessRuleMinActiveTradingDaysPerCampaignType = (
  value?: CampaignBusinessRule
) => {
  return value === CampaignBusinessRule.MinimumActiveTradingDaysPerCampaign
}
