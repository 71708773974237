import React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { Badge, BadgeVariantType } from '../../global/badge/Badge'
import { BankAccountStatus } from '../../model/BankAccount'
import { NameDto } from '../../model/NameDto'

interface DocumentBadgeStatusProps extends NameDto {
  type?: BankAccountStatus
}

type BankBadgeVariantMapType = Record<
  BankAccountStatus,
  'approved' | 'expired' | 'pending' | 'rejected' | 'activated'
>

const BankBadgeVariantAppearanceMap: BankBadgeVariantMapType = {
  [BankAccountStatus.None]: 'rejected',
  [BankAccountStatus.AwaitingApproval]: 'pending',
  [BankAccountStatus.Approved]: 'approved',
  [BankAccountStatus.Rejected]: 'rejected',
  [BankAccountStatus.Expired]: 'expired',
  [BankAccountStatus.Activated]: 'activated',
}

const getBankBadgeLabel = (
  status: BankAccountStatus,
  t: TFunction<'translation', undefined, 'translation'>
): string => {
  if (status === BankAccountStatus.None) {
    return t('Pending')
  } else if (status === BankAccountStatus.AwaitingApproval) {
    return t('AwaitingApproval')
  } else if (status === BankAccountStatus.Approved) {
    return t('Approved')
  } else if (status === BankAccountStatus.Rejected) {
    return t('Rejected')
  } else if (status === BankAccountStatus.Expired) {
    return t('Expired')
  } else {
    return t('Pending')
  }
}

export const BankBadgeStatus: React.FC<DocumentBadgeStatusProps> = (props) => {
  const { id } = props

  const { t } = useTranslation()

  return (
    <Badge
      text={getBankBadgeLabel(id, t)}
      appearance={BankBadgeVariantAppearanceMap[id as BankAccountStatus] as BadgeVariantType}
    />
  )
}
