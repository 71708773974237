import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../../global/button/Button'
import { ClientPhoneNumberDto } from '../../../model/AccountDetailedDto'
import { TextStrong } from '../../../ui/Typography/Typography'
import { PhoneNumberVerifyForm } from './PhoneNumberVerifyForm'

import styles from './PhoneNumberModal.module.scss'

interface PhoneNumberVerifyModalProps {
  phoneNumber: ClientPhoneNumberDto
  requestId: string

  verificationError?: string
  setVerificationError(v?: string): void

  verificationCodeError?: string
  setVerificationCodeError(v?: string): void

  verificationSuccess?: boolean

  verifyCounter: number
  setVerifyCounter(v: number): void

  resendCode(phoneNumber: string, phoneNumberId: string): void
  onReceivePhoneCall(phoneNumber: string, phoneNumberId: string): void
  onVerify(id: ClientPhoneNumberDto, requestId: string, code: string): void
  onCancel(): void
}

export const PhoneNumberVerifyModal: React.FC<PhoneNumberVerifyModalProps> = (props) => {
  const { onVerify, phoneNumber, requestId, verifyCounter, setVerifyCounter } = props

  const { t } = useTranslation()

  const [code, setCode] = useState<string>()

  const handleSubmit = async () => {
    if (code?.length === 4 && requestId) {
      await onVerify(phoneNumber, requestId, code)
    }
  }

  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('PhoneNumbers.Verify phone number')}</p>
      </header>
      <PhoneNumberVerifyForm
        {...props}
        phoneNumberId={phoneNumber.id}
        phoneNumber={phoneNumber.countryCode + phoneNumber.number}
        counter={verifyCounter}
        setCounter={setVerifyCounter}
        onPassCode={setCode}
        onSubmit={handleSubmit}
        renderFooter={(footerProps) => (
          <FooterModal {...props} onSubmit={footerProps.handleSubmit} />
        )}
      />
    </>
  )
}

interface FooterModalProps extends PhoneNumberVerifyModalProps {
  onSubmit(): void
}

const FooterModal: React.FC<FooterModalProps> = (props) => {
  const { onCancel, verifyCounter, onSubmit } = props

  const { t } = useTranslation()

  return (
    <footer className={classNames('modal-card-foot', styles.footer)}>
      <Button
        appearance='plain'
        disabled={verifyCounter > 0}
        onClick={onCancel}
        className={styles.button}
      >
        {t('Skip')}
      </Button>
      <Button appearance='plain' type='button' onClick={onSubmit}>
        <TextStrong className={styles.strongButton}>{t('Confirm')}</TextStrong>
      </Button>
    </footer>
  )
}
