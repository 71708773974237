import { IconProps, iconColor } from './IconProps'
import { Color } from './index'

import styles from './Icons.module.scss'

interface CheckCircledIconProps extends IconProps {
  circleColor?: Color
  checkColor?: Color
}

export function CheckCircledIcon(props: CheckCircledIconProps) {
  const size = props.size || 24

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_14652_63005)'>
        <circle
          cx='12'
          cy='12'
          r='12'
          fill={props.circleColor ? styles[props.circleColor] : iconColor(props)}
        />
        <path
          d='M9.99993 14.8001L7.6666 12.4667C7.40887 12.209 6.991 12.209 6.73327 12.4667C6.47554 12.7245 6.47554 13.1423 6.73327 13.4001L9.99993 16.6667L17.5333 9.1334C17.791 8.87567 17.791 8.4578 17.5333 8.20007C17.2755 7.94233 16.8577 7.94233 16.5999 8.20007L9.99993 14.8001Z'
          fill={props.checkColor ? styles[props.checkColor] : styles.white}
        />
      </g>
      <defs>
        <clipPath id='clip0_14652_63005'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
