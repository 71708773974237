import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react'
import classNames from 'classnames'

import styles from './IconButton.module.scss'

enum ButtonAppearance {
  Selectable = 'selectable',
  Hoverable = 'hoverable',
}

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'XS' | 'S' | 'M' | 'L'
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  fullWidth?: boolean
  className?: string
  appearance?: 'selectable' | 'hoverable'
}

const IconButton: React.FunctionComponent<PropsWithChildren<IconButtonProps>> = ({
  children,
  fullWidth,
  className,
  appearance,
  ...props
}) => {
  return (
    <button
      {...props}
      type='button'
      className={classNames(
        'button',
        styles.btn,
        {
          [styles.selectable]: appearance === ButtonAppearance.Selectable,
          [styles.hoverable]: appearance === ButtonAppearance.Hoverable,
          [styles.fullWidth]: fullWidth,
        },
        className
      )}
    >
      {children}
    </button>
  )
}

export default IconButton
