import React from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmationModal } from '../global/modal/ConfirmationModal'
import { Modal } from '../global/modal/Modal'

interface Props {
  onConfirm(): void
  onClose(): void
}

export const SubscriptionCQGConfirmationModal: React.FC<Props> = (props) => {
  const { onConfirm, onClose } = props

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <ConfirmationModal
          title={t('Subscriptions.CQG Integrated Client')}
          onCancel={closeModal}
          onConfirm={onConfirm}
          renderFooter={() => (
            <React.Fragment>
              <button className='button' onClick={closeModal} type='button'>
                {t('Cancel')}
              </button>
              <button className='button' onClick={onConfirm} type='button'>
                <b>{t('Confirm')}</b>
              </button>
            </React.Fragment>
          )}
        >
          <p>{t('Subscriptions.By subscribing to CQG Integrated Client you confirm')}</p>
        </ConfirmationModal>
      )}
    />
  )
}
