import { IconProps, iconColor } from './IconProps'

export function LogoWindowsIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M21 12V3L10.2 4.62M21 12V21L10.2 19.38M21 12H3M3 12V18.3L10.2 19.38M3 12V5.7L10.2 4.62M10.2 4.62V19.38'
        stroke={iconColor(props)}
        strokeWidth='1.5'
      />
    </svg>
  )
}
