import { ReactNode, createContext, useMemo, useState } from 'react'

import { useGuardedContext } from '../global/useGuardedContext'
import { AccountDetailedDto } from '../model/AccountDetailedDto'
import { IntroducingBrokerCampaignResultDto } from '../model/IntroducingBrokerCampaignResultDto'
import { useApiClient } from './ApiClient'
import { ClientApiClient } from './clientApi'

interface IBCampaignsResultsReadContextProps {
  ibCampaignsResults: IntroducingBrokerCampaignResultDto[]
}

interface IBCampaignsResultsWriteContextProps {
  refreshIBCampaigns: (campaigns?: IntroducingBrokerCampaignResultDto[]) => void
}

const IBCampaignsResultsReadContext = createContext<IBCampaignsResultsReadContextProps | undefined>(
  undefined
)

IBCampaignsResultsReadContext.displayName = 'IBCampaignsResultsReadContext'

const IBCampaignsResultsWriteContext = createContext<
  IBCampaignsResultsWriteContextProps | undefined
>(undefined)

export function useIBCampaignsResultsReadContext(): IBCampaignsResultsReadContextProps {
  return useGuardedContext(IBCampaignsResultsReadContext)
}

export function useIBCampaignsResultsWriteContext() {
  return useGuardedContext(IBCampaignsResultsWriteContext)
}

interface Props {
  campaignsResults: IntroducingBrokerCampaignResultDto[]
  account?: AccountDetailedDto
  children: ReactNode
}

export function IBCampaignsResultsContextProvider({ campaignsResults, children, account }: Props) {
  const [campaignsResultsState, setCampaignsResultsState] = useState(campaignsResults)

  const apiClient = useApiClient(ClientApiClient)

  const writeContext = useMemo<IBCampaignsResultsWriteContextProps>(() => {
    return {
      async refreshIBCampaigns(campaignsResults?: IntroducingBrokerCampaignResultDto[]) {
        if (!campaignsResults?.length && account?.clientIntroducingBroker?.id) {
          const ibBCampaignsResults = await apiClient.getIBCampaignsResults()
          setCampaignsResultsState(ibBCampaignsResults.items)
        } else if (campaignsResults?.length) {
          setCampaignsResultsState(campaignsResults)
        } else if (campaignsResults === undefined) {
          setCampaignsResultsState([])
        }
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const readContext = useMemo<IBCampaignsResultsReadContextProps>(() => {
    return { ibCampaignsResults: campaignsResultsState }
  }, [campaignsResultsState])

  return (
    <IBCampaignsResultsReadContext.Provider value={readContext}>
      <IBCampaignsResultsWriteContext.Provider value={writeContext}>
        {children}
      </IBCampaignsResultsWriteContext.Provider>
    </IBCampaignsResultsReadContext.Provider>
  )
}
