import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMAsiaLogo: FC = () => (
  <svg width='160' height='40' viewBox='0 0 160 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_77751_365927)'>
      <path
        d='M70.7879 21.9512C72.7758 21.9512 74.4243 21.1754 75.6849 19.5754L78.3031 22.2421C76.2182 24.5694 73.794 25.733 71.0303 25.733C68.2182 25.733 65.9394 24.8603 64.097 23.0664C62.3031 21.3209 61.3818 19.0421 61.3818 16.3754C61.3818 13.6603 62.3031 11.43 64.1455 9.63605C65.9879 7.84211 68.2667 6.9209 70.9334 6.9209C73.9394 6.9209 76.4121 8.03605 78.4485 10.3148L75.9273 13.1754C74.6667 11.5754 73.0667 10.7997 71.1273 10.7997C69.6243 10.7997 68.3152 11.2845 67.2 12.3027C66.1334 13.3209 65.5515 14.63 65.5515 16.327C65.5515 18.0239 66.0849 19.3815 67.1031 20.3997C68.1212 21.4179 69.3334 21.9512 70.7879 21.9512Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M45.8181 25.5391V10.8481H50.9575V7.35724H36.606V10.8481H41.7454V25.5391H45.8181Z'
        fill='var(--logo-text-color)'
      />
      <path d='M57.9878 7.35724H53.915V25.5391H57.9878V7.35724Z' fill='var(--logo-text-color)' />
      <path
        d='M85.5757 7.35724H81.5029V25.5391H85.5757V20.3997L87.4181 18.3633L92.7514 25.5391H97.5029C97.0518 24.9022 96.47 24.0911 95.7575 23.0978L95.7517 23.0897C95.1637 22.2699 94.4868 21.3262 93.7211 20.2542C92.2209 18.0922 91.1223 16.5726 90.4617 15.6589L90.2787 15.4057L97.5029 7.35724H92.4605L85.5757 14.8239V7.35724Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M116.266 14.0966L111.37 24.036H108.945L104.048 14.0966V25.5391H99.9756V7.35724H105.454L110.157 17.3451L114.86 7.35724H120.339V25.5391H116.266V14.0966Z'
        fill='var(--logo-text-color)'
      />
      <path d='M128.873 7.35724H124.8V25.5391H128.873V7.35724Z' fill='var(--logo-text-color)' />
      <path
        d='M133.333 25.5391V7.35724H137.406V21.9027H145.163V25.5391H133.333Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M148.121 7.35724V25.5391H159.951V21.9027H152.194V7.35724H148.121Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M7.70909 12.0121C7.41818 12.2061 7.17576 12.6424 7.17576 12.9818H7.27273V18.9939L0 23.1636V9.58788C0 8.71515 0.630303 7.64849 1.35758 7.21212L12.9939 0.327273C13.7212 -0.109091 14.9333 -0.109091 15.6606 0.327273L27.297 7.21212C27.3455 7.21212 27.3939 7.26061 27.4424 7.30909L20.0727 11.5758L14.8364 8.52121C14.497 8.32727 14.0121 8.32727 13.7212 8.52121L7.70909 12.0121Z'
        fill='var(--logo-custom-color)'
      />
      <path
        d='M21.3336 19.9152V13.9031L28.5093 9.7334V23.3092C28.5093 24.1819 27.879 25.2486 27.1517 25.6849L15.5154 32.5698C14.7881 33.0061 13.576 33.0061 12.8487 32.5698L1.21235 25.6849C1.16387 25.6849 1.11539 25.6364 1.0669 25.588L8.43659 21.3213L13.673 24.3758C14.0124 24.5698 14.4972 24.5698 14.7881 24.3758L20.8002 20.8849C21.0911 20.691 21.3336 20.2546 21.3336 19.9152Z'
        fill='var(--logo-custom-color)'
      />
      <path
        d='M141.17 39.08L140.43 37.38H136.74L136.01 39.08H135L138.11 32.08H139.11L142.21 39.08H141.17ZM137.12 36.51H140.06L138.58 33.11L137.12 36.51Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M147.648 33.54C147.321 33.3333 146.981 33.18 146.628 33.08C146.281 32.9733 145.961 32.92 145.668 32.92C145.261 32.92 144.934 33 144.688 33.16C144.448 33.3133 144.328 33.5267 144.328 33.8C144.328 34.0467 144.401 34.25 144.548 34.41C144.694 34.5633 144.874 34.6867 145.088 34.78C145.301 34.8667 145.597 34.97 145.977 35.09C146.451 35.2367 146.834 35.3767 147.128 35.51C147.421 35.6433 147.671 35.8433 147.878 36.11C148.084 36.37 148.188 36.7167 148.188 37.15C148.188 37.55 148.078 37.9 147.858 38.2C147.644 38.5 147.341 38.7333 146.948 38.9C146.561 39.06 146.114 39.14 145.608 39.14C145.094 39.14 144.594 39.04 144.108 38.84C143.621 38.64 143.201 38.3733 142.848 38.04L143.278 37.21C143.611 37.5367 143.984 37.79 144.398 37.97C144.818 38.1433 145.224 38.23 145.618 38.23C146.084 38.23 146.451 38.14 146.718 37.96C146.991 37.7733 147.128 37.5233 147.128 37.21C147.128 36.9633 147.054 36.76 146.908 36.6C146.761 36.44 146.574 36.3133 146.348 36.22C146.128 36.1267 145.828 36.0233 145.448 35.91C144.981 35.77 144.601 35.6333 144.308 35.5C144.014 35.3667 143.764 35.17 143.558 34.91C143.358 34.65 143.258 34.3067 143.258 33.88C143.258 33.5067 143.361 33.1767 143.568 32.89C143.774 32.6033 144.064 32.3833 144.438 32.23C144.811 32.0767 145.244 32 145.738 32C146.158 32 146.568 32.0633 146.968 32.19C147.374 32.31 147.734 32.4733 148.048 32.68L147.648 33.54Z'
        fill='var(--logo-text-color)'
      />
      <path d='M150.12 32.08H151.08V39.08H150.12V32.08Z' fill='var(--logo-text-color)' />
      <path
        d='M158.679 39.08L157.939 37.38H154.249L153.519 39.08H152.509L155.619 32.08H156.619L159.719 39.08H158.679ZM154.629 36.51H157.569L156.089 33.11L154.629 36.51Z'
        fill='var(--logo-text-color)'
      />
    </g>
    <defs>
      <clipPath id='clip0_77751_365927'>
        <rect width='160' height='40' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
