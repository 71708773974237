import { SubscriptionStatus } from './SubscriptionsDto'

export const isTransactionStatusActive = (value: number): boolean => {
  return value === SubscriptionStatus.ACTIVE
}

export const isTransactionStatusPending = (value: number): boolean => {
  return value === SubscriptionStatus.PENDING
}

export const isTransactionStatusCompleted = (value: number): boolean => {
  return value === SubscriptionStatus.COMPLETED
}

export const isTransactionStatusCanceled = (value: number): boolean => {
  return value === SubscriptionStatus.CANCELLED
}

export const isTransactionStatusDeductingFunds = (value: number): boolean => {
  return value === SubscriptionStatus.DEDUCTING_FUNDS
}

export const isTransactionStatusFailed = (value: number): boolean => {
  return value === SubscriptionStatus.FAILED
}

export const isTransactionStatusInProcess = (value: number): boolean => {
  return value === SubscriptionStatus.IN_PROCESS
}
