import { AxiosError } from 'axios'

import { ResponseError } from '../../utils/ApiClient'
import { isServiceUnavailableStatus } from '../../utils/httpStatus'
import { JSONParse } from '../../utils/json'
import { errorOptions } from '../RouterToaster'

export interface MaintenanceType {
  Message: string
  StartDateTime: string
  EndDateTime: string
}

export const getErrorResponseData = (error: ResponseError) => {
  const response = error?.response
  const status = response?.response?.status
  const configApiUrl = error?.response?.config?.url

  const config = JSONParse(error?.response?.config?.data)
  const message = JSONParse<{ code: string }>(response?.response?.data?.message || '')
  const confirmationCode = (response?.response?.data?.code || message?.code) as errorOptions
  const isMaintenanceMode = isMaintenance(response)
  const maintenance = JSONParse<MaintenanceType>(
    response?.response?.headers?.['x-maintenance'] ||
      response?.response?.headers?.['X-Maintenance'] ||
      ''
  )
  return {
    response,
    status,
    config,
    message,
    confirmationCode,
    configApiUrl,
    isMaintenanceMode,
    maintenance: isMaintenanceMode
      ? maintenance ?? { Message: 'Scheduled Maintenance', StartDateTime: '', EndDateTime: '' }
      : undefined,
  }
}

export const getPspServiceCheckErrorMessage = (error: ResponseError): string | undefined => {
  const { response } = getErrorResponseData(error)
  const messages = response?.response?.data?.message?.split(';') || []
  const [, message] = messages.filter((x: string) => !!x).map((x: string) => x.trim())
  return message
}

export const isIpApiCall = (error: ResponseError): boolean => {
  const { configApiUrl } = getErrorResponseData(error)
  return !!configApiUrl?.includes('ipapi')
}

export const isMaintenance = (response: AxiosError): boolean => {
  return (
    isServiceUnavailableStatus(response?.response?.status) &&
    (!!response?.response?.headers?.['x-maintenance'] ||
      !!response?.response?.headers?.['X-Maintenance'])
  )
}
