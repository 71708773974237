import { IconProps, iconColor } from './IconProps'

export function DocGeneralIcon(props: IconProps): React.ReactElement {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.4998 16.7143V5.14301C15.5021 5.05853 15.4858 4.97455 15.4522 4.89687C15.4185 4.81919 15.3682 4.74964 15.3048 4.69302L10.7549 0.193073C10.6976 0.130296 10.6273 0.0805429 10.5488 0.0472476C10.4702 0.0139523 10.3853 -0.00209301 10.2999 0.000218595H1.79998C1.45552 0.00123605 1.12546 0.13702 0.881891 0.377915C0.638321 0.61881 0.501029 0.945242 0.5 1.28592V16.7143C0.5 17.0553 0.636962 17.3823 0.880755 17.6234C1.12455 17.8645 1.4552 18 1.79998 18H14.2141C14.9242 18 15.4998 17.4244 15.4998 16.7143H1.79998V1.28592H8.99989V5.14301C9.00092 5.48369 9.13821 5.81012 9.38178 6.05102C9.62535 6.29191 9.95541 6.42769 10.2999 6.42871H14.1998V16.7143H15.4998ZM10.2999 5.14301V1.54306L13.9398 5.14301H10.2999Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
