import React from 'react'

import FileUpload, { FileUploadProps } from './FileUpload'
import { DocumentFileExtensionType, getImageFileExtensions } from './types'

export const DocumentFileUpload: React.FC<FileUploadProps> = (props) => {
  return (
    <FileUpload
      {...props}
      acceptType={[...getImageFileExtensions(), DocumentFileExtensionType.PDF]}
    />
  )
}
