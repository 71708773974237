import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table } from '../../global/table/Table'
import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { ClientRebateCampaignDto } from '../../model/CampaignResultDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { getCentralRowIdConditionally } from '../../utils/getItemId'
import { wait } from '../../utils/wait'
import { CampaignStatus } from './campaign.utils'

import styles from './CampaignResultsTable.module.scss'

interface Props {
  campaignResults: ClientRebateCampaignDto[]
  setSelectedCampaign(campaignResult: ClientRebateCampaignDto): void
}

export const CampaignResultsTable: React.FC<Props> = (props) => {
  const { campaignResults, setSelectedCampaign } = props

  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const { scrollIntoView } = useScrollToElementIds()

  const dateFormat = auth?.dateFormatType?.name

  return (
    <Table>
      <thead>
        <tr>
          <SortHeader>{t('Campaigns.Campaign name')}</SortHeader>
          <SortHeader align='center' className={styles.column}>
            {t('Campaigns.Campaign Account No')}
          </SortHeader>
          <SortHeader align='center' className={styles.column}>
            {t('Campaigns.Start Date')}
          </SortHeader>
          <SortHeader align='center' className={styles.column}>
            {t('Campaigns.End Date')}
          </SortHeader>
          <SortHeader align='center' className={styles.column}>
            {t('Campaigns.Currency')}
          </SortHeader>
          <SortHeader align='center' className={styles.column}>
            <span className='is-flex is-justify-content-center'>{t('Campaigns.Status')}</span>
          </SortHeader>
          <SortHeader align='center' className={styles.column}>
            <span className='is-flex is-justify-content-center'>{t('Campaigns.Results')}</span>
          </SortHeader>
        </tr>
      </thead>
      <tbody>
        {campaignResults.map((x, index) => {
          return (
            <tr
              key={index}
              {...getCentralRowIdConditionally(index, campaignResults.length, 'campaign-results')}
            >
              <td>{x.name}</td>
              <td align='center'>{x.account && <CampaignAccountNo {...x} />}</td>
              <td align='center'>
                {formatDate(x.from, { formatType: dateFormat, withTime: false })}
              </td>
              <td align='center'>
                {formatDate(x.to, { formatType: dateFormat, withTime: false })}
              </td>
              <td align='center'>{x.currency.id}</td>
              <td align='center'>
                <CampaignStatus {...x} />
              </td>
              <td align='center'>
                <span className='is-link' onClick={() => setSelectedCampaign(x)}>
                  {t('View')}
                </span>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

const CampaignAccountNo: React.FC<ClientRebateCampaignDto> = (props) => {
  if (props.disqualified) {
    return <span className={classNames(styles.strikethrough)}>{getCampaignAccountNo(props)}</span>
  }

  return <span>{getCampaignAccountNo(props)}</span>
}

const getCampaignAccountNo = (campaign: ClientRebateCampaignDto) => {
  return `${campaign.currency.id}-${campaign.account}`
}
