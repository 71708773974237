import React from 'react'

export function PaymentProcessingIcon(props: { size?: number }) {
  const size = props.size || 64

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 64 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='icon/pending'>
        <path
          id='Ellipse 9'
          d='M32 55.9984C29.1949 55.9984 26.5022 55.5171 24 54.6327C14.6785 51.338 8 42.4481 8 31.9984C8 25.5575 10.5372 19.7092 14.6667 15.3984'
          stroke='#F19007'
          strokeWidth='3'
          strokeLinecap='round'
          strokeDasharray='5 5'
        />
        <path
          id='Ellipse 10'
          d='M31.9993 56C45.2542 56 55.9993 45.2548 55.9993 32C55.9993 18.7452 45.2542 8 31.9993 8C25.1854 8 19.0347 10.8396 14.666 15.4001'
          stroke='#F19007'
          strokeWidth='3'
          strokeLinecap='round'
        />
        <path
          id='Vector 20'
          d='M31.9993 17.3307V31.9974L39.9993 37.3307'
          stroke='#F19007'
          strokeWidth='3'
          strokeLinecap='round'
        />
      </g>
    </svg>
  )
}
