import { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { InfoIcon } from '../../icons/InfoIcon'
import { SubscriptionRequestTypeEnum } from '../../model/SubscriptionRequestTypeEnum'
import { Subscription, isCancellationRequested } from '../../model/SubscriptionTypes'
import { SubscriptionTimeInForce } from '../../model/UpdateSubscriptionRequest'
import { TextSmall } from '../../ui/Typography/Typography'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import {
  getPendingAddNewSubscriptionLevel,
  getPendingCancelSubscriptionLevel,
  getPendingUpdateSubscriptionLevel,
} from './subscriptionUtils'

interface SubscriptionDetailMessageProps {
  selectedSubscription: Subscription | undefined
  onSetPendingUnsubscriptionInfoModal: (value: boolean) => void
}

export const SubscriptionDetailMessage: React.FC<SubscriptionDetailMessageProps> = ({
  selectedSubscription,
  onSetPendingUnsubscriptionInfoModal,
}) => {
  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType.name

  const pendingSubscriptionLevel = useMemo(
    () =>
      getPendingCancelSubscriptionLevel(selectedSubscription) ||
      getPendingUpdateSubscriptionLevel(selectedSubscription) ||
      getPendingAddNewSubscriptionLevel(selectedSubscription),
    [selectedSubscription]
  )

  if (!pendingSubscriptionLevel || !pendingSubscriptionLevel.subscriptionRequest) {
    return null
  }

  const { subscriptionRequest, name } = pendingSubscriptionLevel
  const { type, dueDate } = subscriptionRequest
  const isSubscriptionStartingToday =
    format(new Date(), dateFormat || 'yyyy-MM-dd') ===
    formatDate(dueDate, { formatType: dateFormat })

  if (isSubscriptionStartingToday) {
    return null
  }

  if (isCancellationRequested(subscriptionRequest)) {
    return (
      <>
        <TextSmall className='pl-1 has-text-success'>
          {t('Subscriptions.Active until', {
            date: formatDate(dueDate, { formatType: dateFormat }),
          })}
        </TextSmall>
        <div className='is-flex mt-1'>
          <TextSmall className='has-text-warning'>
            {t('Subscriptions.Pending Unsubscription')}
          </TextSmall>

          <span
            className='ml-2 pt-1'
            onClick={() => {
              onSetPendingUnsubscriptionInfoModal(true)
            }}
          >
            <InfoIcon />
          </span>
        </div>
      </>
    )
  } else if (type.name === SubscriptionRequestTypeEnum.UpdateExistingSubscription) {
    return (
      <p className='has-text-warning'>
        <TextSmall>
          {`${name} ${t('Subscriptions.Starting', {
            firstDateOfNextMonth: formatDate(dueDate, { formatType: dateFormat }),
          })}`}
        </TextSmall>
      </p>
    )
  } else if (
    type.name === SubscriptionRequestTypeEnum.AddNewSubscription ||
    subscriptionRequest?.subscriptionTimeInForce?.id ===
      SubscriptionTimeInForce.BeginningOfNextMonth
  ) {
    return (
      <p className='has-text-warning'>
        <TextSmall>
          {t('Subscriptions.Starting', {
            firstDateOfNextMonth: formatDate(dueDate, { formatType: dateFormat }),
          })}
        </TextSmall>
      </p>
    )
  }

  return null
}
