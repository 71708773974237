import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMUKLogoMobile: FC = () => (
  <svg width='120' height='30' viewBox='0 0 120 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M53.091 16.4632C54.582 16.4632 55.8183 15.8813 56.7638 14.6813L58.7274 16.6813C57.1638 18.4268 55.3456 19.2995 53.2729 19.2995C51.1638 19.2995 49.4547 18.645 48.0729 17.2995C46.7274 15.9904 46.0365 14.2813 46.0365 12.2813C46.0365 10.245 46.7274 8.57225 48.1092 7.22679C49.491 5.88134 51.2001 5.19043 53.2001 5.19043C55.4547 5.19043 57.3092 6.02679 58.8365 7.73588L56.9456 9.88134C56.0001 8.68134 54.8001 8.09952 53.3456 8.09952C52.2183 8.09952 51.2365 8.46316 50.4001 9.22679C49.6001 9.99043 49.1638 10.9722 49.1638 12.245C49.1638 13.5177 49.5638 14.5359 50.3274 15.2995C51.091 16.0632 52.0001 16.4632 53.091 16.4632Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M34.3637 19.154V8.13587H38.2182V5.51768H27.4546V8.13587H31.3091V19.154H34.3637Z'
      fill='var(--logo-text-color)'
    />
    <path d='M43.4909 5.51768H40.4364V19.154H43.4909V5.51768Z' fill='var(--logo-text-color)' />
    <path
      d='M64.1819 5.51768H61.1273V19.154H64.1819V15.2995L65.5637 13.7722L69.5637 19.154H73.1273C72.789 18.6764 72.3526 18.0681 71.8183 17.3231L71.8139 17.317C71.3729 16.7022 70.8652 15.9944 70.291 15.1904C69.1658 13.5689 68.3418 12.4292 67.8464 11.744L67.7091 11.554L73.1273 5.51768H69.3455L64.1819 11.1177V5.51768Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M87.2 10.5722L83.5273 18.0268H81.7091L78.0364 10.5722V19.154H74.9818V5.51768H79.0909L82.6182 13.0086L86.1454 5.51768H90.2545V19.154H87.2V10.5722Z'
      fill='var(--logo-text-color)'
    />
    <path d='M96.6545 5.51768H93.6V19.154H96.6545V5.51768Z' fill='var(--logo-text-color)' />
    <path
      d='M99.9999 19.154V5.51768H103.054V16.4268H108.873V19.154H99.9999Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M111.091 5.51768V19.154H119.964V16.4268H114.145V5.51768H111.091Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M5.78182 9.00909C5.56364 9.15455 5.38182 9.48182 5.38182 9.73636H5.45455V14.2455L0 17.3727V7.19091C0 6.53636 0.472727 5.73636 1.01818 5.40909L9.74546 0.245455C10.2909 -0.0818182 11.2 -0.0818182 11.7455 0.245455L20.4727 5.40909C20.5091 5.40909 20.5455 5.44546 20.5818 5.48182L15.0545 8.68182L11.1273 6.39091C10.8727 6.24545 10.5091 6.24545 10.2909 6.39091L5.78182 9.00909Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M16.0002 14.9364V10.4273L21.382 7.30005V17.4819C21.382 18.1364 20.9093 18.9364 20.3638 19.2637L11.6365 24.4273C11.0911 24.7546 10.182 24.7546 9.63653 24.4273L0.909262 19.2637C0.872901 19.2637 0.836539 19.2273 0.800178 19.191L6.32744 15.991L10.2547 18.2819C10.5093 18.4273 10.8729 18.4273 11.0911 18.2819L15.6002 15.6637C15.8184 15.5182 16.0002 15.191 16.0002 14.9364Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M48.72 29.1698C48.02 29.1698 47.475 28.9698 47.085 28.5698C46.695 28.1698 46.5 27.5948 46.5 26.8448V23.8598H47.25V26.8148C47.25 27.9398 47.7425 28.5023 48.7275 28.5023C49.2075 28.5023 49.575 28.3648 49.83 28.0898C50.085 27.8098 50.2125 27.3848 50.2125 26.8148V23.8598H50.94V26.8448C50.94 27.5998 50.745 28.1773 50.355 28.5773C49.965 28.9723 49.42 29.1698 48.72 29.1698Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M57.2926 23.8598V29.1098H56.6776L53.5276 25.1948V29.1098H52.7776V23.8598H53.3926L56.5426 27.7748V23.8598H57.2926Z'
      fill='var(--logo-text-color)'
    />
    <path d='M59.164 23.8598H59.914V29.1098H59.164V23.8598Z' fill='var(--logo-text-color)' />
    <path
      d='M62.8283 24.5123H61.0283V23.8598H65.3708V24.5123H63.5708V29.1098H62.8283V24.5123Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M70.2977 28.4573V29.1098H66.4877V23.8598H70.1927V24.5123H67.2377V26.1248H69.8702V26.7623H67.2377V28.4573H70.2977Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M71.8121 23.8598H74.0246C74.5796 23.8598 75.0721 23.9698 75.5021 24.1898C75.9321 24.4098 76.2646 24.7198 76.4996 25.1198C76.7396 25.5148 76.8596 25.9698 76.8596 26.4848C76.8596 26.9998 76.7396 27.4573 76.4996 27.8573C76.2646 28.2523 75.9321 28.5598 75.5021 28.7798C75.0721 28.9998 74.5796 29.1098 74.0246 29.1098H71.8121V23.8598ZM73.9796 28.4573C74.4046 28.4573 74.7771 28.3748 75.0971 28.2098C75.4221 28.0448 75.6721 27.8148 75.8471 27.5198C76.0221 27.2198 76.1096 26.8748 76.1096 26.4848C76.1096 26.0948 76.0221 25.7523 75.8471 25.4573C75.6721 25.1573 75.4221 24.9248 75.0971 24.7598C74.7771 24.5948 74.4046 24.5123 73.9796 24.5123H72.5621V28.4573H73.9796Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M82.3325 26.7773L81.3725 27.7523V29.1098H80.6225V23.8598H81.3725V26.7923L84.2225 23.8598H85.0775L82.835 26.2223L85.22 29.1098H84.3425L82.3325 26.7773Z'
      fill='var(--logo-text-color)'
    />
    <path d='M86.3132 23.8598H87.0632V29.1098H86.3132V23.8598Z' fill='var(--logo-text-color)' />
    <path
      d='M93.4499 23.8598V29.1098H92.8349L89.6849 25.1948V29.1098H88.9349V23.8598H89.5499L92.6999 27.7748V23.8598H93.4499Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M98.9589 26.4548H99.6789V28.5023C99.4189 28.7173 99.1164 28.8823 98.7714 28.9973C98.4264 29.1123 98.0664 29.1698 97.6914 29.1698C97.1614 29.1698 96.6839 29.0548 96.2589 28.8248C95.8339 28.5898 95.4989 28.2698 95.2539 27.8648C95.0139 27.4548 94.8939 26.9948 94.8939 26.4848C94.8939 25.9748 95.0139 25.5148 95.2539 25.1048C95.4989 24.6948 95.8339 24.3748 96.2589 24.1448C96.6889 23.9148 97.1714 23.7998 97.7064 23.7998C98.1264 23.7998 98.5064 23.8698 98.8464 24.0098C99.1914 24.1448 99.4839 24.3448 99.7239 24.6098L99.2589 25.0748C98.8389 24.6698 98.3314 24.4673 97.7364 24.4673C97.3364 24.4673 96.9764 24.5548 96.6564 24.7298C96.3414 24.8998 96.0939 25.1398 95.9139 25.4498C95.7339 25.7548 95.6439 26.0998 95.6439 26.4848C95.6439 26.8648 95.7339 27.2098 95.9139 27.5198C96.0939 27.8248 96.3414 28.0648 96.6564 28.2398C96.9764 28.4148 97.3339 28.5023 97.7289 28.5023C98.1989 28.5023 98.6089 28.3898 98.9589 28.1648V26.4548Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M101.415 23.8598H103.627C104.182 23.8598 104.675 23.9698 105.105 24.1898C105.535 24.4098 105.867 24.7198 106.102 25.1198C106.342 25.5148 106.462 25.9698 106.462 26.4848C106.462 26.9998 106.342 27.4573 106.102 27.8573C105.867 28.2523 105.535 28.5598 105.105 28.7798C104.675 28.9998 104.182 29.1098 103.627 29.1098H101.415V23.8598ZM103.582 28.4573C104.007 28.4573 104.38 28.3748 104.7 28.2098C105.025 28.0448 105.275 27.8148 105.45 27.5198C105.625 27.2198 105.712 26.8748 105.712 26.4848C105.712 26.0948 105.625 25.7523 105.45 25.4573C105.275 25.1573 105.025 24.9248 104.7 24.7598C104.38 24.5948 104.007 24.5123 103.582 24.5123H102.165V28.4573H103.582Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M110.281 29.1698C109.751 29.1698 109.274 29.0548 108.849 28.8248C108.424 28.5898 108.089 28.2673 107.844 27.8573C107.604 27.4473 107.484 26.9898 107.484 26.4848C107.484 25.9798 107.604 25.5223 107.844 25.1123C108.089 24.7023 108.424 24.3823 108.849 24.1523C109.274 23.9173 109.751 23.7998 110.281 23.7998C110.806 23.7998 111.281 23.9173 111.706 24.1523C112.131 24.3823 112.464 24.7023 112.704 25.1123C112.944 25.5173 113.064 25.9748 113.064 26.4848C113.064 26.9948 112.944 27.4548 112.704 27.8648C112.464 28.2698 112.131 28.5898 111.706 28.8248C111.281 29.0548 110.806 29.1698 110.281 29.1698ZM110.281 28.5023C110.666 28.5023 111.011 28.4148 111.316 28.2398C111.626 28.0648 111.869 27.8248 112.044 27.5198C112.224 27.2098 112.314 26.8648 112.314 26.4848C112.314 26.1048 112.224 25.7623 112.044 25.4573C111.869 25.1473 111.626 24.9048 111.316 24.7298C111.011 24.5548 110.666 24.4673 110.281 24.4673C109.896 24.4673 109.546 24.5548 109.231 24.7298C108.921 24.9048 108.676 25.1473 108.496 25.4573C108.321 25.7623 108.234 26.1048 108.234 26.4848C108.234 26.8648 108.321 27.2098 108.496 27.5198C108.676 27.8248 108.921 28.0648 109.231 28.2398C109.546 28.4148 109.896 28.5023 110.281 28.5023Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M119.377 29.1098L119.37 25.2848L117.472 28.4723H117.127L115.23 25.3073V29.1098H114.51V23.8598H115.125L117.315 27.5498L119.475 23.8598H120.09L120.097 29.1098H119.377Z'
      fill='var(--logo-text-color)'
    />
  </svg>
)
