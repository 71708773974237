import React, { FC } from 'react'
import { useFormikContext } from 'formik'

import { createFormCurrencyAmountField } from '../../../../global/formField/CurrencyAmountRateField'
import { WalletDepositFormValues } from './WalletDepositForm'

const FormCurrencyAmountField = createFormCurrencyAmountField<WalletDepositFormValues>()

export const CurrencyAmountField: FC = () => {
  const { values } = useFormikContext<WalletDepositFormValues>()

  const fromCurrency = values.wallet.currency.id
  const toCurrency = values.paymentProvider.currency.id

  return (
    <FormCurrencyAmountField
      name='amount'
      fromCurrency={toCurrency}
      toCurrency={fromCurrency}
      required
    />
  )
}
