import { useCallback } from 'react'

import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import { PaymentProvider } from '../../../model/PaymentProviderDto'
import { TradingAccountDetailDto } from '../../../model/TradingAccountDetailDto'
import { WalletPaymentProvider } from '../../../model/WalletPaymentProvider'
import { WalletPaymentProviderParameterMessage } from '../../../model/WalletPaymentProviderParameters'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import {
  TradingAccount,
  getCurrencyLimits,
} from './TradingAccountDepositForm/TradingAccountDepositForm'

export const useTradingAccountPaymentProviderFactory = () => {
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const createPaymentProvider = useCallback(
    async (
      walletPaymentProvider: WalletPaymentProvider,
      tradingAccount: TradingAccount | TradingAccountDetailDto
    ) => {
      const walletPaymentProviderParameters = await apiClient.getWalletDepositMethodParameters(
        locale,
        tradingAccount.wallet.id,
        walletPaymentProvider.method.id
      )
      const paymentProvider: PaymentProvider = {
        id: walletPaymentProvider.method.id,
        name: walletPaymentProvider.name,
        method_name: walletPaymentProvider.method.name,
        providerCategory: walletPaymentProvider.providerCategory,
        currency: {
          id: walletPaymentProvider.currency.id,
          ...walletPaymentProvider,
        },
        parameters: walletPaymentProviderParameters,
      }

      return {
        ...paymentProvider,
        parameters: {
          ...walletPaymentProviderParameters,
          messages: walletPaymentProviderParameters.messages.map((x) =>
            formatWalletDepositWarningMessage(x, paymentProvider)
          ),
        },
      }
    },
    [locale, apiClient]
  )

  return { createPaymentProvider }
}

const formatWalletDepositWarningMessage = (
  message: WalletPaymentProviderParameterMessage,
  paymentProvider: PaymentProvider
): WalletPaymentProviderParameterMessage => {
  const currencyLimit = getCurrencyLimits(paymentProvider)
  const minAmount = currencyLimit?.minAmount?.toString() || '0'
  const maxAmount = currencyLimit?.maxAmount?.toString() || '0'
  const currency = paymentProvider.currency.id || ''

  return {
    ...message,
    list: message.list.map((x) =>
      x
        .replace(/{minAmount}/g, minAmount)
        .replace(/{maxAmount}/g, maxAmount)
        .replace(/{paymentProviderCurrency}/g, currency)
    ),
  }
}
