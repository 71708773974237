import styles from './Icons.module.scss'

export function TradingPlatformIcon() {
  return (
    <svg width='59' height='50' viewBox='0 0 59 50' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.18238 30.4634C5.05629 30.4634 5.76474 31.1623 5.76474 32.0244V47.6341C5.76474 48.4962 5.05629 49.1951 4.18238 49.1951C3.30847 49.1951 2.60002 48.4962 2.60002 47.6341V32.0244C2.60002 31.1623 3.30847 30.4634 4.18238 30.4634Z'
        fill={styles.contrastMediumLow}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8412 22.2683C17.7152 22.2683 18.4236 22.9672 18.4236 23.8293V47.6341C18.4236 48.4962 17.7152 49.1951 16.8412 49.1951C15.9673 49.1951 15.2589 48.4962 15.2589 47.6341V23.8293C15.2589 22.9672 15.9673 22.2683 16.8412 22.2683Z'
        fill={styles.contrastMediumLow}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.5001 28.9024C30.374 28.9024 31.0825 29.6013 31.0825 30.4634V47.6341C31.0825 48.4962 30.374 49.1951 29.5001 49.1951C28.6262 49.1951 27.9178 48.4962 27.9178 47.6341V30.4634C27.9178 29.6013 28.6262 28.9024 29.5001 28.9024Z'
        fill={styles.contrastMediumLow}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M42.159 21.0976C43.0329 21.0976 43.7413 21.7964 43.7413 22.6585V47.6341C43.7413 48.4962 43.0329 49.1951 42.159 49.1951C41.2851 49.1951 40.5766 48.4962 40.5766 47.6341V22.6585C40.5766 21.7964 41.2851 21.0976 42.159 21.0976Z'
        fill={styles.contrastMediumLow}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M54.8179 13.2927C55.6918 13.2927 56.4002 13.9915 56.4002 14.8537V47.6341C56.4002 48.4962 55.6918 49.1951 54.8179 49.1951C53.9439 49.1951 53.2355 48.4962 53.2355 47.6341V14.8537C53.2355 13.9915 53.944 13.2927 54.8179 13.2927Z'
        fill={styles.contrastMediumLow}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M55.7676 3.49572C56.2277 4.22871 55.9982 5.19081 55.2552 5.64462L31.0944 20.4008C30.1495 20.9779 28.9671 21.0238 27.9792 20.5218L16.5423 14.7098L4.65889 22.4132C3.92855 22.8867 2.94744 22.6864 2.46751 21.9659C1.98758 21.2455 2.19058 20.2776 2.92092 19.8042L14.8044 12.1007C15.7586 11.4821 16.9766 11.4186 17.9916 11.9344L29.4285 17.7464L53.5893 2.99021C54.3323 2.5364 55.3076 2.76273 55.7676 3.49572Z'
        fill={styles.contrastMediumLow}
      />
      <path
        d='M58.3782 3.92682C58.3782 5.65103 56.9613 7.04877 55.2135 7.04877C53.4656 7.04877 52.0487 5.65103 52.0487 3.92682C52.0487 2.20262 53.4656 0.804871 55.2135 0.804871C56.9613 0.804871 58.3782 2.20262 58.3782 3.92682Z'
        fill={styles.contrastMediumLow}
      />
      <path
        d='M32.2692 18.7561C32.2692 20.4803 30.8524 21.878 29.1045 21.878C27.3567 21.878 25.9398 20.4803 25.9398 18.7561C25.9398 17.0319 27.3567 15.6341 29.1045 15.6341C30.8524 15.6341 32.2692 17.0319 32.2692 18.7561Z'
        fill={styles.contrastMediumLow}
      />
      <path
        d='M44.9281 11.7317C44.9281 13.4559 43.5112 14.8537 41.7634 14.8537C40.0156 14.8537 38.5987 13.4559 38.5987 11.7317C38.5987 10.0075 40.0156 8.60975 41.7634 8.60975C43.5112 8.60975 44.9281 10.0075 44.9281 11.7317Z'
        fill={styles.contrastMediumLow}
      />
      <path
        d='M19.6104 12.5122C19.6104 14.2364 18.1935 15.6341 16.4457 15.6341C14.6978 15.6341 13.2809 14.2364 13.2809 12.5122C13.2809 10.788 14.6978 9.39024 16.4457 9.39024C18.1935 9.39024 19.6104 10.788 19.6104 12.5122Z'
        fill={styles.contrastMediumLow}
      />
      <path
        d='M6.95151 21.0976C6.95151 22.8218 5.53461 24.2195 3.78679 24.2195C2.03896 24.2195 0.62207 22.8218 0.62207 21.0976C0.62207 19.3733 2.03896 17.9756 3.78679 17.9756C5.53461 17.9756 6.95151 19.3733 6.95151 21.0976Z'
        fill={styles.contrastMediumLow}
      />
    </svg>
  )
}
