import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { useHomePageUrl } from '../../hooks/useHomePageUrl'
import {
  overrideForZHToCNLanguage,
  useOverrideLocaleForENorTR,
  useSessionLanguage,
} from '../context/SessionSettingsContext'
import { CancelActionModal } from './CancleActionModal'

interface Props {
  title: string
  renderFooter?(): React.ReactNode
  renderBody(): React.ReactNode
  onCancel(): void
  onConfirm(): void
}

export const BlogModal: React.FC<Props> = ({
  onCancel,
  onConfirm,
  renderBody,
  title,
  renderFooter,
}) => {
  return (
    <CancelActionModal
      title={title}
      footer={
        <BlogModalFooter onCancel={onCancel} onConfirm={onConfirm} renderFooter={renderFooter} />
      }
    >
      {renderBody()}
    </CancelActionModal>
  )
}

const BlogModalFooter: React.FC<Omit<PropsWithChildren<Props>, 'title' | 'renderBody'>> = (
  props
) => {
  const { onCancel, onConfirm, renderFooter } = props

  const { t } = useTranslation()
  const locale = useSessionLanguage()
  const isLocaleENorTR = useOverrideLocaleForENorTR(locale)
  const homePageUrl = useHomePageUrl()

  const blogLink = isLocaleENorTR
    ? `${homePageUrl}/blog`
    : `${homePageUrl}/${overrideForZHToCNLanguage(locale)}/blog`

  if (renderFooter) {
    return <React.Fragment>{renderFooter()}</React.Fragment>
  }

  return (
    <React.Fragment>
      <button className='button' onClick={onConfirm} type='button'>
        {t('Cancel')}
      </button>
      <button
        className='button'
        onClick={() => {
          window.open(blogLink, '_blank')
          onCancel()
        }}
        type='button'
      >
        {t('Proceed')}
      </button>
    </React.Fragment>
  )
}
