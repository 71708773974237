import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export function SmartphoneIcon(props: IconProps) {
  const size = props.size || 24
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_872_1877)'>
        <rect
          x='6'
          y='2'
          width='12'
          height='20'
          rx='1'
          stroke={iconColor(props)}
          strokeWidth='1.5'
        />
        <circle cx='12' cy='18.5' r='1' fill={iconColor(props)} />
      </g>
      <defs>
        <clipPath id='clip0_872_1877'>
          <rect width={size} height={size} fill={styles.textInverse} />
        </clipPath>
      </defs>
    </svg>
  )
}
