import { SubscriptionTimeInForce } from './UpdateSubscriptionRequest'

export interface SubscriptionTypeOption {
  id: SubscriptionTimeInForce
  displayName: string
}

export const subscriptionTypeOptions: SubscriptionTypeOption[] = [
  {
    id: SubscriptionTimeInForce.Immediate,
    displayName: 'Market Data.Today',
  },
  {
    id: SubscriptionTimeInForce.BeginningOfNextMonth,
    displayName: 'Market Data.Next month',
  },
]
