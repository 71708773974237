import { NameDto } from './NameDto'

export interface PlatformInfoDto {
  platfromType: NameDto
  platformLinks: {
    operatingSystem: NameDto
    order: number
    productLink: string
  }[]
  externalPlatformApplications: ExternalPlatformApplication[]
}

interface ExternalPlatformApplication {
  order: number
  name: string
  externalApplication: string
  icon: string
  productLink: string
  infoLink: string
  guides: {
    order: number
    icon: string
    guideLink: string
    name: string
  }[]
}

export enum PlatformExternalApplication {
  FXBLUE = 'FXBLUE',
  ACUITY = 'ACUITY',
}

export const isPlatformExternalApplicationFXBLUE = (
  externalPlatformApplication: ExternalPlatformApplication
) => {
  return externalPlatformApplication.externalApplication === PlatformExternalApplication.FXBLUE
}

export const isPlatformExternalApplicationACUITY = (
  externalPlatformApplication: ExternalPlatformApplication
) => {
  return externalPlatformApplication.externalApplication === PlatformExternalApplication.ACUITY
}
