import { IconProps } from './IconProps'

import styles from './Icons.module.scss'

export function TransferIcon(props: IconProps): JSX.Element {
  const size = props.size || 24
  const strokeWidth = props.strokeWidth || 0.9

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 7H19M19 7L15.5 3.5M19 7L15.5 10.5'
        stroke={props.color ? styles[props.color] : styles.contrastMediumLow}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
      <path
        d='M19 17H5M5 17L8.5 13.5M5 17L8.5 20.5'
        stroke={props.color ? styles[props.color] : styles.contrastMediumLow}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  )
}
