import React from 'react'
import { useNavigate } from 'react-router-dom'

import { WalletDepositTransactionFailedPage } from '../../../Wallets/WalletDeposit/WalletDepositTransactionPage/WalletDepositTransactionFailedPage'

export const TradersRoomTradingAccountDepositTransactionFailedPage: React.FC = () => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/dashboard/traders-room/trading-accounts')
  }

  return <WalletDepositTransactionFailedPage onCancel={handleCancel} />
}
