import styles from './Icons.module.scss'

export function SuccessIcon(props: { size?: number }) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.9999 16.1996L5.4999 12.6996C5.1133 12.313 4.4865 12.313 4.0999 12.6996C3.7133 13.0862 3.7133 13.713 4.0999 14.0996L8.9999 18.9996L20.2999 7.69961C20.6865 7.31301 20.6865 6.68621 20.2999 6.29961C19.9133 5.91301 19.2865 5.91301 18.8999 6.29961L8.9999 16.1996Z'
        fill={styles.success}
      />
    </svg>
  )
}
