import React, { useCallback } from 'react'

import { Loading } from '../../../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { useSignup } from '../../../../../global/context/SignupContext'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { useFetchOne } from '../../../../../utils/useFetch'
import { CompanyDetailsStep1Form, CompanyDetailsStep1FormValues } from './CompanyDetailsStep1Form'

interface OuterProps {
  onSubmit(values: CompanyDetailsStep1FormValues): void
}

export const CompanyDetailsStep1Page: React.FC<OuterProps> = (props) => {
  const { onSubmit } = props
  const { signupData } = useSignup()
  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)

  const countriesCall = useCallback(() => apiClient.getCountries(), [apiClient])
  const countriesTypes = useFetchOne(countriesCall)

  const companyTypesCall = useCallback(() => apiClient.getCompanyTypes(locale), [apiClient, locale])
  const companyTypes = useFetchOne(companyTypesCall)

  const isLoading = countriesTypes.isLoading || companyTypes.isLoading || countriesTypes.isLoading

  return (
    <Loading isLoading={isLoading}>
      <CompanyDetailsStep1Form
        companyTypes={companyTypes.data ?? []}
        countries={countriesTypes.data ?? []}
        initialValues={signupData.corporateValues}
        onSubmit={onSubmit}
      />
    </Loading>
  )
}
