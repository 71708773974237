import React, { PropsWithChildren, useState } from 'react'
import classNames from 'classnames'

import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../icons/ChevronUpIcon'

import styles from './FormWarningMessage.module.scss'

export const FormWarningMessageGroup: React.FC<PropsWithChildren> = (props) => {
  const { children } = props
  return <div className={styles.messagesWrapper}>{children}</div>
}

export interface FormWarningMessageProps {
  message: string | React.ReactNode
}

export const FormWarningMessage: React.FC<FormWarningMessageProps> = (props) => {
  const [isOpen, setOpen] = useState(false)

  const { message } = props

  const handleMessageOpen = () => {
    setOpen(true)
  }

  const handleMessageClose = () => {
    setOpen(false)
  }

  return isOpen ? (
    <div className={styles.messageItem} onClick={handleMessageClose}>
      <p>{message}</p>
      <span className={styles.icon}>
        <ChevronUpIcon />
      </span>
    </div>
  ) : (
    <div
      className={classNames(styles.messageItem, styles.messageHidden)}
      onClick={handleMessageOpen}
    >
      <p>{message}</p>
      <span className={styles.icon}>
        <ChevronDownIcon />
      </span>
    </div>
  )
}
