import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMUKLogo: FC = () => (
  <svg width='160' height='40' viewBox='0 0 160 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M70.7879 21.9512C72.7758 21.9512 74.4243 21.1754 75.6849 19.5754L78.3031 22.2421C76.2182 24.5694 73.794 25.733 71.0303 25.733C68.2182 25.733 65.9394 24.8603 64.097 23.0664C62.3031 21.3209 61.3818 19.0421 61.3818 16.3754C61.3818 13.6603 62.3031 11.43 64.1455 9.63605C65.9879 7.84211 68.2667 6.9209 70.9334 6.9209C73.9394 6.9209 76.4121 8.03605 78.4485 10.3148L75.9273 13.1754C74.6667 11.5754 73.0667 10.7997 71.1273 10.7997C69.6243 10.7997 68.3152 11.2845 67.2 12.3027C66.1334 13.3209 65.5515 14.63 65.5515 16.327C65.5515 18.0239 66.0849 19.3815 67.1031 20.3997C68.1212 21.4179 69.3334 21.9512 70.7879 21.9512Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M45.8181 25.5391V10.8481H50.9575V7.35724H36.606V10.8481H41.7454V25.5391H45.8181Z'
      fill='var(--logo-text-color)'
    />
    <path d='M57.9878 7.35724H53.915V25.5391H57.9878V7.35724Z' fill='var(--logo-text-color)' />
    <path
      d='M85.5757 7.35724H81.5029V25.5391H85.5757V20.3997L87.4181 18.3633L92.7514 25.5391H97.5029C97.0518 24.9022 96.47 24.0911 95.7575 23.0978L95.7517 23.0897C95.1637 22.2699 94.4868 21.3262 93.7211 20.2542C92.2209 18.0922 91.1223 16.5726 90.4617 15.6589L90.2787 15.4057L97.5029 7.35724H92.4605L85.5757 14.8239V7.35724Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M116.266 14.0966L111.37 24.036H108.945L104.048 14.0966V25.5391H99.9756V7.35724H105.454L110.157 17.3451L114.86 7.35724H120.339V25.5391H116.266V14.0966Z'
      fill='var(--logo-text-color)'
    />
    <path d='M128.873 7.35724H124.8V25.5391H128.873V7.35724Z' fill='var(--logo-text-color)' />
    <path
      d='M133.333 25.5391V7.35724H137.406V21.9027H145.163V25.5391H133.333Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M148.121 7.35724V25.5391H159.951V21.9027H152.194V7.35724H148.121Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M7.70909 12.0121C7.41818 12.2061 7.17576 12.6424 7.17576 12.9818H7.27273V18.9939L0 23.1636V9.58788C0 8.71515 0.630303 7.64849 1.35758 7.21212L12.9939 0.327273C13.7212 -0.109091 14.9333 -0.109091 15.6606 0.327273L27.297 7.21212C27.3455 7.21212 27.3939 7.26061 27.4424 7.30909L20.0727 11.5758L14.8364 8.52121C14.497 8.32727 14.0121 8.32727 13.7212 8.52121L7.70909 12.0121Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M21.3336 19.9152V13.9031L28.5093 9.7334V23.3092C28.5093 24.1819 27.879 25.2486 27.1517 25.6849L15.5154 32.5698C14.7881 33.0061 13.576 33.0061 12.8487 32.5698L1.21235 25.6849C1.16387 25.6849 1.11539 25.6364 1.0669 25.588L8.43659 21.3213L13.673 24.3758C14.0124 24.5698 14.4972 24.5698 14.7881 24.3758L20.8002 20.8849C21.0911 20.691 21.3336 20.2546 21.3336 19.9152Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M64.96 38.8934C64.0267 38.8934 63.3 38.6267 62.78 38.0934C62.26 37.5601 62 36.7934 62 35.7934V31.8134H63V35.7534C63 37.2534 63.6567 38.0034 64.97 38.0034C65.61 38.0034 66.1 37.8201 66.44 37.4534C66.78 37.0801 66.95 36.5134 66.95 35.7534V31.8134H67.92V35.7934C67.92 36.8001 67.66 37.5701 67.14 38.1034C66.62 38.6301 65.8933 38.8934 64.96 38.8934Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M76.3902 31.8134V38.8134H75.5702L71.3702 33.5934V38.8134H70.3702V31.8134H71.1902L75.3902 37.0334V31.8134H76.3902Z'
      fill='var(--logo-text-color)'
    />
    <path d='M78.8854 31.8134H79.8854V38.8134H78.8854V31.8134Z' fill='var(--logo-text-color)' />
    <path
      d='M83.7711 32.6834H81.3711V31.8134H87.1611V32.6834H84.7611V38.8134H83.7711V32.6834Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M93.7302 37.9434V38.8134H88.6502V31.8134H93.5902V32.6834H89.6502V34.8334H93.1602V35.6834H89.6502V37.9434H93.7302Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M95.7495 31.8134H98.6995C99.4395 31.8134 100.096 31.9601 100.669 32.2534C101.243 32.5467 101.686 32.9601 101.999 33.4934C102.319 34.0201 102.479 34.6267 102.479 35.3134C102.479 36.0001 102.319 36.6101 101.999 37.1434C101.686 37.6701 101.243 38.0801 100.669 38.3734C100.096 38.6667 99.4395 38.8134 98.6995 38.8134H95.7495V31.8134ZM98.6395 37.9434C99.2061 37.9434 99.7028 37.8334 100.129 37.6134C100.563 37.3934 100.896 37.0867 101.129 36.6934C101.363 36.2934 101.479 35.8334 101.479 35.3134C101.479 34.7934 101.363 34.3367 101.129 33.9434C100.896 33.5434 100.563 33.2334 100.129 33.0134C99.7028 32.7934 99.2061 32.6834 98.6395 32.6834H96.7495V37.9434H98.6395Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M109.777 35.7034L108.497 37.0034V38.8134H107.497V31.8134H108.497V35.7234L112.297 31.8134H113.437L110.447 34.9634L113.627 38.8134H112.457L109.777 35.7034Z'
      fill='var(--logo-text-color)'
    />
    <path d='M115.084 31.8134H116.084V38.8134H115.084V31.8134Z' fill='var(--logo-text-color)' />
    <path
      d='M124.6 31.8134V38.8134H123.78L119.58 33.5934V38.8134H118.58V31.8134H119.4L123.6 37.0334V31.8134H124.6Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M131.945 35.2734H132.905V38.0034C132.558 38.2901 132.155 38.5101 131.695 38.6634C131.235 38.8167 130.755 38.8934 130.255 38.8934C129.548 38.8934 128.912 38.7401 128.345 38.4334C127.778 38.1201 127.332 37.6934 127.005 37.1534C126.685 36.6067 126.525 35.9934 126.525 35.3134C126.525 34.6334 126.685 34.0201 127.005 33.4734C127.332 32.9267 127.778 32.5001 128.345 32.1934C128.918 31.8867 129.562 31.7334 130.275 31.7334C130.835 31.7334 131.342 31.8267 131.795 32.0134C132.255 32.1934 132.645 32.4601 132.965 32.8134L132.345 33.4334C131.785 32.8934 131.108 32.6234 130.315 32.6234C129.782 32.6234 129.302 32.7401 128.875 32.9734C128.455 33.2001 128.125 33.5201 127.885 33.9334C127.645 34.3401 127.525 34.8001 127.525 35.3134C127.525 35.8201 127.645 36.2801 127.885 36.6934C128.125 37.1001 128.455 37.4201 128.875 37.6534C129.302 37.8867 129.778 38.0034 130.305 38.0034C130.932 38.0034 131.478 37.8534 131.945 37.5534V35.2734Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M135.22 31.8134H138.17C138.91 31.8134 139.566 31.9601 140.14 32.2534C140.713 32.5467 141.156 32.9601 141.47 33.4934C141.79 34.0201 141.95 34.6267 141.95 35.3134C141.95 36.0001 141.79 36.6101 141.47 37.1434C141.156 37.6701 140.713 38.0801 140.14 38.3734C139.566 38.6667 138.91 38.8134 138.17 38.8134H135.22V31.8134ZM138.11 37.9434C138.676 37.9434 139.173 37.8334 139.6 37.6134C140.033 37.3934 140.366 37.0867 140.6 36.6934C140.833 36.2934 140.95 35.8334 140.95 35.3134C140.95 34.7934 140.833 34.3367 140.6 33.9434C140.366 33.5434 140.033 33.2334 139.6 33.0134C139.173 32.7934 138.676 32.6834 138.11 32.6834H136.22V37.9434H138.11Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M147.041 38.8934C146.335 38.8934 145.698 38.7401 145.131 38.4334C144.565 38.1201 144.118 37.6901 143.791 37.1434C143.471 36.5967 143.311 35.9867 143.311 35.3134C143.311 34.6401 143.471 34.0301 143.791 33.4834C144.118 32.9367 144.565 32.5101 145.131 32.2034C145.698 31.8901 146.335 31.7334 147.041 31.7334C147.741 31.7334 148.375 31.8901 148.941 32.2034C149.508 32.5101 149.951 32.9367 150.271 33.4834C150.591 34.0234 150.751 34.6334 150.751 35.3134C150.751 35.9934 150.591 36.6067 150.271 37.1534C149.951 37.6934 149.508 38.1201 148.941 38.4334C148.375 38.7401 147.741 38.8934 147.041 38.8934ZM147.041 38.0034C147.555 38.0034 148.015 37.8867 148.421 37.6534C148.835 37.4201 149.158 37.1001 149.391 36.6934C149.631 36.2801 149.751 35.8201 149.751 35.3134C149.751 34.8067 149.631 34.3501 149.391 33.9434C149.158 33.5301 148.835 33.2067 148.421 32.9734C148.015 32.7401 147.555 32.6234 147.041 32.6234C146.528 32.6234 146.061 32.7401 145.641 32.9734C145.228 33.2067 144.901 33.5301 144.661 33.9434C144.428 34.3501 144.311 34.8067 144.311 35.3134C144.311 35.8201 144.428 36.2801 144.661 36.6934C144.901 37.1001 145.228 37.4201 145.641 37.6534C146.061 37.8867 146.528 38.0034 147.041 38.0034Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M159.17 38.8134L159.16 33.7134L156.63 37.9634H156.17L153.64 33.7434V38.8134H152.68V31.8134H153.5L156.42 36.7334L159.3 31.8134H160.12L160.13 38.8134H159.17Z'
      fill='var(--logo-text-color)'
    />
  </svg>
)
