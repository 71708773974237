import React from 'react'

import { TickmillCompaniesEnum } from '../utils/companyName.utils'
import { useWindowResize } from '../utils/domUtils'
import { IconProps } from './IconProps'
import { TMAsiaLogo } from './Logos/TMAsiaLogo'
import { TMAsiaLogoMobile } from './Logos/TMAsiaLogoMobile'
import { TMEuropeLogo } from './Logos/TMEuropeLogo'
import { TMEuropeLogoMobile } from './Logos/TMEuropeLogoMobile'
import { TMGenericLogo } from './Logos/TMGenericLogo'
import { TMGenericLogoMobile } from './Logos/TMGenericLogoMobile'
import { TMPartnersLogo } from './Logos/TMPartnersLogo'
import { TMPartnersLogoMobile } from './Logos/TMPartnersLogoMobile'
import { TMSeychellesLogo } from './Logos/TMSeychellesLogo'
import { TMSeychellesLogoMobile } from './Logos/TMSeychellesLogoMobile'
import { TMUKLogo } from './Logos/TMUKLogo'
import { TMUKLogoMobile } from './Logos/TMUKLogoMobile'

export const TMEntityLogo: Record<TickmillCompaniesEnum, React.FC<IconProps>> = {
  [TickmillCompaniesEnum.GENERIC]: () => <TMGenericLogo />,
  [TickmillCompaniesEnum.TICKMILL_PA]: () => <TMPartnersLogo />,
  [TickmillCompaniesEnum.TICKMILL_AS]: () => <TMAsiaLogo />,
  [TickmillCompaniesEnum.TICKMILL_SC]: () => <TMSeychellesLogo />,
  [TickmillCompaniesEnum.TICKMILL_EU]: () => <TMEuropeLogo />,
  [TickmillCompaniesEnum.TICKMILL_UK]: () => <TMUKLogo />,
}

export const TMEntityMobileLogo: Record<TickmillCompaniesEnum, React.FC<IconProps>> = {
  [TickmillCompaniesEnum.GENERIC]: () => <TMGenericLogoMobile />,
  [TickmillCompaniesEnum.TICKMILL_PA]: () => <TMPartnersLogoMobile />,
  [TickmillCompaniesEnum.TICKMILL_AS]: () => <TMAsiaLogoMobile />,
  [TickmillCompaniesEnum.TICKMILL_SC]: () => <TMSeychellesLogoMobile />,
  [TickmillCompaniesEnum.TICKMILL_EU]: () => <TMEuropeLogoMobile />,
  [TickmillCompaniesEnum.TICKMILL_UK]: () => <TMUKLogoMobile />,
}

interface TMEntityLogoFactoryProps {
  variant: TickmillCompaniesEnum
}

export const TMEntityLogoFactory: React.FC<TMEntityLogoFactoryProps> = (props) => {
  const { variant } = props

  const isMobile = useWindowResize()

  if (isMobile) {
    const Logo = TMEntityMobileLogo[variant]
    return <Logo />
  } else {
    const Logo = TMEntityLogo[variant]
    return <Logo />
  }
}
