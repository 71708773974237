import classNames from 'classnames'

import styles from './AlertIconWrapper.module.scss'

interface AlertIconWrapperProps {
  children: React.ReactNode
  isActive?: boolean
  spaced?: boolean
  fullWidth?: boolean
}

export const AlertIconWrapper = (props: AlertIconWrapperProps): JSX.Element => {
  const { children, isActive, spaced, fullWidth } = props

  return (
    <div
      className={classNames(styles.alertIconWrapper, {
        [styles.active]: isActive,
        [styles.spaced]: spaced,
        [styles.fullWidth]: fullWidth,
      })}
    >
      {children}
    </div>
  )
}
