import React from 'react'
import { useTranslation } from 'react-i18next'

import { Modal } from './Modal'
import { ProceedModal } from './ProceedModal'

interface PaymentProviderModalProps {
  onProceed(): void

  onClose(): void
}

export const PaymentProviderProceedModal: React.FC<PaymentProviderModalProps> = ({
  onProceed,
  onClose,
}) => {
  const handleProceed = () => {
    onProceed()
  }

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onClose}
      render={() => (
        <ProceedModal
          title={t('3rd party service provider Flow')}
          addition={t(
            'Clients must ONLY make payments or withdrawals using their Crypto Wallet(s) directly from Tickmills Client Area.'
          )}
          onProceed={handleProceed}
        />
      )}
    />
  )
}
