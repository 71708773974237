import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useProductReadContext } from '../global/context/ProductContext'
import {
  overrideForZHToCNLanguage,
  useSessionLanguage,
} from '../global/context/SessionSettingsContext'
import { RouteConfig } from '../routing/RenderRoutes'
import { isDepositBeforeKYC } from '../utils/AccountAccess/accountKYCStatuses'
import {
  hasProductAppropriatenessTestSubmittedStatus,
  hasProductEmailVerifiedStatus,
} from '../utils/AccountAccess/accountProductStatuses'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { isTickmillPartner } from '../utils/companyName.utils'
import { GettingStartedETDPage } from './GettingStarted/GettingStartedETDPage'
import { GettingStartedPage } from './GettingStarted/GettingStartedPage'
import { Learning } from './Learning'
import { LearningETD } from './LearningETD'
import { LearningVideos } from './LearningVideos/LearningVideos'
import { TrainingPage } from './Trainings/TrainingPage'

export const useLearningRoutes = (): RouteConfig[] => {
  const visible = useLearningRoutesConditions()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const languageId = overrideForZHToCNLanguage(locale)

  return useMemo(
    (): RouteConfig[] => [
      {
        path: 'learning',
        outlet: <Learning />,
        condition: visible.isLearningAccessible && isDefaultCFDProductType(),
        children: [
          {
            path: 'trainings',
            element: <TrainingPage />,
            condition: visible.isLearningAccessible,
          },
          {
            path: 'getting-started',
            element: <GettingStartedPage />,
            condition: visible.isGettingStartedVisible,
          },
        ],
      },
      {
        path: 'learning',
        outlet: <LearningETD />,
        condition: visible.isLearningAccessible && !isDefaultCFDProductType(),
        children: [
          {
            path: 'getting-started',
            element: <GettingStartedETDPage />,
            condition: visible.isGettingStartedVisible,
          },
          {
            path: 'cqg-education-videos',
            element: (
              <LearningVideos
                title={t('Learning.CQG Education Videos')}
                fetchVideos={() => apiClient.getEducationalVideos(languageId)}
              />
            ),
          },
          {
            path: 'tutorial-videos',
            condition: visible.isLearningAccessible,
            element: (
              <LearningVideos
                title={t('Learning.Tutorial Videos')}
                showGroups
                fetchVideos={() => apiClient.getTutorialVideos(languageId)}
              />
            ),
          },
        ],
      },
    ],
    [
      apiClient,
      isDefaultCFDProductType,
      languageId,
      t,
      visible.isGettingStartedVisible,
      visible.isLearningAccessible,
    ]
  )
}

interface RoutesConditions {
  isLearningVisible: boolean
  isGettingStartedVisible: boolean
  isLearningAccessible: boolean
}
export const useLearningRoutesConditions = (): RoutesConditions => {
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()
  const { isDefaultCFDProductType } = useProductReadContext()

  const isLearningVisible = useMemo(() => {
    return isDefaultCFDProductType()
      ? !!account?.visibilityConfiguration.hasForexTools
      : !!account?.visibilityConfiguration.hasEtdForexTools
  }, [
    account?.visibilityConfiguration.hasEtdForexTools,
    account?.visibilityConfiguration.hasForexTools,
    isDefaultCFDProductType,
  ])

  const isLearningAccessible = useMemo(() => {
    if (
      !isDepositBeforeKYC(account) &&
      (hasProductEmailVerifiedStatus(account, product) ||
        hasProductAppropriatenessTestSubmittedStatus(account, product))
    ) {
      return !!account?.isAppropriatenessTestAccountFailed[product]
    }
    return isDefaultCFDProductType()
      ? !!account?.visibilityConfiguration.hasForexTools
      : !!account?.visibilityConfiguration.hasEtdForexTools
  }, [account, isDefaultCFDProductType, product])

  const isGettingStartedVisible = useMemo(() => {
    if (account?.visibilityConfiguration.hasForexTools) {
      return !isTickmillPartner(account)
    }
    return false
  }, [account])

  return {
    isLearningVisible,
    isGettingStartedVisible,
    isLearningAccessible,
  }
}
