import React from 'react'
import { useTranslation } from 'react-i18next'

import { WalletPaymentProviderCurrency } from '../../model/WalletPaymentProviderParameters'
import { SelectModal, SelectModalOption } from '../field/SelectModal'
import { Modal } from './Modal'

interface WalletModalProps {
  currencies: WalletPaymentProviderCurrency[]

  onSelectOption(option: WalletPaymentProviderCurrency): void

  onClose(): void

  render?({ items }: { items: WalletPaymentProviderCurrency[] }): React.ReactNode

  renderItem?({
    item,
  }: {
    item: WalletPaymentProviderCurrency
    onSelect(wallet: WalletPaymentProviderCurrency): void
  }): React.ReactNode

  title?: string
}

export const CurrencyModal: React.FC<WalletModalProps> = (props) => {
  const { currencies, onSelectOption, onClose, render, renderItem, title } = props

  const handleSelectOption = (currency: WalletPaymentProviderCurrency) => {
    onSelectOption(currency)
  }
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={title || t('Change Currency')}
          renderOptions={() => (
            <div className='control'>
              {render
                ? render({ items: currencies })
                : renderItem &&
                  currencies.map((x) =>
                    renderItem?.({
                      item: x,
                      onSelect: handleSelectOption,
                    })
                  )}
            </div>
          )}
        />
      )}
    />
  )
}

interface CurrencyModalItemProps {
  selected: boolean
  currency: WalletPaymentProviderCurrency

  onSelectOption(option: WalletPaymentProviderCurrency): void
}

export const CurrencyModalItem: React.FC<CurrencyModalItemProps> = (props) => {
  const { selected, currency, onSelectOption } = props

  return (
    <SelectModalOption
      label={currency.id}
      value={selected}
      onClick={() => onSelectOption(currency)}
      key={currency.id}
    />
  )
}
