import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import isEqual from 'lodash.isequal'
import * as XLSX from 'xlsx'

import { TransactionStatusHistoryModal } from '../../Traders-Room/Transaction-History/TransactionStatusHistoryModal'
import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../../global/context/SessionSettingsContext'
import { SelectableModal } from '../../global/field/SelectableModal'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { ConfirmationModal } from '../../global/modal/ConfirmationModal'
import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useMixedText } from '../../hooks/useMixedText'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../hooks/useScrollToElementIds'
import { CloseIcon } from '../../icons/CloseIcon'
import { TransactionDto } from '../../model/TransactionDto'
import { TransactionStatus } from '../../model/TransactionStatus'
import { CurrencyType, WalletDto, isWalletPAType } from '../../model/WalletDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { NoResults } from '../../ui/Table/NoResults/NoResults'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { PageQuery, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { SharedContext } from '../../utils/SharedContext'
import { ClientApiClient } from '../../utils/clientApi'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { getAppliedFiltersLength, prefixFilter, setQuery } from '../../utils/filter.utils'
import { getScrollToCardId } from '../../utils/getItemId'
import { filterObjectByKeys } from '../../utils/object-filter'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import {
  TransactionType,
  getCurrencySymbol,
  getIBTransactionFieldsByTransactionType,
  getTransactionType,
} from '../../utils/transaction.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage, useFetchOne } from '../../utils/useFetch'
import { isOne } from '../../utils/validations'
import { PaymentAgentTransactionFilterModal } from './PaymentAgentTransactionFilterModal'
import { PaymentAgentTransactionHistoryCard } from './PaymentAgentTransactionHistoryCard'
import { PaymentAgentTransactionHistoryTable } from './PaymentAgentTransactionHistoryTable'

import styles from './PaymentAgentTransactionHistoryPage.module.scss'

interface SearchProps extends FilterQueryProps {
  ca_search_ClientAmountFrom?: string
  ca_search_ClientAmountTo?: string
}

export const PaymentAgentTransactionHistoryPage: React.FC<{
  walletId?: string
}> = () => {
  const location = useLocation()
  const [auth] = useContext(AuthSessionContext)
  const [sharedState, setSharedState] = useContext(SharedContext)
  const { formatNumber } = useFormatNumber()
  const { parseMixedText } = useMixedText()

  const dateFormat = auth?.dateFormatType?.name
  const [historyModal, setHistoryModal] = useState<boolean>(false)
  const [filterModal, setFilterModalOpen] = useState<boolean>(false)
  const [sortModalOpen, setSortModalOpen] = useState<boolean>(false)
  const [optionsModal, setOptionsModal] = useState<boolean>(false)
  const isMobile = useWindowResize()
  const [searchTerm, showSearchTerm] = useState<SearchTermState>({
    show: false,
    searchTerm: undefined,
  })
  const [optionModal, setOptionModal] = useState<{
    step: number
    visible: boolean
    data?: TransactionDto
  }>({ step: 1, visible: false })

  const [wallets, setWallets] = useState<WalletDto[]>([])
  const { walletId } = location.state || {}
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  useEffect(() => {
    ;(async () => {
      const wallets = await apiClient.getWallets()
      setWallets(wallets.filter((wallet) => isWalletPAType(wallet.walletType.id)))
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const initialSearchValue = {
    ca_search_ClientAmountFrom: '',
    ca_search_ClientAmountTo: '',
    TransactionStateId: undefined,
    TransactionTypeId: undefined,
    TaId: undefined,
    DateCreated: undefined,
    DateTo: undefined,
    DateFrom: undefined,
  }
  const [search, setSearch] = useState<SearchProps>(initialSearchValue)

  const [selectedTransaction, setSelectedTransaction] = useState('')
  const apiClient = useApiClient(ClientApiClient)

  useEffect(() => {
    if (!isEqual(search, initialSearchValue)) {
      setSearch(initialSearchValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [walletId, wallets])

  const locale = useSessionLanguage()
  const isArabic = useArabicSessionLanguage()

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      const { ca_search_ClientAmountFrom, ca_search_ClientAmountTo, ...clearedSearch } = search

      return await apiClient.getTransactions({
        ...query,
        caller: 'ca',
        search: { ...clearedSearch },
        ca_search_ClientAmountFrom: prefixFilter({
          prefix: account?.id,
          value: ca_search_ClientAmountFrom,
        }),
        ca_search_ClientAmountTo: prefixFilter({
          prefix: account?.id,
          value: ca_search_ClientAmountTo,
        }),
        ca_search_Client: auth?.id || '',
        ca_search_WalletId: walletId ? walletId : wallets.map((x) => x.id).toString() || '',
        languageId: locale,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search, locale, wallets.length, walletId]
  )

  const { data, meta, setPageQuery, pageQuery, isLoading, hasInitialResults } =
    useFetchAppendablePage(callback)

  const callbackForFilters = useCallback(
    async () => {
      if (!account?.id || (!walletId && !wallets.length)) {
        return
      }
      return await apiClient.getPATransactionHistoryFilterOptions(
        account.id,
        walletId ? walletId : wallets.map((x) => x.id).toString() || ''
      )
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [walletId, wallets.length, account?.id]
  )

  const { data: filterOptions } = useFetchOne(callbackForFilters)

  const onCancelTransaction = async (transaction: TransactionDto) => {
    await apiClient.cancelTransaction(transaction.id)
    setOptionModal({ data: undefined, visible: false, step: 1 })
    forceRefresh()
  }
  const onGenerateExcel = () => {
    const data = prepareCSVData(true)
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(data)

    XLSX.utils.book_append_sheet(wb, ws, 'transactions_report')

    XLSX.writeFile(wb, 'transactions_report.xlsx')
  }

  const onGeneratePDF = () => {
    const data = prepareCSVData()
    generatePDFTable({ data, title: t('Wallet.Transaction History') })
    setOptionsModal(false)
    setOptionsModal(false)
  }

  const prepareCSVData = (isXLSX?: boolean) => {
    const headerCsvData = [
      [
        t('Type'),
        t('Date'),
        t('From'),
        t('To'),
        t('Wallet.Reference number'),
        t('Status'),
        t('Amount'),
        t('IB.Transaction exchange rate'),
      ],
    ]

    return data.reduce(
      (previousValue, currentValue) => {
        const transactionType = getTransactionType(currentValue.type.id)
        const fields = getIBTransactionFieldsByTransactionType(
          transactionType,
          currentValue,
          isArabic,
          auth?.id
        )

        const date = formatDate(currentValue.createdDate, { formatType: dateFormat })

        const row = [
          currentValue.type.name,
          isArabic ? date.split(' ').reverse().join(' ') : date,
          isXLSX ? parseMixedText(fields.from) : fields.from,
          isXLSX ? parseMixedText(fields.to) : fields.to,
          currentValue.referenceId,
          currentValue.state.name,
          `${getCurrencySymbol(fields.currency as CurrencyType)} ${formatNumber(fields.amount)}`,
          formatNumber(fields.rate),
        ]

        return previousValue.concat([isArabic ? row.reverse() : row])
      },
      isArabic ? headerCsvData.map((row) => row.reverse()) : headerCsvData
    )
  }

  useEffect(() => {
    ;(async () => {
      let { transactionStates } = sharedState
      if (!transactionStates) {
        transactionStates = await apiClient.getTransactionStates(locale)
        setSharedState((currentState) => ({
          ...currentState,
          transactionStates,
        }))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { clearFilter } = useDateFilterWriteContext()

  const clearSearch = () => {
    setPageQuery?.(undefined)
    showSearchTerm({ searchTerm: '', show: false })
    setSearch(initialSearchValue)
    clearFilter()
  }

  useEffect(
    () => {
      const timer = setTimeout(
        () =>
          searchTerm.searchTerm !== undefined &&
          setPageQuery!({
            ...pageQuery,
            pageIndex: 1,
            ca_search_TextSearch: searchTerm.searchTerm,
          }),
        500
      )
      return () => clearTimeout(timer)
    },
    [searchTerm?.searchTerm] // eslint-disable-line react-hooks/exhaustive-deps
  )

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.PaymentAgentTransactionHistoryHeader,
    isPaginationEntrySelected,
    isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(ScrollToIds.PaymentAgentTransactionHistoryHeader, isLoading, meta?.pageSize)

  const activeFilters = getAppliedFiltersLength({
    ...search,
    Client: undefined,
    WalletId: undefined,
  })

  return (
    <React.Fragment>
      {optionsModal && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              onCloseModal={closeModal}
              csvData={prepareCSVData()}
              onExportToCSV={closeModal}
              onExportToPdf={onGeneratePDF}
              onExportToExcel={onGenerateExcel}
            />
          )}
          closeModal={() => setOptionsModal(false)}
        />
      )}
      {optionModal.visible && (
        <Modal
          closeModal={() =>
            setOptionModal({
              visible: false,
              data: undefined,
              step: 1,
            })
          }
          render={({ closeModal }) => {
            const isAllowed =
              (optionModal.data?.type.id === TransactionType.WithdrawToPaymentAgent ||
                optionModal.data?.type.id === TransactionType.WalletWithdrawal) &&
              optionModal.data.state.id === TransactionStatus.AwaitingApproval
            if (isOne(optionModal.step)) {
              return (
                <SelectableModal
                  title={t('Transaction')}
                  onCancel={closeModal}
                  renderOptions={() => (
                    <button
                      disabled={!isAllowed}
                      onClick={() =>
                        setOptionModal({
                          ...optionModal,
                          step: 2,
                        })
                      }
                      className={classNames(styles.errorBox, 'radio column is-full-desktop radio')}
                    >
                      <div className={styles.error}>
                        <CloseIcon color={'error'} /> {t('Transactions.Cancel Transaction')}
                      </div>
                    </button>
                  )}
                />
              )
            }
            return (
              <ConfirmationModal
                title={t('Transactions.Cancel Confirmation')}
                onCancel={closeModal}
                onConfirm={() => {}}
                renderFooter={() => (
                  <React.Fragment>
                    <button
                      className='button'
                      onClick={() =>
                        setOptionModal({
                          visible: false,
                          step: 1,
                          data: undefined,
                        })
                      }
                      type='button'
                    >
                      {t('Transactions.Back to Transaction History')}
                    </button>
                    <button
                      className='button'
                      onClick={() => onCancelTransaction(optionModal.data as TransactionDto)}
                      type='button'
                    >
                      {t('Transactions.Yes, Cancel Transaction')}
                    </button>
                  </React.Fragment>
                )}
              >
                <p
                  dangerouslySetInnerHTML={{
                    __html: t('Transactions.Cancel Confirmation'),
                  }}
                />
              </ConfirmationModal>
            )
          }}
        />
      )}
      {historyModal && (
        <Modal
          closeModal={() => setHistoryModal(false)}
          render={({ closeModal }) => (
            <TransactionStatusHistoryModal id={selectedTransaction} onCancel={closeModal} />
          )}
        />
      )}

      {filterModal && (
        <Modal
          closeModal={() => setFilterModalOpen(false)}
          render={({ closeModal }) => (
            <PaymentAgentTransactionFilterModal
              currentQuery={search}
              filterOptions={filterOptions}
              sharedState={sharedState}
              onConfirm={({ searchFilters = initialSearchValue, currentFilter }) => {
                let q: FilterQueryProps = {
                  ...searchFilters,
                  ...setQuery(currentFilter),
                }
                if (!currentFilter) {
                  q = filterObjectByKeys(q)
                }
                setSearch(q)

                setPageQuery?.({
                  ...pageQuery,
                  pageIndex: 1,
                })
                closeModal()
              }}
              onCancel={closeModal}
            />
          )}
        />
      )}
      {sortModalOpen && (
        <Modal
          closeModal={() => setSortModalOpen(false)}
          render={({ closeModal }) => (
            <SortByModal
              onCancel={closeModal}
              options={[
                { name: t('Transactions.Transaction Type'), id: 'TransactionType.name' },
                { name: t('Created Date'), id: 'CreatedDate' },
                { name: t('Transactions.Transaction State'), id: 'TransactionState.name' },
              ]}
              onConfirm={(option, sortBy, sortName) => {
                setPageQuery?.({
                  ...pageQuery,
                  sort: option,
                  sortOrder: sortBy,
                  sortName,
                })
                closeModal()
              }}
            />
          )}
        />
      )}
      {hasInitialResults ? (
        <PageHeader
          id={ScrollToIds.PaymentAgentTransactionHistoryHeader}
          title={t('Payment Agent.Transaction History')}
          search={{
            show: searchTerm.show,
            placeholder: t('Search reference'),
            searchTerm: searchTerm.searchTerm,
            setShow: (v) => showSearchTerm({ ...searchTerm, show: v }),
            setSearchTerm: (v) =>
              showSearchTerm({
                ...searchTerm,
                searchTerm: v,
              }),
          }}
          optionsToggle={() => setOptionsModal(true)}
          filterToggles={{
            openFilterModal: () => setFilterModalOpen(true),
            resetFilters: clearSearch,
            activeFilters,
            sortLabel: `${t('SortBy')}${pageQuery?.sortName ? `: ${pageQuery?.sortName} ` : ''}`,
            openSortModal: () => setSortModalOpen(true),
          }}
        />
      ) : (
        <PageHeader
          title={t('Payment Agent.Transaction History')}
          id={ScrollToIds.PaymentAgentTransactionHistoryHeader}
        />
      )}
      <Loading showLoadingIcon isLoading={isLoading}>
        {isMobile ? (
          !data.length && !isLoading ? (
            <NoResults
              subtitle={activeFilters > 0 ? t('No results') : undefined}
              hideLink={activeFilters > 0}
            />
          ) : (
            data.map((trans, index) => (
              <PaymentAgentTransactionHistoryCard
                data={trans}
                isLoading={isLoading}
                setPageQuery={setPageQuery}
                setSelectedTransaction={setSelectedTransaction}
                setHistoryModal={setHistoryModal}
                setOptionModal={setOptionModal}
                cardId={getScrollToCardId(index, data.length, 'payment-agent-transaction')}
              />
            ))
          )
        ) : (
          <PaymentAgentTransactionHistoryTable
            data={data}
            isLoading={isLoading}
            setPageQuery={setPageQuery}
            setHistoryModal={setHistoryModal}
            activeFilters={activeFilters}
            setSelectedTransaction={setSelectedTransaction}
            setOptionModal={setOptionModal}
          />
        )}
      </Loading>
      {meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.PaymentAgentTransactionHistoryHeader}
          maxPageSize={500}
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsPaginationEntrySelected(true)
            }
            setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </React.Fragment>
  )
}
