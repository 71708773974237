import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { Loading } from '../../../../global/Loading/Loading'
import { Modal } from '../../../../global/modal/Modal'
import { MasterTransaction } from '../../../../model/MasterTransaction'
import {
  isPaymentProviderBankType,
  isPaymentProviderCardProviderType,
  isPaymentProviderPSPType,
  isPaymentProviderPaymentAgentType,
} from '../../../../model/PaymentProviderType'
import { WalletWithdrawSuccessModal } from './WalletWithdrawModal'
import { WalletWithdrawTransactionFailedPage } from './WalletWithdrawTransactionFailedPage'

import styles from '../../WalletTransfer/TransferForm.module.scss'

type WalletDepositTransactionSuccessState = {
  data: MasterTransaction
  providerCategoryId: number
}
type WalletWithdrawTransactionSuccessPageProps = {
  onCancel(): void
}
type WalletWithdrawFormTransactionSuccessValues = WalletDepositTransactionSuccessState

export const WalletWithdrawTransactionSuccessPage: React.FC<
  WalletWithdrawTransactionSuccessPageProps
> = (props) => {
  const { onCancel } = props
  const { t } = useTranslation()
  const location = useLocation()

  const [values] = useState<WalletDepositTransactionSuccessState>(location.state)
  const providerCategoryId = values?.providerCategoryId

  return (
    <React.Fragment>
      {(isPaymentProviderPSPType(providerCategoryId) ||
        isPaymentProviderCardProviderType(providerCategoryId) ||
        isPaymentProviderBankType(providerCategoryId)) && <WalletWithdrawTransactionSuccess />}

      {isPaymentProviderPaymentAgentType(providerCategoryId) && (
        <WalletWithdrawTransactionSuccessPaymentAgentFactoryPage onCancel={onCancel} />
      )}

      {!values && (
        <WalletWithdrawTransactionFailedPage
          title={t('Transactions.Funds not withdrawn')}
          onCancel={onCancel}
        />
      )}
    </React.Fragment>
  )
}

const WalletWithdrawTransactionSuccess = () => {
  const { transaction } = useWalletWithdrawTransactionSuccess()
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()

  const isPaymentAgentPath = location.pathname.includes('/dashboard/payment-agent/')
  const isIBPath = location.pathname.includes('/dashboard/introducing-broker/')

  const handleCancel = () =>
    isPaymentAgentPath
      ? navigate('/dashboard/payment-agent/transaction-history')
      : isIBPath
      ? navigate('/dashboard/introducing-broker/transaction-history')
      : navigate('/dashboard/traders-room/transaction-history')

  return (
    <Loading text={t('Withdrawing...')} isLoading={!transaction} showLoadingIcon>
      <Modal
        size='xsmall'
        cardClassName={styles.modalContainer}
        closeModal={handleCancel}
        render={() => (
          <WalletWithdrawSuccessModal transaction={transaction} onCancel={handleCancel} />
        )}
      />
    </Loading>
  )
}

const WalletWithdrawTransactionSuccessPaymentAgentFactoryPage: React.FC<{
  onCancel(): void
}> = ({ onCancel }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { transaction } = useWalletWithdrawTransactionSuccess()

  const isPaymentAgentPath = location.pathname.includes('/dashboard/payment-agent/')

  const handleCancel = () =>
    isPaymentAgentPath
      ? navigate('/dashboard/payment-agent/transaction-history')
      : navigate('/dashboard/traders-room/transaction-history')

  return (
    <Loading text={t('Withdrawing...')} isLoading={!transaction} showLoadingIcon>
      <Modal
        size='xsmall'
        cardClassName={styles.modalContainer}
        closeModal={onCancel}
        render={() => (
          <WalletWithdrawSuccessModal transaction={transaction} onCancel={handleCancel} />
        )}
      />
    </Loading>
  )
}

const useWalletWithdrawTransactionSuccess = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [values] = useState<WalletWithdrawFormTransactionSuccessValues>(location.state)

  useEffect(() => {
    navigate({ ...location }, { replace: true })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { transaction: values.data }
}
