import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export const SortDownIcon: React.FC<IconProps> = (props) => {
  const size = props.size || 24

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='sort-down'>
        <path
          id='Icon'
          d='M7.48287 10C7.30469 10 7.21546 10.2154 7.34145 10.3414L11.7879 14.7879C11.9051 14.905 12.095 14.905 12.2122 14.7879L16.6586 10.3414C16.7846 10.2154 16.6954 10 16.5172 10H7.48287Z'
          fill={iconColor(props)}
        />
      </g>
    </svg>
  )
}
