import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'

import styles from './TransactionFormActionButtonTemplate.module.scss'

export const TransactionFormActionButtonTemplate: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={classNames(styles.formActionWrapper, {
        [styles.childrenFullWidth]: React.Children.count(children) > 1,
      })}
    >
      {children}
    </div>
  )
}
