import { useProductReadContext } from '../global/context/ProductContext'
import { IconProps, iconColor } from './IconProps'

export function NotificationIcon(props: IconProps) {
  const size = props.size || 24
  const { isDefaultCFDProductType } = useProductReadContext()
  const isProductTypeCFD = isDefaultCFDProductType()

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 5H11.6483C9.06007 5 6.90034 6.97477 6.61584 9.54732C6.2093 13.2235 5.5912 18.022 5 19.5H10.3846M12 5H12.3517C14.9399 5 17.0997 6.97477 17.3842 9.54732C17.7907 13.2235 18.4088 18.022 19 19.5H13.6154M12 5V2M10.3846 19.5V19.8846C10.3846 20.7768 11.1078 21.5 12 21.5V21.5C12.8922 21.5 13.6154 20.7768 13.6154 19.8846V19.5M10.3846 19.5H13.6154'
        stroke={!isProductTypeCFD ? '#FFFFFF' : iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
