import { ReactNode } from 'react'

export function CreateTradingAccountIcon(): ReactNode {
  return (
    <svg
      width='299'
      height='197'
      viewBox='0 0 299 197'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M292.583 3.24292H5.79413C3.49288 3.24292 1.62109 5.11564 1.62109 7.41802V193.117C1.62109 194.268 2.55294 195.208 3.71166 195.208H294.673C295.824 195.208 296.764 194.276 296.764 193.117V7.41802C296.764 5.11564 294.892 3.24292 292.591 3.24292H292.583Z'
        fill='white'
        stroke='#424244'
        stroke-miterlimit='10'
      />
      <path
        d='M292.583 3.24292H5.79413C3.49288 3.24292 1.62109 5.11564 1.62109 7.41802V21.816H296.756V7.41802C296.756 5.11564 294.884 3.24292 292.583 3.24292Z'
        stroke='#424244'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M11.8545 15.7683C13.6452 15.7683 15.0957 14.3172 15.0957 12.5255C15.0957 10.7339 13.6452 9.28271 11.8545 9.28271C10.0637 9.28271 8.61328 10.7339 8.61328 12.5255C8.61328 14.3172 10.0637 15.7683 11.8545 15.7683Z'
        fill='white'
        stroke='#424244'
        stroke-miterlimit='10'
      />
      <path
        d='M23.7568 15.7683C25.5476 15.7683 26.998 14.3172 26.998 12.5255C26.998 10.7339 25.5476 9.28271 23.7568 9.28271C21.9661 9.28271 20.5156 10.7339 20.5156 12.5255C20.5156 14.3172 21.9661 15.7683 23.7568 15.7683Z'
        fill='white'
        stroke='#424244'
        stroke-miterlimit='10'
      />
      <path
        d='M35.7021 15.7683C37.4929 15.7683 38.9433 14.3172 38.9433 12.5255C38.9433 10.7339 37.4929 9.28271 35.7021 9.28271C33.9114 9.28271 32.4609 10.7339 32.4609 12.5255C32.4609 14.3172 33.9114 15.7683 35.7021 15.7683Z'
        fill='white'
        stroke='#424244'
        stroke-miterlimit='10'
      />
      <path
        d='M275.299 43.7778H165.495C163.705 43.7778 162.254 45.2297 162.254 47.0206V161.718C162.254 163.509 163.705 164.961 165.495 164.961H275.299C277.089 164.961 278.54 163.509 278.54 161.718V47.0206C278.54 45.2297 277.089 43.7778 275.299 43.7778Z'
        fill='#C0E3FA'
      />
      <path
        d='M263.922 115.549H176.863C175.713 115.549 174.789 116.668 174.789 118.054C174.789 119.44 175.721 120.559 176.863 120.559H263.922C265.072 120.559 265.996 119.44 265.996 118.054C265.996 116.668 265.064 115.549 263.922 115.549Z'
        fill='white'
      />
      <path
        d='M244.612 126.128H176.863C175.713 126.128 174.789 127.247 174.789 128.633C174.789 130.02 175.721 131.139 176.863 131.139H244.612C245.763 131.139 246.687 130.02 246.687 128.633C246.687 127.247 245.755 126.128 244.612 126.128Z'
        fill='white'
      />
      <path
        d='M209.178 136.895H176.863C175.713 136.895 174.789 138.013 174.789 139.4C174.789 140.786 175.721 141.905 176.863 141.905H209.178C210.329 141.905 211.252 140.786 211.252 139.4C211.252 138.013 210.321 136.895 209.178 136.895Z'
        fill='white'
      />
      <mask
        id='mask0_5334_209045'
        style={{ maskType: 'luminance' }}
        maskUnits='userSpaceOnUse'
        x='175'
        y='67'
        width='31'
        height='32'
      >
        <path
          d='M190.314 98.2C198.71 98.2 205.516 91.3872 205.516 82.9832C205.516 74.5792 198.71 67.7664 190.314 67.7664C181.919 67.7664 175.113 74.5792 175.113 82.9832C175.113 91.3872 181.919 98.2 190.314 98.2Z'
          fill='white'
        />
      </mask>
      <g mask='url(#mask0_5334_209045)'>
        <path
          d='M190.314 98.2C198.71 98.2 205.516 91.3872 205.516 82.9832C205.516 74.5792 198.71 67.7664 190.314 67.7664C181.919 67.7664 175.113 74.5792 175.113 82.9832C175.113 91.3872 181.919 98.2 190.314 98.2Z'
          fill='white'
        />
        <path
          d='M190.311 82.8048C193.721 82.8048 196.486 80.039 196.486 76.6272C196.486 73.2155 193.721 70.4497 190.311 70.4497C186.901 70.4497 184.137 73.2155 184.137 76.6272C184.137 80.039 186.901 82.8048 190.311 82.8048Z'
          fill='#424244'
        />
        <path
          d='M188.489 83.4614H192.127C198.439 83.4614 203.568 88.5932 203.568 94.9085V98.4837H177.039V94.9085C177.039 88.5932 182.168 83.4614 188.48 83.4614H188.489Z'
          fill='#424244'
        />
      </g>
      <rect x='34.0312' y='137.008' width='86.7019' height='16.214' rx='8.107' fill='#424244' />
      <rect
        x='34.5312'
        y='56.4382'
        width='85.7019'
        height='15.214'
        rx='7.607'
        fill='white'
        stroke='#424244'
      />
      <path
        d='M93.6681 61.2637L93.8196 63.1986L92.2098 62.0952L91.4414 63.4444L93.1872 64.2759L91.4414 65.1074L92.2098 66.4566L93.8196 65.3532L93.6681 67.2881H95.21L95.0584 65.3532L96.6683 66.4566L97.4366 65.1074L95.6909 64.2759L97.4366 63.4444L96.6683 62.0952L95.0584 63.1986L95.21 61.2637H93.6681Z'
        fill='#424244'
      />
      <path
        d='M85.1915 61.2637L85.3431 63.1986L83.7332 62.0952L82.9648 63.4444L84.7106 64.2759L82.9648 65.1074L83.7332 66.4566L85.3431 65.3532L85.1915 67.2881H86.7334L86.5818 65.3532L88.1917 66.4566L88.9601 65.1074L87.2143 64.2759L88.9601 63.4444L88.1917 62.0952L86.5818 63.1986L86.7334 61.2637H85.1915Z'
        fill='#424244'
      />
      <path
        d='M76.711 61.2637L76.8626 63.1986L75.2527 62.0952L74.4844 63.4444L76.2302 64.2759L74.4844 65.1074L75.2527 66.4566L76.8626 65.3532L76.711 67.2881H78.253L78.1014 65.3532L79.7113 66.4566L80.4796 65.1074L78.7338 64.2759L80.4796 63.4444L79.7113 62.0952L78.1014 63.1986L78.253 61.2637H76.711Z'
        fill='#424244'
      />
      <path
        d='M68.2345 61.2637L68.386 63.1986L66.7762 62.0952L66.0078 63.4444L67.7536 64.2759L66.0078 65.1074L66.7762 66.4566L68.386 65.3532L68.2345 67.2881H69.7764L69.6248 65.3532L71.2347 66.4566L72.003 65.1074L70.2573 64.2759L72.003 63.4444L71.2347 62.0952L69.6248 63.1986L69.7764 61.2637H68.2345Z'
        fill='#424244'
      />
      <path
        d='M59.7579 61.2637L59.9095 63.1986L58.2996 62.0952L57.5312 63.4444L59.277 64.2759L57.5312 65.1074L58.2996 66.4566L59.9095 65.3532L59.7579 67.2881H61.2998L61.1482 65.3532L62.7581 66.4566L63.5265 65.1074L61.7807 64.2759L63.5265 63.4444L62.7581 62.0952L61.1482 63.1986L61.2998 61.2637H59.7579Z'
        fill='#424244'
      />
      <rect
        opacity='0.3'
        x='48.6172'
        y='83.502'
        width='58.3415'
        height='4.8642'
        rx='2.4321'
        fill='#424244'
      />
      <ellipse cx='39.7033' cy='85.9342' rx='5.67209' ry='5.6749' fill='#C8FAC0' />
      <path
        d='M39.1963 87.5095L37.7697 86.7263C37.6106 86.6382 37.4105 86.6921 37.3181 86.8389C37.2257 86.9907 37.2822 87.1816 37.4361 87.2697L39.4324 88.3662L42.0906 83.9753C42.1829 83.8236 42.1265 83.6327 41.9725 83.5446C41.8135 83.4565 41.6133 83.5103 41.5209 83.6572L39.1912 87.5046L39.1963 87.5095Z'
        fill='#198807'
      />
      <rect
        opacity='0.3'
        x='48.6172'
        y='99.7163'
        width='18.6369'
        height='4.8642'
        rx='2.4321'
        fill='#424244'
      />
      <rect
        opacity='0.3'
        x='68.875'
        y='99.7163'
        width='29.981'
        height='4.8642'
        rx='2.4321'
        fill='#424244'
      />
      <rect
        opacity='0.3'
        x='102.098'
        y='99.7163'
        width='4.86179'
        height='4.8642'
        rx='2.43089'
        fill='#424244'
      />
      <ellipse cx='39.7033' cy='102.148' rx='5.67209' ry='5.6749' fill='#C8FAC0' />
      <path
        d='M39.1963 103.724L37.7697 102.941C37.6106 102.853 37.4105 102.906 37.3181 103.053C37.2257 103.205 37.2822 103.396 37.4361 103.484L39.4324 104.581L42.0906 100.19C42.1829 100.038 42.1265 99.847 41.9725 99.7589C41.8135 99.6708 41.6133 99.7247 41.5209 99.8715L39.1912 103.719L39.1963 103.724Z'
        fill='#198807'
      />
      <rect
        opacity='0.3'
        x='48.6172'
        y='115.93'
        width='12.1545'
        height='4.8642'
        rx='2.4321'
        fill='#424244'
      />
      <rect
        opacity='0.3'
        x='64.0117'
        y='115.93'
        width='36.4634'
        height='4.8642'
        rx='2.4321'
        fill='#424244'
      />
      <ellipse cx='39.7033' cy='118.362' rx='5.67209' ry='5.6749' fill='#C8FAC0' />
      <path
        d='M39.1963 119.938L37.7697 119.155C37.6106 119.066 37.4105 119.12 37.3181 119.267C37.2257 119.419 37.2822 119.61 37.4361 119.698L39.4324 120.794L42.0906 116.404C42.1829 116.252 42.1265 116.061 41.9725 115.973C41.8135 115.885 41.6133 115.939 41.5209 116.085L39.1912 119.933L39.1963 119.938Z'
        fill='#198807'
      />
    </svg>
  )
}
