import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import {
  WalletModalItem,
  WalletRestrictionsModal,
} from '../../global/modal/WalletRestrictionsModal'
import { WalletCurrency, WalletDto, isWalletFX_CFDType } from '../../model/WalletDto'
import { Text } from '../../ui/Typography/Typography'
import { WalletRestrictions } from '../../utils/wallet.utils'
import { CreateTradingAccountCampaignFormValues } from './CreateTradingAccountCampaignForm'

import styles from './TradingAccountWalletModal.module.scss'

interface TradingAccountWalletModalProps {
  currencies?: WalletCurrency[]

  onSelectOption(option: WalletDto): void

  onClose(): void
}

export const TradingAccountWalletModal: React.FC<TradingAccountWalletModalProps> = (props) => {
  const { currencies, onSelectOption, onClose } = props

  const { t } = useTranslation()

  const handleSelectOption = (wallet: WalletDto) => {
    onSelectOption(wallet)
  }

  return (
    <WalletRestrictionsModal
      title={t('Trading Account.Wallet')}
      render={({ items }) => (
        <TradingAccountWalletItemModal
          wallets={items}
          currencies={currencies}
          onSelectOption={onSelectOption}
        />
      )}
      onSelectOption={handleSelectOption}
      onClose={onClose}
    />
  )
}

interface TradingAccountWalletItemModalProps {
  wallets: WalletDto[]

  currencies?: WalletCurrency[]

  onSelectOption(option: WalletDto): void
}

const TradingAccountWalletItemModal: React.FC<TradingAccountWalletItemModalProps> = (props) => {
  const { wallets, currencies, onSelectOption } = props

  const { t } = useTranslation()

  const { values } = useFormikContext<CreateTradingAccountCampaignFormValues>()

  const filterWalletByCurrencies = useMemo(() => {
    if (currencies?.length) {
      return wallets.reduce<WalletDto[]>((previousValue, currentValue) => {
        if (currencies.find((x) => x.id === currentValue.currency.id)) {
          previousValue.push(currentValue)
        }
        return previousValue
      }, [])
    }
    return wallets
  }, [wallets, currencies])

  const filteredWallets = filterWalletByCurrencies.filter((x) =>
    isWalletFX_CFDType(x.walletType.id)
  )

  return (
    <React.Fragment>
      {filteredWallets.length ? (
        filteredWallets.map((item) => (
          <WalletModalItem
            item={item}
            restrictionType={WalletRestrictions.ADD_TRADING_ACCOUNT}
            selected={item?.id === values.wallet?.id}
            onSelectOption={onSelectOption}
            key={item.id}
            hideHint={true}
          />
        ))
      ) : (
        <div className={styles.wrapper}>
          <Text>
            {t(
              'Trading Account.There is no available wallet for this platform. Please create a USD wallet'
            )}
          </Text>
        </div>
      )}
    </React.Fragment>
  )
}
