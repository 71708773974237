import React from 'react'
import { useTranslation } from 'react-i18next'

import { ConfirmationModal } from '../../global/modal/ConfirmationModal'
import { Modal } from '../../global/modal/Modal'

interface ConfirmationModalProps {
  onGuideClose(): void
  onClose(): void
}

export const GuideConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  const { onGuideClose, onClose } = props

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onClose}
      render={() => (
        <ConfirmationModal
          onCancel={onClose}
          onConfirm={onGuideClose}
          title={t('Close Quick Guidelines')}
          renderFooter={() => (
            <>
              <button className='button' onClick={onClose} type='button'>
                {t('Cancel')}
              </button>
              <button className='button' onClick={onGuideClose} type='button'>
                {t('Close Guidelines')}
              </button>
            </>
          )}
        >
          <>
            <p>{t('You can access Quick Guidelines at anytime')}</p>
          </>
        </ConfirmationModal>
      )}
    />
  )
}
