import React from 'react'
import { useTranslation } from 'react-i18next'

import { TransactionFailedDialogueTemplate } from '../../../../global/templates/TransactionFailedDialogueTemplate'

interface WalletWithdrawTransactionFailedPageProps {
  title: string

  onCancel?(): void
}

export const WalletWithdrawTransactionFailedPage: React.FC<
  WalletWithdrawTransactionFailedPageProps
> = ({ onCancel, title }) => {
  const { t } = useTranslation()
  return (
    <TransactionFailedDialogueTemplate
      title={title || t('Wallet.Funds not withdrawn')}
      goBack={onCancel}
    />
  )
}
