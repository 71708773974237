import { NameDto } from './NameDto'
import { SubscriptionRequestType, SubscriptionTimeInForce } from './UpdateSubscriptionRequest'

export interface SubscriptionCartItem {
  id: string
  subscription: {
    description: string
    id: string
    name: string
    subscriptionGroupType?: NameDto
  }
  subscriptionDetail: {
    id: string
    name: string
    description: string
    amount: number
  }
  subscriptionTimeInForce: NameDto
  subscriptionRequestType: NameDto<SubscriptionRequestType>
}

export interface AddSubscriptionItemToCartData {
  subscriptionDetailId: string
  subscriptionTimeInForceId: SubscriptionTimeInForce
  subscriptionRequestTypeId?: SubscriptionRequestType
}

export enum ActiveSubscriptionsRequestType {
  StateId = 8,
}

export enum PendingUnSubscriptionsRequestType {
  StateId = 1,
  RequestTypeId = 2,
}
