export enum UploadDocumentCategoryEnum {
  None = 0,
  Personal = 1,
  Address = 2,
  Corporate = 3,
  Additional = 7,
  Internal = 8,
  Appropriateness_test = 9,
  Payment_agent = 10,
  Migrated = 11,
}
