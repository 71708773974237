import { ReactNode, createContext, useContext } from 'react'

import { EntityConfigurationDto } from '../model/EntityConfigurationDto'

const TradingAccountEntityConfigurationContext = createContext<EntityConfigurationDto | null>(null)
TradingAccountEntityConfigurationContext.displayName = 'TradingAccountEntityConfigurationContext'

export function useEntityConfig(): EntityConfigurationDto | null {
  return useContext(TradingAccountEntityConfigurationContext)
}

interface Props {
  configuration: EntityConfigurationDto | null
  children: ReactNode
}

export function TradingAccountEntityConfigurationProvider({ configuration, children }: Props) {
  return (
    <TradingAccountEntityConfigurationContext.Provider value={configuration}>
      {children}
    </TradingAccountEntityConfigurationContext.Provider>
  )
}
