import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { SelectableModal } from '../../global/field/SelectableModal'
import { Modal } from '../../global/modal/Modal'
import { LeverageTypeDto } from '../../model/LeverageTypeDto'
import { NameDto } from '../../model/NameDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { TradingAccountRestrictions, isRestricted } from '../../utils/trading-account.utils'
import { useFetchOne } from '../../utils/useFetch'

import styles from './LeverageModal.module.scss'

interface Props {
  restrictions: NameDto[]
  currentLeverage?: NameDto
  platformTypeId?: number
  title?: string
  onClose(): void
  onSelect(leverage: LeverageTypeDto): void
}

export const LeverageModal: React.FC<Props> = ({
  platformTypeId,
  onSelect,
  onClose,
  currentLeverage,
  title,
  restrictions,
}) => {
  const apiClient = useApiClient(ClientApiClient)

  const leveragesCallback = useCallback(
    async () => await apiClient.getLeverageTypes(platformTypeId),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [platformTypeId]
  )

  const { data: leverages = [], isLoading } = useFetchOne(leveragesCallback)

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={() => onClose()}
      render={({ closeModal }) => (
        <SelectableModal
          onCancel={closeModal}
          title={title || t('Trading Account.Change Leverage')}
          renderOptions={() => (
            <Loading isLoading={isLoading} showLoadingIcon>
              <div className='control'>
                {leverages.map((leverage) => {
                  const leverageUpRestriction = isRestricted(
                    TradingAccountRestrictions.LEVERAGE_UP,
                    restrictions
                  )
                  const leverageDownRestriction = isRestricted(
                    TradingAccountRestrictions.LEVERAGE_DOWN,
                    restrictions
                  )
                  const currentLeverageType = leverages.find((l) => l.id === currentLeverage?.id)
                  const isDisabled =
                    (leverageUpRestriction && currentLeverageType!.amount < leverage.amount) ||
                    (leverageDownRestriction && currentLeverageType!.amount > leverage.amount)
                  return (
                    <label
                      key={leverage.id}
                      className={classNames('radio column is-full-desktop radio', styles.label, {
                        [styles.inactive]: isDisabled,
                      })}
                    >
                      <input
                        className={styles.radio}
                        defaultChecked={leverage?.id === currentLeverage?.id}
                        onClick={() => onSelect(leverage)}
                        type='radio'
                        disabled={isDisabled}
                        value={leverage.id}
                        name='option'
                      />{' '}
                      {leverage.name}
                    </label>
                  )
                })}
              </div>
            </Loading>
          )}
        />
      )}
    />
  )
}
