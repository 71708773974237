import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMEuropeLogoMobile: FC = () => (
  <svg width='120' height='30' viewBox='0 0 120 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M53.091 16.4632C54.582 16.4632 55.8183 15.8813 56.7638 14.6813L58.7274 16.6813C57.1638 18.4268 55.3456 19.2995 53.2729 19.2995C51.1638 19.2995 49.4547 18.645 48.0729 17.2995C46.7274 15.9904 46.0365 14.2813 46.0365 12.2813C46.0365 10.245 46.7274 8.57225 48.1092 7.22679C49.491 5.88134 51.2001 5.19043 53.2001 5.19043C55.4547 5.19043 57.3092 6.02679 58.8365 7.73588L56.9456 9.88134C56.0001 8.68134 54.8001 8.09952 53.3456 8.09952C52.2183 8.09952 51.2365 8.46316 50.4001 9.22679C49.6001 9.99043 49.1638 10.9722 49.1638 12.245C49.1638 13.5177 49.5638 14.5359 50.3274 15.2995C51.091 16.0632 52.0001 16.4632 53.091 16.4632Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M34.3637 19.154V8.13587H38.2182V5.51768H27.4546V8.13587H31.3091V19.154H34.3637Z'
      fill='var(--logo-text-color)'
    />
    <path d='M43.4909 5.51768H40.4364V19.154H43.4909V5.51768Z' fill='var(--logo-text-color)' />
    <path
      d='M64.1819 5.51768H61.1273V19.154H64.1819V15.2995L65.5637 13.7722L69.5637 19.154H73.1273C72.789 18.6764 72.3526 18.0681 71.8183 17.3231L71.8139 17.317C71.3729 16.7022 70.8652 15.9944 70.291 15.1904C69.1658 13.5689 68.3418 12.4292 67.8464 11.744L67.7091 11.554L73.1273 5.51768H69.3455L64.1819 11.1177V5.51768Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M87.2 10.5722L83.5273 18.0268H81.7091L78.0364 10.5722V19.154H74.9818V5.51768H79.0909L82.6182 13.0086L86.1454 5.51768H90.2545V19.154H87.2V10.5722Z'
      fill='var(--logo-text-color)'
    />
    <path d='M96.6545 5.51768H93.6V19.154H96.6545V5.51768Z' fill='var(--logo-text-color)' />
    <path
      d='M99.9999 19.154V5.51768H103.054V16.4268H108.873V19.154H99.9999Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M111.091 5.51768V19.154H119.964V16.4268H114.145V5.51768H111.091Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M5.78182 9.00909C5.56364 9.15455 5.38182 9.48182 5.38182 9.73636H5.45455V14.2455L0 17.3727V7.19091C0 6.53636 0.472727 5.73636 1.01818 5.40909L9.74546 0.245455C10.2909 -0.0818182 11.2 -0.0818182 11.7455 0.245455L20.4727 5.40909C20.5091 5.40909 20.5455 5.44546 20.5818 5.48182L15.0545 8.68182L11.1273 6.39091C10.8727 6.24545 10.5091 6.24545 10.2909 6.39091L5.78182 9.00909Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M16.0002 14.9364V10.4273L21.382 7.30005V17.4819C21.382 18.1364 20.9093 18.9364 20.3638 19.2637L11.6365 24.4273C11.0911 24.7546 10.182 24.7546 9.63653 24.4273L0.909262 19.2637C0.872901 19.2637 0.836539 19.2273 0.800178 19.191L6.32744 15.991L10.2547 18.2819C10.5093 18.4273 10.8729 18.4273 11.0911 18.2819L15.6002 15.6637C15.8184 15.5182 16.0002 15.191 16.0002 14.9364Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M94.6011 28.675V29.25H91.7776V28.675H94.6011ZM91.9204 23.918V29.25H91.2136V23.918H91.9204ZM94.2275 26.2104V26.7854H91.7776V26.2104H94.2275ZM94.5645 23.918V24.4966H91.7776V23.918H94.5645Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M98.8269 23.918H99.53V27.5251C99.53 27.9255 99.4409 28.2588 99.2626 28.5249C99.0844 28.791 98.8476 28.9912 98.5522 29.1255C98.2592 29.2573 97.9406 29.3232 97.5964 29.3232C97.2351 29.3232 96.9079 29.2573 96.6149 29.1255C96.3244 28.9912 96.0937 28.791 95.9228 28.5249C95.7543 28.2588 95.6701 27.9255 95.6701 27.5251V23.918H96.3696V27.5251C96.3696 27.8035 96.4208 28.033 96.5234 28.2136C96.6259 28.3943 96.7687 28.5286 96.9519 28.6165C97.1374 28.7043 97.3522 28.7483 97.5964 28.7483C97.843 28.7483 98.0578 28.7043 98.2409 28.6165C98.4265 28.5286 98.5705 28.3943 98.673 28.2136C98.7756 28.033 98.8269 27.8035 98.8269 27.5251V23.918Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M100.94 23.918H102.705C103.105 23.918 103.443 23.979 103.719 24.1011C103.997 24.2231 104.209 24.4038 104.353 24.6431C104.499 24.8799 104.572 25.1716 104.572 25.5183C104.572 25.7625 104.522 25.9858 104.422 26.1885C104.325 26.3887 104.183 26.5596 103.997 26.7012C103.814 26.8403 103.595 26.9441 103.338 27.0125L103.141 27.0894H101.482L101.474 26.5144H102.727C102.981 26.5144 103.192 26.4705 103.36 26.3826C103.529 26.2922 103.656 26.1714 103.741 26.02C103.827 25.8687 103.869 25.7014 103.869 25.5183C103.869 25.3132 103.829 25.1338 103.748 24.98C103.668 24.8262 103.541 24.7078 103.368 24.6248C103.197 24.5393 102.976 24.4966 102.705 24.4966H101.646V29.25H100.94V23.918ZM104.056 29.25L102.76 26.833L103.496 26.8293L104.81 29.2061V29.25H104.056Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M109.966 26.4155V26.7524C109.966 27.1528 109.916 27.5117 109.816 27.8291C109.716 28.1465 109.572 28.4163 109.384 28.6384C109.196 28.8606 108.97 29.0303 108.707 29.1475C108.445 29.2646 108.152 29.3232 107.828 29.3232C107.513 29.3232 107.223 29.2646 106.96 29.1475C106.699 29.0303 106.472 28.8606 106.279 28.6384C106.088 28.4163 105.941 28.1465 105.836 27.8291C105.731 27.5117 105.678 27.1528 105.678 26.7524V26.4155C105.678 26.0151 105.729 25.6575 105.832 25.3425C105.937 25.0251 106.085 24.7554 106.275 24.5332C106.465 24.3086 106.691 24.1377 106.952 24.0205C107.216 23.9033 107.505 23.8447 107.82 23.8447C108.145 23.8447 108.438 23.9033 108.699 24.0205C108.963 24.1377 109.189 24.3086 109.377 24.5332C109.567 24.7554 109.712 25.0251 109.813 25.3425C109.915 25.6575 109.966 26.0151 109.966 26.4155ZM109.267 26.7524V26.4082C109.267 26.0908 109.234 25.8101 109.168 25.5659C109.105 25.3218 109.011 25.1167 108.886 24.9507C108.762 24.7847 108.609 24.6589 108.428 24.5735C108.25 24.488 108.047 24.4453 107.82 24.4453C107.601 24.4453 107.402 24.488 107.223 24.5735C107.048 24.6589 106.896 24.7847 106.769 24.9507C106.645 25.1167 106.548 25.3218 106.48 25.5659C106.412 25.8101 106.378 26.0908 106.378 26.4082V26.7524C106.378 27.0723 106.412 27.3555 106.48 27.6021C106.548 27.8462 106.646 28.0525 106.773 28.2209C106.902 28.387 107.055 28.5127 107.231 28.5981C107.409 28.6836 107.608 28.7263 107.828 28.7263C108.057 28.7263 108.261 28.6836 108.439 28.5981C108.618 28.5127 108.768 28.387 108.89 28.2209C109.014 28.0525 109.108 27.8462 109.172 27.6021C109.235 27.3555 109.267 27.0723 109.267 26.7524Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M113.291 27.1589H111.867V26.584H113.291C113.567 26.584 113.791 26.54 113.961 26.4521C114.132 26.3643 114.257 26.2422 114.335 26.0859C114.416 25.9297 114.456 25.7515 114.456 25.5513C114.456 25.3682 114.416 25.196 114.335 25.0349C114.257 24.8738 114.132 24.7444 113.961 24.6467C113.791 24.5466 113.567 24.4966 113.291 24.4966H112.032V29.25H111.325V23.918H113.291C113.694 23.918 114.035 23.9875 114.313 24.1267C114.591 24.2659 114.803 24.4587 114.947 24.7053C115.091 24.9495 115.163 25.229 115.163 25.5439C115.163 25.8857 115.091 26.1775 114.947 26.4192C114.803 26.6609 114.591 26.8452 114.313 26.9722C114.035 27.0967 113.694 27.1589 113.291 27.1589Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M119.744 28.675V29.25H116.92V28.675H119.744ZM117.063 23.918V29.25H116.356V23.918H117.063ZM119.37 26.2104V26.7854H116.92V26.2104H119.37ZM119.707 23.918V24.4966H116.92V23.918H119.707Z'
      fill='var(--logo-text-color)'
    />
  </svg>
)
