import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { CloseIcon } from '../../../icons/CloseIcon'
import { HamburgerIcon } from '../../../icons/HamburgerIcon'
import { TMEntityLogoFactory } from '../../../icons/TickmillLogoIcons'
import { CookieConsent } from '../../CookieConsent/CookieConsent'
import { useSessionEntity } from '../../context/EntityContext'
import IconButton from '../../iconButton/IconButton'
import { LanguageVariants } from '../../localeSelector/LanguageVariants'
import { LocaleSelector } from '../../localeSelector/LocaleSelector'
import { NavBarMenu } from '../NavBarMenu'
import { useDropdownHeight } from './useDropdownHeight'

import styles from './MobileNav.module.scss'

export const MobileLoginNav: React.FC = () => {
  const location = useLocation()
  const entity = useSessionEntity()
  const { headerRef, dropdownRef } = useDropdownHeight()

  const [isBurgerMenuOpen, setBurgerMenuOpen] = useState(false)

  useEffect(() => {
    setBurgerMenuOpen(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const handleBurgerMenu = (value: boolean) => {
    setBurgerMenuOpen(value)
  }

  return (
    <div ref={headerRef} className={styles.navigationWrapper}>
      <CookieConsent />
      <nav
        className={classNames(styles.nav, {
          [styles.isExpanded]: isBurgerMenuOpen,
        })}
        role='navigation'
        aria-label='main navigation'
      >
        <div className={styles.container}>
          <TMEntityLogoFactory variant={entity} />
          <div className={styles.navbarItem}>
            <IconButton onClick={() => handleBurgerMenu(!isBurgerMenuOpen)}>
              {isBurgerMenuOpen ? <CloseIcon size={16} /> : <HamburgerIcon size={24} />}
            </IconButton>
          </div>
        </div>
        <div
          className={classNames(styles.menu, {
            [styles.active]: isBurgerMenuOpen,
          })}
        >
          <div ref={dropdownRef} className={styles.dropdown}>
            <NavBarMenu />
            <LocaleSelector />
            <LanguageVariants />
          </div>
        </div>
      </nav>
    </div>
  )
}
