export enum ClientTestSubcategoryEnum {
  Individual = 1,
  Company = 2,
  ClassificationProfessional = 3,
  ClassificationExperiencedRetail = 4,
  W8BENTaxForm = 5,
  DefaultIb = 6,
  MultiTierIb = 7,
  Reduced = 8,
  EMIR = 9,
}
