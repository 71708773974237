import { useTranslation } from 'react-i18next'

import { PointedRightArrowIcon } from '../../icons/PointedRightArrowIcon'
import { Text } from '../../ui/Typography/Typography'
import { Button } from '../button/Button'

import styles from './WalletSetupIndicator.module.scss'

export interface WalletSetupIndicatorProps {
  isButtonDisabled: boolean
  onSetupComplete: () => void
}

export function WalletSetupIndicator(props: WalletSetupIndicatorProps): JSX.Element {
  const { t } = useTranslation()
  const { onSetupComplete, isButtonDisabled } = props

  return (
    <div className={styles.container}>
      <div className={styles.setupStatusText}>
        <Text isParagraph>{t('Setting up your TICKMILL account.')}</Text>
        <Text>{t('Please wait a moment')}</Text>
      </div>

      <Button
        className={styles.nextButton}
        size='M'
        appearance='primary'
        disabled={isButtonDisabled}
        onClick={onSetupComplete}
      >
        <span className={styles.buttonContent}>
          <span>{t('Next')}</span>
          <span className={styles.arrowIcon}>
            <PointedRightArrowIcon color='white' />
          </span>
        </span>
      </Button>
    </div>
  )
}
