import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Tabs, defineTab } from '../../global/tabs/Tabs'
import { WalletTypeEnum } from '../../model/WalletDto'
import { FirstTimeGuideCarousel } from '../../ui/FirstTimeGuideCarousel/FirstTimeGuideCarousel'
import { useShowFirstTimeGuide } from '../../utils/FirstTimeGuideContext'
import { useWindowResize } from '../../utils/domUtils'
import { TradingAccountBalances } from './TradingAccounts/TradingAccountBalances'
import { WalletBalances } from './Wallets/WalletBalances'

import styles from './BalancesPage.module.scss'

interface BalancesPageProps {
  walletType: WalletTypeEnum
}

export const BalancesPage: React.FC<BalancesPageProps> = ({ walletType }) => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const showFirstTimeGuid = useShowFirstTimeGuide()

  const [tab, setTab] = useState('wallets')

  function* getTabs() {
    yield {
      ...defineTab('wallets', t('Tabs.Wallets')),
    }
    yield {
      ...defineTab('tradingAccounts', t('Tabs.Trading Accounts')),
    }
  }

  const tabs = [...getTabs()]
  if (isMobile) {
    return (
      <div>
        <div className={styles.tabsWrapper}>
          <Tabs tabs={tabs} currentTab={tab} onTabChanged={setTab} />
        </div>

        {showFirstTimeGuid && <FirstTimeGuideCarousel walletType={walletType} />}
        <div className={styles.balances}>
          {tab === 'wallets' && (
            <div className={styles.wallets}>
              <WalletBalances walletType={walletType} />
            </div>
          )}
          {tab === 'tradingAccounts' && (
            <div className={styles.tradingAccounts}>
              <TradingAccountBalances />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      {showFirstTimeGuid && <FirstTimeGuideCarousel />}
      <div className={styles.balances}>
        <div className={styles.wallets}>
          <WalletBalances walletType={walletType} />
        </div>
        <div className={styles.tradingAccounts}>
          <TradingAccountBalances />
        </div>
      </div>
    </React.Fragment>
  )
}
