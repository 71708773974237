import { IconProps } from './IconProps'

export function GoodIcon(props: IconProps) {
  const size = props.size || 36
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z'
        fill='#00B728'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M36.8369 18.1656C37.7651 19.1032 37.7613 20.6273 36.8283 21.5697L26.1769 32.3293C24.1526 34.3742 20.881 34.3898 18.8583 32.3643L13.4709 26.9696C12.5386 26.0361 12.5359 24.512 13.4648 23.5655C14.3938 22.619 15.9026 22.6084 16.8348 23.542L22.2222 28.9367C22.3862 29.1009 22.6515 29.0996 22.8156 28.9338L33.467 18.1743C34.4 17.2319 35.9087 17.2279 36.8369 18.1656Z'
        fill='white'
      />
    </svg>
  )
}
