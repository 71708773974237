import styles from './Icons.module.scss'

export function GettingStartedDownloadIcon() {
  return (
    <svg
      width='238'
      height='235'
      viewBox='0 0 238 235'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5334_203501)'>
        <path
          d='M27.8631 99.49H197.977C202.908 99.49 206.914 103.492 206.914 108.427V224.758H18.9258V108.427C18.9258 103.492 22.9323 99.49 27.8631 99.49Z'
          fill='#D9D9D9'
          stroke='#3C3C3C'
          stroke-width='0.692764'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          d='M200.856 102.712H25.162C23.7522 102.712 22.6055 103.868 22.6055 105.289V219.884C22.6055 220.595 23.1763 221.175 23.8862 221.175H202.136C202.841 221.175 203.417 220.6 203.417 219.884V105.289C203.417 103.868 202.27 102.712 200.86 102.712H200.856Z'
          fill='white'
        />
        <path
          d='M61.9077 184.458H48.5308C46.5959 184.458 45.0273 186.023 45.0273 187.954C45.0273 189.885 46.5959 191.45 48.5308 191.45H61.9077C63.8426 191.45 65.4111 189.885 65.4111 187.954C65.4111 186.023 63.8426 184.458 61.9077 184.458Z'
          fill='#424244'
        />
        <path
          d='M0.238281 226.001C0.238281 225.314 0.794747 224.758 1.4867 224.758H224.363C225.05 224.758 225.612 225.314 225.612 226.006V226.485C225.612 230.535 222.326 233.821 218.276 233.821H7.56911C3.51901 233.821 0.238281 230.535 0.238281 226.485V226.006V226.001Z'
          fill='#D9D9D9'
          stroke='#3C3C3C'
          stroke-width='0.692764'
          stroke-linecap='round'
          stroke-linejoin='round'
        />
        <path
          opacity='0.3'
          d='M119.802 152.943H46.7693C45.8064 152.943 45.0273 153.722 45.0273 154.685C45.0273 155.648 45.8064 156.427 46.7693 156.427H119.807C120.77 156.427 121.549 155.648 121.549 154.685C121.549 153.722 120.77 152.943 119.807 152.943H119.802Z'
          fill='#424244'
          stroke='#424244'
          stroke-width='0.483883'
          stroke-miterlimit='10'
        />
        <path
          opacity='0.3'
          d='M103.606 160.307H46.7693C45.8064 160.307 45.0273 161.086 45.0273 162.049C45.0273 163.012 45.8064 163.791 46.7693 163.791H103.606C104.569 163.791 105.348 163.012 105.348 162.049C105.348 161.086 104.569 160.307 103.606 160.307Z'
          fill='#424244'
          stroke='#424244'
          stroke-width='0.483883'
          stroke-miterlimit='10'
        />
        <path
          opacity='0.3'
          d='M73.8813 167.803H46.7693C45.8064 167.803 45.0273 168.582 45.0273 169.545C45.0273 170.508 45.8064 171.287 46.7693 171.287H73.8813C74.8442 171.287 75.6233 170.508 75.6233 169.545C75.6233 168.582 74.8442 167.803 73.8813 167.803Z'
          fill='#424244'
          stroke='#424244'
          stroke-width='0.483883'
          stroke-miterlimit='10'
        />
        <rect x='45' y='146' width='38' height='3.48' rx='1.74' fill='#C6C6C7' />
      </g>
      <path
        d='M228.178 124.188H192.618C188.035 124.188 184.32 127.903 184.32 132.485V225.535C184.32 230.117 188.035 233.832 192.618 233.832H228.178C232.761 233.832 236.476 230.117 236.476 225.535V132.485C236.476 127.903 232.761 124.188 228.178 124.188Z'
        fill='#C3C3C3'
        stroke='#424244'
        stroke-width='0.692764'
        stroke-miterlimit='10'
      />
      <path
        d='M184.619 145.228C184.619 144.9 184.354 144.635 184.026 144.635C183.699 144.635 183.434 144.9 183.434 145.228V149.376C183.434 149.704 183.699 149.969 184.026 149.969C184.354 149.969 184.619 149.704 184.619 149.376V145.228Z'
        fill='#424244'
      />
      <path
        d='M184.619 154.118C184.619 153.791 184.354 153.526 184.026 153.526C183.699 153.526 183.434 153.791 183.434 154.118V161.23C183.434 161.558 183.699 161.823 184.026 161.823C184.354 161.823 184.619 161.558 184.619 161.23V154.118Z'
        fill='#424244'
      />
      <path
        d='M184.619 164.786C184.619 164.459 184.354 164.193 184.026 164.193C183.699 164.193 183.434 164.459 183.434 164.786V171.898C183.434 172.225 183.699 172.491 184.026 172.491C184.354 172.491 184.619 172.225 184.619 171.898V164.786Z'
        fill='#424244'
      />
      <path
        d='M237.365 159.452C237.365 159.125 237.1 158.859 236.772 158.859C236.445 158.859 236.18 159.125 236.18 159.452V172.491C236.18 172.818 236.445 173.084 236.772 173.084C237.1 173.084 237.365 172.818 237.365 172.491V159.452Z'
        fill='#424244'
      />
      <path
        d='M228.672 125H193.112C189.184 125 186 128.184 186 132.112V225.162C186 229.09 189.184 232.274 193.112 232.274H228.672C232.6 232.274 235.785 229.09 235.785 225.162V132.112C235.785 128.184 232.6 125 228.672 125Z'
        fill='white'
        stroke='#424244'
        stroke-width='0.692764'
        stroke-miterlimit='10'
      />
      <path
        d='M208.779 184.458H195.402C193.467 184.458 191.898 186.023 191.898 187.954C191.898 189.885 193.467 191.45 195.402 191.45H208.779C210.714 191.45 212.282 189.885 212.282 187.954C212.282 186.023 210.714 184.458 208.779 184.458Z'
        fill='#424244'
      />
      <path
        d='M216.027 127.151H204.766C203.62 127.151 202.691 128.08 202.691 129.225C202.691 130.371 203.62 131.3 204.766 131.3H216.027C217.172 131.3 218.101 130.371 218.101 129.225C218.101 128.08 217.172 127.151 216.027 127.151Z'
        fill='#424244'
        stroke='#424244'
        stroke-width='0.592673'
        stroke-miterlimit='10'
      />
      <path
        d='M216.033 130.411C216.688 130.411 217.218 129.88 217.218 129.225C217.218 128.571 216.688 128.04 216.033 128.04C215.378 128.04 214.848 128.571 214.848 129.225C214.848 129.88 215.378 130.411 216.033 130.411Z'
        fill='white'
      />
      <rect x='191.902' y='174.759' width='15.2408' height='1.38553' rx='0.692764' fill='#C6C6C7' />
      <rect x='191.902' y='171.296' width='28.4033' height='1.38553' rx='0.692764' fill='#C6C6C7' />
      <rect x='191.902' y='167.832' width='37.4093' height='1.38553' rx='0.692764' fill='#C6C6C7' />
      <rect x='192' y='165' width='14' height='1.39' rx='0.695' fill='#C6C6C7' />
      <path
        d='M90.5087 58.8461H72.65C63.7321 58.8461 56.5195 51.623 56.5195 42.6923C56.5195 33.7615 63.7321 26.5384 72.65 26.5384H72.8343C73.9404 18.6923 80.6461 12.6923 88.7804 12.6923C92.0065 12.6923 95.0021 13.6384 97.5139 15.2538C100.993 6.96919 109.197 1.15381 118.737 1.15381C131.457 1.15381 141.78 11.4923 141.78 24.2307C141.78 25.0846 141.734 25.9153 141.642 26.7461C142.426 26.6077 143.255 26.5384 144.085 26.5384C153.003 26.5384 160.215 33.7615 160.215 42.6923C160.215 51.623 153.003 58.8461 144.085 58.8461H126.226'
        stroke='#424244'
        stroke-linecap='round'
        stroke-linejoin='round'
      />
      <path
        d='M96.7064 71.95C96.3215 71.2833 96.8027 70.45 97.5724 70.45L100.91 70.45C101.463 70.45 101.91 70.0023 101.91 69.45L101.91 43C101.91 42.4477 102.358 42 102.91 42L114.91 42C115.463 42 115.91 42.4477 115.91 43L115.91 69.45C115.91 70.0023 116.358 70.45 116.91 70.45L120.248 70.45C121.018 70.45 121.499 71.2833 121.115 71.95L109.777 91.5898C109.392 92.2565 108.429 92.2565 108.044 91.5898L96.7064 71.95Z'
        fill='#C0E3FA'
        stroke='#424244'
        stroke-miterlimit='10'
      />
      <defs>
        <clipPath id='clip0_5334_203501'>
          <rect width='225.852' height='134.805' fill='white' transform='translate(0 99.2483)' />
        </clipPath>
      </defs>
    </svg>
  )
}
