import { LossPercentagesDto } from '../model/LossPercentagesDto'
import { TickmillCompaniesEnum } from './companyName.utils'

export const getLossPercentagesForCompany = (
  items: LossPercentagesDto[],
  companyId: TickmillCompaniesEnum
): LossPercentagesDto | undefined => {
  const company = items.find((item) => item.tickmillCompany.id === companyId)
  return company ?? undefined
}
