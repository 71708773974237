import { IconProps, iconColor } from './IconProps'

export function DarkLightModeIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14.6952 3.73532L12 1L9.30479 3.73532L5.51064 3.10081L4.94385 6.89215L1.5 8.60081L3.27812 12L1.5 15.3992L4.94385 17.1079L5.51064 20.8992L9.30479 20.2647L12 23L14.6952 20.2647L18.4894 20.8992L19.0561 17.1079L22.5 15.3992L20.7219 12L22.5 8.60081L19.0561 6.89215L18.4894 3.10081L14.6952 3.73532ZM10.461 12.0002C10.461 9.71162 11.8988 7.75767 13.9235 6.98613C13.3261 6.7585 12.6777 6.63379 12 6.63379C9.02533 6.63379 6.6139 9.0364 6.6139 12.0002C6.6139 14.9639 9.02533 17.3665 12 17.3665C12.6777 17.3665 13.3261 17.2418 13.9235 17.0142C11.8988 16.2427 10.461 14.2887 10.461 12.0002Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
