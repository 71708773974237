import { differenceInMonths, differenceInYears } from 'date-fns'

import { AccountDetailedDto } from '../../model/AccountDetailedDto'

export const useAccountAgeStatuses = (accountDetailed: AccountDetailedDto | undefined) => {
  const olderThanOneYear = isAccountOlderThanOneYear(accountDetailed)
  const olderThanThreeMonths = isAccountOlderThanThreeMonths(accountDetailed)
  const olderThanOneMonth = isAccountOlderThanOneMonth(accountDetailed)

  return {
    isAccountOlderThanOneYear: olderThanOneYear,
    isAccountOlderThanThreeMonths: olderThanThreeMonths,
    isAccountOlderThanOneMonth: olderThanOneMonth,
  }
}

const isAccountOlderThanOneYear = (accountDetailed: AccountDetailedDto | undefined) => {
  const createdDate = getAccountCreatedDate(accountDetailed)
  return isAccountOlderThan(createdDate, differenceInYears, 1)
}

const isAccountOlderThanThreeMonths = (accountDetailed: AccountDetailedDto | undefined) => {
  const createdDate = getAccountCreatedDate(accountDetailed)
  return isAccountOlderThan(createdDate, differenceInMonths, 3)
}

export const isAccountOlderThanOneMonth = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  const createdDate = getAccountCreatedDate(accountDetailed)
  return isAccountOlderThan(createdDate, differenceInMonths, 1)
}

const isAccountOlderThan = (
  accountCreatedDate: string | undefined | null,
  differenceFn: (dateNow: Date, dateOld: Date) => number,
  threshold: number
): boolean => {
  if (!accountCreatedDate) {
    return false
  }

  const accountCreated = new Date(accountCreatedDate)
  const currentDate = new Date()

  const difference = differenceFn(currentDate, accountCreated)

  return difference >= threshold
}

const getAccountCreatedDate = (accountDetailed: AccountDetailedDto | undefined) => {
  return accountDetailed?.createdDate || undefined
}
