import React, { ReactNode, createContext, useContext } from 'react'

import { GlobalModalsType } from '../../routing/GlobalModals'
import { useLocallyPersistedState } from '../../utils/useStorage'

export enum DefaultModals {
  None,
}

export const AllModals = { ...DefaultModals, ...GlobalModalsType }
type ModalType = DefaultModals | GlobalModalsType | number | string

type ModalData = {
  [K in keyof ModalType]?: () => void
}

interface ModalsContextType {
  modalData: any
  activeModal: ModalType
  setActiveModal: (modalType: ModalType, data?: any) => void
  modalCallBacks: ModalData
  closeModal: () => void
}

const ModalsContext = createContext<ModalsContextType>({
  modalData: {},
  modalCallBacks: {},
  activeModal: AllModals.None,
  setActiveModal: () => {},
  closeModal: () => {},
})

const initialState = () => {
  return { id: AllModals.None, modalData: {} }
}

interface ModalStateProps {
  id: ModalType
  modalData?: any
}

export const ModalsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [activeModal, setActiveModal] = useLocallyPersistedState<ModalStateProps>(
    'activeModal',
    initialState()
  )

  const handleActiveModal = (modalType: ModalType, data?: any) => {
    setActiveModal({ id: modalType, modalData: data })
  }

  const closeModal = () => {
    setActiveModal(initialState())
  }

  return (
    <ModalsContext.Provider
      value={{
        modalCallBacks: {},
        activeModal: activeModal.id,
        modalData: activeModal.modalData,
        setActiveModal: handleActiveModal,
        closeModal,
      }}
    >
      {children}
    </ModalsContext.Provider>
  )
}

export const useModalsContext = (): ModalsContextType => {
  const context = useContext(ModalsContext)
  if (!context) {
    throw new Error('useModalsContext must be used within a ModalsProvider')
  }
  return context
}
