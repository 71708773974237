import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '../../global/button/Button'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { DotsIcon } from '../../icons/DotsIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { WalletDto } from '../../model/WalletDto'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { isZero } from '../../utils/validations'
import {
  WalletRestrictions,
  isDepositEnabledFromAction,
  isRestricted,
  isWalletSuspended,
} from '../../utils/wallet.utils'

import styles from './WalletCard.module.scss'

interface WalletCardProps {
  wallet: WalletDto
  shouldShowReservedBonus: boolean
  setModalVisible: (v: boolean) => void
  setWalletNumberModal({ visible, data }: { visible: boolean; data?: WalletDto }): void
  handleDeposit: (wallet: WalletDto) => void
  getReservedData: (walletId: string) => void
}

export const WalletCard: React.FC<WalletCardProps> = ({
  wallet,
  setModalVisible,
  setWalletNumberModal,
  shouldShowReservedBonus,
  handleDeposit,
  getReservedData,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
  const isDepositRestricted = isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)
  const isCanDepositEnabled = isDepositEnabledFromAction(wallet.depositAction)
  const isTransferFromRestricted = isRestricted(
    WalletRestrictions.TRANSFER_FROM,
    wallet.restrictions
  )
  const isWithdrawRestricted = isRestricted(WalletRestrictions.WITHDRAW, wallet.restrictions)
  return (
    <div className={styles.card}>
      <TextSmall className={styles.mainWrap}>
        {hasAllRestrictions && (
          <span className='is-pulled-left' onClick={() => setModalVisible(true)}>
            <WarningIcon size={20} color={'error'} />
          </span>
        )}{' '}
        {wallet.currency.id} {t('Wallet.Wallet')}
        <span className='is-pulled-right'>
          <span
            onClick={() =>
              setWalletNumberModal({
                visible: true,
                data: wallet,
              })
            }
            className='has-cursor-pointer'
          >
            <DotsIcon />
          </span>
        </span>
      </TextSmall>
      <h2 className={styles.title}>{formatMoney(wallet.balance, wallet.currency.id)}</h2>
      <div>
        <TextSmall className={styles.subLabel}>{t('Wallet.Reserved')}</TextSmall>
        <TextSmallStrong>{formatMoney(wallet.bonusReserved, wallet.currency.id)}</TextSmallStrong>
      </div>
      {shouldShowReservedBonus && (
        <div>
          <TextSmall className={styles.subLabel}>{t('Wallet.Reserved funds for Bonus')}</TextSmall>
          <TextSmallStrong>
            {isZero(wallet.bonusReserved) ? (
              formatMoney(wallet.bonusReserved, wallet.currency.id)
            ) : (
              <Button
                disabled={isZero(wallet.bonusReserved)}
                className={styles.clickable}
                appearance='link'
                onClick={() => getReservedData(wallet.id)}
              >
                {formatMoney(wallet.bonusReserved, wallet.currency.id)}
              </Button>
            )}
          </TextSmallStrong>
        </div>
      )}
      <div className='mt-4 mb-1'>
        <Button
          onClick={() => {
            if (isDepositRestricted || !isCanDepositEnabled) {
              setModalVisible(true)
            } else if (!isDepositRestricted) {
              handleDeposit(wallet)
            }
          }}
          size='M'
          state={isDepositRestricted || !isCanDepositEnabled ? 'disabled' : 'normal'}
          appearance='primary'
          className={styles.button}
        >
          <span className='is-flex is-align-items-center'>
            {!hasAllRestrictions && isDepositRestricted && (
              <WarningIcon size={20} className='mr-1' color='error' />
            )}
            {t('Deposit')}
          </span>
        </Button>
      </div>
      <div className='mb-1'>
        <Link
          to={
            isTransferFromRestricted ? '#' : `/dashboard/traders-room/wallets/${wallet.id}/transfer`
          }
        >
          <Button
            size='M'
            state={hasAllRestrictions || isTransferFromRestricted ? 'disabled' : 'normal'}
            appearance='secondary'
            className={styles.button}
            onClick={() => {
              if (!hasAllRestrictions && isTransferFromRestricted) {
                setModalVisible(true)
              }
            }}
          >
            <span className='is-flex is-align-items-center'>
              {!hasAllRestrictions && isTransferFromRestricted && (
                <WarningIcon size={20} className='mr-1' color='error' />
              )}
              {t('Transfer')}
            </span>
          </Button>
        </Link>
      </div>
      <div className='mb-4'>
        <Link
          to={
            isWithdrawRestricted ? '#' : `/dashboard/traders-room/wallets/${wallet.id}/withdrawal`
          }
        >
          <Button
            size='M'
            state={hasAllRestrictions || isWithdrawRestricted ? 'disabled' : 'normal'}
            appearance='secondary'
            className={styles.button}
            onClick={() => {
              if (!hasAllRestrictions && isWithdrawRestricted) {
                setModalVisible(true)
              }
            }}
          >
            <span className='is-flex is-align-items-center'>
              {!hasAllRestrictions && isWithdrawRestricted && (
                <WarningIcon size={20} className='mr-1' color='error' />
              )}
              {t('Withdraw')}
            </span>
          </Button>
        </Link>
      </div>
      <div>
        <Link
          className={styles.history}
          to='/dashboard/traders-room/transaction-history'
          state={{ walletId: wallet.id }}
        >
          {t('View Transaction History')}
        </Link>
      </div>
    </div>
  )
}
