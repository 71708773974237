import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { Modal } from '../../global/modal/Modal'
import { AuthSessionContext } from '../../utils/AuthContext'
import { getLastBusinessDayOfMonth } from '../../utils/date.utils'

interface Props {
  onClickUnsubscribe(): void
}

interface UnsubscribeConfirmationModalProps extends Props {
  dueDate: string | undefined
  setUnsubscribeConfirmationModal: () => void
}

interface UnsubscribeOpenPositionModalProps extends Props {
  setUnsubscribeOpenPositionModal: () => void
}

export const UnsubscribeConfirmationModal: React.FC<UnsubscribeConfirmationModalProps> = ({
  setUnsubscribeConfirmationModal,
  onClickUnsubscribe,
}) => {
  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType.name

  const lastBusinessDayOfMonth = getLastBusinessDayOfMonth().getDate()
  const dates = format(lastBusinessDayOfMonth, dateFormat || 'yyyy-MM-dd')

  return (
    <Modal
      closeModal={setUnsubscribeConfirmationModal}
      render={({ closeModal }) => (
        <CancelActionModal
          title={t('Market Data.Attention')}
          onCancel={closeModal}
          cancelLabel={t('Market Data.Keep subscription')}
          confirmLabel={t('Market Data.Yes, unsubscribe')}
          onConfirm={onClickUnsubscribe}
        >
          <p className='text'>{t('Market Data.unsubscribe confirmation 1')}</p>
          <p className='text mt-4'>
            {t('Market Data.unsubscribe confirmation 2', {
              dates,
            })}
          </p>
          <p className='text mt-4'>{t('Market Data.unsubscribe confirmation 3')}</p>
        </CancelActionModal>
      )}
    />
  )
}

export const UnsubscribeOpenPositionModal: React.FC<UnsubscribeOpenPositionModalProps> = ({
  setUnsubscribeOpenPositionModal,
  onClickUnsubscribe,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={setUnsubscribeOpenPositionModal}
      render={({ closeModal }) => (
        <CancelActionModal
          title={t('Market Data.Attention')}
          onCancel={closeModal}
          cancelLabel={t('Market Data.Keep subscription')}
          confirmLabel={t('Market Data.Yes, unsubscribe')}
          onConfirm={onClickUnsubscribe}
        >
          <p className='text'>{t('Market Data.unsubscribe open position 1')}</p>
        </CancelActionModal>
      )}
    />
  )
}
