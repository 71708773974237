import React, { PropsWithChildren, ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { Button, ButtonProps } from '../../global/button/Button'
import { useSessionEntity } from '../../global/context/EntityContext'
import { CopyLink } from '../../global/copyLink/CopyLink'
import IconButton from '../../global/iconButton/IconButton'
import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table, TableBody, TableCell } from '../../global/table/Table'
import { detectThemeClientSide } from '../../global/theme/Theme'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { useTraderUrlByPlatform } from '../../hooks/useTraderUrlByPlatform'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../../icons/ChevronUpIcon'
import { CopyIcon } from '../../icons/CopyIcon'
import { DotsIcon } from '../../icons/DotsIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { isAccountGroupLive } from '../../model/AccountGroupType'
import { PlatformType, isPlatformTypeDisabled } from '../../model/PlatformTypeDto'
import {
  PlatformTypeEnum,
  isMT5PlatformType,
  isPlatformTypeCQGCAST,
  isTMTPlatformType,
} from '../../model/PlatformTypeEnum'
import {
  DemoTradingAccount,
  TradingAccount,
  isTradingAccountStatusApproved,
} from '../../model/TradingAccount'
import { AlertIconWrapper } from '../../ui/AlertIconWrapper/AlertIconWrapper'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextH3, TextLargeStrong, TextSmall } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { PageQuery } from '../../utils/ApiClient'
import { TickmillCompaniesEnum, isTickmillSCType } from '../../utils/companyName.utils'
import { ElementResizeProperty } from '../../utils/domUtils'
import { getCentralRowIdConditionally } from '../../utils/getItemId'
import { addSwapFreePostfix } from '../../utils/text'
import {
  TradingAccountRestrictions,
  isRestricted,
  isTradingAccountSuspended,
} from '../../utils/trading-account.utils'
import { useTradingAccountsMessage } from './useTradingAccountsMessage'

import styles from './TradingAccountsTable.module.scss'

interface Props {
  data: TradingAccount[]
  isLoading: boolean
  hasReachedMaxTradingAccounts?: boolean
  pageQuery?: PageQuery
  setPageQuery: ((pageQuery?: PageQuery | undefined) => void) | undefined
  setSelectedTradingAccount?: (tradingAccount: TradingAccount | DemoTradingAccount) => void
  setTradingAccountModal?: (visible: boolean) => void
  setDisplayDynamicLeverageInfo?(v: boolean): void
  platformTypes?: PlatformType[]
  columnsProperties?: ElementResizeProperty<HTMLTableHeaderCellElement>[]
}

type TableWithModals = Props & {
  setRestrictionModal?(v: boolean): void
  handleSetSelectedTradingAccount?(ta: TradingAccount | DemoTradingAccount): void
  setCampaignModal?(v: boolean): void
  onLeverageChange?({
    tradingAccount,
    restricted,
  }: {
    tradingAccount?: TradingAccount
    restricted?: boolean
  }): void
  setAddTradingAccountInfoModalVisible?(v: boolean): void
  setWebTraderMT4IsUnavailableModalVisible?(v: boolean): void
  setWebTraderMT5IsUnavailableModalVisible?(v: boolean): void
  setWebTraderTickmillTraderIsUnavailableModalVisible?(v: boolean): void
  setDisplayCurrencyInfo?(value: boolean): void
}

type DemoTradingAccountsTableProps = Omit<TableWithModals, 'data'> & {
  data: DemoTradingAccount[]
  handleInfoModal: (title: string) => void
  isDemoTradingInActive?: boolean
  isCreateAllowed?: boolean
  isDemoTickmillTraderCreateAllowed?: boolean
  setDisplayDynamicLeverageInfo?(v: boolean): void

  isCountryAllowed?: boolean
  title?: string
  columnsProperties?: ElementResizeProperty<HTMLTableHeaderCellElement>[]
}

export const tradingAccountsTableColumnsNumber = 9

const columnNumbers: number[] = [...Array(tradingAccountsTableColumnsNumber).keys()]

const isWebTraderAvailable = (tradingAccount: TradingAccount) => {
  return (
    isAccountGroupLive(tradingAccount.accountGroupType.id) &&
    !isPlatformTypeCQGCAST(tradingAccount.platformType.id) &&
    isTradingAccountStatusApproved(tradingAccount.status)
  )
}

const isDemoWebTraderAvailable = (tradingAccount: DemoTradingAccount) => {
  return (
    !isPlatformTypeCQGCAST(tradingAccount.platformType.id) &&
    isTradingAccountStatusApproved(tradingAccount.status)
  )
}

export const InactiveTradingAccountsTable: React.FC<Props> = ({
  data,
  isLoading,
  setPageQuery,
  pageQuery,
  columnsProperties,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const getThWidthStyle = (thNumber: number) => {
    if (!columnsProperties) {
      return
    }

    return { minWidth: columnsProperties[thNumber]?.elementWidth }
  }

  return (
    <React.Fragment>
      <PageHeader
        id={ScrollToIds.InactiveLiveTradingAccountsHeader}
        renderTitle={() => (
          <TextLargeStrong className='text-primary'>
            {t('Trading Account.Inactive Trading Accounts')}
          </TextLargeStrong>
        )}
      />
      <Table inactive>
        <Loading showLoadingIcon isLoading={isLoading}>
          <thead>
            <tr>
              <SortHeader
                id='Name'
                sort={(pageQuery && pageQuery!.sort) || undefined}
                sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
                setSort={(sort, sortOrder) =>
                  setPageQuery!({
                    ...pageQuery,
                    sort,
                    sortOrder,
                  })
                }
                style={getThWidthStyle(columnNumbers[0])}
              >
                {t('Trading Account.Account Number')}
              </SortHeader>
              <th style={getThWidthStyle(columnNumbers[1])} align='left'>
                {t('Trading Account.Account Type')}
              </th>
              <th style={getThWidthStyle(columnNumbers[2])} align='center'>
                {t('Trading Account.Balance')}
              </th>
              <th style={getThWidthStyle(columnNumbers[3])} align='center'>
                {t('Trading Account.Leverage')}
              </th>
              <th style={getThWidthStyle(columnNumbers[4])} align='center'>
                {t('Trading Account.Platform')}
              </th>
              <th style={getThWidthStyle(columnNumbers[5])} align='center'>
                {t('Trading Account.Transaction History')}
              </th>
              <th style={getThWidthStyle(columnNumbers[6])} />
              <th style={getThWidthStyle(columnNumbers[7])} />
              <th style={getThWidthStyle(columnNumbers[8])} />
            </tr>
          </thead>
          <tbody>
            {!data.length ? (
              <td align='center' colSpan={tradingAccountsTableColumnsNumber}>
                {t('No results')}
              </td>
            ) : (
              data?.map((row, index) => {
                const balance = formatMoney(row.balance, row.currency.id)

                return (
                  <tr
                    key={row.id}
                    {...getCentralRowIdConditionally(
                      index,
                      data.length,
                      'inactive-live-trading-accounts'
                    )}
                  >
                    <td width={180}>
                      {row.currency.id}-{row.name}
                    </td>
                    <td align='left' width={140}>
                      {row.accountType.name}
                    </td>
                    <td align='center' width={80} className={styles.trimMid} title={balance}>
                      {balance}
                    </td>
                    <td align='center' width={90}>
                      {row.leverageType.name}
                    </td>
                    <td align='center'>{row.platformType.name}</td>
                    <td align='center'>
                      <Link
                        className='is-link'
                        to='/dashboard/traders-room/transaction-history'
                        state={{
                          tradingAccountId: row.id,
                        }}
                      >
                        {t('View')}
                      </Link>
                    </td>
                    <td width={100} />
                    <td width={120} />
                    <td width={50} />
                  </tr>
                )
              })
            )}
          </tbody>
        </Loading>
      </Table>
    </React.Fragment>
  )
}

export const TradingAccountsTable: React.FC<TableWithModals> = (props) => {
  const {
    data,
    setRestrictionModal,
    setPageQuery,
    pageQuery,
    handleSetSelectedTradingAccount,
    setWebTraderMT4IsUnavailableModalVisible,
    setWebTraderMT5IsUnavailableModalVisible,
    setWebTraderTickmillTraderIsUnavailableModalVisible,
    setCampaignModal,
    isLoading,
    hasReachedMaxTradingAccounts,
    onLeverageChange,
    setDisplayCurrencyInfo,
    setDisplayDynamicLeverageInfo,
    columnsProperties,
    platformTypes,
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const tradingAccountMessage = useTradingAccountsMessage()
  const theme = detectThemeClientSide()
  const isEUEntity = useSessionEntity() === TickmillCompaniesEnum.TICKMILL_EU
  const { account } = useAccountReadContext()
  const { formatMoney, formatNumber } = useFormatNumber()
  const { scrollIntoView } = useScrollToElementIds()
  const [expandedRow, setExpandedRow] = useState('')
  const handleSetExpandedRow = (id: string, rowIndex: number) => {
    if (id === expandedRow) {
      id = ''
    }
    id && scrollIntoView([`live-trading-accounts-item-${rowIndex + 1}`])
    setExpandedRow(id)
  }

  const getThRef = (thNumber: number) => {
    return columnsProperties && columnsProperties[thNumber]?.elementRef
  }

  const handleWebTraderModalVisibility = (tradingAccount: TradingAccount) => {
    switch (tradingAccount.platformType.id) {
      case PlatformTypeEnum.MT4:
        setWebTraderMT4IsUnavailableModalVisible?.(true)
        break
      case PlatformTypeEnum.MT5:
        setWebTraderMT5IsUnavailableModalVisible?.(true)
        break
      default:
        setWebTraderTickmillTraderIsUnavailableModalVisible?.(true)
        break
    }
  }

  return (
    <React.Fragment>
      <PageHeader
        id={ScrollToIds.LiveTradingAccountsHeader}
        renderTitle={() => (
          <TextSmall className='text-secondary'>
            {t('Trading Account.Live Trading Accounts')}
          </TextSmall>
        )}
        renderSubtitle={() => (
          <div className='is-flex is-align-items-center'>
            <TextH3>{`≈${formatMoney(
              data.reduce(
                (acc, account) => (acc += account.platformOverview?.balanceInDisplayCurrency || 0),
                0
              ),
              account?.displayCurrency?.id
            )}`}</TextH3>

            {setDisplayCurrencyInfo && (
              <IconButton className='ml-2' onClick={() => setDisplayCurrencyInfo(true)}>
                <InfoIcon size={14} />
              </IconButton>
            )}
          </div>
        )}
        actions={[
          {
            disabled: hasReachedMaxTradingAccounts,
            size: 'S',
            onClick: () => navigate('/dashboard/traders-room/trading-accounts/new'),
            label: `+ ${t('Trading Account.Add Trading Account')}`,
            tooltipText: tradingAccountMessage,
          },
        ]}
      />
      <Table {...(data.length && { spacing: 'none' })} className={styles.table}>
        <Loading showLoadingIcon isLoading={isLoading}>
          <thead>
            <tr>
              <SortHeader
                id='Name'
                sort={(pageQuery && pageQuery!.sort) || undefined}
                sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
                setSort={(sort, sortOrder) =>
                  setPageQuery!({
                    ...pageQuery,
                    sort,
                    sortOrder,
                  })
                }
                ref={getThRef(columnNumbers[0])}
              >
                {t('Trading Account.Account Number')}
              </SortHeader>
              <th ref={getThRef(columnNumbers[1])} align='left'>
                {t('Trading Account.Account Type')}
              </th>
              <th ref={getThRef(columnNumbers[2])} align='center'>
                {t('Trading Account.Balance')}
              </th>
              <th ref={getThRef(columnNumbers[3])} align='center'>
                {t('Trading Account.Leverage')}
              </th>
              <th ref={getThRef(columnNumbers[4])} align='center'>
                {t('Trading Account.Platform')}
              </th>
              <th ref={getThRef(columnNumbers[5])} align='center'>
                {t('Trading Account.Transaction History')}
              </th>
              <th ref={getThRef(columnNumbers[6])}></th>
              <th ref={getThRef(columnNumbers[7])}></th>
              <th ref={getThRef(columnNumbers[8])}></th>
            </tr>
          </thead>
          {!data.length ? (
            <td align='center' colSpan={tradingAccountsTableColumnsNumber}>
              {t('No results')}
            </td>
          ) : (
            data?.map((tradingAccount, index) => {
              const isTickmillPlatformTrader = isTMTPlatformType(tradingAccount.platformType.id)
              const hasAllRestrictions = isTradingAccountSuspended(tradingAccount.restrictions)
              const isTradeDisabled = !isWebTraderAvailable(tradingAccount)
              const hasCampaign = !!tradingAccount.campaigns.length
              const isPlatformDisabled =
                platformTypes &&
                isPlatformTypeDisabled(platformTypes, tradingAccount.platformType.id)
              const isRowSelected = tradingAccount.id === expandedRow
              const referralCode = tradingAccount.introducingBroker?.name
              const equity = formatNumber(tradingAccount.platformOverview.equity)
              const balance = formatMoney(
                tradingAccount.platformOverview.balance,
                tradingAccount.currency.id
              )
              const isCollapsible =
                isTickmillPlatformTrader ||
                (!isEUEntity && !!referralCode?.length) ||
                !!equity.length

              return (
                <TableBody
                  margined
                  spacing='none'
                  key={tradingAccount.id}
                  id={`live-trading-accounts-item-${index + 1}`}
                >
                  <tr
                    onClick={() => hasAllRestrictions && setRestrictionModal?.(true)}
                    key={tradingAccount.id}
                    className={classNames({
                      [styles.expandableParent]: isRowSelected,
                      'has-cursor-pointer': hasAllRestrictions,
                    })}
                  >
                    <td
                      className={classNames({
                        ['has-cursor-pointer']: isCollapsible,
                      })}
                      width={180}
                      onClick={() => handleSetExpandedRow(tradingAccount.id, index)}
                    >
                      <span className={styles.transactionTypeName}>
                        {isCollapsible && (
                          <span className='is-flex pr-2'>
                            {isRowSelected ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        )}
                        {hasAllRestrictions && <WarningIcon size={20} color={'error'} />}{' '}
                        {tradingAccount.currency.id}
                        <span>-</span>
                        {tradingAccount.name}
                      </span>
                    </td>
                    <td align='center' width={140}>
                      <span className='is-flex is-align-items-center'>
                        {hasCampaign ? (
                          <span className='is-flex is-align-items-center'>
                            {tradingAccount.accountType.name}
                            <span className='text-primary ml-1'>
                              {t('Trading Account.Campaign')}
                            </span>
                            <span className='is-flex ml-1'>
                              <IconButton onClick={() => setCampaignModal?.(true)}>
                                <InfoIcon />
                              </IconButton>
                            </span>
                          </span>
                        ) : (
                          <span className='is-flex'>
                            {addSwapFreePostfix(
                              tradingAccount.isSwapFree,
                              tradingAccount.accountType.name,
                              t
                            )}
                          </span>
                        )}
                      </span>
                    </td>
                    <td align='center' width={80} className={styles.trimMid} title={balance}>
                      {balance}
                    </td>
                    <td
                      align={tradingAccount.platformType.dynamicLeverage ? 'left' : 'center'}
                      width={90}
                    >
                      <div className='is-flex'>
                        <Button
                          appearance='plain'
                          size='S'
                          className='p-0 has-text-weight-normal'
                          onClick={() => {
                            onLeverageChange?.({
                              tradingAccount,
                              restricted: hasAllRestrictions,
                            })
                          }}
                        >
                          {tradingAccount.leverageType.name}
                        </Button>
                        {tradingAccount.platformType.dynamicLeverage && (
                          <IconButton
                            className='ml-2'
                            onClick={() => setDisplayDynamicLeverageInfo?.(true)}
                          >
                            <InfoIcon color='warning' size={14} />
                          </IconButton>
                        )}
                      </div>
                    </td>
                    <td align='center'>{tradingAccount.platformType.name}</td>
                    <td align='center'>
                      <Link
                        className='is-link'
                        to='/dashboard/traders-room/transaction-history'
                        state={{
                          tradingAccountId: tradingAccount.id,
                        }}
                      >
                        {t('View')}
                      </Link>
                    </td>
                    <td align='center' width={100}>
                      <span className='is-flex is-align-items-center'>
                        <ButtonLinkWrapper
                          disabled={isTradeDisabled || isPlatformDisabled}
                          tradingAccount={tradingAccount}
                        >
                          <Button size='XS' appearance='primary'>
                            <span
                              className={classNames({
                                [styles.disabled]:
                                  theme === 'dark' && (isTradeDisabled || isPlatformDisabled),
                              })}
                            >
                              {t('Trade')}
                            </span>
                          </Button>
                        </ButtonLinkWrapper>
                        {(isTradeDisabled || isPlatformDisabled) && (
                          <span className='is-flex ml-1'>
                            <IconButton
                              onClick={() => handleWebTraderModalVisibility(tradingAccount)}
                            >
                              <InfoIcon />
                            </IconButton>
                          </span>
                        )}
                      </span>
                    </td>
                    <td align='center' width={120}>
                      <TradingAccountManageFundsButton
                        tradingAccount={tradingAccount}
                        setRestrictionModal={setRestrictionModal}
                      />
                    </td>
                    <td width={50} align='center'>
                      <span
                        className='has-cursor-pointer is-flex is-align-content-center is-justify-content-end'
                        onClick={() => handleSetSelectedTradingAccount?.(tradingAccount)}
                      >
                        <DotsIcon />
                      </span>
                    </td>
                  </tr>
                  {isRowSelected && isCollapsible && (
                    <tr className={styles.expandable}>
                      <TableCell expanded colSpan={12}>
                        <div className={classNames('px-4 py-4', styles.container)}>
                          {!!tradingAccount.externalId?.length && (
                            <div className={styles.box}>
                              <TextSmall className={classNames(styles.textSecondary)}>
                                {t('Trading Account.Username')}:{' '}
                              </TextSmall>
                              {tradingAccount.externalId && (
                                <TextSmall>{tradingAccount.externalId}</TextSmall>
                              )}
                              <CopyLink value={tradingAccount.externalId}>
                                <div className='pt-1'>
                                  <CopyIcon size={18} />
                                </div>
                              </CopyLink>
                            </div>
                          )}
                          {!isEUEntity && !!referralCode?.length && (
                            <div className={styles.box}>
                              <TextSmall className={classNames(styles.textSecondary)}>
                                {t('Trading Account.Referral Code')}:{' '}
                              </TextSmall>
                              <TextSmall>{tradingAccount.introducingBroker?.name}</TextSmall>
                            </div>
                          )}
                          {!!equity.length && (
                            <div className={styles.box}>
                              <TextSmall className={classNames(styles.textSecondary)}>
                                {t('Trading Account.Equity')}
                              </TextSmall>
                              <TextSmall>{equity}</TextSmall>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </tr>
                  )}
                </TableBody>
              )
            })
          )}
        </Loading>
      </Table>
    </React.Fragment>
  )
}

interface ManageFundsButtonProps {
  tradingAccount: TradingAccount

  setRestrictionModal?(v: boolean): void
}

const TradingAccountManageFundsButton: React.FC<ManageFundsButtonProps> = (props) => {
  const { tradingAccount, setRestrictionModal } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const hasAllRestrictions = isTradingAccountSuspended(tradingAccount.restrictions)
  const isTransferFromRestricted = isRestricted(
    TradingAccountRestrictions.TRANSFER_FROM,
    tradingAccount.restrictions
  )
  const hasCampaign = !!tradingAccount.campaigns.length

  if (hasCampaign) {
    return (
      <Link
        to={`/dashboard/traders-room/trading-accounts/${tradingAccount.id}/deposit-to-campaign`}
      >
        <Button size='XS' appearance='primary'>
          {t('Deposit')}
        </Button>
      </Link>
    )
  }

  const handleTransfer = () => {
    if (!hasAllRestrictions && isTransferFromRestricted) {
      setRestrictionModal?.(true)
    } else {
      navigate(`/dashboard/traders-room/trading-accounts/${tradingAccount.id}/transfer`)
    }
  }

  return (
    <AlertIconWrapper spaced isActive={!hasAllRestrictions && isTransferFromRestricted}>
      <Button
        onClick={handleTransfer}
        size='XS'
        state={isTransferFromRestricted ? 'disabled' : 'normal'}
        appearance='secondary'
      >
        {t('Transfer')}
      </Button>
    </AlertIconWrapper>
  )
}

export const DemoTradingAccountsTable: React.FC<DemoTradingAccountsTableProps> = (props) => {
  const {
    data = [],
    isDemoTradingInActive = false,
    isCreateAllowed = false,
    isCountryAllowed = false,
    isLoading,
    pageQuery,
    setAddTradingAccountInfoModalVisible,
    setWebTraderMT4IsUnavailableModalVisible,
    setWebTraderMT5IsUnavailableModalVisible,
    setWebTraderTickmillTraderIsUnavailableModalVisible,
    setDisplayDynamicLeverageInfo,
    handleInfoModal,
    setPageQuery,
    platformTypes,
    handleSetSelectedTradingAccount,
    columnsProperties,
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const entity = useSessionEntity()
  const { formatMoney, formatNumber } = useFormatNumber()
  const { scrollIntoView } = useScrollToElementIds()
  const [expandedRow, setExpandedRow] = useState('')

  const platformModalSetters: { [key in PlatformTypeEnum]?: (v: boolean) => void } = {
    [PlatformTypeEnum.MT4]: setWebTraderMT4IsUnavailableModalVisible,
    [PlatformTypeEnum.MT5]: setWebTraderMT5IsUnavailableModalVisible,
    [PlatformTypeEnum.TickmillTrader]: setWebTraderTickmillTraderIsUnavailableModalVisible,
  }

  const getThWidthStyle = (thNumber: number) => {
    if (!columnsProperties) {
      return
    }

    return { minWidth: columnsProperties[thNumber]?.elementWidth }
  }

  const handleSetExpandedRow = (id: string, rowIndex: number) => {
    if (id === expandedRow) {
      id = ''
    }
    id &&
      scrollIntoView([
        isDemoTradingInActive
          ? `inactive-demo-trading-accounts-item-${rowIndex + 1}`
          : `demo-trading-accounts-item-${rowIndex + 1}`,
      ])
    setExpandedRow(id)
  }

  const infoMessage = () => {
    if (!isCreateAllowed && !isCountryAllowed) {
      return t(
        'Trading Account.It appears that the Demo Trading Account is not available in your country'
      )
    }

    if (!isCreateAllowed) {
      return t('Trading Account.You have reached the maximum number of Demo Trading Accounts')
    }

    return undefined
  }

  const handleWebTraderModalVisibility = (tradingAccount: DemoTradingAccount) => {
    switch (tradingAccount.platformType.id) {
      case PlatformTypeEnum.MT4:
        setWebTraderMT4IsUnavailableModalVisible?.(true)
        break
      case PlatformTypeEnum.MT5:
        setWebTraderMT5IsUnavailableModalVisible?.(true)
        break
      default:
        setWebTraderTickmillTraderIsUnavailableModalVisible?.(true)
        break
    }
  }

  return (
    <React.Fragment>
      {isDemoTradingInActive ? (
        <PageHeader
          id={ScrollToIds.InactiveDemoTradingAccountsHeader}
          renderTitle={() => (
            <TextLargeStrong className='text-primary'>
              {t('Trading Account.Inactive Demo Trading Accounts')}
            </TextLargeStrong>
          )}
        />
      ) : (
        <PageHeader
          id={ScrollToIds.DemoTradingAccountsHeader}
          renderTitle={
            <TextLargeStrong className='text-primary'>
              {t('Trading Account.Demo Trading Accounts')}
            </TextLargeStrong>
          }
          actions={[
            {
              size: 'S',
              onClick: () => navigate('/dashboard/traders-room/trading-accounts/new/demo'),
              label: `+ ${t('Trading Account.Add Demo Trading Account')}`,
              disabled: !isCreateAllowed,
              infoHandler: setAddTradingAccountInfoModalVisible
                ? () => setAddTradingAccountInfoModalVisible?.(true)
                : undefined,
              tooltipText: infoMessage(),
            },
          ]}
        />
      )}
      <Table
        inactive={isDemoTradingInActive}
        {...(data.length && { spacing: 'none' })}
        className={styles.table}
      >
        <Loading showLoadingIcon isLoading={isLoading}>
          <thead>
            <tr>
              <SortHeader
                id='Name'
                sort={(pageQuery && pageQuery!.sort) || undefined}
                sortOrder={(pageQuery && pageQuery!.sortOrder) || 'DESC'}
                setSort={(sort, sortOrder) =>
                  setPageQuery!({
                    ...pageQuery,
                    sort,
                    sortOrder,
                  })
                }
                style={getThWidthStyle(columnNumbers[0])}
              >
                {t('Trading Account.Account Number')}
              </SortHeader>
              <th align='left' style={getThWidthStyle(columnNumbers[1])}>
                {t('Trading Account.Account Type')}
              </th>
              <th align='center' style={getThWidthStyle(columnNumbers[2])}>
                {t('Trading Account.Balance')}
              </th>
              <th align='center' style={getThWidthStyle(columnNumbers[3])}>
                {t('Trading Account.Leverage')}
              </th>
              <th align='center' style={getThWidthStyle(columnNumbers[4])}>
                {t('Trading Account.Platform')}
              </th>
              <th align='center' />
              <th align='center' />

              {!isDemoTradingInActive && data.length ? (
                <>
                  <th align='center' />
                  <th align='center' />
                </>
              ) : null}
            </tr>
          </thead>
          {!data.length ? (
            <td align='center' colSpan={12}>
              {t('No results')}
            </td>
          ) : (
            data?.map((tradingAccount, index) => {
              const isTradeDisabled = !isDemoWebTraderAvailable(tradingAccount)
              const isTickmillPlatformTrader = isTMTPlatformType(tradingAccount.platformType.id)
              const isMT5 = isMT5PlatformType(tradingAccount.platformType.id)
              const isPlatformDisabled =
                platformTypes &&
                isPlatformTypeDisabled(platformTypes, tradingAccount.platformType.id)
              const isRowSelected = tradingAccount.id === expandedRow
              const equity = formatNumber(tradingAccount.platformOverview?.equity)
              const balance = formatMoney(
                tradingAccount.platformOverview?.balance,
                tradingAccount.currency.id
              )
              const isCollapsible = !isDemoTradingInActive || !!equity.length

              return (
                <TableBody
                  margined
                  spacing='none'
                  key={tradingAccount.id}
                  id={
                    isDemoTradingInActive
                      ? `inactive-demo-trading-accounts-item-${index + 1}`
                      : `demo-trading-accounts-item-${index + 1}`
                  }
                >
                  <tr
                    className={classNames({
                      [styles.expandableParent]: isRowSelected && !isCollapsible,
                    })}
                  >
                    <td
                      className={classNames({
                        ['has-cursor-pointer']: isCollapsible,
                      })}
                      onClick={() => handleSetExpandedRow(tradingAccount.id, index)}
                      width={180}
                    >
                      <span className={styles.transactionTypeName}>
                        {isCollapsible && (
                          <span className='is-flex pr-2'>
                            {isRowSelected ? <ChevronDownIcon /> : <ChevronUpIcon />}
                          </span>
                        )}
                        {tradingAccount.currency.id}
                        <span>-</span>
                        {tradingAccount.name}
                      </span>
                    </td>
                    <td align='left' width={140}>
                      {tradingAccount.accountType?.name}
                    </td>
                    <td align='center' width={80} className={styles.trimMid} title={balance}>
                      {balance}
                    </td>
                    <td align='center' width={90}>
                      <div className='is-flex'>
                        <span>{tradingAccount.leverage?.name}</span>
                        {isMT5 && isTickmillSCType(entity) && !isDemoTradingInActive && (
                          <IconButton
                            className='ml-2 has-cursor-pointer'
                            onClick={() => setDisplayDynamicLeverageInfo?.(true)}
                          >
                            <InfoIcon color='warning' size={14} />
                          </IconButton>
                        )}
                      </div>
                    </td>
                    <td align='center' width={80}>
                      {tradingAccount.platformType?.name}
                    </td>
                    <td width={100} align='center'>
                      {!isDemoTradingInActive &&
                        isTradeDisabled &&
                        platformModalSetters[tradingAccount.platformType.id] && (
                          <span className='is-flex is-align-items-center'>
                            <IconButton
                              onClick={() =>
                                platformModalSetters[tradingAccount.platformType.id]?.(true)
                              }
                            >
                              <InfoIcon />
                            </IconButton>
                          </span>
                        )}
                    </td>
                    {!isDemoTradingInActive ? (
                      <>
                        <td align='center' width={100}>
                          <span className='is-flex is-align-items-center'>
                            <ButtonLinkWrapper
                              disabled={isTradeDisabled || isPlatformDisabled}
                              tradingAccount={tradingAccount}
                            >
                              <Button size='XS' appearance='primary'>
                                {t('Trade')}
                              </Button>
                            </ButtonLinkWrapper>
                            {(isTradeDisabled || isPlatformDisabled) && (
                              <span className='is-flex ml-1'>
                                <IconButton
                                  onClick={() => handleWebTraderModalVisibility(tradingAccount)}
                                >
                                  <InfoIcon />
                                </IconButton>
                              </span>
                            )}
                          </span>
                        </td>
                        <td width={150} align='center'>
                          <Link
                            to={
                              isTradingAccountStatusApproved(tradingAccount.status)
                                ? `/dashboard/traders-room/trading-accounts/${tradingAccount.id}/demo/add-balance`
                                : '#'
                            }
                          >
                            <Button
                              size='XS'
                              disabled={!isTradingAccountStatusApproved(tradingAccount.status)}
                              appearance='secondary'
                            >
                              {t('Trading Account.Add')} {t('Trading Account.Balance')}
                            </Button>
                          </Link>
                        </td>
                        <td width={50} align='center'>
                          <span
                            className='has-cursor-pointer is-flex is-align-content-center is-justify-content-end'
                            onClick={() => {
                              isTMTPlatformType(tradingAccount.platformType.id)
                                ? handleSetSelectedTradingAccount?.(tradingAccount)
                                : handleInfoModal(
                                    `${tradingAccount.currency.id}-${tradingAccount.name}`
                                  )
                            }}
                          >
                            <DotsIcon />
                          </span>
                        </td>
                      </>
                    ) : (
                      <>
                        <td />
                        <td />
                        <td />
                      </>
                    )}
                  </tr>
                  {isRowSelected && isCollapsible && (
                    <tr className={styles.expandable}>
                      <TableCell expanded colSpan={12}>
                        <div className={classNames('px-4 py-4', styles.container)}>
                          {!isDemoTradingInActive && tradingAccount.externalId && (
                            <div className={styles.box}>
                              <TextSmall className={classNames(styles.textSecondary)}>
                                {t('Trading Account.Username')}:{' '}
                              </TextSmall>
                              {tradingAccount.externalId && (
                                <TextSmall>{tradingAccount.externalId}</TextSmall>
                              )}
                              <CopyLink value={tradingAccount.externalId}>
                                <div className='pt-1'>
                                  <CopyIcon size={18} />
                                </div>
                              </CopyLink>
                            </div>
                          )}
                          {!!equity.length && (
                            <div className={styles.box}>
                              <TextSmall className={classNames(styles.textSecondary)}>
                                {t('Trading Account.Equity')}:{' '}
                              </TextSmall>
                              <TextSmall>{equity}</TextSmall>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    </tr>
                  )}
                </TableBody>
              )
            })
          )}
        </Loading>
      </Table>
    </React.Fragment>
  )
}

interface ButtonLinkWrapperProps {
  disabled: boolean | undefined
  tradingAccount: TradingAccount | DemoTradingAccount
  children: ReactElement
}

const ButtonLinkWrapper: React.FC<PropsWithChildren<ButtonLinkWrapperProps>> = ({
  disabled,
  tradingAccount,
  children,
}): ReactElement => {
  const webTraderUrl = useTraderUrlByPlatform(tradingAccount)
  const navigate = useNavigate()

  const childrenWithProps = React.isValidElement(children)
    ? React.cloneElement(children as React.ReactElement<ButtonProps>, {
        disabled,
      })
    : children

  if (!webTraderUrl) {
    return <></>
  }
  if (disabled) {
    return childrenWithProps
  }

  return (
    <>
      {isTMTPlatformType(tradingAccount.platformType.id) ? (
        <Link
          className='is-link'
          to={webTraderUrl}
          onClick={(e) => {
            e.preventDefault()
            navigate(webTraderUrl, { state: { tmtAccountTradeBtnClick: true } })
          }}
        >
          {children}
        </Link>
      ) : (
        <a className='is-link' target='_blank' href={webTraderUrl} rel='noreferrer'>
          {children}
        </a>
      )}
    </>
  )
}
