import React, { useContext } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { SignupFormValues, useSignup } from '../../../../../global/context/SignupContext'
import { ToastContext, successToast } from '../../../../../global/toast/Toast'
import { AccountType } from '../../../../../model/AccountType'
import { isCannotCreateLeadError, isInvalidLeadError } from '../../../../../routing/RouterToaster'
import { Text, TextH1 } from '../../../../../ui/Typography/Typography'
import { useApiClient } from '../../../../../utils/ApiClient'
import { useSupportContacts } from '../../../../../utils/SupportContactsContext'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { SignupErrorStep } from '../../../Components/SignupErrorStep'
import { SignupFormWrapper } from '../../../Components/SignupFormWrapper'
import { SignupSections, SignupStep } from '../../../Components/SignupStep'

import styles from '../PersonalDetailsPage.module.scss'

interface PersonalDetailsStep4FormProps {
  step: number

  values?: Partial<SignupFormValues>
  error?: string
  goBack(): void
  totalSteps: number
}

export const LeadCreationPage: React.FC<PersonalDetailsStep4FormProps> = (props) => {
  const { step, totalSteps, error, goBack } = props
  const { signupData } = useSignup()
  const { t } = useTranslation()

  const apiClient = useApiClient(ClientApiClient)
  const setToast = useContext(ToastContext)

  const handleResend = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    event.stopPropagation()
    if (signupData.leadResponse?.leadId) {
      await apiClient.resendCreateLeadEmail(signupData.leadResponse.leadId)
      setToast(successToast(t('Email was re-sent')))
    } else if (signupData.values?.email) {
      await apiClient.resendCreateLeadEmailByEmail(signupData.values.email)
      setToast(successToast(t('Email was re-sent')))
    }
  }

  if (error) {
    if (isInvalidLeadError(error)) {
      return <InvalidLeadError error={error} goBack={goBack} />
    }

    if (isCannotCreateLeadError(error)) {
      return <CannotCreateLeadError error={error} onResend={handleResend} goBack={goBack} />
    }

    return <LeadError goBack={goBack} />
  }

  return <SuccessPage totalSteps={totalSteps} step={step} error={error} onResend={handleResend} />
}

interface SuccessPageProps {
  step: number
  error?: string
  totalSteps: number
  onResend(event: React.MouseEvent<HTMLButtonElement>): void
}

const SuccessPage: React.FC<SuccessPageProps> = (props) => {
  const { step, totalSteps, onResend } = props
  const { signupData } = useSignup()

  const { t } = useTranslation()

  return (
    <SignupFormWrapper label={t('Sign up.Sign up')} shouldHideBackArrow>
      <SignupStep
        subStep={{
          current: step,
          total: totalSteps,
        }}
        label={t('Sign up.Confirm Registration')}
        section={SignupSections.PersonalDetails}
        accountTypeId={AccountType.Individual}
      />
      <div id='lead-created-page' className={styles.confirmationWrapper}>
        <TextH1>{t('Sign up.Verify your Email')}!</TextH1>
        <span className={styles.verification}>
          {t('Sign up.Please check your inbox', {
            email: signupData.values?.email,
          })}
          {
            <span className={styles.textStrong}>
              {t('Sign up.click the verification link to continue')}
            </span>
          }{' '}
          {t('Sign up.Your verification link is active for the next 48 hours')}.
        </span>
        <span className={styles.buttonWrapper}>
          {t(`Sign up.Didn't get the link`)}? {t('Sign up.Make sure to check your spam folder or')}
          <button type='button' className={styles.plainButton} onClick={onResend}>
            {t('Sign up.Resend')}
          </button>
        </span>
      </div>
    </SignupFormWrapper>
  )
}

interface InvalidLeadErrorProps {
  error: string
  goBack(): void
}

const InvalidLeadError: React.FC<InvalidLeadErrorProps> = (props) => {
  const { error, goBack } = props

  const { t } = useTranslation()

  const { supportEmail } = useSupportContacts()

  return (
    <React.Fragment>
      <SignupErrorStep goBack={goBack} />
      <div className={styles.errorWrapper}>
        <TextH1>{t('Sign up.Error!')}</TextH1>
        <span className={styles.errorContent}>
          {error === 'missing_gender_id' && <Text>{t('Sign up.Gender was not specified.')}</Text>}
          {error === 'invalid_gender' && <Text>{t('Sign up.Invalid gender.')}</Text>}
          {error === 'name_contains_illegal_characters' && (
            <Text>{t('Sign up.Please use latin characters only for name.')}</Text>
          )}
          {error === 'invalid_first_name' && (
            <Text>{t('Sign up.First name contains unsupported characters.')}</Text>
          )}
          {error === 'invalid_last_name' && (
            <Text>{t('Sign up.Last name contains unsupported characters.')}</Text>
          )}
          {error === 'invalid_birth_day' && (
            <Text>{t('Sign up.Selected birthday is invalid.')}</Text>
          )}
          {error === 'invalid_email' && <Text>{t('Sign up.Email provided is invalid.')}</Text>}
          {error === 'invalid_lead_type' && (
            <Text>{t('Sign up.Account type selected is invalid.')}</Text>
          )}
          {error === 'invalid_phone_number_length' && (
            <Text>{t('Sign up.Phone number needs to be 5 or more digits.')}</Text>
          )}
          {error === 'invalid_country' && (
            <Text>{t('Sign up.This country is not available for this Tickmill entity.')}</Text>
          )}
          {error === 'invalid_language' && (
            <Text>{t('Sign up.Language selected is invalid.')}</Text>
          )}
          {error === 'invalid_user_status_aptest_limit' && (
            <Text>
              {t(
                'Sign up.Maximum attempts reached. You will be able to re-take the appropriateness test in 1 year. Please contact {{email}} for further assistance.',
                {
                  email: supportEmail,
                }
              )}
            </Text>
          )}
          {error === 'invalid_user_status_vulnerable_client' && (
            <Text>
              {t(
                'Sign up.From the information you have provided, we do not think this product is appropriate for you at this time. If you would like to discuss further, please contact {{email}}.',
                {
                  email: supportEmail,
                }
              )}
            </Text>
          )}
          {error === 'address_street_client_contains_illegal_characters' && (
            <Text>{t('Sign up.Address street contains unsupported characters')}</Text>
          )}
          {error === 'address_city_client_contains_illegal_characters' && (
            <Text>{t('Sign up.Address city contains unsupported characters')}</Text>
          )}
          {error === 'address_county_client_contains_illegal_characters' && (
            <Text>{t('Sign up.Address state contains unsupported characters')}</Text>
          )}
          {error === 'address_postcode_client_contains_illegal_characters' && (
            <Text>{t('Sign up.Address postcode contains unsupported characters')}</Text>
          )}
          {error === 'address_street_company_contains_illegal_characters' && (
            <Text>{t('Sign up.Company address street contains unsupported characters')}</Text>
          )}
          {error === 'address_city_company_contains_illegal_characters' && (
            <Text>{t('Sign up.Company address city contains unsupported characters')}</Text>
          )}
          {error === 'address_county_company_contains_illegal_characters' && (
            <Text>{t('Sign up.Company address state contains unsupported characters')}</Text>
          )}
          {error === 'address_postcode_company_contains_illegal_characters' && (
            <Text>{t('Sign up.Company address postcode contains unsupported characters')}</Text>
          )}
        </span>
        <span className={styles.goBackWrapper}>
          {' '}
          {t('Sign up.Go back to')}{' '}
          <Link to={'/login'}>
            <button type='button' className={styles.errorLink}>
              {t('Sign up.Login Page')}
            </button>
          </Link>
        </span>
      </div>
    </React.Fragment>
  )
}

interface CannotCreateLeadErrorProps {
  error: string
  onResend(event: React.MouseEvent<HTMLButtonElement>): void
  goBack(): void
}

const CannotCreateLeadError: React.FC<CannotCreateLeadErrorProps> = (props) => {
  const { error, onResend, goBack } = props

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <SignupErrorStep goBack={goBack} />
      <div className={styles.errorWrapper}>
        <TextH1>{t('Sign up.Error!')}</TextH1>
        <span className={styles.errorContent}>
          {error === 'already_awaiting_lead_verification' && (
            <Text>
              {t(
                'Sign up.Verification email has already been sent to you, please verify your email before proceeding.'
              )}
            </Text>
          )}
          {error === 'lead_already_verified' && (
            <Trans
              i18nkey={t(
                'Sign up.You have started the registration process earlier. To continue registration please press'
              )}
            >
              <Text>
                {t(
                  'Sign up.You have started the registration process earlier. To continue registration please press'
                )}
              </Text>
              <Link to='#'>
                <button type='button' className={styles.plainButton} onClick={onResend}>
                  {t('Sign up.Resend')} {t('Sign up.email')}
                </button>
              </Link>
              .
            </Trans>
          )}
          {error === 'already_started_another_lead_verification_process' && (
            <Trans
              i18nkey={t(
                'Sign up.You have started the registration process earlier. To continue registration please press'
              )}
            >
              <Text>
                {t(
                  'Sign up.You have started the registration process earlier. To continue registration please press'
                )}
              </Text>
              <Link to='#'>
                <button type='button' className={styles.plainButton} onClick={onResend}>
                  {t('Sign up.Resend')} {t('Sign up.email')}
                </button>
              </Link>
              .
            </Trans>
          )}
          {error === 'lead_already_converted' && (
            <Text>{t('Sign up.We already have a record with this e-mail account.')}</Text>
          )}
          {error === 'lead_already_exists' && (
            <Text>
              {t(
                'Sign up.We already have a record with this e-mail account. Please verify your e-mail.'
              )}
            </Text>
          )}
        </span>
        <span className={styles.goBackWrapper}>
          {' '}
          {t('Sign up.Go back to')}{' '}
          <Link to={'/login'}>
            <button type='button' className={styles.errorLink}>
              {t('Sign up.Login Page')}
            </button>
          </Link>
        </span>
      </div>
    </React.Fragment>
  )
}

interface LeadErrorProps {
  goBack(): void
}

const LeadError: React.FC<LeadErrorProps> = (props) => {
  const { goBack } = props

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <SignupErrorStep goBack={goBack} />
      <div className={styles.errorWrapper}>
        <TextH1>{t('Sign up.Error!')}</TextH1>
        <Text className={styles.errorContent}>
          {t('Sign up.Something went wrong, please contact support.')}
        </Text>
        <span className={styles.goBackWrapper}>
          {' '}
          {t('Sign up.Go back to')}{' '}
          <Link to={'/login'}>
            <button type='button' className={styles.errorLink}>
              {t('Sign up.Login Page')}
            </button>
          </Link>
        </span>
      </div>
    </React.Fragment>
  )
}
