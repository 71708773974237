import styles from './Icons.module.scss'

export function CompletedStepIcon(props: { size?: number }) {
  const size = props.size || 32

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z'
        fill={styles.white}
      />
      <path
        d='M12.9999 20.1996L9.4999 16.6996C9.1133 16.313 8.4865 16.313 8.0999 16.6996C7.7133 17.0862 7.7133 17.713 8.0999 18.0996L12.9999 22.9996L24.2999 11.6996C24.6865 11.313 24.6865 10.6862 24.2999 10.2996C23.9133 9.91301 23.2865 9.91301 22.8999 10.2996L12.9999 20.1996Z'
        fill={styles.success}
      />
      <path
        d='M16 31C7.71573 31 1 24.2843 1 16H-1C-1 25.3888 6.61116 33 16 33V31ZM31 16C31 24.2843 24.2843 31 16 31V33C25.3888 33 33 25.3888 33 16H31ZM16 1C24.2843 1 31 7.71573 31 16H33C33 6.61116 25.3888 -1 16 -1V1ZM16 -1C6.61116 -1 -1 6.61116 -1 16H1C1 7.71573 7.71573 1 16 1V-1Z'
        fill={styles.success}
      />
    </svg>
  )
}
