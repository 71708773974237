import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import {
  GettingStartedTransferFundsIcon,
  GettingStartedTransferFundsMobileIcon,
} from '../../icons/GettingStartedDepositFundsIcon'
import {
  GettingStartedDepositIcon,
  GettingStartedDepositMobileIcon,
} from '../../icons/GettingStartedDepositIcon'
import {
  GettingStartedEmailIcon,
  GettingStartedEmailMobileIcon,
} from '../../icons/GettingStartedEmailIcon'
import {
  GettingStartedSubscribeMarketDataIcon,
  GettingStartedSubscribeMarketDataMobileIcon,
} from '../../icons/GettingStartedSubscribeMarketDataIcon'
import {
  GettingStartedSubscribePlatformIcon,
  GettingStartedSubscribePlatformMobileIcon,
} from '../../icons/GettingStartedSubscribePlatformIcon'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useWindowResize } from '../../utils/domUtils'

import styles from './GettingStartedETD.module.scss'

export interface AccordionProps {
  title: string
  subtitle?: string
  text?: string
  path?: string
  icon: React.ReactNode
  buttonText?: string
}

const Accordion: React.FC<AccordionProps> = ({ title, subtitle, path, icon, buttonText, text }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={styles.accordion}>
      <div className={styles.titleBox} onClick={() => setIsOpen(!isOpen)}>
        <p className={isOpen ? styles.titleOpen : styles.title}>{title}</p>
        <div className={styles.chevron}>
          <div className={isOpen ? styles.chevronUp : styles.chevronDown}>
            <ChevronDownIcon size={24} />
          </div>
        </div>
      </div>
      <div
        className={classNames(styles.content, {
          [styles.open]: isOpen,
        })}
      >
        <div className={styles.contentWrapper}>
          <div className={classNames('column', styles.leftSection)}>
            <div className='column is-hidden-desktop is-hidden-tablet'>{icon}</div>
            <p className={styles.subtitle}>{subtitle}</p>
            <p className={styles.text}>{text}</p>
            {buttonText && path && (
              <Link to={path}>
                <Button appearance='primary' size='M'>
                  {buttonText}
                </Button>
              </Link>
            )}
          </div>
          <div className='column is-hidden-mobile'>{icon}</div>
        </div>
      </div>
    </div>
  )
}

export const GettingStartedETDPage = () => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()

  return (
    <>
      <PageHeader title={t('Learning.Getting Started')} />
      <div className={styles.titleSection}>
        <h3>{t('Learning.Quick guidelines')}</h3>
        <p className={styles.text}>
          {t('Learning.Congratulations! You have registered with Tickmill')}
        </p>
      </div>
      <Accordion
        title={t('Learning.Fund your Tickmill Wallet')}
        subtitle={t('Fund your Tickmill Wallet')}
        text={t('Min. 1,000 USD or equivalent in GBP, EUR, HKD, SGD and CHF')}
        icon={isMobile ? <GettingStartedDepositMobileIcon /> : <GettingStartedDepositIcon />}
        buttonText={t('Learning.Start Deposit')}
        path={'/dashboard/traders-room/wallets/deposit'}
      />
      <Accordion
        title={t('Learning.Transfer funds from Wallet to Trading Account step')}
        subtitle={
          isMobile
            ? t(
                'Learning.Please note that the payment for the required trading platform and market'
              )
            : ''
        }
        text={
          isMobile
            ? ''
            : t(
                'Learning.Please note that the payment for the required trading platform and market'
              )
        }
        icon={
          isMobile ? <GettingStartedTransferFundsMobileIcon /> : <GettingStartedTransferFundsIcon />
        }
        buttonText={t('Transactions.Start Transfer')}
        path={'/dashboard/traders-room/wallets/transfer'}
      />
      <Accordion
        title={t('Learning.Subscribe to Trading Platform title')}
        subtitle={t('Learning.Subscribe to Trading Platform')}
        icon={
          isMobile ? (
            <GettingStartedSubscribePlatformMobileIcon />
          ) : (
            <GettingStartedSubscribePlatformIcon />
          )
        }
        buttonText={t('Learning.Subscribe')}
        path={'/dashboard/subscriptions/trading-platforms'}
      />
      <Accordion
        title={t('Learning.Subscribe to Market Data title')}
        subtitle={t('Learning.Subscribe to Market Data')}
        icon={
          isMobile ? (
            <GettingStartedSubscribeMarketDataMobileIcon />
          ) : (
            <GettingStartedSubscribeMarketDataIcon />
          )
        }
        buttonText={t('Learning.Subscribe')}
        path={'/dashboard/subscriptions/market-data'}
      />
      <Accordion
        title={t(
          'Learning.Check your email for instructions on how to connect to a third party platform title'
        )}
        subtitle={
          isMobile
            ? ''
            : t(
                'Learning.Check your email for instructions on how to connect to a third party platform'
              )
        }
        icon={isMobile ? <GettingStartedEmailMobileIcon /> : <GettingStartedEmailIcon />}
      />
    </>
  )
}
