import React from 'react'
import { useTranslation } from 'react-i18next'

import { CancelActionModal } from '../global/modal/CancleActionModal'
import { Modal } from '../global/modal/Modal'

interface Props {
  onConfirm(): void
  onCancel(): void
}

export const UnsubscribeAttentionConfirmationModal: React.FC<Props> = (props) => {
  const { onConfirm, onCancel } = props
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={onCancel}
      render={({ closeModal }) => (
        <CancelActionModal
          title={t('Subscriptions.Attention')}
          onCancel={closeModal}
          cancelLabel={t('Subscriptions.Keep subscription')}
          confirmLabel={t('Subscriptions.Yes, unsubscribe')}
          onConfirm={onConfirm}
        >
          <p className='text'>{t('Subscriptions.unsubscribe attention position 1')}</p>
          <p className='text mt-4'>{t('Subscriptions.unsubscribe attention position 2')}</p>
        </CancelActionModal>
      )}
    />
  )
}
