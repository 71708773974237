import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMPartnersLogo: FC = () => (
  <svg width='160' height='40' viewBox='0 0 160 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_83719_353139)'>
      <path
        d='M70.7879 21.9512C72.7758 21.9512 74.4243 21.1754 75.6849 19.5754L78.3031 22.2421C76.2182 24.5694 73.794 25.733 71.0303 25.733C68.2182 25.733 65.9394 24.8603 64.097 23.0664C62.3031 21.3209 61.3818 19.0421 61.3818 16.3754C61.3818 13.6603 62.3031 11.43 64.1455 9.63605C65.9879 7.84211 68.2667 6.9209 70.9334 6.9209C73.9394 6.9209 76.4121 8.03605 78.4485 10.3148L75.9273 13.1754C74.6667 11.5754 73.0667 10.7997 71.1273 10.7997C69.6243 10.7997 68.3152 11.2845 67.2 12.3027C66.1334 13.3209 65.5515 14.63 65.5515 16.327C65.5515 18.0239 66.0849 19.3815 67.1031 20.3997C68.1212 21.4179 69.3334 21.9512 70.7879 21.9512Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M45.8181 25.5391V10.8481H50.9575V7.35724H36.606V10.8481H41.7454V25.5391H45.8181Z'
        fill='var(--logo-text-color)'
      />
      <path d='M57.9878 7.35724H53.915V25.5391H57.9878V7.35724Z' fill='var(--logo-text-color)' />
      <path
        d='M85.5757 7.35724H81.5029V25.5391H85.5757V20.3997L87.4181 18.3633L92.7514 25.5391H97.5029C97.0518 24.9022 96.47 24.0911 95.7575 23.0978L95.7517 23.0897C95.1637 22.2699 94.4868 21.3262 93.7211 20.2542C92.2209 18.0922 91.1223 16.5726 90.4617 15.6589L90.2787 15.4057L97.5029 7.35724H92.4605L85.5757 14.8239V7.35724Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M116.266 14.0966L111.37 24.036H108.945L104.048 14.0966V25.5391H99.9756V7.35724H105.454L110.157 17.3451L114.86 7.35724H120.339V25.5391H116.266V14.0966Z'
        fill='var(--logo-text-color)'
      />
      <path d='M128.873 7.35724H124.8V25.5391H128.873V7.35724Z' fill='var(--logo-text-color)' />
      <path
        d='M133.333 25.5391V7.35724H137.406V21.9027H145.163V25.5391H133.333Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M148.121 7.35724V25.5391H159.951V21.9027H152.194V7.35724H148.121Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M7.70909 12.0121C7.41818 12.2061 7.17576 12.6424 7.17576 12.9818H7.27273V18.9939L0 23.1636V9.58788C0 8.71515 0.630303 7.64849 1.35758 7.21212L12.9939 0.327273C13.7212 -0.109091 14.9333 -0.109091 15.6606 0.327273L27.297 7.21212C27.3455 7.21212 27.3939 7.26061 27.4424 7.30909L20.0727 11.5758L14.8364 8.52121C14.497 8.32727 14.0121 8.32727 13.7212 8.52121L7.70909 12.0121Z'
        fill='var(--logo-custom-color)'
      />
      <path
        d='M21.3336 19.9152V13.9031L28.5093 9.7334V23.3092C28.5093 24.1819 27.879 25.2486 27.1517 25.6849L15.5154 32.5698C14.7881 33.0061 13.576 33.0061 12.8487 32.5698L1.21235 25.6849C1.16387 25.6849 1.11539 25.6364 1.0669 25.588L8.43659 21.3213L13.673 24.3758C14.0124 24.5698 14.4972 24.5698 14.7881 24.3758L20.8002 20.8849C21.0911 20.691 21.3336 20.2546 21.3336 19.9152Z'
        fill='var(--logo-custom-color)'
      />
      <path
        d='M106.7 32.08C107.573 32.08 108.247 32.2867 108.72 32.7C109.2 33.1133 109.44 33.6933 109.44 34.44C109.44 35.22 109.2 35.8267 108.72 36.26C108.24 36.6867 107.567 36.9 106.7 36.9H104.96V39.08H104V32.08H106.7ZM106.66 36.03C107.26 36.03 107.72 35.9 108.04 35.64C108.36 35.3733 108.52 34.9833 108.52 34.47C108.52 33.97 108.36 33.5933 108.04 33.34C107.72 33.08 107.26 32.95 106.66 32.95L104.96 32.96V36.03H106.66Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M115.899 39.08L115.159 37.38H111.469L110.739 39.08H109.729L112.839 32.08H113.839L116.939 39.08H115.899ZM111.849 36.51H114.789L113.309 33.11L111.849 36.51Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M122.875 39.08L121.485 36.88C121.325 36.8933 121.195 36.9 121.095 36.9H119.315V39.08H118.355V32.08H121.095C121.981 32.08 122.665 32.2867 123.145 32.7C123.631 33.1067 123.875 33.6867 123.875 34.44C123.875 35.0067 123.741 35.4833 123.475 35.87C123.215 36.2567 122.838 36.5367 122.345 36.71L123.975 39.08H122.875ZM121.095 36.03C121.695 36.03 122.155 35.9 122.475 35.64C122.795 35.3733 122.955 34.9833 122.955 34.47C122.955 33.97 122.795 33.5933 122.475 33.34C122.155 33.08 121.695 32.95 121.095 32.95H119.315V36.03H121.095Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M125.081 32.08H130.551V32.96H128.291V39.08H127.331V32.96H125.081V32.08Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M137.035 32.08H137.985V39.08H137.015L133.015 33.66V39.08H132.055V32.08H133.025L137.035 37.51V32.08Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M140.395 32.08H145.255V32.95H141.355V35.11H144.845V35.98H141.355V38.21H145.375V39.08H140.395V32.08Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M151.848 39.08L150.458 36.88C150.298 36.8933 150.168 36.9 150.068 36.9H148.288V39.08H147.328V32.08H150.068C150.954 32.08 151.638 32.2867 152.118 32.7C152.604 33.1067 152.848 33.6867 152.848 34.44C152.848 35.0067 152.714 35.4833 152.448 35.87C152.188 36.2567 151.811 36.5367 151.318 36.71L152.948 39.08H151.848ZM150.068 36.03C150.668 36.03 151.128 35.9 151.448 35.64C151.768 35.3733 151.928 34.9833 151.928 34.47C151.928 33.97 151.768 33.5933 151.448 33.34C151.128 33.08 150.668 32.95 150.068 32.95H148.288V36.03H150.068Z'
        fill='var(--logo-text-color)'
      />
      <path
        d='M159.044 33.54C158.718 33.3333 158.378 33.18 158.024 33.08C157.678 32.9733 157.358 32.92 157.064 32.92C156.658 32.92 156.331 33 156.084 33.16C155.844 33.3133 155.724 33.5267 155.724 33.8C155.724 34.0467 155.798 34.25 155.944 34.41C156.091 34.5633 156.271 34.6867 156.484 34.78C156.698 34.8667 156.994 34.97 157.374 35.09C157.848 35.2367 158.231 35.3767 158.524 35.51C158.818 35.6433 159.068 35.8433 159.274 36.11C159.481 36.37 159.584 36.7167 159.584 37.15C159.584 37.55 159.474 37.9 159.254 38.2C159.041 38.5 158.738 38.7333 158.344 38.9C157.958 39.06 157.511 39.14 157.004 39.14C156.491 39.14 155.991 39.04 155.504 38.84C155.018 38.64 154.598 38.3733 154.244 38.04L154.674 37.21C155.008 37.5367 155.381 37.79 155.794 37.97C156.214 38.1433 156.621 38.23 157.014 38.23C157.481 38.23 157.848 38.14 158.114 37.96C158.388 37.7733 158.524 37.5233 158.524 37.21C158.524 36.9633 158.451 36.76 158.304 36.6C158.158 36.44 157.971 36.3133 157.744 36.22C157.524 36.1267 157.224 36.0233 156.844 35.91C156.378 35.77 155.998 35.6333 155.704 35.5C155.411 35.3667 155.161 35.17 154.954 34.91C154.754 34.65 154.654 34.3067 154.654 33.88C154.654 33.5067 154.758 33.1767 154.964 32.89C155.171 32.6033 155.461 32.3833 155.834 32.23C156.208 32.0767 156.641 32 157.134 32C157.554 32 157.964 32.0633 158.364 32.19C158.771 32.31 159.131 32.4733 159.444 32.68L159.044 33.54Z'
        fill='var(--logo-text-color)'
      />
    </g>
    <defs>
      <clipPath id='clip0_83719_353139'>
        <rect width='160' height='40' fill='white' />
      </clipPath>
    </defs>
  </svg>
)
