import { useMemo } from 'react'

import { IBTestSubCategory } from '../model/TestSectionsDto'
import { useAccountReadContext } from './AccountContextContext'

export const useIBPrograms = (): {
  defaultProgram: boolean
  multiTierProgram: boolean
  defaultSubCategory?: IBTestSubCategory
} => {
  const { account } = useAccountReadContext()

  const programs = useMemo(
    () => ({
      defaultProgram: account?.introducingBrokerRegistrationAllowed ?? false,
      multiTierProgram: account?.multiTierRegistrationAllowed ?? false,
    }),
    [account]
  )

  const defaultSubCategory =
    programs.defaultProgram && !programs.multiTierProgram
      ? IBTestSubCategory.Default
      : programs.multiTierProgram && !programs.defaultProgram
      ? IBTestSubCategory.MultiTier
      : undefined

  return {
    ...programs,
    defaultSubCategory,
  }
}
