import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export const useCurrentTab = (parentPath: string): string => {
  const location = useLocation()
  const path = location.pathname.split('/')

  return useMemo(() => {
    const parentIndex = path.indexOf(parentPath)
    return path[parentIndex + 1]
  }, [parentPath, path])
}
