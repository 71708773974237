import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { UAParser } from 'ua-parser-js'

import { BrowserOutdatedModal } from '../global/browserOutdated/BrowserOutdateModal'
import { useModalsContext } from '../global/context/ModalsContext'
import { useProductReadContext, useProductWriteContext } from '../global/context/ProductContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { CancelActionModal } from '../global/modal/CancleActionModal'
import { ConfirmationModal } from '../global/modal/ConfirmationModal'
import { Modal } from '../global/modal/Modal'
import { ProductContextSwitchModal } from '../global/modal/ProductContextSwitchModal'
import { CoolOffPeriodModal } from '../global/nav/CoolOffPeriodModal'
import { RegisterProductModal } from '../global/nav/RegisterProductModal'
import { TickmillProductType } from '../model/TickmillProductType'
import { useProductSwitchClick } from '../ui/ProductSwitch/ProductSwitch'
import { Text } from '../ui/Typography/Typography'
import { useAccountWriteContext } from '../utils/AccountContextContext'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { compareBrowserVersions } from '../utils/compare.utils'
import { isLoginPage as checkIfLoginPage } from '../utils/isLoginPage'
import { outDatedBrowserRoutes, useProductSwitchPath } from '../utils/path'
import { useLocallyPersistedState } from '../utils/useStorage'

export enum GlobalModalsType {
  ProductSwitch = 100,
  ClientCooolOff,
  RegisterProduct,
  CancelAction,
  CancelSwitch,
  ProductInfo,
  BrowserOutdated,
  DeleteNotification,
}

export const GlobalModals: React.FC = () => {
  const productSwitchClick = useProductSwitchClick()
  const { activeModal, setActiveModal, closeModal, modalData } = useModalsContext()
  const { changeProductContext } = useProductWriteContext()
  const { isDefaultCFDProductType } = useProductReadContext()
  const apiClient = useApiClient(ClientApiClient)
  const isProductTypeCFD = isDefaultCFDProductType()
  const { refreshAccount } = useAccountWriteContext()
  const locale = useSessionLanguage()
  const { t } = useTranslation()
  const location = useLocation()
  const isLoginPage = checkIfLoginPage(location.pathname)
  const { navigateToNextPath } = useProductSwitchPath()

  const [browserOutdatedDisplay, setBrowserOutdatedDisplay] = useLocallyPersistedState(
    'isBrowserOutdatedModalDisplayed',
    false
  )
  const handleProductSwitch = () => {
    const nextProduct = changeProductContext()
    navigateToNextPath(nextProduct)
  }

  const productSwitchConfirm = (v: boolean | undefined) => {
    handleProductSwitch()
    closeModal()
  }

  const registerProduct = async () => {
    const productType = isProductTypeCFD ? TickmillProductType.ETD : TickmillProductType.CFD
    await apiClient.addTickmillProduct(productType)
    closeModal()
    refreshAccount(locale)
    handleProductSwitch()
  }

  useEffect(() => {
    if (browserOutdatedDisplay) {
      return
    }
    const isPageToBeVerified = () => outDatedBrowserRoutes?.includes(location?.pathname)
    if (isLoginPage || !isPageToBeVerified()) {
      return
    }

    const parser = new UAParser()
    const { browser } = parser.getResult()

    if (!(browser.name === 'Safari' || browser.name === 'Mobile Safari')) {
      return
    }
    if (!(compareBrowserVersions(browser.version as string, '15.3') <= 0)) {
      return
    }

    setActiveModal(GlobalModalsType.BrowserOutdated)
    setBrowserOutdatedDisplay(true)
  }, [
    isLoginPage,
    browserOutdatedDisplay,
    setActiveModal,
    setBrowserOutdatedDisplay,
    location?.pathname,
  ])

  switch (activeModal) {
    case GlobalModalsType.ProductSwitch:
      return (
        <Modal
          closeModal={closeModal}
          render={() => (
            <ProductContextSwitchModal onConfirm={productSwitchConfirm} onCancel={closeModal} />
          )}
        />
      )
    case GlobalModalsType.ClientCooolOff:
      return <CoolOffPeriodModal onCloseModal={closeModal} />
    case GlobalModalsType.RegisterProduct:
      return (
        <RegisterProductModal
          onClose={closeModal}
          onCancel={() => setActiveModal(GlobalModalsType.CancelSwitch)}
          onConfirm={registerProduct}
        />
      )
    case GlobalModalsType.CancelAction:
      return (
        <Modal
          closeModal={closeModal}
          render={() => (
            <CancelActionModal
              onConfirm={() => {
                closeModal()
                productSwitchClick()
              }}
              onCancel={closeModal}
            />
          )}
        />
      )
    case GlobalModalsType.CancelSwitch:
      return (
        <Modal
          closeModal={closeModal}
          render={() => (
            <CancelActionModal
              onConfirm={closeModal}
              onCancel={() => setActiveModal(GlobalModalsType.ProductSwitch)}
            />
          )}
        />
      )
    case GlobalModalsType.DeleteNotification:
      return (
        <Modal
          closeModal={closeModal}
          render={() => (
            <ConfirmationModal
              title={t('Notifications.Delete Notification')}
              renderFooter={() => (
                <React.Fragment>
                  <button className='button' onClick={closeModal} type='button'>
                    {t('No')}
                  </button>
                  <button className='button' onClick={modalData.onConfirm} type='button'>
                    {t('Yes')}
                  </button>
                </React.Fragment>
              )}
              onCancel={closeModal}
              onConfirm={modalData.onConfirm}
            >
              <Text>{t('Notifications.This notification will be permanently deleted')}</Text>
            </ConfirmationModal>
          )}
        />
      )
    case GlobalModalsType.BrowserOutdated:
      return <BrowserOutdatedModal />
  }

  return null
}
