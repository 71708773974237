import {
  ChangeEventHandler,
  FormEventHandler,
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { SearchIcon } from '../../icons/SearchIcon'
import { useAutoselect } from '../../utils/autoSelect'

import styles from './ThrottleSearchInput.module.scss'

export interface SearchInputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  onSearch?: (value?: string) => void
  onRequestAutocomplete?: (value: string) => void
  initialValue?: string
  throttle: number
}

export function ThrottleSearchInput(props: SearchInputProps) {
  const { onSearch, onRequestAutocomplete, initialValue, throttle, ...restProps } = props
  const [value, setValue] = useState<string>(initialValue || '')
  const ref = useAutoselect<HTMLInputElement>(!initialValue)

  const onChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const timer = setTimeout(() => setValue(e.target.value), throttle)
      return () => clearTimeout(timer)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  )

  const onSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault()
      onSearch?.(ref.current?.value)
    },
    [onSearch] /* "ref" is not a dependency */ // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(() => {
    if (onRequestAutocomplete) {
      const timer = setTimeout(() => onRequestAutocomplete(value), 500)
      return () => clearTimeout(timer)
    }
  }, [value, onRequestAutocomplete])

  return (
    <form onSubmit={onSubmit} className={styles.searchInput}>
      <span className={styles.icon}>
        <SearchIcon />
      </span>
      <input ref={ref} value={value} onChange={onChange} {...restProps} />
    </form>
  )
}
