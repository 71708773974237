import { LegalDocumentDto } from '../model/CompanyLegalDocumentDto'

export const filterDocumentsByClient = (document: LegalDocumentDto): boolean => {
  return document.step === 'client'
}

export const filterDuplicateDocuments = (
  value: LegalDocumentDto,
  index: number,
  self: LegalDocumentDto[]
): boolean => index === self.findIndex((t) => t.id === value.id)
