import React from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentManagementNonNCIPage } from './DocumentManagementNonNCI/DocumentManagementNonNCIPage'

export const DocumentManagementCompanyPage: React.FC = () => {
  const { t } = useTranslation()

  return <DocumentManagementNonNCIPage documentTitle={t('Profile.Company Identification')} />
}
