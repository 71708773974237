export enum DocumentPropertyType {
  DateOfIssue = 1,
  DateOfExpiry = 2,
  DateOfBirth = 3,
  DocumentNumber = 4,
  LastName = 5,
  FirstName = 6,
  FullName = 7,
  PlaceOfBirth = 8,
  Issuer = 9,
  Nationality = 10,
  Gender = 11,
  DocumentNumber2 = 12,
  CountryCodeAlpha3 = 13,
  PersonalIdNumber = 14,
  DayOfExpiry = 15,
  DayOfIssue = 16,
  MachineReadableZone1 = 17,
  MachineReadableZone2 = 18,
  MachineReadableZone3 = 19,
  ClientTestId = 20,
  TestQuestionId = 21,
  TicketId = 22,
}
