import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import IconButton from '../../global/iconButton/IconButton'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { InfoIcon } from '../../icons/InfoIcon'
import { ClientRebateCampaignDto } from '../../model/CampaignResultDto'
import { ClientCampaignRebateResultDto } from '../../model/ClientCampaignRebateResultDto'
import {
  ClientRebateCampaignDetailDto,
  isCampaignCalculationAccount,
  isCampaignClientRebate,
} from '../../model/ClientRebateCampaignDetailDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { getScrollToCardId } from '../../utils/getItemId'

import styles from './CampaignRebateCard.module.scss'

interface CampaignRebateCardsProps extends Omit<CampaignRebateCardProps, 'data' | 'item'> {
  data: ClientCampaignRebateResultDto[]
  campaignDetails?: ClientRebateCampaignDetailDto
}

export const CampaignRebateCards: React.FC<CampaignRebateCardsProps> = (props) => {
  const { data, campaign, campaignDetails, currentRound, campaignRebateValue, setInfoModal } = props

  const { t } = useTranslation()

  if (!data.length) {
    return (
      <div className={classNames(styles.text, 'text-align-center pt-4')}>{t('No results')}</div>
    )
  }

  return (
    <React.Fragment>
      {data.map((x, index) => (
        <CampaignRebateCard
          item={x}
          campaign={campaign}
          campaignDetails={campaignDetails}
          currentRound={currentRound}
          campaignRebateValue={campaignRebateValue}
          setInfoModal={setInfoModal}
          key={`campaignRebateCard_${index}`}
          cardId={getScrollToCardId(index, data.length, 'campaign-total-rebate')}
        />
      ))}
    </React.Fragment>
  )
}

interface CampaignRebateCardProps {
  campaign: ClientRebateCampaignDto
  campaignDetails?: ClientRebateCampaignDetailDto

  item: ClientCampaignRebateResultDto
  currentRound?: Date | null
  campaignRebateValue: string
  setInfoModal(v: boolean): void
  cardId?: string
}

const CampaignRebateCard: React.FC<CampaignRebateCardProps> = (props) => {
  const {
    item,
    currentRound,
    campaign,
    campaignDetails,
    campaignRebateValue,
    setInfoModal,
    cardId,
  } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const [auth] = useContext(AuthSessionContext)
  const { scrollIntoView } = useScrollToElementIds()

  const dateFormat = auth?.dateFormatType?.name

  const [expanded, setExpanded] = useState(false)

  return (
    <div
      className={styles.card}
      onClick={() => {
        if (!expanded && cardId) {
          scrollIntoView([cardId])
        }
        setExpanded((prevState) => !prevState)
      }}
      id={cardId}
    >
      <div className='is-flex is-justify-content-space-between'>
        <span className='has-text-weight-bold'>{t('Campaigns.Total Volume Traded')}</span>
        <span className='has-text-weight-bold'>
          {item.lots} {t('Campaigns.Lots')}
        </span>
      </div>

      <div className={styles.subtitle}>
        <span className='text-secondary'>{t('Campaigns.Round Date')}</span>
        <span className='text-primary'>
          {new Date(item.round).getTime() === currentRound?.getTime()
            ? 'Current Round'
            : formatDate(item.round, { formatType: dateFormat })}
        </span>
      </div>
      {expanded && (
        <React.Fragment>
          {hasRebateValueColumn(campaignDetails) && (
            <div className={styles.subtitle}>
              <span className='text-secondary'>{campaignRebateValue}</span>
              <span className='text-primary'>{item.rebateValue || '0'}</span>
            </div>
          )}

          <div className={styles.subtitle}>
            <span className='text-secondary'>{t('Campaigns.Accumulated Rebate')}</span>
            <span className='text-primary is-flex'>
              <span>{formatMoney(item.rebate, campaign.currency.id)}</span>
              {item.rebate > 0 && item.rebate < 1 && (
                <span className='ml-1 is-flex is-align-items-center'>
                  <IconButton
                    className={classNames('is-clickable', styles.infoIcon)}
                    onClick={() => setInfoModal(true)}
                  >
                    <InfoIcon />
                  </IconButton>
                </span>
              )}
            </span>
          </div>
        </React.Fragment>
      )}
    </div>
  )
}

const hasRebateValueColumn = (campaignDetails?: ClientRebateCampaignDetailDto) => {
  return isCampaignClientRebate(campaignDetails) && !isCampaignCalculationAccount(campaignDetails)
}
