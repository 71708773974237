import React from 'react'
import { useTranslation } from 'react-i18next'

import { CardProceedModal } from './CardProceedModal'
import { Modal } from './Modal'

import styles from './CardPaymentProviderProceedModal.module.scss'

interface CardPaymentProviderModalProps {
  onProceed(): void

  onClose(): void
}

export const CardPaymentProviderProceedModal: React.FC<CardPaymentProviderModalProps> = ({
  onProceed,
  onClose,
}) => {
  const handleProceed = () => {
    onProceed()
  }

  const { t } = useTranslation()

  return (
    <Modal
      pullDown
      closeModal={onClose}
      cardClassName={styles.modal}
      render={() => (
        <CardProceedModal
          title={t('Deposit of')}
          thirdPartyTransferWarning={t(
            'Please note that third party transfers are not accepted for funding.'
          )}
          cardHolderNameInstruction={t(
            'Please ensure the name you provide matches the one shown on your credit/debit card (card holder name).'
          )}
          onProceed={handleProceed}
        />
      )}
    />
  )
}
