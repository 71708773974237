import { useCallback, useContext, useEffect } from 'react'
import axios, { AxiosResponse } from 'axios'

import { HubSpotSettings } from '../api'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { getClientPrimaryEmail } from './AccountAccess/accountEmailStatuses'
import { useAccountReadContext } from './AccountContextContext'
import { AuthSessionContext } from './AuthContext'
import { useLocallyPersistedState } from './useStorage'

export const setHubSpotSettings = (settings: HubSpotSettings): void => {
  window.hsConversationsSettings = {
    ...settings,
    loadImmediately: false,
  }
}

export const getHubSpotWidgetStatus = (): { loaded: boolean } | undefined => {
  return window.HubSpotConversations?.widget?.status?.()
}

export const loadHubSpotWidget = (): void => {
  window.HubSpotConversations?.widget?.load?.()
}

export const resetHubSpotWidget = (): void => {
  window.HubSpotConversations?.clear?.({ resetWidget: true })
}

export interface HubSpotVisitorTokenParams {
  email: string
  firstName: string
  lastName: string
}

export interface HubSpotVisitorTokenResponse {
  token: string
}

export const getHubSpotVisitorToken = async (
  params: HubSpotVisitorTokenParams
): Promise<HubSpotVisitorTokenResponse> => {
  const response = await axios.post<
    HubSpotVisitorTokenParams,
    AxiosResponse<HubSpotVisitorTokenResponse>
  >('/hubspot/conversations/v3/visitor-identification/tokens/create', params, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${window.env.HUBSPOT_API_KEY}`,
    },
  })

  return response.data
}

export const useHubSpot = (): void => {
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()
  const [auth] = useContext(AuthSessionContext)

  const [hubSpotIdentificationToken, setHubSpotIdentificationToken] = useLocallyPersistedState<
    string | undefined
  >('hubSpotIdentificationToken', undefined)

  const initializeHubSpot = useCallback(async () => {
    if (!account) {
      resetHubSpotWidget()
      return
    }

    const email = getClientPrimaryEmail(account)

    if (!email) {
      return
    }

    let identificationToken = hubSpotIdentificationToken

    if (!identificationToken) {
      try {
        const data = await getHubSpotVisitorToken({
          email: email,
          firstName: account.firstname,
          lastName: account.lastname,
        })

        identificationToken = data.token
        setHubSpotIdentificationToken(identificationToken)
      } catch (error: unknown) {
        console.error('Error fetching identification token:', error)
        return
      }
    }

    setHubSpotSettings({
      identificationEmail: email,
      identificationToken: identificationToken,
    })

    if (!window.HubSpotConversations) {
      window.hsConversationsOnReady = [
        () => {
          loadHubSpotWidget()
        },
      ]
    } else {
      loadHubSpotWidget()
    }
  }, [account, hubSpotIdentificationToken, setHubSpotIdentificationToken])

  useEffect(() => {
    if (!auth?.email) {
      resetHubSpotWidget()
    } else {
      resetHubSpotWidget()
      initializeHubSpot()
    }
  }, [auth?.email, initializeHubSpot, locale])
}
