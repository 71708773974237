import { TickmillCompaniesEnum } from './companyName.utils'

export enum AppENV {
  production = 'production',
  development = 'development',
  test = 'test',
}
export enum AppFeature {
  ibRoom = 'ibRoom',
  w8nHistory = 'w8nHistory',
}

const features: Record<AppFeature, { entities: TickmillCompaniesEnum[]; envs: AppENV[] }> = {
  [AppFeature.ibRoom]: { envs: [AppENV.production], entities: [] },
  [AppFeature.w8nHistory]: {
    envs: [AppENV.production, AppENV.development],
    entities: [TickmillCompaniesEnum.TICKMILL_SC],
  },
}

export function enableByEnv(env: string[]): boolean {
  let isEnabledViaEnv
  isEnabledViaEnv = (env as string[]).includes(window.env.APP_ENVIRONMENT.split('-')[0])
  return !!isEnabledViaEnv
}

export function isFeatureEnabled(
  f: AppFeature,
  env: AppENV,
  entity?: TickmillCompaniesEnum
): boolean {
  const feature = features[f]
  const isEnabledViaEnv = feature.envs.includes(env)
  const isEnabledForEntity = entity ? feature.entities.includes(entity) : false
  return isEnabledForEntity && isEnabledViaEnv
}

export const dateTimeFormat = 'MM/dd/yyyy HH:mm'
export const dateFormat = 'MM/dd/yyyy'
