import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export function CloseIcon(props: IconProps): JSX.Element {
  const { size = 14, className } = props

  return (
    <svg
      width={size}
      className={className}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.3 2.1C13.6866 1.7134 13.6866 1.0866 13.3 0.7C12.9134 0.313401 12.2866 0.313401 11.9 0.7L7 5.6L2.1 0.7C1.7134 0.3134 1.0866 0.313401 0.7 0.7C0.313401 1.0866 0.313401 1.7134 0.7 2.1L5.6 7L0.7 11.9C0.3134 12.2866 0.313401 12.9134 0.7 13.3C1.0866 13.6866 1.7134 13.6866 2.1 13.3L7 8.4L11.9 13.3C12.2866 13.6866 12.9134 13.6866 13.3 13.3C13.6866 12.9134 13.6866 12.2866 13.3 11.9L8.4 7L13.3 2.1Z'
        fill={props.color ? styles[props.color] : iconColor(props)}
      />
    </svg>
  )
}
