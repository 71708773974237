import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { PercentageAmountChip } from '../../../../global/PercentageChip/PercentageAmountChip'
import { Button } from '../../../../global/button/Button'
import { FieldGroup } from '../../../../global/form/FieldGroup'
import {
  FormWarningMessage,
  FormWarningMessageGroup,
} from '../../../../global/form/FormWarningMessage'
import { createFormField } from '../../../../global/formField/FormField'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import { WalletPaymentProviderCurrency } from '../../../../model/WalletPaymentProviderParameters'
import { WalletWithdrawCurrencyModal } from '../WalletWithdrawCurrencyModal'
import { CurrencyAmountField } from './CurrencyAmountField'
import { FormTermsConditionsPSP } from './FormTermsConditionsFactory'
import { WalletFormFieldsFactory } from './WalletFormFieldsFactory'
import { WalletWithdrawFormValues } from './WalletWithdrawForm'

import styles from './WalletWithdrawForm.module.scss'

interface WalletWithdrawPSPFieldsProps {
  showAmountPresets?: boolean
  onCancel(): void
  amountHint?: string
  onClickTermsConditions(): void
}

const FormField = createFormField<WalletWithdrawFormValues>()

export const WalletWithdrawPSPFields: React.FC<WalletWithdrawPSPFieldsProps> = (props) => {
  const { onCancel, onClickTermsConditions, amountHint } = props

  const { t } = useTranslation()

  const { values, setValues, isValid, handleSubmit, setFieldValue, setFieldTouched } =
    useFormikContext<WalletWithdrawFormValues>()

  const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false)

  const handleExitPageConfirmationModalOpen = () => {
    onCancel()
  }

  const handleCurrency = (walletPaymentProviderCurrency: WalletPaymentProviderCurrency) => {
    setValues({
      ...values,
      paymentProvider: {
        ...values.paymentProvider,
        currency: walletPaymentProviderCurrency,
      },
    })
    setCurrencyModalOpen(false)
  }

  const handleCurrencyModalOpen = () => {
    setCurrencyModalOpen(true)
  }

  const handleCurrencyModalClose = () => {
    setCurrencyModalOpen(false)
  }

  const handleSubmitForm = () => {
    handleSubmit()
  }

  const disabled = values.paymentProvider.parameters.currencies.length <= 1

  return (
    <React.Fragment>
      {values.paymentProvider.id && (
        <React.Fragment>
          {isCurrencyModalOpen && (
            <WalletWithdrawCurrencyModal
              onSelectOption={handleCurrency}
              onClose={handleCurrencyModalClose}
            />
          )}
          <FieldGroup>
            <CurrencyAmountField hint={amountHint} />
            <div className={styles.currencyFieldWrapper}>
              <FormField
                name='paymentProvider.currency.id'
                label={t('Wallet.Currency')}
                required
                disabled={disabled}
                rightIcon={disabled ? null : <DropArrowDownIcon />}
                onClick={handleCurrencyModalOpen}
              />
            </div>
          </FieldGroup>
          {props.showAmountPresets && (
            <div className='mb-3'>
              <PercentageAmountChip
                currentAmount={values.amount}
                walletBalance={values.wallet.balance}
                onAmountChange={(newAmount) => {
                  setFieldTouched('amount', true)
                  setFieldValue('amount', newAmount.toFixed(2))
                }}
              />
            </div>
          )}
          <WalletFormFieldsFactory />

          <FormField
            name='comment'
            label={t('Wallet.Additional Comment')}
            placeholder={t('Wallet.Additional Comment')}
          />

          <Note />

          <div className='my-5'>
            <FormTermsConditionsPSP onClickTermsConditions={onClickTermsConditions} />
          </div>

          <TransactionFormActionButtonTemplate>
            <Button
              appearance='secondary'
              size='L'
              type='button'
              onClick={handleExitPageConfirmationModalOpen}
            >
              {t('Cancel')}
            </Button>
            <Button
              appearance='primary'
              size='L'
              type='button'
              onClick={handleSubmitForm}
              disabled={!isValid}
            >
              {t('Confirm')}
            </Button>
          </TransactionFormActionButtonTemplate>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

const Note: React.FC = () => {
  const { values } = useFormikContext<WalletWithdrawFormValues>()

  const { t } = useTranslation()

  return (
    <React.Fragment>
      {values.paymentProvider.method_name === 'SC Alogateway CUP' ? (
        <FormWarningMessageGroup>
          <FormWarningMessage
            message={
              <React.Fragment>
                <span className='has-text-danger pr-1'>{t('Note')}</span> {t('china info')}
              </React.Fragment>
            }
          />
        </FormWarningMessageGroup>
      ) : null}
      {values.paymentProvider.method_name === 'SC Transact365 CNY' ? (
        <FormWarningMessageGroup>
          <FormWarningMessage
            message={
              <React.Fragment>
                <span className='has-text-danger pr-1'>{t('Note')}</span>{' '}
                {t('This payment provider channel is')}
              </React.Fragment>
            }
          />
        </FormWarningMessageGroup>
      ) : null}
    </React.Fragment>
  )
}
