import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { isOne } from '../utils/validations'
import { JourneyStep } from './JourneyStep'

import styles from './JourneyPage.module.scss'

export const JourneyPage: React.FC = () => {
  const [screenNumber, setScreenNumber] = useState(1)
  const navigate = useNavigate()

  const handleNext = () => setScreenNumber(2)
  const handleStartJourney = () => navigate('/dashboard/traders-room')

  return (
    <div className='container'>
      <JourneyStep
        stepNumber={screenNumber}
        nextScreen={handleNext}
        startJourney={handleStartJourney}
      />

      <div className={classNames(styles['wrapper-row'], 'mt-6 pb-6')}>
        <button
          className={classNames(styles.round, 'mr-5 has-cursor-pointer', {
            [styles.active]: isOne(screenNumber),
          })}
          onClick={() => setScreenNumber(1)}
        ></button>
        <button
          className={classNames(styles.round, 'has-cursor-pointer', {
            [styles.active]: screenNumber === 2,
          })}
          onClick={() => setScreenNumber(2)}
        ></button>
      </div>
    </div>
  )
}
