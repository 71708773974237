import { UploadDocumentCategoryEnum } from '../model/UploadDocumentCategoryEnum'
import { UploadDocumentTypeEnum } from '../model/UploadDocumentTypeEnum'

export const isDoubleSidedDocument = (
  categoryId: UploadDocumentCategoryEnum,
  typeId: UploadDocumentTypeEnum
): boolean => {
  return (
    categoryId === UploadDocumentCategoryEnum.Personal &&
    typeId !== UploadDocumentTypeEnum.Birth_Certificate &&
    typeId !== UploadDocumentTypeEnum.Passport
  )
}
