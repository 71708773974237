import React from 'react'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { Badge, BadgeVariantType } from '../../global/badge/Badge'
import { NameDto } from '../../model/NameDto'
import { TransactionStatus } from '../../model/TransactionStatus'

interface TransactionBadgeStatusProps extends NameDto {
  type?: TransactionStatus
}

type TransactionBadgeVariantMapType = Record<
  TransactionStatus,
  'completed' | 'pending' | 'cancelled' | 'failed' | 'declined' | 'reversed' | 'rejected'
>

const TransactionBadgeVariantAppearanceMap: TransactionBadgeVariantMapType = {
  [TransactionStatus.None]: 'rejected',
  [TransactionStatus.AwaitingApproval]: 'pending',
  [TransactionStatus.Pending]: 'pending',
  [TransactionStatus.Completed]: 'completed',
  [TransactionStatus.Cancelled]: 'cancelled',
  [TransactionStatus.Declined]: 'declined',
  [TransactionStatus.Failed]: 'failed',
  [TransactionStatus.Reversed]: 'reversed',
}

const getTransactionBadgeLabel = (
  status: TransactionStatus,
  t: TFunction<'translation', undefined, 'translation'>
): string => {
  if (status === TransactionStatus.None) {
    return t('Pending')
  } else if (status === TransactionStatus.AwaitingApproval) {
    return t('AwaitingApproval')
  } else if (status === TransactionStatus.Pending) {
    return t('Pending')
  } else if (status === TransactionStatus.Completed) {
    return t('Completed')
  } else if (status === TransactionStatus.Cancelled) {
    return t('Cancelled')
  } else if (status === TransactionStatus.Declined) {
    return t('Declined')
  } else if (status === TransactionStatus.Failed) {
    return t('Failed')
  } else if (status === TransactionStatus.Reversed) {
    return t('Reversed')
  } else {
    return t('Pending')
  }
}

export const TransactionBadgeStatus: React.FC<TransactionBadgeStatusProps> = (props) => {
  const { id } = props

  const { t } = useTranslation()

  return (
    <span>
      <Badge
        text={getTransactionBadgeLabel(id, t)}
        appearance={
          TransactionBadgeVariantAppearanceMap[id as TransactionStatus] as BadgeVariantType
        }
      />
    </span>
  )
}
