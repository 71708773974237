import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '../../../global/button/Button'
import { Dropdown } from '../../../global/dropdown/Dropdown'
import IconButton from '../../../global/iconButton/IconButton'
import { Table } from '../../../global/table/Table'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DotsIcon } from '../../../icons/DotsIcon'
import { WarningIcon } from '../../../icons/WarningIcon'
import { WalletDto } from '../../../model/WalletDto'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../../utils/wallet.utils'
import { WalletBalancesOptions } from './WalletBalancesOptions'

import styles from './WalletsBalancesTable.module.scss'

type ExtendedWalletDto = WalletDto & {
  isOpen?: boolean
}

interface WalletTableProps {
  wallets: ExtendedWalletDto[]
  setModalVisible: (v: boolean) => void
  setWalletInformationModalOpen: (value: boolean) => void
  setWalletOptionsData?: (wallet: WalletDto) => void
}

export const WalletBalancesTable: React.FC<WalletTableProps> = (props) => {
  const { wallets, setWalletInformationModalOpen, setWalletOptionsData, setModalVisible } = props

  const [isOpen, setIsOpen] = useState<string | null>()

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const handleRestrictionModalOpen = (wallet: WalletDto) => {
    const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
    if (!hasAllRestrictions) {
      setModalVisible(true)
    }
  }

  return (
    <Table size='small'>
      <thead>
        <tr>
          <th>{t('Wallet.Wallet')}</th>
          <th>{t('Wallet.Available Balance')}</th>
          <th>{t('Wallet.Reserved')}</th>
          <th />
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        {wallets.map((wallet, idx) => {
          const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
          const isDepositRestricted = isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)
          const isTransferFromRestricted = isRestricted(
            WalletRestrictions.TRANSFER_FROM,
            wallet.restrictions
          )

          return (
            <tr key={wallet.id} onClick={() => hasAllRestrictions && setModalVisible(true)}>
              <td onClick={() => hasAllRestrictions && setModalVisible(true)}>
                {hasAllRestrictions && <WarningIcon color={'error'} size={20} />}
                {wallet.currency.id} {t('Wallet.Wallet')}
              </td>
              <td>
                <strong>{formatMoney(wallet.balance, wallet.currency.id)}</strong>
              </td>
              <td>{formatMoney(wallet.reserved, wallet.currency.id)}</td>
              <td>
                {isDepositRestricted && (
                  <AlertIconWrapper spaced isActive={!hasAllRestrictions}>
                    <Button
                      appearance='primary'
                      state='disabled'
                      size='XS'
                      onClick={() => handleRestrictionModalOpen(wallet)}
                    >
                      {t('Deposit')}
                    </Button>
                  </AlertIconWrapper>
                )}
                {!isDepositRestricted && (
                  <Link to={`/dashboard/traders-room/wallets/${wallet.id}/deposit`}>
                    <Button appearance='primary' size='XS'>
                      {t('Deposit')}
                    </Button>
                  </Link>
                )}
              </td>
              <td>
                {isTransferFromRestricted && (
                  <AlertIconWrapper spaced isActive={!hasAllRestrictions}>
                    <Button
                      appearance='secondary'
                      state='disabled'
                      size='XS'
                      onClick={() => handleRestrictionModalOpen(wallet)}
                    >
                      {t('Transfer')}
                    </Button>
                  </AlertIconWrapper>
                )}
                {!isTransferFromRestricted && (
                  <Link to={`/dashboard/traders-room/wallets/${wallet.id}/transfer`}>
                    <Button appearance='secondary' size='XS'>
                      {t('Transfer')}
                    </Button>
                  </Link>
                )}
              </td>
              <td width={50}>
                <div className={styles.dropdownIconContainer}>
                  <IconButton appearance='hoverable' onClick={() => setIsOpen(wallet.id)}>
                    <DotsIcon />
                  </IconButton>
                  {isOpen === wallet.id && (
                    <Dropdown
                      position={idx === 0 ? 'bottom' : 'top'}
                      onClose={() => setIsOpen(null)}
                    >
                      <WalletBalancesOptions
                        wallet={wallet}
                        setWalletInformationModalOpen={setWalletInformationModalOpen}
                        setWalletOptionsData={setWalletOptionsData}
                      />
                    </Dropdown>
                  )}
                </div>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
