import { IconProps, iconColor } from './IconProps'

export function UserBookIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.25 4.09961C4.25 3.13311 5.0335 2.34961 6 2.34961H18.9C19.3694 2.34961 19.75 2.73016 19.75 3.19961V17.9996C19.75 18.469 19.3694 18.8496 18.9 18.8496H18.754V20.3496H19C19.4142 20.3496 19.75 20.6854 19.75 21.0996C19.75 21.5138 19.4142 21.8496 19 21.8496H6C5.0335 21.8496 4.25 21.0661 4.25 20.0996V4.09961ZM17.254 20.3496V18.8496H5.75V20.0996C5.75 20.2377 5.86193 20.3496 6 20.3496H17.254ZM5.75 17.3496H18.25V3.84961H6C5.86193 3.84961 5.75 3.96154 5.75 4.09961V17.3496Z'
        fill={iconColor(props)}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 7.30293C11.419 7.30293 10.9479 7.77396 10.9479 8.35501C10.9479 8.93606 11.419 9.40709 12 9.40709C12.5811 9.40709 13.0521 8.93606 13.0521 8.35501C13.0521 7.77396 12.5811 7.30293 12 7.30293ZM9.64795 8.35501C9.64795 7.05599 10.701 6.00293 12 6.00293C13.2991 6.00293 14.3521 7.05599 14.3521 8.35501C14.3521 9.65403 13.2991 10.7071 12 10.7071C10.701 10.7071 9.64795 9.65403 9.64795 8.35501Z'
        fill={iconColor(props)}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.3206 11.5588C10.9312 11.3716 11.5932 11.3496 12 11.3496C12.4068 11.3496 13.0688 11.3716 13.6794 11.5588C14.2827 11.7438 15.0051 12.149 15.205 13.021C15.3278 13.5564 15.4346 14.0135 15.5107 14.3366C15.5488 14.4981 15.5792 14.6261 15.6 14.7136L15.6319 14.8474C15.7157 15.1964 15.5008 15.5478 15.1518 15.6316C14.8027 15.7155 14.4518 15.5004 14.368 15.1514L14.3353 15.0147C14.3143 14.9263 14.2837 14.7973 14.2454 14.6347C14.1688 14.3096 14.0613 13.85 13.9379 13.3115C13.8916 13.1097 13.7222 12.9316 13.2984 12.8017C12.8817 12.674 12.3812 12.6496 12 12.6496C11.6188 12.6496 11.1183 12.674 10.7016 12.8017C10.2778 12.9316 10.1084 13.1097 10.0621 13.3115C9.93869 13.85 9.83124 14.3096 9.75464 14.6347C9.71633 14.7973 9.68572 14.9263 9.66467 15.0147L9.63219 15.1507C9.54837 15.4998 9.1973 15.7155 8.84824 15.6316C8.49918 15.5478 8.28415 15.1969 8.36797 14.8478L8.40001 14.7136C8.42085 14.6261 8.45123 14.4981 8.48929 14.3366C8.56542 14.0135 8.67226 13.5564 8.79501 13.021C8.99495 12.149 9.71727 11.7438 10.3206 11.5588Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
