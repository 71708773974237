import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Wallet } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'
import { usePaymentProviderFactory } from '../../Traders-Room/Wallets/WalletDeposit/usePaymentProviderFactory'
import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import {
  PaymentProviderDepositModalItem,
  PaymentProviderModal,
} from '../../global/modal/PaymentProviderModal'
import { PaymentProvider } from '../../model/PaymentProviderDto'
import { WalletDto } from '../../model/WalletDto'
import { WalletPaymentProvider } from '../../model/WalletPaymentProvider'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'

interface PaymentProviderModalProps {
  onSelectOption(option: PaymentProvider, walletPaymentProvider: WalletPaymentProvider): void
  onClose(): void
  wallet?: Wallet | WalletDto
}

export const WalletDepositPaymentProviderModal: React.FC<PaymentProviderModalProps> = ({
  onSelectOption,
  onClose,
  wallet,
}) => {
  const { walletId } = useParams<{ walletId?: string }>()

  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const { createPaymentProvider } = usePaymentProviderFactory()

  const [isSelectLoading, setSelectLoading] = useState<boolean>(false)

  const { callback } = useCallbackWithForceRefresh(async () => {
    if (wallet?.id) {
      return apiClient.getWalletDepositMethods(locale, wallet.id)
    }
    if (walletId) {
      return apiClient.getWalletDepositMethods(locale, walletId)
    }
  }, [locale])

  const { data: paymentProviders = [], isLoading } = useFetchOne(callback)

  const handleSelectOption = async (walletPaymentProvider: WalletPaymentProvider) => {
    setSelectLoading(true)
    if (wallet?.currency) {
      const paymentProvider = await createPaymentProvider(walletPaymentProvider, wallet)
      onSelectOption(paymentProvider, walletPaymentProvider)
    }
  }

  return (
    <PaymentProviderModal
      render={() => (
        <Loading isLoading={isLoading || isSelectLoading} showLoadingIcon>
          {paymentProviders.map((x) => (
            <PaymentProviderDepositModalItem
              item={x}
              onSelectOption={handleSelectOption}
              key={x.method.id}
            />
          ))}
        </Loading>
      )}
      onClose={onClose}
    />
  )
}
