import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { BackButton } from '../backButton/BackButton'

import styles from './CancelActionModal.module.scss'

interface CancelActionModalProps {
  title?: string
  footer?: React.ReactNode | React.ReactNode[] | undefined
  onBack?(): void
  onCancel?(): void
  onConfirm?(): void
  className?: string
  cancelLabel?: string
  spacing?: 'regular' | 'compact'
  confirmLabel?: string
}

export const CancelActionModalBody: React.FC<
  Omit<PropsWithChildren<CancelActionModalProps>, 'title' | 'footer'>
> = (props) => {
  const { children, className, spacing } = props

  const { t } = useTranslation()

  return (
    <section
      className={classNames('modal-card-body', styles.body, className, {
        hasSpacingCompact: spacing === 'compact',
      })}
    >
      {children ? children : <p>{t('Are you sure you want to cancel')}</p>}
    </section>
  )
}

const CancelActionModalFooter: React.FC<
  Omit<PropsWithChildren<CancelActionModalProps>, 'title' | 'children'>
> = (props) => {
  const { footer, onConfirm, onCancel, cancelLabel, confirmLabel } = props
  const { t } = useTranslation()

  if (Array.isArray(footer) && footer?.length > 0) {
    return <React.Fragment>{footer}</React.Fragment>
  }

  if (footer) {
    return <React.Fragment>{footer}</React.Fragment>
  }

  return (
    <React.Fragment>
      <button className='button' onClick={onCancel} type='button'>
        {cancelLabel || t('No')}
      </button>

      <button className='button' onClick={onConfirm} type='button'>
        {confirmLabel || t('Yes')}
      </button>
    </React.Fragment>
  )
}

export const CancelActionModal: React.FC<PropsWithChildren<CancelActionModalProps>> = (props) => {
  const { t } = useTranslation()
  const { title = t('Cancel?'), onBack, children } = props

  return (
    <React.Fragment>
      <header className='modal-card-head'>
        {onBack && <BackButton onClick={onBack} className='pt-2' />}{' '}
        <p className='modal-card-title'>{title}</p>
      </header>
      <CancelActionModalBody {...props}>{children}</CancelActionModalBody>
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <CancelActionModalFooter {...props} />
      </footer>
    </React.Fragment>
  )
}
