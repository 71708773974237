import styles from './Icons.module.scss'

export function GettingStartedTransferFundsIcon() {
  return (
    <svg
      width='592'
      height='416'
      viewBox='0 0 592 416'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='592' height='416' rx='16' fill={styles.background} />
      <path
        d='M152.004 188H204.004C208.422 188 212.004 191.582 212.004 196V232C212.004 236.418 208.422 240 204.004 240H148.004C143.586 240 140.004 236.418 140.004 232V184C140.004 179.582 143.586 176 148.004 176H200.004'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path d='M198.004 206V218' stroke={styles.text} strokeWidth='3' strokeLinecap='round' />
      <path
        d='M261.336 196.267H298.669M298.669 196.267L289.336 186.934M298.669 196.267L289.336 205.6'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M277.336 221.333H314.669M314.669 221.333L305.336 212M314.669 221.333L305.336 230.667'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M359.007 213.037L392.7 182.411C392.96 182.175 393.324 182.092 393.66 182.193L423.62 191.181C423.939 191.276 424.284 191.207 424.541 190.997L458.241 163.42M458.241 163.42L439.635 163.422M458.241 163.42L455.141 182.027'
        stroke={styles.primary}
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        d='M424.127 213.815V257.23M362.105 257.23V232.421M393.116 257.23V207.613M455.138 257.23V207.613M409.559 224.5V257.23M377.559 257.23V221M439.559 239V257.23'
        stroke={styles.text}
        strokeWidth='2.5'
        strokeLinecap='round'
      />
    </svg>
  )
}

export function GettingStartedTransferFundsMobileIcon() {
  return (
    <svg
      width='312'
      height='150'
      viewBox='0 0 312 150'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='312' height='149.537' rx='12' fill={styles.background} />
      <path
        d='M52.9531 60.6016H87.4531C91.8714 60.6016 95.4531 64.1833 95.4531 68.6016V89.4349C95.4531 93.8532 91.8714 97.4349 87.4531 97.4349H52.4531C48.0348 97.4349 44.4531 93.8532 44.4531 89.4349V60.1016C44.4531 55.6833 48.0348 52.1016 52.4531 52.1016H86.9531'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M85.5352 73.3516V81.8516'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M131.703 66.5195H157.953M157.953 66.5195L151.391 59.957M157.953 66.5195L151.391 73.082'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M142.953 84.1445H169.203M169.203 84.1445L162.641 77.582M169.203 84.1445L162.641 90.707'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M201.899 78.325L225.572 56.8067C225.832 56.5707 226.196 56.488 226.532 56.5888L247.382 62.844C247.701 62.9395 248.046 62.8706 248.303 62.6601L271.983 43.2828M271.983 43.2828L258.842 43.284M271.983 43.2828L269.793 56.4242'
        stroke={styles.primary}
        strokeWidth='2.5'
        strokeLinecap='round'
      />
      <path
        d='M247.888 78.8763V109.538M204.086 109.538V92.0171M225.987 109.538V74.4961M269.79 109.538V74.4961M237.6 86.4226V109.538M215 109.538V83.9508M258.787 96.6632V109.538'
        stroke={styles.text}
        strokeWidth='2.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
