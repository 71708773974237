import styles from './Icons.module.scss'

export function LogoIcon(props: { size?: number }) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.72227 9.77299C7.7285 9.66635 7.76048 9.56259 7.81567 9.46999C7.87086 9.37739 7.94773 9.29853 8.04014 9.23969L11.5272 7.2869C11.6256 7.23866 11.7344 7.21352 11.8448 7.21352C11.9552 7.21352 12.064 7.23866 12.1624 7.2869L15.2055 8.99073L19.4053 6.53834L12.6211 2.68188C12.3813 2.56237 12.1153 2.5 11.8454 2.5C11.5755 2.5 11.3095 2.56237 11.0697 2.68188L4.28431 6.5372C4.05469 6.68703 3.8643 6.88628 3.72819 7.11925C3.59207 7.35222 3.51396 7.61251 3.5 7.87959V15.5L7.72227 13.1544V9.77299Z'
        stroke={styles.contrastMediumLow}
        strokeWidth='0.9'
      />
      <path
        d='M16.2777 10.8484V14.2273C16.2716 14.3341 16.2397 14.4379 16.1845 14.5306C16.1293 14.6233 16.0523 14.7023 15.9599 14.7612L12.4722 16.7139C12.3738 16.7621 12.265 16.7873 12.1546 16.7873C12.0442 16.7873 11.9354 16.7621 11.837 16.7139L8.79153 15.0101L4.5 17.3979C4.5 17.3979 9.14748 20.0497 11.3795 21.3181C11.6193 21.4376 11.8853 21.5 12.1552 21.5C12.4251 21.5 12.6911 21.4376 12.9309 21.3181L19.7157 17.4624C19.9453 17.3126 20.1357 17.1133 20.2718 16.8804C20.4079 16.6474 20.486 16.3871 20.5 16.1201V8.5L16.2777 10.8484Z'
        stroke={styles.contrastMediumLow}
        strokeWidth='0.9'
      />
    </svg>
  )
}
