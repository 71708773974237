import React, { useEffect } from 'react'
import { Params, useNavigate, useParams } from 'react-router-dom'

import { WalletDepositFormValues } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'
import { Loading } from '../../global/Loading/Loading'
import { MasterTransaction, WalletTransaction } from '../../model/MasterTransaction'
import { WalletTypeEnum } from '../../model/WalletDto'
import {
  isPaymentRedirectTransactionCompletedStatus,
  isPaymentRedirectTransactionFailedStatus,
  isPaymentRedirectTransactionSuccessStatus,
} from './PaymentRedirectTransactionStatusType'
import {
  TransactionCategoryType,
  isTransactionCategory,
  isTransactionDepositCategory,
} from './TransactionCategoryType'

interface DepositSuccessResponse {
  id: string
  transactionCategoryType: string
  transactionCurrentCategoryType: string
  data: MasterTransaction
  providerCategoryId: number
  // add a match field with the new version of react-router
  match: { category: string; walletId: string; url: string }
  date: string
  redirectPath?: string
}

export const DepositRedirectFromProviderPage: React.FC = () => {
  const { statusId } = useParams<{ statusId?: string }>()
  const navigate = useNavigate()

  const transaction = transactionStorage().getProviderDepositResponseTransaction()

  useEffect(() => {
    if (transaction?.redirectPath) {
      navigate(transaction.redirectPath)
      return
    }
    if (transaction?.id && statusId) {
      transactionStorage().removeProviderResponseTransactions()
      if (isPaymentRedirectTransactionSuccessStatus(statusId)) {
        const dashboardSection =
          transaction.data.walletTransactions[0]?.wallet.walletType.id ===
          WalletTypeEnum.PAYMENT_AGENT
            ? 'payment-agent'
            : 'traders-room'

        navigate(
          `/dashboard/${dashboardSection}/wallets/${transaction.match.walletId}/deposit/transaction/succeed`,
          {
            state: {
              transaction: transaction.data,
              providerCategoryId: transaction.providerCategoryId,
              navigateToTransactionHistory: true,
            },
          }
        )
      }
      if (isPaymentRedirectTransactionCompletedStatus(statusId)) {
        navigate(`${transaction.match.url}/transaction/completed`, {
          state: transaction,
        })
      }
      if (isPaymentRedirectTransactionFailedStatus(statusId)) {
        navigate(`${transaction.match.url}/transaction/failed`, {
          state: transaction,
        })
      }
    } else {
      navigate('/dashboard')
    }
  }, [transaction, navigate, statusId])

  return <Loading isLoading showLoadingIcon />
}

export const transactionStorage = () => ({
  getProviderResponseTransactions,
  getProviderDepositResponseTransaction,
  addProviderDepositResponseTransaction,
  removeProviderResponseTransactions,
})

const getProviderResponseTransactions = (): DepositSuccessResponse[] => {
  const transactions: DepositSuccessResponse[] = Object.entries(localStorage)
    .map(([, value]) => JSONParse(value))
    .filter((value) => !!value)
    .filter((x) => isTransactionCategory(x.transactionCategoryType))

  return transactions
}

const getProviderDepositResponseTransaction = (): DepositSuccessResponse => {
  const [transaction]: DepositSuccessResponse[] = getProviderResponseTransactions().filter((x) =>
    isTransactionDepositCategory(x.transactionCurrentCategoryType)
  )
  return transaction
}

const addProviderDepositResponseTransaction = (data: {
  transaction: MasterTransaction
  values: WalletDepositFormValues
  match: Readonly<Params>
  redirectPath?: string
}) => {
  removeProviderResponseTransactions()
  localStorage.setItem(data.transaction.id, createDepositTransactionSuccess(data))
}

const removeProviderResponseTransactions = () => {
  getProviderResponseTransactions().forEach((x) => {
    localStorage.removeItem(x.id)
  })
}

const JSONParse = (value: string) => {
  try {
    return JSON.parse(value)
  } catch (error: unknown) {
    return undefined
  }
}

const createDepositTransactionSuccess = (data: {
  transaction: MasterTransaction
  values: WalletDepositFormValues
  match: Readonly<Params>
  redirectPath?: string
}): string => {
  const { transaction, values, match, redirectPath } = data

  return JSON.stringify({
    id: transaction.id,
    transactionCategoryType: TransactionCategoryType.TRANSACTION_TYPE,
    transactionCurrentCategoryType: TransactionCategoryType.DEPOSIT_TRANSACTION_TYPE,
    data: {
      walletTransactions: transaction.walletTransactions.map(createWalletTransaction),
    },
    providerCategoryId: values.paymentProvider.providerCategory.id,

    match,
    date: new Date().toDateString(),
    redirectPath,
  })
}

const createWalletTransaction = (x: WalletTransaction) => ({
  wallet: {
    currency: {
      id: x.wallet?.currency?.id,
    },
    walletType: x.wallet?.walletType,
  },
  otherCurrency: {
    id: x?.otherCurrency?.id,
  },
  otherAmount: x?.otherAmount,
  paymentProviderTransaction: {
    paymentProvider: {
      name: x?.paymentProviderTransaction?.paymentProvider?.name,
    },
  },
})
