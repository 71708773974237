import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormTemplate } from '../../../global/templates/FormTemplate'
import { PaymentProvider } from '../../../model/PaymentProviderDto'
import { useIsLangSelected } from '../../../utils/language.utils'
import { TermsConditions } from '../../TermsConditions/TermsConditions'
import { Wallet, getCurrencyLimits } from './WalletWithdrawForm/WalletWithdrawForm'

import styles from './WalletWithdrawTermsConditions.module.scss'

interface Props {
  wallet: Wallet | undefined
  paymentProvider: PaymentProvider | undefined
  onCancel(): void
}
export const WalletWithdrawTermsConditions: FC<Props> = ({ wallet, paymentProvider, onCancel }) => {
  const { t } = useTranslation()

  useIsLangSelected(onCancel)

  const walletWithdrawTermsConditionsMessageFormat = (
    message: string,
    wallet: Wallet,
    paymentProvider: PaymentProvider
  ): string => {
    const currencyLimit = getCurrencyLimits(wallet, paymentProvider)
    const minAmount = currencyLimit?.minAmount?.toString() || '0'
    const maxAmount = currencyLimit?.maxAmount?.toString() || '0'

    const currency = paymentProvider.currency.id || ''
    return message
      .replace(/{minAmount}/g, minAmount)
      .replace(/{maxAmount}/g, maxAmount)
      .replace(/{paymentProviderCurrency}/g, currency)
  }

  const formattedTerms = useMemo((): string[] => {
    return (
      paymentProvider?.parameters.termsConditions.map((term, index) =>
        wallet && paymentProvider
          ? walletWithdrawTermsConditionsMessageFormat(
              `${index + 1}. ${term.name}`,
              wallet,
              paymentProvider
            )
          : `${index + 1}. ${term.name}`
      ) || []
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentProvider])

  return (
    <FormTemplate
      title={t('Wallet.General Terms and Conditions for Withdrawals')}
      goBack={onCancel}
      headerDisplayType={'flex'}
      titleClassName={styles.customTitle}
    >
      <TermsConditions terms={formattedTerms} onCancel={onCancel} />
    </FormTemplate>
  )
}
