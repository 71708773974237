import { NameDto } from './NameDto'
import { TickmillProductType } from './TickmillProductType'

export interface LandingPageDto {
  id: string
  name: string
  promoMaterialBaseUrl: NameDto
  url: string
  enabled: boolean
  imagePath: string
  tickmillCompany: NameDto
  language: {
    id: string
    name: string
  }
}

export interface ClientLandingPageSettingDto {
  tickmillProductId: TickmillProductType
  landingPages: ClientLandingPageDto[]
}

export interface ClientLandingPageDto {
  id: number
  name: string
  selected: boolean
}

export enum ETDLandingPageID {
  TradersRoomBalances = 300,
  TradersRoomETDTransactionHistory = 301,
  SubscriptionsPlatforms = 400,
  SubscriptionsMarketData = 401,
  SubscriptionsHistory = 402,
  ETDStatements = 500,
}

export enum CFDLandingPageID {
  TradersRoomWallets = 100,
  TradersRoomTradingAccounts = 101,
  TradersRoomTransactionHistory = 102,
  IntroducingBrokerWallets = 200,
  IntroducingBrokerClients = 201,
  IntroducingBrokerIncome = 202,
  IntroducingBrokerReports = 203,
  IntroducingBrokerTransactionHistory = 204,
  IntroducingBrokerReferralMaterials = 205,
  IntroducingBrokerLegalDocuments = 206,
}

const ETDLandingPage = {
  [ETDLandingPageID.TradersRoomBalances]: '/dashboard/traders-room/balances',
  [ETDLandingPageID.TradersRoomETDTransactionHistory]:
    '/dashboard/traders-room/transaction-history',
  [ETDLandingPageID.SubscriptionsPlatforms]: '/dashboard/subscriptions/trading-platforms',
  [ETDLandingPageID.SubscriptionsMarketData]: '/dashboard/subscriptions/market-data',
  [ETDLandingPageID.SubscriptionsHistory]: '/dashboard/subscriptions/history',
  [ETDLandingPageID.ETDStatements]: '/dashboard/statements/daily',
}

const CFDLandingPage = {
  [CFDLandingPageID.TradersRoomWallets]: '/dashboard/traders-room/wallets',
  [CFDLandingPageID.TradersRoomTradingAccounts]: '/dashboard/traders-room/trading-accounts',
  [CFDLandingPageID.TradersRoomTransactionHistory]: '/dashboard/traders-room/transaction-history',
  [CFDLandingPageID.IntroducingBrokerWallets]: '/dashboard/introducing-broker/wallets',
  [CFDLandingPageID.IntroducingBrokerClients]: '/dashboard/introducing-broker/clients',
  [CFDLandingPageID.IntroducingBrokerIncome]: '/dashboard/introducing-broker/income',
  [CFDLandingPageID.IntroducingBrokerReports]: '/dashboard/introducing-broker/reports',
  [CFDLandingPageID.IntroducingBrokerTransactionHistory]:
    '/dashboard/introducing-broker/transaction-history',
  [CFDLandingPageID.IntroducingBrokerReferralMaterials]:
    '/dashboard/introducing-broker/referral-materials',
  [CFDLandingPageID.IntroducingBrokerLegalDocuments]:
    '/dashboard/introducing-broker/legal-documents',
}

export const LandingPageMapping = {
  ...ETDLandingPage,
  ...CFDLandingPage,
}
