import { IconProps, iconColor } from './IconProps'

export function DropArrowUpIcon(props: IconProps) {
  const size = props.size || 10

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 10 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.481651 4.99976C0.30347 4.99976 0.214237 4.78433 0.34023 4.65834L4.78668 0.211891C4.90383 0.0947341 5.09378 0.0947341 5.21094 0.211891L9.65739 4.65834C9.78338 4.78433 9.69415 4.99976 9.51597 4.99976H0.481651Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
