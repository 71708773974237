import { get } from 'object-path'

function compare(a: any, b: any, key: string) {
  const aVal = get(a, key)
  const bVal = get(b, key)
  if (typeof aVal === 'string') {
    return aVal.localeCompare(bVal)
  }

  return (aVal || 0) - (bVal || 0)
}

export function inMemorySort<T>(items: ReadonlyArray<T>, sortBy: string): T[] {
  return [...items].sort((left, right) => {
    if (sortBy.startsWith('-')) {
      return compare(right, left, sortBy.substring(1))
    }

    return compare(left, right, sortBy)
  })
}
