import { Location } from 'react-router-dom'

export function getLocationCountry(location: Location) {
  const searchParams = new URLSearchParams(location.search)
  return (
    searchParams.get('fcountry') ||
    searchParams.get('FCountry') ||
    searchParams.get('fCountry') ||
    undefined
  )
}
