import React from 'react'

import { IconProps, iconColor } from './IconProps'

export const CopyIcon: React.FC<IconProps> = (props) => {
  const size = props.size || 24
  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 24 24'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill={iconColor(props)}
        d='M18.4545 20.1818H8.45455V7.45455H18.4545V20.1818ZM18.4545 5.63636H8.45455C7.97233 5.63636 7.50987 5.82792 7.1689 6.1689C6.82792 6.50987 6.63636 6.97233 6.63636 7.45455V20.1818C6.63636 20.664 6.82792 21.1265 7.1689 21.4675C7.50987 21.8084 7.97233 22 8.45455 22H18.4545C18.9368 22 19.3992 21.8084 19.7402 21.4675C20.0812 21.1265 20.2727 20.664 20.2727 20.1818V7.45455C20.2727 6.97233 20.0812 6.50987 19.7402 6.1689C19.3992 5.82792 18.9368 5.63636 18.4545 5.63636ZM15.7273 2H4.81818C4.33597 2 3.87351 2.19156 3.53253 2.53253C3.19156 2.87351 3 3.33597 3 3.81818V16.5455H4.81818V3.81818H15.7273V2Z'
      />
    </svg>
  )
}
