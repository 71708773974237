import { Dispatch, ReactNode, createContext, useCallback, useState } from 'react'

import {
  storeHideGeneralNotificationsClientSide,
  storeHidePlatformNotificationsClientSide,
  storeHidePromoNotificationsClientSide,
} from '../../model/Notification'
import { NotificationsContextInterface } from '../notification/notification'
import { useGuardedContext } from '../useGuardedContext'

type NotificationsContextType =
  | [NotificationsContextInterface, Dispatch<Partial<NotificationsContextInterface>>]
  | undefined

const NotificationsContext = createContext<NotificationsContextType>(undefined)
NotificationsContext.displayName = 'NotificationsContext'

export function useNotificationsContext() {
  return useGuardedContext(NotificationsContext)
}

interface Props {
  initialSettings: NotificationsContextInterface
  children: ReactNode
}

export function NotificationsContextProvider({ initialSettings, children }: Props) {
  const [settings, _setSettings] = useState(initialSettings)

  const setSettings = useCallback(
    (newSettings: Partial<NotificationsContextInterface>) => {
      const { hidePlatformNotifications, hideGeneralNotifications, hidePromoNotifications } =
        newSettings

      if (
        hidePlatformNotifications !== undefined &&
        hidePlatformNotifications !== settings.hidePlatformNotifications
      ) {
        storeHidePlatformNotificationsClientSide(hidePlatformNotifications)
      }
      if (
        hideGeneralNotifications !== undefined &&
        hideGeneralNotifications !== settings.hideGeneralNotifications
      ) {
        storeHideGeneralNotificationsClientSide(hideGeneralNotifications)
      }
      if (
        hidePromoNotifications !== undefined &&
        hidePromoNotifications !== settings.hidePromoNotifications
      ) {
        storeHidePromoNotificationsClientSide(hidePromoNotifications)
      }

      _setSettings({ ...settings, ...newSettings })
    },
    [settings]
  )

  return (
    <NotificationsContext.Provider value={[settings, setSettings]}>
      {children}
    </NotificationsContext.Provider>
  )
}
