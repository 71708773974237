import { subMonths } from 'date-fns'
import isBefore from 'date-fns/isBefore'

import { useProductReadContext } from '../../global/context/ProductContext'
import { AccountDetailedDto, isAccountCompanyType } from '../../model/AccountDetailedDto'
import { AccountDetailedStatus } from '../../model/AccountDetailedStatus'
import { TickmillProductType } from '../../model/TickmillProductType'
import { useAccountReadContext } from '../AccountContextContext'
import { getExpirationDaysInterval } from '../date.utils'
import {
  evaluateProductStateAttribute,
  isProductAppropriatenessTestNotFailed,
} from './accountProductStatuses'

export const useAccountDocumentCheck = () => {
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()

  const isDocumentUploadAllowed = isAccountDocumentAllowedUpload(account)
  const areDocumentsApproved = isAccountDocumentsApproved(account)
  const isPendingDocumentVerification = isAccountPendingDocumentVerificationStatus(account)
  const isDocumentManagementPermitted = isAccountDocumentManagementPermitted(product, account)
  const isLegalDocumentsConsentProvided = hasConsentedToLegalDocuments(account, product)
  const documentExpirationIntervalDays = getAccountDocumentExpirationDaysInterval(account)

  return {
    isDocumentUploadAllowed,
    isAccountDocumentsApproved: areDocumentsApproved,
    isPendingDocumentVerification,
    isDocumentManagementPermitted,
    isLegalDocumentsConsentProvided,
    documentExpirationIntervalDays,
  }
}

export const isAccountDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return (
    isAccountPersonalInfoDocumentAllowedUpload(accountDetailed) ||
    isAccountAddressInfoDocumentAllowedUpload(accountDetailed) ||
    isAccountCompanyInfoDocumentAllowedUpload(accountDetailed) ||
    isAccountAdditionalInfoDocumentAllowedUpload(accountDetailed) ||
    isAccountPaymentInfoDocumentAllowedUpload(accountDetailed)
  )
}

export const isAccountPersonalInfoDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed) {
    return false
  }
  return (
    !accountDetailed.isPersonalInfoLocked || isDocumentManagementNotificationNeeded(accountDetailed)
  )
}

export const isAccountAddressInfoDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return !!accountDetailed && !accountDetailed.isAddressInfoLocked
}

export const isAccountCompanyInfoDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return (
    !!accountDetailed &&
    !accountDetailed.isCompanyInfoLocked &&
    isAccountCompanyType(accountDetailed)
  )
}

export const isAccountAdditionalInfoDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return !!accountDetailed && !accountDetailed.isAdditionalInfoLocked
}

export const isAccountPaymentInfoDocumentAllowedUpload = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return !!accountDetailed && !accountDetailed.isPaymentInfoLocked
}

export const isAccountDocumentsApproved = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return (
    isAccountPersonalInfoDocumentApproved(accountDetailed) &&
    isAccountAddressInfoDocumentApproved(accountDetailed) &&
    isAccountCompanyInfoDocumentApproved(accountDetailed) &&
    isAccountAdditionalInfoDocumentApproved(accountDetailed)
  )
}

const isAccountPersonalInfoDocumentApproved = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed || accountDetailed.isPersonalInfoLocked === undefined) {
    return true
  }
  return !!accountDetailed.isPersonalInfoLocked
}

const isAccountAddressInfoDocumentApproved = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed || accountDetailed.isAddressInfoLocked === undefined) {
    return true
  }
  return !!accountDetailed.isAddressInfoLocked
}

const isAccountCompanyInfoDocumentApproved = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed || accountDetailed.isCompanyInfoLocked === undefined) {
    return true
  }
  return !!accountDetailed.isCompanyInfoLocked && isAccountCompanyType(accountDetailed)
}

const isAccountAdditionalInfoDocumentApproved = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed || accountDetailed.isAdditionalInfoLocked === undefined) {
    return true
  }
  return !!accountDetailed.isAdditionalInfoLocked
}

export const isAccountPendingDocumentVerificationStatus = (
  account: AccountDetailedDto | undefined
): boolean => {
  return !!account && account.status?.id === AccountDetailedStatus.PendingDocumentVerification
}

export const hasProductPendingDocumentVerificationStatus = (
  accountDetailed: AccountDetailedDto | undefined,
  productType: TickmillProductType | undefined
): boolean => {
  return (
    !!accountDetailed &&
    evaluateProductStateAttribute(
      accountDetailed,
      productType,
      (productState) =>
        productState.calculatedStatus.id === AccountDetailedStatus.PendingDocumentVerification
    )
  )
}

export const isAccountDocumentManagementPermitted = (
  product: TickmillProductType,
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return (
    isProductAppropriatenessTestNotFailed(product, accountDetailed) ||
    hasProductPendingDocumentVerificationStatus(accountDetailed, product)
  )
}

export const getAccountDocumentExpirationDaysInterval = (
  account: AccountDetailedDto | undefined
): number => {
  if (!account?.documentExpirationDate) {
    return 0
  }
  return getExpirationDaysInterval(account.documentExpirationDate)
}

export const isAccountDocumentManagementExpired = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return (
    !!accountDetailed?.documentExpirationDate &&
    isDocumentManagementExpired(accountDetailed.documentExpirationDate)
  )
}

export const isAccountDocumentManagement1MonthExpired = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return (
    !!accountDetailed?.documentExpirationDate &&
    isDocumentManagement1MonthExpired(accountDetailed.documentExpirationDate)
  )
}

export const isAccountDocumentManagement2MonthExpired = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return (
    !!accountDetailed?.documentExpirationDate &&
    isDocumentManagement2MonthExpired(accountDetailed.documentExpirationDate)
  )
}

export const isDocumentManagementNotificationNeeded = (
  account: AccountDetailedDto | undefined
): boolean => {
  return (
    isAccountDocumentManagementExpired(account) ||
    isAccountDocumentManagement1MonthExpired(account) ||
    isAccountDocumentManagement2MonthExpired(account)
  )
}

const isDocumentManagementExpired = (expirationDate: string | undefined | null): boolean => {
  if (!expirationDate) {
    return false
  }
  return isBefore(new Date(expirationDate), new Date())
}

const isDocumentManagement1MonthExpired = (expirationDate: string | undefined | null): boolean => {
  if (!expirationDate) {
    return false
  }
  return isBefore(subMonths(new Date(expirationDate), 1), new Date())
}

export const isDocumentManagement2MonthExpired = (
  expirationDate: string | undefined | null
): boolean => {
  if (!expirationDate) {
    return false
  }
  return isBefore(subMonths(new Date(expirationDate), 2), new Date())
}

export const hasConsentedToLegalDocuments = (
  account: AccountDetailedDto | undefined,
  productType: TickmillProductType
): boolean => {
  return (
    !!account &&
    evaluateProductStateAttribute(
      account,
      productType,
      (productState) => !!productState.agreedToLegalDocuments
    )
  )
}

export const isAccountDocumentAllLocked = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (!accountDetailed) {
    return false
  }

  const fieldsToCheck = isAccountCompanyType(accountDetailed)
    ? [
        accountDetailed.isPersonalInfoLocked,
        accountDetailed.isAddressInfoLocked,
        accountDetailed.isCompanyInfoLocked,
        accountDetailed.isAdditionalInfoLocked,
      ]
    : [
        accountDetailed.isPersonalInfoLocked,
        accountDetailed.isAddressInfoLocked,
        accountDetailed.isAdditionalInfoLocked,
      ]

  return fieldsToCheck.every(Boolean)
}
