import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { ExternalLink } from '../../../global/ExternalLink'
import { createFormCheckBoxField } from '../../../global/formField/FormCheckBoxField'
import {
  LegalDocumentDto,
  isLegalDocumentWidgetCheckbox,
} from '../../../model/CompanyLegalDocumentDto'
import { Text } from '../../../ui/Typography/Typography'
import { validateIsTrue } from '../../../utils/formValidation'

export interface DocumentFieldItem {
  documents: LegalDocumentDto[]
  component: React.ReactNode
}

interface FormValues {
  documents: { [key: string]: boolean }
  agreedLegalDocuments: AgreedLegalDocument[]
}

const FormCheckBoxField = createFormCheckBoxField()

interface DocumentGroupFieldProps {
  groupReference: string
  documents: LegalDocumentDto[]
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

interface AgreedLegalDocument {
  id: string
  openedOn: Date
  agreedOn: Date
}

export const DocumentCheckBoxGroupField: React.FC<DocumentGroupFieldProps> = (props) => {
  const { groupReference, documents, renderItem } = props

  const { values, setValues } = useFormikContext<FormValues>()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (groupReference) {
      const agreedLegalDocumentsRest = values.agreedLegalDocuments.filter(
        (x) => !documents.find((doc) => x.id === doc.id)
      )

      const agreedLegalDocuments = documents
        .filter(isLegalDocumentWidgetCheckbox)
        .map(createAgreedLegalCheckBoxDocument)
        .concat(agreedLegalDocumentsRest)
        .filter(filterAgreedLegalDocumentsDuplicates)

      setValues({
        ...values,
        documents: {
          ...values.documents,
          [groupReference]: event.target.checked || false,
        },
        agreedLegalDocuments: agreedLegalDocuments,
      })
    }
  }

  return (
    <CheckBoxGroupField
      documents={documents}
      groupReference={groupReference}
      renderItem={renderItem}
      onChange={handleChange}
    />
  )
}

export interface CheckBoxGroupFieldProps {
  groupReference: string
  documents: LegalDocumentDto[]
  onChange(data: ChangeEvent<HTMLInputElement>): void
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

const CheckBoxGroupField: React.FC<CheckBoxGroupFieldProps> = (props) => {
  const { groupReference, documents, onChange, renderItem } = props

  const { t } = useTranslation()

  const defaultComponent = (
    <React.Fragment>
      <Text>{t('Sign up.I confirm that I have read, consent and agree to the')}</Text>
      {documents
        .map<React.ReactNode>((x, i) => <DocumentLink document={x} key={x.id} />)
        .reduce((prev, curr, i, arr) => {
          if (i === 0) {
            return [' ', curr]
          } else {
            return [prev, ', ', curr]
          }
        }, [] as React.ReactNode[])}{' '}
      <span className='text'>*</span>
    </React.Fragment>
  )

  return (
    <FormCheckBoxField
      name={`documents.${groupReference}`}
      validate={validateIsTrue(t('Validation.Required'))}
      onChange={onChange}
    >
      <React.Fragment>
        {renderItem
          ? renderItem({
              documents,
              component: defaultComponent,
            })
          : defaultComponent}
      </React.Fragment>
    </FormCheckBoxField>
  )
}

interface DocumentCheckBoxFieldFactoryProps {
  documents: LegalDocumentDto[]
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

export const DocumentCheckBoxField: React.FC<DocumentCheckBoxFieldFactoryProps> = (props) => {
  const { documents = [], renderItem } = props
  const [document] = documents

  const { values, setValues } = useFormikContext<FormValues>()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const agreedLegalDocuments = values.agreedLegalDocuments
      .filter(filterAgreedLegalDocumentsByDocument(document))
      .concat(createAgreedLegalCheckBoxDocument(document))
      .filter(filterAgreedLegalDocumentsDuplicates)

    setValues({
      ...values,
      documents: {
        ...values.documents,
        [document.id]: event.target.checked || false,
      },
      agreedLegalDocuments,
    })
  }

  return <CheckBoxField documents={[document]} onChange={handleChange} renderItem={renderItem} />
}

export interface CheckBoxFieldProps {
  documents: LegalDocumentDto[]
  onChange(data: ChangeEvent<HTMLInputElement>): void
  renderItem?(props: DocumentFieldItem): React.ReactNode
}

const CheckBoxField: React.FC<CheckBoxFieldProps> = (props) => {
  const { documents = [], onChange, renderItem } = props
  const [document] = documents

  const { t } = useTranslation()

  const defaultComponent = (
    <React.Fragment>
      {document?.url && <Text>{t('I agree with')} </Text>}
      <DocumentLink document={document} /> <Text>*</Text>
    </React.Fragment>
  )

  return (
    <FormCheckBoxField
      name={`documents.${document.id}`}
      validate={validateIsTrue(t('Validation.Required'))}
      onChange={onChange}
      className='text'
    >
      <React.Fragment>
        {renderItem
          ? renderItem({
              documents: [document],
              component: defaultComponent,
            })
          : defaultComponent}
      </React.Fragment>
    </FormCheckBoxField>
  )
}

interface DocumentLinkProps {
  document: LegalDocumentDto
}

const DocumentLink: React.FC<DocumentLinkProps> = (props) => {
  const { document } = props

  return (
    <ExternalLink
      title={document?.title || undefined}
      url={document?.url || undefined}
      className={classNames('text', {
        'is-link': !!document?.url,
      })}
    >
      {document.title}
    </ExternalLink>
  )
}

const filterAgreedLegalDocumentsByDocument =
  (document: LegalDocumentDto) => (x: AgreedLegalDocument) => {
    return x.id !== document.id
  }

const createAgreedLegalCheckBoxDocument = (document: LegalDocumentDto) => {
  return {
    id: document.id,
    openedOn: new Date(),
    agreedOn: new Date(),
  }
}

const filterAgreedLegalDocumentsDuplicates = (
  value: AgreedLegalDocument,
  index: number,
  self: AgreedLegalDocument[]
) => {
  return index === self.findIndex((t) => t.id === value.id && t.id === value.id)
}
