import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { WalletDepositFormValues } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'
import {
  MasterTransaction,
  isTransactionFormRedirect,
  isTransactionUrlRedirect,
} from '../../model/MasterTransaction'
import { DepositFormRedirectPage } from './DepositFormRedirectPage'
import { DepositUrlRedirectPage } from './DepositUrlRedirectPage'

export const useDepositRedirectToProvider = () => {
  const location = useLocation()
  const match = useParams()

  const [values] = useState<{
    transaction: MasterTransaction
    values: WalletDepositFormValues
    redirectPath?: string
  }>(location.state)

  return {
    transaction: values?.transaction,
    values: values?.values,
    match: match!,
    redirectPath: values?.redirectPath,
  }
}

export const DepositRedirectToProviderPage: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { transaction } = useDepositRedirectToProvider()

  useEffect(() => {
    if (transaction) {
      navigate({ ...location }, { replace: true })
    } else {
      navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transaction])

  return (
    <React.Fragment>
      {isTransactionUrlRedirect(transaction) && <DepositUrlRedirectPage />}
      {isTransactionFormRedirect(transaction) && <DepositFormRedirectPage />}
    </React.Fragment>
  )
}
