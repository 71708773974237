export enum MarketDataClassificationsType {
  MarketDataPrivate = 1,
  MarketDataProfessional = 2,
}

export const getMarketDataClassificationsName = (type: MarketDataClassificationsType) => {
  if (isMarketDataPrivateType(type)) {
    return 'Sign up.Private/Non-Professional'
  }

  if (isMarketDataProfessionalType(type)) {
    return 'Sign up.Professional Investor'
  }

  return ''
}

export const isMarketDataPrivateType = (type: MarketDataClassificationsType) => {
  return type === MarketDataClassificationsType.MarketDataPrivate
}

export const isMarketDataProfessionalType = (type: MarketDataClassificationsType) => {
  return type === MarketDataClassificationsType.MarketDataProfessional
}
