import { IconProps, iconColor } from './IconProps'

export function InfoIcon(props: IconProps): JSX.Element {
  const { color = 'textInteractive', ...restProps } = props
  const size = props.size || 14

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='7' cy='7' r='6' stroke={iconColor({ color, ...restProps })} strokeWidth='1.5' />
      <path
        d='M7 6.57114V9.99971'
        stroke={iconColor({ color, ...restProps })}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M7 4.21432L7 4.00003'
        stroke={iconColor({ color, ...restProps })}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
