import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'

import { AccountType } from '../../model/AccountType'
import { LeadDto } from '../../model/LeadDto'
import { LeadResponseDto } from '../../model/LeadResponseDto'
import { FormValuesStep0 } from '../../pages/Signup/Pages/AccountType/AccountType'
import { CompanyDetailsStep1FormValues } from '../../pages/Signup/Pages/CompanyDetails/CompanyDetailsStep1/CompanyDetailsStep1Form'
import { CompanyDetailsStep2FormValues } from '../../pages/Signup/Pages/CompanyDetails/CompanyDetailsStep2/CompanyDetailsStep2Form'
import { CompanyDetailsStep3FormValues } from '../../pages/Signup/Pages/CompanyDetails/CompanyDetailsStep3/CompanyDetailsStep3Form'
import { CompanyDetailsStep4FormValues } from '../../pages/Signup/Pages/CompanyDetails/CompanyDetailsStep4/CompanyDetailsStep4Form'
import { FormValuesLegalDocs } from '../../pages/Signup/Pages/PersonalDetails/PersonalDetailsDocumentsFactory'
import { PersonalDetailsStep1FormValues } from '../../pages/Signup/Pages/PersonalDetails/PersonalDetailsStep1/PersonalDetailsStep1Form'
import { PersonalDetailsStep2FormValues } from '../../pages/Signup/Pages/PersonalDetails/PersonalDetailsStep2/PersonalDetailsStep2Form'
import { PersonalDetailsStep3FormValues } from '../../pages/Signup/Pages/PersonalDetails/PersonalDetailsStep3/PersonalDetailsStep3Form'
import { PersonalDetailsStep4FormValues } from '../../pages/Signup/Pages/PersonalDetails/PersonalDetailsStep4/PersonalDetailsStep4Form'
import { PersonalDetailsStep5FormValues } from '../../pages/Signup/Pages/PersonalDetails/PersonalDetailsStep5/PersonalDetailsStep5Form'
import { isTickmillEUType, isTickmillSCType, isTickmillUKType } from '../../utils/companyName.utils'
import { useSessionEntity } from './EntityContext'

enum SignupActionKind {
  APPEND_VALUES,
  OVERWRITE_VALUES,
  UPDATE_TOTAL_STEPS,
  UPDATE_CORPORATE_VALUES,
  SET_LEAD_RESPONSE,
  SET_LEAD,
}

export type SignupFormValues = FormValuesStep0 &
  PersonalDetailsStep1FormValues &
  PersonalDetailsStep2FormValues &
  PersonalDetailsStep3FormValues &
  PersonalDetailsStep4FormValues &
  PersonalDetailsStep5FormValues &
  FormValuesLegalDocs

export type CorporateFormValues = CompanyDetailsStep1FormValues &
  CompanyDetailsStep2FormValues &
  CompanyDetailsStep3FormValues &
  CompanyDetailsStep4FormValues

interface SignupAction {
  type: SignupActionKind
  count?: number
  values?: Partial<SignupFormValues>
  totalSteps?: {
    personalDetails?: number
    companyDetails?: number
  }
  corporateValues?: Partial<CorporateFormValues>
  leadResponse?: LeadResponseDto
  lead?: LeadDto
}

interface SignupState {
  totalSteps: {
    personalDetails: number
    companyDetails: number
  }
  values: SignupFormValues
  corporateValues: CorporateFormValues
  leadResponse?: LeadResponseDto
  lead?: LeadDto
}

interface SnackbarContextValue {
  signupData: SignupState
  appendValues: (values?: Partial<SignupFormValues>) => void
  resetLead: () => void
  resetValues: () => void
  updateCorpValues: (corpValues?: Partial<CorporateFormValues>) => void
  setLeadResponse: (leadResponse: LeadResponseDto) => void
  setLead: (lead?: LeadDto) => void
  updateTotalSteps: (totalSteps?: Partial<SignupAction['totalSteps']>) => void
}

const SignupContext = createContext<SnackbarContextValue | undefined>(undefined)

const initialState: SignupState = {
  totalSteps: {
    personalDetails: 6,
    companyDetails: 4,
  },
  values: { typeId: AccountType.Individual } as SignupFormValues,
  corporateValues: {} as CorporateFormValues,
}

const reducer = (state: SignupState, action: SignupAction): SignupState => {
  switch (action.type) {
    case SignupActionKind.APPEND_VALUES:
      return {
        ...state,
        values: {
          ...state.values,
          ...action.values,
        },
      }
    case SignupActionKind.OVERWRITE_VALUES:
      return {
        ...state,
        values: {
          ...action.values,
        } as SignupFormValues,
      }
    case SignupActionKind.UPDATE_CORPORATE_VALUES:
      return {
        ...state,
        corporateValues: {
          ...state.corporateValues,
          ...action.corporateValues,
        },
      }
    case SignupActionKind.SET_LEAD_RESPONSE:
      return {
        ...state,
        leadResponse: action.leadResponse ?? ({} as LeadResponseDto),
      }
    case SignupActionKind.SET_LEAD:
      return {
        ...state,
        lead: action.lead,
      }
    case SignupActionKind.UPDATE_TOTAL_STEPS:
      return {
        ...state,
        totalSteps: { ...state.totalSteps, ...action.totalSteps },
      }
    default:
      return state
  }
}

enum HasLegalDocumentsStep {
  No = 5,
  Yes = 6,
}

export enum HasEmirStep {
  No = 3,
  Yes = 4,
}

export const SignupContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [signupData, dispatch] = useReducer(reducer, initialState)

  const entity = useSessionEntity()

  useEffect(() => {
    const isTickmillSC =
      isTickmillSCType(entity) ||
      isTickmillSCType(signupData.lead?.tickmillCompany?.id) ||
      isTickmillSCType(signupData.values.tickmillCompanyId)

    const steps = isTickmillSC ? HasLegalDocumentsStep.No : HasLegalDocumentsStep.Yes

    dispatch({ type: SignupActionKind.UPDATE_TOTAL_STEPS, totalSteps: { personalDetails: steps } })
  }, [entity, signupData.lead?.tickmillCompany?.id, signupData.values.tickmillCompanyId])

  const appendValues = useCallback(
    (values?: Partial<SignupFormValues>) =>
      dispatch({ type: SignupActionKind.APPEND_VALUES, values }),
    []
  )

  const resetLead = useCallback(() => {
    dispatch({ type: SignupActionKind.SET_LEAD, lead: undefined })
    dispatch({ type: SignupActionKind.SET_LEAD_RESPONSE, leadResponse: undefined })
  }, [])

  const resetValues = useCallback(
    () => dispatch({ type: SignupActionKind.OVERWRITE_VALUES, values: initialState.values }),
    []
  )

  const updateCorpValues = useCallback(
    (corporateValues?: Partial<CorporateFormValues>) =>
      dispatch({ type: SignupActionKind.UPDATE_CORPORATE_VALUES, corporateValues }),
    []
  )

  const updateTotalSteps = useCallback(
    (totalSteps?: Partial<SignupAction['totalSteps']>) =>
      dispatch({ type: SignupActionKind.UPDATE_TOTAL_STEPS, totalSteps }),
    []
  )

  const setLeadResponse = useCallback((leadResponse: LeadResponseDto) => {
    dispatch({ type: SignupActionKind.SET_LEAD_RESPONSE, leadResponse })
  }, [])

  const setLead = useCallback(
    (lead?: LeadDto) => dispatch({ type: SignupActionKind.SET_LEAD, lead }),
    []
  )

  return (
    <SignupContext.Provider
      value={{
        signupData,
        updateCorpValues,
        appendValues,
        resetLead,
        resetValues,
        setLeadResponse,
        setLead,
        updateTotalSteps,
      }}
    >
      {children}
    </SignupContext.Provider>
  )
}

export const useSignup = (): SnackbarContextValue => {
  const context = useContext(SignupContext)
  if (context !== undefined) {
    return context
  }
  throw new Error('useSignup must be used within a SignupContextProvider')
}
