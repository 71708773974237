import { IconProps } from './IconProps'

export function AlertFilledIcon(props: IconProps): JSX.Element {
  const size = props.size || 24

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z'
        fill='#EF0000'
      />
      <path
        d='M13.5001 15.5C13.5001 16.3284 12.8285 17 12.0001 17C11.1717 17 10.5001 16.3284 10.5001 15.5C10.5001 14.6716 11.1717 14 12.0001 14C12.8285 14 13.5001 14.6716 13.5001 15.5Z'
        fill='white'
      />
      <path
        d='M10.3289 8.97279C10.1568 7.94009 10.9531 7 12.0001 7C13.047 7 13.8434 7.94009 13.6713 8.97279L13.1645 12.0136C13.0696 12.5828 12.5771 13 12.0001 13C11.423 13 10.9306 12.5828 10.8357 12.0136L10.3289 8.97279Z'
        fill='white'
      />
    </svg>
  )
}
