import { useSessionLanguage } from '../../global/context/SessionSettingsContext'

export const useLoyaltyTermsUrl = (): string => {
  const locale = useSessionLanguage()

  if (locale == 'zh') {
    return `https://d1fyjtrsl71uh.cloudfront.net/collections/campaign-tc/ib-loyalty/ib-loyalty-tc-cn.pdf`
  }

  return `https://d1fyjtrsl71uh.cloudfront.net/collections/campaign-tc/ib-loyalty/ib-loyalty-tc-${locale}.pdf`
}
