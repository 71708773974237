import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { CloseIcon } from '../../icons/CloseIcon'
import { WarningIcon } from '../../icons/WarningIcon'

import styles from './SystemNotification.module.scss'

interface SystemNotificationProps {
  title: string
  id?: string
  close?: () => void
}
export const SystemNotification = (props: SystemNotificationProps) => {
  const { title, close, id } = props
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={styles.message}>
      <div className={classNames('container', styles.innerWrapper)}>
        <WarningIcon color='textOnPrimaryColor' />
        <p>
          {title}
          {id && (
            <span className={styles.readMore} onClick={() => navigate(`notifications/${id}`)}>
              {t('Notifications.Read more')}
            </span>
          )}
        </p>
        {!!close && (
          <span onClick={close} className='is-clickable'>
            <CloseIcon color='textOnPrimaryColor' />
          </span>
        )}
      </div>
    </div>
  )
}
