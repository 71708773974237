import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useArabicSessionLanguage } from '../../global/context/SessionSettingsContext'
import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { ClientRebateCampaignDto } from '../../model/CampaignResultDto'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { wait } from '../../utils/wait'
import { CampaignStatus } from './campaign.utils'

import styles from './CampaignResultCard.module.scss'

interface CampaignResultCardProps {
  campaignResult: ClientRebateCampaignDto
  cardId?: string

  setSelectedCampaign(campaign: ClientRebateCampaignDto): void
}

export const CampaignResultCard: React.FC<CampaignResultCardProps> = (props) => {
  const { campaignResult, setSelectedCampaign, cardId } = props

  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [auth] = useContext(AuthSessionContext)
  const { scrollIntoView } = useScrollToElementIds()
  const isArabic = useArabicSessionLanguage()
  const dateFormat = auth?.dateFormatType?.name

  return (
    <div
      onClick={() => {
        if (!expanded && cardId) {
          scrollIntoView([cardId])
        }
        setExpanded((prevState) => !prevState)
      }}
      className={styles.card}
      id={cardId}
    >
      <div className={styles.title}>
        <TextSmallStrong className={styles.text} dir={isArabic ? 'rtl' : ''}>
          {campaignResult.name}
        </TextSmallStrong>
        <CampaignStatus {...campaignResult} />
      </div>
      {expanded && (
        <Fragment>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('Campaigns.Start Date')}</span>
            <span className={styles.primary}>
              {formatDate(campaignResult.from, { formatType: dateFormat, withTime: false })}
            </span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('Campaigns.End Date')}</span>
            <span className={styles.primary}>
              {formatDate(campaignResult.to, { formatType: dateFormat, withTime: false })}
            </span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('Campaigns.Currency')}</span>
            <span className={styles.primary}>{campaignResult.currency.id}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('Campaigns.Campaign Account No')}</span>
            <span
              className={classNames({
                [styles.strikeThrough]: campaignResult.disqualified,
                [styles.primary]: !campaignResult.disqualified,
              })}
            >
              {campaignResult.account && `${campaignResult.currency.id}-${campaignResult.account}`}
            </span>
          </div>

          <div className='container'>
            <div className='is-flex is-fullwidth is-justify-content-space-between mt-2'>
              <span className='is-flex is-justify-content-flex-end is-flex-grow-1'>
                <Button
                  appearance='link'
                  onClick={() => {
                    scrollIntoView([ScrollToIds.CampaignResultsHeader])
                    wait(500).then(() => setSelectedCampaign(campaignResult))
                  }}
                >
                  <TextSmall>{t('Campaigns.View results')}</TextSmall>
                </Button>
              </span>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  )
}
