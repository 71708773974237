import { IconProps, iconColor } from './IconProps'

export function EditIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.9575 1.04347C19.8648 1.07718 19.713 1.14038 19.6203 1.19094C19.5149 1.24572 17.5671 3.167 14.5358 6.2006L9.61989 11.1218L9.29104 12.765C9.00857 14.1975 8.97484 14.425 9.01278 14.5599C9.06759 14.741 9.29526 14.9559 9.47233 14.9981C9.55665 15.0149 10.1132 14.9222 11.1124 14.7242C11.9429 14.5599 12.6891 14.4082 12.7692 14.3871C12.8873 14.3534 13.8907 13.3717 17.8538 9.40695C23.4062 3.84956 22.9931 4.31303 22.9931 3.6431C22.9889 3.06588 22.9087 2.93105 21.9897 2.01254C21.3699 1.39318 21.176 1.22465 20.9778 1.13617C20.6869 1.00134 20.2147 0.959208 19.9575 1.04347ZM21.0916 2.92262C21.665 3.49564 21.7578 3.60518 21.7367 3.68945C21.7198 3.74422 21.5385 3.95911 21.3277 4.16977L20.9483 4.54897L20.1979 3.799L19.4516 3.05324L19.8564 2.65297C20.084 2.42966 20.2948 2.2527 20.3412 2.2527C20.3876 2.2527 20.6953 2.52236 21.0916 2.92262ZM19.3041 4.71751L20.0419 5.45484L16.1673 9.3269L12.2928 13.199L11.3906 13.3759C10.8973 13.477 10.48 13.5445 10.4631 13.5318C10.4504 13.515 10.5179 13.1021 10.6149 12.6091L10.7919 11.7116L14.658 7.8438C16.7829 5.72028 18.5325 3.98017 18.5452 3.98017C18.5578 3.98017 18.8993 4.31303 19.3041 4.71751Z'
        fill={iconColor(props)}
      />
      <path
        d='M12.5 4.5H4.5C3.94772 4.5 3.5 4.94772 3.5 5.5V19.5C3.5 20.0523 3.94772 20.5 4.5 20.5H18.5C19.0523 20.5 19.5 20.0523 19.5 19.5V11.5'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
