import { errorOptions } from '../../routing/RouterToaster'

export const getDocUploadFailMessage = (errorMessage: string) => {
  switch (errorMessage) {
    case errorOptions.documents_not_provided:
      return 'errors.Documents were not provided'
    case errorOptions.document_filename_exists:
      return 'errors.Document name has already been uploaded'
    case errorOptions.document_already_uploaded:
      return 'errors.The Document has already been uploaded'
    case errorOptions.invalid_document:
      return "errors.We couldn't read your document"
    case errorOptions.invalid_document_status:
      return "errors.This document has been removed and can't be updated"
    case errorOptions.invalid_document_status_reason:
      return "errors.This document has been removed and can't be updated"
    case errorOptions.invalid_document_category:
      return 'errors.Check your category choice'
    case errorOptions.upload_file_max_size_limit:
      return 'errors.Cannot upload file because its size is over allowed limit'
    case errorOptions.client_not_found:
      return 'errors.Client not found'
    default:
      return 'Upload failed'
  }
}
