import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { StepCircle } from '../../../global/StepCircle/StepCircle'
import { FormTemplate } from '../../../global/templates/FormTemplate'
import { BackIcon } from '../../../icons/BackIcon'
import { Text, TextSmall, TextSmallStrong } from '../../../ui/Typography/Typography'

import styles from './CreateDemoTradingAccountSuccessPage.module.scss'

export const CreateDemoTradingAccountSuccessPage: React.FC = () => {
  const { platformType } = useParams()
  const navigate = useNavigate()

  if (platformType === 'mt4' || platformType === 'mt5') {
    return <MetaTraderSuccessPage />
  } else if (platformType === 'tickmilltrader') {
    return <TickmillTraderSuccessPage />
  } else {
    navigate('/dashboard')
    return null
  }
}

const MetaTraderSuccessPage = () => {
  const { t } = useTranslation()
  const { platformType } = useParams()
  const location = useLocation()
  const urlParams = new URLSearchParams(location.search)
  const serverName = urlParams.get('serverName')
  const serverDns = urlParams.get('serverDns')

  const getPlatformLink = () => {
    if (platformType === 'mt4') {
      return '/dashboard/tools/platforms/mt4'
    }
    if (platformType === 'mt5') {
      return '/dashboard/tools/platforms/mt5'
    }
    if (platformType === 'tickmilltrader') {
      return '/dashboard/tools/platforms/tickmilltrader'
    }
    return '/dashboard'
  }
  const navigate = useNavigate()

  return (
    <FormTemplate
      title={t('Trading Account.Demo Trading Account Created!')}
      goBackIcon={<BackIcon />}
      goBack={() => navigate('/dashboard/traders-room/trading-accounts')}
      wrapped
      titleClassName={styles.title}
      subtitle={t(
        'Trading Account.You will receive a confirmation email with your demo trading account credentials'
      )}
      bottomText={t('Trading Account.Please note that your demo trading account credentials')}
    >
      <div className={styles.contentWrapper}>
        <Text className='text-align-center'>
          {t('Trading Account.Please, follow the instructions below')}
        </Text>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={1} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            <Link className={styles.link} to={getPlatformLink()}>
              {t('Trading Account.Download')}
            </Link>{' '}
            {t('and')} <TextSmallStrong>{t('Trading Account.install')}</TextSmallStrong>{' '}
            {t('Trading Account.the MetaTrader trading platform', {
              platform: platformType === 'mt4' ? '4' : '5',
            })}
          </TextSmall>
        </div>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={2} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            <TextSmallStrong>{t('Trading Account.Login')}</TextSmallStrong>{' '}
            {t(
              'Trading Account.into your trading platform with your demo trading account credentials'
            )}
          </TextSmall>
        </div>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={3} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            {t('Trading Account.During login, please choose')}{' '}
            <TextSmallStrong>{serverName ? serverName : 'Tickmill-Demo'}</TextSmallStrong>{' '}
            {t('Trading Account.from the servers list or type')}{' '}
            <TextSmallStrong>
              {serverDns ? serverDns : `demo01.mt${platformType}tickmill.com`}
            </TextSmallStrong>
          </TextSmall>
        </div>
        <div className={styles.contentText}>
          <StepCircle isActive={false} section={4} isRed={true} isSmall={true} />
          <TextSmall>
            <TextSmallStrong>{t('Trading Account.Practice trading')}</TextSmallStrong>{' '}
            {t('Trading Account.without any risks')}
          </TextSmall>
        </div>
      </div>
    </FormTemplate>
  )
}

const TickmillTraderSuccessPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <FormTemplate
      title={t('Trading Account.Tickmill Trader Account Created')}
      goBackIcon={<BackIcon />}
      goBack={() => navigate('/dashboard/traders-room/trading-accounts')}
      wrapped
      titleClassName={styles.title}
      subtitle={t(
        'Trading Account.You will receive a confirmation email with your username and password tickmillTrader'
      )}
    >
      <div className={styles.contentWrapper}>
        <Text className='text-align-center'>
          {t('Trading Account.Please, follow the instructions below')}
        </Text>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={1} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            <Link className={styles.link} to={'/dashboard/tools/platforms/tickmilltrader'}>
              {t('Trading Account.Download')}
            </Link>{' '}
            {t('and')} <TextSmallStrong>{t('Trading Account.install')}</TextSmallStrong>{' '}
            {t('Trading Account.the Tickmill Trader Mobile App')}
          </TextSmall>
        </div>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={2} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            {t('Trading Account.Access the app with your login username and password')}
          </TextSmall>
        </div>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={3} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            {t(
              'Trading Account.Click on “menu” on the bottom of the screen to switch between Live and Demo Accounts'
            )}
          </TextSmall>
        </div>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={4} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            {t(
              'Trading Account.Trade without any risks on your Demo, or fund your account and start trading'
            )}
          </TextSmall>
        </div>
      </div>
    </FormTemplate>
  )
}
