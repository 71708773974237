import { useCallback } from 'react'

import { LandingPageDto } from '../../../model/LandingPageDto'
import { PromoOptionCategoryEnum } from '../../../model/PromoOptionCategoryEnum'
import { PageData, useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { getDomainName } from '../../../utils/cookie.utils'
import { useFetchOne } from '../../../utils/useFetch'

export interface LandingProps {
  landingPages: PageData<LandingPageDto> | undefined
  isLoadingLandingPages: boolean
}

export const useLandingPageFetch = (language: string): LandingProps => {
  const apiClient = useApiClient(ClientApiClient)

  const landingPageCallback = useCallback(async () => {
    return apiClient.getLandingPages({
      promoMaterialCategoryId: PromoOptionCategoryEnum.LINKS,
      query: {
        pageSize: 100,
        Domain: getDomainName(true),
        search: {
          LanguageId: language,
        },
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])
  const { data, isLoading } = useFetchOne(landingPageCallback)

  return {
    landingPages: data,
    isLoadingLandingPages: isLoading,
  }
}
