import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { Locale } from '../global/locale/Locale'
import { CurrencyType } from '../model/WalletDto'
import { getCurrencySymbol } from '../utils/transaction.utils'

export interface FormatMoney {
  (number?: number, currency?: CurrencyType | string, decimalPlaces?: number): string
}

export interface FormatNumber {
  (number?: number, decimalPlaces?: number): string
}

interface UseFormatNumber {
  formatMoney: FormatMoney
  formatNumber: FormatNumber
}

export const useFormatNumber = (): UseFormatNumber => {
  const locale = useSessionLanguage()

  const formatMoney = (
    number = 0,
    currency: CurrencyType | string = 'USD',
    decimalPlaces = 2
  ): string => {
    let formatted: string
    try {
      formatted = new Intl.NumberFormat(commonLocaleMapping[locale], {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: decimalPlaces ?? 2,
      }).format(number ?? 0)
    } catch (error: unknown) {
      formatted = ''
    }

    if (formatted.includes(currency)) {
      const symbol = getCurrencySymbol(currency as CurrencyType)
      const absoluteNumber = Math.abs(number)
      formatted = (number < 0 ? `-${symbol}` : symbol) + absoluteNumber.toFixed(decimalPlaces ?? 2)
    }

    return formatted
  }

  const formatNumber = (number = 0, decimalPlaces = 2): string => {
    return (number ?? 0).toLocaleString(locale, {
      minimumFractionDigits: 2,
      maximumFractionDigits: decimalPlaces ?? 2,
    })
  }

  return {
    formatMoney,
    formatNumber,
  }
}

export type LocaleMapping = {
  [key in Locale]: string
}

export const commonLocaleMapping: LocaleMapping = {
  ar: 'ar',
  de: 'de-DE',
  en: 'en-US',
  es: 'es-ES',
  id: 'id-ID',
  it: 'it-IT',
  ko: 'ko-KR',
  ms: 'ms-MY',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ru: 'ru-RU',
  th: 'th-TH',
  tr: 'tr-TR',
  vi: 'vi-VN',
  zh: 'zh-CN',
}
