import React, { useEffect, useMemo, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import useCartItemsData, {
  CartLinkPersistedKey,
  CartPersistedKey,
} from '../../../Subscriptions/hooks/useCartItemsData'
import { BasketCircledIcon } from '../../../icons/BasketCircledIcon'
import { NotificationCircledIcon } from '../../../icons/NotificationCircledIcon'
import { TMEntityLogoFactory } from '../../../icons/TickmillLogoIcons'
import { UserCircledIcon } from '../../../icons/UserCircledIcon'
import { isTickmillProductTypeETD } from '../../../model/TickmillProductType'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { ProductSwitch } from '../../../ui/ProductSwitch/ProductSwitch'
import { isAccountDocumentAllowedUpload } from '../../../utils/AccountAccess/accountDocumentStatuses'
import { requireDepositBeforeKYC } from '../../../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { useNotificationFunctionality, useNotificationUnread } from '../../../utils/notifications'
import { useBasePath } from '../../../utils/path'
import { useOnClickedOutside } from '../../../utils/useOnClickedOutside'
import { useLocallyPersistedState } from '../../../utils/useStorage'
import { CookieConsent } from '../../CookieConsent/CookieConsent'
import { useSessionEntity } from '../../context/EntityContext'
import { useProductReadContext } from '../../context/ProductContext'
import IconButton from '../../iconButton/IconButton'
import { LanguageVariants } from '../../localeSelector/LanguageVariants'
import { LocaleSelector } from '../../localeSelector/LocaleSelector'
import { NavAccountMenu } from '../NavAccountMenu'
import { NavBarMenu } from '../NavBarMenu'

import styles from './DesktopNav.module.scss'

export const DesktopNav: React.FC = () => {
  const location = useLocation()
  const { product } = useProductReadContext()
  const redirectionLink = useBasePath(true)
  const entity = useSessionEntity()

  useCartItemsData()

  const [isAccountMenuOpen, setAccountMenuOpen] = useState(false)

  useEffect(() => setAccountMenuOpen(false), [location])

  return (
    <div className={styles.navWrapper}>
      <CookieConsent />
      <nav
        className={classNames(styles.nav, {
          [styles.isETDSelected]: isTickmillProductTypeETD(product),
        })}
        role='navigation'
        aria-label='main navigation'
      >
        <div className={classNames('container', styles.navInner)}>
          <div className={styles.navLogo}>
            <Link to={redirectionLink}>
              <TMEntityLogoFactory variant={entity} />
            </Link>
            <ProductSwitch />
          </div>
          <div>
            <div className={styles.navMenu}>
              <NavBarMenu />
              <LocaleSelector />
              <BarActionMenu
                isAccountMenuOpen={isAccountMenuOpen}
                onAccountMenuOpen={setAccountMenuOpen}
              />
            </div>
            <LanguageVariants />
            <NavAccountMenu isOpen={isAccountMenuOpen} close={() => setAccountMenuOpen(false)} />
          </div>
        </div>
      </nav>
    </div>
  )
}

interface BarActionMenuProps {
  isAccountMenuOpen: boolean
  onAccountMenuOpen: (value: boolean) => void
}

const BarActionMenu: React.FC<BarActionMenuProps> = (props) => {
  const { isAccountMenuOpen, onAccountMenuOpen } = props

  const { account } = useAccountReadContext()

  const { isDefaultCFDProductType } = useProductReadContext()
  const isProductTypeCFD = isDefaultCFDProductType()
  const navigate = useNavigate()

  const { product } = useProductReadContext()
  const { hasUnread } = useNotificationUnread()
  const { enableNotifications } = useNotificationFunctionality()

  const [hasCartItems] = useLocallyPersistedState<boolean>(CartPersistedKey, false)
  const [cartLink] = useLocallyPersistedState<string>(CartLinkPersistedKey, '')

  const onClickOutside = useOnClickedOutside<HTMLDivElement>(() => onAccountMenuOpen(false))

  const color = isTickmillProductTypeETD(product) ? 'alwaysWhite' : undefined

  const requiresAction = useMemo(
    () => isAccountDocumentAllowedUpload(account) || requireDepositBeforeKYC(account),
    [account]
  )

  return (
    <div className={styles.barButtonWrapper}>
      {!isProductTypeCFD && (
        <div className={styles.barButtonGroup}>
          <IconButton onClick={() => navigate(cartLink)} className={styles.menuAction}>
            <AlertIconWrapper isActive={!!hasCartItems}>
              <BasketCircledIcon color={color} size={40} />
            </AlertIconWrapper>
          </IconButton>
        </div>
      )}
      {enableNotifications && (
        <div className={styles.barButtonGroup}>
          <IconButton onClick={() => navigate('/notifications')} className={styles.menuAction}>
            <AlertIconWrapper isActive={!!hasUnread}>
              <NotificationCircledIcon color={color} size={40} />
            </AlertIconWrapper>
          </IconButton>
        </div>
      )}
      <div className={styles.barButtonGroup}>
        <div className={styles.menuAction} ref={onClickOutside}>
          <IconButton
            onClick={() => onAccountMenuOpen(!isAccountMenuOpen)}
            className={styles.menuAction}
          >
            <AlertIconWrapper isActive={requiresAction}>
              <UserCircledIcon color={color} size={40} />
            </AlertIconWrapper>
          </IconButton>
        </div>
      </div>
    </div>
  )
}
