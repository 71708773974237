import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

import { useScrollToTop } from '../../utils/useScrollToTop'
import { VendorType, Vendors } from '../Vendors/Vendors'
import CmeGroupDesktopLogo from '../images/CMEGROUP-dekstop.png'
import EurexDesktopLogo from '../images/EUREX-desktop.png'

export const MarketDataVendors: React.FC = () => {
  useScrollToTop()

  const { t } = useTranslation()

  return <Vendors vendors={platfomVendorsData(t)} title={t('Market Data.Market Data Vendors')} />
}

export const platfomVendorsData = (t: TFunction): VendorType[] => [
  {
    name: 'CME Group Bundle',
    imageUrl: CmeGroupDesktopLogo,
    url: 'https://www.cmegroup.com/company/cme.html',
    description: t('Market Data.The CME Group bundle includes and covers a complete'),
  },
  {
    name: 'CME Standalone Market Data',
    imageUrl: CmeGroupDesktopLogo,
    url: 'https://www.cmegroup.com/company/cme.html',
    description: t('Market Data.The Chicago Mercantile Exchange (CME), often called'),
  },
  {
    name: 'CBOT Standalone Market Data',
    imageUrl: CmeGroupDesktopLogo,
    url: 'https://www.cmegroup.com/company/cbot.html',
    description: t('Market Data.The Chicago Board of Trade (CBOT), established in'),
  },
  {
    name: 'COMEX Standalone Market Data',
    imageUrl: CmeGroupDesktopLogo,
    url: 'https://www.cmegroup.com/company/comex.html',
    description: t('Market Data.The Commodity Exchange (COMEX) is the world’s largest'),
  },
  {
    name: 'NYMEX Standalone Market Data',
    imageUrl: CmeGroupDesktopLogo,
    url: 'https://www.cmegroup.com/company/nymex.html',
    description: t('Market Data.The New York Mercantile Exchange (NYMEX) is the'),
  },
  {
    name: 'EUREX',
    imageUrl: EurexDesktopLogo,
    url: 'https://www.eurex.com/ex-de/',
    description: t('Market Data.The Eurex (European Exchange) is one of the largest'),
  },
]
