import { useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { FirstDepositPage } from '../FirstDeposit/FirstDepositPage'
import { useIBRoutes } from '../IntroducingBroker/IntroducingBrokerRoutes'
import { useLearningRoutes } from '../Learning/LearningRoutes'
import { usePARoutes } from '../PaymentAgent/PaymentAgentRoutes'
import { DepositRedirectFromProviderPage } from '../PaymentProviderRedirect/DepositRedirectFromProviderPage/DepositRedirectFromProviderPage'
import { ProductAppropriatenessTestPage } from '../ProductRegistration/AppropriatenessTest/ProductAppropriatenessTestPage'
import { ProductLegalDocumentsPage } from '../ProductRegistration/LegalDocuments/ProductLegalDocumentsPage'
import { ProductPersonalDetailsPage } from '../ProductRegistration/ProductPersonalDetails/ProductPersonalDetailsPage'
import { ProductLegalDocumentsStepPage } from '../ProductRegistration/ProductRegistrationStepsPage/ProductLegalDocumentsStepPage'
import { useProfileRoutesConditions } from '../Profile/ProfileRoutes'
import { StatementPage } from '../Statements/Statement'
import { StatementsETD } from '../Statements/StatementsETD'
import { useSubscriptionRoutes } from '../Subscriptions/SubscriptionsRoutes'
import { useToolsRoutes } from '../Tools/ToolsRoutes'
import {
  useTraderRoomRoutes,
  useTraderRoomRoutesConditions,
} from '../Traders-Room/TradersRoomRoutes'
import { FirstAppropriateTest } from '../WarningAccount/FirstAppropriateTest'
import { WarningAccountPage } from '../WarningAccount/WarningAccountPage'
import { useProductReadContext } from '../global/context/ProductContext'
import { showClosedAccountPage, showInactiveAccountPage } from '../model/AccountDetailedDto'
import { isAccountActivatedStatus } from '../model/AccountDetailedStatus'
import { StatementType } from '../model/Statements'
import { WalletTypeEnum } from '../model/WalletDto'
import { RouteConfig } from '../routing/RenderRoutes'
import { getAccountAccessStatuses } from '../utils/AccountAccess/accountAccessStatuses'
import { hasConsentedToLegalDocuments } from '../utils/AccountAccess/accountDocumentStatuses'
import { isKYCUpdateDue } from '../utils/AccountAccess/accountKYCStatuses'
import {
  hasProductEmailVerifiedStatus,
  isProductAppropriatenessValid,
} from '../utils/AccountAccess/accountProductStatuses'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { useFirstDepositContext } from '../utils/FirstDepositContext'
import { useLandingPage } from '../utils/useLandingPage'
import { Dashboard } from './Dashboard'

export const useDashboardRoutes = (): RouteConfig[] => {
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()
  const { isFirstDepositVisible, isDashboardVisible } = useDashboardRoutesConditions()

  const traderRoomRoutes = useTraderRoomRoutes()
  const { isTradersRoomVisible } = useTraderRoomRoutesConditions()
  const ibRoutes = useIBRoutes()
  const paRoutes = usePARoutes()
  const toolsRoutes = useToolsRoutes()
  const learningRoutes = useLearningRoutes()
  const subscriptionsRoutes = useSubscriptionRoutes()

  const { isDefaultCFDProductType } = useProductReadContext()
  const { isAccountActivatedStatus } = getAccountAccessStatuses(account, product)
  const walletType = isDefaultCFDProductType() ? WalletTypeEnum.CFD : WalletTypeEnum.ETD
  const { getLandingPage } = useLandingPage()

  return useMemo(
    (): RouteConfig[] => [
      {
        path: 'payments',
        children: [
          {
            path: 'redirect-to-transactions',
            children: [
              {
                path: ':statusId',
                element: <DepositRedirectFromProviderPage />,
              },
            ],
          },
        ],
      },
      {
        path: 'dashboard',
        outlet: <Dashboard />,
        element: <Navigate to={getLandingPage()} />,
        condition: isDashboardVisible,
        children: [
          {
            path: 'closed-account',
            condition: showClosedAccountPage(account, product),
            element: <WarningAccountPage status={'Closed'} />,
          },
          {
            path: 'closed-account',
            condition: showInactiveAccountPage(account, product),
            element: <WarningAccountPage status={'Inactive'} />,
          },
          {
            path: 'legal-documents',
            condition: !hasConsentedToLegalDocuments(account, product) || isAccountActivatedStatus,
            element: <ProductLegalDocumentsPage />,
          },
          {
            path: 'take-app-test',
            condition: hasProductEmailVerifiedStatus(account, product),
            element: <FirstAppropriateTest />,
          },
          {
            path: 'update-kyc',
            element: <ProductPersonalDetailsPage />,
          },
          {
            path: 'first-deposit',
            condition: isFirstDepositVisible,
            element: <FirstDepositPage walletType={walletType} />,
          },
          ...traderRoomRoutes,
          ...ibRoutes,
          ...paRoutes,
          ...toolsRoutes,
          ...learningRoutes,
          ...subscriptionsRoutes,
          {
            path: 'statements',
            outlet: <StatementsETD />,
            condition: isTradersRoomVisible,
            children: [
              {
                path: 'daily',
                element: <StatementPage type={StatementType.Daily} />,
              },
              {
                path: 'monthly',
                element: <StatementPage type={StatementType.Monthly} />,
              },
            ],
          },
        ],
      },
      {
        path: 'dashboard',
        outlet: <Dashboard />,
        element: <Navigate to={getLandingPage()} />,
        children: [
          {
            path: 'product-registration',
            children: [
              {
                path: 'legal-documents',
                condition: !hasConsentedToLegalDocuments(account, product),
                element: <ProductLegalDocumentsStepPage />,
              },
              {
                path: 'appropriateness-test',
                condition: isProductAppropriatenessValid(product, account),
                element: <ProductAppropriatenessTestPage />,
              },
              {
                path: 'kyc-personal-details',
                condition: isKYCUpdateDue(account),
                element: <ProductPersonalDetailsPage />,
              },
            ],
          },
        ],
      },
    ],
    [
      getLandingPage,
      isDashboardVisible,
      account,
      product,
      isAccountActivatedStatus,
      isFirstDepositVisible,
      walletType,
      traderRoomRoutes,
      ibRoutes,
      paRoutes,
      toolsRoutes,
      learningRoutes,
      subscriptionsRoutes,
      isTradersRoomVisible,
    ]
  )
}

interface RoutesConditions {
  isFirstDepositVisible: boolean
  isDashboardVisible: boolean
}

export const useDashboardRoutesConditions = (): RoutesConditions => {
  const { account } = useAccountReadContext()

  const { hasFirstDeposit } = useFirstDepositContext()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { isOnlyKYCUpdateVisible } = useProfileRoutesConditions()

  const isProductTypeCFD = isDefaultCFDProductType()

  const isFirstDepositVisible = useMemo(
    () => isAccountActivatedStatus(account) && !hasFirstDeposit && isProductTypeCFD,
    [account, hasFirstDeposit, isProductTypeCFD]
  )

  const isDashboardVisible = useMemo(() => !isOnlyKYCUpdateVisible, [isOnlyKYCUpdateVisible])

  return {
    isFirstDepositVisible,
    isDashboardVisible,
  }
}
