import { IconProps } from './IconProps'

export function PlatformInfoIcon(props: IconProps) {
  const size = props.size || 18

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9 16C12.866 16 16 12.866 16 9C16 5.13401 12.866 2 9 2C5.13401 2 2 5.13401 2 9C2 12.866 5.13401 16 9 16Z'
        fill='#BEBEBE'
      />
      <path
        d='M10.125 11.625C10.125 12.2463 9.62133 12.75 9.00001 12.75C8.37869 12.75 7.87501 12.2463 7.87501 11.625C7.87501 11.0037 8.37869 10.5 9.00001 10.5C9.62133 10.5 10.125 11.0037 10.125 11.625Z'
        fill='white'
      />
      <path
        d='M7.74661 6.72959C7.61752 5.95506 8.2148 5.25 9.00001 5.25C9.78522 5.25 10.3825 5.95506 10.2534 6.72959L9.87331 9.0102C9.80216 9.43711 9.4328 9.75 9.00001 9.75C8.56722 9.75 8.19786 9.43711 8.12671 9.0102L7.74661 6.72959Z'
        fill='white'
      />
    </svg>
  )
}
