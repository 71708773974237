import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { TFunction } from 'i18next'

import { Badge } from '../../global/badge/Badge'
import { Button } from '../../global/button/Button'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { InfoIcon } from '../../icons/InfoIcon'
import { SubscriptionCartItem } from '../../model/SubscriptionCart'
import { SubscriptionRequestState } from '../../model/SubscriptionRequestState'
import { SubscriptionRequestTypeEnum } from '../../model/SubscriptionRequestTypeEnum'
import {
  Subscription,
  SubscriptionDetail,
  isCancellationRequested,
  isSubscriptionActive,
  isSubscriptionDeductingFundOrInProcess,
  isSubscriptionImmediateAndPending,
  isSubscriptionPending,
} from '../../model/SubscriptionTypes'
import {
  SubscriptionRequestType,
  SubscriptionTimeInForce,
  UpdateSubscriptionRequest,
} from '../../model/UpdateSubscriptionRequest'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate, isLastBusinessDay } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { SubscriptionTimeInForceModal } from '../SubscriptionTimeInForceTypeModal'
import { UnsubscribeAttentionConfirmationModal } from '../UnsubscribeAttentionModal'
import { ConfirmCancelSubscriptionModal } from './ConfirmCancelSubscriptionModal'
import { PendingUnsubscriptionModal } from './PendingUnsubscriptionModal'
import { CancelOrProcessingButton } from './ProductActions'
import { ResubscribeModal } from './ResubscribeModal'
import { SubscriptionDetailMessage } from './SubscriptionDetailMessage'
import { SubscriptionDetailModal } from './SubscriptionDetailModal'
import { ProductStatusIcon } from './SubscriptionGroup'
import { UnsubscribeConfirmationModal } from './UnsubscribeConfirmationModal'
import {
  allowSubDowngrade,
  allowSubUpgrade,
  getActiveSubscriptionLevel,
  getNextSubLevel,
  getPendingCancelSubscriptionLevel,
  getPendingUpdateSubscriptionLevel,
  getPreviousSubLevel,
  getSubscriptionDefaultLevel,
  hasActivatingSubscription,
  hasInProcessDowngrade,
  hasInProcessUpgrade,
  hasLevelInCart,
  hasPendingDowngrade,
  hasPendingUnsubscriptionRequest,
  hasPendingUpgrade,
  isLevelDowngrade,
} from './subscriptionUtils'

import styles from './MarketDataPage.module.scss'

interface SubscriptionGroupDetailProps {
  subscription: Subscription
  selectedSubscriptionDetail?: SubscriptionDetail
  handleOnSubscriptionDetailChange(subscriptionDetail: SubscriptionDetail): void
  setPendingUnsubscriptionInfoModal(visible: boolean): void
  handleOnSetConfirmCancelSubscriptionModal: (subscriptionDetail?: SubscriptionDetail) => void
  nonActiveSubscriptions: SubscriptionCartItem[]
}

const renderNewSubscriptionPending = (
  amountPerMonth: string,
  dueDate: string,
  t: TFunction,
  dateFormat?: string
) => (
  <div>
    <p>{amountPerMonth}</p>
    <p className='has-text-warning'>
      <TextSmall>
        {t('Subscriptions.Starting', {
          firstDateOfNextMonth: formatDate(dueDate, { formatType: dateFormat }),
        })}
      </TextSmall>
    </p>
  </div>
)

const renderCancelExistingSubscription = (
  amountPerMonth: string,
  dueDate: string,
  setPendingUnsubscriptionInfoModal: (value: boolean) => void,
  t: TFunction,
  dateFormat?: string
) => {
  return (
    <div>
      <span>{amountPerMonth}</span>
      <TextSmall className='pl-2 has-text-success'>
        {t('Subscriptions.Active until', {
          date: formatDate(dueDate, { formatType: dateFormat }),
        })}
      </TextSmall>
      <div className='is-flex mt-1'>
        <TextSmall className='has-text-warning'>
          {t('Subscriptions.Pending Unsubscription')}{' '}
        </TextSmall>
        <span
          className='ml-2 pt-1'
          onClick={() => {
            setPendingUnsubscriptionInfoModal(true)
          }}
        >
          <InfoIcon />
        </span>
      </div>
    </div>
  )
}

const SubscriptionGroupDetail: React.FC<SubscriptionGroupDetailProps> = ({
  subscription,
  selectedSubscriptionDetail,
  handleOnSubscriptionDetailChange,
  handleOnSetConfirmCancelSubscriptionModal,
  setPendingUnsubscriptionInfoModal,
  nonActiveSubscriptions,
}) => {
  const { t } = useTranslation()
  const [newLevel, setNewLevel] = useState<SubscriptionDetail>()
  const isLevelSelectionDisabled = useMemo(
    () =>
      hasPendingUnsubscriptionRequest(subscription) ||
      hasActivatingSubscription(subscription) ||
      hasLevelInCart(subscription, nonActiveSubscriptions),
    [subscription, nonActiveSubscriptions]
  )
  const pendingUpgrade = useMemo(
    () => hasPendingUpgrade(subscription, selectedSubscriptionDetail),
    [subscription, selectedSubscriptionDetail]
  )
  const pendingDowngrade = useMemo(
    () => hasPendingDowngrade(subscription, selectedSubscriptionDetail),
    [subscription, selectedSubscriptionDetail]
  )

  const allowUpgrade = useMemo(
    () => !isLevelSelectionDisabled && allowSubUpgrade(subscription, selectedSubscriptionDetail),
    [isLevelSelectionDisabled, subscription, selectedSubscriptionDetail]
  )

  const allowDowngrade = useMemo(
    () => !isLevelSelectionDisabled && allowSubDowngrade(subscription, selectedSubscriptionDetail),
    [isLevelSelectionDisabled, subscription, selectedSubscriptionDetail]
  )

  const inProcessUpgrade = useMemo(
    () => hasInProcessUpgrade(subscription, selectedSubscriptionDetail),
    [subscription, selectedSubscriptionDetail]
  )

  const inProcessDowngrade = useMemo(
    () => hasInProcessDowngrade(subscription, selectedSubscriptionDetail),
    [subscription, selectedSubscriptionDetail]
  )

  const handleOnSelectSubscriptionDetail = (subscriptionDetail: SubscriptionDetail) => {
    if (subscriptionDetail) {
      handleOnSubscriptionDetailChange(subscriptionDetail)
    }
    setNewLevel(undefined)
  }

  return (
    <>
      {newLevel && (
        <SubscriptionDetailModal
          subscription={subscription}
          handleOnSelectSubscriptionDetail={handleOnSelectSubscriptionDetail}
          selectedSubscriptionDetail={newLevel}
          closeModal={() => setNewLevel(undefined)}
        />
      )}
      {selectedSubscriptionDetail && (
        <TextSmall>
          {`${selectedSubscriptionDetail.name} - $${selectedSubscriptionDetail.amount}/${t(
            'Subscriptions.mth'
          )}`}
        </TextSmall>
      )}
      {((allowUpgrade && !pendingUpgrade) || pendingDowngrade || inProcessDowngrade) && (
        <Button
          disabled={pendingDowngrade || inProcessDowngrade}
          className={styles.levelButton}
          size='S'
          onClick={() =>
            pendingDowngrade
              ? handleOnSetConfirmCancelSubscriptionModal(selectedSubscriptionDetail)
              : setNewLevel(getNextSubLevel(subscription, selectedSubscriptionDetail))
          }
          appearance='plain'
        >
          {t('Upgrade')}
        </Button>
      )}
      {((allowDowngrade && !pendingDowngrade) || pendingUpgrade || inProcessUpgrade) && (
        <Button
          className={styles.levelButton}
          size='S'
          disabled={
            pendingUpgrade ||
            inProcessUpgrade ||
            !!selectedSubscriptionDetail?.excludedBySubscriptionDetailId
          }
          onClick={() =>
            pendingUpgrade
              ? handleOnSetConfirmCancelSubscriptionModal(selectedSubscriptionDetail)
              : setNewLevel(getPreviousSubLevel(subscription, selectedSubscriptionDetail))
          }
          appearance='plain'
        >
          {t('Downgrade')}
        </Button>
      )}
      <SubscriptionDetailMessage
        selectedSubscription={subscription}
        onSetPendingUnsubscriptionInfoModal={setPendingUnsubscriptionInfoModal}
      />
    </>
  )
}

interface SubscriptionGroupSubscriptionProps {
  subscription: Subscription
  isSubscribedToBundle: boolean
  onAddCartItem(
    subscriptionDetailId: string,
    subscriptionRequestTypeId: SubscriptionRequestType
  ): void
  onUpdateSubscription(data: UpdateSubscriptionRequest, selectedItem?: SubscriptionDetail): void
  isCollapsed: boolean
  scrollToCart?(): void
  nonActiveSubscriptions: SubscriptionCartItem[]
}

export const SubscriptionGroupSubscription: React.FC<SubscriptionGroupSubscriptionProps> = ({
  isCollapsed,
  subscription,
  onAddCartItem,
  onUpdateSubscription,
  scrollToCart,
  nonActiveSubscriptions,
}) => {
  const { t } = useTranslation()
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const { formatMoney } = useFormatNumber()

  const [selectedSubscriptionDetail, setSelectedSubscriptionDetail] = useState<SubscriptionDetail>()
  const [previousSelectedSubscriptionDetail, setPreviousSelectedSubscriptionDetail] =
    useState<SubscriptionDetail>()
  const [subscriptionTimeInForceTypeModal, setSubscriptionTimeInForceTypeModal] = useState(false)
  const [subscriptionTimeInForceType, setSubscriptionTimeInForceType] = useState<number>()
  const [unsubscribeAttentionConfirmationModal, setUnsubscribeAttentionConfirmationModal] =
    useState(false)
  const [unsubscribeConfirmationModal, setUnsubscribeConfirmationModal] = useState(false)
  const [resubscribeModal, setResubscribeModal] = useState(false)
  const [subscriptionDetailModal, setSubscriptionDetailModal] = useState(false)

  const getSubscriptionRequestType = () =>
    subscription?.activeSubscriptionRequest ||
    subscription?.subscriptionRequest?.state.name === SubscriptionRequestState.Pending
      ? SubscriptionRequestType.Update
      : SubscriptionRequestType.Add

  const canAddSubscription = () =>
    !selectedSubscriptionDetail?.activeSubscriptionRequest &&
    !(
      selectedSubscriptionDetail?.subscriptionRequest?.state.name ===
      SubscriptionRequestState.Pending
    )

  const addNewSubscription = () => {
    if (!selectedSubscriptionDetail) {
      return
    }
    onAddCartItem(selectedSubscriptionDetail.id, getSubscriptionRequestType())
  }

  const handleOnCancelPendingSubscription = (subscriptionDetailId?: string | undefined) => {
    setConfirmCancelSubscriptionModal(false)
    onUpdateSubscription({
      subscriptions: [
        {
          subscriptionDetailId: subscriptionDetailId || selectedSubscriptionDetail?.id,
          subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
        },
      ],
      subscriptionTimeInForceId: SubscriptionTimeInForce.Immediate,
      overrideOpenPositionsForCancel: true,
    })
  }

  const handleOnClickResubscribeConfirm = () => {
    onUpdateSubscription({
      subscriptions: [
        {
          subscriptionDetailId: selectedSubscriptionDetail?.id,
          subscriptionRequestTypeId: SubscriptionRequestType.Resubscribe,
        },
      ],
      overrideOpenPositionsForCancel: true,
      subscriptionTimeInForceId: SubscriptionTimeInForce.Immediate,
    })
    setResubscribeModal(false)
  }

  const handleOnSubscribeProduct = async (type: SubscriptionTimeInForce) => {
    if (!canAddSubscription() || !selectedSubscriptionDetail) {
      return
    }

    if (isCollapsed) {
      if (type === SubscriptionTimeInForce.BeginningOfNextMonth && canAddSubscription()) {
        onUpdateSubscription({
          subscriptions: [
            {
              subscriptionDetailId: selectedSubscriptionDetail?.id,
              subscriptionRequestTypeId: getSubscriptionRequestType(),
            },
          ],
          subscriptionTimeInForceId: SubscriptionTimeInForce.BeginningOfNextMonth,
        })
      } else {
        addNewSubscription()
      }
    }
  }

  const handleOnSetConfirmCancelSubscriptionModal = (subscriptionDetail?: SubscriptionDetail) => {
    if (subscriptionDetail) {
      setSelectedSubscriptionDetail(subscriptionDetail)
    }
    setConfirmCancelSubscriptionModal(true)
  }

  const handleOnSelectSubscriptionTimeInForceType = (type: SubscriptionTimeInForce) => {
    setSubscriptionTimeInForceTypeModal(false)
    handleOnSubscribeProduct(type)
    setSubscriptionTimeInForceType(type)
  }
  const handleOnUnsubscribeProduct = async () => {
    onUpdateSubscription(
      {
        subscriptions: [
          {
            subscriptionDetailId: selectedSubscriptionDetail
              ? selectedSubscriptionDetail?.id
              : subscription?.details[0].id,
            subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
          },
        ],
        subscriptionTimeInForceId: SubscriptionTimeInForce.BeginningOfNextMonth,
      },
      selectedSubscriptionDetail
    )

    setUnsubscribeConfirmationModal(false)
    setUnsubscribeAttentionConfirmationModal(false)
  }
  const [confirmCancelSubscriptionModal, setConfirmCancelSubscriptionModal] = useState(false)

  const handleOnCloseCancelConfirmationModal = () => setConfirmCancelSubscriptionModal(false)

  const [levelDowngrade, setLevelDowngrade] = useState(false)
  const [pendingUnsubscriptionInfoModal, setPendingUnsubscriptionInfoModal] = useState(false)
  const handleOnClosePendingUnsubscriptionModal = () => setPendingUnsubscriptionInfoModal(false)

  const activeSubscription = subscription.details.find((detail) => detail.activeSubscriptionRequest)

  const activeViaBundlePackage = subscription.details.find(
    (detail) => detail.activeViaBundleSubscriptionRequest
  )

  const shouldHideAction =
    !!activeViaBundlePackage &&
    (!activeSubscription ||
      (!!activeSubscription && activeViaBundlePackage?.type.id === activeSubscription.type.id))

  useEffect(() => {
    const subscriptionDetail =
      getActiveSubscriptionLevel(subscription) ||
      getPendingCancelSubscriptionLevel(subscription) ||
      getPendingUpdateSubscriptionLevel(subscription) ||
      getSubscriptionDefaultLevel(subscription) ||
      subscription.details[0]

    setSelectedSubscriptionDetail(subscriptionDetail)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscription])

  const handleOnSubscriptionDetailChange = (subscriptionDetail: SubscriptionDetail) => {
    setPreviousSelectedSubscriptionDetail(selectedSubscriptionDetail)
    setSelectedSubscriptionDetail(subscriptionDetail)
    setLevelDowngrade(isLevelDowngrade(subscription, subscriptionDetail))
    setSubscriptionTimeInForceTypeModal(true)
  }

  const handleOnSelectSubscriptionDetail = (subscriptionDetail: SubscriptionDetail) => {
    if (subscriptionDetail) {
      handleOnSubscriptionDetailChange(subscriptionDetail)
    }
    setSubscriptionDetailModal(false)
  }

  const renderProductPrice = (subscription: Subscription) => {
    if (subscription.details.length > 1) {
      return (
        <SubscriptionGroupDetail
          handleOnSetConfirmCancelSubscriptionModal={handleOnSetConfirmCancelSubscriptionModal}
          selectedSubscriptionDetail={selectedSubscriptionDetail}
          subscription={subscription}
          handleOnSubscriptionDetailChange={handleOnSubscriptionDetailChange}
          setPendingUnsubscriptionInfoModal={setPendingUnsubscriptionInfoModal}
          nonActiveSubscriptions={nonActiveSubscriptions}
        />
      )
    }

    const [subscriptionDetail] = subscription.details

    if (!subscriptionDetail.subscriptionRequest) {
      return (
        <TextSmall>
          {`${formatMoney(subscriptionDetail.amount)}/${t('Subscriptions.mth')}`}
        </TextSmall>
      )
    }

    const { type, dueDate, subscriptionTimeInForce } = subscriptionDetail.subscriptionRequest
    const isCancelExistingSubscription =
      type.name === SubscriptionRequestTypeEnum.CancelExistingSubscription

    if (isSubscriptionPending(subscriptionDetail.subscriptionRequest)) {
      const amountPerMonth = `${formatMoney(subscriptionDetail.amount)}/${t('Subscriptions.mth')}`
      if (
        type.name === SubscriptionRequestTypeEnum.AddNewSubscription &&
        subscriptionTimeInForce?.id === SubscriptionTimeInForce.BeginningOfNextMonth
      ) {
        return renderNewSubscriptionPending(amountPerMonth, dueDate, t, dateFormat)
      }

      if (isCancelExistingSubscription) {
        return renderCancelExistingSubscription(
          amountPerMonth,
          dueDate,
          setPendingUnsubscriptionInfoModal,
          t,
          dateFormat
        )
      }
    }

    return (
      <TextSmall>{`${formatMoney(subscriptionDetail.amount)}/${t('Subscriptions.mth')}`}</TextSmall>
    )
  }

  const handleOnClickUnsubscribe = async () => {
    if (isLastBusinessDay()) {
      setUnsubscribeAttentionConfirmationModal(true)
    } else {
      setSelectedSubscriptionDetail(selectedSubscriptionDetail)
      setUnsubscribeConfirmationModal(true)
    }
  }

  const onHandleOpenResubscribeModal = (subscriptionDetail?: SubscriptionDetail) => {
    if (subscriptionDetail) {
      setSelectedSubscriptionDetail(subscriptionDetail)
    }
    setResubscribeModal(true)
  }

  const isSubscriptionInCart = useMemo(() => {
    return hasLevelInCart(subscription, nonActiveSubscriptions)
  }, [subscription, nonActiveSubscriptions])

  const useRenderProductAction = (shouldHideAction: boolean, subscription: Subscription) => {
    const isMobile = useWindowResize()

    if (!selectedSubscriptionDetail || shouldHideAction) {
      return null
    }

    const { subscriptionRequest } = selectedSubscriptionDetail

    switch (true) {
      case isSubscriptionImmediateAndPending(subscription.subscriptionRequest) ||
        isSubscriptionDeductingFundOrInProcess(subscription.subscriptionRequest):
        return <Badge appearance='warning' text={t('Market Data.Processing')} />
      case isCancellationRequested(subscriptionRequest):
        return (
          <Button
            className={styles.button}
            appearance='secondary'
            onClick={() => onHandleOpenResubscribeModal(selectedSubscriptionDetail)}
            size={isMobile ? 'S' : 'XS'}
          >
            {t('Subscriptions.Resubscribe')}
          </Button>
        )
      case isSubscriptionPending(subscription.subscriptionRequest):
        const pendingSubscriptionDetail = subscription.details.find((detail) =>
          isSubscriptionPending(detail.subscriptionRequest)
        )

        return (
          <Button
            className={classNames(styles.button, styles.buttonHalfSize)}
            appearance='secondary'
            size={isMobile ? 'S' : 'XS'}
            onClick={() => {
              handleOnSetConfirmCancelSubscriptionModal(pendingSubscriptionDetail)
            }}
          >
            {t('Cancel')}
          </Button>
        )
      case isSubscriptionActive(selectedSubscriptionDetail):
        return (
          <Button
            className={styles.button}
            appearance='secondary'
            size={isMobile ? 'S' : 'XS'}
            onClick={handleOnClickUnsubscribe}
          >
            {t('Subscriptions.Unsubscribe')}
          </Button>
        )
      case isSubscriptionPending(subscriptionRequest):
        return (
          <CancelOrProcessingButton
            subscriptionRequest={subscriptionRequest}
            scrollToCart={scrollToCart}
            onCancel={() => {
              handleOnSetConfirmCancelSubscriptionModal(selectedSubscriptionDetail)
            }}
          />
        )
      case isSubscriptionDeductingFundOrInProcess(subscriptionRequest):
        return (
          <>
            <Badge appearance='warning' text={t('Subscriptions.Processing')} />
          </>
        )
      default:
        return (
          <Button
            appearance='primary'
            size={isMobile ? 'S' : 'XS'}
            onClick={() => {
              if (selectedSubscriptionDetail) {
                if (subscription.details.length > 1) {
                  return setSubscriptionDetailModal(true)
                }

                handleOnSubscriptionDetailChange(selectedSubscriptionDetail)
              }
            }}
            className={styles.button}
            disabled={isSubscriptionInCart}
          >
            {t('Market Data.Subscribe')}
          </Button>
        )
    }
  }

  const cancelTimeInForceSelection = () => {
    setSelectedSubscriptionDetail(previousSelectedSubscriptionDetail)
    setSubscriptionTimeInForceTypeModal(false)
  }

  return (
    <>
      {subscriptionDetailModal && (
        <SubscriptionDetailModal
          subscription={subscription}
          handleOnSelectSubscriptionDetail={handleOnSelectSubscriptionDetail}
          closeModal={() => setSubscriptionDetailModal(false)}
          isSelectEnabled
        />
      )}
      {pendingUnsubscriptionInfoModal && (
        <PendingUnsubscriptionModal
          handleOnClosePendingUnsubscriptionModal={handleOnClosePendingUnsubscriptionModal}
          dueDate={selectedSubscriptionDetail?.subscriptionRequest?.dueDate}
        />
      )}

      {confirmCancelSubscriptionModal && (
        <ConfirmCancelSubscriptionModal
          handleOnCancelPendingSubscription={() =>
            handleOnCancelPendingSubscription(selectedSubscriptionDetail?.id)
          }
          handleOnCloseCancelConfirmationModal={handleOnCloseCancelConfirmationModal}
        />
      )}
      {resubscribeModal && (
        <ResubscribeModal
          handleOnClickResubscribeConfirm={handleOnClickResubscribeConfirm}
          setResubscribeModal={setResubscribeModal}
          amount={selectedSubscriptionDetail?.amount}
        />
      )}
      {unsubscribeConfirmationModal && (
        <UnsubscribeConfirmationModal
          dueDate={subscription?.subscriptionRequest?.dueDate}
          onClickUnsubscribe={handleOnUnsubscribeProduct}
          setUnsubscribeConfirmationModal={() => setUnsubscribeConfirmationModal(false)}
        />
      )}
      {unsubscribeAttentionConfirmationModal && (
        <UnsubscribeAttentionConfirmationModal
          onConfirm={handleOnUnsubscribeProduct}
          onCancel={() => setUnsubscribeAttentionConfirmationModal(false)}
        />
      )}
      {subscriptionTimeInForceTypeModal && (
        <SubscriptionTimeInForceModal
          handleOnSelectSubscriptionTimeInForceType={handleOnSelectSubscriptionTimeInForceType}
          levelDowngrade={levelDowngrade}
          subscriptionTimeInForceType={subscriptionTimeInForceType}
          cancelTimeInForceSelection={cancelTimeInForceSelection}
        />
      )}
      <hr className={styles.separator} />
      <div className={classNames(styles.dataItem, 'mt-0')}>
        <div className={styles.titleWrapper}>
          <div className={classNames('is-flex', styles.name)}>
            <ProductStatusIcon className={styles.statusIcon} subscription={subscription} />
            <TextSmallStrong>{subscription.name}</TextSmallStrong>
            <TextSmall className={classNames('text-secondary', styles.dataDescription)}>
              {selectedSubscriptionDetail?.description}
            </TextSmall>
          </div>
          <TextSmall className={classNames('text-secondary', styles.mobileDataDescription)}>
            {selectedSubscriptionDetail?.description}
          </TextSmall>
          <div className={classNames(styles.price)}>{renderProductPrice(subscription)}</div>
          <div className={styles.status}>
            {useRenderProductAction(shouldHideAction, subscription)}
          </div>
        </div>
      </div>
    </>
  )
}
