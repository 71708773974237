import React, { FormEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikProps, useFormikContext, withFormik } from 'formik'

import { DepositFormWarningBox } from '../../../../../Traders-Room/DepositFormWarningBox'
import {
  Wallet,
  WalletDepositFormValues,
  validateWalletDepositForm,
  walletDepositInitialState,
} from '../../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'
import {
  GetDepositCurrencyLimits,
  useGetDepositCurrencyLimits,
} from '../../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/hooks/useGetDepositCurrencyLimits'
import { WalletDepositModal } from '../../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositModal'
import { WalletDepositTermsConditions } from '../../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositTermsConditions'
import { WalletDepositPaymentProviderModal } from '../../../../../global/WalletDepositPaymentProviderModal/WalletDepositPaymentProviderModal'
import { useSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { createFormField } from '../../../../../global/formField/FormField'
import { FormatMoney } from '../../../../../hooks/useFormatNumber'
import { DropArrowDownIcon } from '../../../../../icons/DropArrowDownIcon'
import { isAccountActivatedStatus } from '../../../../../model/AccountDetailedStatus'
import { PaymentProvider } from '../../../../../model/PaymentProviderDto'
import { isPaymentProviderSCOrbitalCrypto } from '../../../../../model/PaymentProviderId'
import { WalletDto, WalletTypeEnum } from '../../../../../model/WalletDto'
import { useAccountReadContext } from '../../../../../utils/AccountContextContext'
import { WalletRestrictions, isRestricted } from '../../../../../utils/wallet.utils'
import { FirstTimeDepositPSPFields } from './FirstTimeDepositPSPFields'

import styles from './FirstTimeDepositForm.module.scss'

interface OuterProps {
  wallets: WalletDto[]
  skipButtonLink: string
  onSubmit: (values: WalletDepositFormValues) => void
  formatMoney: FormatMoney
  getDepositCurrencyLimits: GetDepositCurrencyLimits
}

export interface CurrencyLimits {
  minAmount: number
  maxAmount: number
}

const FormField = createFormField<WalletDepositFormValues>()

const FirstTimeDepositFormUI: React.FC<FormikProps<WalletDepositFormValues> & OuterProps> = (
  props
) => {
  const { wallets, skipButtonLink, formatMoney } = props
  const { handleSubmit, values, resetForm, setValues } = useFormikContext<WalletDepositFormValues>()
  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()

  const { depositCurrencyLimits } = useGetDepositCurrencyLimits({
    wallet: values.wallet,
    paymentProvider: values.paymentProvider,
  })

  const [isPaymentProviderModalOpen, setPaymentProviderModalOpen] = useState(false)
  const [isTermsConditionOpen, setIsTermsConditionOpen] = useState(false)
  const [isWalletModalOpen, setWalletModalOpen] = useState(false)

  const isWalletFieldShown =
    wallets.length > 1 ||
    (wallets.length === 1 && isRestricted(WalletRestrictions.DEPOSIT, wallets[0].restrictions))

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()

    props.validateForm()
    handleSubmit()
  }

  const handleWallet = (wallet: Wallet) => {
    resetForm()
    setValues({
      ...walletDepositInitialState(),
      wallet,
      walletType: values.walletType,
    })
    setWalletModalOpen(false)
  }

  const handleWalletOpen = () => {
    setWalletModalOpen(true)
  }

  const handleWalletClose = () => {
    setWalletModalOpen(false)
  }

  const handlePaymentProvider = (paymentProvider: PaymentProvider) => {
    resetForm()
    setValues({
      ...values,
      paymentProvider,
    })
    setPaymentProviderModalOpen(false)
  }

  const handlePaymentProviderOpen = () => {
    setPaymentProviderModalOpen(true)
  }

  const handlePaymentProviderClose = () => {
    setPaymentProviderModalOpen(false)
  }

  useEffect(() => {
    props.validateForm()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  if (isTermsConditionOpen && values.wallet && values.paymentProvider) {
    return (
      <div>
        <WalletDepositTermsConditions
          wallet={values.wallet}
          paymentProvider={values.paymentProvider}
          onCancel={() => setIsTermsConditionOpen(false)}
        />
      </div>
    )
  }

  return (
    <Form className={styles.form} onSubmit={onSubmit} autoComplete='off'>
      {isPaymentProviderModalOpen && (
        <WalletDepositPaymentProviderModal
          onSelectOption={handlePaymentProvider}
          onClose={handlePaymentProviderClose}
          wallet={values.wallet}
        />
      )}
      {isWalletModalOpen && (
        <WalletDepositModal onSelectOption={handleWallet} onClose={handleWalletClose} />
      )}
      {isPaymentProviderSCOrbitalCrypto(values.paymentProvider.id) && (
        <DepositFormWarningBox className={styles.formItem}>
          {t(
            'Wallet.The wallet address and exchange rate will expire within 1 hour, please make your payment within this time.'
          )}
        </DepositFormWarningBox>
      )}
      {isWalletFieldShown && (
        <FormField
          name='wallet.name'
          label={t('Wallet.Deposit To Wallet')}
          placeholder={t('Wallet.Deposit To Wallet')}
          value={
            values.wallet?.id &&
            t('Wallet.Wallet Currency Name', {
              walletCurrencyId: values.wallet.currency.id,
              walletName: values.wallet.name,
            })
          }
          rightIcon={<DropArrowDownIcon />}
          hint={
            values.wallet?.id &&
            `${t('Wallet.Balance')} ${formatMoney(
              values.wallet.balance,
              values.wallet.currency.id
            )}`
          }
          required
          readOnly
          onClick={handleWalletOpen}
        />
      )}
      <FormField
        name='paymentProvider.name'
        value={values.paymentProvider?.currency?.description}
        label={t('Sign up.Payment method')}
        placeholder={t('Sign up.Payment method')}
        rightIcon={<DropArrowDownIcon />}
        disabled={!values.wallet?.id}
        readOnly
        onClick={handlePaymentProviderOpen}
        key={values.paymentProvider?.id ? 'paymentProvider' : 'no-paymentProvider'}
      />
      <FirstTimeDepositPSPFields
        minAmount={depositCurrencyLimits?.minAmount}
        showAmountPresets={isAccountActivatedStatus(account)}
        onClickTermsConditions={() => setIsTermsConditionOpen(true)}
        skipButtonLink={skipButtonLink}
      />
    </Form>
  )
}

export const FirstTimeDepositForm = withFormik<OuterProps, WalletDepositFormValues>({
  mapPropsToValues: ({ wallets }) => {
    const filteredWallets = wallets?.filter(
      (wallet) => !isRestricted(WalletRestrictions.DEPOSIT, wallet?.restrictions)
    )
    const wallet = filteredWallets?.find((wallet) => wallet.isDefault) ?? filteredWallets?.[0]
    return walletDepositInitialState({ wallet, walletType: WalletTypeEnum.PAYMENT_AGENT })
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: validateWalletDepositForm,
  enableReinitialize: true,
  validateOnMount: true,
})(FirstTimeDepositFormUI)
