import { Trans, useTranslation } from 'react-i18next'

import { useScrollToTop } from '../../utils/useScrollToTop'
import { VendorType, Vendors } from '../Vendors/Vendors'
import AgenaLogo from '../images/AGENA-desktop.png'
import AtasLogo from '../images/ATAS-desktop.png'
import BookmapLogo from '../images/BOOKMAP-desktop.png'
import CQGLogo from '../images/CQG-desktop.png'
import MotivewaveLogo from '../images/MOTIVEWAVE-desktop.png'
import MultichartsLogo from '../images/MULTICHARTS-desktop.png'
import SierraChartLogo from '../images/SIERRA-desktop.png'
import TradingViewLogo from '../images/TRADINGVIEW-desktop.png'
import VolumetricaLogo from '../images/VOLUMETRICA-desktop.png'

export const PlatformVendorsPage = (): JSX.Element => {
  useScrollToTop()
  const { t } = useTranslation()

  return <Vendors vendors={platfomVendorsData()} title={t('Platform Vendors.title')} />
}

export const platfomVendorsData = (): VendorType[] => {
  return [
    {
      name: 'TradingView',
      imageUrl: TradingViewLogo,
      description: (
        <Trans
          i18nKey='Platform Vendors.TradingView'
          components={{ 1: <div className='mb-4' /> }}
        />
      ),
      url: 'https://www.tradingview.com/gopro/?source=header_go_pro_button&feature=start_free_trial#',
    },
    {
      name: 'MotiveWave',
      imageUrl: MotivewaveLogo,
      description: (
        <Trans i18nKey='Platform Vendors.MotiveWave' components={{ 1: <div className='mb-4' /> }} />
      ),
      url: 'https://www.motivewave.com/',
    },
    {
      name: 'MultiCharts',
      imageUrl: MultichartsLogo,
      description: (
        <Trans
          i18nKey='Platform Vendors.MultiCharts'
          components={{ 1: <div className='mb-4' /> }}
        />
      ),
      url: 'https://www.multicharts.com/',
    },
    {
      name: 'Sierra Chart',
      imageUrl: SierraChartLogo,
      description: (
        <Trans
          i18nKey='Platform Vendors.SierraChart'
          components={{ 1: <div className='mb-4' /> }}
        />
      ),
      url: 'https://www.sierrachart.com/',
    },
    {
      name: 'Bookmap',
      imageUrl: BookmapLogo,
      description: (
        <Trans i18nKey='Platform Vendors.Bookmap' components={{ 1: <div className='mb-4' /> }} />
      ),
      url: 'https://bookmap.com/',
    },
    {
      name: 'Volumetrica',
      imageUrl: VolumetricaLogo,
      description: (
        <Trans
          i18nKey='Platform Vendors.Volumetrica'
          components={{ 1: <div className='mb-4' /> }}
        />
      ),
      url: 'https://www.volumetricatrading.com/',
    },
    {
      name: 'ATAS',
      imageUrl: AtasLogo,
      description: (
        <Trans i18nKey='Platform Vendors.ATAS' components={{ 1: <div className='mb-4' /> }} />
      ),
      url: 'https://atas.net/',
    },
    {
      name: 'AgenaTrader',
      imageUrl: AgenaLogo,
      description: (
        <Trans
          i18nKey='Platform Vendors.AgenaTrader'
          components={{ 1: <div className='mb-4' /> }}
        />
      ),
      url: 'https://shop.agenatrader.com/',
    },
    {
      name: 'CQG Mobile',
      imageUrl: CQGLogo,
      description: (
        <Trans i18nKey='Platform Vendors.CQG Mobile' components={{ 1: <div className='mb-4' /> }} />
      ),
      url: 'https://www.cqg.com/products/cqg-mobile',
    },
    {
      name: 'CQG Desktop (Web Based)',
      imageUrl: CQGLogo,
      description: (
        <Trans
          i18nKey='Platform Vendors.CQG Desktop'
          components={{ 1: <div className='mb-4' /> }}
        />
      ),
      url: 'https://www.cqg.com/products/cqg-desktop',
    },
    {
      name: 'CQG QTrader (Installable)',
      imageUrl: CQGLogo,
      description: (
        <Trans
          i18nKey='Platform Vendors.CQG QTrader'
          components={{ 1: <div className='mb-4' /> }}
        />
      ),
      url: 'https://www.cqg.com/products/cqg-qtrader',
    },
    {
      name: 'CQG Platform',
      imageUrl: CQGLogo,
      description: (
        <Trans
          i18nKey='Platform Vendors.CQG Platform'
          components={{ 1: <div className='mb-4' /> }}
        />
      ),
      url: 'https://www.cqg.com/products/overview',
    },
    {
      name: 'CQG Integrated Client',
      imageUrl: CQGLogo,
      description: (
        <Trans
          i18nKey='Platform Vendors.CQG Integrated Client'
          components={{ 1: <div className='mb-4' /> }}
        />
      ),
      url: 'https://www.cqg.com/products/cqg-integrated-client',
    },
  ]
}
