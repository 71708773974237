import { iconColor } from './IconProps'
import { Color } from './index'

import styles from './Icons.module.scss'

export function ClockIcon(props: { size?: number; color?: Color }): JSX.Element {
  const size = props.size || 24

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 20.9984C10.9481 20.9984 9.93834 20.8179 9 20.4863C5.50442 19.2508 3 15.9171 3 11.9984C3 9.58308 3.95145 7.38997 5.5 5.77344'
        stroke={props.color ? styles[props.color] : iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeDasharray='1 3'
      />
      <path
        d='M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C9.44477 3 7.13825 4.06486 5.5 5.77503'
        stroke={props.color ? styles[props.color] : iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M12 6.5V12L15 14'
        stroke={props.color ? styles[props.color] : iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
