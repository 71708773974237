import { useSessionLanguage } from '../global/context/SessionSettingsContext'

export const useRedirectToSupport = () => {
  const locale = useSessionLanguage()
  const baseUrl = window.location.origin
  const supportUrl = `${baseUrl}/${locale}/contact-us`

  return {
    supportUrl,
  }
}
