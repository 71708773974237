import React, { useState } from 'react'

import {
  FilterOptions,
  FilterQueryModal,
  FilterQueryProps,
} from '../../global/filter/FilterQueryModal'
import { FilterOptionsDto } from '../../model/FilterOptionsDto'
import { NameDto } from '../../model/NameDto'
import { Operator } from '../../utils/ApiClient'
import { IDateFilter } from '../../utils/filter.utils'

export interface TransactionQuery {
  [key: string]: string | { value: string; operator: Operator } | undefined

  TaId: string
  Client: string
  WalletTypeId: string
  TransactionStateId: string
  TransactionTypeId: string
  WalletId: string
}

interface Props {
  currentQuery?: FilterQueryProps
  onCancel: () => void
  transactionStates: NameDto<number>[]
  filterOptions?: FilterOptionsDto
  onConfirm({
    searchFilters,
    currentFilter,
  }: {
    searchFilters?: FilterQueryProps
    currentFilter?: IDateFilter
  }): void
}

export const IntroducingBrokerTransactionFilterModal: React.FC<Props> = ({
  onConfirm,
  currentQuery,
  filterOptions,
  transactionStates,
}) => {
  const [searchFilters, setSearchFilters] = useState<FilterQueryProps | undefined>(currentQuery)

  return (
    <FilterQueryModal
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      transactionTypes={filterOptions?.transactionTypes}
      onConfirm={onConfirm}
      transactionStates={transactionStates}
      filterOptions={[
        FilterOptions.TransactionType,
        FilterOptions.TransactionStatus,
        FilterOptions.Date,
        FilterOptions.ClientIdAmount,
      ]}
    />
  )
}
