import { IconProps, iconColor } from './IconProps'

export function FormatExcelIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.9998 10V7.14301C19.0021 7.05853 18.9858 6.97455 18.9522 6.89687C18.9185 6.81919 18.8682 6.74964 18.8048 6.69302L14.2549 2.19307C14.1976 2.1303 14.1273 2.08054 14.0488 2.04725C13.9702 2.01395 13.8853 1.99791 13.7999 2.00022H4.29998C3.95552 2.00124 3.62546 2.13702 3.38189 2.37792C3.13832 2.61881 3.00103 2.94524 3 3.28592V20.7143C3 21.0553 3.13696 21.3823 3.38075 21.6234C3.62455 21.8645 3.9552 22 4.29998 22H18V20.7143H4.29998V3.28592H12.4999V7.14301C12.5009 7.48369 12.6382 7.81012 12.8818 8.05102C13.1254 8.29191 13.4554 8.42769 13.7999 8.42871H17.6998V10H18.9998ZM13.7999 7.14301V3.54306L17.4398 7.14301H13.7999Z'
        fill={iconColor(props)}
      />
      <path
        d='M12.9144 17.9503L11.0746 14.9254L12.7901 12.0497H11.489L10.453 13.9558L9.42541 12.0497H8.11602L9.83149 14.9254L8 17.9503H9.31768L10.453 15.895L11.5967 17.9503H12.9144Z'
        fill={iconColor(props)}
      />
      <path
        d='M17.4686 17.9503V16.9227H14.7918V12.0497H13.6398V17.9503H17.4686Z'
        fill={iconColor(props)}
      />
      <path
        d='M22.2188 16.2099C22.2188 15.6878 22.0779 15.2569 21.763 14.9586C21.5144 14.7265 21.1746 14.5773 20.6359 14.5028L19.9398 14.4033C19.6995 14.3702 19.5088 14.279 19.3928 14.1713C19.2685 14.0552 19.2188 13.8978 19.2188 13.7486C19.2188 13.3425 19.5171 13.0028 20.1304 13.0028C20.5199 13.0028 20.9674 13.0525 21.3403 13.4088L22.0696 12.6878C21.5641 12.1989 20.9757 12 20.1635 12C18.8873 12 18.1083 12.7376 18.1083 13.7901C18.1083 14.2873 18.2492 14.6685 18.5309 14.9503C18.7961 15.2072 19.1691 15.3646 19.6746 15.4392L20.3873 15.5387C20.6608 15.5801 20.7934 15.6381 20.9094 15.7459C21.0337 15.8619 21.0917 16.0359 21.0917 16.2431C21.0917 16.7155 20.7271 16.9724 20.0475 16.9724C19.5006 16.9724 18.995 16.8481 18.6221 16.4751L17.8763 17.221C18.4564 17.8094 19.1359 18 20.0309 18C21.2658 18 22.2188 17.3536 22.2188 16.2099Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
