import { IconProps, iconColor } from './IconProps'

export function DropArrowDownIcon(props: IconProps) {
  const size = props.size || 10

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 10 5'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.482871 0C0.30469 0 0.215457 0.215428 0.34145 0.341421L4.7879 4.78787C4.90505 4.90503 5.095 4.90503 5.21216 4.78787L9.65861 0.341421C9.7846 0.215428 9.69537 0 9.51719 0H0.482871Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
