import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import {
  TradersRoomModalsType,
  isTradersRoomLuckyDrawModalOpen,
} from '../../Traders-Room/TradersRoomModals'
import { LuckyDrawCampaign } from '../../model/LuckyDrawDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { useModalsContext } from '../context/ModalsContext'

interface LuckyDrawResponseProps {
  luckyDrawCampaign: LuckyDrawCampaign | undefined
  setShowLuckyDraw(value: boolean): void
}

export const useLuckyDraw = (): LuckyDrawResponseProps => {
  const location = useLocation()
  const apiClient = useApiClient(ClientApiClient)
  const { activeModal, setActiveModal } = useModalsContext()

  const [, setLuckyDrawEligible] = useLocallyPersistedState('luckyDrawEligible', false)
  const [luckyDrawViewed, setLuckyDrawViewed] = useLocallyPersistedState('luckyDrawViewed', false)

  const [isLuckyDrawModalOpen, setLuckyDrawModalOpen] = useState<boolean>(!luckyDrawViewed)
  const [luckyDrawCampaign, setLuckyDrawCampaign] = useState<LuckyDrawCampaign>()

  useEffect(() => {
    if (isLuckyDrawModalOpen && luckyDrawCampaign) {
      setActiveLuckyDrawModalOpen(luckyDrawCampaign)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLuckyDrawModalOpen, luckyDrawCampaign])

  useEffect(() => {
    if (!isTradersRoomLuckyDrawModalOpen(activeModal)) {
      setLuckyDrawModalOpen(false)
    }
  }, [activeModal])

  useEffect(() => {
    checkLuckyDrawEligible()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location?.state?.luckyDrawModalActive) {
      setLuckyDrawModalOpen(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  const checkLuckyDrawEligible = async () => {
    const luckyCampaignFetch = await fillLuckyCampaign()
    if (!luckyCampaignFetch) {
      setLuckyDrawModalOpen(false)
      setLuckyDrawViewed(true)
    }
  }

  const fillLuckyCampaign = async (): Promise<LuckyDrawCampaign | null> => {
    const eligibility = await apiClient.getLuckyDrawCampaign()

    let pickedCampaign: LuckyDrawCampaign | null = null
    for (let i = 0; i < eligibility.length; i++) {
      if (eligibility[i].canGenerateMoreChances || eligibility[i].hasAvailableChancesToUse) {
        pickedCampaign = eligibility[i]
        break
      }
    }

    if (eligibility.length > 0) {
      setLuckyDrawEligible(true)
    }

    if (pickedCampaign) {
      setLuckyDrawCampaign(pickedCampaign)
    } else {
      setLuckyDrawCampaign(eligibility[0])
    }

    return pickedCampaign
  }

  const setActiveLuckyDrawModalOpen = (campaign: LuckyDrawCampaign) => {
    setActiveModal(TradersRoomModalsType.LuckyDraw, {
      campaign,
    })
  }

  return {
    luckyDrawCampaign,
    setShowLuckyDraw: setLuckyDrawModalOpen,
  }
}
