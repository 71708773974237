import { NameDto } from './NameDto'

export enum ValidationOperationId {
  ValidateClientPlainPassword = '0001e3e9-892a-41e8-a29b-03400f213b1a',
  ValidateMetatraderAccountPassword = '083a1974-2bb0-48e2-a057-4b5df385ef05',
}

export interface ValidationRule {
  id: ValidationRuleId
  name: string
  regex: string
  ruleText: string
  errorText: string
  shouldValidateOnFrontEnd: boolean
}

export enum ValidationRuleId {
  Between12and128Characters = '2eacaa24-3cc2-4ee5-8320-47f83b88cbdd',
  LowerCaseCharacter = '3c16fe9a-8fca-466c-bf3c-59e37c1b385e',
  UpperCaseCharacter = '6a46395f-e4e8-4ad7-b7ad-39e83412aeed',
  Digit = '6e0d4369-21f7-4b3e-b90a-2db3cdfd5ff8',
  Symbol = '5109b5c2-e594-4fe0-aac7-6e02b3ff5ffd',
  StringSplitElementCheck = '2cb92a65-a125-45cd-833a-9cf38327b6c4',
  Between8and16Characters = '71dd5a8d-24ac-4b1a-aba1-a71a2867915c',
}

export interface ValidationOperation {
  validationOperationId: ValidationOperationId
  validationOperationName: keyof typeof ValidationOperationId
  operationType: NameDto
  validationRules: ValidationRule[]
}
