import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMSeychellesLogo: FC = () => (
  <svg width='160' height='40' viewBox='0 0 160 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M70.7879 21.9512C72.7758 21.9512 74.4243 21.1754 75.6849 19.5754L78.3031 22.2421C76.2182 24.5694 73.794 25.733 71.0303 25.733C68.2182 25.733 65.9394 24.8603 64.097 23.0664C62.3031 21.3209 61.3818 19.0421 61.3818 16.3754C61.3818 13.6603 62.3031 11.43 64.1455 9.63605C65.9879 7.84211 68.2667 6.9209 70.9334 6.9209C73.9394 6.9209 76.4121 8.03605 78.4485 10.3148L75.9273 13.1754C74.6667 11.5754 73.0667 10.7997 71.1273 10.7997C69.6243 10.7997 68.3152 11.2845 67.2 12.3027C66.1334 13.3209 65.5515 14.63 65.5515 16.327C65.5515 18.0239 66.0849 19.3815 67.1031 20.3997C68.1212 21.4179 69.3334 21.9512 70.7879 21.9512Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M45.8181 25.5391V10.8481H50.9575V7.35724H36.606V10.8481H41.7454V25.5391H45.8181Z'
      fill='var(--logo-text-color)'
    />
    <path d='M57.9878 7.35724H53.915V25.5391H57.9878V7.35724Z' fill='var(--logo-text-color)' />
    <path
      d='M85.5757 7.35724H81.5029V25.5391H85.5757V20.3997L87.4181 18.3633L92.7514 25.5391H97.5029C97.0518 24.9022 96.47 24.0911 95.7575 23.0978L95.7517 23.0897C95.1637 22.2699 94.4868 21.3262 93.7211 20.2542C92.2209 18.0922 91.1223 16.5726 90.4617 15.6589L90.2787 15.4057L97.5029 7.35724H92.4605L85.5757 14.8239V7.35724Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M116.266 14.0966L111.37 24.036H108.945L104.048 14.0966V25.5391H99.9756V7.35724H105.454L110.157 17.3451L114.86 7.35724H120.339V25.5391H116.266V14.0966Z'
      fill='var(--logo-text-color)'
    />
    <path d='M128.873 7.35724H124.8V25.5391H128.873V7.35724Z' fill='var(--logo-text-color)' />
    <path
      d='M133.333 25.5391V7.35724H137.406V21.9027H145.163V25.5391H133.333Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M148.121 7.35724V25.5391H159.951V21.9027H152.194V7.35724H148.121Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M7.70909 12.0121C7.41818 12.2061 7.17576 12.6424 7.17576 12.9818H7.27273V18.9939L0 23.1636V9.58788C0 8.71515 0.630303 7.64849 1.35758 7.21212L12.9939 0.327273C13.7212 -0.109091 14.9333 -0.109091 15.6606 0.327273L27.297 7.21212C27.3455 7.21212 27.3939 7.26061 27.4424 7.30909L20.0727 11.5758L14.8364 8.52121C14.497 8.32727 14.0121 8.32727 13.7212 8.52121L7.70909 12.0121Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M21.3336 19.9152V13.9031L28.5093 9.7334V23.3092C28.5093 24.1819 27.879 25.2486 27.1517 25.6849L15.5154 32.5698C14.7881 33.0061 13.576 33.0061 12.8487 32.5698L1.21235 25.6849C1.16387 25.6849 1.11539 25.6364 1.0669 25.588L8.43659 21.3213L13.673 24.3758C14.0124 24.5698 14.4972 24.5698 14.7881 24.3758L20.8002 20.8849C21.0911 20.691 21.3336 20.2546 21.3336 19.9152Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M94.68 39.16C94.1533 39.16 93.6433 39.08 93.15 38.92C92.6633 38.7533 92.28 38.54 92 38.28L92.37 37.5C92.6367 37.74 92.9767 37.9367 93.39 38.09C93.81 38.2367 94.24 38.31 94.68 38.31C95.26 38.31 95.6933 38.2133 95.98 38.02C96.2667 37.82 96.41 37.5567 96.41 37.23C96.41 36.99 96.33 36.7967 96.17 36.65C96.0167 36.4967 95.8233 36.38 95.59 36.3C95.3633 36.22 95.04 36.13 94.62 36.03C94.0933 35.9033 93.6667 35.7767 93.34 35.65C93.02 35.5233 92.7433 35.33 92.51 35.07C92.2833 34.8033 92.17 34.4467 92.17 34C92.17 33.6267 92.2667 33.29 92.46 32.99C92.66 32.69 92.96 32.45 93.36 32.27C93.76 32.09 94.2567 32 94.85 32C95.2633 32 95.67 32.0533 96.07 32.16C96.47 32.2667 96.8133 32.42 97.1 32.62L96.77 33.42C96.4767 33.2333 96.1633 33.0933 95.83 33C95.4967 32.9 95.17 32.85 94.85 32.85C94.2833 32.85 93.8567 32.9533 93.57 33.16C93.29 33.3667 93.15 33.6333 93.15 33.96C93.15 34.2 93.23 34.3967 93.39 34.55C93.55 34.6967 93.7467 34.8133 93.98 34.9C94.22 34.98 94.5433 35.0667 94.95 35.16C95.4767 35.2867 95.9 35.4133 96.22 35.54C96.54 35.6667 96.8133 35.86 97.04 36.12C97.2733 36.38 97.39 36.73 97.39 37.17C97.39 37.5367 97.29 37.8733 97.09 38.18C96.89 38.48 96.5867 38.72 96.18 38.9C95.7733 39.0733 95.2733 39.16 94.68 39.16Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M104.331 38.21V39.08H99.2509V32.08H104.191V32.95H100.251V35.1H103.761V35.95H100.251V38.21H104.331Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M108.952 36.66V39.08H107.962V36.64L105.182 32.08H106.252L108.492 35.77L110.742 32.08H111.732L108.952 36.66Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M115.974 39.16C115.274 39.16 114.641 39.0067 114.074 38.7C113.514 38.3867 113.074 37.96 112.754 37.42C112.434 36.8733 112.274 36.26 112.274 35.58C112.274 34.9 112.434 34.29 112.754 33.75C113.074 33.2033 113.517 32.7767 114.084 32.47C114.651 32.1567 115.284 32 115.984 32C116.531 32 117.031 32.0933 117.484 32.28C117.937 32.46 118.324 32.73 118.644 33.09L117.994 33.72C117.467 33.1667 116.811 32.89 116.024 32.89C115.504 32.89 115.034 33.0067 114.614 33.24C114.194 33.4733 113.864 33.7967 113.624 34.21C113.391 34.6167 113.274 35.0733 113.274 35.58C113.274 36.0867 113.391 36.5467 113.624 36.96C113.864 37.3667 114.194 37.6867 114.614 37.92C115.034 38.1533 115.504 38.27 116.024 38.27C116.804 38.27 117.461 37.99 117.994 37.43L118.644 38.06C118.324 38.42 117.934 38.6933 117.474 38.88C117.021 39.0667 116.521 39.16 115.974 39.16Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M126.49 32.08V39.08H125.49V35.96H121.47V39.08H120.47V32.08H121.47V35.09H125.49V32.08H126.49Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M134.066 38.21V39.08H128.986V32.08H133.926V32.95H129.986V35.1H133.496V35.95H129.986V38.21H134.066Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M136.085 32.08H137.085V38.21H140.875V39.08H136.085V32.08Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M142.422 32.08H143.422V38.21H147.212V39.08H142.422V32.08Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M153.84 38.21V39.08H148.76V32.08H153.7V32.95H149.76V35.1H153.27V35.95H149.76V38.21H153.84Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M157.899 39.16C157.372 39.16 156.862 39.08 156.369 38.92C155.882 38.7533 155.499 38.54 155.219 38.28L155.589 37.5C155.856 37.74 156.196 37.9367 156.609 38.09C157.029 38.2367 157.459 38.31 157.899 38.31C158.479 38.31 158.912 38.2133 159.199 38.02C159.486 37.82 159.629 37.5567 159.629 37.23C159.629 36.99 159.549 36.7967 159.389 36.65C159.236 36.4967 159.042 36.38 158.809 36.3C158.582 36.22 158.259 36.13 157.839 36.03C157.312 35.9033 156.886 35.7767 156.559 35.65C156.239 35.5233 155.962 35.33 155.729 35.07C155.502 34.8033 155.389 34.4467 155.389 34C155.389 33.6267 155.486 33.29 155.679 32.99C155.879 32.69 156.179 32.45 156.579 32.27C156.979 32.09 157.476 32 158.069 32C158.482 32 158.889 32.0533 159.289 32.16C159.689 32.2667 160.032 32.42 160.319 32.62L159.989 33.42C159.696 33.2333 159.382 33.0933 159.049 33C158.716 32.9 158.389 32.85 158.069 32.85C157.502 32.85 157.076 32.9533 156.789 33.16C156.509 33.3667 156.369 33.6333 156.369 33.96C156.369 34.2 156.449 34.3967 156.609 34.55C156.769 34.6967 156.966 34.8133 157.199 34.9C157.439 34.98 157.762 35.0667 158.169 35.16C158.696 35.2867 159.119 35.4133 159.439 35.54C159.759 35.6667 160.032 35.86 160.259 36.12C160.492 36.38 160.609 36.73 160.609 37.17C160.609 37.5367 160.509 37.8733 160.309 38.18C160.109 38.48 159.806 38.72 159.399 38.9C158.992 39.0733 158.492 39.16 157.899 39.16Z'
      fill='var(--logo-text-color)'
    />
  </svg>
)
