import React, { ReactElement, ReactNode } from 'react'
import classNames from 'classnames'

import styles from './CarouselItem.module.scss'

export interface CarouselItemProps {
  title: string
  content: ReactElement<any, any>
  Image?: ReactElement<any, any>
}

export const CarouselItem = (props: CarouselItemProps) => {
  return <div className={styles.carouselItem}>{!!props.Image && <div>{props.Image}</div>}</div>
}

export interface CarouselChildProps {
  children: ReactNode
  className?: string
}

export const CarouselChild: React.FC<CarouselChildProps> = ({ children, className }) => (
  <div className={classNames(styles.carouselItem, className)}>{children}</div>
)
