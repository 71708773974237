import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { SelectModal, SelectModalOption } from '../../../global/field/SelectModal'
import { Modal } from '../../../global/modal/Modal'
import { AddIcon } from '../../../icons/AddIcon'
import { BankAccount, isBankAccountAllowed } from '../../../model/BankAccount'
import { WalletWithdrawFormValues } from './WalletWithdrawForm/WalletWithdrawForm'

interface BankAccountsModalProps {
  onSelectOption(option: BankAccount): void

  onNewAccount(): void

  onClose(): void
}

export const BankAccountsModal: React.FC<BankAccountsModalProps> = (props) => {
  const { onSelectOption, onClose, onNewAccount } = props
  const [selectedBankAccount, setSelectedBankAccount] = useState<BankAccount>()

  const { t } = useTranslation()

  const { values } = useFormikContext<WalletWithdrawFormValues>()

  const handleSelectOption = (bankAccount: BankAccount) => {
    setSelectedBankAccount(bankAccount)
  }

  const handleNextClick = () => {
    if (selectedBankAccount) {
      onSelectOption(selectedBankAccount)
    } else {
      onClose()
    }
  }

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Wallet.Choose Bank Account')}
          renderOptions={() =>
            values.bankAccounts
              .filter(isBankAccountAllowed)
              .map((x) => (
                <BankAccountsModalItem item={x} onSelectOption={handleSelectOption} key={x.id} />
              ))
          }
          renderFooter={() => (
            <React.Fragment>
              <button className='button mr-auto' onClick={onNewAccount} type='button'>
                <AddIcon size={20} />{' '}
                <span className='ml-1'>{t('Wallet.Add New Bank Account')}</span>
              </button>
              <button className='button' onClick={onClose} type='button'>
                {t('Cancel')}
              </button>
              <button className='button' onClick={handleNextClick} type='button'>
                {t('Next')}
              </button>
            </React.Fragment>
          )}
        />
      )}
    />
  )
}

interface BankAccountProps {
  item: BankAccount

  onSelectOption(bankAccount: BankAccount): void
}

const BankAccountsModalItem: React.FC<BankAccountProps> = (props) => {
  const { item, onSelectOption } = props

  const { values, setFieldValue } = useFormikContext<WalletWithdrawFormValues>()

  const isChecked = item.id === values.bankAccount?.id

  const handleChange = (id: string) => {
    setFieldValue('bankAccount.id', id)
  }

  return (
    <SelectModalOption
      label={item.accountName + ' ' + item.bankAccount}
      value={item.id === values.bankAccount?.id}
      onClick={() => {
        handleChange(item.id)
        onSelectOption(item)
      }}
      checked={isChecked}
      key={item.id}
    />
  )
}
