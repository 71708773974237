import React from 'react'
import { useFormikContext } from 'formik'

import { Chip } from '../../global/chip/Chip'
import { useSessionEntity } from '../../global/context/EntityContext'
import {
  isTickmillASType,
  isTickmillEUType,
  isTickmillPartnerType,
  isTickmillSCType,
  isTickmillUKType,
} from '../../utils/companyName.utils'
import { WalletDepositFormValues } from '../Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'

import styles from './CurrencyAmountChip.module.scss'

type Currency = 'USD' | 'EU' | 'PLN' | 'GBR' | 'CHF' | 'HKD' | 'SGD' | 'ZAR'

enum CURRENCY_TYPE {
  EUR = 'EUR',
  USD = 'USD',
  GBP = 'GBP',
  CHF = 'CHF',
  ZAR = 'ZAR',
  PLN = 'PLN',
  HKD = 'HKD',
  SGD = 'SGD',
}

const PresetValues: Record<Currency, number[]> = {
  USD: [500, 1000, 2000, 3000, 5000],
  EU: [500, 1000, 2000, 3000, 5000],
  PLN: [2000, 5000, 10000, 15000, 20000],
  GBR: [500, 1000, 2000, 3000, 10000],
  CHF: [500, 1000, 2000, 3000, 5000],
  HKD: [4000, 8000, 16000, 25000, 40000],
  SGD: [500, 1000, 2000, 3000, 5000],
  ZAR: [2000, 5000, 10000, 15000, 20000],
}

interface CurrencyAmountChipProps {
  minValue?: number
}

export const CurrencyAmountChip: React.FC<CurrencyAmountChipProps> = ({ minValue = 0 }) => {
  const entity = useSessionEntity()

  const { values, setFieldValue } = useFormikContext<WalletDepositFormValues>()

  const currencyIsZAR = values.paymentProvider.currency.id === CURRENCY_TYPE.ZAR

  const generateChips = (amounts: number[]) => (
    <div className='is-flex is-flex-direction-row'>
      {amounts
        .filter((value) => value >= minValue)
        .map((value) => (
          <div className={styles.depositButton}>
            <Chip
              text={value}
              isActive={values.amount === value}
              onClick={() => setFieldValue('amount', value)}
            />
          </div>
        ))}
    </div>
  )

  if (isTickmillEUType(entity)) {
    switch (values.paymentProvider.currency.id) {
      case CURRENCY_TYPE.USD:
      case CURRENCY_TYPE.EUR:
      case CURRENCY_TYPE.CHF:
      case CURRENCY_TYPE.ZAR:
        return generateChips(PresetValues.EU)

      case CURRENCY_TYPE.PLN:
        return generateChips(PresetValues.PLN)

      case CURRENCY_TYPE.GBP:
        return generateChips(PresetValues.GBR)
    }
  }

  if (isTickmillUKType(entity)) {
    switch (values.paymentProvider.currency.id) {
      case CURRENCY_TYPE.USD:
        return generateChips(PresetValues.USD)

      case CURRENCY_TYPE.PLN:
        return generateChips(PresetValues.PLN)

      case CURRENCY_TYPE.GBP:
        return generateChips(PresetValues.GBR)

      case CURRENCY_TYPE.CHF:
        return generateChips(PresetValues.CHF)

      case CURRENCY_TYPE.HKD:
        return generateChips(PresetValues.HKD)

      case CURRENCY_TYPE.SGD:
        return generateChips(PresetValues.SGD)

      default:
        return generateChips(PresetValues.EU)
    }
  }

  if (
    (isTickmillSCType(entity) || isTickmillPartnerType(entity) || isTickmillASType(entity)) &&
    currencyIsZAR
  ) {
    return generateChips(PresetValues.ZAR)
  }

  if (isTickmillSCType(entity) || isTickmillPartnerType(entity) || isTickmillASType(entity)) {
    switch (values.paymentProvider.currency.id) {
      case CURRENCY_TYPE.USD:
      case CURRENCY_TYPE.EUR:
      case CURRENCY_TYPE.CHF:
        return generateChips(PresetValues.EU)

      case CURRENCY_TYPE.PLN:
        return generateChips(PresetValues.PLN)

      case CURRENCY_TYPE.GBP:
        return generateChips(PresetValues.GBR)
    }
  }

  return null
}
