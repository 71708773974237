import { JSXElementConstructor, ReactElement } from 'react'
import { Navigate, Route } from 'react-router-dom'

export interface RouteConfig {
  path?: string
  element?: JSX.Element
  condition?: boolean
  outlet?: JSX.Element
  locked?: boolean
  children?: RouteConfig[]
}

export const renderRoutes = (
  routes: RouteConfig[]
): ReactElement<any, string | JSXElementConstructor<any>> => {
  return (
    <>
      {routes
        .filter((route) => route.condition !== false)
        .map(({ children, path, element, locked, outlet }) => {
          if (locked) {
            return (
              <Route
                key={path}
                path={path}
                element={<Navigate to='/profile/document-management' />}
              />
            )
          }
          if (children) {
            return (
              <Route key={path} path={path} element={outlet}>
                {renderRoutes(children)}
                <Route index element={element} />
              </Route>
            )
          }
          return <Route key={path} path={path} element={element} />
        })}
    </>
  )
}
