import { useEffect, useState } from 'react'
import { DebouncedState, useDebouncedCallback } from 'use-debounce'

import { isAccountActivatedStatus } from '../../../../../model/AccountDetailedStatus'
import { PaymentProvider } from '../../../../../model/PaymentProviderDto'
import { useAccountReadContext } from '../../../../../utils/AccountContextContext'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { Wallet } from '../WalletDepositForm'

export interface GetDepositCurrencyLimitsValue {
  minAmount: number
  maxAmount: number
}

export type GetDepositCurrencyLimits = DebouncedState<
  (wallet: Wallet, paymentProvider: PaymentProvider) => Promise<GetDepositCurrencyLimitsValue>
>

interface UseGetDepositCurrencyLimitsValue {
  getDepositCurrencyLimits: DebouncedState<GetDepositCurrencyLimits>
  depositCurrencyLimits?: GetDepositCurrencyLimitsValue
}

interface UseGetDepositCurrencyLimitsProps {
  wallet?: Wallet
  paymentProvider?: PaymentProvider
}

const isWalletCurrencyDifferentFromProviderCurrency = (
  wallet: Wallet,
  paymentProvider: PaymentProvider
): boolean =>
  !!wallet.currency.id &&
  !!paymentProvider.currency.id &&
  wallet.currency.id !== paymentProvider.currency.id

export const useGetDepositCurrencyLimits = (
  props: UseGetDepositCurrencyLimitsProps = {}
): UseGetDepositCurrencyLimitsValue => {
  const { wallet, paymentProvider } = props

  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const [depositCurrencyLimits, setDepositCurrencyLimits] =
    useState<GetDepositCurrencyLimitsValue>()

  const getDepositCurrencyLimits = useDebouncedCallback<
    (wallet: Wallet, paymentProvider: PaymentProvider) => Promise<GetDepositCurrencyLimitsValue>
  >(
    async (
      wallet: Wallet,
      paymentProvider: PaymentProvider
    ): Promise<GetDepositCurrencyLimitsValue> => {
      const currencyLimits = paymentProvider?.parameters?.currenciesLimits
        .filter((currenciesLimit) => currenciesLimit.walletType.id === wallet?.walletType?.id)
        .find((currenciesLimit) => currenciesLimit.id === paymentProvider.currency.id)

      const maxAmount = isAccountActivatedStatus(account)
        ? currencyLimits?.maxAmount
        : wallet.depositAction.availableToDeposit

      if (isWalletCurrencyDifferentFromProviderCurrency(wallet, paymentProvider)) {
        const { rate } = await apiClient.getExchangeRate({
          BaseCcy: wallet.currency.id,
          QuoteCcy: paymentProvider.currency.id,
          BaseWalletTypeId: wallet.walletType.id,
          QuoteWalletTypeId: currencyLimits?.walletType.id,
          direction: 'sell',
        })

        return {
          minAmount: currencyLimits?.minAmount || 0,
          maxAmount: (maxAmount || 0) * rate,
        }
      }

      return {
        minAmount: currencyLimits?.minAmount || 0,
        maxAmount: maxAmount || 0,
      }
    },
    350
  )

  useEffect(() => {
    if (!account?.id || !wallet?.currency.id.length || !paymentProvider?.currency.id.length) {
      return
    }
    ;(async () => {
      const depositCurrencyLimits = await getDepositCurrencyLimits(wallet, paymentProvider)
      setDepositCurrencyLimits(depositCurrencyLimits)
    })()
  }, [account?.id, apiClient, getDepositCurrencyLimits, wallet, paymentProvider])

  return { getDepositCurrencyLimits, depositCurrencyLimits }
}
