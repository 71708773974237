import { useTranslation } from 'react-i18next'

import { SelectModal, SelectModalOption } from '../../global/field/SelectModal'
import { Modal } from '../../global/modal/Modal'
import { CurrencyType } from '../../model/WalletDto'
import { isZero } from '../../utils/validations'

interface CampaignCurrencyModalProps {
  currencies?: CurrencyType[]

  onSelectOption(option: CurrencyType): void

  onClose(): void

  selectedCurrencyId: CurrencyType | undefined
}

export const CampaignCurrencyModal: React.FC<CampaignCurrencyModalProps> = (props) => {
  const { currencies, onSelectOption, onClose, selectedCurrencyId } = props

  const { t } = useTranslation()

  const renderOptions = () => {
    if (!currencies || isZero(currencies.length)) {
      return <div className='p-6'>{t('No currencies available')}</div>
    }

    return (
      <div className='control'>
        {currencies.map((currency) => (
          <SelectModalOption
            key={currency}
            value={currency === selectedCurrencyId}
            label={currency}
            onClick={() => onSelectOption(currency)}
          />
        ))}
      </div>
    )
  }

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Campaigns.Currency')}
          renderOptions={renderOptions}
        />
      )}
    />
  )
}
