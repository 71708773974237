import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { InfoIcon } from '../../icons/InfoIcon'
import { CurrencyType } from '../../model/WalletDto'

import styles from './ExchangeRateBox.module.scss'

interface ExchangeRateBoxProps {
  currencyFrom: CurrencyType
  currencyTo: CurrencyType
  calculatedAmount: number | string
  className?: string
  infoIconClick?: () => void
}

export const ExchangeRateBox: React.FC<ExchangeRateBoxProps> = ({
  calculatedAmount,
  currencyTo,
  currencyFrom,
  className,
  infoIconClick,
}) => {
  const { t } = useTranslation()
  return (
    <div className={classNames(styles.exchangeWrapper, className)}>
      <span>{t('Wallet.Indicative Rate')}:</span>
      <span className={classNames('pl-2', styles.calculation)}>
        1 {currencyFrom} = {calculatedAmount} {currencyTo}
      </span>
      {infoIconClick && (
        <span className={classNames('has-cursor-pointer', styles.icon)} onClick={infoIconClick}>
          <InfoIcon />
        </span>
      )}
    </div>
  )
}
