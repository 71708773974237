import { IconProps, iconColor, strokeIconClassName } from './IconProps'

export function TradingAccountsEuroIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={strokeIconClassName(props)}
    >
      <circle cx='12' cy='12' r='10' stroke={iconColor(props)} strokeWidth='1.5' />
      <path
        d='M15.5 8.5C14.8078 7.80777 13.9258 7.33635 12.9656 7.14536C12.0055 6.95437 11.0103 7.0524 10.1058 7.42703C9.20137 7.80167 8.42832 8.43609 7.88444 9.25007C7.34055 10.064 7.05026 11.021 7.05026 12C7.05026 12.979 7.34055 13.936 7.88444 14.7499C8.42832 15.5639 9.20137 16.1983 10.1058 16.573C11.0103 16.9476 12.0055 17.0456 12.9656 16.8546C13.9258 16.6637 14.8078 16.1922 15.5 15.5'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M5.46255 13.3203H12.5943'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M5.46255 10.3486H12.5943'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
