export interface LeverageTypeDto {
  id: number
  name: string
  amount: number
}

export enum LeverageType {
  Dynamic = 100,
}

export const isLeverageTypeDynamic = (leverageId?: number): boolean =>
  leverageId === LeverageType.Dynamic
