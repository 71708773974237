import { useEffect } from 'react'

export const dispatchLangSelectEvent = (): void => {
  const event = new Event('isLangSelected')
  document.dispatchEvent(event)
}

export const useIsLangSelected = (handleLangSelectedEvent: () => void): void => {
  useEffect(() => {
    document.addEventListener('isLangSelected', handleLangSelectedEvent)

    return () => {
      document.removeEventListener('isLangSelected', handleLangSelectedEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
