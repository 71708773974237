import React, { FormEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../../../../../global/button/Button'
import { useArabicSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { BackIcon } from '../../../../../icons/BackIcon'
import { ForwardIcon } from '../../../../../icons/ForwardIcon'
import { TestSectionDto } from '../../../../../model/TestSectionsDto'
import {
  QuestionFieldFactory,
  showConditionalQuestions,
} from '../../../../../ui/TestFields/TestFields'

import styles from '../CompanyDetailsStep.module.scss'

export interface CompanyDetailsStep4FormValues {
  clientTests: {
    category: string
    testId: string
    dateTaken: string
    selectedAnswers?: string[]
    freeAnswers?: { TestQuestionId?: string; Answer?: string }[]
  }[]
  selectedAnswers?: string[]
  freeAnswers?: { [key: string]: string }
}

interface OuterProps {
  sections?: TestSectionDto[]
  testId?: string
  onSubmit(values: CompanyDetailsStep4FormValues): void
}

const CompanyDetailsStep4FormUI: React.FC<
  FormikProps<CompanyDetailsStep4FormValues> & OuterProps
> = (props) => {
  const { handleSubmit, validateForm, setSubmitting, sections, errors, isSubmitting, isValid } =
    props

  const onSubmit: FormEventHandler = (e) => {
    validateForm()
    e.preventDefault()

    if (!Object.keys(errors).length) {
      setSubmitting(true)
      handleSubmit()
    }
  }

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()

  return (
    <React.Fragment>
      <Form className={styles.form} onSubmit={onSubmit} autoComplete='off'>
        {sections?.map((section) => (
          <QuestionFieldFactory section={section} />
        ))}
        <Button
          className={styles.button}
          type='submit'
          appearance='primary'
          size='L'
          onClick={() => validateForm()}
          renderRightIcon={() => (
            <span className='is-flex is-align-items-center'>
              {isArabic ? <BackIcon inverse /> : <ForwardIcon inverse />}
            </span>
          )}
          disabled={isSubmitting || !isValid}
        >
          {t('Next')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

export const CompanyDetailsStep4Form = withFormik<OuterProps, CompanyDetailsStep4FormValues>({
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.onSubmit({
        clientTests: [
          {
            freeAnswers: Object.entries(values.freeAnswers ?? {}).map(([key, value]) => ({
              TestQuestionId: key,
              Answer: value,
            })),
            selectedAnswers: Object.values(values.selectedAnswers ?? {}).flatMap((value) => value),
            testId: props.testId ?? '',
            category: 'Corporate test',
            dateTaken: new Date().toISOString(),
          },
        ],
      })
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, props) => {
    const errors: FormikErrors<CompanyDetailsStep4FormValues> = {}
    props.sections?.forEach((section) => {
      section.questions
        .filter(({ widget }) =>
          showConditionalQuestions(
            section?.questions,
            Object.values(values.selectedAnswers ?? {}).flatMap((value) => value),
            widget
          )
        )
        .forEach((question) => {
          const options = question.widget.widgetOptions
          const answer =
            values.freeAnswers?.[question.question.id] ??
            Object.values(values.selectedAnswers ?? {})
              .flatMap((value) => value)
              ?.find((x) => question.answers.some((answer) => answer.id === x))

          if (question.isMandatory && !answer) {
            errors[question.widget.name as keyof CompanyDetailsStep4FormValues] =
              t('Validation.Required')
          } else if (options?.regExp) {
            const regExp = new RegExp(options.regExp)
            if (answer && !regExp.test(answer.toString())) {
              errors[question.widget.name as keyof CompanyDetailsStep4FormValues] =
                t(`Validation.Invalid format`)
            }
          }
        })
    })
    console.log({ errors })
    return errors
  },
  enableReinitialize: true,
  isInitialValid: false,
})(CompanyDetailsStep4FormUI)
