/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './layout/site.scss'
import { Referral } from './Referral/Referral'
import { NotificationsContextProvider } from './global/context/NotificationsContext'
import { usePathLocale } from './global/locale/Locale'
import { initiateNotificationsContext } from './global/notification/notification'
import { Session } from './routing/Session/Session'
import { AccountContextProvider, initiateAccountContext } from './utils/AccountContextContext'
import { ApiClient, ApiClientContext } from './utils/ApiClient'
import { CategorySizesContextProvider } from './utils/CategorySizesContext'
import { IBCampaignsResultsContextProvider } from './utils/IBCampaignsResultsContext'
import { MaintenanceModeProvider } from './utils/MaintenanceModeContext'
import { PathHistoryProvider } from './utils/PathHistoryContext'
import { SharedContext, StateSharedState, initialSharedContext } from './utils/SharedContext'

export const App: React.FC = () => {
  const apiClient = useMemo(() => new ApiClient(), [])
  const newBasename = usePathLocale()
  const [sharedState, setSharedState] = useState<StateSharedState>(initialSharedContext)
  const [baseLanguage, setBaseLanguage] = useState<string>(newBasename)

  useEffect(() => {
    const handlePopState = () => newBasename !== baseLanguage && setBaseLanguage(newBasename)
    window.addEventListener('popstate', handlePopState)
    return () => window.removeEventListener('popstate', handlePopState)
  }, [baseLanguage, newBasename])

  return (
    <BrowserRouter basename={baseLanguage}>
      <ApiClientContext.Provider value={apiClient}>
        <AccountContextProvider account={initiateAccountContext()}>
          <SharedContext.Provider value={[sharedState, setSharedState]}>
            <PathHistoryProvider>
              <MaintenanceModeProvider>
                <IBCampaignsResultsContextProvider
                  account={initiateAccountContext() || undefined}
                  campaignsResults={[]}
                >
                  <CategorySizesContextProvider categorySizes={[]}>
                    <Referral>
                      <NotificationsContextProvider
                        initialSettings={initiateNotificationsContext()}
                      >
                        <Routes>
                          <Route path='*' element={<Session />} />
                        </Routes>
                      </NotificationsContextProvider>
                    </Referral>
                  </CategorySizesContextProvider>
                </IBCampaignsResultsContextProvider>
              </MaintenanceModeProvider>
            </PathHistoryProvider>
          </SharedContext.Provider>
        </AccountContextProvider>
      </ApiClientContext.Provider>
    </BrowserRouter>
  )
}

export default App
