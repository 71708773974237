import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

interface Props {
  changeInvestorPassword: boolean
  changeTickmillTraderPassword: boolean
}
export const PasswordChangeMessageModal: React.FC<Props> = ({
  changeInvestorPassword,
  changeTickmillTraderPassword,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const goToDashboard = () => {
    navigate(`/dashboard/traders-room/trading-accounts`)
  }
  const headerText = useMemo(() => {
    if (changeInvestorPassword) {
      return t('Trading Account.Investor Password Changed Successfully!')
    } else if (changeTickmillTraderPassword) {
      return t('Trading Account.Trading Account User ID Password Changed Successfully')
    }
    return t('Trading Account.Trading Account Password Changed Successfully!')
  }, [changeInvestorPassword, changeTickmillTraderPassword, t])

  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{headerText}</p>
      </header>
      <section className={'modal-card-body'}>
        {changeTickmillTraderPassword && (
          <p className='text'>
            {t(
              'Trading Account.Note, the same password will be used to access all your Trading Accounts created in the Tickmill Trader platform'
            )}
          </p>
        )}
      </section>
      <footer className='modal-card-foot'>
        <button className='button' onClick={goToDashboard}>
          {t('Ok')}
        </button>
      </footer>
    </>
  )
}
