import React from 'react'
import classNames from 'classnames'

import styles from './Icons.module.scss'

export const ClientBaseIcon = ({ className }: { className?: string }) => (
  <svg
    className={classNames(className)}
    width='68'
    height='132'
    viewBox='0 0 68 132'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M33.5291 0C25.6443 0 19.2524 6.43315 19.2524 14.3688C19.2524 22.3045 25.6443 28.7377 33.5291 28.7377C41.4139 28.7377 47.8058 22.3045 47.8058 14.3688C47.8058 6.43315 41.4139 0 33.5291 0ZM21.2445 14.3688C21.2445 7.54045 26.7445 2.00495 33.5291 2.00495C40.3137 2.00495 45.8137 7.54045 45.8137 14.3688C45.8137 21.1972 40.3137 26.7327 33.5291 26.7327C26.7445 26.7327 21.2445 21.1972 21.2445 14.3688Z'
      fill={styles.primary}
    />
    <path
      d='M33.527 35.0342C30.0253 35.0342 24.7844 35.1881 20.1217 36.267C17.7902 36.8065 15.5483 37.5892 13.727 38.7384C11.8975 39.8929 10.4422 41.4513 9.80761 43.5407C8.71859 47.1261 7.77075 50.1867 7.09522 52.3508C6.75747 53.4328 6.48781 54.2906 6.30265 54.8777C6.21007 55.1713 6.13861 55.3972 6.09033 55.5496L6.01706 55.7806C5.84933 56.3079 6.13806 56.8722 6.66197 57.041C7.18587 57.2098 7.74658 56.9191 7.91432 56.3918L7.98829 56.1586C8.03683 56.0054 8.10855 55.7787 8.2014 55.4843C8.3871 54.8954 8.65735 54.0357 8.99573 52.9517C9.67249 50.7837 10.6219 47.7181 11.7127 44.1268C12.168 42.6278 13.2315 41.4175 14.7851 40.4371C16.3469 39.4517 18.3525 38.7336 20.5681 38.221C24.9998 37.1955 30.0524 37.0392 33.527 37.0392C37.0015 37.0392 42.0541 37.1955 46.4858 38.221C48.7014 38.7337 50.707 39.4517 52.2688 40.4372C53.8224 41.4176 54.8859 42.6279 55.3412 44.1269C56.432 47.7181 57.3814 50.7837 58.0582 52.9517C58.3966 54.0358 58.6668 54.8954 58.8525 55.4843C58.9454 55.7787 59.0171 56.0054 59.0656 56.1586L59.1393 56.3909L59.1396 56.3918C59.3073 56.9191 59.868 57.2098 60.3919 57.041C60.9158 56.8722 61.2046 56.3078 61.0368 55.7805L61.0366 55.7797L60.9636 55.5496C60.9153 55.3972 60.8438 55.1713 60.7513 54.8777C60.5661 54.2906 60.2964 53.4328 59.9587 52.3508C59.2832 50.1867 58.3353 47.1261 57.2463 43.5407C56.6117 41.4514 55.1564 39.8929 53.3269 38.7385C51.5056 37.5892 49.2637 36.8065 46.9322 36.267C42.2695 35.1881 37.0286 35.0342 33.527 35.0342Z'
      fill={styles.primary}
    />
    <path
      d='M5.34657 94.2703C4.93538 94.2703 4.60205 94.7191 4.60205 95.2727C4.60205 95.8263 4.93538 96.2751 5.34657 96.2751H58.6543C59.0654 96.2751 59.3988 95.8263 59.3988 95.2727C59.3988 94.7191 59.0654 94.2703 58.6543 94.2703H5.34657Z'
      fill={styles.contrastMediumLow}
    />
    <path
      d='M5.34657 103.494C4.93538 103.494 4.60205 103.943 4.60205 104.496C4.60205 105.05 4.93538 105.499 5.34657 105.499H47.9332C48.3443 105.499 48.6777 105.05 48.6777 104.496C48.6777 103.943 48.3443 103.494 47.9332 103.494H5.34657Z'
      fill={styles.contrastMediumLow}
    />
    <path
      d='M4.60205 113.717C4.60205 113.163 4.93538 112.715 5.34657 112.715H62.8236C63.2348 112.715 63.5681 113.163 63.5681 113.717C63.5681 114.271 63.2348 114.72 62.8236 114.72H5.34657C4.93538 114.72 4.60205 114.271 4.60205 113.717Z'
      fill={styles.contrastMediumLow}
    />
    <path
      d='M5.34657 121.939C4.93538 121.939 4.60205 122.388 4.60205 122.941C4.60205 123.495 4.93538 123.944 5.34657 123.944H33.6384C34.0495 123.944 34.3829 123.495 34.3829 122.941C34.3829 122.388 34.0495 121.939 33.6384 121.939H5.34657Z'
      fill={styles.contrastMediumLow}
    />
    <path
      d='M5.5981 73.4187C5.048 73.4187 4.60205 73.8675 4.60205 74.4212C4.60205 74.9748 5.048 75.4237 5.5981 75.4237H33.8859C34.436 75.4237 34.8819 74.9748 34.8819 74.4212C34.8819 73.8675 34.436 73.4187 33.8859 73.4187H5.5981Z'
      fill={styles.contrastMedium}
    />
    <path
      d='M5.5981 85.0474C5.048 85.0474 4.60205 85.4963 4.60205 86.0499C4.60205 86.6036 5.048 87.0524 5.5981 87.0524H62.572C63.1222 87.0524 63.5681 86.6036 63.5681 86.0499C63.5681 85.4963 63.1222 85.0474 62.572 85.0474H5.5981Z'
      fill={styles.contrastMedium}
    />
  </svg>
)
