import { IconProps, iconColor } from './IconProps'

import styles from './Icons.module.scss'

export function TrashIcon(props: IconProps): JSX.Element {
  const size = props.size || 24

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 18 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M3 4L4 17H14L15 4M3 4H1M3 4H15M15 4H17M6 1H12M7 7V14M11 7V14'
        stroke={props.color ? styles[props.color] : iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
