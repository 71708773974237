import { IconProps } from './IconProps'

export function BronzeIcon(props: IconProps) {
  const size = props.size || 36
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='#957444'
      />
      <path d='M15.353 6L21.6765 15.1232H14.3236L8 6H15.353Z' fill='white' />
      <path d='M20.647 6H28L22.2647 14.2109L18.5882 8.88902L20.647 6Z' fill='white' />
      <path
        d='M21.6766 25.4641C21.6766 27.5635 20.0306 29.2654 18.0001 29.2654C15.9696 29.2654 14.3236 27.5635 14.3236 25.4641C14.3236 23.3646 15.9696 21.6627 18.0001 21.6627C20.0306 21.6627 21.6766 23.3646 21.6766 25.4641Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.6765 20.1398V16.1864H14.3236V20.1398C12.7059 21.3562 11.6765 23.1809 11.6765 25.4617C11.6765 29.111 14.4706 32 18 32C21.5295 32 24.3236 29.111 24.3236 25.4617C24.3236 23.1809 23.2942 21.3562 21.6765 20.1398ZM15.353 17.2508H20.6471V19.5316C19.9118 19.2275 19.0295 18.9234 18 18.9234C17.1177 18.9234 16.2353 19.0754 15.353 19.5316V17.2508ZM18 30.3274C15.353 30.3274 13.2941 28.1987 13.2941 25.4617C13.2941 22.7247 15.353 20.596 18 20.596C20.6471 20.596 22.7059 22.7247 22.7059 25.4617C22.7059 28.0466 20.6471 30.3274 18 30.3274Z'
        fill='white'
      />
    </svg>
  )
}

export function SilverIcon(props: IconProps) {
  const size = props.size || 36
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='#BFBFBF'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.4706 28.3784V27.4727C16.4706 25.8122 15.8693 24.3027 14.817 22.9441C12.7124 20.227 11.5098 17.057 11.5098 13.5852V8H25.3399V13.5852C25.3399 17.057 24.1373 20.378 22.0327 22.9441C20.9804 24.3027 20.3791 25.8122 20.3791 27.4727V28.3784H16.4706ZM15.719 14.4909C15.5686 14.4909 15.4183 14.4909 15.4183 14.6418C15.4183 14.6418 15.4183 14.7928 15.5686 14.9437L16.9216 16.1513L16.4706 17.8118C16.4706 17.9628 16.4706 18.1137 16.6209 18.1137H16.9216L18.4249 17.208L19.9281 18.1137C19.9281 18.207 19.9855 18.185 20.0649 18.1545C20.1139 18.1357 20.1714 18.1137 20.2288 18.1137C20.3791 18.1137 20.3791 17.9628 20.3791 17.8118L19.9281 16.1513L21.5817 14.9437C21.732 14.9437 21.732 14.7928 21.732 14.6418C21.732 14.6418 21.5817 14.4909 21.4314 14.4909L19.6275 14.3399L18.8758 12.6795C18.8758 12.5285 18.7255 12.5285 18.5752 12.5285C18.4249 12.5285 18.2745 12.5285 18.2745 12.6795L17.5229 14.3399L15.719 14.4909Z'
        fill='white'
      />
      <path
        d='M16.0197 29.4338H20.9805C22.3334 29.4338 23.3857 30.4905 23.3857 32H13.4641C13.4641 30.4905 14.6668 29.4338 16.0197 29.4338Z'
        fill='white'
      />
      <path
        d='M26.3921 9.35915V10.4158H28.9477V10.7177C28.9477 14.1896 27.4444 17.6614 25.0392 20.0767C24.5882 20.9824 24.1372 21.7371 23.6863 22.4919C27.5948 19.9257 30 15.3972 30 10.7177V9.35915H26.3921Z'
        fill='white'
      />
      <path
        d='M8.05229 10.4158V10.8687C8.05229 14.3405 9.40523 17.6614 11.9608 20.0767C12.2614 20.9824 12.7124 21.7371 13.3137 22.4919C9.40523 19.9257 7 15.3972 7 10.7177V9.35915H10.6078V10.4158H8.05229Z'
        fill='white'
      />
    </svg>
  )
}

export function GoldIcon(props: IconProps) {
  const size = props.size || 36
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='#D3AE37'
      />
      <path
        d='M15.9551 23.1985H20.9213V22.5873L19.3146 21.8232V14.3352H17.5618C16.9775 14.9465 15.9551 15.4049 15.5169 15.7105V16.3218H17.8539V21.8232L15.9551 22.5873V23.1985Z'
        fill='white'
      />
      <path
        d='M19.7528 28.2415C22.382 29.7696 25.8876 29.0056 28.0787 25.7964C24.427 24.7267 21.3596 25.338 19.7528 28.2415Z'
        fill='white'
      />
      <path
        d='M25.1573 24.1154C27.9326 24.2683 30.5618 22.1288 31 18.4612C27.4944 19.0725 25.3034 20.9063 25.1573 24.1154Z'
        fill='white'
      />
      <path
        d='M25.0112 19.0725C27.6404 18.3084 29.6854 15.5577 29.1011 11.8901C26.0337 13.5711 24.2809 16.0162 25.0112 19.0725Z'
        fill='white'
      />
      <path
        d='M23.5506 7C21.6517 10.2091 21.3596 13.1127 23.5506 15.4049C25.4494 13.2655 25.7416 9.7507 23.5506 7Z'
        fill='white'
      />
      <path
        d='M7.92135 25.7964C10.1124 29.0056 13.618 29.7696 16.2472 28.2415C14.6404 25.338 11.573 24.7267 7.92135 25.7964Z'
        fill='white'
      />
      <path
        d='M5 18.4612C5.4382 22.1288 8.21348 24.2683 10.8427 24.1154C10.6966 20.9063 8.50562 19.0725 5 18.4612Z'
        fill='white'
      />
      <path
        d='M10.9888 19.0725C11.7191 16.0162 10.1124 13.5711 6.89888 11.8901C6.31461 15.5577 8.35955 18.4612 10.9888 19.0725Z'
        fill='white'
      />
      <path
        d='M12.4494 7C10.2584 9.90351 10.5506 13.4183 12.4494 15.4049C14.6404 13.1127 14.3483 10.0563 12.4494 7Z'
        fill='white'
      />
    </svg>
  )
}

export function PlatinumIcon(props: IconProps) {
  const size = props.size || 36
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='#797979'
      />
      <path
        d='M24.7678 11.9525L8.92841 17.1366C8.64042 17.2806 8.35244 17.1366 8.35244 16.8486L7.05649 12.8165C6.9125 12.5285 7.05649 12.2405 7.34448 12.2405L23.1838 7.05649C23.4718 6.91249 23.7598 7.05649 23.7598 7.34449L25.0557 11.3765C25.1997 11.5205 25.0557 11.8085 24.7678 11.9525Z'
        fill='white'
      />
      <path
        d='M26.6397 17.856L10.8004 23.04C10.5124 23.184 10.2244 23.04 10.2244 22.752L8.92848 18.72C8.78449 18.432 8.92848 18.144 9.21647 18.144L25.0558 12.96C25.3438 12.816 25.6318 12.96 25.6318 13.248L26.9277 17.28C27.0717 17.568 26.9277 17.712 26.6397 17.856Z'
        fill='white'
      />
      <path
        d='M28.6555 23.7595L12.8162 28.9435C12.5282 29.0875 12.2402 28.9435 12.2402 28.6555L10.9443 24.6235C10.8003 24.3355 10.9443 24.0475 11.2322 24.0475L27.0716 18.8634C27.3596 18.7194 27.6476 18.8634 27.6476 19.1514L28.9435 23.1835C29.0875 23.4715 28.9435 23.7595 28.6555 23.7595Z'
        fill='white'
      />
    </svg>
  )
}

export function DiamondIcon(props: IconProps) {
  const size = props.size || 36
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='#C1B49A'
      />
      <path d='M12.5316 14.5255L16.7848 10H9.79747L12.5316 14.5255Z' fill='white' />
      <path d='M6 14.8175H11.4684L8.88608 10.438L6 14.8175Z' fill='white' />
      <path d='M26.3544 10H19.3671L23.4684 14.5255L26.3544 10Z' fill='white' />
      <path d='M13.7468 14.8175H22.4051L18 10.146L13.7468 14.8175Z' fill='white' />
      <path d='M27.2658 10.438L24.5316 14.8175H30L27.2658 10.438Z' fill='white' />
      <path d='M23.9241 15.8394L19.3671 29.562L29.8481 15.8394H23.9241Z' fill='white' />
      <path d='M6.3038 15.8394L16.9367 29.562L12.076 15.8394H6.3038Z' fill='white' />
      <path d='M22.8608 15.8394H13.1392L18 30L22.8608 15.8394Z' fill='white' />
    </svg>
  )
}

export function EliteIcon(props: IconProps) {
  const size = props.size || 36
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='#2B398F'
      />
      <path
        d='M26.7903 9.42857L18.1917 6C18.0434 6 18.0434 6 17.8952 6L9.29651 9.42857C9.14825 9.42857 9 9.58441 9 9.74026V19.7143C9 25.3247 13.0028 29.8442 18.0434 30C23.084 29.8442 26.9386 25.3247 26.9386 19.7143V9.74026C27.0868 9.58441 26.9386 9.42857 26.7903 9.42857ZM23.3805 17.0649L21.1567 19.7143L21.6015 23.2987C21.6015 23.4545 21.6015 23.6104 21.4532 23.7662C21.4532 23.7662 21.305 23.9221 21.1567 23.9221H21.0085L18.0434 22.3636L15.0784 23.9221C14.9301 23.9221 14.7819 23.9221 14.6336 23.9221C14.4854 23.7662 14.4854 23.6104 14.4854 23.4545L14.9301 19.8701L12.7063 17.2208C12.5581 17.0649 12.5581 16.9091 12.5581 16.7532C12.5581 16.5974 12.7063 16.4416 12.8546 16.4416L16.1161 15.8182L17.5987 12.5455C17.7469 12.2338 18.1917 12.2338 18.3399 12.5455L19.8225 15.8182L23.084 16.4416C23.2323 16.4416 23.3805 16.5974 23.3805 16.7532C23.5288 16.7532 23.5288 16.9091 23.3805 17.0649Z'
        fill='white'
      />
    </svg>
  )
}

export function ElitePlusIcon(props: IconProps) {
  const size = props.size || 36
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='#262262'
      />
      <path
        d='M17.1148 17.0769V13.5385H18.8852V17.0769H27V9.84615C27 9.69231 26.8525 9.53846 26.7049 9.38462L18.1475 6C18 6 18 6 17.8525 6L9.29508 9.53846C9.14754 9.53846 9 9.69231 9 9.84615V17.0769H17.1148Z'
        fill='white'
      />
      <path
        d='M18.8852 18.9231V23.0769H17.1148V18.9231H9V19.8462C9 25.3846 12.9836 29.8462 18 30C23.0164 29.8462 26.8525 25.3846 26.8525 19.8462V18.9231H18.8852Z'
        fill='white'
      />
    </svg>
  )
}

export function LegendIcon(props: IconProps) {
  const size = props.size || 36
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='#662D90'
      />
      <path d='M18 10.8176L27 15.0135V10.3514L18 6L9 10.1959V14.8581L18 10.8176Z' fill='white' />
      <path
        d='M18 17.8108L27 22.0068V17.3446L18 12.9932L9 17.1892V21.8514L18 17.8108Z'
        fill='white'
      />
      <path d='M18 24.8041L27 29V24.3378L18 20.1419L9 24.3378V29L18 24.8041Z' fill='white' />
    </svg>
  )
}

export function UltimateIcon(props: IconProps) {
  const size = props.size || 36
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 36 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z'
        fill='#231F20'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M23.8999 26.8541C22.8522 25.3405 21.9542 24.2811 20.9065 23.5243C20.7569 24.1297 20.3079 25.3405 19.4098 25.3405H18.0628H16.7158C15.8178 25.3405 15.5184 24.1297 15.2191 23.5243C14.1714 24.2811 13.2734 25.3405 12.2257 26.8541C11.3277 24.8865 10.8787 22.6162 11.0284 21.7081C11.4774 20.0432 12.3754 18.8324 13.1237 18.0757C12.3754 13.0811 13.1237 7.17838 17.9132 4C22.7026 7.32973 23.4509 13.0811 22.7026 18.0757C23.6006 18.8324 24.4986 19.8919 24.7979 21.7081C25.247 22.6162 24.9476 24.7351 23.8999 26.8541ZM18.2125 10.2054C19.4098 10.2054 20.3079 11.1135 20.3079 12.3243C20.3079 13.5351 19.4098 14.4432 18.2125 14.4432C17.0151 14.4432 16.1171 13.5351 16.1171 12.3243C16.1171 11.2649 17.0151 10.2054 18.2125 10.2054ZM16.7158 26.4C15.0694 28.973 17.3145 30.3351 18.2125 32C19.1105 30.3351 21.3556 28.973 19.7092 26.4C19.7092 27.6108 19.5595 28.5189 18.2125 29.2757C16.8655 28.6703 16.7158 27.6108 16.7158 26.4Z'
        fill='white'
      />
    </svg>
  )
}
