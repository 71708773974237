import styles from './Icons.module.scss'

export function DepositIcon(props: { size?: number }) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <ellipse
        cx='12'
        cy='12.75'
        rx='2'
        ry='2.5'
        stroke={styles.contrastMediumLow}
        strokeWidth='0.9'
      />
      <path
        d='M16.5 7.50063L20 7.50052C20.5523 7.5005 21 7.94822 21 8.50052V17.5005C21 18.0528 20.5523 18.5005 20 18.5005H4C3.44772 18.5005 3 18.0528 3 17.5005V8.50006C3 7.948 3.44737 7.50038 3.99943 7.50006L7.5 7.49805'
        stroke={styles.contrastMediumLow}
        strokeWidth='0.9'
        strokeLinecap='round'
      />
      <path
        d='M11.9998 7.125L15.2498 3.875M11.9998 7.125L8.74976 3.875M11.9998 7.125L12 1'
        stroke={styles.contrastMediumLow}
        strokeWidth='0.9'
        strokeLinecap='round'
      />
    </svg>
  )
}
