import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useFormikContext } from 'formik'

import { TradingAccountDepositFormValues } from '../../Traders-Room/Trading-Accounts/TradingAccountDeposit/TradingAccountDepositForm/TradingAccountDepositForm'
import { WalletDepositFormValues } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'
import { AlertIcon } from '../../icons/AlertIcon'
import { ExternalLinkIcon } from '../../icons/ExternalLinkIcon'
import { CurrencyType } from '../../model/WalletDto'
import { Text, TextH3, TextSmall, TextStrong } from '../../ui/Typography/Typography'
import { getCurrencySymbol } from '../../utils/transaction.utils'
import { Button } from '../button/Button'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'

import styles from './CardProceedModal.module.scss'

interface CardProceedModalProps {
  title?: string
  thirdPartyTransferWarning: string
  cardHolderNameInstruction: string
  onProceed(): void
  onCancel?(): void
}

type FormValues = WalletDepositFormValues | TradingAccountDepositFormValues

const isWalletDepositFormValues = (values: FormValues): values is WalletDepositFormValues =>
  (values as WalletDepositFormValues).wallet !== undefined

const isTradingAccountDepositFormValues = (
  values: FormValues
): values is TradingAccountDepositFormValues =>
  (values as TradingAccountDepositFormValues).tradingAccount !== undefined

export const CardProceedModal: React.FunctionComponent<
  PropsWithChildren<CardProceedModalProps>
> = ({ title, onProceed, thirdPartyTransferWarning, cardHolderNameInstruction }) => {
  const { values } = useFormikContext<FormValues>()
  const { t } = useTranslation()

  const isArabic = useArabicSessionLanguage()

  const [currency, setCurrency] = useState<CurrencyType | undefined>(undefined)

  useEffect(() => {
    if (isWalletDepositFormValues(values)) {
      setCurrency(values.wallet.currency.id as CurrencyType)
    } else if (isTradingAccountDepositFormValues(values)) {
      setCurrency(values.tradingAccount.currency.id as CurrencyType)
    }
  }, [values])

  return (
    <section className={styles.body}>
      <div className={styles.content}>
        <AlertIcon color='warning' size={48} />
        <TextH3>{`${title} ${getCurrencySymbol(currency as CurrencyType)}${values.amount}`}</TextH3>
        <div className={styles.depositInfo}>
          <TextSmall isParagraph>{thirdPartyTransferWarning}</TextSmall>
          <TextSmall>{cardHolderNameInstruction}</TextSmall>
        </div>

        <Button
          appearance='secondary'
          className={styles.button}
          size='S'
          type='submit'
          onClick={onProceed}
          renderRightIcon={() => (
            <span className={classNames(styles.externalIcon, { [styles.iconFlipped]: isArabic })}>
              <ExternalLinkIcon themable size={14} />
            </span>
          )}
        >
          {t('Proceed')}
        </Button>
      </div>
    </section>
  )
}
