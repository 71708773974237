import classNames from 'classnames'

import { useOnClickedOutside } from '../../utils/useOnClickedOutside'

import styles from './Dropdown.module.scss'

export interface DropdownProps {
  className?: string
  children?: React.ReactNode
  position?: 'top' | 'bottom'
  onClose: () => void
}

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const { className, children, onClose, position } = props
  const onClickOutside = useOnClickedOutside<HTMLUListElement>(onClose)

  return (
    <ul
      ref={onClickOutside}
      className={classNames(className, styles.dropdownList, {
        [styles.bottom]: position === 'bottom',
        [styles.top]: position === 'top',
      })}
    >
      {children}
    </ul>
  )
}
