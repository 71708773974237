import React from 'react'

import styles from './Badge.module.scss'

export type BadgeVariantType =
  | 'active'
  | 'pending'
  | 'completed'
  | 'ended'
  | 'approved'
  | 'cancelled'
  | 'failed'
  | 'declined'
  | 'rejected'
  | 'reversed'
  | 'new'
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'successNew'
  | 'surfaceSuccess'
  | 'warning'
  | 'danger'
  | 'light'
  | 'demo'
  | 'live'
  | 'inactive'
  | 'expired'

export interface BadgeProps {
  text: string
  appearance?: BadgeVariantType
  onClick?(): void
}

export const Badge: React.FC<BadgeProps> = ({ text, appearance = 'primary', onClick }) => {
  return (
    <span className={styles?.[appearance]} onClick={onClick}>
      {text}
    </span>
  )
}
