export interface WalletPaymentProviderMethodParametersResponseDto {
  reply: WalletPaymentProviderParametersDto
}

export interface WalletPaymentProviderParametersDto {
  form: FormItemDto[]
  misc: WalletPaymentProviderParameterMiscDto
  messages: WalletPaymentProviderParameterMessageDto[]
  tc: string[]
}

export interface FormItemDto extends FormItemDtoBase {
  value: FormItemValueDto
}

export interface FormItemDtoBase {
  field: string
  name: string
  type: string
  grouping: null
  clientFilled: boolean
  systemFilled: boolean
  mandatory: boolean
}

export interface FormItemInputDto extends FormItemDtoBase {
  field: string
  name: string
  value: string
  type: string
  grouping: null
  clientFilled: boolean
  systemFilled: boolean
  mandatory: boolean
}

export const isFormItemInputDto = (
  formItem: FormItemDto | undefined
): formItem is FormItemInputDto => {
  return typeof formItem?.value === 'string'
}

export interface FormItemSelectDto extends FormItemDtoBase {
  field: string
  name: string
  value: { value: string; name: string; default: boolean }[]
  type: string
  grouping: null
  clientFilled: boolean
  systemFilled: boolean
  mandatory: boolean
}

export const isFormItemSelectDto = (
  formItem: FormItemDto | undefined
): formItem is FormItemSelectDto => {
  return Array.isArray(formItem?.value)
}

type FormItemValueDto = string | { value: string; name: string; default: boolean }[]

export interface WalletPaymentProviderParameterMiscDto {
  [key: string]: {
    [key: string]: {
      min_amount: number
      max_amount: number
      accepted_decimals: number
    }
  }
}

export interface WalletPaymentProviderParameterMessageDto {
  title: string[]
  top: string[]
  list: string[]
  bottom: string[]
  category: string
}
