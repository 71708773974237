import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, LinkProps } from 'react-router-dom'

import { Button } from '../../../global/button/Button'
import { SearchNoContentIcon } from '../../../icons/SearchNoContentIcon'
import { Text, TextStrong } from '../../Typography/Typography'

import styles from './NoResults.module.scss'

type LinkState<T> = {
  state: T
} & Omit<LinkProps, 'to'>

interface NoResultsProps extends PropsWithChildren {
  title?: string
  subtitle?: string
  hideLink?: boolean
  link?: {
    text?: string
    path?: string
    state?: { [key: string]: string | boolean }
    onClick?: () => void
  }
}

export const NoResults: React.FC<NoResultsProps> = (props) => {
  const { t } = useTranslation()
  const { title, subtitle = t('IB.No records yet'), link, children, hideLink } = props

  return (
    <div className={styles.noResults}>
      <div className={styles.heading}>
        <SearchNoContentIcon color='contrastMediumLow' size={100} className={styles.icon} />
        {title && <TextStrong>{title}</TextStrong>}
        <Text>{subtitle}</Text>
      </div>
      {children}
      {!hideLink &&
        (link?.onClick ? (
          <Button appearance='link' onClick={link?.onClick}>
            <Text>{link?.text ?? t('IB.View Wallet')}</Text>
          </Button>
        ) : (
          <Link
            className='is-link'
            to={link?.path ?? '/dashboard/introducing-broker/wallets'}
            state={link?.state}
          >
            <Text>{link?.text ?? t('IB.View Wallet')}</Text>
          </Link>
        ))}
    </div>
  )
}
