import { useCallback } from 'react'

import { ClientAreaLanguageDto } from '../../../model/ClientAreaLanguageDto'
import { PromoOptionCategoryEnum } from '../../../model/PromoOptionCategoryEnum'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useFetchOne } from '../../../utils/useFetch'

export interface LanguagesProps {
  languages: ClientAreaLanguageDto[]
  isLoadingLanguages: boolean
}

export const useLanguagesFetch = (): LanguagesProps => {
  const apiClient = useApiClient(ClientApiClient)

  const languagesCallback = useCallback(async () => {
    const languages = await apiClient.getClientAreaLanguages({
      promoMaterialCategoryId: PromoOptionCategoryEnum.LINKS,
      clientAreaVisible: true,
    })

    return languages.filter((language) => language.clientAreaVisible !== false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { data = [], isLoading } = useFetchOne(languagesCallback)

  return { languages: data, isLoadingLanguages: isLoading }
}
