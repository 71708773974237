import { TradingAccountInfo } from '../../../model/TradingAccountInfo'

export interface AccountInfoLimitsState {
  accountInfoLimits?: AccountInfoLimits
  isLoading: boolean
  lastUpdate: Date | null
  error?: string | null
  accountInfo: TradingAccountInfo[]
}

export interface AccountInfoLimits {
  mt4: number
  mt5: number
  tmt: number
  maxTotalLimit: number
  hasReachedMaxTradingAccounts: boolean
  isDemoTickmillTraderCreateAllowed: boolean
  isMTCreateAllowed: boolean
  isTickmillTraderCreateAllowed: boolean
}

export interface AccountInfoContextValue {
  data: AccountInfoLimitsState
  refreshLimits: () => void
}

export interface AccountInfoContextHook {
  accountInfoLimits?: AccountInfoLimits
  accountInfo: TradingAccountInfo[]
  refreshLimits: () => void
}

export const initialAccountInfoLimitsState: AccountInfoLimitsState = {
  accountInfoLimits: {
    mt4: 0,
    mt5: 0,
    tmt: 0,
    maxTotalLimit: 0,
    hasReachedMaxTradingAccounts: true,
    isDemoTickmillTraderCreateAllowed: false,
    isMTCreateAllowed: false,
    isTickmillTraderCreateAllowed: false,
  },
  accountInfo: [],
  lastUpdate: null,
  isLoading: false,
}

export enum AccountInfoLimitsActionKind {
  FETCH_DATA = 'FETCH_DATA',
  UPDATE_DATA = 'UPDATE_DATA',
  UPDATE_DATA_BY_PRODUCT = 'UPDATE_DATA_BY_PRODUCT',
  FAILED = 'FAILED',
  RESET_TIMER = 'RESET_TIMER',
}

export interface AccountInfoLimitsAction {
  type: AccountInfoLimitsActionKind
  accountInfo?: TradingAccountInfo[]
  data?: AccountInfoLimits
  error?: string | null
}
