import React from 'react'
import { useTranslation } from 'react-i18next'

import { SelectOption } from '../../utils/date.utils'
import { SelectModal, SelectModalOption } from '../field/SelectModal'
import { Modal } from './Modal'

export interface OptionSplitter {
  value: string
  index: number
}

interface OptionSelectModalProps {
  onClose(): void
  selectedOption?: number
  options: SelectOption[]
  onSelectOption(optionId: number): void
  title: string
  splitter?: OptionSplitter[]
}

export const OptionSelectModal: React.FC<OptionSelectModalProps> = (props) => {
  const { onClose, selectedOption, options, onSelectOption, title, splitter } = props

  const handleSelectOption = (option: SelectOption) => {
    onSelectOption(option.id)
    onClose()
  }

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={title}
          renderOptions={() => (
            <div className='control'>
              {options.map((option, idx) => (
                <React.Fragment key={option.id}>
                  {splitter?.find((split) => split.index === idx) && (
                    <label className='radio column is-full-desktop'>
                      <div className='has-text-weight-bold'>
                        {splitter?.find((split) => split.index === idx)?.value}
                      </div>
                    </label>
                  )}

                  <OptionSelectModalItem
                    option={option}
                    selected={selectedOption === option.id}
                    onSelectOption={handleSelectOption}
                    key={option.id}
                  />
                </React.Fragment>
              ))}
            </div>
          )}
        />
      )}
    />
  )
}

interface OptionSelectModalItemProps {
  selected: boolean
  option: SelectOption

  onSelectOption(option: SelectOption): void
}

export const OptionSelectModalItem: React.FC<OptionSelectModalItemProps> = (props) => {
  const { selected, option, onSelectOption } = props
  const { t } = useTranslation()

  return (
    <SelectModalOption
      label={
        option.label + (option.disabled ? ` - (${t('Statements.No statement available')})` : '')
      }
      value={selected}
      disabled={option.disabled}
      onClick={() => onSelectOption(option)}
      key={option.id}
    />
  )
}
