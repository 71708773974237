import { IconProps, iconColor, strokeIconClassName } from './IconProps'

export function DownloadIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={strokeIconClassName(props)}
    >
      <path
        d='M12 4L12 15M12 15L15.5 11.5M12 15L8.5 11.5M20 20L4 20'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
