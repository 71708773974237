import React from 'react'
import { useTranslation } from 'react-i18next'

import { validateRequired } from '../../../utils/formValidation'
import { createFormField } from '../FormField'
import { createFormSelectField } from '../FormSelectField'
import {
  FormFieldComponentType,
  FormFieldSelect,
  FormFieldText,
  FormFieldTextArea,
  isFormFieldDisabled,
  isFormFieldSelect,
  isFormFieldText,
  isFormFieldTextArea,
} from './FormFieldComponentType'

const FormField = createFormField()
const FormSelectField = createFormSelectField()

interface PaymentProviderFieldsFactoryProps {
  fields: FormFieldComponentType[]
  disabled?: boolean
}

export const FormFieldsFactory: React.FC<PaymentProviderFieldsFactoryProps> = (props) => {
  const { fields, disabled = false } = props

  return (
    <React.Fragment>
      {fields.map((x) => (
        <FormFieldFactory
          {...x}
          name={snakeToCamel(x.name)}
          disabled={disabled || x?.disabled}
          key={x.name}
        />
      ))}
    </React.Fragment>
  )
}

const snakeToCamel = (fieldName: string) => {
  return fieldName
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''))
}

const FormFieldFactory: React.FC<FormFieldComponentType> = (props) => {
  return (
    <React.Fragment>
      {isFormFieldText(props) && <FormFieldTextComponent {...props} />}
      {isFormFieldTextArea(props) && <FormFieldTextAreaComponent {...props} />}
      {isFormFieldSelect(props) && <FormFieldSelectComponent {...props} />}
      {isFormFieldDisabled(props) && <FormFieldDisabledTextComponent {...props} />}
    </React.Fragment>
  )
}

const FormFieldTextComponent: React.FC<FormFieldText> = (props) => {
  const { name, readonly, label, required, disabled, maxlength } = props

  const { t } = useTranslation()

  return (
    <FormField
      name={name}
      label={label}
      placeholder={label}
      readOnly={readonly}
      required={required}
      disabled={disabled}
      validate={required ? validateRequired(t('Validation.Required')) : undefined}
      maxlength={maxlength}
    />
  )
}

const FormFieldTextAreaComponent: React.FC<FormFieldTextArea> = (props) => {
  const { name, readonly, label, required, disabled } = props

  const { t } = useTranslation()

  return (
    <FormField
      name={name}
      label={label}
      placeholder={label}
      readOnly={readonly}
      required={required}
      disabled={disabled}
      validate={required ? validateRequired(t('Validation.Required')) : undefined}
    />
  )
}

const FormFieldSelectComponent: React.FC<FormFieldSelect> = (props) => {
  const { name, readonly, label, required, value, disabled } = props

  const { t } = useTranslation()

  return (
    <FormSelectField
      name={name}
      label={label}
      placeholder={label}
      readOnly={readonly}
      required={required}
      disabled={disabled}
      validate={required ? validateRequired(t('Validation.Required')) : undefined}
    >
      {value.map((x) => (
        <option value={x.value} key={x.value}>
          {x.name}
        </option>
      ))}
    </FormSelectField>
  )
}

const FormFieldDisabledTextComponent: React.FC<FormFieldText> = (props) => {
  const { name, value, readonly, label, required } = props

  return (
    <FormField
      name={name}
      label={label}
      placeholder={label}
      value={value}
      readOnly={readonly}
      required={required}
      disabled
    />
  )
}
