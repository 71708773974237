import React from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentManagementNonNCIPage } from './DocumentManagementNonNCI/DocumentManagementNonNCIPage'

export const DocumentManagementProofOfAddressPage: React.FC = () => {
  const { t } = useTranslation()
  return (
    <DocumentManagementNonNCIPage documentTitle={t('Sign up.Proof of Address')} showTerms={false} />
  )
}
