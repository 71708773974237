import React, { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'
import { v4 as uuid } from 'uuid'

import { Button } from '../../../../../global/button/Button'
import { useSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { createFormField } from '../../../../../global/formField/FormField'
import { LanguageSelectModal } from '../../../../../global/modal/LanguageSelectModal'
import { Modal } from '../../../../../global/modal/Modal'
import { PhoneCodeSelectModal } from '../../../../../global/modal/PhoneCodeModal'
import { DropArrowDownIcon } from '../../../../../icons/DropArrowDownIcon'
import { ForwardIcon } from '../../../../../icons/ForwardIcon'
import { InfoIcon } from '../../../../../icons/InfoIcon'
import { ClientAreaLanguageDto } from '../../../../../model/ClientAreaLanguageDto'
import { CountryPreferenceStrippedDto } from '../../../../../model/CountryDto'
import { LeadMembersEnum } from '../../../../../model/CreateLead'
import { NameDto } from '../../../../../model/NameDto'
import { PhoneNumberType } from '../../../../../model/PhoneNumberDto'
import { CountryCodeFlagIcon } from '../../../../../ui/CountryCodeFlagIcon/CountryCodeFlagIcon'
import { InfoCard } from '../../../../../ui/Popups/InfoCard/InfoCard'
import { TextH3 } from '../../../../../ui/Typography/Typography'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { EmailPattern } from '../../../../../utils/formValidation'
import { preventPaste } from '../../../../../utils/input.utils'
import { isEmailValid } from '../../../../../utils/validations'

import styles from './PersonalDetailsStep3Form.module.scss'

const FormField = createFormField<PersonalDetailsStep3FormValues>()

export interface PersonalDetailsStep3FormValues {
  email: string
  languageId: string
  countryCode: string
  phoneNumber: string
  phoneNumberType?: PhoneNumberType
}

const PersonalDetailsStep3FormUI: React.FC<
  FormikProps<PersonalDetailsStep3FormValues> & PersonalDetailsStep3FormProps
> = (props) => {
  const {
    values,
    errors,
    countryId,
    countries = [],
    languages = [],
    handleSubmit,
    setFieldValue,
  } = props

  const apiClient = useApiClient(ClientApiClient)
  const { t } = useTranslation()
  const locale = useSessionLanguage()

  const [isCountryModalOpen, setIsCountryModelOpen] = useState(false)
  const [isLanguageModalOpen, setIsLanguageModelOpen] = useState(false)

  const [emailValidated, setEmailValidated] = useState(false)
  const [emailDomainError, setEmailDomainError] = useState<string>()
  const [phoneNumberError, setPhoneNumberError] = useState<string>()
  const [selectedLanguage, setSelectedLanguage] = useState<NameDto<string>>()
  const [selectedCountry, setSelectedCountry] = useState<CountryPreferenceStrippedDto>()

  const [isLoading, setLoading] = useState(false)
  const validatePhoneNumber = async (phoneNumber: string, countryCode: string) => {
    try {
      const response = await apiClient.validatePhoneNumber(phoneNumber, countryCode)
      if (response.phoneVerificationRequired) {
        await apiClient.validateBasePhoneNumber(phoneNumber, countryCode)
      }
      setFieldValue('phoneNumberType', response.numberType)
      setPhoneNumberError('')
    } catch (e: unknown) {
      setPhoneNumberError(t('Sign up.Please check your phone number'))
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout
    if (values.phoneNumber && !errors.phoneNumber) {
      setLoading(true)
      delayDebounceFn = setTimeout(() => {
        validatePhoneNumber(values.phoneNumber, values.countryCode)
      }, 1000)
    }
    if (!values.phoneNumber) {
      setPhoneNumberError('')
    }
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.phoneNumber, errors.phoneNumber, values.countryCode])

  useEffect(() => {
    if (countryId) {
      const country = countries.find((x) => x.country.id === countryId)
      if (country) {
        const lang =
          languages.find((x) => x.id === country.language?.id) ||
          languages.find((x) => x.id === 'en') // fallback to EN

        !values.countryCode && props.setFieldValue('countryCode', country.phoneCode)
        !values.languageId && props.setFieldValue('languageId', lang?.id)
        if (!selectedLanguage) {
          setSelectedLanguage(lang)
        }
        setSelectedCountry(country)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryId])

  const handleOnChangeOption = (country: CountryPreferenceStrippedDto) => {
    props.setFieldValue('countryCode', country.phoneCode)
    setSelectedCountry(country)
    setIsCountryModelOpen(false)
  }

  const handleOnChangeLanguage = (language: NameDto<string>) => {
    props.setFieldValue('languageId', language.id)
    setSelectedLanguage(language)
    setIsLanguageModelOpen(false)
  }

  const handleEmailValidation = async (email: string) => {
    setLoading(true)
    try {
      const valid = await apiClient.validateEmailDomain(email)
      setEmailValidated(!!valid)
      if (!valid) {
        setEmailDomainError(t('Validation.Invalid email address'))
      }
    } catch (e: unknown) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout
    const { email } = values
    if (email && isEmailValid(email)) {
      delayDebounceFn = setTimeout(async () => handleEmailValidation(email), 1000)
    }
    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.email])

  return (
    <React.Fragment>
      {isLanguageModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setIsLanguageModelOpen(false)}
          render={() => (
            <LanguageSelectModal
              title={t('Sign up.Communication language')}
              options={languages}
              onCancel={() => setIsLanguageModelOpen(false)}
              handleOnChangeOption={handleOnChangeLanguage}
              selectedLanguage={selectedLanguage}
            />
          )}
        />
      )}
      {isCountryModalOpen && (
        <Modal
          cardClassName={styles.modal}
          closeModal={() => setIsCountryModelOpen(false)}
          render={() => (
            <PhoneCodeSelectModal
              title={t('Sign up.Phone Code')}
              options={countries}
              onCancel={() => setIsCountryModelOpen(false)}
              handleOnChangeOption={handleOnChangeOption}
              selectedCountry={values.countryCode}
            />
          )}
        />
      )}

      <Form autoComplete='off' className={styles.form} onSubmit={handleSubmit}>
        <TextH3>{t('Sign up.Phone and Email')}</TextH3>
        <div
          className={classNames(styles.row, {
            [styles.itCountryCodeLabel]: locale === 'it',
          })}
        >
          <FormField
            label={t('Sign up.Country Code')}
            placeholder={t('Sign up.Country Code')}
            value={values.countryCode}
            name={'countryCode'}
            autoComplete={`countryCode-${uuid()}`}
            leftIcon={
              <CountryCodeFlagIcon countries={countries} selectedCountry={selectedCountry} />
            }
            onClick={() => setIsCountryModelOpen(true)}
            rightIcon={<DropArrowDownIcon />}
            required
          />
          <div className='is-flex-direction-column'>
            <FormField
              name='phoneNumber'
              label={t('PhoneNumbers.Phone Number')}
              type='tel'
              min='0'
              autoComplete={`phoneNumber-${uuid()}`}
              required
              showLabel
              onPaste={preventPaste}
              onKeyDown={(event) =>
                ['e', 'E', '+', '-'].includes(event.key) && event.preventDefault()
              }
            />
            {!!phoneNumberError && (
              <div className='help is-danger has-text-weight-bold mb-3 ml-1'>
                {phoneNumberError}
              </div>
            )}
          </div>
        </div>
        <div className='is-flex-direction-column'>
          <FormField
            name='email'
            label={t('Sign up.Email')}
            type='email'
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setEmailDomainError('')
              setEmailValidated(false)
              props.handleChange(e)
            }}
            required
            showLabel
          />
          {!!emailDomainError && (
            <div className='help is-danger has-text-weight-bold mb-3 ml-1'>{emailDomainError}</div>
          )}
        </div>
        <TextH3>
          {t('Sign up.Personal Preferences')}{' '}
          <InfoCard
            text={t(
              "Sign up.Here we'll need some information about your communication languages, wallet preferences and more."
            )}
          >
            <InfoIcon />
          </InfoCard>
        </TextH3>
        <FormField
          name={'languageId'}
          label={t('Sign up.Communication language')}
          placeholder={t('Sign up.Communication language')}
          value={selectedLanguage?.name}
          required
          rightIcon={<DropArrowDownIcon />}
          onClick={() => setIsLanguageModelOpen(true)}
        />
        <Button
          className={styles.button}
          type='submit'
          appearance='primary'
          size='L'
          loading={props.isSubmitting}
          renderRightIcon={() => (
            <span className='is-flex is-align-items-center'>
              <ForwardIcon inverse />
            </span>
          )}
          disabled={
            !props.isValid ||
            isLoading ||
            !!phoneNumberError ||
            !!emailDomainError ||
            !emailValidated
          }
        >
          {t('Next')}
        </Button>
      </Form>
    </React.Fragment>
  )
}

export interface PersonalDetailsStep3FormProps {
  phoneCodes: string[]
  countryId?: string
  languages: ClientAreaLanguageDto[]
  countries: CountryPreferenceStrippedDto[]
  onSubmit(values: PersonalDetailsStep3FormValues): void
}

export const PersonalDetailsStep3Form = withFormik<
  PersonalDetailsStep3FormProps,
  PersonalDetailsStep3FormValues
>({
  mapPropsToValues: () => {
    const searchParams = new URLSearchParams(window.location.search)
    const countryCode = searchParams.get(LeadMembersEnum.COUNTRY_CODE)
    return {
      email: searchParams.get(LeadMembersEnum.EMAIL) || '',
      languageId: searchParams.get(LeadMembersEnum.LANGUAGE_ID) || '',
      phoneNumber: searchParams.get(LeadMembersEnum.PHONE_NUMBER) || '',
      countryCode: countryCode ? `+${countryCode}` : '',
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<PersonalDetailsStep3FormValues> = {}
    if (!values.email) {
      errors.email = t('Validation.Required')
    } else if (values.email && !EmailPattern.test(values.email)) {
      errors.email = t('Validation.Invalid email address')
    }
    if (values.phoneNumber) {
      if (!values.phoneNumber) {
        errors.phoneNumber = t('Validation.Required')
      } else if (values.phoneNumber.toString().length < 4) {
        errors.phoneNumber = t('Validation.Invalid phone number. Min 4 numbers.')
      } else if (
        values.phoneNumber &&
        (values.phoneNumber.toString().includes(',') ||
          values.phoneNumber.toString().includes('.') ||
          values.phoneNumber.toString().includes('+'))
      ) {
        errors.phoneNumber = t('Validation.Invalid phone number. Numbers from 0 to 9.')
      }
    }
    return errors
  },
})(PersonalDetailsStep3FormUI)
