import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { CheckBox } from '../global/checkBox/CheckBox'
import { Text } from '../ui/Typography/Typography'

import styles from './TwoFactorAuthPage.module.scss'

interface Props {
  dontShowAgain: boolean
  onCancel(): void
  onConfirm(): void
  setShowAgain: ((value: ChangeEvent<HTMLInputElement>) => void) | undefined
}

export const TrustModal: React.FC<Props> = ({
  dontShowAgain,
  onCancel,
  onConfirm,
  setShowAgain,
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <header className={'modal-card-head'}>
        <p className='modal-card-title'>{t('TwoFactorAuth.Trust this device')}</p>
      </header>
      <div className={styles.modalBodyWrapper}>
        <Text>
          {t(
            'TwoFactorAuth.If you choose to trust this device, you will not be asked for a verification code the next time you sign in within the next 30 days'
          )}
        </Text>
      </div>
      <div className={styles.borderedWrapper}>
        <CheckBox
          label={t('TwoFactorAuth.Dont ask anymore')}
          value={dontShowAgain}
          onChange={setShowAgain}
        />
      </div>
      <footer className={'modal-card-foot'}>
        <button className='button' onClick={onCancel}>
          {t('Not now')}
        </button>
        <button className='button' onClick={onConfirm}>
          {t('Trust')}
        </button>
      </footer>
    </React.Fragment>
  )
}
