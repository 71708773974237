interface UseDisableMainScroll {
  disableMainScroll: () => void
  enableMainScroll: () => void
}

export const useDisableMainScroll = (): UseDisableMainScroll => {
  const disableMainScroll = () => {
    const root = document.documentElement
    root.classList.add('blockedScroll')
  }

  const enableMainScroll = () => {
    const root = document.documentElement
    root.classList.remove('blockedScroll')
  }

  return { disableMainScroll, enableMainScroll }
}
