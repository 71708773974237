import { createContext, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'

import { useSnackbar } from '../global/context/SnackbarContext'
import { HttpStatus } from './httpStatus'
import { TokenStorage } from './tokenStorage'
import { clearLocalStorage } from './useStorage'

export let isAlreadyFetchingAccessToken = false
let lastRefreshTokenUpdateDate: Date = new Date()

axios.interceptors.response.use(
  (value) => value,
  async (error: AxiosError) => {
    if (
      error.response?.status === HttpStatus.UNAUTHORIZED &&
      !error.config?.headers?.['Authorization']
    ) {
      isAlreadyFetchingAccessToken = false
      clearLocalStorage()
      window.location.href = '/login'
      return Promise.reject(error)
    }
    const diffInSeconds = new Date().getTime() / 1000 - lastRefreshTokenUpdateDate.getTime() / 1000
    if (
      !isAlreadyFetchingAccessToken &&
      diffInSeconds >= 60 &&
      error.response?.status === HttpStatus.UNAUTHORIZED
    ) {
      isAlreadyFetchingAccessToken = true
      return TokenStorage.getNewToken()
        .then((newToken) => {
          lastRefreshTokenUpdateDate = new Date()
          isAlreadyFetchingAccessToken = false
          const config = error.config
          if (config && config.headers) {
            config.headers['Authorization'] = `Bearer ${newToken}`
            return new Promise((resolve, reject) => {
              axios
                .request(config)
                .then((response) => {
                  resolve(response)
                })
                .catch((err) => {
                  reject(err)
                })
            })
          }
        })
        .catch((event) => {
          isAlreadyFetchingAccessToken = false
          clearLocalStorage()
          Promise.reject(event)
        })
    }
    return Promise.reject(error)
  }
)

export enum Operator {
  EQUAL = '==',
  LIKE = 'like',
  NOT_EQUAL = '!=',
  LESS_OR_EQUAL = '<=',
  MORE_OR_EQUAL = '>=',
  IS_EMPTY = 'isempty',
  IS_NOT_EMPTY = 'isnotempty',
}

export interface OperativeQuery {
  value: string | number | boolean
  operator: Operator
}

export interface SearchQueryKeys {
  [key: string]: string | number | OperativeQuery | undefined
}

export interface NestedSearchQuery {
  queries: SearchQueryKeys
  operator: 'AND' | 'OR'
}
export interface SearchQuery {
  [key: string]: string | NestedSearchQuery[] | number | OperativeQuery | undefined
  nested?: NestedSearchQuery[]
}

export type SortOrder = 'ASC' | 'DESC'

export interface PageQuery {
  search?: SearchQuery | null | string
  sort?: string | null
  pageIndex?: number
  pageSize?: number
  sortOrder?: SortOrder
  operator?: 'AND' | 'OR'

  [key: string]: any
}

export interface PageData<T> extends PageMetaData {
  items: T[]
}

export interface PageMetaData {
  itemsCount: number
  pageIndex: number
  pageSize: number
  totalPages: number
  totalResults: number
}

export const initialPageData: PageData<any> = {
  items: [],
  itemsCount: 0,
  pageIndex: 0,
  pageSize: 0,
  totalPages: 0,
  totalResults: 0,
}

export type ErrorHandler = (error: ResponseError) => void

export type PropertyDetailResponseType = { Id: string; Name: string }

export class ResponseError {
  private readonly timer: any

  constructor(
    public response: AxiosError<{
      code: string
      message?: string
      properties?: Record<string, PropertyDetailResponseType>
    }>,
    errorHandler?: ErrorHandler
  ) {
    this.timer = setTimeout(() => {
      if (errorHandler) {
        errorHandler(this)
      }

      console.error(this.response)
    }, 100)
  }

  preventDefault(): void {
    clearTimeout(this.timer)
  }
}

export function instanceOfAxiosError(object: AxiosResponse | Error): object is AxiosError {
  if (!object) {
    return false
  }
  return 'isAxiosError' in object
}

export class ApiClient {
  errorHandler?: ErrorHandler
  updateMaintenanceMode?: () => void

  private get config(): AxiosRequestConfig {
    const token = TokenStorage.getToken('access')
    const sessionId = TokenStorage.getToken('session')

    let headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'tickmill-com-api': sessionId || new Date().getTime().toString(),
    }

    // TOKEN is assigning only in dev, in production build it's set by set-cookie.
    if (process.env.NODE_ENV === 'development' && token) {
      headers = Object.assign(headers, {
        Authorization: `Bearer ${token}`,
      })
    }
    return {
      baseURL: '/api',
      headers,
      validateStatus: (status) => {
        return status >= 200 && status < 300
      },
      timeout: 60000,
    }
  }

  async rawFetch(url: string, reqConfig: AxiosRequestConfig): Promise<AxiosResponse> {
    if ((url.includes('sign-in') || url.includes('leads')) && reqConfig.headers) {
      delete reqConfig.headers['Authorization']
    }
    try {
      const response = await axios.request({ url, ...reqConfig })
      if (instanceOfAxiosError(response) || !response) {
        throw new ResponseError(
          response as AxiosError<{ code: string; message?: string | undefined }, any>,
          this.errorHandler
        )
      }
      if (response?.status === HttpStatus.OK && this.updateMaintenanceMode) {
        this.updateMaintenanceMode()
      }
      return response
    } catch (err: unknown) {
      const error = err as AxiosResponse<any> | Error
      if (typeof err === 'object' && instanceOfAxiosError(error)) {
        throw new ResponseError(
          error as AxiosError<{ code: string; message?: string | undefined }, any>,
          this.errorHandler
        )
      }
      throw err
    }
  }

  async fetchAndParse<T>(url: string, reqConfig: AxiosRequestConfig): Promise<T> {
    const config = { ...this.config, ...reqConfig }
    const response = await this.rawFetch(url, config)
    return response.data as T
  }

  async fetch<T>(url: string, reqConfig: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const config = { ...this.config, ...reqConfig }
    return await this.rawFetch(url, config)
  }

  async get<T>(url: string): Promise<T> {
    return this.fetchAndParse(url, { method: 'GET' })
  }

  async post<T>(url: string, data: unknown): Promise<T> {
    return this.fetchAndParse(url, {
      method: 'POST',
      data,
    })
  }

  async put<T>(url: string, data: unknown): Promise<T> {
    return this.fetchAndParse(url, {
      method: 'PUT',
      data,
    })
  }

  async delete<T>(url: string, data?: unknown): Promise<T> {
    return this.fetchAndParse(url, {
      method: 'DELETE',
      data: data,
    })
  }
}

export const ApiClientContext = createContext<ApiClient | undefined>(undefined)

export const useSnackedApiClient = <T extends object>(
  apiType: new (apiClient: ApiClient) => T
): ((message?: string) => T) => {
  const { t } = useTranslation()
  const apiClient = useContext(ApiClientContext)
  const { addSnackbar, removeSnackbar } = useSnackbar()

  if (!apiClient) {
    throw new Error('ApiClient is not available. Make sure you are within ApiClientProvider.')
  }

  const createClientInstance = (snackMessage?: string): T => {
    const instance = new apiType(apiClient)
    const handler: ProxyHandler<T> = {
      get(target, prop: string, receiver) {
        const origMethod = target[prop as keyof T]
        if (typeof origMethod === 'function') {
          return async (...args: any[]) => {
            const snackId = addSnackbar.info({
              message: snackMessage ? `${snackMessage} - ${t('Loading...')}` : t('Loading...'),
              noTimeout: true,
            })
            try {
              const result = await origMethod.apply(target, args)
              removeSnackbar(snackId)
              addSnackbar.success({
                message: snackMessage ? `${snackMessage} - ${t('Success')}` : t('Success'),
              })
              return result
            } catch (error: unknown) {
              removeSnackbar(snackId)
              addSnackbar.error({
                message: snackMessage ? `${snackMessage} - An error occurred` : 'An error occurred',
              })
              throw error
            }
          }
        }
        return Reflect.get(target, prop, receiver)
      },
    }

    return new Proxy(instance, handler)
  }

  return (message) => createClientInstance(message)
}

export function useApiClient<T>(apiType: new (apiClient: ApiClient) => T): T {
  const apiClient = useContext(ApiClientContext)

  if (!apiClient) {
    throw new Error('ApiClient is not available. Make sure you are within ApiClientProvider.')
  }

  const clientInstance = useMemo(() => {
    return new apiType(apiClient)
  }, [apiClient, apiType])

  return clientInstance
}
