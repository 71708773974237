import { IconProps, iconColor } from './IconProps'

export function HideOnIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M11.9995 18.0359C12.8509 18.0359 13.6285 17.9433 14.3377 17.7831L12.7564 16.2018C12.5107 16.2207 12.2614 16.236 11.9995 16.236C7.18376 16.236 5.31811 12.7747 4.86632 11.7361C5.20556 10.979 5.66374 10.2811 6.22349 9.66884L4.96532 8.41067C3.58115 9.91094 3.05646 11.422 3.04656 11.4517C2.98448 11.6365 2.98448 11.8366 3.04656 12.0214C3.06546 12.0808 5.13001 18.0359 11.9995 18.0359ZM11.9995 5.43624C10.3463 5.43624 8.98822 5.79263 7.85605 6.31912L4.53603 3L3.26346 4.27257L19.4631 20.4722L20.7356 19.1996L17.7486 16.2126C20.1012 14.4567 20.9408 12.0592 20.9534 12.0214C21.0155 11.8366 21.0155 11.6365 20.9534 11.4517C20.9336 11.3914 18.8691 5.43624 11.9995 5.43624ZM16.4742 14.9382L14.4223 12.8863C14.5933 12.5353 14.6995 12.1492 14.6995 11.7361C14.6995 10.2592 13.4764 9.03616 11.9995 9.03616C11.5865 9.03616 11.2004 9.14236 10.8503 9.31425L9.22312 7.68709C10.1164 7.38057 11.0552 7.2281 11.9995 7.2362C16.8153 7.2362 18.681 10.6975 19.1328 11.7361C18.861 12.3589 18.0834 13.8438 16.4742 14.9382Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
