import { useTranslation } from 'react-i18next'

import { useIBRoutesConditions } from '../IntroducingBroker/IntroducingBrokerRoutes'
import { TabProps } from '../global/tabs/Tabs'
import { useProfileRoutesConditions, useProfileRoutesRequired } from './ProfileRoutes'

export const useProfileTabs = (): TabProps[] => {
  const { t } = useTranslation()

  const {
    isFirstTimeDepositVisible,
    isAppropriatenessTestVisible,
    isClientClassificationVisible,
    isTaxFormVisible,
    isETaxFormVisible,
    isMarketDataClassificationVisible,
    isBankAccountVisible,
    isDocManagementVisible,
  } = useProfileRoutesConditions()
  const { isIBVisible } = useIBRoutesConditions()
  const { isPhoneNumberVerified, appropriatenessTest, documentManagement } =
    useProfileRoutesRequired()

  return [
    {
      name: t('Profile Overview'),
      menuName: t('My Profile'),
      id: 'personal-info',
    },
    {
      name: t('Deposit'),
      id: 'first-time-deposit',
      required: true,
      hidden: !isFirstTimeDepositVisible,
    },
    {
      name: t('Profile.Appropriateness Test'),
      id: 'appropriateness-test',
      required: appropriatenessTest,
      hidden: !isAppropriatenessTestVisible,
    },
    {
      name: t('Profile.Document Management'),
      menuName: t('Profile.My Documents'),
      id: 'document-management',
      required: documentManagement,
      hidden: !isDocManagementVisible,
    },
    {
      name: t('Profile.Client Classification'),
      id: 'client-classification',
      hidden: !isClientClassificationVisible,
    },
    {
      name: t('Profile.tax form'),
      id: 'tax-form',
      hidden: !isTaxFormVisible,
    },
    {
      name: t('Profile.W-8BEN-e Form'),
      id: 'e-tax-form',
      hidden: !isETaxFormVisible,
    },
    {
      name: t('Profile.Introducing Broker'),
      id: 'introducing-broker',
      hidden: !isIBVisible,
    },
    {
      name: t('Profile.Account Settings'),
      id: 'account-settings',
    },
    {
      name: t('Profile.Security Settings'),
      id: 'security-settings',
      required: !isPhoneNumberVerified,
    },
    {
      name: t('Profile.Market Data Classification'),
      id: 'market-data-classification',
      hidden: !isMarketDataClassificationVisible,
    },
    {
      name: t('My Bank Accounts'),
      id: 'bank-accounts',
      hidden: !isBankAccountVisible,
    },
    {
      name: t('My Phone Numbers'),
      required: !isPhoneNumberVerified,
      id: 'phone-numbers',
    },
    {
      name: t('Profile.Logout'),
      id: 'logout',
    },
  ]
}
