import React from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentManagementNonNCIPage } from './DocumentManagementNonNCI/DocumentManagementNonNCIPage'

export const DocumentManagementPaymentsPage: React.FC = () => {
  const { t } = useTranslation()

  return (
    <DocumentManagementNonNCIPage
      documentTitle={t('Profile.Payments Documents')}
      showTerms={false}
    />
  )
}
