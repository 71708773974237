import { IconProps } from './IconProps'

import styles from './Icons.module.scss'

export function PinIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='12' cy='12.9453' r='12' fill={styles.primary} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M15.1262 6.29623C14.578 5.7478 13.6613 5.85726 13.2576 6.51937L10.9971 10.2262L6.94235 12.0265C6.17412 12.3675 5.9871 13.3737 6.58141 13.9683L8.41521 15.803L6.15028 18.069C5.94991 18.2694 5.94991 18.5945 6.15028 18.795C6.35066 18.9954 6.67554 18.9954 6.87592 18.795L9.14084 16.5289L10.9809 18.3698C11.5752 18.9644 12.5808 18.7773 12.9218 18.0087L14.7212 13.952L18.4262 11.6905C19.088 11.2865 19.1974 10.3694 18.6492 9.82099L15.1262 6.29623Z'
        fill='white'
      />
    </svg>
  )
}
