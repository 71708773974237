import React from 'react'
import { useTranslation } from 'react-i18next'

import { SubscriptionStatus } from '../../model/SubscriptionsDto'

import styles from './SubscriptionStatusHistoryModal.module.scss'

interface SubscriptionHistoryStatusProps {
  id: SubscriptionStatus
  name: string
  created: string
}

export const SubscriptionHistoryStatus: React.FC<SubscriptionHistoryStatusProps> = (props) => {
  const { id } = props
  const statusType = id
  const { t } = useTranslation()

  const statusConfig: { [key in SubscriptionStatus]?: { className?: string; text: string } } = {
    [SubscriptionStatus.PENDING]: {
      className: styles.warning,
      text: t('Subscriptions.Pending'),
    },
    [SubscriptionStatus.FAILED]: {
      className: styles.failed,
      text: t('Subscriptions.Failed'),
    },
    [SubscriptionStatus.CANCELLED]: {
      className: styles.failed,
      text: t('Subscriptions.Cancelled'),
    },
    [SubscriptionStatus.ACTIVE]: {
      className: styles.active,
      text: t('Subscriptions.Active'),
    },
    [SubscriptionStatus.COMPLETED]: {
      className: styles.disabled,
      text: t('Subscriptions.Completed'),
    },
    [SubscriptionStatus.DEDUCTING_FUNDS]: {
      text: t('Subscriptions.Deducting Funds'),
    },
    [SubscriptionStatus.IN_PROCESS]: {
      text: t('Subscriptions.In Process'),
    },
  }

  const { className, text } = statusConfig[statusType] || { text: '' }

  return <span className={className}>{text}</span>
}
