import React from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { useIBRoutesConditions } from '../IntroducingBroker/IntroducingBrokerRoutes'
import { useLearningRoutesConditions } from '../Learning/LearningRoutes'
import { usePARoutesConditions } from '../PaymentAgent/PaymentAgentRoutes'
import { useToolsRoutesConditions } from '../Tools/ToolsRoutes'
import { useTraderRoomRoutesConditions } from '../Traders-Room/TradersRoomRoutes'
import { useProductReadContext } from '../global/context/ProductContext'
import { Tabs, defineTab } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { isTickmillPartner } from '../utils/companyName.utils'
import { isNoTabsPath } from '../utils/path'

export const DashboardTabsCFD: React.FC = () => {
  const navigate = useNavigate()
  const currentTab = useCurrentTab('dashboard')
  const { account } = useAccountReadContext()
  const location = useLocation()
  const { t } = useTranslation()
  const { isDefaultCFDProductType } = useProductReadContext()

  const ibOrPartnerTab = isTickmillPartner(account)
    ? t('Tabs.Partners')
    : t('Tabs.Introducing broker')

  const { isIBVisible } = useIBRoutesConditions()
  const { isToolsVisible } = useToolsRoutesConditions()
  const { isPAVisible } = usePARoutesConditions()
  const { isTradersRoomVisible } = useTraderRoomRoutesConditions()
  const { isLearningVisible } = useLearningRoutesConditions()

  function* getTabs() {
    if (isTradersRoomVisible) {
      yield defineTab('traders-room', t('Tabs.Traders room'), 'traders-room/wallets')
    }
    if (isIBVisible) {
      yield defineTab('introducing-broker', ibOrPartnerTab, 'introducing-broker/wallets')
    }
    if (isPAVisible) {
      yield defineTab('payment-agent', t('Payment Agent.Payment Agent'), 'payment-agent/wallets')
    }
    if (isToolsVisible) {
      yield defineTab('tools', t('Tabs.Tools'), 'tools/platforms')
    }

    if (isLearningVisible && isTickmillPartner(account)) {
      yield defineTab('learning', t('Tabs.Learning'), 'learning/trainings')
    }

    if (isLearningVisible && !isTickmillPartner(account)) {
      yield defineTab('learning', t('Tabs.Learning'), 'learning/getting-started')
    }
  }

  const tabs = [...getTabs()]

  if (
    !account ||
    tabs.length < 2 ||
    isNoTabsPath(location.pathname) ||
    !currentTab ||
    !isDefaultCFDProductType()
  ) {
    return null
  }

  return (
    <Tabs
      tabs={tabs}
      currentTab={currentTab}
      onTabChanged={(tab) => {
        const path = tabs.find((x) => x.id === tab)
        if (path) {
          return navigate(`/dashboard/${path.path}`)
        }
        return navigate(`/dashboard/${tab}`)
      }}
    />
  )
}
