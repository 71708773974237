import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { AccountDetailedStatus } from '../../model/AccountDetailedStatus'

export const isAccountEmailVerified = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  if (accountDetailed === undefined || accountDetailed === null) {
    return false
  }

  return isAccountEmailVerifiedStatus(accountDetailed.status.id)
}

export const isAccountEmailVerifiedStatus = (status: number): boolean => {
  return AccountDetailedStatus.EmailVerified === status
}

export const getClientPrimaryEmail = (account: AccountDetailedDto): string | undefined => {
  return account.emails.find((e) => e.isPrimary)?.address
}
