import { Color } from './index'

import styles from './Icons.module.scss'

export function LiveChatIcon(props: { color?: Color; size?: number }) {
  const size = props.size || 20

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.1765 15.2L15.7052 14.668L15.4858 14.45H15.1765V15.2ZM4.5 4.75C4.08579 4.75 3.75 5.08579 3.75 5.5C3.75 5.91421 4.08579 6.25 4.5 6.25V4.75ZM15.5 6.25C15.9142 6.25 16.25 5.91421 16.25 5.5C16.25 5.08579 15.9142 4.75 15.5 4.75V6.25ZM7.5 9.25C7.08579 9.25 6.75 9.58579 6.75 10C6.75 10.4142 7.08579 10.75 7.5 10.75V9.25ZM15.5 10.75C15.9142 10.75 16.25 10.4142 16.25 10C16.25 9.58579 15.9142 9.25 15.5 9.25V10.75ZM18.1475 18.1528L17.6188 18.6847L18.1475 18.1528ZM3.5 1.75H16.5V0.25H3.5V1.75ZM18.25 3.5V17.7981H19.75V3.5H18.25ZM1.75 12.7V3.5H0.25V12.7H1.75ZM18.6762 17.6208L15.7052 14.668L14.6478 15.732L17.6188 18.6847L18.6762 17.6208ZM4.5 6.25H15.5V4.75H4.5V6.25ZM7.5 10.75H15.5V9.25H7.5V10.75ZM15.1765 14.45H3.5V15.95H15.1765V14.45ZM0.25 12.7C0.25 14.4949 1.70507 15.95 3.5 15.95V14.45C2.5335 14.45 1.75 13.6665 1.75 12.7H0.25ZM18.25 17.7981C18.25 17.5758 18.5185 17.4641 18.6762 17.6208L17.6188 18.6847C18.4074 19.4685 19.75 18.9099 19.75 17.7981H18.25ZM16.5 1.75C17.4665 1.75 18.25 2.5335 18.25 3.5H19.75C19.75 1.70507 18.2949 0.25 16.5 0.25V1.75ZM3.5 0.25C1.70508 0.25 0.25 1.70507 0.25 3.5H1.75C1.75 2.5335 2.5335 1.75 3.5 1.75V0.25Z'
        fill={props.color ? styles[props.color] : styles.textSecondary}
      />
    </svg>
  )
}
