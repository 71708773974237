import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { CampaignStatus } from '../../Traders-Room/Campaign-Results/campaign.utils'
import { SortHeader } from '../../global/sortHeader/SortHeader'
import { Table } from '../../global/table/Table'
import { ClientIbContestCampaignDto } from '../../model/ClientIbContestCampaignDto'
import { PageQuery } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { getCentralRowIdConditionally } from '../../utils/getItemId'

import styles from './IBContestPage.module.scss'

interface Props {
  data: ClientIbContestCampaignDto[]

  pageQuery?: PageQuery
  setPageQuery(value: PageQuery): void

  setSelectedCampaign(campaignResult: ClientIbContestCampaignDto): void
}

export const IBContestTable: FC<Props> = (props) => {
  const { data, pageQuery, setPageQuery, setSelectedCampaign } = props
  const { t } = useTranslation()

  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  return (
    <Table>
      <thead>
        <tr>
          <SortHeader
            id='Name'
            sort={pageQuery?.sort || undefined}
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
            className={styles.column}
          >
            {t('Campaigns.Campaign name')}
          </SortHeader>
          <SortHeader
            id='From'
            sort={pageQuery?.sort || undefined}
            align='center'
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
            className={styles.column}
          >
            {t('Campaigns.Start Date')}
          </SortHeader>
          <SortHeader
            id='To'
            sort={pageQuery?.sort || undefined}
            align='center'
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
            className={styles.column}
          >
            {t('Campaigns.End Date')}
          </SortHeader>
          <SortHeader
            id='StatusId'
            sort={pageQuery?.sort || undefined}
            align='center'
            sortOrder={pageQuery?.sortOrder}
            setSort={(sort, sortOrder) => {
              setPageQuery!({
                ...pageQuery,
                sort,
                sortOrder,
              })
            }}
            className={styles.column}
          >
            {t('Campaigns.Status')}
          </SortHeader>
          <SortHeader align='center' className={styles.column}>
            {t('Campaigns.Results')}
          </SortHeader>
        </tr>
      </thead>
      <tbody>
        {data.map((x, index) => {
          return (
            <tr {...getCentralRowIdConditionally(index, data.length, 'ib-contest')}>
              <td className={styles.textSmall}>{x.name}</td>
              <td className={styles.textSmallStrong} align='center'>
                {formatDate(x.from, { formatType: dateFormat, withTime: false })}
              </td>
              <td className={styles.textSmall} align='center'>
                {formatDate(x.to, { formatType: dateFormat, withTime: false })}
              </td>
              <td className={styles.textSmallStrong} align='center'>
                <CampaignStatus {...x} />
              </td>
              <td align='center'>
                <span className='is-link' onClick={() => setSelectedCampaign(x)}>
                  {t('View')}
                </span>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}
