import React from 'react'
import { useFormikContext } from 'formik'

import { createFormCurrencyAmountField } from '../../../../global/formField/CurrencyAmountRateField'
import { TradingAccountDepositFormValues, getCurrencyLimits } from './TradingAccountDepositForm'

const FormCurrencyAmountField = createFormCurrencyAmountField<TradingAccountDepositFormValues>()

export const CurrencyAmountField = () => {
  const { values } = useFormikContext<TradingAccountDepositFormValues>()

  const fromCurrency = values.paymentProvider.currency.id
  const toCurrency = values.tradingAccount.currency.id
  const currencyRestriction = getCurrencyLimits(values.paymentProvider)

  return (
    <FormCurrencyAmountField
      name='amount'
      fromCurrency={fromCurrency}
      toCurrency={toCurrency}
      max={currencyRestriction?.maxAmount}
      required
    />
  )
}
