import React, { ChangeEvent, useRef } from 'react'
import classNames from 'classnames'
import { useField } from 'formik'
import type { FieldConfig as FormikFieldConfig } from 'formik/dist/Field'

import { CheckBox } from '../checkBox/CheckBox'
import type { DeepNonNullable } from '../types/DeepNonNullable'
import type { DeepRequired } from '../types/DeepRequired'
import type { TypedFieldProps } from '../types/TypedFieldProps'
import type { ValidateField } from '../types/ValidateField'

type FieldConfig = Omit<FormikFieldConfig, 'name'>
interface FormFieldProps extends FieldConfig {
  onChange?(value: ChangeEvent<HTMLInputElement>): void
  children?: React.ReactNode
  isTextSmall?: boolean
}

export function RawFormCheckBoxField<FormValues>(
  props: FormFieldProps & TypedFieldProps<FormValues>
): JSX.Element {
  const { name = '', className, onChange, ...inputProps } = props

  const fieldRef = useRef<HTMLDivElement>(null)

  const [field, meta, helper] = useField<boolean>({
    name,
    type: 'checkbox',
    validate: inputProps.validate,
  })

  const touched = meta.touched
  const error = touched ? meta.error : undefined

  const handleCheckBox = async (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event)
    } else {
      await helper.setValue(event.target.checked)
      await helper.setTouched(true)
    }
  }

  return (
    <div className={classNames('field')} ref={fieldRef}>
      <div className={classNames('control')}>
        <CheckBox value={field.value} error={error} onChange={handleCheckBox} {...inputProps}>
          {props.children}
        </CheckBox>
      </div>
    </div>
  )
}

export function createFormCheckBoxField<FormValues>() {
  return function TypedField<Name extends ValidateField<DeepNonNullable<DeepRequired<FormValues>>>>(
    props: FormFieldProps & TypedFieldProps<DeepNonNullable<DeepRequired<FormValues>>, Name>
  ) {
    return <RawFormCheckBoxField {...props} />
  }
}
