import { IconProps, iconColor } from './IconProps'

export function ChevronUpIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 8L6.7 13.3C6.3134 13.6866 6.3134 14.3134 6.7 14.7C7.0866 15.0866 7.7134 15.0866 8.1 14.7L12 10.8L15.9 14.7C16.2866 15.0866 16.9134 15.0866 17.3 14.7C17.6866 14.3134 17.6866 13.6866 17.3 13.3L12 8Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
