import React from 'react'
import { useNavigate } from 'react-router-dom'

import { WalletWithdrawTransactionSuccessPage } from '../../Traders-Room/Wallets/WalletWithdraw/WalletWithdrawTransactionPage/WalletWithdrawTransactionSuccessPage'

export const PaymentAgentWalletWithdrawTransactionSuccessPage: React.FC = (props) => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/dashboard/payment-agent/transaction-history')
  }

  return <WalletWithdrawTransactionSuccessPage {...props} onCancel={handleCancel} />
}
