import React from 'react'
import classNames from 'classnames'

import styles from './Icons.module.scss'

export const EarnFromTradesIcon = ({ className }: { className?: string }) => (
  <svg
    width='255'
    className={classNames(className)}
    height='128'
    viewBox='0 0 255 128'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M248.108 121.24C247.443 107.291 244.372 93.5461 239.017 80.6171C232.924 65.9075 223.994 52.542 212.736 41.2838C201.477 30.0256 188.112 21.0951 173.402 15.0022C158.693 8.90933 142.927 5.77336 127.006 5.77336C111.084 5.77336 95.3185 8.90934 80.6089 15.0022C65.8994 21.0951 52.5339 30.0256 41.2757 41.2838C30.0175 52.5421 21.087 65.9075 14.9941 80.6171C9.63875 93.5461 6.56778 107.291 5.90278 121.24H27.9604C28.6048 110.185 31.0963 99.3023 35.3444 89.0464C40.3304 77.0093 47.6384 66.0721 56.8512 56.8593C66.064 47.6465 77.0012 40.3385 89.0383 35.3525C101.075 30.3666 113.977 27.8004 127.006 27.8004C140.034 27.8004 152.936 30.3666 164.973 35.3525C177.01 40.3385 187.947 47.6465 197.16 56.8593C206.373 66.0721 213.681 77.0093 218.667 89.0464C222.915 99.3023 225.406 110.185 226.051 121.24H248.108ZM254.011 125.57C254.02 126.367 253.373 127.014 252.576 127.014H221.889C221.092 127.014 220.447 126.367 220.434 125.57C220.253 113.791 217.845 102.148 213.333 91.2558C208.637 79.9191 201.754 69.6184 193.078 60.9417C184.401 52.265 174.1 45.3822 162.764 40.6864C151.427 35.9906 139.276 33.5737 127.006 33.5737C114.735 33.5737 102.584 35.9906 91.2477 40.6864C79.911 45.3822 69.6103 52.265 60.9335 60.9417C52.2568 69.6184 45.3741 79.9191 40.6783 91.2558C36.1667 102.148 33.7587 113.791 33.5768 125.57C33.5644 126.367 32.9194 127.014 32.1223 127.014H1.43523C0.638098 127.014 -0.00896377 126.367 9.39483e-05 125.57C0.184054 109.382 3.46137 93.3731 9.66023 78.4077C16.0433 62.9977 25.399 48.9958 37.1933 37.2015C48.9877 25.4072 62.9895 16.0514 78.3996 9.66835C93.8096 3.28532 110.326 7.62939e-06 127.006 7.62939e-06C143.685 7.62939e-06 160.202 3.28531 175.612 9.66835C191.022 16.0514 205.024 25.4072 216.818 37.2015C228.612 48.9958 237.968 62.9977 244.351 78.4077C250.55 93.3731 253.827 109.382 254.011 125.57Z'
      fill={styles.text}
    />
    <path
      d='M204.582 47.3905C214.765 57.3781 222.84 69.232 228.348 82.274C232.348 91.7447 234.938 101.708 236.056 111.859'
      stroke={styles.contrastMediumLow}
      strokeWidth='4'
      strokeLinecap='round'
      strokeDasharray='0.5 11'
    />
    <path
      d='M183.288 31.1143C178.715 28.4342 170.191 24.3805 165.246 22.3717C151.933 16.9634 141.411 15.8767 126.998 15.8767C112.585 15.8767 98.3144 18.661 85.0016 24.0693C71.6888 29.4776 59.5962 37.4032 49.4127 47.3909C39.2293 57.3784 31.1544 69.2324 25.6463 82.2744C21.5518 91.9693 18.9348 102.18 17.8613 112.58'
      stroke={styles.primary}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <circle cx='131.344' cy='99.5903' r='21.0934' stroke={styles.text} strokeWidth='4' />
    <line
      x1='150.135'
      y1='86.6588'
      x2='200.671'
      y2='37.488'
      stroke={styles.text}
      strokeWidth='4'
      strokeLinecap='round'
    />
  </svg>
)
