import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { AxiosError } from 'axios'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { Loading } from '../../../../global/Loading/Loading'
import { FileData } from '../../../../global/fileUpload/FileUpload'
import { CancelActionModal } from '../../../../global/modal/CancleActionModal'
import { Modal } from '../../../../global/modal/Modal'
import { FormTemplate } from '../../../../global/templates/FormTemplate'
import { WalletDto } from '../../../../model/WalletDto'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { useMutation } from '../../../../utils/useMutation'
import { PaymentAgentTransferClientDetailsFields } from './PaymentAgentTransferClientDetailsFields'
import { PaymentAgentTransferClientFields } from './PaymentAgentTransferClientFields'

export interface PaymentAgentTransferFormValues {
  client: {
    name: string
  }
  clientWallet: ClientWallet
  paymentProviderWallet?: WalletDto
  tradingAccountId: string
  campaignName: string
  amount: number | undefined
  system: string
  account: string
  comment: string
  fileName: string
  fileData: FileData | undefined
  base64Content: string
}

export type ClientWallet =
  | WalletDto
  | {
      id: string
      name: string
    }

const useTransferMutate = () => {
  const { walletId } = useParams<{ walletId?: string }>()

  const apiClient = useApiClient(ClientApiClient)

  const { setValues } = useFormikContext<PaymentAgentTransferFormValues>()

  const handleSuccess = async (
    {
      paymentProviderWallet,
      clientWallet,
    }: { paymentProviderWallet: WalletDto; clientWallet: WalletDto },
    values: PaymentAgentTransferFormValues
  ) => {
    setValues({
      ...values,
      paymentProviderWallet,
      clientWallet,
    })
  }

  const handleError = (error: AxiosError<{ message: string }>) => {
    // TODO: add error notification
  }

  return useMutation(
    async (values: PaymentAgentTransferFormValues) => {
      const [paymentProviderWallet, clientWallet] = await Promise.all([
        apiClient.getWalletById(walletId || ''),
        apiClient.paymentAgentValidateClientDetails(
          walletId || '',
          values.client.name,
          values.clientWallet.name
        ),
      ])

      return { paymentProviderWallet, clientWallet }
    },
    {
      onSuccess: handleSuccess,
      onError: handleError,
    }
  )
}

const PaymentAgentTransferFormUI: React.FC<
  FormikProps<PaymentAgentTransferFormValues> & OuterProps
> = (props) => {
  const { values, onCancel, handleSubmit } = props

  const { t } = useTranslation()

  const [isPageExitConfirmation, setPageExitConfirmation] = useState(false)

  const mutation = useTransferMutate()

  const handleSubmitForm = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    handleSubmit()
  }

  const handleValidateClientDetails = async (values: PaymentAgentTransferFormValues) => {
    await mutation.mutate(values)
  }

  const handlePageExitConfirmation = () => {
    onCancel()
  }

  const handleExitPageConfirmationModalOpen = () => {
    setPageExitConfirmation(true)
  }

  const handleExitPageConfirmationModalClose = () => {
    setPageExitConfirmation(false)
  }

  return (
    <FormTemplate
      title={t('Payment Agent.Payment Agent Transfer')}
      goBack={handleExitPageConfirmationModalOpen}
    >
      {isPageExitConfirmation && (
        <Modal
          closeModal={handleExitPageConfirmationModalClose}
          render={() => (
            <CancelActionModal
              onConfirm={handlePageExitConfirmation}
              onCancel={handleExitPageConfirmationModalClose}
            />
          )}
        />
      )}
      <Form onSubmit={handleSubmitForm}>
        <Loading isLoading={mutation.isLoading} showLoadingIcon>
          {!values?.clientWallet?.id && (
            <PaymentAgentTransferClientFields
              onSubmit={handleValidateClientDetails}
              onCancel={handleExitPageConfirmationModalOpen}
            />
          )}
          {values?.clientWallet?.id && (
            <PaymentAgentTransferClientDetailsFields
              onCancel={handleExitPageConfirmationModalOpen}
            />
          )}
        </Loading>
      </Form>
    </FormTemplate>
  )
}

const initialState = (data?: PaymentAgentTransferFormValues): PaymentAgentTransferFormValues => {
  return {
    client: {
      name: data?.client.name || '',
    },
    clientWallet: {
      ...data?.clientWallet,
      id: data?.clientWallet?.id || '',
      name: data?.clientWallet?.name || '',
    },
    paymentProviderWallet: undefined,
    tradingAccountId: data?.tradingAccountId || '',
    campaignName: data?.campaignName || '',
    amount: data?.amount,
    system: data?.system || '',
    account: data?.account || '',
    comment: data?.comment || '',
    fileName: data?.fileName || '',
    fileData: undefined,
    base64Content: data?.base64Content || '',
  }
}

interface OuterProps {
  data: PaymentAgentTransferFormValues | undefined
  onSubmit(values: PaymentAgentTransferFormValues): void
  onCancel(): void
}

export const PaymentAgentTransferForm = withFormik<OuterProps, PaymentAgentTransferFormValues>({
  mapPropsToValues: ({ data }) => {
    return initialState(data)
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<PaymentAgentTransferFormValues> = {}

    if (!values?.clientWallet?.id) {
      if (!values?.clientWallet?.name) {
        errors.clientWallet = {}
        errors.clientWallet.name = t('Validation.Required')
      }

      if (!values?.client?.name) {
        errors.client = {}
        errors.client.name = t('Validation.Required')
      }
    }

    if (values?.clientWallet?.id) {
      const amount = values?.amount || 0
      if (amount <= 0) {
        errors.amount = t('Validation.Required')
      }

      if (!values?.system) {
        errors.system = t('Validation.Required')
      }

      if (!values?.account) {
        errors.account = t('Validation.Required')
      }

      if (!values?.fileName) {
        errors.fileName = t('Validation.Required')
      }

      if (!values?.base64Content) {
        errors.base64Content = t('Validation.Required')
      }
    }

    return errors
  },
  enableReinitialize: true,
})(PaymentAgentTransferFormUI)
