import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { StepCircle } from '../../../global/StepCircle/StepCircle'
import { useSessionEntity } from '../../../global/context/EntityContext'
import { AccountType } from '../../../model/AccountType'
import { NameDto } from '../../../model/NameDto'
import { TextSmall } from '../../../ui/Typography/Typography'
import { isDepositBeforeKYC } from '../../../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../../../utils/AccountContextContext'
import { requiresAppTestImprovement } from '../../../utils/companyName.utils'
import { useWindowResize } from '../../../utils/domUtils'

import styles from './SignupStep.module.scss'

export enum OnboardingFlowTypeEnum {
  Standard = 1,
  DepositBeforeKYC = 2,
}

export enum SignupSections {
  AccountType = 1,
  PersonalDetails = 2,
  CompanyDetails = 3,
  FinancialInfo = 4,
  FirstTimeDeposit = 5,
  Documents = 6,
  ReviewConfirm = 7,
}

const TitlePerSection = {
  [SignupSections.AccountType]: 'Sign up.Account Type',
  [SignupSections.PersonalDetails]: 'Sign up.Personal Details',
  [SignupSections.CompanyDetails]: 'Sign up.Company Details',
  [SignupSections.FinancialInfo]: 'Sign up.Financial Info & Experience',
  [SignupSections.FirstTimeDeposit]: 'Sign up.First Time Deposit',
  [SignupSections.Documents]: 'Sign up.Documents',
  [SignupSections.ReviewConfirm]: 'Sign up.Review & Confirm',
  ConfirmRegistration: 'Sign up.Confirm Registration',
  AppropriatenessTest: 'Sign up.Appropriateness Test',
}

interface SignupStepProps {
  section: number
  label?: string
  accountTypeId?: number
  products?: NameDto[]
  subStep?: {
    current: number
    total: number
  }
  isFailed?: boolean
  isCompleted?: boolean
  isHidden?: boolean
}

export const SignupStep: React.FC<SignupStepProps> = (props) => {
  const { section, subStep, isCompleted, isFailed, isHidden, label } = props
  const { account } = useAccountReadContext()
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const tickmillEntity = useSessionEntity()

  const financialInfoTitle = requiresAppTestImprovement(tickmillEntity)
    ? t(TitlePerSection.AppropriatenessTest)
    : t(TitlePerSection[SignupSections.FinancialInfo])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const getSignupSteps = () => {
    const steps = [
      {
        section: SignupSections.AccountType,
        title: t(TitlePerSection[SignupSections.AccountType]),
        flows: [AccountType.Individual, AccountType.Corporate],
      },
      {
        section: SignupSections.PersonalDetails,
        title: t(TitlePerSection[SignupSections.PersonalDetails]),
        flows: [AccountType.Individual, AccountType.Corporate],
      },
      {
        section: SignupSections.CompanyDetails,
        title: t(TitlePerSection[SignupSections.CompanyDetails]),
        flows: [AccountType.Corporate],
        hidden: section === SignupSections.AccountType,
      },
      {
        section: SignupSections.FinancialInfo,
        title: financialInfoTitle,
        flows: [AccountType.Individual, AccountType.Corporate],
      },
      {
        section: SignupSections.FirstTimeDeposit,
        title: t(TitlePerSection[SignupSections.FirstTimeDeposit]),
        flows: [AccountType.Individual],
        hidden: !isDepositBeforeKYC(account),
      },
      {
        section: SignupSections.Documents,
        title: t(TitlePerSection[SignupSections.Documents]),
        flows: [AccountType.Individual, AccountType.Corporate],
        hidden: !!localStorage.getItem('hasAdditionalId'),
      },
      {
        section: SignupSections.ReviewConfirm,
        title: t(TitlePerSection[SignupSections.ReviewConfirm]),
        flows: [AccountType.Individual, AccountType.Corporate],
      },
    ]

    const filteredSteps = steps.filter((step) => {
      if (step.flows.includes(props.accountTypeId || 1)) {
        if (step.hidden !== undefined) {
          return !step.hidden
        } else {
          return true
        }
      } else {
        return false
      }
    })

    const stepNames = filteredSteps.map((step) => step.title)

    const currentSection = filteredSteps.findIndex((step) => step.section === section)

    return (
      <>
        {Array.from({ length: stepNames.length }, (_, i) => (
          <TextSmall
            key={i}
            className={classNames(styles.step, {
              [styles.active]: currentSection === i && !isFailed && !isCompleted,
            })}
          >
            <StepCircle
              isActive={currentSection === i}
              isCompleted={(isCompleted && currentSection === i) || currentSection > i}
              section={i + 1}
              subStep={subStep}
              isFailed={isFailed && currentSection === i}
              isHidden={isHidden}
            />
            {!isMobile && <span>{(i === currentSection && label) || stepNames[i]}</span>}
          </TextSmall>
        ))}
      </>
    )
  }

  if (props.accountTypeId === AccountType.Corporate) {
    return <div className={styles.stepsWrapper}>{getSignupSteps()}</div>
  }

  return (
    <section className={styles.stepsBox}>
      <div className={styles.stepsWrapper}>{getSignupSteps()}</div>
    </section>
  )
}
