import { IconProps, iconColor } from './IconProps'

export function DocumentIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 84 110'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='25.3697' cy='18.979' r='4.75248' stroke='#CC0000' strokeWidth='3' />
      <path
        d='M34.8693 36.6131C34.8693 36.6131 34.2915 34.8084 33.5114 32.2567C32.7314 29.705 27.8607 29.4844 25.3643 29.4844C22.8679 29.4844 17.9973 29.705 17.2172 32.2567C16.4372 34.8084 15.8594 36.6131 15.8594 36.6131'
        stroke='#CC0000'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M58.6535 1.57422H6C3.79086 1.57422 2 3.36508 2 5.57422V104.426C2 106.635 3.79086 108.426 6 108.426H78C80.2091 108.426 82 106.635 82 104.426V24.9208M58.6535 1.57422L82 24.9208M58.6535 1.57422V22.9208C58.6535 24.0253 59.5489 24.9208 60.6535 24.9208H69.3267H82M14.6733 50.2673H69.3267M14.6733 61.3366H69.3267M14.6733 72.8019H55.0693M47.5446 86.2475L55.0693 93.7722L69.3267 79.5148'
        stroke={iconColor(props)}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}
