import { NameDto } from '../model/NameDto'

export type SubscriptionRequestBadgeState =
  | 'Pending'
  | 'Processing'
  | 'Deducting Funds'
  | 'In Process'
  | 'Completed'
  | 'Failed'
  | 'In Process Cancel'
  | 'Cancelled'
  | 'Active'
  | 'Active Via Bundle'

export type SubscriptionRequestButtonStateKeys =
  | 'Cancel'
  | 'Unsubscribe'
  | 'Resubscribe'
  | 'Subscribe'

type SubscriptionRequestBadgeStatusTextProps =
  | 'Pending'
  | 'Active'
  | 'Processing'
  | 'Failed'
  | 'Processing'
  | 'Active Via Bundle'
  | 'Pending Cancellation'

type SubscriptionBadgeStatusColor = 'pending' | 'active' | 'failed' | 'tertiary'
type SubscriptionButtonStatusColor = 'primary' | 'secondary'

export interface ActiveSubscriptionRequest {
  id: string
  state: NameDto
  type: NameDto
  subscriptionDetailId: string
  dueDate: string
  createdDate: string
}

export const SubscriptionRequestBadgeAppearance: Record<
  SubscriptionRequestBadgeState,
  SubscriptionBadgeStatusColor
> = {
  Pending: 'pending',
  Processing: 'pending',
  'Deducting Funds': 'pending',
  'In Process': 'pending',
  Completed: 'active',
  Failed: 'failed',
  'In Process Cancel': 'pending',
  Cancelled: 'pending',
  Active: 'active',
  'Active Via Bundle': 'tertiary',
}

export const SubscriptionRequestBadgeStatusText: Record<
  SubscriptionRequestBadgeState,
  SubscriptionRequestBadgeStatusTextProps
> = {
  Pending: 'Pending',
  Processing: 'Pending',
  'Deducting Funds': 'Processing',
  'In Process': 'Processing',
  Completed: 'Active',
  Failed: 'Failed',
  'In Process Cancel': 'Processing',
  Cancelled: 'Pending Cancellation',
  Active: 'Active',
  'Active Via Bundle': 'Active Via Bundle',
}

export const SubscriptionRequestButtonAppearance: Record<string, SubscriptionButtonStatusColor> = {
  Subscribe: 'primary',
  Unsubscribe: 'secondary',
  Resubscribe: 'secondary',
  Cancel: 'secondary',
}

export const SubscriptionRequestButtonStatusKeys: Record<
  string,
  SubscriptionRequestButtonStateKeys
> = {
  Unsubscribe: 'Unsubscribe',
  Resubscribe: 'Resubscribe',
  Cancel: 'Cancel',
  Subscribe: 'Subscribe',
}

export interface InfoModalProps {
  title?: string
  body?: string
}

export type SubscriptionRequestBadgeStatusProps = keyof typeof SubscriptionRequestBadgeStatusText &
  keyof typeof SubscriptionRequestButtonStatusKeys

export type SubscriptionRequestType = {
  id: string
  state: {
    id: number
    name: SubscriptionRequestBadgeState
  }
  type: NameDto
  subscriptionDetailId: string
  dueDate: string
  subscriptionTimeInForce?: NameDto
  activeSubscriptionRequest?: ActiveSubscriptionRequest
}

export type SubscriptionDetailType = {
  name: string
  description: string
  id?: string
  activeViaBundleSubscriptionRequest?: SubscriptionRequestType
  amount?: number
  activeSubscriptionRequest?: ActiveSubscriptionRequest
  subscriptionRequest?: SubscriptionRequestType
}

export interface TradingPlatformSubscriptionItem {
  id: string | number
  subName?: string
  name?: string
  description?: string
  url?: string
  isActiveViaPackage?: boolean
  subscriptionRequest?: SubscriptionRequestType
  activeSubscriptionRequest?: ActiveSubscriptionRequest
  subscriptionTimeInForce?: { id: number; name: string }
  details?: SubscriptionDetailType[]
}

export interface SubscriptionsTradingPlatformsTableProps {
  items: TradingPlatformSubscriptionItem[]
}

export interface TradingPlatformsItemProps extends TradingPlatformSubscriptionItem {
  minAmount?: number
  maxAmount?: number
  imageUrlMobile?: string
  imageUrl?: string
  price?: number
  isOpen?: boolean
  type: string
  subscriptions?: TradingPlatformSubscriptionItem[]
  subscriptionRequest?: SubscriptionRequestType
}

export interface TradingPlatformsPackagesProps extends TradingPlatformSubscriptionItem {
  productType?: string
  price?: number
}

export interface TradingPlaformsDataProps {
  id: string
  subName?: string
  name?: string
  type: string
  description?: string
  url?: string
  imageUrl?: string
  imageUrlMobile?: string
  minAmount?: number
  maxAmount?: number
  subscriptions: TradingPlatformSubscriptionItem[]
}
