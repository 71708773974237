export enum CampaignCalculationType {
  // Client Rebate
  Tiers = 1,
  AccountType = 2,

  // Client Contest
  RoiPercentage = 100,
  NumberOfProfitableTrades = 101,
  NumberOfClosedTrades = 102,
  PnlUsd = 103,

  // IB Contest
  IbNumberOfLots = 200,
  IbNetDeposit = 201,

  // LuckyDraw
  LuckyDraw = 300,
}

export const isCampaignCalculationTiersType = (value?: CampaignCalculationType) => {
  return value === CampaignCalculationType.Tiers
}

export const isCampaignCalculationAccountType = (value?: CampaignCalculationType) => {
  return value === CampaignCalculationType.AccountType
}

export const isCampaignCalculationRoiPercentageType = (value?: CampaignCalculationType) => {
  return value === CampaignCalculationType.RoiPercentage
}

export const isCampaignCalculationNumberOfProfitableTradesType = (
  value?: CampaignCalculationType
) => {
  return value === CampaignCalculationType.NumberOfProfitableTrades
}

export const isCampaignCalculationNumberOfClosedTradesType = (value?: CampaignCalculationType) => {
  return value === CampaignCalculationType.NumberOfClosedTrades
}

export const isCampaignCalculationPnlUsdType = (value?: CampaignCalculationType) => {
  return value === CampaignCalculationType.PnlUsd
}
