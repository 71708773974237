import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { WalletWithdrawFormValues } from '../../Traders-Room/Wallets/WalletWithdraw/WalletWithdrawForm/WalletWithdrawForm'
import {
  WalletWithdrawPage,
  useWalletWithdrawFetch,
} from '../../Traders-Room/Wallets/WalletWithdraw/WalletWithdrawPage/WalletWithdrawPage'
import { Loading } from '../../global/Loading/Loading'
import { MasterTransaction } from '../../model/MasterTransaction'
import {
  isTransactionStatusSuccessful,
  isTransactionStatusUnSuccessful,
} from '../../model/TransactionStatus'
import { WalletTypeEnum } from '../../model/WalletDto'

export const PaymentAgentWalletWithdrawPage: React.FC = () => {
  const navigate = useNavigate()

  const handlePageExitConfirmation = () => {
    navigate(`/dashboard/payment-agent/wallets`)
  }

  const { data, isLoading } = useWalletWithdrawFetch({
    onCancel: handlePageExitConfirmation,
  })

  const handleSubmit = (data: MasterTransaction, values: WalletWithdrawFormValues) => {
    if (isTransactionStatusSuccessful(data.state.id)) {
      navigate(
        `/dashboard/payment-agent/wallets/${values.wallet.id}/withdrawal/transaction/succeed`,
        {
          state: {
            data,
            providerCategoryId: values.paymentProvider.providerCategory.id,
          },
        }
      )
    }

    if (isTransactionStatusUnSuccessful(data.state.id)) {
      navigate(
        `/dashboard/payment-agent/wallets/${values.wallet.id}/withdrawal/transaction/failed`,
        {
          state: {
            data,
            providerCategoryId: values.paymentProvider.providerCategory.id,
          },
        }
      )
    }
  }

  const { t } = useTranslation()

  return (
    <Loading isLoading={isLoading}>
      {data && (
        <WalletWithdrawPage
          wallet={data}
          walletType={WalletTypeEnum.PAYMENT_AGENT}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
          title={t('Payment Agent.Payment Agent')}
        />
      )}
    </Loading>
  )
}
