import styles from './Icons.module.scss'

export function PhoneIcon(props: { size?: number }) {
  const size = props.size || 20

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5415 6.97614C14.1168 7.59069 14.2568 8.50725 13.8074 9.21907C12.6332 11.0788 11.0825 12.6253 9.22142 13.803C8.50833 14.2542 7.58914 14.1154 6.97309 13.5386L6.59 13.18C6.32 12.91 5.92 12.82 5.57 12.94C4.45 13.31 3.24 13.51 2 13.51C1.45 13.51 1 13.96 1 14.51V18C1 18.55 1.45 19 2 19C11.39 19 19 11.39 19 2C19 1.45 18.55 1 18 1H14.5C13.95 1 13.5 1.45 13.5 2C13.5 3.25 13.3 4.45 12.93 5.57C12.82 5.92 12.9 6.31 13.18 6.59L13.5415 6.97614Z'
        stroke={styles.textSecondary}
        strokeWidth='1.5'
      />
    </svg>
  )
}
