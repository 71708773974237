import React, { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Button } from '../../../../global/button/Button'
import { createFormCheckBoxField } from '../../../../global/formField/FormCheckBoxField'
import { WalletPaymentAgentTransferInformationDto } from '../../../../model/WalletPaymentAgentTransferInformationDto'
import { TradingAccountDepositFormValues } from './TradingAccountDepositForm'

const FormCheckBoxField = createFormCheckBoxField<TradingAccountDepositFormValues>()

export const FormTermsConditionsPSP: FC<{ onClickTermsConditions(): void }> = ({
  onClickTermsConditions,
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<TradingAccountDepositFormValues>()

  return (
    <React.Fragment>
      <FormCheckBoxField name='terms.responsibility' value={values.terms.responsibility}>
        <span>
          {t('I hereby acknowledge that Tickmill shall not be held responsible for any delays')}
        </span>
      </FormCheckBoxField>
      <FormCheckBoxField name='terms.paymentOperations'>
        {t('I have read all instructions and agree with')}{' '}
        <Button type='button' appearance='link' onClick={onClickTermsConditions}>
          {t('Terms & Conditions')}
        </Button>{' '}
        {t('of payment operations')}
      </FormCheckBoxField>
    </React.Fragment>
  )
}

interface FormTermsConditionsPaymentAgentProps {
  paymentAgent: WalletPaymentAgentTransferInformationDto
  onClickTermsConditions(): void
}

export const FormTermsConditionsPaymentAgent: React.FC<FormTermsConditionsPaymentAgentProps> = (
  props
) => {
  const { paymentAgent, onClickTermsConditions } = props

  const { values, setValues } = useFormikContext<TradingAccountDepositFormValues>()

  useEffect(() => {
    setValues({
      ...values,
      terms: {
        ...values.terms,
        paymentOperations: paymentAgent.powerOfAttorney.accepted,
        powerOfAttorney: paymentAgent.powerOfAttorney.accepted,
      },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentAgent.powerOfAttorney.accepted])

  const { t } = useTranslation()

  return (
    <React.Fragment>
      {!paymentAgent.powerOfAttorney.accepted && <br />}
      <FormCheckBoxField
        name='terms.powerOfAttorney'
        value={values.terms.powerOfAttorney}
        disabled={paymentAgent.powerOfAttorney.accepted}
      >
        {t('I have read, understood and consent to the')}{' '}
        <a
          href={paymentAgent.powerOfAttorneyUrl}
          target='_blank'
          rel='noreferrer'
          className='is-link'
        >
          {t('Payment Agent.Power of Attorney')}
        </a>
        {'.'}
      </FormCheckBoxField>
      <FormCheckBoxField
        name='terms.paymentOperations'
        value={values.terms.paymentOperations}
        disabled={paymentAgent.powerOfAttorney.accepted}
      >
        {t('I have read all instructions and agree with')}{' '}
        <Button type='button' appearance='link' onClick={onClickTermsConditions}>
          {t('Terms & Conditions')}
        </Button>{' '}
        {t('of payment operations')}
      </FormCheckBoxField>
    </React.Fragment>
  )
}
