import React from 'react'
import { useTranslation } from 'react-i18next'

import { createFormNumericField } from '../../../../global/formField/FormNumericField'

const FormNumericField = createFormNumericField<{ amount: number }>()

export const CurrencyAmountField = () => {
  const { t } = useTranslation()

  return (
    <FormNumericField
      name='amount'
      label={t('Wallet.Amount')}
      placeholder={t('Wallet.Amount')}
      min={0}
      required
      step='any'
    />
  )
}
