import { IconProps, iconColor } from './IconProps'

export function FormatCSVIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M18.9998 10V7.14301C19.0021 7.05853 18.9858 6.97455 18.9522 6.89687C18.9185 6.81919 18.8682 6.74964 18.8048 6.69302L14.2549 2.19307C14.1976 2.1303 14.1273 2.08054 14.0488 2.04725C13.9702 2.01395 13.8853 1.99791 13.7999 2.00022H4.29998C3.95552 2.00124 3.62546 2.13702 3.38189 2.37792C3.13832 2.61881 3.00103 2.94524 3 3.28592V20.7143C3 21.0553 3.13696 21.3823 3.38075 21.6234C3.62455 21.8645 3.9552 22 4.29998 22H18V20.7143H4.29998V3.28592H12.4999V7.14301C12.5009 7.48369 12.6382 7.81012 12.8818 8.05102C13.1254 8.29191 13.4554 8.42769 13.7999 8.42871H17.6998V10H18.9998ZM13.7999 7.14301V3.54306L17.4398 7.14301H13.7999Z'
        fill={iconColor(props)}
      />
      <path
        d='M12.3425 16.1354H11.174C11.0497 16.6409 10.7348 16.9724 10.1796 16.9724C9.86464 16.9724 9.61602 16.8729 9.44199 16.6657C9.20166 16.4088 9.15193 16.1436 9.15193 15C9.15193 13.8564 9.20166 13.5912 9.44199 13.3343C9.61602 13.1271 9.86464 13.0276 10.1796 13.0276C10.7348 13.0276 11.0497 13.3591 11.174 13.8646H12.3425C12.1436 12.663 11.3066 12 10.1713 12C9.54144 12 9.01105 12.2155 8.58011 12.6464C8.3232 12.9033 8.16575 13.1934 8.09945 13.5331C8.03315 13.8646 8 14.3536 8 15C8 15.6464 8.03315 16.1354 8.09945 16.4669C8.16575 16.7983 8.3232 17.0967 8.58011 17.3536C9.01105 17.7845 9.54144 18 10.1713 18C11.2983 18 12.1436 17.337 12.3425 16.1354Z'
        fill={iconColor(props)}
      />
      <path
        d='M17.1501 16.2099C17.1501 15.6878 17.0093 15.2569 16.6943 14.9586C16.4457 14.7265 16.1059 14.5773 15.5673 14.5028L14.8711 14.4033C14.6308 14.3702 14.4402 14.279 14.3242 14.1713C14.1999 14.0552 14.1501 13.8978 14.1501 13.7486C14.1501 13.3425 14.4485 13.0028 15.0617 13.0028C15.4512 13.0028 15.8988 13.0525 16.2717 13.4088L17.001 12.6878C16.4954 12.1989 15.907 12 15.0949 12C13.8186 12 13.0396 12.7376 13.0396 13.7901C13.0396 14.2873 13.1805 14.6685 13.4623 14.9503C13.7275 15.2072 14.1004 15.3646 14.6059 15.4392L15.3186 15.5387C15.5921 15.5801 15.7247 15.6381 15.8407 15.7459C15.9651 15.8619 16.0231 16.0359 16.0231 16.2431C16.0231 16.7155 15.6584 16.9724 14.9789 16.9724C14.4319 16.9724 13.9264 16.8481 13.5535 16.4751L12.8076 17.221C13.3877 17.8094 14.0673 18 14.9623 18C16.1971 18 17.1501 17.3536 17.1501 16.2099Z'
        fill={iconColor(props)}
      />
      <path
        d='M22.3365 12.0497H21.1348L19.9498 15.895L18.7647 12.0497H17.5713L19.5188 17.9503H20.3807L22.3365 12.0497Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
