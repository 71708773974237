import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { SelectModal, SelectModalOption } from '../../../global/field/SelectModal'
import { Modal } from '../../../global/modal/Modal'
import { NameDto } from '../../../model/NameDto'
import { CreateDemoTradingAccountFormValues } from './CreateDemoTradingAccountForm'

interface Props {
  onSelectOption(classificationType: NameDto): void
  onClose(): void

  classificationTypes: NameDto[]
}

export const ClassificationTypeModal: React.FC<Props> = ({
  onSelectOption,
  onClose,
  classificationTypes,
}) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<CreateDemoTradingAccountFormValues>()
  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Trading Account.Classification Type')}
          renderOptions={() => (
            <div className='control'>
              {classificationTypes.map(({ id, name }) => (
                <SelectModalOption
                  label={name}
                  value={id === values.classificationType?.id}
                  onClick={() => onSelectOption({ id, name })}
                  key={id}
                />
              ))}
            </div>
          )}
        />
      )}
    />
  )
}
