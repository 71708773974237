import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '../../global/button/Button'
import { Table } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { PlaceholderIcon } from '../../icons/PlaceholderIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../utils/wallet.utils'
import { PaymentAgentWalletMappedDto } from './PaymentAgentWalletsPage'

interface Props {
  wallets: PaymentAgentWalletMappedDto[]
  setModalVisible(v: boolean): void
}

export const PaymentAgentWalletsTable: React.FC<Props> = (props) => {
  const { wallets, setModalVisible } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const handleRestrictionModalOpen = (wallet: PaymentAgentWalletMappedDto) => {
    const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
    if (!hasAllRestrictions) {
      setModalVisible(true)
    }
  }

  return (
    <React.Fragment>
      <Table>
        <thead>
          <tr>
            <th>{t('Wallet.Wallet')}</th>
            <th>{t('Wallet.Wallet Number')}</th>
            <th>{t('Wallet.Available Balance')}</th>
            <th>{t('Wallet.Reserved')}</th>
            <th>{t('Wallet.Transaction History')}</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {!wallets.length ? (
            <tr>
              <td align='center' colSpan={7}>
                {t('No results')}
              </td>
            </tr>
          ) : (
            wallets.map((wallet: PaymentAgentWalletMappedDto) => {
              const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
              const isTransferFromRestricted = isRestricted(
                WalletRestrictions.TRANSFER_FROM,
                wallet.restrictions
              )
              const isWithdrawRestricted = isRestricted(
                WalletRestrictions.WITHDRAW,
                wallet.restrictions
              )
              const isDepositRestricted = isRestricted(
                WalletRestrictions.DEPOSIT,
                wallet.restrictions
              )

              return (
                <tr key={wallet.id}>
                  <td>
                    {hasAllRestrictions && <WarningIcon size={20} color={'error'} />}
                    {wallet.currency.id} {t('Payment Agent.PA Wallet')}
                  </td>
                  <td>{wallet.name}</td>
                  <td>
                    <strong>{formatMoney(wallet.balance, wallet.currency.id)}</strong>
                  </td>
                  <td>{formatMoney(wallet.reserved, wallet.currency.id)}</td>
                  <td>
                    <Link
                      className='is-link'
                      to='/dashboard/payment-agent/transaction-history'
                      state={{ walletId: wallet.id }}
                    >
                      {t('View')}
                    </Link>
                  </td>
                  <td>
                    {isWithdrawRestricted && (
                      <Button
                        size='XS'
                        appearance='secondary'
                        state='disabled'
                        renderRightIcon={() =>
                          !hasAllRestrictions && <PlaceholderIcon color={'error'} />
                        }
                        onClick={() => handleRestrictionModalOpen(wallet)}
                      >
                        {t('Withdraw')}
                      </Button>
                    )}
                    {!isWithdrawRestricted && (
                      <Link to={`/dashboard/payment-agent/wallets/${wallet.id}/withdrawal`}>
                        <Button size='XS' appearance='secondary'>
                          {t('Withdraw')}
                        </Button>
                      </Link>
                    )}
                  </td>
                  <td>
                    {isDepositRestricted && (
                      <Button
                        size='XS'
                        appearance='primary'
                        state='disabled'
                        renderRightIcon={() =>
                          !hasAllRestrictions && <PlaceholderIcon color={'error'} />
                        }
                        onClick={() => handleRestrictionModalOpen(wallet)}
                      >
                        {t('Deposit')}
                      </Button>
                    )}
                    {!isDepositRestricted && (
                      <Link to={`/dashboard/payment-agent/wallets/${wallet.id}/deposit`}>
                        <Button size='XS' appearance='primary'>
                          {t('Deposit')}
                        </Button>
                      </Link>
                    )}
                  </td>
                  <td>
                    {isTransferFromRestricted && (
                      <Button
                        size='XS'
                        appearance='secondary'
                        state='disabled'
                        renderRightIcon={() =>
                          !hasAllRestrictions && <PlaceholderIcon color={'error'} />
                        }
                        onClick={() => handleRestrictionModalOpen(wallet)}
                      >
                        {t('Transfer')}
                      </Button>
                    )}
                    {!isTransferFromRestricted && (
                      <Link to={`/dashboard/payment-agent/wallets/${wallet.id}/transfer`}>
                        <Button size='XS' appearance='secondary'>
                          {t('Transfer')}
                        </Button>
                      </Link>
                    )}
                  </td>
                </tr>
              )
            })
          )}
        </tbody>
      </Table>
    </React.Fragment>
  )
}
