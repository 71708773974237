import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { SelectModal, SelectModalOption } from '../../global/field/SelectModal'
import { Modal } from '../../global/modal/Modal'
import {
  CampaignExtendedNameDto,
  CreateTradingAccountCampaignFormValues,
} from './CreateTradingAccountCampaignForm'

interface WalletModalProps {
  campaigns: CampaignExtendedNameDto[]
  isCampaign?: boolean
  onSelectOption(platform: CampaignExtendedNameDto): void

  onClose(): void
}

export const RegularCampaignAccountTypeModal: React.FC<WalletModalProps> = ({
  onSelectOption,
  onClose,
  campaigns,
  isCampaign,
}) => {
  const { values } = useFormikContext<CreateTradingAccountCampaignFormValues>()
  const { t } = useTranslation()
  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Trading Account.Account Type')}
          renderOptions={() => (
            <div className='control'>
              {campaigns
                .filter((campaign) => (isCampaign ? campaign.id !== '0' : true))
                .map((campaign) => (
                  <SelectModalOption
                    label={campaign.name}
                    value={campaign.id === values.tradingAccount?.id.toString()}
                    onClick={() => onSelectOption(campaign)}
                    key={campaign.id}
                  />
                ))}
            </div>
          )}
        />
      )}
    />
  )
}
