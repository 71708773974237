import { IconProps } from './IconProps'

export function FailureExpressionIcon(props: IconProps) {
  const size = props.size || 20

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_73921_354511)'>
        <circle cx='10' cy='10' r='10' fill='#EF0000' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M6.16591 6.16515C5.88928 6.44177 5.88928 6.89026 6.16591 7.16688L13.237 14.238C13.5136 14.5146 13.9621 14.5146 14.2387 14.238C14.5153 13.9613 14.5153 13.5128 14.2387 13.2362L7.16764 6.16515C6.89102 5.88853 6.44253 5.88853 6.16591 6.16515Z'
          fill='white'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14.2384 6.16515C14.515 6.44177 14.515 6.89026 14.2384 7.16688L7.16732 14.238C6.8907 14.5146 6.44221 14.5146 6.16559 14.238C5.88897 13.9613 5.88897 13.5128 6.16559 13.2362L13.2367 6.16515C13.5133 5.88853 13.9618 5.88853 14.2384 6.16515Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_73921_354511'>
          <rect width={size} height={size} fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
