import React from 'react'

import styles from './Icons.module.scss'

export function PendingIcon(props: { size?: number }): React.ReactElement {
  const size = props.size || 112

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 112 112'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M56 97.9991C51.0911 97.9991 46.3789 97.1569 42 95.6092C25.6873 89.8435 14 74.2861 14 55.9991C14 44.7275 18.4401 34.493 25.6667 26.9492'
        stroke={styles.warning}
        strokeWidth='3'
        strokeLinecap='round'
        strokeDasharray='2 8'
      />
      <path
        d='M55.9998 98C79.1958 98 97.9998 79.196 97.9998 56C97.9998 32.804 79.1958 14 55.9998 14C44.0754 14 33.3117 18.9694 25.6665 26.9501'
        stroke={styles.warning}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M55.9998 30.3337V56.0003L69.9998 65.3337'
        stroke={styles.warning}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}
