import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TextField } from '../global/field/TextField'
import { InfoIcon } from '../icons/InfoIcon'
import { Tooltip } from '../ui/Popups/Tooltip/Tooltip'
import { Text } from '../ui/Typography/Typography'
import { useWindowResize } from '../utils/domUtils'

import styles from './TwoFactorAuthPage.module.scss'

interface Props {
  onConfirm(recoveryCode: string): void
}

export const RecoveryCodeModal: React.FC<Props> = ({ onConfirm }) => {
  const isMobile = useWindowResize()
  const { t } = useTranslation()
  const [recoveryCode, setRecoveryCode] = useState('')

  return (
    <React.Fragment>
      <header className={'modal-card-head'}>
        <p className={classNames(styles.recoveryModalTitle, 'modal-card-title')}>
          {t('TwoFactorAuth.Enter your recovery code')}
          <Tooltip
            autoHide={2000}
            direction='bottom'
            content={t(
              'TwoFactorAuth.We provided you with recovery codes when you first enabled 2FA'
            )}
          >
            <InfoIcon size={12} />
          </Tooltip>
        </p>
      </header>
      <div className={styles.modalBodyWrapper}>
        <Text>{t('TwoFactorAuth.Use one of your Recovery codes to access Client Area')}</Text>
        <TextField
          value={recoveryCode}
          onChange={setRecoveryCode}
          type='text'
          label={t('TwoFactorAuth.Recovery code')}
        />
      </div>
      <footer className={'modal-card-foot'}>
        <button
          className='button'
          disabled={recoveryCode.length < 1}
          onClick={() => onConfirm(recoveryCode)}
        >
          {t('Confirm')}
        </button>
      </footer>
    </React.Fragment>
  )
}
