import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useOnCloseModal, useOnOpenModal } from '../utils/cookie.utils'
import { useDisableMainScroll } from './useDisableMainScroll'

export const useModalScrollHandler = (): void => {
  const { disableMainScroll, enableMainScroll } = useDisableMainScroll()
  const location = useLocation()

  useEffect(() => {
    enableMainScroll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  useOnCloseModal(() => {
    enableMainScroll()
  })

  useOnOpenModal(() => {
    disableMainScroll()
  })
}
