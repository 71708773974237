import { NameDto } from './NameDto'

export enum DocumentGeneralStatusType {
  DocumentsRequired = 1,
  MoreDocumentsRequired = 2,
  VerificationPending = 3,
  RejectedDocuments = 4,
  DocumentsApproved = 5,
  AdditionalDocuments = 6,
}

export interface DocumentCategoryDto {
  id: number
  name: string
  title: string
  maxUploadFileSizeInMb: number
}

export interface DocumentCategoryStatusDto {
  category: DocumentCategoryDto
  status: NameDto
}

export interface DocumentGeneralStatusDto {
  generalStatus: NameDto
  categoryStatuses: DocumentCategoryStatusDto[]
}

export const isDocumentsRequiredGeneralStatus = (value: number): boolean => {
  return value === DocumentGeneralStatusType.DocumentsRequired
}

export const isMoreDocumentsRequiredGeneralStatus = (value: number): boolean => {
  return value === DocumentGeneralStatusType.MoreDocumentsRequired
}

export const isVerificationPendingGeneralStatus = (value: number): boolean => {
  return value === DocumentGeneralStatusType.VerificationPending
}

export const isRejectedDocumentsGeneralStatus = (value: number): boolean => {
  return value === DocumentGeneralStatusType.RejectedDocuments
}

export const isDocumentsApprovedGeneralStatus = (value: number): boolean => {
  return value === DocumentGeneralStatusType.DocumentsApproved
}

export const isAdditionalDocumentsGeneralStatus = (value: number): boolean => {
  return value === DocumentGeneralStatusType.AdditionalDocuments
}
