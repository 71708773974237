import styles from './Icons.module.scss'

export function LinkIcon(props: { size?: number }) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.5795 10.3401C12.1486 8.90921 9.82858 8.90921 8.39767 10.3401L4.51131 14.2265C3.0804 15.6574 3.0804 17.9774 4.51131 19.4083C5.94223 20.8392 8.2622 20.8392 9.69312 19.4083L11.6363 17.4651'
        stroke={styles.contrastMediumLow}
        strokeWidth='0.9'
        strokeLinecap='round'
      />
      <path
        d='M10.3409 13.5792C11.7718 15.0101 14.0918 15.0101 15.5227 13.5792L19.4091 9.69283C20.84 8.26191 20.84 5.94194 19.4091 4.51102C17.9781 3.08011 15.6582 3.08011 14.2273 4.51102L12.2841 6.4542'
        stroke={styles.contrastMediumLow}
        strokeWidth='0.9'
        strokeLinecap='round'
      />
    </svg>
  )
}
