import React from 'react'

import styles from './Icons.module.scss'

export const FailedStepIcon = () => (
  <svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' fill='white' />
    <path
      d='M16 13.5V17.5M16 19V20.5M7.0171 23H24.9829C24.9905 23 24.9954 22.9918 24.9916 22.9851L16.0087 7.01549C16.0049 7.0087 15.9951 7.0087 15.9913 7.0155L7.00838 22.9851C7.00463 22.9918 7.00945 23 7.0171 23Z'
      stroke={styles.error}
      strokeWidth='1.5'
    />
    <rect x='0.5' y='0.5' width='31' height='31' rx='15.5' stroke={styles.error} />
  </svg>
)
