import { IconProps } from './IconProps'

export function TelegramIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 2C9.34844 2 6.80312 3.05422 4.92969 4.92891C3.05469 6.80359 2 9.34891 2 12C2 14.6511 3.05469 17.1964 4.92969 19.0711C6.80312 20.9458 9.34844 22 12 22C14.6516 22 17.1969 20.9458 19.0703 19.0711C20.9453 17.1964 22 14.6511 22 12C22 9.34891 20.9453 6.80359 19.0703 4.92891C17.1969 3.05422 14.6516 2 12 2Z'
        fill='url(#paint0_linear_885_257262)'
      />
      <path
        d='M6.75612 11.8744C9.70946 10.6044 11.6783 9.76709 12.6628 9.36256C15.4768 8.20741 16.0608 8.00678 16.4423 8.00007C16.5261 7.99866 16.7129 8.01913 16.8348 8.11647C16.9361 8.1985 16.9646 8.30944 16.9788 8.38725C16.9915 8.46506 17.0089 8.64241 16.9947 8.78084C16.8427 10.3621 16.1827 14.1993 15.8472 15.9704C15.7063 16.7198 15.4262 16.971 15.1555 16.9955C14.5668 17.049 14.1204 16.6118 13.5507 16.2432C12.6596 15.6661 12.1563 15.3071 11.2906 14.7441C10.2903 14.0935 10.9392 13.7358 11.509 13.1515C11.6577 12.9985 14.2502 10.6712 14.2993 10.4601C14.3056 10.4337 14.312 10.3352 14.2518 10.2833C14.1933 10.2313 14.1062 10.2491 14.0429 10.2632C13.9526 10.2832 12.5298 11.2126 9.76953 13.0511C9.36594 13.3252 9.00034 13.4588 8.67114 13.4518C8.31028 13.4441 7.61393 13.2499 7.09639 13.084C6.4633 12.8804 5.95839 12.7727 6.00271 12.4269C6.02487 12.2469 6.27656 12.0627 6.75612 11.8744Z'
        fill='white'
      />
      <defs>
        <linearGradient
          id='paint0_linear_885_257262'
          x1='12'
          y1='2'
          x2='12'
          y2='22'
          gradientUnits='userSpaceOnUse'
        >
          <stop stop-color='#2AABEE' />
          <stop offset='1' stop-color='#229ED9' />
        </linearGradient>
      </defs>
    </svg>
  )
}
