import { useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { WalletDepositFormValues } from '../../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'
import {
  MasterTransaction,
  isTransactionFormRedirect,
  isTransactionHistory,
  isTransactionSuccessful,
  isTransactionUnSuccessful,
  isTransactionUrlRedirect,
} from '../../../../../model/MasterTransaction'
import { isPaymentProviderBankType } from '../../../../../model/PaymentProviderType'
import { useAccountReadContext } from '../../../../../utils/AccountContextContext'

interface UseDepositSubmitValue {
  handleDepositSubmit: (data: MasterTransaction, values: WalletDepositFormValues) => void
}

export const useDepositSubmit = (): UseDepositSubmitValue => {
  const { account } = useAccountReadContext()
  const navigate = useNavigate()
  const location = useLocation()
  const isSignupFlow = useMemo(() => location.pathname.includes('sign-up'), [location.pathname])
  const redirectPath = useMemo(() => {
    if (isSignupFlow) {
      const hasAdditionalId = localStorage.getItem('hasAdditionalId')
      return `/sign-up/${hasAdditionalId ? 'confirm' : `documents-info/${account?.id}`}`
    }

    return '/dashboard/traders-room/transaction-history'
  }, [isSignupFlow, account?.id])

  const handleDepositSubmit = (data: MasterTransaction, values: WalletDepositFormValues) => {
    if (isTransactionHistory(data)) {
      navigate(`/dashboard/traders-room/transaction-history`)
    }

    if (isPaymentProviderBankType(values.paymentProvider.providerCategory.id)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/transaction/succeed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }

    if (isTransactionUrlRedirect(data)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/redirect`, {
        state: {
          transaction: data,
          values,
          redirectPath,
        },
      })
    }

    if (isTransactionFormRedirect(data)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/redirect`, {
        state: {
          transaction: data,
          values,
          redirectPath,
        },
      })
    }

    if (isTransactionSuccessful(data)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/transaction/succeed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }

    if (isTransactionUnSuccessful(data)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/transaction/failed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }
  }

  return {
    handleDepositSubmit,
  }
}
