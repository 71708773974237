import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import styles from './ResetTradingAccountPasswordModal.module.scss'

interface Props {
  onCancel: () => void
  onConfirm: () => void
}
export const ResetTradingAccountPasswordModal: React.FC<Props> = ({ onCancel, onConfirm }) => {
  const { t } = useTranslation()
  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('Trading Account.We will reset your password')}</p>
      </header>
      <section className={classNames(styles.body, 'modal-card-body')}>
        <p>
          {t(
            'Trading Account.The system will generate a new account password and send it via email. Do you want to proceed'
          )}
        </p>
      </section>
      <footer className='modal-card-foot'>
        <button className='button' onClick={onCancel}>
          {t('Cancel')}
        </button>
        <button className='button' onClick={onConfirm}>
          {t('Trading Account.Yes, reset password')}
        </button>
      </footer>
    </>
  )
}
