import React from 'react'
import { useTranslation } from 'react-i18next'

import { IntroducingBrokerMappedDto } from '../../IntroducingBroker/Wallets/IBWalletsPage'
import { WalletDto } from '../../model/WalletDto'
import { Text, TextStrong } from '../../ui/Typography/Typography'
import { Button } from '../button/Button'
import { CopyButton } from '../button/CopyButton'
import { CancelActionModal } from './CancleActionModal'

import styles from './WalletNumberModal.module.scss'

interface Props {
  wallet: WalletDto | IntroducingBrokerMappedDto

  onConfirm(): void
}

export const WalletNumberModalBody: React.FC<Props> = ({ wallet }) => {
  return (
    <section className={`modal-card-body p-0 ${styles.overflowVisible}`}>
      <div className='text is-flex is-justify-content-space-between is-align-items-center'>
        <Text>
          <Text className={styles.walletNumberTitle}>Wallet number: </Text>
          <TextStrong>{wallet.name}</TextStrong>
        </Text>
        <div className='is-pulled-right'>
          <CopyButton value={wallet.name} />
        </div>
      </div>
    </section>
  )
}

export const WalletNumberModal: React.FC<Props> = (props) => {
  const { onConfirm } = props
  const { t } = useTranslation()

  return (
    <CancelActionModal
      title={t('Wallet.Wallet Number')}
      footer={[
        <Button
          className='button'
          onClick={onConfirm}
          type='button'
          appearance='secondary'
          size='L'
        >
          {t('Ok')}
        </Button>,
      ]}
      className={styles.overflowVisible}
    >
      <WalletNumberModalBody wallet={props.wallet} onConfirm={onConfirm} />
    </CancelActionModal>
  )
}
