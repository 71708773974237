import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import {
  getTransactionRedirectForm,
  getTransactionRedirectUrl,
} from '../../model/MasterTransaction'
import { transactionStorage } from '../DepositRedirectFromProviderPage/DepositRedirectFromProviderPage'
import { useDepositRedirectToProvider } from './DepositRedirectToProviderPage'

const useDepositFormRedirect = () => {
  const data = useDepositRedirectToProvider()
  const form = getTransactionRedirectForm(data.transaction)

  return {
    data,
    form,
  }
}

export const DepositUrlRedirectPage: React.FC = () => {
  const { form, data } = useDepositFormRedirect()

  const { t } = useTranslation()

  useEffect(() => {
    const redirectUrl = getTransactionRedirectUrl(data.transaction)
    if (redirectUrl && form && data) {
      transactionStorage().addProviderDepositResponseTransaction(data)
      window.location.href = redirectUrl
    }
  }, [data, data.transaction, form])

  return <Loading isLoading showLoadingIcon text={t('Wallet.Depositing ...')} />
}
