import { IconProps, iconColor } from './IconProps'

export function CalendarIcon(props: IconProps) {
  const size = props.size || 20

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 18 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6 9H4V11H6V9ZM10 9H8V11H10V9ZM14 9H12V11H14V9ZM16 2H15V0H13.5V2H4.5V0H3V2H2C0.89 2 0.00999999 2.9 0.00999999 4L0 18C0 18.5304 0.210714 19.0391 0.585786 19.4142C0.960859 19.7893 1.46957 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM16.5 18.5H1.5V6H16.5V18.5Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
