import { NameDto } from './NameDto'

export enum SubscriptionLevel {
  ONE = 1,
  TWO = 2,
  NOT_APPLICABLE = 3,
}

export enum SubscriptionType {
  MARKET_DATA = 1,
  PLATFORM = 2,
}

export enum SubscriptionGroupType {
  MARKET_DATA = 'Market Data',
  PLATFORM = 'Platform',
}

export enum SubscriptionClassificationType {
  RETAIL = 1,
  PROFESSIONAL = 2,
}

export enum SubscriptionStatus {
  PENDING = 1,
  DEDUCTING_FUNDS = 2,
  IN_PROCESS = 3,
  COMPLETED = 4,
  FAILED = 5,
  IN_PROCESS_CANCEL = 6,
  CANCELLED = 7,
  ACTIVE = 8,
}

export enum SubscriptionRequestType {
  NEW = 1,
  CANCELLATION = 2,
  UPGRADE = 3,
  DOWNGRADE = 4,
}

export const showSubLevel = (type: SubscriptionType, level: SubscriptionLevel): boolean =>
  type === SubscriptionType.MARKET_DATA &&
  (level === SubscriptionLevel.ONE || level === SubscriptionLevel.TWO)

export const SubscriptionTypes = [
  { id: SubscriptionType.MARKET_DATA, name: 'Market Data' },
  { id: SubscriptionType.PLATFORM, name: 'Platform' },
]

export const SubscriptionLevels = [
  { id: SubscriptionLevel.ONE, name: 'Level 1' },
  { id: SubscriptionLevel.TWO, name: 'Level 2' },
  { id: SubscriptionLevel.NOT_APPLICABLE, name: 'Not Applicable' },
]

export const SubscriptionClassificationTypes = [
  { id: SubscriptionClassificationType.RETAIL, name: 'Retail' },
  { id: SubscriptionClassificationType.PROFESSIONAL, name: 'Professional' },
]

export const SubscriptionStatuses = [
  { id: SubscriptionStatus.PENDING, name: 'Pending' },
  { id: SubscriptionStatus.DEDUCTING_FUNDS, name: 'Deducting Funds' },
  { id: SubscriptionStatus.IN_PROCESS, name: 'In Process' },
  { id: SubscriptionStatus.COMPLETED, name: 'Completed' },
  { id: SubscriptionStatus.FAILED, name: 'Failed' },
  { id: SubscriptionStatus.IN_PROCESS_CANCEL, name: 'In Process Cancel' },
  { id: SubscriptionStatus.CANCELLED, name: 'Cancelled' },
  { id: SubscriptionStatus.ACTIVE, name: 'Active' },
]

const isSubscriptionStatusesPending = (status: SubscriptionStatus) => {
  return SubscriptionStatus.PENDING === status
}

const isSubscriptionStatusesActive = (status: SubscriptionStatus) => {
  return SubscriptionStatus.ACTIVE === status
}

export const isSubscriptionAllowedToDelete = (state?: NameDto): boolean => {
  if (!state?.id) {
    return true
  }
  return isSubscriptionStatusesPending(state.id) || isSubscriptionStatusesActive(state.id)
}

export const SubscriptionRequestTypes = [
  { id: SubscriptionRequestType.NEW, name: 'New' },
  { id: SubscriptionRequestType.CANCELLATION, name: 'Cancellation' },
  { id: SubscriptionRequestType.UPGRADE, name: 'Upgrade' },
  { id: SubscriptionRequestType.DOWNGRADE, name: 'Downgrade' },
]

export interface SubscriptionHistoryItem {
  id: string
  description: string
  client: NameDto<string>
  subscriptionDetailId: string
  subscriptionDetailType: NameDto<SubscriptionLevel>
  subscriptionGroupType: NameDto<SubscriptionType>
  level: string
  name: string
  amount: number
  state: NameDto
  dueDate: string
  transactionId: string
  tradingAccountId: string
  createdDate: string
  updatedDate: string
  type: NameDto
  subscriptionTimeInForce: NameDto
  subscription: {
    id: string
    name: string
    description: string
  }
}

export interface SubscriptionStateHistoryDto {
  id: number
  name: string
  created: string
}

export type SubscriptionsSort = 'DateCreated'
