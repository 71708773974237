import { IconProps, iconColor } from './IconProps'

export function RefreshIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7034_1694)'>
        <path
          d='M15.7642 7.39141C15.0193 6.04079 13.8293 4.99021 12.3967 4.41867C10.9641 3.84713 9.37773 3.78999 7.90776 4.25699C6.43779 4.724 5.17522 5.68624 4.33518 6.97978C3.49515 8.27332 3.12961 9.81811 3.30086 11.3509C3.47212 12.8838 4.16956 14.3098 5.27436 15.3861C6.37916 16.4623 7.82295 17.1222 9.35974 17.2533C10.8965 17.3844 12.4312 16.9785 13.7023 16.1049C14.9734 15.2313 15.9023 13.944 16.3307 12.4623M15.7642 7.39141L16.1088 3.66205M15.7642 7.39141L12.2587 7.18819'
          stroke={iconColor(props)}
          strokeWidth='1.5'
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_7034_1694'>
          <rect width={size} height={size} fill={iconColor(props)} />
        </clipPath>
      </defs>
    </svg>
  )
}
