import styles from './Icons.module.scss'

export function GettingStartedDepositIcon() {
  return (
    <svg
      width='592'
      height='296'
      viewBox='0 0 592 296'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='592' height='296' rx='16' fill={styles.background} />
      <circle
        cx='136.124'
        cy='148'
        r='26.6667'
        fill={styles.backgroundSecondary}
        stroke={styles.text}
        strokeWidth='3'
      />
      <path
        d='M144.124 138.181C144.124 138.181 142.879 133.334 137.901 133.333C132.882 133.332 130.435 135.757 130.435 140.606V146.666M144.124 160H128.315C128.18 160 128.107 159.831 128.195 159.73C128.826 159.003 130.435 156.863 130.435 153.939V146.666M130.435 146.666H139.146M130.435 146.666H125.457'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <circle
        cx='183.749'
        cy='148'
        r='26.6667'
        fill={styles.backgroundSecondary}
        stroke={styles.text}
        strokeWidth='3'
      />
      <path
        d='M191.623 140.122C190.784 137.161 186.823 135.371 183.748 135.394M183.748 135.394C179.811 135.424 174.893 139.458 175.877 143.273C177.504 149.582 189.996 146.419 191.623 152.728C192.607 156.543 187.686 160.577 183.748 160.607M183.748 135.394L183.623 130.667M183.748 160.607C180.674 160.63 176.716 158.839 175.877 155.879M183.748 160.607V165.334'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <circle
        cx='231.874'
        cy='148'
        r='26.6667'
        fill={styles.backgroundSecondary}
        stroke={styles.text}
        strokeWidth='3'
      />
      <path
        d='M241.207 138.667C239.361 136.821 237.009 135.564 234.449 135.054C231.888 134.545 229.234 134.806 226.823 135.805C224.411 136.804 222.349 138.496 220.899 140.667C219.449 142.837 218.674 145.389 218.674 148C218.674 150.611 219.449 153.163 220.899 155.333C222.349 157.504 224.411 159.196 226.823 160.195C229.234 161.194 231.888 161.455 234.449 160.946C237.009 160.436 239.361 159.179 241.207 157.333'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M214.441 151.521H233.459'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M214.441 143.596H233.459'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M301.207 136.267H338.54M338.54 136.267L329.207 126.934M338.54 136.267L329.207 145.6'
        stroke='#CC0000'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M317.207 161.333H354.54M354.54 161.333L345.207 152M354.54 161.333L345.207 170.667'
        stroke='#CC0000'
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M415.875 128H467.875C472.293 128 475.875 131.582 475.875 136V172C475.875 176.418 472.293 180 467.875 180H411.875C407.457 180 403.875 176.418 403.875 172V124C403.875 119.582 407.457 116 411.875 116H463.875'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path d='M461.875 146V158' stroke={styles.text} strokeWidth='3' strokeLinecap='round' />
    </svg>
  )
}

export function GettingStartedDepositMobileIcon() {
  return (
    <svg
      width='312'
      height='128'
      viewBox='0 0 312 128'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='312' height='128' rx='12' fill={styles.background} />
      <circle
        cx='63.9987'
        cy='63.9987'
        r='16.6667'
        fill={styles.background}
        stroke={styles.text}
        strokeWidth='2'
      />
      <path
        d='M68.9987 57.8623C68.9987 57.8623 68.2209 54.8328 65.1098 54.832C61.9729 54.8313 60.4431 56.3472 60.4431 59.3775V63.1654M68.9987 71.4987H59.2566C59.122 71.4987 59.0476 71.3276 59.133 71.2236C59.5713 70.6892 60.4431 69.4123 60.4431 67.7108V63.1654M60.4431 63.1654H65.8876M60.4431 63.1654H57.332'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle
        cx='87.9987'
        cy='63.9987'
        r='16.6667'
        fill={styles.background}
        stroke={styles.text}
        strokeWidth='2'
      />
      <path
        d='M92.9205 59.0771C92.3964 57.2269 89.9205 56.1081 87.999 56.1227M87.999 56.1227C85.5384 56.1413 82.4647 58.6622 83.0796 61.0468C84.0964 64.99 91.9038 63.0133 92.9205 66.9558C93.5354 69.3404 90.4597 71.8616 87.999 71.8802M87.999 56.1227L87.9204 53.168M87.999 71.8802C86.0776 71.8948 83.6036 70.7757 83.0796 68.9255M87.999 71.8802V74.8346'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <circle
        cx='111.999'
        cy='63.9987'
        r='16.6667'
        fill={styles.background}
        stroke={styles.text}
        strokeWidth='2'
      />
      <path
        d='M117.832 58.1667C116.678 57.0129 115.208 56.2272 113.608 55.9089C112.008 55.5906 110.349 55.754 108.842 56.3784C107.334 57.0028 106.046 58.0601 105.139 59.4168C104.233 60.7734 103.749 62.3684 103.749 64C103.749 65.6316 104.233 67.2266 105.139 68.5832C106.046 69.9399 107.334 70.9972 108.842 71.6216C110.349 72.246 112.008 72.4094 113.608 72.0911C115.208 71.7728 116.678 70.9871 117.832 69.8333'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M101.102 66.1992H112.988'
        stroke={styles.text}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M101.102 61.2461H112.988'
        stroke={styles.text}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M159.332 56.6654H182.665M182.665 56.6654L176.832 50.832M182.665 56.6654L176.832 62.4987'
        stroke={styles.primary}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M169.332 72.3333H192.665M192.665 72.3333L186.832 66.5M192.665 72.3333L186.832 78.1667'
        stroke={styles.primary}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path
        d='M232 54H258C260.209 54 262 55.7909 262 58V76C262 78.2091 260.209 80 258 80H230C227.791 80 226 78.2091 226 76V52C226 49.7909 227.791 48 230 48H256'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <path d='M255 63V69' stroke={styles.text} strokeWidth='1.5' strokeLinecap='round' />
    </svg>
  )
}
