import React from 'react'
import classNames from 'classnames'

import styles from './InfoNumberIcon.module.css'

interface InfoNumberIconProps {
  number: number
}

const InfoNumberIcon: React.FC<InfoNumberIconProps> = ({ number }) => {
  const circleClass = classNames(styles.infoNumberCircle, {
    [styles.correction]: number === 4,
  })
  return (
    <div className={styles.infoNumberIcon}>
      <div className={circleClass}>{number}</div>
    </div>
  )
}

export default InfoNumberIcon
