import React from 'react'
import { useFormikContext } from 'formik'

import { createFormCurrencyAmountField } from '../../../../global/formField/CurrencyAmountRateField'
import { WalletWithdrawFormValues, getCurrencyLimits } from './WalletWithdrawForm'

const FormCurrencyAmountField = createFormCurrencyAmountField<WalletWithdrawFormValues>()

interface CurrencyAmountFieldProps {
  hint?: string
}

export const CurrencyAmountField: React.FC<CurrencyAmountFieldProps> = ({ hint }) => {
  const { values } = useFormikContext<WalletWithdrawFormValues>()

  const fromCurrency = values.paymentProvider.currency.id
  const toCurrency = values.wallet.currency?.id
  const currencyRestriction = getCurrencyLimits(values.wallet, values.paymentProvider)

  return (
    <FormCurrencyAmountField
      name='amount'
      hint={hint}
      fromCurrency={fromCurrency}
      toCurrency={toCurrency}
      max={currencyRestriction?.maxAmount}
      required
    />
  )
}
