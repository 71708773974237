import { IconProps, iconColor } from './IconProps'

export function ReportIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14 3H6C5.44772 3 5 3.44772 5 4V20C5 20.5523 5.44772 21 6 21H18C18.5523 21 19 20.5523 19 20V8M14 3L19 8M14 3V7C14 7.55228 14.4477 8 15 8H19M8.5 11.5H15.5M15.5 14.5H8.5M15.5 17.5H8.5'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
