import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import cfdDeskMobile from '../../images/cfd-desk-mob.png'
import cfdDesk from '../../images/cfd-desk.png'
import etdDeskMobile from '../../images/etd-desk-mob.png'
import etdDesk from '../../images/etd-desk.png'
import { TickmillProductType } from '../../model/TickmillProductType'
import { Text, TextLargeStrong, TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { Button } from '../button/Button'
import { useProductReadContext } from '../context/ProductContext'
import { Modal } from '../modal/Modal'

import styles from './RegisterProductModal.module.scss'

interface Props {
  onClose(): void
  onConfirm(): void
  onCancel(): void
}

interface Product {
  title: string
  subtitle: string
  description: string
  buttonText: string
}

export const RegisterProductModal: FC<Props> = (props) => {
  const { onClose, onCancel, onConfirm } = props

  const { t } = useTranslation()

  const { isDefaultCFDProductType } = useProductReadContext()
  const isCFDProductType = isDefaultCFDProductType()

  const products: Record<TickmillProductType, Product> = {
    [TickmillProductType.ETD]: {
      title: t('Futures Trading with Tickmill'),
      subtitle: t('Trade exchange traded derivatives such as Futures on Stock Indices'),
      description: t(''),
      buttonText: t('Trade Futures'),
    },
    [TickmillProductType.CFD]: {
      title: t('Trade CFDs with Tickmill'),
      subtitle: t(
        'Switch to a world class broker that offers CFDs on 1,200+ Instruments, chosen by 300k traders globally'
      ),
      description: t(
        'To become an CFD-trader, you are required to complete a quick appropriateness test'
      ),
      buttonText: t('Become CFD Trader'),
    },
  }

  const images = {
    desktop: isCFDProductType ? etdDesk : cfdDesk,
    mobile: isCFDProductType ? etdDeskMobile : cfdDeskMobile,
  }

  const targetProduct = useMemo(() => {
    return isCFDProductType ? products[TickmillProductType.ETD] : products[TickmillProductType.CFD]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Modal
      size='large'
      closeModal={onClose}
      render={() => (
        <div className={styles.modal}>
          <img src={images.mobile} alt='desk' className={styles.image} />
          <div className={styles.contentBox}>
            <div className={styles.productContainer}>
              <h1 className={styles.title}>
                <strong>{targetProduct.title}</strong>
              </h1>
              <TextLargeStrong className={styles.description}>
                {targetProduct.subtitle}
              </TextLargeStrong>
              <Text className={styles.description}>{targetProduct.description}</Text>
            </div>
            <div className={styles.actions}>
              <Button appearance='plain' size='M' onClick={onCancel} type='button'>
                <TextSmall className={styles.cancel}>{t('Not now')}</TextSmall>
              </Button>
              <Button appearance='primary' size='M' onClick={onConfirm}>
                <TextSmallStrong>{targetProduct.buttonText}</TextSmallStrong>
              </Button>
            </div>
          </div>
        </div>
      )}
    />
  )
}
