import { useMemo } from 'react'

import { DepositRedirectToProviderPage } from '../PaymentProviderRedirect/DepositRedirectToProviderPage/DepositRedirectToProviderPage'
import { RouteConfig } from '../routing/RenderRoutes'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { DateFilterContextProvider } from '../utils/DateFilterContext'
import { PaymentAgent } from './PaymentAgent'
import { ProfilePage } from './Profile/ProfilePage'
import { PaymentAgentTransactionHistoryPage } from './TransactionHistory/PaymentAgentTransactionHistoryPage'
import { PaymentAgentWalletTransferPage } from './Wallets/PaymentAgentTransfer/PaymentAgentWalletTransferPage'
import { PaymentAgentWalletDepositPage } from './Wallets/PaymentAgentWalletDepositPage'
import { PaymentAgentWalletDepositTransactionSuccessPage } from './Wallets/PaymentAgentWalletDepositTransactionSuccessPage'
import { PaymentAgentWalletWithdrawPage } from './Wallets/PaymentAgentWalletWithdrawPage'
import { PaymentAgentWalletWithdrawTransactionSuccessPage } from './Wallets/PaymentAgentWalletWithdrawTransactionSuccessPage'
import { PaymentAgentWalletsPage } from './Wallets/PaymentAgentWalletsPage'
import { PaymentProviderWalletDepositTransactionCompletedPage } from './Wallets/PaymentProviderWalletDepositTransactionCompletedPage'
import { PaymentProviderWalletDepositTransactionFailedPage } from './Wallets/PaymentProviderWalletDepositTransactionFailedPage'
import { PaymentProviderWalletWithdrawTransactionCompletedPage } from './Wallets/PaymentProviderWalletWithdrawTransactionCompletedPage'
import { PaymentProviderWalletWithdrawTransactionFailedPage } from './Wallets/PaymentProviderWalletWithdrawTransactionFailedPage'

export const usePARoutes = (): RouteConfig[] => {
  const visible = usePARoutesConditions()

  return useMemo(
    (): RouteConfig[] => [
      {
        path: 'payment-agent',
        outlet: <PaymentAgent />,
        condition: visible.isPAVisible,
        children: [
          {
            path: 'profile-info',
            element: <ProfilePage />,
          },
          {
            path: 'wallets',
            element: <PaymentAgentWalletsPage />,
            children: [
              {
                path: ':walletId',
                children: [
                  {
                    path: 'withdrawal',
                    element: <PaymentAgentWalletWithdrawPage />,
                    children: [
                      {
                        path: 'transaction',
                        children: [
                          {
                            path: 'succeed',
                            element: <PaymentAgentWalletWithdrawTransactionSuccessPage />,
                          },
                          {
                            path: 'completed',
                            element: <PaymentProviderWalletWithdrawTransactionCompletedPage />,
                          },
                          {
                            path: 'failed',
                            element: <PaymentProviderWalletWithdrawTransactionFailedPage />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'deposit',
                    element: <PaymentAgentWalletDepositPage />,
                    children: [
                      {
                        path: 'transaction',
                        children: [
                          {
                            path: 'succeed',
                            element: <PaymentAgentWalletDepositTransactionSuccessPage />,
                          },
                          {
                            path: 'completed',
                            element: <PaymentProviderWalletDepositTransactionCompletedPage />,
                          },
                          {
                            path: 'failed',
                            element: <PaymentProviderWalletDepositTransactionFailedPage />,
                          },
                        ],
                      },
                      {
                        path: 'redirect',
                        element: <DepositRedirectToProviderPage />,
                      },
                    ],
                  },
                  {
                    path: 'transfer',
                    element: <PaymentAgentWalletTransferPage />,
                  },
                ],
              },
            ],
          },
          {
            path: 'transaction-history',
            element: (
              <DateFilterContextProvider>
                <PaymentAgentTransactionHistoryPage />
              </DateFilterContextProvider>
            ),
          },
        ],
      },
    ],
    [visible.isPAVisible]
  )
}

interface RoutesConditions {
  isPAVisible: boolean
}

export const usePARoutesConditions = (): RoutesConditions => {
  const { account } = useAccountReadContext()

  const isPAVisible = useMemo(
    () =>
      !!account?.clientPaymentAgent &&
      !!account?.clientPaymentAgent.isActive &&
      !!account?.visibilityConfiguration.hasPARoom,
    [account]
  )

  return {
    isPAVisible,
  }
}
