import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { AccountDetailedStatus } from '../../model/AccountDetailedStatus'
import { useAccountReadContext } from '../AccountContextContext'
import { isAccountCompanyDepositStandard } from './accountCompanyStatuses'
import { isDepositBeforeKYC } from './accountKYCStatuses'

export const useAccountVisibilityStatuses = () => {
  const { account } = useAccountReadContext()

  const accountVisibilityLockUnlock = isAccountVisibilityLockUnlockStatus(account)
  const accountVisibilityDocumentManagement = isAccountVisibilityDocumentManagementStatus(account)

  return {
    isAccountVisibilityLockUnlockStatus: accountVisibilityLockUnlock,
    isAccountVisibilityDocumentManagementStatus: accountVisibilityDocumentManagement,
  }
}

const isAccountVisibilityLockUnlockStatus = (account: AccountDetailedDto | undefined): boolean => {
  const depositBeforeKYC = isDepositBeforeKYC(account)
  const skipDocuments = isSkipVisibilityDocuments(account)
  const pendingVerification = isAccountPendingDocumentVerificationStatus(account)

  return (
    (depositBeforeKYC && skipDocuments) ||
    (depositBeforeKYC && !skipDocuments && pendingVerification)
  )
}

const isSkipVisibilityDocuments = (account: AccountDetailedDto | undefined): boolean => {
  return !!account?.visibilityConfiguration.skipDocuments
}

const isAccountVisibilityDocumentManagementStatus = (
  account: AccountDetailedDto | undefined
): boolean => {
  const depositStandard = isAccountCompanyDepositStandard(account)
  const skipDocuments = isSkipVisibilityDocuments(account)
  const pendingVerification = isAccountPendingDocumentVerificationStatus(account)

  return (
    (depositStandard && skipDocuments) || (depositStandard && !skipDocuments && pendingVerification)
  )
}

const isAccountPendingDocumentVerificationStatus = (
  account: AccountDetailedDto | undefined
): boolean => {
  return AccountDetailedStatus.PendingDocumentVerification === account?.status?.id
}
