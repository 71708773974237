import { AccountDetailedDto } from './AccountDetailedDto'

export enum AccountType {
  Individual = 1,
  Corporate = 2,
}

export const isAccountTypeIndividual = (
  accountType: AccountType
): accountType is AccountType.Individual => {
  return accountType === AccountType.Individual
}

export const isAccountTypeCorporate = (
  accountType: AccountType
): accountType is AccountType.Corporate => {
  return accountType === AccountType.Corporate
}

export const isAccountCorporate = (account?: AccountDetailedDto): boolean => {
  if (account?.type?.id === undefined || account?.type?.id === null) {
    return false
  }

  return isAccountTypeCorporate(account.type.id)
}
