import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FormTemplate } from '../../../global/templates/FormTemplate'
import { PaymentProvider } from '../../../model/PaymentProviderDto'
import { TermsConditions } from '../../TermsConditions/TermsConditions'
import { getCurrencyLimits } from './TradingAccountDepositForm/TradingAccountDepositForm'

import styles from './TradingAccountDepositTermsConditions.module.scss'

interface Props {
  paymentProvider: PaymentProvider
  onCancel(): void
}

export const TradingAccountDepositTermsConditions: FC<Props> = ({ paymentProvider, onCancel }) => {
  const { t } = useTranslation()

  const walletDepositTermsConditionsMessageFormat = (
    message: string,
    paymentProvider: PaymentProvider
  ): string => {
    const currencyLimit = getCurrencyLimits(paymentProvider)
    const minAmount = currencyLimit?.minAmount?.toString() || '0'
    const maxAmount = currencyLimit?.maxAmount?.toString() || '0'

    const currency = paymentProvider.currency.id || ''
    return message
      .replace(/{minAmount}/g, minAmount)
      .replace(/{maxAmount}/g, maxAmount)
      .replace(/{paymentProviderCurrency}/g, currency)
  }

  const formattedTerms = useMemo((): string[] => {
    return paymentProvider.parameters.termsConditions.map((term, index) =>
      paymentProvider
        ? walletDepositTermsConditionsMessageFormat(`${index + 1}. ${term.name}`, paymentProvider)
        : `${index + 1}. ${term.name}`
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentProvider])

  return (
    <FormTemplate
      title={t('Trading Account.General Terms and Conditions for Deposits')}
      goBack={onCancel}
      titleClassName={styles.customTitle}
    >
      <TermsConditions terms={formattedTerms} onCancel={onCancel} />
    </FormTemplate>
  )
}
