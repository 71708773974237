import { IconProps, iconColor } from './IconProps'

export function PasswordIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8 9.5H6C5.44772 9.5 5 9.94772 5 10.5V20.5C5 21.0523 5.44772 21.5 6 21.5H18C18.5523 21.5 19 21.0523 19 20.5V10.5C19 9.94772 18.5523 9.5 18 9.5H16M8 9.5V6C8 3.79086 9.79086 2 12 2V2C14.2091 2 16 3.79086 16 6V9.5M8 9.5H16M11 16.5V16.5C10.4477 15.9477 10.4477 15.0523 11 14.5V14.5C11.5523 13.9477 12.4477 13.9477 13 14.5V14.5C13.5523 15.0523 13.5523 15.9477 13 16.5V16.5C12.4477 17.0523 11.5523 17.0523 11 16.5Z'
        stroke={iconColor(props)}
        strokeWidth='2'
      />
    </svg>
  )
}
