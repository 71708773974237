import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import { Field, FieldAttributes, FormikProps, getIn, useFormikContext } from 'formik'

import { HideOffIcon } from '../../icons/HideOffIcon'
import { HideOnIcon } from '../../icons/HideOnIcon'
import { TextTiny } from '../../ui/Typography/Typography'
import { removeRestrictedSpecialCharacters } from '../../utils/validations'
import { useArabicSessionLanguage } from '../context/SessionSettingsContext'
import { getFieldValid, getPlaceholder, isFocus } from './helpers'

import styles from './FormPasswordField.module.scss'

export interface FormFieldProps<FormValues>
  extends Partial<FormikProps<FormValues>>,
    FieldAttributes<any> {
  namePrefix?: string
  name: string
  label?: string
  className?: string
  disabled?: boolean
  required?: boolean
  type?: string
}

// TODO: need todo component styles the same as FormField.tsx
export function RawFormField<FormValues>(props: FormFieldProps<FormValues>) {
  const {
    namePrefix = '',
    name,
    helpText,
    highlighted,
    renderIcons,
    showLabel = false,
    renderIconsLeft,
    className,
    label,
    type,
    ...inputProps
  } = props

  const fieldName = namePrefix + name
  const fieldRef = useRef<HTMLDivElement>(null)
  const context = useFormikContext<FormValues>()

  const value = getIn(context.values, name)
  const error = getIn(context.errors, name)
  const touched = getIn(context.touched, name)

  const [inputType, setInputType] = useState(type)

  const placeholder = getPlaceholder(props, value)
  const isArabic = useArabicSessionLanguage()
  const isValid = getFieldValid(error, touched)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.onChange) {
      props.onChange(event)
    } else {
      context.setFieldValue(fieldName, removeRestrictedSpecialCharacters(event.target.value))
    }
  }

  return (
    <div className={classNames('field', styles.field, className)} ref={fieldRef}>
      {(showLabel || isFocus({ value, label })) && !!label && (
        <label
          dir={isArabic ? 'rtl' : ''}
          className={classNames('label', styles.label, {
            'has-text-danger': !isValid,
          })}
        >
          {label}
        </label>
      )}
      <div className={classNames('control', styles.hideIconWrapper)}>
        <Field
          name={fieldName}
          {...inputProps}
          placeholder={placeholder}
          type={inputType}
          dir={isArabic ? 'rtl' : ''}
          onChange={handleChange}
          className={classNames('input', styles.input, {
            'is-danger': !isValid,
            'has-text-danger': !isValid,
          })}
        />

        <div dir={isArabic ? 'rtl' : ''} className={styles.hideIcon}>
          <span
            className='is-clickable'
            onClick={() => setInputType(() => (inputType === 'password' ? 'text' : 'password'))}
          >
            {inputType === 'password' ? <HideOffIcon /> : <HideOnIcon />}
          </span>
        </div>
      </div>
      {!isValid && (
        <TextTiny
          isParagraph
          className={classNames(styles.errorMessage, 'is-danger has-text-danger')}
        >
          {error}
        </TextTiny>
      )}
    </div>
  )
}

export function createFormPasswordField<V>(): React.ComponentType<FormFieldProps<V>> {
  return RawFormField
}
