import { useCallback } from 'react'

import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { useApiClient } from './ApiClient'
import { ClientApiClient } from './clientApi'
import { useFetchOne } from './useFetch'
import { useIBReferralCodes } from './useIBReferralCodes'

export const useIBLink = (referralCode?: string): string | undefined => {
  const { mtCode, ibCode } = useIBReferralCodes()
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const domain = window.location.host

  const referralLinkCallback = useCallback(async () => {
    return apiClient.getReferralLink(locale, domain, referralCode || ibCode || mtCode)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralCode])

  const { data } = useFetchOne(referralLinkCallback)
  const { url: urlLink, domainUrl, landingPageUrl } = data || {}

  const newPath = locale !== 'en' ? `/${locale}${landingPageUrl}` : landingPageUrl
  const newLp = `${domainUrl}${newPath}`
  return urlLink?.replace(
    /(lp=).*(&|$)/,
    (match, p1, p2) => `${p1}${encodeURIComponent(newLp)}${p2}`
  )
}
