import { useTranslation } from 'react-i18next'

import { useFormatNumber } from '../hooks/useFormatNumber'
import { TextSmall } from '../ui/Typography/Typography'

interface PriceRangeProps {
  minAmount?: number
  maxAmount?: number
}

export const PriceRange: React.FC<PriceRangeProps> = ({ minAmount = 0, maxAmount = 0 }) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  if (minAmount >= 0 && minAmount !== maxAmount) {
    return (
      <TextSmall>
        {formatMoney(minAmount)} - {formatMoney(maxAmount)}
        {'/'}
        {t('Subscriptions.mth')}
      </TextSmall>
    )
  } else {
    return (
      <TextSmall>
        {formatMoney(maxAmount)}
        {'/'}
        {t('Subscriptions.mth')}
      </TextSmall>
    )
  }
}
