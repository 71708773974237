import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

import { ProductKey } from '../global/context/ProductContext'
import { clearNotificationHiding } from '../model/Notification'
import { TokenStorage } from './tokenStorage'

export function useLocallyPersistedState<T>(
  key: string,
  defaultValue: T
): [T, Dispatch<SetStateAction<T>>] {
  const [value, setValue] = useState<T>(() => {
    const persistedValueRaw = localStorage.getItem(key)
    try {
      return persistedValueRaw ? JSON.parse(persistedValueRaw) : defaultValue
    } catch (error: unknown) {
      console.error('Failed to parse JSON:', error)
      return defaultValue
    }
  })

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === key) {
        if (e.newValue) {
          setValue(JSON.parse(e.newValue))
        } else {
          setValue(defaultValue)
        }
      }
    }

    window.addEventListener('storage', handleStorageChange)

    return () => {
      window.removeEventListener('storage', handleStorageChange)
    }
  }, [key, defaultValue])

  const setter = useCallback(
    (newValue: SetStateAction<T>) => {
      setValue((prevValue) => {
        const valueToStore =
          typeof newValue === 'function' ? (newValue as (prevState: T) => T)(prevValue) : newValue

        if (valueToStore !== undefined && valueToStore !== null) {
          localStorage.setItem(key, JSON.stringify(valueToStore))
        } else {
          localStorage.removeItem(key)
        }

        // Manually dispatch the 'storage' event to ensure all listeners are notified
        window.dispatchEvent(
          new StorageEvent('storage', {
            key: key,
            oldValue: JSON.stringify(prevValue),
            newValue: JSON.stringify(valueToStore),
          })
        )

        return valueToStore
      })
    },
    [key]
  )

  return [value, setter]
}

export const clearLocalStorage = (): void => {
  localStorage.removeItem('hideDocumentExpiration')
  localStorage.removeItem('auth')
  localStorage.removeItem('IntroducingBrokerContext')
  localStorage.removeItem('luckyDrawEligible')
  localStorage.removeItem('luckyDrawViewed')
  localStorage.removeItem('account')
  localStorage.removeItem('isBrowserOutdatedModalDisplayed')
  localStorage.removeItem('productHandled')
  localStorage.removeItem('walletId')
  localStorage.removeItem(ProductKey)
  localStorage.removeItem('hasDismissedFirstDepositPrompt')
  localStorage.removeItem('hasAdditionalId')
  localStorage.removeItem('hubSpotIdentificationToken')
  localStorage.removeItem('statusBannerOpen')
  localStorage.removeItem('hasUserAnyWallets')
  localStorage.removeItem('selectedProductIdsKey')
  localStorage.removeItem('hasCartItems')
  localStorage.removeItem('cartLink')
  localStorage.removeItem('selectedProductIdsKey')
  TokenStorage.clear()
  clearNotificationHiding()
}
