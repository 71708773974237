import { IconProps, iconColor } from './IconProps'

export function HideOffIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.2556 10.5685C20.5412 11.029 20.7766 11.5071 21 12C20.7766 12.4929 20.5412 12.971 20.2556 13.4315C19.9083 13.9915 19.3845 14.7359 18.673 15.4816C17.2478 16.9754 15.0409 18.5 12 18.5C8.95912 18.5 6.75219 16.9754 5.32697 15.4816C4.6155 14.7359 4.09169 13.9915 3.74438 13.4315C3.35766 12.808 3 12 3 12C3 12 3.45552 11.0342 3.74438 10.5685C4.09169 10.0085 4.6155 9.26411 5.32697 8.51842C6.75219 7.02462 8.95912 5.5 12 5.5C15.0409 5.5 17.2478 7.02462 18.673 8.51842C19.3845 9.26411 19.9083 10.0085 20.2556 10.5685ZM6.53928 14.0184C5.80559 13.2494 5.30391 12.475 5.03124 12C5.30391 11.525 5.80559 10.7506 6.53928 9.98158C7.73781 8.72538 9.53189 7.5 12 7.5C14.4681 7.5 16.2622 8.72538 17.4607 9.98158C18.1944 10.7506 18.6961 11.525 18.9688 12C18.6961 12.475 18.1944 13.2494 17.4607 14.0184C16.2622 15.2746 14.4681 16.5 12 16.5C9.53189 16.5 7.73781 15.2746 6.53928 14.0184ZM12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
