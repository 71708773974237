import { Context, useContext } from 'react'

export function useGuardedContext<T>(type: Context<T | undefined>): T {
  const ctx = useContext(type)

  if (ctx === undefined) {
    // eslint-disable-next-line
    throw `${type.displayName || 'Context'}Provider is missing`
  }

  return ctx
}
