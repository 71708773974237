import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Field, Form, FormikErrors, FormikProps, getIn, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../../../global/button/Button'
import { DocumentFileUploads } from '../../../global/fileUpload/DocumentFileUploads'
import { FileData } from '../../../global/fileUpload/FileUpload'
import { AccountDetailedDto } from '../../../model/AccountDetailedDto'
import { TestAnswerDto, TestAnswersDto } from '../../../model/ClientTestAnswerDto'
import { ClientTestDto } from '../../../model/ClientTestDto'
import { DocumentCategoryType } from '../../../model/DocumentCategories'

import styles from './ExperiencedClassificationTestForm.module.scss'

export interface ExperiencedClassificationTestValues {
  freeAnswers: { testQuestionId: string; answer: string }[]
  selectedAnswers: { question: string; answer: string }[]

  tradeSizeProofs: FileData[]
  knowledgeProofs: FileData[]

  classification_question_4: string
  classification_question_5: string
  classification_question_6: string

  classification_question_7: string
  classification_question_8: string
  classification_question_9: string

  classification_declaration_4: boolean
  classification_declaration_5: boolean
  classification_declaration_6: boolean
}

interface OuterProps {
  classificationName: string | undefined
  testData: ClientTestDto

  testAnswers: TestAnswersDto | undefined

  isFormEnabled: boolean

  handleFormSubmit: (values: ExperiencedClassificationTestValues) => void

  account: AccountDetailedDto | undefined
}

const ExperiencedClassificationTestFormUI: React.FC<
  FormikProps<ExperiencedClassificationTestValues> & OuterProps
> = (props) => {
  const {
    handleSubmit,
    classificationName,
    testData,
    setFieldValue,
    setFieldTouched,
    errors,
    values,
    isFormEnabled,
    isValid,
    testAnswers,
    isSubmitting,
    setSubmitting,
  } = props
  const {
    selectedAnswers,
    freeAnswers,
    classification_question_4,
    classification_question_5,
    classification_question_6,
    classification_question_7,
    classification_question_8,
    classification_question_9,
  } = values

  const { t } = useTranslation()

  const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    setSubmitting(true)
    event.preventDefault()
    handleSubmit()
  }

  const canSubmit = () => {
    return (
      hasAffirmativeAnswer(
        [classification_question_4, classification_question_5, classification_question_6],
        1
      ) &&
      hasAffirmativeAnswer(
        [classification_question_5, classification_question_6, classification_question_7],
        1
      )
    )
  }

  const hasAffirmativeAnswer = (answers: string[], positiveAnswerCount: number) => {
    return answers.filter((answer) => answer === '1').length >= positiveAnswerCount
  }
  const handleKnowledgeProofOnChange = (files: FileData[]) => {
    setFieldValue('knowledgeProofs', [...files])
  }

  const handleTradeSizeProofOnChange = (files: FileData[]) => {
    setFieldValue('tradeSizeProofs', [...files])
  }

  return (
    <Form onSubmit={handleSubmitForm}>
      <div className={styles.headerWhite}>
        <p>
          <span className={styles.textGray}>{t('Profile.Current classification status:')}</span>{' '}
          <span className={styles.textSuccess}>{classificationName}</span>
        </p>
      </div>
      <div className={styles.card}>
        <h3>{t('Profile.Application Form to be Classified as a Experienced Retail Client')}</h3>
        <p className={styles.text}>
          {t(
            'Profile.Please fill in the below form if you wish to apply to be classified as an Experienced Retail Client. You are responsible for keeping Tickmill informed about any change that could affect your current categorisation.'
          )}
        </p>
        <p className={styles.text}>
          {t(
            'Profile.To qualify as an Experienced Retail Client you have to demonstrate sufficient CFDs trading experience and appropriate knowledge of derivatives, including CFDs.'
          )}
        </p>
        <p className={styles.text}>
          {t('Profile.What will change if I am classified as an Experienced Retail Client?')}
        </p>
        <p className={styles.text}>
          {t(
            'Profile.Should you possess the necessary criteria to be classified as an Experienced Retail Client'
          )}
        </p>
      </div>
      {testData.sections.map((section, sectionIndex) => {
        if (sectionIndex === 2) {
          return (
            <div className='mt-5 mb-5' key={sectionIndex}>
              {!isFormEnabled && (
                <div className='mt-5 pl-4 mb-5'>
                  {
                    <p className={styles.text}>{`Uploaded documents: ${
                      testAnswers?.documents.map((doc) => doc.name).join(', ') || ''
                    }`}</p>
                  }
                </div>
              )}
              {section.questions.map((question, questionIndex) => {
                const fieldName = question.widget.name
                return (
                  <React.Fragment key={questionIndex}>
                    <Field
                      disabled={!isFormEnabled}
                      className='radio'
                      type='checkbox'
                      required={true}
                      name={fieldName}
                      onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                        const { checked } = e.target
                        const filteredArray = freeAnswers.filter(
                          (x) => x.testQuestionId !== question.question.id
                        )
                        const newValue = {
                          testQuestionId: question.question.id,
                          answer: checked ? '1' : '0',
                        }
                        setFieldValue('freeAnswers', [...filteredArray, newValue])
                        await setFieldValue(fieldName, checked)
                        setFieldTouched(fieldName)
                      }}
                      checked={getIn(values, fieldName)}
                    />
                    <label className={styles.questionName}>{question.question.name}</label>
                    <p className={styles.questionError}>{getIn(errors, fieldName)}</p>
                  </React.Fragment>
                )
              })}
            </div>
          )
        }
        return (
          <div className={styles.card} key={sectionIndex}>
            <p className={styles.textStrong}>{`${sectionIndex + 1}. ${section.name}`}</p>
            <div className={styles.overviewWrapper}>
              {section.questions.map((question) => {
                return (
                  <div className={classNames(styles.overview)} key={question.question.id}>
                    <p className={styles.text}>{`${question.question.name}`}</p>
                    <div className='is-flex'>
                      {question.answers.map((answer) => {
                        return (
                          <div key={answer.id} className='mr-6'>
                            <Field
                              disabled={!isFormEnabled}
                              className={classNames('radio')}
                              type='radio'
                              required={true}
                              value={answer.code}
                              name={question.widget.name}
                              onChange={async () => {
                                const filteredArray = selectedAnswers.filter(
                                  (x) => x.question !== question.question.id
                                )
                                const newValue = {
                                  question: question.question.id,
                                  answer: answer.id,
                                }
                                await setFieldValue('selectedAnswers', [...filteredArray, newValue])
                                setFieldValue(question.widget.name, answer.code)
                              }}
                            />
                            <label className={classNames(styles.textSmall, 'pl-2')}>
                              {answer.name}
                            </label>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )
              })}
            </div>
            {isFormEnabled && (
              <div
                className={classNames({
                  'is-hidden':
                    sectionIndex === 0
                      ? !hasAffirmativeAnswer(
                          [
                            classification_question_4,
                            classification_question_5,
                            classification_question_6,
                          ],
                          1
                        )
                      : !hasAffirmativeAnswer(
                          [
                            classification_question_7,
                            classification_question_8,
                            classification_question_9,
                          ],
                          1
                        ),
                })}
              >
                <div className='mt-4'>
                  <DocumentFileUploads
                    documentCategory={DocumentCategoryType.Additional}
                    onChange={
                      sectionIndex === 0
                        ? handleTradeSizeProofOnChange
                        : handleKnowledgeProofOnChange
                    }
                  />
                </div>
              </div>
            )}
          </div>
        )
      })}
      <div className={styles.buttonWrapper}>
        {isFormEnabled && (
          <Button appearance='primary' size='L' disabled={!canSubmit() || !isValid || isSubmitting}>
            {t('Confirm')}
          </Button>
        )}
      </div>
    </Form>
  )
}

export const ExperiencedClassificationTestForm = withFormik<
  OuterProps,
  ExperiencedClassificationTestValues
>({
  mapPropsToValues: ({ testAnswers }) => {
    const answers: TestAnswerDto[] =
      testAnswers?.sections.map((section) => section.answers).flat() || []
    return {
      selectedAnswers: [],
      freeAnswers: [],
      classification_question_4:
        (!testAnswers?.allowRetake &&
          answers.find((answer) => answer.question.widgetName === 'classification_question_4')
            ?.answer.code) ||
        '',
      classification_question_5:
        (!testAnswers?.allowRetake &&
          answers.find((answer) => answer.question.widgetName === 'classification_question_5')
            ?.answer.code) ||
        '',
      classification_question_6:
        (!testAnswers?.allowRetake &&
          answers.find((answer) => answer.question.widgetName === 'classification_question_6')
            ?.answer.code) ||
        '',

      classification_question_7:
        (!testAnswers?.allowRetake &&
          answers.find((answer) => answer.question.widgetName === 'classification_question_7')
            ?.answer.code) ||
        '',
      classification_question_8:
        (!testAnswers?.allowRetake &&
          answers.find((answer) => answer.question.widgetName === 'classification_question_8')
            ?.answer.code) ||
        '',
      classification_question_9:
        (!testAnswers?.allowRetake &&
          answers.find((answer) => answer.question.widgetName === 'classification_question_9')
            ?.answer.code) ||
        '',

      classification_declaration_4:
        !testAnswers?.allowRetake &&
        answers.find((answer) => answer.question.widgetName === 'classification_declaration_4')
          ?.answer.name === '1',
      classification_declaration_5:
        !testAnswers?.allowRetake &&
        answers.find((answer) => answer.question.widgetName === 'classification_declaration_5')
          ?.answer.name === '1',
      classification_declaration_6:
        !testAnswers?.allowRetake &&
        answers.find((answer) => answer.question.widgetName === 'classification_declaration_6')
          ?.answer.name === '1',

      knowledgeProofs: [],
      tradeSizeProofs: [],
    }
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    try {
      props.handleFormSubmit(values)
    } catch {
      setSubmitting(false)
    }
  },
  validate: (values) => {
    const errors: FormikErrors<ExperiencedClassificationTestValues> = {}
    if (!values.classification_declaration_4) {
      errors.classification_declaration_4 = t('Validation.Required')
    }
    if (!values.classification_declaration_5) {
      errors.classification_declaration_5 = t('Validation.Required')
    }
    if (!values.classification_declaration_6) {
      errors.classification_declaration_6 = t('Validation.Required')
    }
    if (!values.classification_question_4) {
      errors.classification_question_4 = t('Validation.Required')
    }
    if (!values.classification_question_5) {
      errors.classification_question_5 = t('Validation.Required')
    }
    if (!values.classification_question_6) {
      errors.classification_question_6 = t('Validation.Required')
    }
    if (!values.classification_question_7) {
      errors.classification_question_7 = t('Validation.Required')
    }
    if (!values.classification_question_8) {
      errors.classification_question_8 = t('Validation.Required')
    }
    if (!values.classification_question_9) {
      errors.classification_question_9 = t('Validation.Required')
    }
    if (!values.knowledgeProofs.filter((x) => x.fileName).length) {
      errors.knowledgeProofs = t('Validation.Required')
    }
    if (!values.tradeSizeProofs.filter((x) => x.fileName).length) {
      errors.tradeSizeProofs = t('Validation.Required')
    }

    return errors
  },

  enableReinitialize: true,
  isInitialValid: false,
})(ExperiencedClassificationTestFormUI)
