import { IconProps, iconColor } from './IconProps'

export function RadioButtonOffIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle className='dot' cx='12' cy='12' r='8' stroke={iconColor(props)} strokeWidth='2' />
    </svg>
  )
}
