import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { Text } from '../../ui/Typography/Typography'
import { ExternalLink } from '../ExternalLink'
import { useModalsContext } from '../context/ModalsContext'
import { InfoModal } from '../modal/InfoModal'
import { Modal } from '../modal/Modal'

import styles from './BrowserOutdateModal.module.scss'

export const BrowserOutdatedModal: React.FC = () => {
  const { closeModal } = useModalsContext()

  const { t } = useTranslation()

  return (
    <Modal
      closeModal={closeModal}
      render={() => (
        <InfoModal
          onCancel={closeModal}
          title={t('Your browser is outdated')}
          renderFooter={() => (
            <Fragment>
              <button
                className='button'
                data-test='account-settings-notification-modal-close'
                onClick={closeModal}
                type='button'
              >
                {t('Got It')}
              </button>
            </Fragment>
          )}
          renderBody={() => (
            <section className={'modal-card-body'}>
              <Text>
                {t('You seem to be using an outdated browser')} {'('}
                <ExternalLink
                  url={'https://play.google.com/store/apps/details?id=com.tickmill&pli=1'}
                  className={styles.link}
                >
                  Android
                </ExternalLink>
                {' /'}
                <ExternalLink
                  url={'https://apps.apple.com/app/tickmill/id1548644102'}
                  className={styles.link}
                >
                  iOS
                </ExternalLink>
                {')'}
              </Text>
            </section>
          )}
          onConfirm={closeModal}
        />
      )}
    />
  )
}
