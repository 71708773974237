import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TradingAccount } from '../../../model/TradingAccount'
import { WalletDto } from '../../../model/WalletDto'
import { PageData } from '../../../utils/ApiClient'
import {
  TradingAccountRestrictions,
  isRestricted as taIsRestricted,
} from '../../../utils/trading-account.utils'
import { WalletRestrictions, isRestricted } from '../../../utils/wallet.utils'
import { Balance } from './Balance'
import { WalletOrTradingAccountType } from './TransferForm'
import { RadioBoxField, isWalletType } from './TransferToModal'

import styles from './TransferFromModal.module.scss'

interface Props {
  wallets: WalletDto[]
  tradingAccounts: PageData<TradingAccount> | undefined
  transferFrom: WalletOrTradingAccountType
  setTransferFrom(transferTo: WalletOrTradingAccountType): void
  onCancel(): void
  transferTo: WalletOrTradingAccountType
}

interface BodyProps {
  wallets: WalletDto[]
  tradingAccounts: PageData<TradingAccount> | undefined
  transferFrom: WalletOrTradingAccountType
  setTransferFrom(transferTo: WalletOrTradingAccountType): void
  transferTo: WalletOrTradingAccountType
}

const TransferFromModalBody: React.FC<BodyProps> = ({
  wallets,
  tradingAccounts,
  setTransferFrom,
  transferFrom,
  transferTo,
}) => {
  return (
    <section className={classNames(styles.body, 'modal-card-body')}>
      <div className={styles.wrapper}>
        {wallets.map((wallet, i) => {
          const hasTransferFromRestriction = isRestricted(
            WalletRestrictions.TRANSFER_FROM,
            wallet.restrictions
          )
          return (
            <div className={styles.walletWrapper} key={i}>
              <div className={styles.wallet}>
                <div
                  className={classNames({
                    [styles.grayText]: hasTransferFromRestriction,
                  })}
                >
                  <RadioBoxField
                    tradingAccountOrWallet={wallet}
                    isDisabled={hasTransferFromRestriction}
                    setTransferField={setTransferFrom}
                    isWallet
                    checked={transferFrom === wallet}
                    isFromWallet={true}
                  />
                </div>
                <Balance
                  isDisabled={hasTransferFromRestriction}
                  balance={wallet.balance}
                  currencyId={wallet.currency.id}
                  isWallet={isWalletType(wallet)}
                  isTransferFrom
                />
              </div>

              {tradingAccounts?.items
                .filter((ta) => ta.currency.id === wallet.currency.id)
                .map((ta, i) => {
                  const hasTransferFromRestriction = taIsRestricted(
                    TradingAccountRestrictions.TRANSFER_FROM,
                    ta.restrictions
                  )
                  return (
                    <div
                      key={i}
                      className={classNames(styles.accountWrapper, {
                        [styles.grayText]: hasTransferFromRestriction,
                      })}
                    >
                      <div className={styles.account}>
                        <RadioBoxField
                          tradingAccountOrWallet={ta}
                          isDisabled={hasTransferFromRestriction}
                          setTransferField={setTransferFrom}
                          checked={transferFrom === ta}
                        />
                        <Balance
                          isDisabled={hasTransferFromRestriction}
                          balance={ta.platformOverview.balance}
                          currencyId={ta.currency.id}
                          freeMargin={ta.platformOverview.freeMargin}
                          isWallet={isWalletType(ta)}
                          isTransferFrom
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
          )
        })}
      </div>
    </section>
  )
}

export const TransferFromModal: React.FC<Props> = ({
  onCancel,
  wallets,
  tradingAccounts,
  setTransferFrom,
  transferFrom,
  transferTo,
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('Wallet.Transfer From')}</p>
      </header>
      <TransferFromModalBody
        wallets={wallets}
        tradingAccounts={tradingAccounts}
        setTransferFrom={setTransferFrom}
        transferFrom={transferFrom}
        transferTo={transferTo}
      />
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <button className='button' onClick={onCancel}>
          {t('Cancel')}
        </button>
      </footer>
    </React.Fragment>
  )
}
