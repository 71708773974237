import React from 'react'
import { useTranslation } from 'react-i18next'

import { TransactionSuccessDialogueTemplate } from '../../../../global/templates/TransactionSuccessDialogueTemplate'

interface WalletWithdrawTransactionCompletedPageProps {
  onCancel?(): void
}

export const WalletWithdrawTransactionCompletedPage: React.FC<
  WalletWithdrawTransactionCompletedPageProps
> = (props) => {
  const { onCancel } = props
  const { t } = useTranslation()

  return (
    <TransactionSuccessDialogueTemplate title={t('Wallet.Operation Completed')} goBack={onCancel} />
  )
}
