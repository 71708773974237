import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { SelectModal, SelectModalOption } from '../../../global/field/SelectModal'
import { Modal } from '../../../global/modal/Modal'
import { WalletCurrency } from '../../../model/WalletDto'
import { CreateDemoTradingAccountFormValues } from './CreateDemoTradingAccountForm'

interface Props {
  onSelectOption(currency: WalletCurrency): void
  onClose(): void

  currencies: WalletCurrency[]
}

export const CurrencyTypeModal: React.FC<Props> = ({ onSelectOption, onClose, currencies }) => {
  const { t } = useTranslation()
  const { values } = useFormikContext<CreateDemoTradingAccountFormValues>()
  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Trading Account.Account Currency')}
          renderOptions={() => (
            <div className='control'>
              {currencies.map(({ name, id }) => (
                <SelectModalOption
                  label={id}
                  value={id === values.accountCurrency?.id}
                  onClick={() => onSelectOption({ id, name })}
                  key={id}
                />
              ))}
            </div>
          )}
        />
      )}
    />
  )
}
