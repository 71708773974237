import { IconProps, iconColor } from './IconProps'

export function BackIcon(props: IconProps) {
  const size = props.size || 16

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 7H3.8L8.7 2.1C9.0866 1.7134 9.0866 1.0866 8.7 0.7C8.3134 0.313401 7.6866 0.313401 7.3 0.7L0 8L7.3 15.3C7.6866 15.6866 8.3134 15.6866 8.7 15.3C9.0866 14.9134 9.0866 14.2866 8.7 13.9L3.8 9H16V7Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
