import React from 'react'

import { InfoIcon } from '../../../icons/InfoIcon'
import { TooltipWrapper } from './TooltipWrapper'

interface TooltipProps {
  title: string
}

export const TooltipIcon: React.FC<TooltipProps> = (props) => {
  const { title } = props

  return (
    <TooltipWrapper title={title}>
      <InfoIcon />
    </TooltipWrapper>
  )
}
