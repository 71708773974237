import { NameDto } from './NameDto'

export type PhoneNumberType = 'mobile' | 'landline' | 'Voip'

export interface PhoneNumberDto {
  numberType: PhoneNumberType
  nationalFormat: string
  phoneNumber: string
  country: NameDto<string>
  phoneVerificationRequired: boolean
}

export enum PhoneNumberTypeEnum {
  LANDLINE = 1,
  MOBILE = 2,
}
