import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Tabs } from '../global/tabs/Tabs'
import { useCurrentTab } from '../hooks/useCurrentTab'
import { isNoTabsPath } from '../utils/path'

import styles from './PaymentAgent.module.scss'

export const PaymentAgent: React.FC = () => {
  const currentTab = useCurrentTab('payment-agent')
  const navigate = useNavigate()
  const location = useLocation()
  const { t } = useTranslation()
  const TabsDefinition = [
    {
      name: t('Tabs.Wallets'),
      id: 'wallets',
      disabled: false,
    },
    {
      name: t('Tabs.Transaction History'),
      id: 'transaction-history',
      disabled: false,
    },
    { name: t('Tabs.Profile Info'), id: 'profile-info', disabled: false },
  ]

  return (
    <React.Fragment>
      {!isNoTabsPath(location.pathname) && (
        <div className={classNames('section', styles.adminRoutes)}>
          <Tabs
            tabs={TabsDefinition}
            currentTab={currentTab}
            appearance='light'
            onTabChanged={(tab) => navigate(`/dashboard/payment-agent/${tab}`)}
          />
        </div>
      )}
      <Outlet />
    </React.Fragment>
  )
}
