import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

import '../../layout/site.scss'
import { AccountInfoLimitsContextProvider } from '../../global/context/AccountInfoContext/AccountInfoContext.Provider'
import { EntitySettingsContextProvider, restoreSession } from '../../global/context/EntityContext'
import { LegalLinksProvider } from '../../global/context/LegalLinksContext'
import { LossPercentagesContextProvider } from '../../global/context/LossPercentagesContext'
import { ModalsProvider } from '../../global/context/ModalsContext'
import { ProductContextProvider, useProductWriteContext } from '../../global/context/ProductContext'
import { PublicDomainsContextProvider } from '../../global/context/PublicDomainsContext'
import { SessionSettingsContextProvider } from '../../global/context/SessionSettingsContext'
import { SignupContextProvider } from '../../global/context/SignupContext'
import { SnackbarContextProvider } from '../../global/context/SnackbarContext'
import { AllLocales, Locale, detectLocaleClientSide } from '../../global/locale/Locale'
import { detectThemeClientSide } from '../../global/theme/Theme'
import { Toast, ToastContext, isToastTypeDanger } from '../../global/toast/Toast'
import { useExternalScripts } from '../../hooks/useExternalScripts'
import { useLogout } from '../../hooks/useLogout'
import i18n from '../../i18n'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { EntityConfigurationDto } from '../../model/EntityConfigurationDto'
import { IntroducingBrokerDetailsDto } from '../../model/IntroducingBrokerDetailsDto'
import { NotificationType } from '../../model/Notification'
import { AuthUser } from '../../model/User'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { ApiClientContext, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext, MaybeAuthSession, initiateAuthSession } from '../../utils/AuthContext'
import { FirstDepositContextProvider } from '../../utils/FirstDepositContext'
import { FirstTimeGuideContextProvider } from '../../utils/FirstTimeGuideContext'
import {
  useIBCampaignsResultsReadContext,
  useIBCampaignsResultsWriteContext,
} from '../../utils/IBCampaignsResultsContext'
import { IntroducingBrokerContextProvider } from '../../utils/IntroducingBrokerContext'
import { useMaintenance } from '../../utils/MaintenanceModeContext'
import { usePathHistoryContext } from '../../utils/PathHistoryContext'
import { SupportContactsProvider } from '../../utils/SupportContactsContext'
import { TradingAccountEntityConfigurationProvider } from '../../utils/TradingAccountEntityConfigurationContext'
import { ClientApiClient } from '../../utils/clientApi'
import {
  entityPerTickmillCompany,
  getRedirectDomain,
  getTickmillCompanyByHostname,
} from '../../utils/companyName.utils'
import { dispatchEntitySelected } from '../../utils/cookie.utils'
import { useHubSpot } from '../../utils/hubSpot'
import { isLoginPage as checkIfLoginPage } from '../../utils/isLoginPage'
import { useHideNotifications } from '../../utils/notifications'
import { isSignUpPath } from '../../utils/path'
import { sleep } from '../../utils/transaction.utils'
import { useScrollbarWidth } from '../../utils/useScrollbarWidth'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { MainContainer } from '../MainContainer'
import { useErrorHandler } from './ErrorHandler'

interface RoutingProps {
  toast?: Toast
  auth: MaybeAuthSession
  account?: AccountDetailedDto
  urlLocale: Locale
  setCurrentPath: (path: string) => void
}

const Routing = (props: RoutingProps) => {
  const { toast, auth, account, urlLocale, setCurrentPath } = props

  const apiClient = useContext(ApiClientContext)
  const clientApiClient = useApiClient(ClientApiClient)
  const { setMaintenance } = useMaintenance()

  const { changeProductContext } = useProductWriteContext()
  const { errorHandler } = useErrorHandler()
  const location = useLocation()
  const { logout } = useLogout()
  const isLoginPage = checkIfLoginPage(location.pathname)
  const [productHandled, setProductHandled] = useLocallyPersistedState<boolean>(
    'productHandled',
    false
  )
  const removeSession = (a: AuthUser) => {
    logout()
    window.location.replace('https://' + getRedirectDomain(a.tickmillCompany.id))
  }

  useEffect(() => {
    setTimeout(() => {
      if (!auth) {
        return
      }
      if (isLoginPage) {
        return
      }
      if (window.env.APP_ENTITY.includes(entityPerTickmillCompany[auth.tickmillCompany.id])) {
        return
      }
      if (process.env.NODE_ENV === 'development') {
        return
      }

      console.debug('Wrong Entity. Logout!')
      clientApiClient.logOut(auth.id).finally(() => removeSession(auth))
    }, 3000)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, isLoginPage])

  useEffect(() => {
    if (!apiClient) {
      return
    }
    apiClient.updateMaintenanceMode = () => setMaintenance()

    apiClient.errorHandler = errorHandler
  }, [apiClient, errorHandler, setMaintenance])

  // useExternalScripts('//js.hs-scripts.com/5514769.js', 'hs-script-loader')
  // useHubSpot()

  useEffect(() => {
    const productId = account?.preferredLandingPage?.tickmillProductId
    if (!productHandled && productId) {
      changeProductContext(productId)
      setProductHandled(true)
    }
    return
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  return (
    <MainContainer
      urlLocale={urlLocale}
      toast={toast}
      auth={auth}
      account={account}
      setCurrentPath={setCurrentPath}
    />
  )
}

export const Session: React.FC = () => {
  useScrollbarWidth()

  const location = useLocation()
  const { pathHistory, setPathHistory } = usePathHistoryContext()

  const [applyTheme, setApplyTheme] = useState(false)
  const [currentPath, setCurrentPath] = useState(location.pathname)
  const [auth, setAuth] = useState<MaybeAuthSession>(initiateAuthSession(location))
  const [entityConfiguration, setEntityConfiguration] = useState<EntityConfigurationDto | null>(
    null
  )
  const [introducingBroker, setIntroducingBroker] = useState<IntroducingBrokerDetailsDto | null>(
    null
  )
  const clientApiClient = useApiClient(ClientApiClient)
  const urlParams = new URLSearchParams(location.search)
  const preferredLanguage = urlParams.get('lang')
  const { refreshAccount } = useAccountWriteContext()
  const { refreshIBCampaigns } = useIBCampaignsResultsWriteContext()
  const { ibCampaignsResults } = useIBCampaignsResultsReadContext()
  const { account } = useAccountReadContext()
  const { toast, setToast } = useToast()

  const rootDiv = document.getElementById('root')
  if (rootDiv) {
    rootDiv.setAttribute('dir', i18n.dir())
  }

  // this should be moved to the context above to avoid this way of getting language
  const locale =
    (preferredLanguage
      ? AllLocales.includes(preferredLanguage as Locale)
        ? preferredLanguage
        : null
      : null) ||
    detectLocaleClientSide() ||
    ('en' as Locale)
  //TODO- Move getAccountAppTest in order to use locale from context

  useEffect(() => {
    if (urlParams.get('phrase')) {
      i18n.changeLanguage('cimode')
    } else {
      i18n.changeLanguage(locale)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchClientEntityConfiguration = async () => {
    try {
      const entityConfig = await clientApiClient.getClientEntityConfiguration()
      setEntityConfiguration(entityConfig)
    } catch (e: unknown) {
      console.error('error from entityConfiguration endpoint')
    }
  }

  const setLiveChatEntity = async (auth: MaybeAuthSession) => {
    if (auth) {
      // it's added due to App.tsx is rendered before and event listener is not added yet
      await sleep(2000)
      dispatchEntitySelected(
        auth?.tickmillCompany.id || getTickmillCompanyByHostname(),
        locale as Locale,
        auth.email,
        auth.firstName
      )
    }
  }

  useEffect(() => {
    if (!auth || !!entityConfiguration) {
      return
    } else {
      fetchClientEntityConfiguration()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!auth])

  useEffect(() => {
    if (auth) {
      refreshAccount(locale as Locale)
      // setLiveChatEntity(auth).then(() => {})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth])

  useEffect(() => {
    if (account && account.clientIntroducingBroker?.id && auth) {
      clientApiClient
        .getIntroducingBrokerById(locale, account.clientIntroducingBroker.id)
        .then((introducingBrokerDetails) => setIntroducingBroker(introducingBrokerDetails))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, auth, locale])

  useEffect(() => {
    if (!ibCampaignsResults.length && account?.clientIntroducingBroker?.id) {
      clientApiClient.getIBCampaignsResults().then((campaigns) => {
        refreshIBCampaigns(campaigns.items)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ibCampaignsResults.length, account?.clientIntroducingBroker?.id])

  useEffect(() => {
    setApplyTheme(!isSignUpPath(currentPath))
  }, [currentPath])

  const getUrlLocale = useMemo(() => {
    const urlLocale = window.location.pathname.substring(1, 3) as Locale
    if (AllLocales.includes(urlLocale)) {
      return urlLocale as Locale
    } else {
      return 'en' as Locale
    }
  }, [])

  const trackLastPathsVisited = () => {
    if (pathHistory.length === 0 || pathHistory[pathHistory.length - 1] !== location.pathname) {
      const newPathHistory = [...pathHistory, location.pathname].slice(-2)
      setPathHistory(newPathHistory)
    }
  }

  useEffect(() => {
    trackLastPathsVisited()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return (
    <SessionSettingsContextProvider
      initialSettings={{
        locale: locale as Locale,
        theme: detectThemeClientSide(),
      }}
      applyTheme={applyTheme}
    >
      <EntitySettingsContextProvider initialSettings={restoreSession(auth)}>
        <PublicDomainsContextProvider>
          <SupportContactsProvider>
            <ToastContext.Provider value={setToast}>
              <AuthSessionContext.Provider value={[auth, setAuth]}>
                <ProductContextProvider>
                  <TradingAccountEntityConfigurationProvider configuration={entityConfiguration}>
                    <IntroducingBrokerContextProvider introducingBroker={introducingBroker}>
                      <FirstTimeGuideContextProvider>
                        <LossPercentagesContextProvider>
                          <FirstDepositContextProvider auth={auth}>
                            <LegalLinksProvider>
                              <ModalsProvider>
                                <AccountInfoLimitsContextProvider>
                                  <SnackbarContextProvider>
                                    <SignupContextProvider>
                                      <Routing
                                        setCurrentPath={setCurrentPath}
                                        urlLocale={getUrlLocale}
                                        toast={toast}
                                        auth={auth}
                                        account={account}
                                      />
                                    </SignupContextProvider>
                                  </SnackbarContextProvider>
                                </AccountInfoLimitsContextProvider>
                              </ModalsProvider>
                            </LegalLinksProvider>
                          </FirstDepositContextProvider>
                        </LossPercentagesContextProvider>
                      </FirstTimeGuideContextProvider>
                    </IntroducingBrokerContextProvider>
                  </TradingAccountEntityConfigurationProvider>
                </ProductContextProvider>
              </AuthSessionContext.Provider>
            </ToastContext.Provider>
          </SupportContactsProvider>
        </PublicDomainsContextProvider>
      </EntitySettingsContextProvider>
    </SessionSettingsContextProvider>
  )
}

const useToast = () => {
  const location = useLocation()
  const ToastErrorTypeVisibilityInSeconds = 15
  const ToastOtherTypeVisibilityInSeconds = 5

  const [toast, setToast] = useState<Toast>()
  const { hideNotifications } = useHideNotifications()

  useEffect(() => {
    if (toast && !toast.requireInteraction) {
      // clear toast after 'n' seconds
      hideNotifications([NotificationType.Promotional, NotificationType.General])

      const timeOutSeconds = isToastTypeDanger(toast.type)
        ? ToastErrorTypeVisibilityInSeconds * 1000
        : ToastOtherTypeVisibilityInSeconds * 1000

      const timeout = setTimeout(() => setToast(undefined), timeOutSeconds)
      return () => clearTimeout(timeout)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toast, setToast])

  useEffect(() => {
    if (toast && isToastTypeDanger(toast.type)) {
      setToast(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  return { toast, setToast }
}
