import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { useProductReadContext } from '../../../../global/context/ProductContext'
import { MasterTransaction } from '../../../../model/MasterTransaction'
import { WalletDepositTransactionSuccessPage } from './WalletDepositTransactionSuccessPage'

export interface WalletDepositFormTransactionSuccessState {
  transaction: MasterTransaction
  providerCategoryId: number
  navigateToTransactionHistory?: boolean
}

export const TradersRoomWalletDepositTransactionSuccessPage: React.FC = (props) => {
  const navigate = useNavigate()
  const { isDefaultCFDProductType } = useProductReadContext()
  const location = useLocation()

  const [values] = useState<WalletDepositFormTransactionSuccessState>(location.state)

  const getNavigationPath = () => {
    if (isDefaultCFDProductType()) {
      return values?.navigateToTransactionHistory
        ? '/dashboard/traders-room/transaction-history'
        : '/dashboard/traders-room/wallets'
    }
    return '/dashboard/traders-room/balances'
  }

  const handleCancel = () => {
    navigate(getNavigationPath())
  }

  return (
    <div id='tr-wallet-deposit-succeed'>
      <WalletDepositTransactionSuccessPage {...props} onCancel={handleCancel} />
    </div>
  )
}
