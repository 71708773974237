export enum UploadDocumentTypeEnum {
  None = 0,
  Driving_License = 1,
  National_Identity_Card = 2,
  Other = 3,
  Passport = 4,
  Residence_Permit = 5,
  Address_Certificate = 6,
  Not_Available = 10,
  Bank_Statement = 11,
  Utility_Bill = 12,
  Certificate_of_Incorporation = 13,
  Certificate_of_Shareholders = 14,
  Certificate_of_Members_Directors = 15,
  Memorandum_and_Articles_of_Association = 16,
  Registration_Card = 17,
  Auditable_Accounts = 18,
  Board_Resolution = 19,
  Company_proof_of_Address = 20,
  World_Check = 21,
  Credit_Debit_Card = 22,
  Trading_Statement = 23,
  Client_Classification = 30,
  Proof_of_Payment = 31,
  Voter_Card = 32,
  Election_Card = 33,
  Birth_Certificate = 34,
  Military_Card = 35,
  PAN_Card = 36,
  NHIS_Card = 37,
  Affidavit = 38,
  Rental_Agreement = 39,
  Trulioo = 40,
  Translation = 41,
  Crypto_Wallet_Transaction_Statement = 42,
  Credit_Card_Statement = 43,
  NIN_Slip = 44,
  Daily_Statement = 45,
  Monthly_Statement = 46,
  Tax_Form_W8BENE_W8BEN = 47,
  Proof_of_Deposit = 48,
  GOODS_SERVICES_TAX_CERTIFICATE = 50,
  Source_of_Funds = 49,
  Proof_Of_Address = 1006,
}

export const isDrivingLicenseDocumentType = (value: UploadDocumentTypeEnum | undefined) => {
  return value && UploadDocumentTypeEnum.Driving_License === Number.parseInt(value.toString())
}

export const isNationalIdentityCardDocumentType = (value: UploadDocumentTypeEnum | undefined) => {
  return (
    value && UploadDocumentTypeEnum.National_Identity_Card === Number.parseInt(value.toString())
  )
}

export const isOtherDocumentType = (value: UploadDocumentTypeEnum | undefined) => {
  return value && UploadDocumentTypeEnum.Other === Number.parseInt(value.toString())
}

export const isPassportDocumentType = (value: UploadDocumentTypeEnum | undefined) => {
  return value && UploadDocumentTypeEnum.Passport === Number.parseInt(value.toString())
}

export const isResidencePermitDocumentType = (value: UploadDocumentTypeEnum | undefined) => {
  return value && UploadDocumentTypeEnum.Residence_Permit === Number.parseInt(value.toString())
}

export const isClientClassificationDocumentType = (value: UploadDocumentTypeEnum | undefined) => {
  return value && UploadDocumentTypeEnum.Client_Classification === Number.parseInt(value.toString())
}

export const isCryptoWalletTransactionStatementDocumentType = (
  value: UploadDocumentTypeEnum | undefined
) => {
  return (
    value &&
    UploadDocumentTypeEnum.Crypto_Wallet_Transaction_Statement === Number.parseInt(value.toString())
  )
}

export const isTaxFormDocumentType = (value: UploadDocumentTypeEnum | undefined) => {
  return value && UploadDocumentTypeEnum.Tax_Form_W8BENE_W8BEN === Number.parseInt(value.toString())
}

export const isProofOfAddressDocumentType = (value: UploadDocumentTypeEnum | undefined) => {
  return value && UploadDocumentTypeEnum.Proof_Of_Address === Number.parseInt(value.toString())
}
