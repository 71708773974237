import { IconProps, iconColor, strokeIconClassName } from './IconProps'

export function ExternalLinkIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={strokeIconClassName(props)}
    >
      <path
        d='M13.25 9.875V14C13.25 14.5523 12.8023 15 12.25 15H2C1.44772 15 1 14.5523 1 14V3.75C1 3.19772 1.44772 2.75 2 2.75H6.125M7.125 8.875L15 1M15 1V5.8125M15 1H10.625'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
