import React, { Fragment, PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { useRedirectToSupport } from '../../utils/useRedirectToSupport'

import styles from './ConfirmationModal.module.scss'

interface Props {
  title?: string

  onConfirm?(): void
  onCancel(): void
  renderFooter?: ({
    onConfirm,
    onCancel,
  }: {
    onConfirm?(): void
    onCancel?(): void
  }) => React.ReactNode
}

interface ConfirmationModalBodyProps {}

const ConfirmationModalBody: React.FunctionComponent<
  PropsWithChildren<ConfirmationModalBodyProps>
> = ({ children }) => (
  <section className={classNames('modal-card-body', styles.body)}>{children}</section>
)

export const ConfirmationModal: React.FunctionComponent<PropsWithChildren<Props>> = ({
  onCancel,
  onConfirm,
  children,
  renderFooter,
  title,
}) => {
  const { t } = useTranslation()
  const { supportUrl } = useRedirectToSupport()
  return (
    <Fragment>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{title}</p>
      </header>
      <ConfirmationModalBody>{children}</ConfirmationModalBody>
      <footer className={classNames('modal-card-foot', styles.footer)}>
        {!renderFooter ? (
          <Fragment>
            <Link to={supportUrl} target='_blank' rel='noreferrer'>
              <button
                className='button'
                onClick={() => {
                  onCancel()
                }}
                type='button'
              >
                {t('Contact Support')}
              </button>
            </Link>
            <button className='button' onClick={onCancel} type='button'>
              {t('Back')}
            </button>
            <button className='button' onClick={onConfirm} type='button'>
              {t('Proceed')}
            </button>
          </Fragment>
        ) : (
          renderFooter({ onConfirm, onCancel })
        )}
      </footer>
    </Fragment>
  )
}
