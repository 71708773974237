import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { BackButton } from '../global/backButton/BackButton'
import { Support } from './SupportPage'

import styles from './ContactUsPage.module.scss'

export const ContactUsPage = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={classNames(styles.backButton)}>
        <BackButton section={t('Support.Support')} className='is-uppercase' />
      </div>
      <Support />
    </>
  )
}
