import React from 'react'
import classNames from 'classnames'

import { TMEntityLogoFactory } from '../../../icons/TickmillLogoIcons'
import { CookieConsent } from '../../CookieConsent/CookieConsent'
import { useSessionEntity } from '../../context/EntityContext'
import { LanguageVariants } from '../../localeSelector/LanguageVariants'
import { LocaleSelector } from '../../localeSelector/LocaleSelector'
import { NavBarMenu } from '../NavBarMenu'

import styles from './DesktopNav.module.scss'

export const DesktopLoginNav: React.FC = () => {
  const entity = useSessionEntity()

  return (
    <div className={styles.navWrapper}>
      <CookieConsent />
      <nav className={styles.nav} role='navigation' aria-label='main navigation'>
        <div className={classNames('container', styles.navInner)}>
          <div className={styles.navLogo}>
            <TMEntityLogoFactory variant={entity} />
          </div>
          <div>
            <div className={styles.navMenu}>
              <NavBarMenu />
              <LocaleSelector />
            </div>
            <LanguageVariants />
          </div>
        </div>
      </nav>
    </div>
  )
}
