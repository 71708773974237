import { IconProps, iconColor } from './IconProps'

export function LandingPageIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 5H20C20.5523 5 21 5.44772 21 6V15C21 15.5523 20.5523 16 20 16H12M8 5L3.99997 5.00012C3.4477 5.00013 3 5.44784 3 6.00012V15C3 15.5523 3.44772 16 4 16H12M12 16V20.5M12 20.5H7M12 20.5H17'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
      <path
        d='M12 11.125L9.75 8.875M12 11.125L14.25 8.875M12 11.125L11.9998 3'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
