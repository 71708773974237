import { MasterTransactionDto, isTransactionRedirectStaticType } from './MasterTransactionDto'

export enum PaymentProviderType {
  Bank = 1,
  CardProvider = 2,
  PSP = 3,
  PaymentAgent = 4,
  None = 0,
}

export const isPaymentProviderBankType = (
  walletDepositCategoryType: number | undefined,
  responseData?: MasterTransactionDto | undefined
): boolean => {
  return (
    PaymentProviderType.Bank === walletDepositCategoryType ||
    (PaymentProviderType.PSP === walletDepositCategoryType &&
      isTransactionRedirectStaticType(responseData))
  )
}

export const isPaymentProviderCardProviderType = (
  walletDepositCategoryType: number | undefined
): boolean => {
  return PaymentProviderType.CardProvider === walletDepositCategoryType
}

export const isPaymentProviderPSPType = (
  walletDepositCategoryType: number | undefined
): boolean => {
  return PaymentProviderType.PSP === walletDepositCategoryType
}

export const isPaymentProviderPaymentAgentType = (
  walletDepositCategoryType: number | undefined
): boolean => {
  return PaymentProviderType.PaymentAgent === walletDepositCategoryType
}

export const isPaymentProviderNoneType = (
  walletDepositCategoryType: number | undefined
): boolean => {
  return PaymentProviderType.None === walletDepositCategoryType
}
