import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'
import { t } from 'i18next'

import { Button } from '../../global/button/Button'
import { createFormCheckBoxField } from '../../global/formField/FormCheckBoxField'
import { createFormField } from '../../global/formField/FormField'
import { RawSelectFormField, createFormSelectField } from '../../global/formField/FormSelectField'
import { CalendarIcon } from '../../icons/CalendarIcon'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { TestAnswersDto } from '../../model/ClientTestAnswerDto'
import { ClientTestDto } from '../../model/ClientTestDto'
import { RegulatorOptionDto } from '../../model/RegulatorOptionDto'
import { FreeTextAnswer } from '../../model/TestSectionsDto'
import { Text, TextH3, TextLargeStrong } from '../../ui/Typography/Typography'
import { AuthSessionContext } from '../../utils/AuthContext'
import { formatDate } from '../../utils/date.utils'
import { inMemorySort } from '../../utils/useSortBy'

import styles from './W8BeneficialForm.module.scss'

export interface W8BeneficialFormValues {
  freeAnswers: FreeTextAnswer[]
  selectedAnswers: { question: string; answer: string }[]
  name_of_individual: string
  country_of_citizenship: string
  residence_country: string
  residence_county: string
  residence_city: string
  residence_street: string
  residence_postal_code: string
  mailing_same_as_residence: boolean
  mailing_country: string
  mailing_city: string
  mailing_county: string
  mailing_street: string
  mailing_postal_code: string
  us_tin: string
  us_tin_type: string
  tax_country_id: string
  tax_country_tin: string
  birth_country_id: string
  citytown_of_birth: string
  birthdate: string
  certify_country_id: string
  certify_no_us_tax_benefits: boolean
  name_of_signer: string
  certification_date: string
  certification_signature: string
  certify_capacity_to_sign: boolean
}

interface OuterProps {
  regulators: RegulatorOptionDto[]
  taxFormQuestions: ClientTestDto
  taxFormAnswers: TestAnswersDto | undefined
  account: AccountDetailedDto
  handelFormSubmit: (values: W8BeneficialFormValues) => void
  isFormEnabled: boolean
}

const FormCheckBoxField = createFormCheckBoxField()
const FormTextField = createFormField()
const FormSelectField = createFormSelectField()

const W8BeneficialFormUI: React.FC<FormikProps<W8BeneficialFormValues> & OuterProps> = (props) => {
  const {
    regulators,
    values,
    taxFormQuestions,
    account,
    setValues,
    isValid,
    handleSubmit,
    setSubmitting,
    isFormEnabled,
    isSubmitting,
    errors,
  } = props
  const { birthdate, certification_date, mailing_same_as_residence, freeAnswers, selectedAnswers } =
    values
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name

  const questions = taxFormQuestions.sections.map((section) => section.questions).flat()

  const getQuestion = (widgetName: string) => {
    return questions.find((question) => question.widget.name === widgetName)
  }

  // TODO REFACTOR
  const individualName = getQuestion('name_of_individual')
  const citizenshipCountry = getQuestion('country_of_citizenship')
  const residenceCountry = getQuestion('residence_country')
  const residenceCity = getQuestion('residence_city')
  const residenceCounty = getQuestion('residence_county')
  const residenceStreet = getQuestion('residence_street')
  const residencePostalCode = getQuestion('residence_postal_code')
  const mailingSameAsResidence = getQuestion('mailing_same_as_residence')
  const mailingCountry = getQuestion('mailing_country')
  const mailingCity = getQuestion('mailing_city')
  const mailingCounty = getQuestion('mailing_county')
  const mailingStreet = getQuestion('mailing_street')
  const mailingPostalCode = getQuestion('mailing_postal_code')
  const usTin = getQuestion('us_tin')
  const usTinType = getQuestion('us_tin_type')
  const taxCountryId = getQuestion('tax_country_id')
  const taxCountryTin = getQuestion('tax_country_tin')
  const birthCountryId = getQuestion('birth_country_id')
  const birthCity = getQuestion('citytown_of_birth')
  const birthday = getQuestion('birthdate')

  const handleSubmitForm = (event: React.FormEvent) => {
    setSubmitting(true)
    event.preventDefault()
    handleSubmit()
  }

  useEffect(() => {
    const address = account.addresses.find((c) => c.isPrimary)
    if (!regulators.find((x) => x.country.id === address?.country.id) && address?.country) {
      regulators.concat({ country: address.country, regulators: [] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { t } = useTranslation()

  return (
    <Form className={styles.form} onSubmit={handleSubmitForm}>
      <div className={styles.card}>
        <TextH3>{t('W8.Identification of Beneficial Owner')}</TextH3>

        {taxFormQuestions.sections.map((section, sectionIndex) => {
          if (section.code === 'identification_of_beneficial_owner') {
            return (
              <React.Fragment key={sectionIndex}>
                <div className={styles.row}>
                  {individualName && (
                    <div className={styles.input}>
                      <FormTextField
                        type='text'
                        name={individualName.widget.name}
                        required
                        placeholder={individualName.question.name}
                        label={individualName.question.name}
                        disabled
                      />
                    </div>
                  )}

                  {citizenshipCountry && (
                    <div className={styles.input}>
                      <RawSelectFormField
                        disabled={!isFormEnabled}
                        name={citizenshipCountry.question.widgetName}
                        label={citizenshipCountry.question.name}
                        placeholder={citizenshipCountry.question.name}
                        required
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const { value } = event.target
                          setValues({
                            ...values,
                            [citizenshipCountry.widget.name]: value,
                            freeAnswers: [
                              ...values.freeAnswers.filter(
                                (fa) => fa.testQuestionId !== citizenshipCountry.question.id
                              ),
                              {
                                testQuestionId: citizenshipCountry.question.id,
                                answer: value,
                              },
                            ],
                          })
                        }}
                      >
                        <option value='' className='is-hidden' />
                        {account.nationalities.map((nationality) => (
                          <option value={nationality.id} onChange={() => {}} key={nationality.id}>
                            {nationality.name}
                          </option>
                        ))}
                      </RawSelectFormField>
                    </div>
                  )}
                </div>
                <TextLargeStrong isParagraph className={styles.labelBox}>
                  {t('W8.Permanent Residence Address')}
                </TextLargeStrong>
                <div className={styles.row}>
                  {residenceCountry && (
                    <div className={styles.input}>
                      <FormSelectField
                        name={residenceCountry.widget.name}
                        placeholder={residenceCountry.question.name}
                        label={residenceCountry.question.name}
                        required
                        disabled
                      >
                        <option value='' className='is-hidden' />
                        {inMemorySort(regulators, 'country.name').map((regulator) => (
                          <option value={regulator.country.id} key={regulator.country.id}>
                            {regulator.country.name}
                          </option>
                        ))}
                      </FormSelectField>
                    </div>
                  )}
                </div>

                <div className={styles.row}>
                  {residenceCity && (
                    <div className={styles.input}>
                      <FormTextField
                        type='text'
                        name={residenceCity.widget.name}
                        label={residenceCity.question.name}
                        placeholder={residenceCity.question.name}
                        required
                        disabled
                      />
                    </div>
                  )}
                  {residenceCounty && (
                    <div className={styles.input}>
                      <FormTextField
                        type='text'
                        name={residenceCounty.widget.name}
                        label={residenceCounty.question.name}
                        placeholder={residenceCounty.question.name}
                        required
                        disabled
                      />
                    </div>
                  )}
                </div>
                <div className={styles.row}>
                  {residenceStreet && (
                    <div className={styles.input}>
                      <FormTextField
                        type='text'
                        name={residenceStreet.widget.name}
                        label={residenceStreet.question.name}
                        placeholder={residenceStreet.question.name}
                        required
                        disabled
                      />
                    </div>
                  )}

                  {residencePostalCode && (
                    <div className={styles.input}>
                      <FormTextField
                        type='text'
                        name={residencePostalCode.widget.name}
                        label={residencePostalCode.question.name}
                        placeholder={residencePostalCode.question.name}
                        required
                        disabled
                      />
                    </div>
                  )}
                </div>

                <TextLargeStrong isParagraph className={styles.labelBox}>
                  {t('W8.Mailing Address')}
                </TextLargeStrong>
                {mailingSameAsResidence && (
                  <FormCheckBoxField
                    disabled={!isFormEnabled}
                    name={mailingSameAsResidence.widget.name}
                    onChange={(event) => {
                      const { checked } = event.target

                      if (checked) {
                        setValues({
                          ...values,
                          [mailingSameAsResidence.widget.name]: checked,
                          freeAnswers: [
                            ...values.freeAnswers.filter(
                              (x) =>
                                x.testQuestionId !== mailingCountry?.question.id &&
                                x.testQuestionId !== mailingCounty?.question.id &&
                                x.testQuestionId !== mailingCity?.question.id &&
                                x.testQuestionId !== mailingStreet?.question.id &&
                                x.testQuestionId !== mailingPostalCode?.question.id &&
                                x.testQuestionId !== mailingSameAsResidence.question.id
                            ),
                            {
                              testQuestionId: mailingCountry?.question.id || '',
                              answer: values.residence_country,
                            },
                            {
                              testQuestionId: mailingCounty?.question.id || '',
                              answer: values.residence_county,
                            },
                            {
                              testQuestionId: mailingCity?.question.id || '',
                              answer: values.residence_city,
                            },
                            {
                              testQuestionId: mailingStreet?.question.id || '',
                              answer: values.residence_street,
                            },
                            {
                              testQuestionId: mailingPostalCode?.question.id || '',
                              answer: values.residence_postal_code,
                            },
                            {
                              testQuestionId: mailingSameAsResidence.question.id,
                              answer: checked ? '1' : '0',
                            },
                          ],
                        })
                      } else {
                        setValues({
                          ...values,
                          [mailingSameAsResidence.widget.name]: checked,
                          freeAnswers: [
                            ...values.freeAnswers.filter(
                              (x) => x.testQuestionId !== mailingSameAsResidence.question.id
                            ),
                            {
                              testQuestionId: mailingSameAsResidence.question.id,
                              answer: checked ? '1' : '0',
                            },
                          ],
                          [mailingCountry?.widget.name || '']: '',
                          [mailingCounty?.widget.name || '']: '',
                          [mailingCity?.widget.name || '']: '',
                          [mailingStreet?.widget.name || '']: '',
                          [mailingPostalCode?.widget.name || '']: '',
                        })
                      }
                    }}
                  >
                    <Text isParagraph>{t('W8.Same as Permanent Residence Address')}</Text>
                  </FormCheckBoxField>
                )}

                <div
                  className={classNames(styles.input, {
                    'is-hidden': mailing_same_as_residence,
                  })}
                >
                  <div className={styles.row}>
                    {mailingCountry && (
                      <div className={styles.input}>
                        <RawSelectFormField
                          name={mailingCountry.widget.name}
                          placeholder={mailingCountry.question.name}
                          label={mailingCountry.question.name}
                          disabled={values.mailing_same_as_residence || !isFormEnabled}
                          onChange={(event: any) => {
                            const { value } = event.target
                            setValues({
                              ...values,
                              [mailingCountry.widget.name]: value,
                              freeAnswers: [
                                ...values.freeAnswers.filter(
                                  (fa) => fa.testQuestionId !== mailingCountry.question.id
                                ),
                                {
                                  testQuestionId: mailingCountry.question.id,
                                  answer: value,
                                },
                              ],
                            })
                          }}
                        >
                          <option value='' className='is-hidden' />
                          {inMemorySort(regulators, 'country.name').map((regulator) => (
                            <option value={regulator.country.id} key={regulator.country.id}>
                              {regulator.country.name}
                            </option>
                          ))}
                        </RawSelectFormField>
                      </div>
                    )}
                  </div>

                  <div className={styles.row}>
                    {mailingCity && (
                      <div className={styles.input}>
                        <FormTextField
                          type='text'
                          name={mailingCity?.question?.widgetName || ''}
                          label={mailingCity.question.name}
                          placeholder={mailingCity.question.name}
                          disabled={mailing_same_as_residence || !isFormEnabled}
                          onChange={(event) => {
                            const { id } = mailingCity.question
                            const { value } = event.target
                            setValues({
                              ...values,
                              mailing_city: value,
                              freeAnswers: [
                                ...freeAnswers.filter((x) => x.testQuestionId !== id),
                                {
                                  testQuestionId: id,
                                  answer: value,
                                },
                              ],
                            })
                          }}
                        />
                      </div>
                    )}
                    {mailingCounty && (
                      <div className={styles.input}>
                        <FormTextField
                          type='text'
                          name={mailingCounty.widget.name}
                          label={mailingCounty.question.name}
                          placeholder={mailingCounty.question.name}
                          required
                          disabled={mailing_same_as_residence || !isFormEnabled}
                          onChange={(event) => {
                            const { id } = mailingCounty.question
                            const { value } = event.target
                            setValues({
                              ...values,
                              mailing_county: value,
                              freeAnswers: [
                                ...freeAnswers.filter((x) => x.testQuestionId !== id),
                                {
                                  testQuestionId: id,
                                  answer: value,
                                },
                              ],
                            })
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className={styles.row}>
                    {mailingStreet && (
                      <div className={styles.input}>
                        <FormTextField
                          type='text'
                          name={mailingStreet.widget.name}
                          label={mailingStreet.question.name}
                          placeholder={mailingStreet.question.name}
                          disabled={mailing_same_as_residence || !isFormEnabled}
                          onChange={(event) => {
                            const { id } = mailingStreet.question
                            const { value } = event.target
                            setValues({
                              ...values,
                              mailing_street: value,
                              freeAnswers: [
                                ...freeAnswers.filter((x) => x.testQuestionId !== id),
                                {
                                  testQuestionId: id,
                                  answer: value,
                                },
                              ],
                            })
                          }}
                        />
                      </div>
                    )}
                    {mailingPostalCode && (
                      <div className={styles.input}>
                        <FormTextField
                          type='text'
                          name={mailingPostalCode.widget.name}
                          label={mailingPostalCode.question.name}
                          placeholder={mailingPostalCode.question.name}
                          disabled={mailing_same_as_residence || !isFormEnabled}
                          onChange={(event) => {
                            const { id } = mailingPostalCode.question
                            const { value } = event.target
                            setValues({
                              ...values,
                              mailing_postal_code: value,
                              freeAnswers: [
                                ...freeAnswers.filter((x) => x.testQuestionId !== id),
                                {
                                  testQuestionId: id,
                                  answer: value,
                                },
                              ],
                            })
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <TextLargeStrong isParagraph className={styles.labelBox}>
                  {t('W8.Tax Info')}
                </TextLargeStrong>

                <div className={styles.row}>
                  {usTin && (
                    <div className={styles.input}>
                      <FormTextField
                        disabled={!isFormEnabled}
                        type='text'
                        name={usTin.widget.name}
                        label={usTin.question.name}
                        placeholder={usTin.question.name}
                        onChange={(event) => {
                          const { id } = usTin.question
                          const { value } = event.target
                          setValues({
                            ...values,
                            us_tin: value,
                            freeAnswers: [
                              ...freeAnswers.filter((x) => x.testQuestionId !== id),
                              {
                                testQuestionId: id,
                                answer: value,
                              },
                            ],
                          })
                        }}
                      />
                    </div>
                  )}

                  {usTinType && (
                    <div className={styles.input}>
                      <FormSelectField
                        disabled={!isFormEnabled}
                        name={usTinType.widget.name}
                        label={usTinType.question.name}
                        placeholder={usTinType.question.name}
                        onChange={(event) => {
                          const { id } = usTinType.question
                          const { value } = event.target
                          setValues({
                            ...values,
                            [usTinType.widget.name]: value,
                            selectedAnswers: [
                              ...selectedAnswers.filter((x) => x.question !== id),
                              {
                                question: id,
                                answer: value,
                              },
                            ],
                          })
                        }}
                      >
                        {usTinType.answers
                          .sort((a, b) => b.order - a.order)
                          .map((answer) => (
                            <option value={answer.id} key={answer.id}>
                              {answer.name}
                            </option>
                          ))}
                      </FormSelectField>
                    </div>
                  )}
                </div>

                <div className={styles.row}>
                  {taxCountryId && (
                    <div className={styles.input}>
                      <RawSelectFormField
                        disabled={!isFormEnabled}
                        name={taxCountryId.widget.name}
                        label={taxCountryId.question.name}
                        placeholder={taxCountryId.question.name}
                        onChange={(event) => {
                          const { id } = taxCountryId.question
                          const { value } = event.target
                          setValues({
                            ...values,
                            [taxCountryId.widget.name]: value,
                            freeAnswers: [
                              ...freeAnswers.filter((x) => x.testQuestionId !== id),
                              {
                                testQuestionId: id,
                                answer: value,
                              },
                            ],
                          })
                        }}
                      >
                        <option value='' className='is-hidden' />
                        {inMemorySort(regulators, 'country.name').map((regulator) => (
                          <option value={regulator.country.id} key={regulator.country.id}>
                            {regulator.country.name}
                          </option>
                        ))}
                      </RawSelectFormField>
                    </div>
                  )}
                  {taxCountryTin && (
                    <div className={styles.input}>
                      <FormTextField
                        disabled={!isFormEnabled}
                        type='text'
                        name={taxCountryTin.widget.name}
                        label={taxCountryTin.question.name}
                        placeholder={taxCountryTin.question.name}
                        onChange={(event) => {
                          const { id } = taxCountryTin.question
                          const { value } = event.target
                          setValues({
                            ...values,
                            [taxCountryTin.widget.name]: value,
                            freeAnswers: [
                              ...freeAnswers.filter((x) => x.testQuestionId !== id),
                              {
                                testQuestionId: id,
                                answer: value,
                              },
                            ],
                          })
                        }}
                      />
                    </div>
                  )}
                </div>

                <div className={styles.row}>
                  {birthCountryId && (
                    <div className={styles.input}>
                      <FormSelectField
                        disabled={!isFormEnabled}
                        name={birthCountryId.widget.name}
                        label={birthCountryId.question.name}
                        placeholder={birthCountryId.question.name}
                        onChange={(event) => {
                          const { id } = birthCountryId.question
                          const { value } = event.target
                          setValues({
                            ...values,
                            [birthCountryId.widget.name]: value,
                            freeAnswers: [
                              ...freeAnswers.filter((x) => x.testQuestionId !== id),
                              {
                                testQuestionId: id,
                                answer: value,
                              },
                            ],
                          })
                        }}
                      >
                        <option value='' className='is-hidden' />
                        {inMemorySort(regulators, 'country.name').map((regulator) => (
                          <option value={regulator.country.id} key={regulator.country.id}>
                            {regulator.country.name}
                          </option>
                        ))}
                      </FormSelectField>
                    </div>
                  )}

                  {birthCity && (
                    <div className={styles.input}>
                      <FormTextField
                        disabled={!isFormEnabled}
                        type='text'
                        name={birthCity.widget.name}
                        label={birthCity.question.name}
                        placeholder={birthCity.question.name}
                        onChange={(event) => {
                          const { id } = birthCity.question
                          const { value } = event.target
                          setValues({
                            ...values,
                            [birthCity.widget.name]: value,
                            freeAnswers: [
                              ...freeAnswers.filter((x) => x.testQuestionId !== id),
                              {
                                testQuestionId: id,
                                answer: value,
                              },
                            ],
                          })
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className={styles.row}>
                  {birthday && (
                    <div className={classNames('field', styles.input)}>
                      <FormTextField
                        name='birthdate'
                        type='text'
                        label={birthday.question.name}
                        value={formatDate(birthdate, { formatType: dateFormat })}
                        required
                        disabled
                        showLabel
                        rightIcon={<CalendarIcon />}
                      />
                    </div>
                  )}
                </div>
              </React.Fragment>
            )
          }
          return null
        })}

        {taxFormQuestions.sections.map((section, sectionIndex) => {
          if (section.code === 'claim_of_treaty_benefits') {
            return (
              <React.Fragment key={sectionIndex}>
                <TextH3 className='mb-5 mt-6'>{section.name}</TextH3>
                <Text isParagraph className='mb-3'>
                  {t(`W8.I certify that the beneficial`)}
                </Text>
                {section.questions.map((question, questionIndex) => {
                  if (question.widget.type === 'short_text') {
                    return (
                      <div className={styles.row} key={questionIndex}>
                        <div className={styles.input}>
                          <FormSelectField
                            label={t('W8.Country')}
                            component='select'
                            name={question.widget.name}
                            placeholder={t('W8.Country')}
                            required
                            disabled
                          >
                            <option value='' className='is-hidden' />
                            {inMemorySort(regulators, 'country.name').map((regulator) => (
                              <option value={regulator.country.id} key={regulator.country.id}>
                                {regulator.country.name}
                              </option>
                            ))}
                          </FormSelectField>
                        </div>
                      </div>
                    )
                  }
                  return (
                    <FormCheckBoxField
                      disabled={!isFormEnabled}
                      name={question.widget.name}
                      key={questionIndex}
                      onChange={(event) => {
                        const { id } = question.question
                        const { checked } = event.target
                        setValues({
                          ...values,
                          [question.widget.name]: checked,
                          freeAnswers: [
                            ...freeAnswers.filter((x) => x.testQuestionId !== id),
                            {
                              testQuestionId: id,
                              answer: checked ? '1' : '0',
                            },
                          ],
                        })
                      }}
                    >
                      <Text isParagraph>
                        {t('W8.I do not qualify for U.S. tax treaty benefits')}
                      </Text>
                    </FormCheckBoxField>
                  )
                })}
              </React.Fragment>
            )
          }

          return null
        })}

        <TextH3 className='mt-6 mb-5'>{t('W8.Certification')}</TextH3>
        <div className={styles.confirmation}>
          <Text isParagraph>{t('W8.Under penalties of perjury')}</Text>
          <ol>
            <li className={styles.text}>{t('W8.I am the individual')}</li>
            <li className={styles.text}>{t('W8.The beneficial owner is not a US person;')}</li>
            <li className={styles.text}>
              {t('W8.This form relates to')}
              <Text isParagraph>{t('W8.income not effectively connected')}</Text>
              <Text isParagraph>{t('W8.income effectively connected')}</Text>
              <Text isParagraph>{t("W8.the partner's share")}</Text>
              <Text isParagraph>{t("W8.the partner's amount realized")};</Text>
            </li>

            <li className={styles.text}>{t('W8.The beneficial owner listed on this form is a')}</li>
            <li className={styles.text}>{t('W8.For broker transactions')}</li>
          </ol>
          <Text isParagraph>{t('W8.Furthermore, I authorize')}</Text>
        </div>

        <div className={styles.row}>
          {taxFormQuestions.sections.map((section) => {
            if (section.code === 'certification') {
              return section.questions.map((question, questionIndex) => (
                <>
                  {question.widget.name === 'certify_capacity_to_sign' ? (
                    <div className={styles.checkBox}>
                      <FormCheckBoxField
                        disabled={!isFormEnabled}
                        name={question.widget.name}
                        key={questionIndex}
                        error={errors.certify_capacity_to_sign}
                        label={question.question.name}
                        onChange={(event) => {
                          const { id } = question.question
                          const { checked } = event.target

                          setValues({
                            ...values,
                            [question.widget.name]: checked,
                            freeAnswers: [
                              ...freeAnswers.filter((x) => x.testQuestionId !== id),
                              {
                                testQuestionId: id,
                                answer: checked ? '1' : '0',
                              },
                            ],
                          })
                        }}
                      />
                    </div>
                  ) : question.widget.name === 'certification_date' ? (
                    <div className={styles.input} key={questionIndex}>
                      <FormTextField
                        name='certification_date'
                        type='text'
                        label={question.question.name}
                        value={formatDate(certification_date, { formatType: dateFormat })}
                        required
                        disabled
                        showLabel
                        rightIcon={<CalendarIcon />}
                      />
                    </div>
                  ) : (
                    <div className={styles.input} key={questionIndex}>
                      <FormTextField
                        name={question.widget.name}
                        placeholder={question.question.name}
                        label={question.question.name}
                        disabled={question.widget.name === 'name_of_signer' || !isFormEnabled}
                        onChange={(event) => {
                          if (question.widget.name === 'name_of_signer') {
                            return
                          }
                          const { id } = question.question
                          const { value } = event.target
                          setValues({
                            ...values,
                            [question.widget.name]: value,
                            freeAnswers: [
                              ...freeAnswers.filter((x) => x.testQuestionId !== id),
                              {
                                testQuestionId: id,
                                answer: value,
                              },
                            ],
                          })
                        }}
                      />
                    </div>
                  )}
                </>
              ))
            }

            return null
          })}
        </div>
        <div className={styles.action}>
          <Button appearance='primary' size='L' disabled={!isValid || isSubmitting}>
            {t('Submit')}
          </Button>
        </div>
      </div>
    </Form>
  )
}

const setAnswers = (taxFormQuestions: ClientTestDto, values: W8BeneficialFormValues) => {
  const questions = taxFormQuestions.sections.map((section) => section.questions).flat()
  const defaultTinType = questions
    .find((q) => q.widget.name === 'us_tin_type')
    ?.answers.find((a) => a.key === 'na')

  questions.map((question) => {
    if (question.widget.name === 'us_tin_type') {
      if (!values.selectedAnswers.find((x) => x.question === question?.question.id)) {
        values.selectedAnswers = [
          ...values.selectedAnswers,
          {
            question: question.question.id || '',
            answer: defaultTinType?.id || '',
          },
        ]
      }
    } else if (!values.freeAnswers.find((x) => x.testQuestionId === question.question.id)) {
      let answer = values[question.widget.name as keyof W8BeneficialFormValues]

      if (
        question.widget.name === 'mailing_same_as_residence' ||
        question.widget.name === 'certify_no_us_tax_benefits'
      ) {
        answer = answer ? '1' : '0'
      }
      values.freeAnswers = [
        ...values.freeAnswers,
        {
          testQuestionId: question.question.id,
          answer: answer as string,
        },
      ]
    }
    return null
  })
}

export const getQuestionAnswer = (
  testAnswers: TestAnswersDto | undefined,
  widgetName: string
): string => {
  if (!testAnswers) {
    return ''
  }
  const answers = testAnswers.sections.map((section) => section.answers).flat()
  if (widgetName === 'us_tin_type') {
    return answers.find((x) => x.question.widgetName === widgetName)?.answer.id || ''
  }
  return answers.find((x) => x.question.widgetName === widgetName)?.answer.name || ''
}
export const W8BeneficialForm = withFormik<OuterProps, W8BeneficialFormValues>({
  mapPropsToValues: ({ account, taxFormQuestions, isFormEnabled, taxFormAnswers }) => {
    const questions = taxFormQuestions.sections.map((section) => section.questions).flat()

    const allowRetake = taxFormAnswers?.allowRetake

    const nationality = account.nationalities.find((n) => n.isPrimary)
    const address = account.addresses.find((c) => c.isPrimary)
    const name =
      !isFormEnabled || allowRetake
        ? getQuestionAnswer(taxFormAnswers, 'name_of_individual')
        : [account.firstname, account.middlename, account.lastname].filter(Boolean).join(' ')
    const defaultTinType = questions
      .find((q) => q.widget.name === 'us_tin_type')
      ?.answers.find((a) => a.key === 'na')

    return {
      freeAnswers: [],
      selectedAnswers: [],
      name_of_individual: name || '',
      country_of_citizenship:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'country_of_citizenship')
          : nationality?.id || '',
      residence_country:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'residence_country')
          : address?.country.id || '',
      residence_city:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'residence_city')
          : address?.city || '',
      residence_county:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'residence_county')
          : address?.state || '',
      residence_street:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'residence_street')
          : address?.street || '',
      residence_postal_code:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'residence_postal_code')
          : address?.postalCode || '',
      mailing_same_as_residence:
        (!isFormEnabled || allowRetake) &&
        getQuestionAnswer(taxFormAnswers, 'mailing_same_as_residence') === '0'
          ? false
          : true,
      mailing_country:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'mailing_country')
          : address?.country.id || '',
      mailing_city:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'mailing_city')
          : address?.city || '',
      mailing_county:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'mailing_county')
          : address?.state || '',
      mailing_street:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'mailing_street')
          : address?.street || '',
      mailing_postal_code:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'mailing_postal_code')
          : address?.postalCode || '',
      us_tin: !isFormEnabled || allowRetake ? getQuestionAnswer(taxFormAnswers, 'us_tin') : '',
      us_tin_type:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'us_tin_type')
          : defaultTinType?.id || '',
      tax_country_id:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'tax_country_id')
          : nationality?.id || '',
      tax_country_tin:
        !isFormEnabled || allowRetake ? getQuestionAnswer(taxFormAnswers, 'tax_country_tin') : '',
      birth_country_id:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'birth_country_id')
          : nationality?.id || '',
      citytown_of_birth:
        !isFormEnabled || allowRetake ? getQuestionAnswer(taxFormAnswers, 'citytown_of_birth') : '',
      birthdate: account.birthday || '',
      certify_country_id:
        !isFormEnabled || allowRetake
          ? getQuestionAnswer(taxFormAnswers, 'certify_country_id')
          : address?.country.id || '',
      certify_no_us_tax_benefits:
        (!isFormEnabled || allowRetake) &&
        getQuestionAnswer(taxFormAnswers, 'certify_no_us_tax_benefits') === '1'
          ? true
          : false,
      name_of_signer: name || '',
      certification_date: !isFormEnabled
        ? getQuestionAnswer(taxFormAnswers, 'certification_date')
        : new Date().toISOString() || '',
      certification_signature: !isFormEnabled
        ? getQuestionAnswer(taxFormAnswers, 'certification_signature')
        : '',
      certify_capacity_to_sign:
        (!isFormEnabled || allowRetake) &&
        getQuestionAnswer(taxFormAnswers, 'certify_capacity_to_sign') === '1'
          ? true
          : false,
    }
  },
  validate: (values) => {
    const errors: FormikErrors<W8BeneficialFormValues> = {}

    if (!values.certification_signature) {
      errors.certification_signature = t('Validation.Required')
    }
    if (values.certification_signature !== values.name_of_individual) {
      errors.certification_signature = t('Validation.Signature must match with the name of signer')
    }
    if (!values.mailing_same_as_residence && !values.mailing_country) {
      errors.mailing_country = t('Validation.Required')
    }
    if (!values.mailing_same_as_residence && !values.mailing_county) {
      errors.mailing_county = t('Validation.Required')
    }
    if (!values.mailing_same_as_residence && !values.mailing_city) {
      errors.mailing_city = t('Validation.Required')
    }
    if (!values.mailing_same_as_residence && !values.mailing_street) {
      errors.mailing_street = t('Validation.Required')
    }
    if (!values.mailing_same_as_residence && !values.mailing_postal_code) {
      errors.mailing_postal_code = t('Validation.Required')
    }
    if (!values.certify_capacity_to_sign) {
      errors.certify_capacity_to_sign = t('Validation.Required')
    }

    return errors
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    try {
      setAnswers(props.taxFormQuestions, values)
      props.handelFormSubmit(values)
    } catch (error: unknown) {
      setSubmitting(false)
    }
  },
  isInitialValid: false,
  enableReinitialize: true,
})(W8BeneficialFormUI)
