import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { TextH3, TextStrong } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'

import styles from './TransactionsPage.module.scss'

export interface TCardProps {
  title: string
  description?: string
  renderDescription?(): React.ReactNode
  buttonText: string
  cardType?: TransactionCardType
  additionalDescription?: string
  appearance?: 'primary' | 'secondary'
  icon: React.ReactNode
  to: string
  bottomSpaced?: boolean
  isDisabled?: boolean
  disabledButtonText?: string
  onClick?: () => void
}

export enum TransactionCardType {
  depositCFD,
  depositETD,
  withdrawalCFD,
  withdrawalETD,
  transferCFD,
  transferETD,
  conversionETD,
}

export const Card: React.FC<TCardProps> = (props) => {
  const {
    title,
    buttonText,
    appearance = 'secondary',
    icon,
    to,
    isDisabled = false,
    onClick,
    cardType,
  } = props
  const { description, renderDescription, additionalDescription, bottomSpaced } = props
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isMobile = useWindowResize()

  const [isTooltipVisible, setTooltipVisible] = useState(false)

  const handleClick = () => {
    if (onClick) {
      onClick()
    } else if (!isDisabled) {
      navigate(to)
    }
  }

  const getDisabledButtonText = (cardType?: TransactionCardType) => {
    switch (cardType) {
      case TransactionCardType.withdrawalCFD:
        return t('Wallet.Withdrawal is currently not available')
      default:
        return t('Temporarily unavailable')
    }
  }

  return (
    <div className={styles.card}>
      {isDisabled && isTooltipVisible && (
        <div className={styles.tooltipContainer}>
          <span className={styles.textSmall}>{getDisabledButtonText(cardType)}</span>
        </div>
      )}
      <div className={styles.textBox}>
        {icon}
        {title && <TextH3 className={styles.title}>{title}</TextH3>}
        {description && (
          <div>
            <p className={styles.text}>{description}</p>
          </div>
        )}
        {renderDescription && <div className={styles.text}>{renderDescription()}</div>}
      </div>
      <div className={styles.actionBlock}>
        <Button
          onMouseOut={() => isDisabled && !isMobile && setTooltipVisible(false)}
          onMouseOver={() => isDisabled && !isMobile && setTooltipVisible(true)}
          size='L'
          appearance={appearance}
          className={styles.buttonCard}
          fullWidth
          onClick={handleClick}
          state={isDisabled ? 'disabled' : 'normal'}
        >
          <TextStrong>{buttonText}</TextStrong>
        </Button>
        {(additionalDescription || bottomSpaced) && (
          <p
            className={classNames(styles.text, {
              [styles.bottomSpaced]: bottomSpaced,
            })}
          >
            {additionalDescription}
          </p>
        )}
      </div>
    </div>
  )
}
