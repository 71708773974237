import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  onCancel(): void
}

export const ExchangeRateModal: React.FC<Props> = ({ onCancel }) => {
  const { t } = useTranslation()
  return (
    <React.Fragment>
      <header className={'modal-card-head'}>
        <p className='modal-card-title'>{t('Wallet.Indicative Rate')}</p>
      </header>
      <section className='modal-card-body'>
        <p className='text px-1 py-2'>
          {t(
            'Wallet.The current rate is subject to ongoing updates and may not be the same at the time of the conversion'
          )}
        </p>
      </section>
      <footer className={'modal-card-foot'}>
        <button className='button' onClick={onCancel}>
          {t('Got It')}
        </button>
      </footer>
    </React.Fragment>
  )
}
