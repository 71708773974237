import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import styles from './SelectableModal.module.scss'

interface Props {
  title: string
  onCancel(): void
  renderOptions(): React.ReactNode
  renderFooter?(): React.ReactNode
}

export const SelectableModal: React.FC<Props> = ({
  onCancel,
  title,
  renderOptions,
  renderFooter,
}) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{title}</p>
      </header>
      <section className={classNames('modal-card-body', styles.body)}>{renderOptions()}</section>
      <footer className={classNames('modal-card-foot', styles.foot)}>
        {!renderFooter ? (
          <button className='button' onClick={onCancel} type='button'>
            {t('Cancel')}
          </button>
        ) : (
          renderFooter()
        )}
      </footer>
    </React.Fragment>
  )
}
