import React from 'react'
import { useFormikContext } from 'formik'

import { CurrencyModal, CurrencyModalItem } from '../../../global/modal/CurrencyModal'
import { WalletPaymentProviderCurrency } from '../../../model/WalletPaymentProviderParameters'
import { WalletWithdrawFormValues } from './WalletWithdrawForm/WalletWithdrawForm'

interface WalletModalProps {
  onSelectOption(option: WalletPaymentProviderCurrency): void

  onClose(): void
}

export const WalletWithdrawCurrencyModal: React.FC<WalletModalProps> = (props) => {
  const { onSelectOption, onClose } = props

  const { values } = useFormikContext<WalletWithdrawFormValues>()

  const handleSelectOption = async (
    walletPaymentProviderCurrency: WalletPaymentProviderCurrency
  ) => {
    onSelectOption(walletPaymentProviderCurrency)
  }

  return (
    <CurrencyModal
      currencies={values.paymentProvider.parameters.currencies}
      renderItem={({ item }) => (
        <CurrencyModalItem
          selected={item.id === values.paymentProvider.currency.id}
          currency={item}
          onSelectOption={handleSelectOption}
          key={item.id}
        />
      )}
      onSelectOption={handleSelectOption}
      onClose={onClose}
    />
  )
}
