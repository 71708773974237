import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { IDdocsIcon } from '../../icons/IDdocsIcon'
import { UploadIcon } from '../../icons/UploadIcon'
import { AccountDetailedStatus } from '../../model/AccountDetailedStatus'
import { Note } from '../../ui/Note/Note'
import { isDepositBeforeKYC } from '../../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { isSignUpPath } from '../../utils/path'
import { useLocallyPersistedState } from '../../utils/useStorage'
import { Button } from '../button/Button'

import styles from './StatusBanner.module.scss'

export const StatusBanner: React.FC = () => {
  const [bannerOpen, setBannerOpen] = useLocallyPersistedState('statusBannerOpen', true)
  const location = useLocation()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { account } = useAccountReadContext()

  const isSignUpRoute = isSignUpPath(location.pathname)
  const isRedirectPath = location.pathname.includes('redirect')
  const isEligibleAccountStatus =
    (account &&
      [
        AccountDetailedStatus.AppropriatenessTestSubmitted,
        AccountDetailedStatus.PendingDocumentVerification,
      ].includes(account.status.id)) ??
    false

  const displayBanner = useMemo(
    () =>
      bannerOpen &&
      !isSignUpRoute &&
      !isRedirectPath &&
      isEligibleAccountStatus &&
      isDepositBeforeKYC(account),
    [bannerOpen, location.pathname, account]
  )

  if (!displayBanner) {
    return null
  }

  return (
    <div className={styles.statusBanner}>
      <Note
        renderLeft={<IDdocsIcon color='warning' />}
        mobileTitle={t('Verification required')}
        text={t('To gain full access to your Client Area, please upload your documents')}
        renderRight={
          <Button
            size='XS'
            appearance='primary'
            renderRightIcon={() => <UploadIcon color='alwaysWhite' />}
            onClick={() => navigate('/profile/document-management')}
          >
            {t('Profile.Upload')}
          </Button>
        }
        close={() => setBannerOpen(false)}
      />
    </div>
  )
}
