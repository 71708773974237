export enum PaymentRedirectTransactionStatusType {
  FAILED = '0',
  SUCCESS = '1',
  COMPLETED = '2',
}

export const isPaymentRedirectTransactionFailedStatus = (value: string) => {
  return PaymentRedirectTransactionStatusType.FAILED === value
}

export const isPaymentRedirectTransactionSuccessStatus = (value: string) => {
  return PaymentRedirectTransactionStatusType.SUCCESS === value
}

export const isPaymentRedirectTransactionCompletedStatus = (value: string) => {
  return PaymentRedirectTransactionStatusType.COMPLETED === value
}
