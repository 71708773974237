import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { getContactUsLink } from '../../utils/companyName.utils'
import { usePublicDomainsReadContext } from '../context/PublicDomainsContext'
import { useSessionLanguage } from '../context/SessionSettingsContext'

interface Props {
  title: string
  renderFooter?: () => React.ReactNode
  renderBody: () => React.ReactNode
  onCancel?: () => void
  onConfirm?: () => void
}

export const InfoModal: React.FC<Props> = ({
  onCancel,
  onConfirm,
  renderBody,
  title,
  renderFooter,
}) => {
  const { t } = useTranslation()
  const { domains } = usePublicDomainsReadContext()
  const locale = useSessionLanguage()

  const supportLink = getContactUsLink(domains, locale)
  return (
    <Fragment>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{title}</p>
      </header>
      {renderBody()}
      <footer className={'modal-card-foot'}>
        {renderFooter ? (
          renderFooter()
        ) : (
          <React.Fragment>
            <button
              className='button'
              onClick={() => {
                window.open(
                  supportLink,
                  '_blank',
                  'location=yes,height=570,width=520,scrollbars=yes,status=yes'
                )
                onCancel?.()
              }}
              type='button'
            >
              {t('Contact Support')}
            </button>
            <button className='button' onClick={onConfirm} type='button'>
              {t('Got It')}
            </button>
          </React.Fragment>
        )}
      </footer>
    </Fragment>
  )
}
