import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

interface Props {
  email?: string
  attempts: number
  attemptsLeft: number
  onCancel: () => void
  onConfirm: () => void
}

export const PasswordResetConfirmation: React.FC<Props> = ({
  onConfirm,
  email,
  attemptsLeft,
  attempts,
}) => {
  const { t } = useTranslation()

  return (
    <Fragment>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('Password has been reset')}</p>
      </header>

      <section className={classNames('modal-card-body')}>
        <p
          dangerouslySetInnerHTML={{
            __html: `${t('We sent out the password reset e-mail')} (<b>${email}</b>) ${t(
              'to your address. Please follow the instructions on e-mail.'
            )}`,
          }}
        />
      </section>
      <footer className='modal-card-foot'>
        <button className='button' onClick={onConfirm} type='button'>
          {t('Got It')}
        </button>
      </footer>
    </Fragment>
  )
}

interface PasswordChangedConfirmationProps {
  onCancel: Props['onCancel']
  onConfirm: Props['onConfirm']
}
export const PasswordChangedConfirmation: React.FC<PasswordChangedConfirmationProps> = ({
  onConfirm,
}) => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('Password has been changed')}</p>
      </header>

      <section className={classNames('modal-card-body')}>
        <p
          dangerouslySetInnerHTML={{
            __html: t('Password has been reset successfully!'),
          }}
        />
      </section>
      <footer className='modal-card-foot'>
        <button className='button' onClick={onConfirm} type='button'>
          {t('Got It')}
        </button>
      </footer>
    </Fragment>
  )
}
