import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useFormikContext } from 'formik'

import { Loading } from '../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../global/context/SessionSettingsContext'
import {
  PaymentProviderModal,
  PaymentProviderWithdrawalModalItem,
} from '../../../global/modal/PaymentProviderModal'
import { BankAccount } from '../../../model/BankAccount'
import { PaymentProvider } from '../../../model/PaymentProviderDto'
import { isPaymentProviderBankType } from '../../../model/PaymentProviderType'
import { WalletPaymentProvider } from '../../../model/WalletPaymentProvider'
import { WalletPaymentProviderParameterMessage } from '../../../model/WalletPaymentProviderParameters'
import { useApiClient } from '../../../utils/ApiClient'
import { ClientApiClient } from '../../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../utils/useFetch'
import {
  Wallet,
  WalletWithdrawFormValues,
  getCurrencyLimits,
} from './WalletWithdrawForm/WalletWithdrawForm'

interface PaymentProviderModalProps {
  onSelectOption(option: PaymentProviderOptionProps): void
  onClose(): void
}

export interface PaymentProviderOptionProps {
  paymentProviderOptions: PaymentProvider
  bankAccounts: BankAccount[]
  walletPaymentProvider: WalletPaymentProvider
}

export const WalletWithdrawPaymentProviderModal: React.FC<PaymentProviderModalProps> = (props) => {
  const { onSelectOption, onClose } = props

  const { walletId } = useParams<{ walletId?: string }>()
  const { values } = useFormikContext<WalletWithdrawFormValues>()

  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()

  const [isSelectLoading, setSelectLoading] = useState<boolean>(false)

  const { callback } = useCallbackWithForceRefresh(async () => {
    return apiClient.getWalletWithdrawalMethods(locale, values.wallet.id || walletId || '')
  }, [])

  const { data: paymentProviders = [], isLoading } = useFetchOne(callback)

  const handleSelectOption = async (walletPaymentProvider: WalletPaymentProvider) => {
    setSelectLoading(true)
    if (values.wallet.currency) {
      const paymentProvider = await fetchWalletWithdrawalMethodParameters(walletPaymentProvider)
      const paymentProviderOptions = {
        ...paymentProvider,
        parameters: {
          ...paymentProvider.parameters,
          messages: paymentProvider.parameters.messages.map((x) =>
            walletWithdrawWarningMessageFormat(x, values.wallet, paymentProvider)
          ),
        },
      }

      if (isPaymentProviderBankType(paymentProvider.providerCategory.id)) {
        const bankAccounts = await apiClient.getBankAccounts()
        onSelectOption({ paymentProviderOptions, bankAccounts, walletPaymentProvider })
      } else {
        onSelectOption({ paymentProviderOptions, bankAccounts: [], walletPaymentProvider })
      }
    }
  }

  const fetchWalletWithdrawalMethodParameters = async (
    walletPaymentProvider: WalletPaymentProvider
  ) => {
    const walletPaymentProviderParameters = await apiClient.getWalletWithdrawalMethodParameters(
      locale,
      values.wallet.id || walletId || '',
      walletPaymentProvider.method.id
    )

    const paymentProvider: PaymentProvider = {
      id: walletPaymentProvider.method.id,
      name: walletPaymentProvider.name,
      description: walletPaymentProvider.description,
      providerCategory: walletPaymentProvider.providerCategory,
      currency: {
        id: walletPaymentProvider.currency.id,
        ...walletPaymentProvider,
      },
      parameters: walletPaymentProviderParameters,
      method_name: walletPaymentProvider.method_name,
    }

    return paymentProvider
  }

  return (
    <PaymentProviderModal
      render={() => (
        <Loading isModal isLoading={isLoading || isSelectLoading} showLoadingIcon>
          {paymentProviders.map((x) => (
            <PaymentProviderWithdrawalModalItem
              item={x}
              onSelectOption={handleSelectOption}
              key={x.method.id}
            />
          ))}
        </Loading>
      )}
      onClose={onClose}
    />
  )
}

const walletWithdrawWarningMessageFormat = (
  message: WalletPaymentProviderParameterMessage,
  wallet: Wallet,
  paymentProvider: PaymentProvider
): WalletPaymentProviderParameterMessage => {
  const currencyLimit = getCurrencyLimits(wallet, paymentProvider)
  const minAmount = currencyLimit?.minAmount?.toString() || '0'
  const maxAmount = currencyLimit?.maxAmount?.toString() || '0'
  const currency = paymentProvider.currency.id || ''

  return {
    ...message,
    list: message.list.map((x) =>
      x
        .replace(/{minAmount}/g, minAmount)
        .replace(/{maxAmount}/g, maxAmount)
        .replace(/{paymentProviderCurrency}/g, currency)
    ),
  }
}
