import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { useFormatNumber } from '../../hooks/useFormatNumber'
import { NameDto } from '../../model/NameDto'
import { TickmillProductType } from '../../model/TickmillProductType'
import { WalletDto, WalletTypeEnum } from '../../model/WalletDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'
import { useRedirectToSupport } from '../../utils/useRedirectToSupport'
import {
  WalletRestrictions,
  isDepositEnabledFromAction,
  isRestricted,
  isWalletSuspended,
} from '../../utils/wallet.utils'
import { Loading } from '../Loading/Loading'
import { useProductReadContext } from '../context/ProductContext'
import { SelectModal, SelectModalOption } from '../field/SelectModal'
import { Modal } from './Modal'

interface WalletRestrictionsModalProps {
  title: string

  onSelectOption?(option: WalletDto): void

  onClose(): void

  render?({ items }: { items: WalletDto[] }): React.ReactNode

  renderItem?({ item }: { item: WalletDto; onSelect(wallet: WalletDto): void }): React.ReactNode

  onConfirm?(): void
}

export const WalletRestrictionsModal: React.FC<WalletRestrictionsModalProps> = ({
  title,
  onSelectOption,
  onClose,
  render,
  renderItem,
  onConfirm,
}) => {
  const apiClient = useApiClient(ClientApiClient)
  const { product } = useProductReadContext()
  const location = useLocation()
  const { callback } = useCallbackWithForceRefresh(async () => {
    let walletTypeId = WalletTypeEnum.CFD
    if (product === TickmillProductType.ETD) {
      walletTypeId = WalletTypeEnum.ETD
    } else if (location.pathname.includes('introducing-broker')) {
      walletTypeId = WalletTypeEnum.IB
    } else if (location.pathname.includes('payment-agent')) {
      walletTypeId = WalletTypeEnum.PAYMENT_AGENT
    }
    return apiClient.getWallets({ walletTypeId })
  }, [product])
  const { data: wallets = [], isLoading } = useFetchOne(callback)

  const handleSelectOption = (wallet: WalletDto) => {
    onSelectOption?.(wallet)
  }

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onConfirm={onConfirm}
          onCancel={closeModal}
          title={title}
          renderOptions={() => (
            <Loading isModal isLoading={isLoading}>
              <div className='control'>
                {render
                  ? render({ items: wallets })
                  : renderItem &&
                    wallets.map((x) =>
                      renderItem?.({
                        item: x,
                        onSelect: handleSelectOption,
                      })
                    )}
              </div>
            </Loading>
          )}
        />
      )}
    />
  )
}

interface WalletDepositItemProps {
  selected: boolean
  item: WalletDto
  restrictionType: WalletRestrictions
  onSelectOption(option: WalletDto): void

  hideHint?: boolean
}

export const WalletModalItem: React.FC<WalletDepositItemProps> = (props) => {
  const { selected, item, restrictionType, onSelectOption, hideHint } = props

  const isWalletAllowed =
    !(isRestricted(restrictionType, item.restrictions) && isWalletSuspended(item.restrictions)) &&
    ((restrictionType === WalletRestrictions.DEPOSIT &&
      isDepositEnabledFromAction(item.depositAction)) ||
      restrictionType !== WalletRestrictions.DEPOSIT)

  const { t } = useTranslation()
  const location = useLocation()
  const { formatMoney } = useFormatNumber()

  const walletName = location.pathname.includes('introducing-broker')
    ? t('Wallet.IB Wallet')
    : t('Wallet.Wallet')

  return (
    <SelectModalOption
      value={selected}
      label={`${item.currency?.id} ${walletName}`}
      hint={hideHint ? '' : `${t('Wallet.Balance')} ${formatMoney(item.balance, item.currency.id)}`}
      disabled={!isWalletAllowed}
      onClick={() => onSelectOption(item)}
    >
      <WalletRestriction restrictions={item.restrictions} restrictionType={restrictionType} />
    </SelectModalOption>
  )
}

interface WalletRestrictionProps {
  restrictions: NameDto[] | undefined
  restrictionType: WalletRestrictions
}

export const WalletRestriction: React.FC<WalletRestrictionProps> = ({
  restrictions,
  restrictionType,
}) => {
  const { t } = useTranslation()
  const { supportUrl } = useRedirectToSupport()

  if (isWalletSuspended(restrictions)) {
    return (
      <div>
        <div className='has-error'>{t('Transfers restricted for this TM Wallet')}</div>
        <div>
          {t('Please')}{' '}
          <Link to={supportUrl} target='_blank' rel='noreferrer'>
            {t('Contact Support').toLowerCase()}
          </Link>{' '}
          {t('for more info.')}
        </div>
      </div>
    )
  }

  if (isRestricted(restrictionType, restrictions)) {
    return (
      <div>
        <div className='has-error'>{t('Temporarily restricted!')}</div>
        <div>
          Please{' '}
          <Link to={supportUrl} target='_blank' rel='noreferrer'>
            {t('Contact Support').toLowerCase()}
          </Link>{' '}
          {t('for more info.')}
        </div>
      </div>
    )
  }

  return null
}
