import axios from 'axios'
import Cookie from 'js-cookie'

import { RefreshTokenResponse } from '../model/RefreshTokenResponse'

type TokenType = 'refresh' | 'access' | 'session' | 'trustDevice'

export class TokenStorage {
  static readonly ACCESS_TOKEN = '__Host_tm-access-token'
  static readonly REFRESH_TOKEN = '__Host_tm-refresh-token'
  static readonly TRUST_DEVICE_TOKEN = '__Host_tm-trustDevice-token'
  static readonly SESSION = 'session'

  public static getTokenByType(type: TokenType): string {
    const getType: Record<TokenType, string> = {
      session: TokenStorage.SESSION,
      access: TokenStorage.ACCESS_TOKEN,
      refresh: TokenStorage.REFRESH_TOKEN,
      trustDevice: TokenStorage.TRUST_DEVICE_TOKEN,
    }
    return getType[type]
  }

  public static storeSession(value: string): void {
    Cookie.set(this.getTokenByType('session'), value)
  }

  public static storeToken(type: TokenType, value: string): void {
    if (process.env.NODE_ENV !== 'development') {
      return
    }
    Cookie.set(this.getTokenByType(type), value)
  }

  public static getToken(type: TokenType): string | undefined {
    return Cookie.get(this.getTokenByType(type))
  }

  public static getNewToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      const token = this.getToken('refresh')
      const sessionId = this.getToken('session')
      let payload
      // we assume on development all is taken from set-headers cookies, and we should not store these tokens.
      if (process.env.NODE_ENV === 'development') {
        payload = {
          sessionId,
          refreshToken: token,
        }
      } else {
        payload = { sessionId }
      }
      try {
        return axios
          .post<RefreshTokenResponse>(`api/access-tokens/refresh`, payload)
          .then((response) => {
            const tokenResponse = response.data
            this.storeToken('access', tokenResponse.accessToken)
            this.storeToken('refresh', tokenResponse.refreshToken)
            this.storeSession(tokenResponse.sessionId)
            resolve(tokenResponse.accessToken)
          })
          .catch((error) => {
            this.clear()
            Cookie.remove('auth')
            window.location.replace('/login')
            window.location.reload()
            reject(error)
          })
      } catch (e: unknown) {
        console.error(e)
      }
    })
  }

  public static clear(): void {
    Cookie.remove(this.ACCESS_TOKEN)
    Cookie.remove(this.SESSION)
    Cookie.remove(this.REFRESH_TOKEN)
  }
}
