import {
  PlatformTypeEnum,
  isMT4PlatformType,
  isMT5PlatformType,
  isPlatformTypeCQGCAST,
  isTMTPlatformType,
} from './PlatformTypeEnum'

export enum TickmillProductType {
  CFD = 1,
  ETD = 2,
}

export const getTickmillProductName = (productType: TickmillProductType | undefined): string => {
  if (productType === TickmillProductType.CFD) {
    return 'FX/CFD'
  }

  if (productType === TickmillProductType.ETD) {
    return 'Futures'
  }

  return ''
}

export const getTickmillProductTypeName = (value: TickmillProductType): string => {
  return TickmillProductType[value]
}

export const getTickmillProductIdByCFD = (value: boolean): TickmillProductType | undefined => {
  return value ? TickmillProductType.CFD : undefined
}

export const getTickmillProductIdByETD = (value: boolean): TickmillProductType | undefined => {
  return value ? TickmillProductType.ETD : undefined
}

export const isTickmillProductTypeCFD = (
  productType: TickmillProductType | TickmillProductType[]
): boolean => {
  if (Array.isArray(productType)) {
    return productType.includes(TickmillProductType.CFD)
  }
  return productType === TickmillProductType.CFD
}

export const isTickmillProductTypeETD = (
  productType: TickmillProductType | TickmillProductType[]
): boolean => {
  if (Array.isArray(productType)) {
    return productType.includes(TickmillProductType.ETD)
  }
  return productType === TickmillProductType.ETD
}

export const isPlatformTypeCFD = (platform: PlatformTypeEnum): boolean =>
  isTMTPlatformType(platform) || isMT4PlatformType(platform) || isMT5PlatformType(platform)

export const isPlatformTypeETD = (platform: PlatformTypeEnum): boolean =>
  isPlatformTypeCQGCAST(platform)
