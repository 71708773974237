import styles from './Icons.module.scss'

export function GettingStartedSubscribePlatformIcon() {
  return (
    <svg
      width='592'
      height='224'
      viewBox='0 0 592 224'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='592' height='224' rx='16' fill={styles.background} />
      <rect x='279' y='164.5' width='34' height='14' fill='#BEBEBE' />
      <rect x='259' y='178.5' width='74' height='6' rx='3' fill={styles.text} />
      <path
        d='M172 55.5C172 46.6634 179.163 39.5 188 39.5H404C412.837 39.5 420 46.6634 420 55.5V69.5H172V55.5Z'
        fill='#ECECEC'
      />
      <path
        d='M172 69.5H420V148.5C420 157.337 412.837 164.5 404 164.5H188C179.163 164.5 172 157.337 172 148.5V69.5Z'
        fill='white'
      />
      <rect
        x='174'
        y='41.5'
        width='244'
        height='121'
        rx='14'
        stroke={styles.text}
        strokeWidth='4'
      />
      <path d='M186.5 58H222.5' stroke={styles.primary} strokeWidth='3' strokeLinecap='round' />
      <path
        d='M208.502 115.388L230.014 95.8343C230.274 95.5983 230.638 95.5156 230.974 95.6165L249.86 101.282C250.178 101.378 250.523 101.309 250.781 101.098L272.3 83.4895M272.3 83.4895L260.338 83.4905M272.3 83.4895L270.306 95.452'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M250.37 115.889V143.8M210.496 143.8V127.851M230.433 143.8V111.902M270.306 143.8V111.902M241.004 122.758V143.8M220.431 143.8V120.508M260.29 132.08V143.8'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <line
        x1='303.5'
        y1='89'
        x2='367.5'
        y2='89'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <line
        x1='303'
        y1='97.5'
        x2='385'
        y2='97.5'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='303'
        y1='103.5'
        x2='368'
        y2='103.5'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='303'
        y1='109.5'
        x2='380'
        y2='109.5'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='303'
        y1='115.5'
        x2='365'
        y2='115.5'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='303'
        y1='121.5'
        x2='354'
        y2='121.5'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='303'
        y1='127.5'
        x2='375'
        y2='127.5'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='303'
        y1='133.5'
        x2='360'
        y2='133.5'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='303'
        y1='139.5'
        x2='338'
        y2='139.5'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export function GettingStartedSubscribePlatformMobileIcon() {
  return (
    <svg
      width='312'
      height='186'
      viewBox='0 0 312 186'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.539062' width='312' height='185.242' rx='16' fill={styles.background} />
      <rect x='143.66' y='131.266' width='24.6774' height='10.1613' fill='#BEBEBE' />
      <rect
        x='129.145'
        y='141.426'
        width='53.7097'
        height='4.35484'
        rx='2.17742'
        fill={styles.text}
      />
      <path
        d='M66 56.5391C66 47.7025 73.1634 40.5391 82 40.5391H230C238.837 40.5391 246 47.7025 246 56.5391V62.3133H66V56.5391Z'
        fill='#ECECEC'
      />
      <path
        d='M66 62.3125H246V115.264C246 124.101 238.837 131.264 230 131.264H82C73.1634 131.264 66 124.101 66 115.264V62.3125Z'
        fill='white'
      />
      <rect
        x='68'
        y='42.5391'
        width='176'
        height='86.7258'
        rx='14'
        stroke={styles.text}
        strokeWidth='4'
      />
      <path
        d='M76.5234 53.9648H102.652'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M92.4944 95.6209L107.993 81.5327C108.253 81.2967 108.617 81.2141 108.953 81.3149L122.372 85.3407C122.691 85.4362 123.036 85.3673 123.293 85.1568L138.799 72.4685M138.799 72.4685L130.117 72.4693M138.799 72.4685L137.352 81.151'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M122.882 95.9839V116.242M93.9414 116.242V104.666M108.412 116.242V93.0898M137.352 116.242V93.0898M116.084 100.97V116.242M101.152 116.242V99.3366M130.082 107.736V116.242'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <line
        x1='161.855'
        y1='76.0547'
        x2='207.484'
        y2='76.0547'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <line
        x1='161.355'
        y1='82.3594'
        x2='220.323'
        y2='82.3594'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='161.355'
        y1='86.7148'
        x2='207.984'
        y2='86.7148'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='161.355'
        y1='91.0703'
        x2='216.694'
        y2='91.0703'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='161.355'
        y1='95.4258'
        x2='205.807'
        y2='95.4258'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='161.355'
        y1='99.7812'
        x2='197.823'
        y2='99.7812'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='161.355'
        y1='104.137'
        x2='213.065'
        y2='104.137'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='161.355'
        y1='108.488'
        x2='202.178'
        y2='108.488'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
      <line
        x1='161.355'
        y1='112.844'
        x2='186.21'
        y2='112.844'
        stroke={styles.text}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}
