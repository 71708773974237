import styles from './Icons.module.scss'

export function GettingStartedSubscribeMarketDataIcon() {
  return (
    <svg
      width='592'
      height='224'
      viewBox='0 0 592 224'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='592' height='224' rx='16' fill={styles.background} />
      <rect x='279' y='164.5' width='34' height='14' fill='#BEBEBE' />
      <rect x='259' y='178.5' width='74' height='6' rx='3' fill={styles.text} />
      <path
        d='M172 55.5C172 46.6634 179.163 39.5 188 39.5H404C412.837 39.5 420 46.6634 420 55.5V69.5H172V55.5Z'
        fill='#ECECEC'
      />
      <path
        d='M172 69.5H420V148.5C420 157.337 412.837 164.5 404 164.5H188C179.163 164.5 172 157.337 172 148.5V69.5Z'
        fill={styles.background}
      />

      <rect
        x='173.5'
        y='41'
        width='245'
        height='122'
        rx='14.5'
        stroke={styles.text}
        strokeWidth='3'
      />
      <line
        x1='186.5'
        y1='58'
        x2='222.5'
        y2='58'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M210.5 140.5L210.5 142L213.5 142L213.5 140.5L210.5 140.5ZM213.5 89.5C213.5 88.6716 212.828 88 212 88C211.172 88 210.5 88.6716 210.5 89.5L213.5 89.5ZM213.5 140.5L213.5 89.5L210.5 89.5L210.5 140.5L213.5 140.5Z'
        fill={styles.text}
      />
      <path
        d='M210.5 140.5L210.5 142L213.5 142L213.5 140.5L210.5 140.5ZM213.5 98.5C213.5 97.6716 212.828 97 212 97C211.172 97 210.5 97.6716 210.5 98.5L213.5 98.5ZM213.5 140.5L213.5 98.5L210.5 98.5L210.5 140.5L213.5 140.5Z'
        fill={styles.primary}
      />
      <path
        d='M200.5 140.5L200.5 142L203.5 142L203.5 140.5L200.5 140.5ZM203.5 89.5C203.5 88.6716 202.828 88 202 88C201.172 88 200.5 88.6716 200.5 89.5L203.5 89.5ZM203.5 140.5L203.5 89.5L200.5 89.5L200.5 140.5L203.5 140.5Z'
        fill={styles.text}
      />
      <path
        d='M200.5 140.5L200.5 142L203.5 142L203.5 140.5L200.5 140.5ZM203.5 108.5C203.5 107.672 202.828 107 202 107C201.172 107 200.5 107.672 200.5 108.5L203.5 108.5ZM203.5 140.5L203.5 108.5L200.5 108.5L200.5 140.5L203.5 140.5Z'
        fill={styles.primary}
      />
      <path
        d='M220.5 140.5L220.5 142L223.5 142L223.5 140.5L220.5 140.5ZM223.5 89.5C223.5 88.6716 222.828 88 222 88C221.172 88 220.5 88.6716 220.5 89.5L223.5 89.5ZM223.5 140.5L223.5 89.5L220.5 89.5L220.5 140.5L223.5 140.5Z'
        fill={styles.text}
      />
      <path
        d='M220.5 140.5L220.5 142L223.5 142L223.5 140.5L220.5 140.5ZM223.5 126.5C223.5 125.672 222.828 125 222 125C221.172 125 220.5 125.672 220.5 126.5L223.5 126.5ZM223.5 140.5L223.5 126.5L220.5 126.5L220.5 140.5L223.5 140.5Z'
        fill={styles.primary}
      />
      <path
        d='M230.5 140.5L230.5 142L233.5 142L233.5 140.5L230.5 140.5ZM233.5 89.5C233.5 88.6716 232.828 88 232 88C231.172 88 230.5 88.6716 230.5 89.5L233.5 89.5ZM233.5 140.5L233.5 89.5L230.5 89.5L230.5 140.5L233.5 140.5Z'
        fill={styles.text}
      />
      <path
        d='M230.5 140.5L230.5 142L233.5 142L233.5 140.5L230.5 140.5ZM233.5 103.5C233.5 102.672 232.828 102 232 102C231.172 102 230.5 102.672 230.5 103.5L233.5 103.5ZM233.5 140.5L233.5 103.5L230.5 103.5L230.5 140.5L233.5 140.5Z'
        fill={styles.primary}
      />
      <path
        d='M190.5 140.5L190.5 142L193.5 142L193.5 140.5L190.5 140.5ZM193.5 89.5C193.5 88.6716 192.828 88 192 88C191.172 88 190.5 88.6716 190.5 89.5L193.5 89.5ZM193.5 140.5L193.5 89.5L190.5 89.5L190.5 140.5L193.5 140.5Z'
        fill={styles.primary}
      />

      <mask id='path-17-inside-1_76_159829' fill={styles.background}>
        <path d='M297 114.5C297 126.65 287.15 136.5 275 136.5C262.85 136.5 253 126.65 253 114.5C253 102.35 262.85 92.5 275 92.5C287.15 92.5 297 102.35 297 114.5ZM256.3 114.5C256.3 124.828 264.672 133.2 275 133.2C285.328 133.2 293.7 124.828 293.7 114.5C293.7 104.172 285.328 95.8 275 95.8C264.672 95.8 256.3 104.172 256.3 114.5Z' />
      </mask>
      <path
        d='M297 114.5C297 126.65 287.15 136.5 275 136.5C262.85 136.5 253 126.65 253 114.5C253 102.35 262.85 92.5 275 92.5C287.15 92.5 297 102.35 297 114.5ZM256.3 114.5C256.3 124.828 264.672 133.2 275 133.2C285.328 133.2 293.7 124.828 293.7 114.5C293.7 104.172 285.328 95.8 275 95.8C264.672 95.8 256.3 104.172 256.3 114.5Z'
        fill={styles.text}
        stroke={styles.text}
        strokeWidth='2'
        mask='url(#path-17-inside-1_76_159829)'
      />

      <path
        d='M277.786 92.6771C273.867 92.1768 269.885 92.7411 266.26 94.3107C262.634 95.8803 259.498 98.3974 257.181 101.597C254.864 104.797 253.451 108.562 253.091 112.497C252.732 116.431 253.438 120.39 255.136 123.957C256.835 127.524 259.462 130.568 262.743 132.769C266.024 134.97 269.837 136.247 273.782 136.466C277.727 136.685 281.657 135.837 285.162 134.013C288.666 132.188 291.614 129.453 293.696 126.095L290.892 124.356C289.122 127.21 286.616 129.535 283.637 131.086C280.659 132.637 277.318 133.357 273.965 133.171C270.612 132.985 267.37 131.9 264.582 130.029C261.793 128.158 259.559 125.571 258.116 122.538C256.672 119.506 256.072 116.141 256.378 112.797C256.683 109.453 257.884 106.253 259.854 103.533C261.823 100.813 264.489 98.6733 267.571 97.3391C270.653 96.0049 274.037 95.5253 277.368 95.9505L277.786 92.6771Z'
        fill={styles.primary}
      />
      <mask id='path-19-inside-2_76_159829' fill={styles.background}>
        <path d='M348 114.5C348 126.65 338.15 136.5 326 136.5C313.85 136.5 304 126.65 304 114.5C304 102.35 313.85 92.5 326 92.5C338.15 92.5 348 102.35 348 114.5ZM307.3 114.5C307.3 124.828 315.672 133.2 326 133.2C336.328 133.2 344.7 124.828 344.7 114.5C344.7 104.172 336.328 95.8 326 95.8C315.672 95.8 307.3 104.172 307.3 114.5Z' />
      </mask>
      <path
        d='M348 114.5C348 126.65 338.15 136.5 326 136.5C313.85 136.5 304 126.65 304 114.5C304 102.35 313.85 92.5 326 92.5C338.15 92.5 348 102.35 348 114.5ZM307.3 114.5C307.3 124.828 315.672 133.2 326 133.2C336.328 133.2 344.7 124.828 344.7 114.5C344.7 104.172 336.328 95.8 326 95.8C315.672 95.8 307.3 104.172 307.3 114.5Z'
        fill={styles.text}
        stroke={styles.text}
        strokeWidth='2'
        mask='url(#path-19-inside-2_76_159829)'
      />
      <path
        d='M319.629 93.4428C316.64 94.3471 313.877 95.8775 311.526 97.9322C309.174 99.9868 307.286 102.519 305.989 105.359C304.691 108.2 304.013 111.284 304 114.407C303.987 117.53 304.639 120.62 305.912 123.471C307.186 126.322 309.051 128.87 311.386 130.945C313.72 133.019 316.469 134.573 319.451 135.502C322.432 136.432 325.577 136.717 328.677 136.337C331.776 135.957 334.76 134.921 337.428 133.299L335.714 130.479C333.446 131.858 330.91 132.738 328.275 133.061C325.64 133.384 322.967 133.142 320.433 132.352C317.899 131.562 315.562 130.241 313.578 128.478C311.594 126.715 310.008 124.549 308.925 122.125C307.843 119.702 307.289 117.075 307.3 114.421C307.311 111.766 307.888 109.145 308.991 106.73C310.093 104.316 311.698 102.164 313.697 100.417C315.696 98.6709 318.044 97.3701 320.584 96.6013L319.629 93.4428Z'
        fill={styles.primary}
      />
      <mask id='path-21-inside-3_76_159829' fill={styles.background}>
        <path d='M400 114.5C400 126.65 390.15 136.5 378 136.5C365.85 136.5 356 126.65 356 114.5C356 102.35 365.85 92.5 378 92.5C390.15 92.5 400 102.35 400 114.5ZM359.3 114.5C359.3 124.828 367.672 133.2 378 133.2C388.328 133.2 396.7 124.828 396.7 114.5C396.7 104.172 388.328 95.8 378 95.8C367.672 95.8 359.3 104.172 359.3 114.5Z' />
      </mask>
      <path
        d='M400 114.5C400 126.65 390.15 136.5 378 136.5C365.85 136.5 356 126.65 356 114.5C356 102.35 365.85 92.5 378 92.5C390.15 92.5 400 102.35 400 114.5ZM359.3 114.5C359.3 124.828 367.672 133.2 378 133.2C388.328 133.2 396.7 124.828 396.7 114.5C396.7 104.172 388.328 95.8 378 95.8C367.672 95.8 359.3 104.172 359.3 114.5Z'
        fill={styles.text}
        stroke={styles.text}
        strokeWidth='2'
        mask='url(#path-21-inside-3_76_159829)'
      />
      <path
        d='M392.096 131.391C388.519 134.376 384.087 136.148 379.439 136.453C374.79 136.758 370.165 135.579 366.229 133.086C362.293 130.594 359.25 126.916 357.539 122.583C355.827 118.251 355.535 113.486 356.705 108.977C357.874 104.468 360.445 100.446 364.047 97.4911C367.648 94.5365 372.095 92.8013 376.746 92.5358C381.397 92.2702 386.012 93.4879 389.927 96.0134C393.841 98.5389 396.853 102.242 398.528 106.589L395.449 107.775C394.025 104.081 391.465 100.933 388.138 98.7864C384.81 96.6397 380.887 95.6047 376.934 95.8304C372.981 96.0561 369.201 97.531 366.14 100.042C363.078 102.554 360.893 105.972 359.899 109.805C358.905 113.638 359.153 117.688 360.608 121.371C362.063 125.054 364.649 128.179 367.995 130.298C371.34 132.417 375.271 133.419 379.223 133.16C383.174 132.901 386.941 131.395 389.981 128.857L392.096 131.391Z'
        fill={styles.primary}
      />
    </svg>
  )
}

export function GettingStartedSubscribeMarketDataMobileIcon() {
  return (
    <svg
      width='312'
      height='187'
      viewBox='0 0 312 187'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.78125' width='312' height='185.242' rx='16' fill={styles.background} />
      <rect x='143.66' y='131.508' width='24.6774' height='10.1613' fill='#BEBEBE' />
      <rect
        x='129.145'
        y='141.668'
        width='53.7097'
        height='4.35484'
        rx='2.17742'
        fill={styles.text}
      />
      <path
        d='M66 56.7813C66 47.9447 73.1634 40.7812 82 40.7812H230C238.837 40.7812 246 47.9447 246 56.7812V62.5554H66V56.7813Z'
        fill='#ECECEC'
      />
      <path
        d='M66 62.5547H246V115.506C246 124.343 238.837 131.506 230 131.506H82C73.1634 131.506 66 124.343 66 115.506V62.5547Z'
        fill={styles.background}
      />
      <rect
        x='67.5'
        y='42.2812'
        width='177'
        height='87.7258'
        rx='14.5'
        stroke={styles.text}
        strokeWidth='3'
      />
      <line
        x1='76.9336'
        y1='53.7969'
        x2='102.24'
        y2='53.7969'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M93.5312 114.09L93.5313 115.59L96.5313 115.59L96.5312 114.09L93.5312 114.09ZM96.5312 77.0737C96.5312 76.2453 95.8597 75.5737 95.0312 75.5737C94.2028 75.5737 93.5312 76.2453 93.5312 77.0737L96.5312 77.0737ZM96.5312 114.09L96.5312 77.0737L93.5312 77.0737L93.5312 114.09L96.5312 114.09Z'
        fill={styles.text}
      />
      <path
        d='M93.5312 114.09L93.5313 115.59L96.5313 115.59L96.5312 114.09L93.5312 114.09ZM96.5312 83.606C96.5312 82.7775 95.8597 82.106 95.0312 82.106C94.2028 82.106 93.5312 82.7775 93.5312 83.606L96.5312 83.606ZM96.5312 114.09L96.5312 83.606L93.5312 83.606L93.5312 114.09L96.5312 114.09Z'
        fill={styles.primary}
      />
      <path
        d='M86.2734 114.09L86.2734 115.59L89.2734 115.59L89.2734 114.09L86.2734 114.09ZM89.2734 77.0737C89.2734 76.2453 88.6019 75.5737 87.7734 75.5737C86.945 75.5737 86.2734 76.2453 86.2734 77.0737L89.2734 77.0737ZM89.2734 114.09L89.2734 77.0737L86.2734 77.0737L86.2734 114.09L89.2734 114.09Z'
        fill={styles.text}
      />
      <path
        d='M86.2734 114.09L86.2734 115.59L89.2734 115.59L89.2734 114.09L86.2734 114.09ZM89.2734 90.864C89.2734 90.0356 88.6019 89.364 87.7734 89.364C86.945 89.364 86.2734 90.0356 86.2734 90.864L89.2734 90.864ZM89.2734 114.09L89.2734 90.864L86.2734 90.864L86.2734 114.09L89.2734 114.09Z'
        fill={styles.primary}
      />
      <path
        d='M100.793 114.09L100.793 115.59L103.793 115.59L103.793 114.09L100.793 114.09ZM103.793 77.0737C103.793 76.2453 103.121 75.5737 102.293 75.5737C101.465 75.5737 100.793 76.2453 100.793 77.0737L103.793 77.0737ZM103.793 114.09L103.793 77.0737L100.793 77.0737L100.793 114.09L103.793 114.09Z'
        fill={styles.text}
      />
      <path
        d='M100.793 114.09L100.793 115.59L103.793 115.59L103.793 114.09L100.793 114.09ZM103.793 103.929C103.793 103.1 103.121 102.429 102.293 102.429C101.465 102.429 100.793 103.1 100.793 103.929L103.793 103.929ZM103.793 114.09L103.793 103.929L100.793 103.929L100.793 114.09L103.793 114.09Z'
        fill={styles.primary}
      />
      <path
        d='M108.051 114.09L108.051 115.59L111.051 115.59L111.051 114.09L108.051 114.09ZM111.051 77.0737C111.051 76.2453 110.379 75.5737 109.551 75.5737C108.722 75.5737 108.051 76.2453 108.051 77.0737L111.051 77.0737ZM111.051 114.09L111.051 77.0737L108.051 77.0737L108.051 114.09L111.051 114.09Z'
        fill={styles.text}
      />
      <path
        d='M108.051 114.09L108.051 115.59L111.051 115.59L111.051 114.09L108.051 114.09ZM111.051 87.235C111.051 86.4066 110.379 85.735 109.551 85.735C108.722 85.735 108.051 86.4066 108.051 87.235L111.051 87.235ZM111.051 114.09L111.051 87.235L108.051 87.235L108.051 114.09L111.051 114.09Z'
        fill={styles.primary}
      />
      <path
        d='M79.0156 114.09L79.0156 115.59L82.0156 115.59L82.0156 114.09L79.0156 114.09ZM82.0156 77.0737C82.0156 76.2453 81.3441 75.5737 80.5156 75.5737C79.6872 75.5737 79.0156 76.2453 79.0156 77.0737L82.0156 77.0737ZM82.0156 114.09L82.0156 77.0737L79.0156 77.0737L79.0156 114.09L82.0156 114.09Z'
        fill={styles.primary}
      />
      <mask id='path-17-inside-1_61558_322118' fill={styles.background}>
        <path d='M156.728 95.2177C156.728 104.036 149.579 111.185 140.761 111.185C131.942 111.185 124.793 104.036 124.793 95.2177C124.793 86.399 131.942 79.25 140.761 79.25C149.579 79.25 156.728 86.399 156.728 95.2177ZM127.188 95.2177C127.188 102.714 133.265 108.79 140.761 108.79C148.257 108.79 154.333 102.714 154.333 95.2177C154.333 87.7218 148.257 81.6452 140.761 81.6452C133.265 81.6452 127.188 87.7218 127.188 95.2177Z' />
      </mask>
      <path
        d='M156.728 95.2177C156.728 104.036 149.579 111.185 140.761 111.185C131.942 111.185 124.793 104.036 124.793 95.2177C124.793 86.399 131.942 79.25 140.761 79.25C149.579 79.25 156.728 86.399 156.728 95.2177ZM127.188 95.2177C127.188 102.714 133.265 108.79 140.761 108.79C148.257 108.79 154.333 102.714 154.333 95.2177C154.333 87.7218 148.257 81.6452 140.761 81.6452C133.265 81.6452 127.188 87.7218 127.188 95.2177Z'
        fill={styles.text}
        stroke={styles.text}
        strokeWidth='2'
        mask='url(#path-17-inside-1_61558_322118)'
      />
      <path
        d='M142.783 79.3785C139.938 79.0154 137.048 79.425 134.417 80.5642C131.785 81.7035 129.509 83.5303 127.827 85.8529C126.146 88.1755 125.12 90.9081 124.859 93.7638C124.598 96.6194 125.111 99.4926 126.344 102.082C127.576 104.671 129.483 106.88 131.865 108.478C134.246 110.075 137.013 111.002 139.877 111.161C142.74 111.32 145.593 110.705 148.136 109.38C150.679 108.056 152.819 106.071 154.331 103.634L152.295 102.371C151.01 104.443 149.192 106.13 147.03 107.256C144.868 108.382 142.443 108.904 140.009 108.77C137.576 108.635 135.223 107.847 133.199 106.489C131.175 105.131 129.554 103.253 128.506 101.052C127.458 98.8514 127.023 96.4091 127.245 93.9819C127.466 91.5546 128.338 89.2319 129.767 87.2577C131.197 85.2834 133.132 83.7306 135.368 82.7623C137.605 81.7939 140.062 81.4458 142.479 81.7544L142.783 79.3785Z'
        fill={styles.primary}
      />
      <mask id='path-19-inside-2_61558_322118' fill={styles.background}>
        <path d='M193.744 95.2177C193.744 104.036 186.595 111.185 177.776 111.185C168.958 111.185 161.809 104.036 161.809 95.2177C161.809 86.399 168.958 79.25 177.776 79.25C186.595 79.25 193.744 86.399 193.744 95.2177ZM164.204 95.2177C164.204 102.714 170.28 108.79 177.776 108.79C185.272 108.79 191.349 102.714 191.349 95.2177C191.349 87.7218 185.272 81.6452 177.776 81.6452C170.28 81.6452 164.204 87.7218 164.204 95.2177Z' />
      </mask>
      <path
        d='M193.744 95.2177C193.744 104.036 186.595 111.185 177.776 111.185C168.958 111.185 161.809 104.036 161.809 95.2177C161.809 86.399 168.958 79.25 177.776 79.25C186.595 79.25 193.744 86.399 193.744 95.2177ZM164.204 95.2177C164.204 102.714 170.28 108.79 177.776 108.79C185.272 108.79 191.349 102.714 191.349 95.2177C191.349 87.7218 185.272 81.6452 177.776 81.6452C170.28 81.6452 164.204 87.7218 164.204 95.2177Z'
        fill={styles.text}
        stroke={styles.text}
        strokeWidth='2'
        mask='url(#path-19-inside-2_61558_322118)'
      />
      <path
        d='M173.152 79.9343C170.983 80.5907 168.978 81.7014 167.271 83.1927C165.564 84.6839 164.194 86.5215 163.252 88.5832C162.31 90.6449 161.818 92.8835 161.809 95.1501C161.799 97.4167 162.272 99.6593 163.196 101.729C164.121 103.799 165.475 105.648 167.169 107.153C168.863 108.659 170.859 109.787 173.023 110.461C175.187 111.136 177.469 111.343 179.719 111.067C181.969 110.791 184.134 110.04 186.071 108.862L184.827 106.815C183.18 107.816 181.34 108.455 179.428 108.69C177.515 108.924 175.575 108.748 173.736 108.175C171.896 107.601 170.2 106.643 168.76 105.363C167.32 104.083 166.169 102.511 165.383 100.752C164.598 98.9931 164.196 97.0868 164.204 95.1602C164.212 93.2336 164.63 91.3308 165.431 89.5784C166.231 87.826 167.396 86.264 168.847 84.9964C170.298 83.7289 172.002 82.7847 173.846 82.2268L173.152 79.9343Z'
        fill={styles.primary}
      />
      <mask id='path-21-inside-3_61558_322118' fill={styles.background}>
        <path d='M231.486 95.2177C231.486 104.036 224.337 111.185 215.519 111.185C206.7 111.185 199.551 104.036 199.551 95.2177C199.551 86.399 206.7 79.25 215.519 79.25C224.337 79.25 231.486 86.399 231.486 95.2177ZM201.946 95.2177C201.946 102.714 208.023 108.79 215.519 108.79C223.014 108.79 229.091 102.714 229.091 95.2177C229.091 87.7218 223.014 81.6452 215.519 81.6452C208.023 81.6452 201.946 87.7218 201.946 95.2177Z' />
      </mask>
      <path
        d='M231.486 95.2177C231.486 104.036 224.337 111.185 215.519 111.185C206.7 111.185 199.551 104.036 199.551 95.2177C199.551 86.399 206.7 79.25 215.519 79.25C224.337 79.25 231.486 86.399 231.486 95.2177ZM201.946 95.2177C201.946 102.714 208.023 108.79 215.519 108.79C223.014 108.79 229.091 102.714 229.091 95.2177C229.091 87.7218 223.014 81.6452 215.519 81.6452C208.023 81.6452 201.946 87.7218 201.946 95.2177Z'
        fill={styles.text}
        stroke={styles.text}
        strokeWidth='2'
        mask='url(#path-21-inside-3_61558_322118)'
      />
      <path
        d='M225.749 107.477C223.153 109.644 219.937 110.93 216.563 111.151C213.189 111.372 209.832 110.517 206.975 108.708C204.118 106.899 201.91 104.229 200.668 101.085C199.425 97.9399 199.213 94.482 200.062 91.2091C200.911 87.9361 202.777 85.0171 205.391 82.8726C208.005 80.7281 211.233 79.4687 214.608 79.276C217.984 79.0832 221.334 79.967 224.175 81.8001C227.016 83.6331 229.202 86.3207 230.418 89.4758L228.183 90.3371C227.15 87.6553 225.292 85.3708 222.877 83.8127C220.461 82.2546 217.614 81.5034 214.745 81.6672C211.875 81.8311 209.132 82.9015 206.91 84.7244C204.688 86.5472 203.102 89.0284 202.381 91.8104C201.659 94.5924 201.839 97.5316 202.895 100.205C203.951 102.878 205.828 105.146 208.257 106.684C210.685 108.222 213.538 108.949 216.406 108.761C219.274 108.573 222.008 107.48 224.215 105.638L225.749 107.477Z'
        fill={styles.primary}
      />
    </svg>
  )
}
