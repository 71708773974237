import React, { useCallback } from 'react'

import { Loading } from '../../../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../../../global/context/SessionSettingsContext'
import { useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { useFetchOne } from '../../../../../utils/useFetch'
import { CompanyDetailsStep4Form, CompanyDetailsStep4FormValues } from './CompanyDetailsStep4Form'

interface CompanyDetailsStep4PageProps {
  onSubmit: (values: CompanyDetailsStep4FormValues) => Promise<void>
}

export const CompanyDetailsStep4Page: React.FC<CompanyDetailsStep4PageProps> = (props) => {
  const { onSubmit } = props

  const locale = useSessionLanguage()

  const apiClient = useApiClient(ClientApiClient)

  const getEMIRTestQuestionsCallback = useCallback(
    () => apiClient.getEMIRTestQuestions(locale),
    [apiClient, locale]
  )

  const emirSections = useFetchOne(getEMIRTestQuestionsCallback)

  return (
    <Loading isLoading={emirSections.isLoading}>
      <CompanyDetailsStep4Form
        sections={emirSections.data?.sections}
        testId={emirSections.data?.id}
        onSubmit={onSubmit}
      />
    </Loading>
  )
}
