import styles from './Icons.module.scss'

export function GettingStartedTransferIcon() {
  return (
    <svg
      width='592'
      height='296'
      viewBox='0 0 592 296'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='592' height='296' rx='16' fill={styles.background} />
      <path
        d='M168 128H220C224.418 128 228 131.582 228 136V172C228 176.418 224.418 180 220 180H164C159.582 180 156 176.418 156 172V124C156 119.582 159.582 116 164 116H216'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path d='M214 146V158' stroke={styles.text} strokeWidth='3' strokeLinecap='round' />
      <path
        d='M277.332 136.267H314.665M314.665 136.267L305.332 126.934M314.665 136.267L305.332 145.6'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M293.332 161.333H330.665M330.665 161.333L321.332 152M330.665 161.333L321.332 170.667'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <ellipse
        cx='408.001'
        cy='131.333'
        rx='13.3333'
        ry='13.3333'
        stroke={styles.text}
        strokeWidth='3'
      />
      <path
        d='M381.332 178C381.332 178 382.953 172.937 385.142 165.778C386.617 160.95 393.313 159.096 399.665 158.401L406.332 158.001'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M434.559 159.515C433.861 156.954 430.559 155.404 427.997 155.425M427.997 155.425C424.717 155.45 420.618 158.941 421.438 162.243C422.794 167.702 433.204 164.965 434.559 170.424C435.379 173.726 431.278 177.217 427.997 177.243M427.997 155.425L427.893 151.333M427.997 177.243C425.435 177.263 422.137 175.713 421.438 173.152M427.997 177.243V181.333'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}
