import styles from './Icons.module.scss'

export function GettingStartedCreateAccountIcon(): JSX.Element {
  return (
    <svg
      width='592'
      height='272'
      viewBox='0 0 592 272'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='592' height='272' rx='16' fill={styles.background} />
      <rect
        x='224'
        y='64'
        width='144'
        height='144'
        rx='72'
        stroke={styles.primary}
        strokeWidth='16'
        stroke-dasharray='3 45'
      />
      <ellipse
        cx='296.001'
        cy='119.333'
        rx='13.3333'
        ry='13.3333'
        stroke={styles.text}
        strokeWidth='3'
      />
      <path
        d='M269.332 166C269.332 166 270.953 160.937 273.142 153.778C274.617 148.95 281.313 147.096 287.665 146.401L294.332 146.001'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M322.559 147.515C321.861 144.953 318.559 143.404 315.997 143.424M315.997 143.424C312.717 143.45 308.618 146.94 309.438 150.242C310.794 155.702 321.204 152.965 322.559 158.424C323.379 161.726 319.278 165.216 315.997 165.242M315.997 143.424L315.893 139.333M315.997 165.242C313.435 165.262 310.137 163.713 309.438 161.151M315.997 165.242V169.333'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}
