import { Dispatch, ReactNode, createContext, useCallback, useState } from 'react'

import { MaybeAuthSession } from '../../utils/AuthContext'
import { TickmillCompaniesEnum, getTickmillCompanyByHostname } from '../../utils/companyName.utils'
import { useGuardedContext } from '../useGuardedContext'

export interface EntitySettings {
  entity: TickmillCompaniesEnum
}

export function restoreSession(auth: MaybeAuthSession): EntitySettings {
  return {
    entity: auth?.tickmillCompany?.id || getTickmillCompanyByHostname(),
  }
}

type EntitySettingsContextType = [EntitySettings, Dispatch<Partial<EntitySettings>>] | undefined

const EntitySettingsContext = createContext<EntitySettingsContextType>(undefined)
EntitySettingsContext.displayName = 'EntitySettingsContextType'

export function useEntitySettings() {
  return useGuardedContext(EntitySettingsContext)
}

export function useSessionEntity(): TickmillCompaniesEnum {
  const [entity] = useEntitySettings()
  return entity.entity
}

interface Props {
  initialSettings: EntitySettings
  children: ReactNode
}

export function EntitySettingsContextProvider({ initialSettings, children }: Props) {
  const [entitySettings, _setEntitySettings] = useState(initialSettings)

  const setSettings = useCallback(
    (entity: Partial<EntitySettings>) => {
      _setEntitySettings({ ...entitySettings, ...entity })
    },
    [entitySettings]
  )

  return (
    <EntitySettingsContext.Provider value={[entitySettings, setSettings]}>
      {children}
    </EntitySettingsContext.Provider>
  )
}
