import React from 'react'
import { useTranslation } from 'react-i18next'
import { FormikProps, withFormik } from 'formik'

import { createFormCheckBoxField } from '../../global/formField/FormCheckBoxField'
import { Text } from '../../ui/Typography/Typography'
import { FormSubmitValues } from '../../utils/formValidation'

import styles from './AccountSettingsPage.module.scss'

export interface ChangeEmailFormValues {
  newsletterChoice: boolean
}

const FormCheckBoxField = createFormCheckBoxField<ChangeEmailFormValues>()

const ChangeEmailFormUI: React.FC<FormikProps<ChangeEmailFormValues> & OuterProps> = (props) => {
  const { handleSubmit, setSubmitting, setFieldValue, isSubmitting } = props
  const { t } = useTranslation()

  return (
    <div className={styles.form}>
      <div className='is-flex is-justify-content-space-between is-align-items-center'>
        <Text className='field text-primary'>
          {t("Profile.Register to Tickmill's newsletters")}
        </Text>
        <FormCheckBoxField
          onChange={(e) => {
            setFieldValue('newsletterChoice', e.target.checked)
            setSubmitting(true)
            handleSubmit()
          }}
          disabled={isSubmitting}
          name='newsletterChoice'
        />
      </div>
    </div>
  )
}

interface OuterProps {
  newsletterChoice?: boolean

  onSubmit(values: FormSubmitValues<ChangeEmailFormValues>): Promise<void>
}

export const ChangeEmailForm = withFormik<OuterProps, ChangeEmailFormValues>({
  mapPropsToValues: ({ newsletterChoice = false }) => {
    return {
      newsletterChoice,
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(values)
    } finally {
      setSubmitting(false)
    }
  },
  enableReinitialize: true,
})(ChangeEmailFormUI)
