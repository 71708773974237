import { TickmillCompaniesEnum } from '../utils/companyName.utils'
import { NameDto } from './NameDto'

export interface AuthUser {
  accessToken: string
  dateFormatType: NameDto<number>
  email: string
  expires: number
  firstName: string
  forceUpdateCredentials: boolean
  id: string
  kind: 'client'
  lastName: string
  refreshToken: string
  roles: []
  sessionId: string
  teams: []
  tickmillCompany: {
    shortName: string
    id: TickmillCompaniesEnum
    name: string
  }
  twoFactor: null
}

export enum TwoFactorProviders {
  Email = 1,
  SMS = 2,
  Google = 3,
}

export interface TwoFactor {
  token: string
  provider: NameDto<TwoFactorProviders>
  authMedium: string
  remainingResendCodeAttemptsCount: number
}

export interface LoginResponse {
  accessToken: string | null
  dateFormatType: NameDto<number> | null
  email: string
  expires: number
  firstName: string | null
  lastName: string | null
  forceUpdateCredentials: boolean
  id: string
  kind: 'client' | null
  refreshToken: string | null
  roles: [] | null
  sessionId: string | null
  teams: [] | null
  departments: [] | null
  trustDeviceToken: string | null
  tickmillCompany: {
    shortName: string
    id: TickmillCompaniesEnum
    name: string
  } | null
  twoFactor: TwoFactor[] | null
}

export interface TrustDeviceResponse {
  id: string
  isDeviceTrusted: boolean
  trustUserDeviceToken: string
  message: string | null
}
