import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { CancelActionModal } from './CancleActionModal'

interface InformationContactModalProps {
  title?: string
  onConfirm?(): void
  onCancel(): void
  onBack?(): void

  className?: string
}

const InformationModalBody: React.FC<PropsWithChildren> = ({ children }) => {
  return <p>{children}</p>
}

export const InformationContactModal: React.FC<PropsWithChildren<InformationContactModalProps>> = (
  props
) => {
  const { onConfirm, onCancel, onBack, title, children, className } = props

  const { t } = useTranslation()

  const handleContactSupport = () => {
    onConfirm?.()
    onCancel()
  }

  return (
    <CancelActionModal
      title={title}
      footer={
        <React.Fragment>
          <button className='button' onClick={handleContactSupport} type='button'>
            {t('Contact Support')}
          </button>
          <button className='button' type='button' onClick={onCancel}>
            {t('Got It')}
          </button>
        </React.Fragment>
      }
      onBack={onBack}
      className={className}
    >
      <InformationModalBody>{children}</InformationModalBody>
    </CancelActionModal>
  )
}
