export enum NavTabsType {
  TradersRoom,
  IB,
  PA,
  Tools,
  Learning,
  Subscriptions,
  Statements = 6,
}

export type NavTabType = NavTabsType | undefined

export const isTradersRoomType = (value: NavTabType): value is NavTabsType.TradersRoom => {
  return value === NavTabsType.TradersRoom
}

export const isSubscriptionsType = (value: NavTabType): value is NavTabsType.Subscriptions => {
  return value === NavTabsType.Subscriptions
}

export const isStatementsType = (value: NavTabType): value is NavTabsType.Statements => {
  return value === NavTabsType.Statements
}

export const isIBType = (value: NavTabType): value is NavTabsType.IB => {
  return value === NavTabsType.IB
}

export const isPAType = (value: NavTabType): value is NavTabsType.PA => {
  return value === NavTabsType.PA
}

export const isToolsType = (value: NavTabType): value is NavTabsType.Tools => {
  return value === NavTabsType.Tools
}

export const isLearningType = (value: NavTabType): value is NavTabsType.Learning => {
  return value === NavTabsType.Learning
}
