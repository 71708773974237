import React from 'react'
import { useNavigate } from 'react-router-dom'

import { WalletWithdrawTransactionSuccessPage } from './WalletWithdrawTransactionSuccessPage'

export const TradersRoomWalletWithdrawTransactionSuccessPage: React.FC = (props) => {
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/dashboard/traders-room/wallets')
  }

  return <WalletWithdrawTransactionSuccessPage {...props} onCancel={handleCancel} />
}
