import { NameDto } from './NameDto'

export enum DocumentCategoryType {
  None = 0,
  Personal = 1,
  Address = 2,
  Corporate = 3,
  Additional = 7,
  Internal = 8,
  AppropriatenessTest = 9,
  PaymentAgent = 10,
  Migrated = 11,
  Statements = 12,
  Payment = 13,
}

export const DocumentCategoryLinkMap: { [key in DocumentCategoryType]: string } = {
  [DocumentCategoryType.None]: '',
  [DocumentCategoryType.Personal]: 'identification',
  [DocumentCategoryType.Address]: 'proof-of-address',
  [DocumentCategoryType.Corporate]: 'company-proof',
  [DocumentCategoryType.Additional]: 'additional',
  [DocumentCategoryType.Internal]: '',
  [DocumentCategoryType.AppropriatenessTest]: '',
  [DocumentCategoryType.PaymentAgent]: '',
  [DocumentCategoryType.Migrated]: '',
  [DocumentCategoryType.Statements]: '',
  [DocumentCategoryType.Payment]: 'payments',
}

export interface DocumentCategorySizeDto extends NameDto<DocumentCategoryType> {
  maxUploadFileSizeInMb: number
}

export const isDocumentCategoryPersonalType = (value: number): boolean => {
  return value === DocumentCategoryType.Personal
}

export const isDocumentCategoryAddressType = (value: number): boolean => {
  return value === DocumentCategoryType.Address
}

export const isDocumentCategoryCorporateType = (value: number): boolean => {
  return value === DocumentCategoryType.Corporate
}

export const isDocumentCategoryAdditionalType = (value: number): boolean => {
  return value === DocumentCategoryType.Additional
}

export const isDocumentCategoryPaymentsType = (value: number): boolean => {
  return value === DocumentCategoryType.Payment
}

export const isDocumentCategoryAppropriatenessTestType = (value: number): boolean => {
  return value === DocumentCategoryType.AppropriatenessTest
}
