import { ReactNode, createContext, useContext } from 'react'

import { IntroducingBrokerDetailsDto } from '../model/IntroducingBrokerDetailsDto'

const IntroducingBrokerContext = createContext<IntroducingBrokerDetailsDto | null>(null)
IntroducingBrokerContext.displayName = 'IntroducingBrokerContext'

export function useIntroducingBroker(): IntroducingBrokerDetailsDto | null {
  return useContext(IntroducingBrokerContext)
}

interface Props {
  introducingBroker: IntroducingBrokerDetailsDto | null
  children: ReactNode
}

export function IntroducingBrokerContextProvider({
  introducingBroker,
  children,
}: Props): JSX.Element {
  return (
    <IntroducingBrokerContext.Provider value={introducingBroker}>
      {children}
    </IntroducingBrokerContext.Provider>
  )
}
