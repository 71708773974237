import {
  isCampaignCalculationAccountType,
  isCampaignCalculationTiersType,
} from './CampaignCalculationType'
import {
  isCampaignClientContestType,
  isCampaignClientRebateType,
  isCampaignLiveRebateAccountType,
} from './CampaignType'
import { ClientRebateCampaignDetailDto } from './ClientRebateCampaignDetailDto'
import { NameDto } from './NameDto'
import { CurrencyType } from './WalletDto'

export interface ClientRebateCampaignDto {
  id: string
  name: string | null
  from: string
  to: string
  currency: NameDto<CurrencyType>
  status: NameDto
  account: string | null
  type: NameDto
  disqualified: boolean
  calculationType: NameDto
}

export const isCampaignClientRebate = (
  campaign?: ClientRebateCampaignDto | undefined
): campaign is ClientRebateCampaignDto => {
  return isCampaignClientRebateType(campaign?.type?.id)
}

export const isCalculationTiers = (campaignDetails?: ClientRebateCampaignDetailDto | undefined) => {
  return isCampaignCalculationTiersType(campaignDetails?.calculationType.id)
}

export const isCalculationAccount = (
  campaignDetails?: ClientRebateCampaignDetailDto | undefined
) => {
  return isCampaignCalculationAccountType(campaignDetails?.calculationType.id)
}

export const isCampaignClientContest = (
  campaign?: ClientRebateCampaignDto | undefined
): campaign is ClientRebateCampaignDto => {
  return isCampaignClientContestType(campaign?.type?.id)
}

export const isCampaignLiveRebateAccount = (
  campaign?: ClientRebateCampaignDto | undefined
): campaign is ClientRebateCampaignDto => {
  return isCampaignLiveRebateAccountType(campaign?.type?.id)
}
