import { FC } from 'react'

import '../../icons/Icons.module.scss'

export const TMEuropeLogo: FC = () => (
  <svg width='160' height='40' viewBox='0 0 160 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M70.7879 21.9512C72.7758 21.9512 74.4243 21.1754 75.6849 19.5754L78.3031 22.2421C76.2182 24.5694 73.794 25.733 71.0303 25.733C68.2182 25.733 65.9394 24.8603 64.097 23.0664C62.3031 21.3209 61.3818 19.0421 61.3818 16.3754C61.3818 13.6603 62.3031 11.43 64.1455 9.63605C65.9879 7.84211 68.2667 6.9209 70.9334 6.9209C73.9394 6.9209 76.4121 8.03605 78.4485 10.3148L75.9273 13.1754C74.6667 11.5754 73.0667 10.7997 71.1273 10.7997C69.6243 10.7997 68.3152 11.2845 67.2 12.3027C66.1334 13.3209 65.5515 14.63 65.5515 16.327C65.5515 18.0239 66.0849 19.3815 67.1031 20.3997C68.1212 21.4179 69.3334 21.9512 70.7879 21.9512Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M45.8181 25.5391V10.8481H50.9575V7.35724H36.606V10.8481H41.7454V25.5391H45.8181Z'
      fill='var(--logo-text-color)'
    />
    <path d='M57.9878 7.35724H53.915V25.5391H57.9878V7.35724Z' fill='var(--logo-text-color)' />
    <path
      d='M85.5757 7.35724H81.5029V25.5391H85.5757V20.3997L87.4181 18.3633L92.7514 25.5391H97.5029C97.0518 24.9022 96.47 24.0911 95.7575 23.0978L95.7517 23.0897C95.1637 22.2699 94.4868 21.3262 93.7211 20.2542C92.2209 18.0922 91.1223 16.5726 90.4617 15.6589L90.2787 15.4057L97.5029 7.35724H92.4605L85.5757 14.8239V7.35724Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M116.266 14.0966L111.37 24.036H108.945L104.048 14.0966V25.5391H99.9756V7.35724H105.454L110.157 17.3451L114.86 7.35724H120.339V25.5391H116.266V14.0966Z'
      fill='var(--logo-text-color)'
    />
    <path d='M128.873 7.35724H124.8V25.5391H128.873V7.35724Z' fill='var(--logo-text-color)' />
    <path
      d='M133.333 25.5391V7.35724H137.406V21.9027H145.163V25.5391H133.333Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M148.121 7.35724V25.5391H159.951V21.9027H152.194V7.35724H148.121Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M7.70909 12.0121C7.41818 12.2061 7.17576 12.6424 7.17576 12.9818H7.27273V18.9939L0 23.1636V9.58788C0 8.71515 0.630303 7.64849 1.35758 7.21212L12.9939 0.327273C13.7212 -0.109091 14.9333 -0.109091 15.6606 0.327273L27.297 7.21212C27.3455 7.21212 27.3939 7.26061 27.4424 7.30909L20.0727 11.5758L14.8364 8.52121C14.497 8.32727 14.0121 8.32727 13.7212 8.52121L7.70909 12.0121Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M21.3336 19.9152V13.9031L28.5093 9.7334V23.3092C28.5093 24.1819 27.879 25.2486 27.1517 25.6849L15.5154 32.5698C14.7881 33.0061 13.576 33.0061 12.8487 32.5698L1.21235 25.6849C1.16387 25.6849 1.11539 25.6364 1.0669 25.588L8.43659 21.3213L13.673 24.3758C14.0124 24.5698 14.4972 24.5698 14.7881 24.3758L20.8002 20.8849C21.0911 20.691 21.3336 20.2546 21.3336 19.9152Z'
      fill='var(--logo-custom-color)'
    />
    <path
      d='M126.135 38.2334V39H122.37V38.2334H126.135ZM122.561 31.8906V39H121.618V31.8906H122.561ZM125.637 34.9473V35.7139H122.37V34.9473H125.637ZM126.086 31.8906V32.6621H122.37V31.8906H126.086Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M131.769 31.8906H132.707V36.7002C132.707 37.234 132.588 37.6784 132.35 38.0332C132.113 38.388 131.797 38.6549 131.403 38.834C131.012 39.0098 130.587 39.0977 130.129 39.0977C129.647 39.0977 129.211 39.0098 128.82 38.834C128.433 38.6549 128.125 38.388 127.897 38.0332C127.672 37.6784 127.56 37.234 127.56 36.7002V31.8906H128.493V36.7002C128.493 37.0713 128.561 37.3773 128.698 37.6182C128.835 37.859 129.025 38.0381 129.269 38.1553C129.517 38.2725 129.803 38.3311 130.129 38.3311C130.457 38.3311 130.744 38.2725 130.988 38.1553C131.235 38.0381 131.427 37.859 131.564 37.6182C131.701 37.3773 131.769 37.0713 131.769 36.7002V31.8906Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M134.586 31.8906H136.94C137.474 31.8906 137.924 31.972 138.292 32.1348C138.663 32.2975 138.945 32.5384 139.137 32.8574C139.332 33.1732 139.43 33.5622 139.43 34.0244C139.43 34.3499 139.363 34.6478 139.23 34.918C139.099 35.1849 138.911 35.4128 138.663 35.6016C138.419 35.7871 138.126 35.9255 137.784 36.0166L137.521 36.1191H135.309L135.299 35.3525H136.969C137.307 35.3525 137.589 35.2939 137.814 35.1768C138.038 35.0563 138.208 34.8952 138.321 34.6934C138.435 34.4915 138.492 34.2686 138.492 34.0244C138.492 33.751 138.439 33.5117 138.331 33.3066C138.224 33.1016 138.055 32.9437 137.823 32.833C137.596 32.7191 137.301 32.6621 136.94 32.6621H135.529V39H134.586V31.8906ZM138.741 39L137.013 35.7773L137.994 35.7725L139.747 38.9414V39H138.741Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M146.622 35.2207V35.6699C146.622 36.2038 146.555 36.6823 146.422 37.1055C146.288 37.5286 146.096 37.8883 145.846 38.1846C145.595 38.4808 145.294 38.707 144.942 38.8633C144.594 39.0195 144.203 39.0977 143.77 39.0977C143.35 39.0977 142.965 39.0195 142.613 38.8633C142.265 38.707 141.962 38.4808 141.705 38.1846C141.451 37.8883 141.254 37.5286 141.114 37.1055C140.974 36.6823 140.904 36.2038 140.904 35.6699V35.2207C140.904 34.6868 140.972 34.21 141.109 33.79C141.249 33.3669 141.446 33.0072 141.7 32.7109C141.954 32.4115 142.255 32.1836 142.603 32.0273C142.955 31.8711 143.341 31.793 143.761 31.793C144.193 31.793 144.584 31.8711 144.932 32.0273C145.284 32.1836 145.585 32.4115 145.836 32.7109C146.09 33.0072 146.283 33.3669 146.417 33.79C146.554 34.21 146.622 34.6868 146.622 35.2207ZM145.689 35.6699V35.2109C145.689 34.7878 145.645 34.4134 145.557 34.0879C145.473 33.7624 145.347 33.4889 145.181 33.2676C145.015 33.0462 144.812 32.8786 144.571 32.7646C144.333 32.6507 144.063 32.5938 143.761 32.5938C143.468 32.5938 143.202 32.6507 142.965 32.7646C142.73 32.8786 142.528 33.0462 142.359 33.2676C142.193 33.4889 142.065 33.7624 141.973 34.0879C141.882 34.4134 141.837 34.7878 141.837 35.2109V35.6699C141.837 36.0964 141.882 36.474 141.973 36.8027C142.065 37.1283 142.195 37.4033 142.364 37.6279C142.537 37.8493 142.74 38.0169 142.974 38.1309C143.212 38.2448 143.477 38.3018 143.77 38.3018C144.076 38.3018 144.348 38.2448 144.586 38.1309C144.823 38.0169 145.024 37.8493 145.186 37.6279C145.352 37.4033 145.478 37.1283 145.562 36.8027C145.647 36.474 145.689 36.0964 145.689 35.6699Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M151.055 36.2119H149.156V35.4453H151.055C151.423 35.4453 151.721 35.3867 151.949 35.2695C152.176 35.1523 152.343 34.9896 152.447 34.7812C152.554 34.5729 152.608 34.3353 152.608 34.0684C152.608 33.8242 152.554 33.5947 152.447 33.3799C152.343 33.165 152.176 32.9925 151.949 32.8623C151.721 32.7288 151.423 32.6621 151.055 32.6621H149.375V39H148.433V31.8906H151.055C151.592 31.8906 152.046 31.9834 152.417 32.1689C152.788 32.3545 153.07 32.6117 153.262 32.9404C153.454 33.266 153.55 33.6387 153.55 34.0586C153.55 34.5143 153.454 34.9033 153.262 35.2256C153.07 35.5479 152.788 35.7936 152.417 35.9629C152.046 36.1289 151.592 36.2119 151.055 36.2119Z'
      fill='var(--logo-text-color)'
    />
    <path
      d='M159.658 38.2334V39H155.894V38.2334H159.658ZM156.084 31.8906V39H155.142V31.8906H156.084ZM159.16 34.9473V35.7139H155.894V34.9473H159.16ZM159.609 31.8906V32.6621H155.894V31.8906H159.609Z'
      fill='var(--logo-text-color)'
    />
  </svg>
)
