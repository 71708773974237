import { useTranslation } from 'react-i18next'

import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { Modal } from '../../global/modal/Modal'

interface Props {
  handleOnCancelPendingSubscription(): void
  handleOnCloseCancelConfirmationModal(): void
}
export const ConfirmCancelSubscriptionModal: React.FC<Props> = ({
  handleOnCancelPendingSubscription,
  handleOnCloseCancelConfirmationModal,
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      closeModal={handleOnCloseCancelConfirmationModal}
      render={() => (
        <CancelActionModal
          title={t('Subscriptions.Cancel Order?')}
          cancelLabel={t('Subscriptions.Keep Order')}
          confirmLabel={t('Subscriptions.Yes, cancel')}
          onConfirm={handleOnCancelPendingSubscription}
          onCancel={handleOnCloseCancelConfirmationModal}
        >
          <p className='text'>{t('Subscriptions.Do you really like to cancel the order?')}</p>
        </CancelActionModal>
      )}
    />
  )
}
