import React, { useState } from 'react'

import {
  FilterOptions,
  FilterQueryModal,
  FilterQueryProps,
} from '../../global/filter/FilterQueryModal'
import { FilterOptionsDto } from '../../model/FilterOptionsDto'
import { SharedState } from '../../utils/SharedContext'
import { IDateFilter } from '../../utils/filter.utils'

interface Props {
  currentQuery?: FilterQueryProps
  sharedState: SharedState
  onCancel(): void
  filterOptions?: FilterOptionsDto
  onConfirm({
    searchFilters,
    currentFilter,
  }: {
    searchFilters?: FilterQueryProps
    currentFilter?: IDateFilter
  }): void
}

export const PaymentAgentTransactionFilterModal: React.FC<Props> = ({
  onConfirm,
  currentQuery,
  filterOptions,
  sharedState,
}) => {
  const [searchFilters, setSearchFilters] = useState<FilterQueryProps | undefined>(currentQuery)

  return (
    <FilterQueryModal
      searchFilters={searchFilters}
      setSearchFilters={setSearchFilters}
      onConfirm={onConfirm}
      transactionTypes={filterOptions?.transactionTypes}
      transactionStates={sharedState.transactionStates}
      filterOptions={[
        FilterOptions.TransactionStatus,
        FilterOptions.TransactionType,
        FilterOptions.Date,
        FilterOptions.ClientIdAmount,
      ]}
    />
  )
}
