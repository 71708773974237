import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import styles from '../../global/modal/CancelActionModal.module.scss'

interface Props {
  email?: string
  onCancel: () => void
}

export const TradingAccountPasswordResetMessageModal: React.FC<Props> = ({ onCancel, email }) => {
  const { t } = useTranslation()
  return (
    <>
      <header className='modal-card-head'>
        <p className='modal-card-title'>{t('Trading Account Password Reset Successfully')}</p>
      </header>
      <section className={classNames(styles.body, 'modal-card-body')}>
        <p>
          {t('Trading Account.A new trading account password has been sent to')} {email}
        </p>
      </section>
      <footer className='modal-card-foot'>
        <button className='button' onClick={onCancel}>
          {t('Got It')}
        </button>
      </footer>
    </>
  )
}
