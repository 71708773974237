import { IconProps } from './IconProps'

import styles from './Icons.module.scss'

export function WithdrawIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_17114_78518)'>
        <ellipse
          cx='12'
          cy='11.25'
          rx='2'
          ry='2.5'
          stroke={props.color ? styles[props.color] : styles.contrastMediumLow}
          strokeWidth={props.strokeWidth || '0.9'}
        />
        <path
          d='M16.5 16.4974L20 16.4975C20.5523 16.4975 21 16.0498 21 15.4975V6.49756C21 5.94527 20.5523 5.49756 20 5.49756H4C3.44772 5.49756 3 5.94527 3 6.49756V15.498C3 16.05 3.44737 16.4977 3.99943 16.498L7.5 16.5'
          stroke={props.color ? styles[props.color] : styles.contrastMediumLow}
          strokeWidth={props.strokeWidth || '0.9'}
          strokeLinecap='round'
        />
        <path
          d='M11.9998 23.125L8.74976 19.875M11.9998 23.125L15.2498 19.875M11.9998 23.125L11.9995 17'
          stroke={props.color ? styles[props.color] : styles.contrastMediumLow}
          strokeWidth={props.strokeWidth || '0.9'}
          strokeLinecap='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_17114_78518'>
          <rect width={size} height={size} fill={styles.white} />
        </clipPath>
      </defs>
    </svg>
  )
}
