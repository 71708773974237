import React, { PropsWithChildren } from 'react'

interface TooltipTooltipWrapperProps {
  title: string
}

export const TooltipWrapper: React.FC<PropsWithChildren<TooltipTooltipWrapperProps>> = (props) => {
  const { title, children } = props

  return (
    <span
      data-tooltip={title}
      className='has-tooltip-arrow has-tooltip-multiline has-tooltip-white'
    >
      {children}
    </span>
  )
}
