import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../../global/Loading/Loading'
import { Button } from '../../../global/button/Button'
import { useSessionEntity } from '../../../global/context/EntityContext'
import IconButton from '../../../global/iconButton/IconButton'
import { Table } from '../../../global/table/Table'
import { useFormatNumber } from '../../../hooks/useFormatNumber'
import { DotsIcon } from '../../../icons/DotsIcon'
import { NameDto } from '../../../model/NameDto'
import { CurrencyBalance } from '../../../model/TradingAccount'
import { CurrencyType } from '../../../model/WalletDto'
import { AlertIconWrapper } from '../../../ui/AlertIconWrapper/AlertIconWrapper'
import { isTickmillEUType } from '../../../utils/companyName.utils'
import { AppENV, enableByEnv } from '../../../utils/config'
import { TradingAccountRestrictions, isRestricted } from '../../../utils/trading-account.utils'

interface TradingAccountsTableProps {
  balances?: CurrencyBalance[]
  restrictions?: NameDto[]
  isLoading?: boolean
  handleSetSelectedTradingAccount(currency: CurrencyType): void
  initiateTransfer: (currency: CurrencyType) => void
  setModalVisible(v: boolean): void
}

const getColumnSpan = (columns: number, isEUEntity: boolean) => {
  let fixedColumnCount = columns
  if (isEUEntity) {
    fixedColumnCount--
  }
  if (!enableByEnv([AppENV.development])) {
    fixedColumnCount--
  }
  return fixedColumnCount
}

export const TradingAccountsTable: React.FC<TradingAccountsTableProps> = (props) => {
  const {
    balances = [],
    handleSetSelectedTradingAccount,
    isLoading,
    initiateTransfer,
    restrictions = [],
    setModalVisible,
  } = props
  const { t } = useTranslation()
  const entity = useSessionEntity()
  const { formatMoney } = useFormatNumber()

  const [showMore, setShoreMore] = useState(false)

  const getBalances = useMemo(() => {
    if (!balances?.length) {
      return []
    }
    if (showMore) {
      return balances
    }
    return balances.filter((x, index) => index <= 2)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balances.length, showMore])

  const isTransferFromRestricted = isRestricted(
    TradingAccountRestrictions.TRANSFER_FROM,
    restrictions
  )

  const handleRestrictionModalOpen = () => {
    if (isTransferFromRestricted) {
      setModalVisible(true)
    }
  }

  return (
    <React.Fragment>
      <Table size='small'>
        <Loading showLoadingIcon isLoading={!!isLoading}>
          <thead>
            <tr>
              <th>{t('Trading Account.Currency')}</th>
              <th>{t('Trading Account.Balance')}</th>
              <th />
              <th />
            </tr>
          </thead>
          <tbody>
            {!getBalances ? (
              <td align='center' colSpan={getColumnSpan(11, isTickmillEUType(entity))}>
                {t('No results')}
              </td>
            ) : (
              getBalances.map((tradingAccount) => (
                <tr key={tradingAccount.currency}>
                  <td>{tradingAccount.currency} </td>
                  <td>{formatMoney(tradingAccount.balance, tradingAccount.currency)}</td>
                  <td>
                    {isTransferFromRestricted && (
                      <AlertIconWrapper spaced isActive={isTransferFromRestricted}>
                        <Button
                          state='disabled'
                          size='XS'
                          appearance='secondary'
                          onClick={handleRestrictionModalOpen}
                        >
                          {t('Transfer')}
                        </Button>
                      </AlertIconWrapper>
                    )}
                    {!isTransferFromRestricted && (
                      <Button
                        size='XS'
                        appearance='secondary'
                        onClick={() => initiateTransfer(tradingAccount.currency)}
                      >
                        {t('Transfer')}
                      </Button>
                    )}
                  </td>
                  <td width={50}>
                    <IconButton
                      appearance='hoverable'
                      onClick={() => handleSetSelectedTradingAccount(tradingAccount.currency)}
                    >
                      <DotsIcon />
                    </IconButton>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Loading>
      </Table>
      {balances.length > 3 && (
        <div className='text-align-center pt-5'>
          <Button appearance='link' onClick={() => setShoreMore((prevState) => !prevState)}>
            {showMore ? t('Show less Currencies') : t('Show more Currencies')}
          </Button>
        </div>
      )}
    </React.Fragment>
  )
}
