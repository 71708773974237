import React from 'react'

import { isFunction } from './isFunction'

type HasChildrenProp<T> = T extends {
  children: (props: any) => React.ReactNode
}
  ? T
  : never

export const hasChildren = <T extends {}>(value: T): value is HasChildrenProp<T> => {
  return 'children' in value && isFunction((value as HasChildrenProp<T>)?.children)
}
