import React, { Suspense, useCallback, useEffect, useRef } from 'react'
import { Outlet } from 'react-router-dom'

import { Loading } from '../global/Loading/Loading'
import { LocaleModule } from '../global/LocaleModule'
import { SessionTimeout } from '../global/SessionTimeout/SessionTimeout'
import { StatusBanner } from '../global/StatusBanner/StatusBanner'
import { AppFooter } from '../global/footer/Footer'
import { MaintenanceMessage } from '../global/maintenanceMessage/MaintenanceMessage'
import { MobileTabs } from '../global/mobileTabs/MobileTabs'
import { Nav } from '../global/nav/Nav'
import { NotificationBlock } from '../global/notification/NotificationBlock'
import { PlatformNotification } from '../global/platformNotification/PlatformNotification'
import { Toast, ToastBanner } from '../global/toast/Toast'
import { useModalScrollHandler } from '../hooks/useModalScrollHandler'
import { SnackbarContainer } from '../ui/Popups/Snackbar/Snackbar'
import { PathHandler } from '../utils/PathHandler'
import { useLiveChatWidget } from '../utils/useLiveChatWidget'
import { GlobalModals } from './GlobalModals'

interface LayoutProps {
  toast?: Toast
  setCurrentPath: (path: string) => void
}

export const Layout: React.FC<LayoutProps> = ({ toast, setCurrentPath }) => {
  useModalScrollHandler()

  const statusBannerRef = useRef<HTMLDivElement>(null)

  const updateLayoutHeight = useCallback(() => {
    const statusBannerHeight = statusBannerRef.current?.clientHeight || 0

    document.documentElement.style.setProperty('--status-banner-height', `${statusBannerHeight}px`)
  }, [])

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateLayoutHeight()
    })

    if (statusBannerRef.current) {
      resizeObserver.observe(statusBannerRef.current)
    }

    return () => {
      if (statusBannerRef.current) {
        resizeObserver.unobserve(statusBannerRef.current)
      }
    }
  }, [updateLayoutHeight])

  return (
    <div className='expander'>
      {useLiveChatWidget()}
      <SnackbarContainer />
      <GlobalModals />
      <MaintenanceMessage />
      <PlatformNotification />
      <Nav />
      <main id='mainContainer' className='container'>
        <Suspense fallback={<Loading isLoading showLoadingIcon />}>
          <SessionTimeout />
          <NotificationBlock />
          <ToastBanner of={toast} />
          <div ref={statusBannerRef}>
            <StatusBanner />
          </div>
          <PathHandler setCurrentPath={setCurrentPath} />
          <LocaleModule />
          <MobileTabs />
          <Outlet />
        </Suspense>
      </main>
      <AppFooter />
    </div>
  )
}
