/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useNotificationsContext } from '../global/context/NotificationsContext'
import { useProductReadContext } from '../global/context/ProductContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { Notification, NotificationType, getNotificationSearchQuery } from '../model/Notification'
import { NotificationPlatformEnum } from '../model/PlatformTypeEnum'
import { useApiClient } from './ApiClient'
import { AuthSessionContext } from './AuthContext'
import { SharedContext } from './SharedContext'
import { ClientApiClient } from './clientApi'
import { getDomainName } from './cookie.utils'
import { isSignUpPath } from './path'
import { sanitizeHtml } from './sanitization'

export const useNotificationFunctionality = (): {
  enableNotifications: boolean
  showNotifications: boolean
} => {
  const [enable, setEnable] = useState(true)
  const [show, setShow] = useState(false)
  const location = useLocation()
  const [auth] = useContext(AuthSessionContext)

  useEffect(() => {
    if (!auth) {
      setEnable(false)
    } else if (isSignUpPath(location.pathname)) {
      setEnable(false)
    } else {
      setEnable(true)
    }
  }, [location, auth])

  useEffect(() => {
    const allowedPaths = ['/dashboard']
    const excludedPaths = [
      '/deposit',
      '/withdrawal',
      '/conversion',
      '/transfer',
      '/trading-accounts/new',
      '/trading-accounts/new/demo',
      '/demo/add-balance',
      '/change-password',
      '/first-deposit',
      '/legal-documents',
      '/dashboard/product-registration/appropriateness-test',
      '/dashboard/product-registration/kyc-personal-details',
      '/dashboard/take-app-test',
      '/dashboard/update-kyc',
    ]
    const currentPath = location.pathname
    if (!enable) {
      setShow(false)
    } else if (!allowedPaths.filter((path) => currentPath.includes(path)).length) {
      setShow(false)
    } else if (excludedPaths.filter((ePath) => currentPath.includes(ePath)).length) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [enable, location.pathname])

  return {
    enableNotifications: enable,
    showNotifications: show,
  }
}

export const useNotificationUnread = (): {
  refreshUnread: () => Promise<void>
  refreshAllNotifications: () => Promise<void>
  hasUnread: boolean | undefined
} => {
  const [sharedState, setSharedState] = useContext(SharedContext)
  const apiClient = useApiClient(ClientApiClient)
  const [auth] = useContext(AuthSessionContext)
  const locale = useSessionLanguage()
  const { refreshNotifications } = useFetchNotificationAlerts()
  const { product: tickmillProduct } = useProductReadContext()
  const location = useLocation()

  const refreshUnread = async () => {
    if (!auth || isSignUpPath(location.pathname)) {
      return
    }
    const newNotifications = await apiClient.getNotifications({
      domain: getDomainName(true),
      languageId: locale,
      ShowOnlyUnread: true,
      search: {
        TickmillProduct: tickmillProduct,
        Platform: NotificationPlatformEnum.WEB,
      },
      operator: 'AND',
      pageSize: 1,
    })
    setSharedState((currentState) => ({
      ...currentState,
      hasUnreadNotifications: !!newNotifications.items.length,
    }))
  }

  const refreshAllNotifications = async () => {
    await refreshNotifications()
    await refreshUnread()
  }

  return {
    refreshUnread,
    refreshAllNotifications,
    hasUnread: sharedState.hasUnreadNotifications,
  }
}

export const useFetchNotificationAlerts = (): {
  refreshNotifications: () => Promise<void>
} => {
  const [, setSharedState] = useContext(SharedContext)
  const apiClient = useApiClient(ClientApiClient)
  const [auth] = useContext(AuthSessionContext)
  const { product: tickmillProduct } = useProductReadContext()
  const locale = useSessionLanguage()
  const { enableNotifications } = useNotificationFunctionality()
  const location = useLocation()

  const refreshNotifications = async () => {
    if (!auth || isSignUpPath(location.pathname)) {
      return
    }
    const notificationPreferences = await apiClient.getNotificationPreferences()
    if (
      !!notificationPreferences.isGeneralSilent &&
      !!notificationPreferences.isPlatformRelatedSilent &&
      !!notificationPreferences.isPromotionalSilent
    ) {
      setSharedState((currentState) => ({
        ...currentState,
        notifications: [],
      }))
    } else {
      const { items } = await apiClient.getNotifications({
        domain: getDomainName(true),
        languageId: locale,
        search: getNotificationSearchQuery(notificationPreferences, {
          TickmillProduct: tickmillProduct,
          Platform: NotificationPlatformEnum.WEB,
        }),
        operator: 'AND',
        pageSize: 25,
      })
      setSharedState((currentState) => ({
        ...currentState,
        notifications: items,
      }))
    }
  }

  return { refreshNotifications }
}

export const useNotificationAlerts = (): {
  generalNotifications: Notification[]
  platformNotifications: Notification[]
  promotionalNotifications: Notification[]
} => {
  const [sharedState] = useContext(SharedContext)
  const [settings] = useNotificationsContext()
  const [general, setGeneral] = useState<Notification[]>([])
  const [platform, setPlatform] = useState<Notification[]>([])
  const [promotional, setPromotional] = useState<Notification[]>([])

  useEffect(() => {
    if (settings.hideGeneralNotifications) {
      setGeneral([])
    } else {
      setGeneral(
        sharedState.notifications?.filter(
          (n) =>
            n.notificationType.id !== NotificationType.Promotional &&
            n.notificationType.id !== NotificationType.Platform
        )
      )
    }
    if (settings.hidePlatformNotifications) {
      setPlatform([])
    } else {
      setPlatform(
        sharedState.notifications
          ?.filter((n) => n.notificationType.id === NotificationType.Platform)
          .slice(0, 1)
      )
    }
    if (settings.hidePromoNotifications) {
      setPromotional([])
    } else {
      setPromotional(
        sharedState.notifications?.filter(
          (n) => n.notificationType.id === NotificationType.Promotional
        )
      )
    }
  }, [settings, sharedState.notifications])

  return {
    generalNotifications: general,
    platformNotifications: platform,
    promotionalNotifications: promotional,
  }
}

export const useHideNotifications = (): {
  hideNotifications: (types: NotificationType[]) => void
} => {
  const [settings, setSettings] = useNotificationsContext()

  const hideNotifications = (types: NotificationType[]) => {
    let newSettings = { ...settings }
    if (types.includes(NotificationType.Platform)) {
      newSettings = { ...newSettings, hidePlatformNotifications: true }
    }
    if (types.includes(NotificationType.General)) {
      newSettings = { ...newSettings, hideGeneralNotifications: true }
    }
    if (types.includes(NotificationType.Promotional)) {
      newSettings = { ...newSettings, hidePromoNotifications: true }
    }
    setSettings({ ...newSettings })
  }

  return { hideNotifications }
}

interface NotificationTextProps {
  overflown: boolean
  text: string
  className: string
  open?: () => void
}

export const NotificationText: React.FC<NotificationTextProps> = ({
  overflown,
  text,
  className,
  open,
}) => {
  const { t } = useTranslation()
  const sanitizedText = sanitizeHtml(text, overflown ? 175 : undefined)

  return (
    <>
      <span dangerouslySetInnerHTML={{ __html: sanitizedText }} />
      {overflown && (
        <span data-test='notification-read-more' className={className} onClick={open}>
          {t('Notifications.Read more')}
        </span>
      )}
    </>
  )
}
