import React, {
  ChangeEvent,
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Form, FormikProps, getIn, useFormikContext, withFormik } from 'formik'
import { FieldValidator } from 'formik/dist/types'

import { Button } from '../../../../global/button/Button'
import { useSessionEntity } from '../../../../global/context/EntityContext'
import { SelectField } from '../../../../global/field/SelectField'
import { createFormField } from '../../../../global/formField/FormField'
import { createFormRadioField } from '../../../../global/formField/RadioGroupField'
import { Modal } from '../../../../global/modal/Modal'
import { RadioButton } from '../../../../global/radioButton/RadioButton'
import { ClientTestDto } from '../../../../model/ClientTestDto'
import { AppropriatenessTestTakeTestFormValues } from '../../../../model/Test'
import {
  isTestQuestionWidgetRadio,
  isTestQuestionWidgetSelect,
} from '../../../../model/TestQuestionWidget'
import {
  OnChangeOption,
  TestSectionAnswerDto,
  TestSectionDto,
  TestSectionQuestionDto,
  WidgetOptions,
  isAppropriatenessTestPersonalInformation,
  isAppropriatenessTestTradingKnowledge,
} from '../../../../model/TestSectionsDto'
import { TooltipIcon } from '../../../../ui/Popups/Tooltip/TooltipIcon'
import { getCleanedAnswers, getOptions } from '../../../../ui/TestFields/TestFields'
import { Text, TextH3 } from '../../../../ui/Typography/Typography'
import { requiresAppTestImprovement } from '../../../../utils/companyName.utils'
import { isMobile } from '../../../../utils/domUtils'
import { isOne } from '../../../../utils/validations'
import { AppropriatenessTestUploadModal } from './AppropriatenessTestUploadModal'
import { AlignmentMessage } from './FinancialInfoPage'

import styles from './FinancialInfoPage.module.scss'

const FormField = createFormField()
const FormRadioGroupField = createFormRadioField()

export const FinancialInfoFormUI: React.FC<
  FormikProps<AppropriatenessTestTakeTestFormValues> & OuterProps
> = (props) => {
  const { data, step, isSubmitting, errors, handleSubmit, setFieldTouched, setErrors } = props

  const tickmillEntity = useSessionEntity()

  const section = data.sections.find((x) => x.code === step)

  const entityRequiresAppTestImprovement = requiresAppTestImprovement(tickmillEntity)

  const { t } = useTranslation()
  const context = useFormikContext<AppropriatenessTestTakeTestFormValues>()

  useImperativeHandle(props.forwardedRef, () => ({
    goPrevStep() {
      handleBack()
    },
  }))

  const handleSubmitForm = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (Object.keys(errors).length > 0) {
      setFieldTouchedAll()
    } else {
      handleSubmit()
    }
  }

  const handleBack = () => {
    setErrors({})
    props.goPrevStep(context.values)
  }

  const setFieldTouchedAll = () => {
    Object.entries(errors).forEach(([key, value]) => {
      if (Object.keys(value).length > 0) {
        Object.entries(value).forEach(([key2]) => {
          setFieldTouched(`${key}.${key2}`, true)
        })
      }
    })
  }

  return (
    <div>
      <Form onSubmit={handleSubmitForm}>
        {section && (
          <div className={styles.formFieldsWrapper}>
            <TextH3 className={styles.title}>
              {section.name || t('Sign up.We are almost finished. Click next to continue.')}
            </TextH3>
            {!isOne(step) && isMobile() && entityRequiresAppTestImprovement && (
              <AlignmentMessage sectionCode={section.code} />
            )}
            {!entityRequiresAppTestImprovement &&
              isAppropriatenessTestPersonalInformation(section.code) && (
                <Text>
                  {t(
                    "Sign up.Here we'll need some information about your education and current employer."
                  )}
                </Text>
              )}
            {section.questions.map((x) => (
              <QuestionFieldFactory {...x} section={section} key={x.question.id} />
            ))}
          </div>
        )}

        <Button
          appearance='primary'
          size='L'
          type='submit'
          loading={isSubmitting}
          className={styles.button}
        >
          {isLastSection(data, step) ? t('Submit') : t('Next')}
        </Button>
      </Form>
    </div>
  )
}

interface QuestionFieldFactoryProps extends TestSectionQuestionDto {
  section: TestSectionDto
}

const QuestionFieldFactory: React.FC<QuestionFieldFactoryProps> = (props) => {
  const { question, widget } = props

  const { values } = useFormikContext<AppropriatenessTestTakeTestFormValues>()

  const options = getOptions(widget.options)

  if (isOptionsHide(options)) {
    return null
  }

  if (isTestQuestionWidgetSelect(question.widgetType)) {
    return <SelectWidget {...props} />
  }

  if (isTestQuestionWidgetRadio(question.widgetType)) {
    return <RadioWidget {...props} />
  }

  if (!isFieldShow(props, values)) {
    return null
  }

  return <TextWidget {...props} />
}

const QuestionFieldOtherFactory: React.FC<TestSectionQuestionDto & { section: TestSectionDto }> = (
  props
) => {
  const { question } = props

  if (isTestQuestionWidgetSelect(question.widgetType)) {
    return <SelectWidget {...props} />
  }

  if (isTestQuestionWidgetRadio(question.widgetType)) {
    return <RadioWidget {...props} />
  }

  return <TextWidget {...props} />
}

const SelectWidget: React.FC<TestSectionQuestionDto & { section: TestSectionDto }> = (props) => {
  const { question, answers, widget } = props
  const name = `selectedAnswers.${widget.name}`

  const { t } = useTranslation()
  const context = useFormikContext<AppropriatenessTestTakeTestFormValues>()

  const [isDocumentUploadModalOpen, setDocumentUploadModalOpen] = useState<boolean>()
  const errorMessage = question?.errorMessage || t('Validation.Required')
  const isTouched = context.touched.selectedAnswers?.[widget.name]
  const options = getOptions(widget.options)

  useEffect(() => {
    const value = getIn(context.values, name)
    const isMandatory = options.isMandatory ?? props.isMandatory
    if (isMandatory && !value) {
      context.setFieldError(name, errorMessage)
    } else {
      context.setFieldError(name, undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, question, context.values, options.isMandatory, context.errors, context.touched])

  if (widget?.name && question?.name) {
    const options = getOptions(widget.options)
    const isMandatory = options.isMandatory ?? props.isMandatory
    const value = getIn(context.values, name)
    const isDocumentUploadAllowed = options?.upload?.values?.includes(value)

    return (
      <React.Fragment>
        <div className={styles.questionAnswerField}>
          <div>
            <label className={styles.text}>
              {question.name}
              {isMandatory ? <span>*</span> : null}{' '}
              {question?.tooltip && (
                <React.Fragment>
                  <TooltipIcon title={question.tooltip} />
                </React.Fragment>
              )}
            </label>
            <SelectField
              name={name}
              error={isMandatory && !value && isTouched ? errorMessage : undefined}
              options={answers.map((x) => ({ value: x.id, label: x.name }))}
              onChange={(v) => {
                context.setFieldValue(name, v.target.value)
                context.setFieldTouched(name, true)
              }}
              value={value}
              required={isMandatory}
            />
            {isDocumentUploadAllowed && (
              <React.Fragment>
                <span
                  onClick={() => setDocumentUploadModalOpen(true)}
                  className='is-link has-cursor-pointer'
                >
                  {t(
                    'Sign up.If available please upload any documentary evidence of trading history e.g. trading statement (Optional)'
                  )}
                </span>
                {isDocumentUploadModalOpen && (
                  <Modal
                    closeModal={() => setDocumentUploadModalOpen(false)}
                    render={() => (
                      <AppropriatenessTestUploadModal
                        questionId={question.questionId}
                        onCancel={() => setDocumentUploadModalOpen(false)}
                        onConfirm={() => setDocumentUploadModalOpen(false)}
                      />
                    )}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        {options?.onchange?.map((x) => {
          return <WidgetOptionComponent {...props} name={name} option={x} key={x.name} />
        })}
      </React.Fragment>
    )
  }

  return null
}

const RadioWidget: React.FC<TestSectionQuestionDto & { section: TestSectionDto }> = (props) => {
  const { question, answers, widget } = props

  const { t } = useTranslation()
  const context = useFormikContext<AppropriatenessTestTakeTestFormValues>()

  const [isDocumentUploadModalOpen, setDocumentUploadModalOpen] = useState<boolean>()

  if (widget?.name && question?.name) {
    const name = `selectedAnswers.${widget.name}`
    const options = getOptions(widget.options)
    const isMandatory = options.isMandatory ?? props.isMandatory
    const error = getIn(context.errors, name)
    const touched = getIn(context.touched, name)
    const value = getIn(context.values, name)
    const isDocumentUploadAllowed = isRadioWidgetCode(options, answers, value)

    return (
      <React.Fragment>
        <div
          className={classNames(styles.questionAnswerField, {
            [styles.radioError]: touched && error,
          })}
        >
          <div>
            <label className={styles.text}>
              {question.name}
              {isMandatory ? <span>*</span> : null}{' '}
              {question?.tooltip && (
                <React.Fragment>
                  <TooltipIcon title={question.tooltip} />
                </React.Fragment>
              )}
            </label>
            <FormRadioGroupField
              name={name}
              value={value}
              required
              validate={(value: FieldValidator) => {
                if (!value) {
                  return question?.errorMessage || t('Validation.Required')
                }
                return undefined
              }}
            >
              <div className='columns'>
                {answers.map((x) => (
                  <div className={classNames(styles.radioWidgetWrapper, 'column is-flex-grow-0')}>
                    <RadioButton
                      name={name}
                      label={x.name}
                      value={x.id}
                      checked={x.id === value}
                      key={x.id}
                    />
                  </div>
                ))}
              </div>
            </FormRadioGroupField>
            {isDocumentUploadAllowed && (
              <React.Fragment>
                <span
                  onClick={() => setDocumentUploadModalOpen(true)}
                  className='is-link has-cursor-pointer'
                >
                  {t(
                    'Sign up.If available please upload any documentary evidence of trading history e.g. trading statement (Optional)'
                  )}
                </span>
                {isDocumentUploadModalOpen && (
                  <Modal
                    closeModal={() => setDocumentUploadModalOpen(false)}
                    render={() => (
                      <AppropriatenessTestUploadModal
                        questionId={question.questionId}
                        onConfirm={() => setDocumentUploadModalOpen(false)}
                        onCancel={() => setDocumentUploadModalOpen(false)}
                      />
                    )}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </div>
        {options?.onchange?.map((x) => {
          return <WidgetOptionComponent {...props} name={name} option={x} key={x.name} />
        })}
      </React.Fragment>
    )
  }

  return null
}

const TextWidget: React.FC<TestSectionQuestionDto & { section: TestSectionDto }> = (props) => {
  const { question, widget } = props

  const { t } = useTranslation()

  if (widget?.name && question?.name) {
    const name = `freeAnswers.${question.id}`
    const options = getOptions(widget.options)
    const isMandatory = options ? options.isMandatory ?? !options.optional : props.isMandatory

    return (
      <React.Fragment>
        <div className={styles.questionAnswerField}>
          <div>
            <label className={styles.text}>
              {question.name}
              {isMandatory ? <span>*</span> : null}{' '}
              {question?.tooltip && (
                <React.Fragment>
                  <TooltipIcon title={question.tooltip} />
                </React.Fragment>
              )}
            </label>
            <FormField
              name={name}
              required={isMandatory}
              validate={(value: FieldValidator) => {
                if (isMandatory && !value) {
                  return question?.errorMessage || t('Validation.Required')
                }
                return undefined
              }}
            />
          </div>
        </div>
        {options?.onchange?.map((x) => {
          return <WidgetOptionComponent {...props} name={name} option={x} key={x.name} />
        })}
      </React.Fragment>
    )
  }

  return null
}

const WidgetOptionComponent: React.FC<
  {
    option: OnChangeOption
  } & {
    name: string
    section: TestSectionDto
  } & TestSectionQuestionDto
> = (props) => {
  const { name, option, section, answers } = props
  const widgetName = option?.name

  const { values } = useFormikContext<AppropriatenessTestTakeTestFormValues>()

  return (
    <React.Fragment>
      {option.values?.map((id) => {
        const widgetQuestion = getWidgetByWidgetName(section, widgetName)
        if (isOther(values, name, answers, id)) {
          if (widgetQuestion) {
            return (
              <QuestionFieldOtherFactory
                {...props}
                {...{
                  ...widgetQuestion,
                  isMandatory: true,
                  widget: {
                    ...widgetQuestion.widget,
                  },
                }}
                key={widgetQuestion.widget.name}
              />
            )
          }
        }

        if (isDepend(values, name, id)) {
          if (widgetQuestion) {
            return (
              <QuestionFieldOtherFactory
                {...props}
                {...{
                  ...widgetQuestion,
                  widget: {
                    ...widgetQuestion.widget,
                  },
                }}
                key={widgetQuestion.widget.name}
              />
            )
          }
        }

        if (isRadioWidgetType(values, props)) {
          if (widgetQuestion) {
            return (
              <QuestionFieldOtherFactory
                {...props}
                {...{
                  ...widgetQuestion,
                  widget: {
                    ...widgetQuestion.widget,
                  },
                }}
                key={widgetQuestion.widget.name}
              />
            )
          }
        }

        return null
      })}
    </React.Fragment>
  )
}

interface OuterProps {
  forwardedRef: ForwardedRefType
  data: ClientTestDto
  step: string
  values: AppropriatenessTestTakeTestFormValues | undefined

  onSubmit(values: AppropriatenessTestTakeTestFormValues): void
  goPrevStep(values: AppropriatenessTestTakeTestFormValues): void
  goNextStep(values: AppropriatenessTestTakeTestFormValues): void
}

type ForwardedRefType = ForwardedRef<
  | {
      goPrevStep(): void
    }
  | undefined
>

const dataToSubmit = (
  values: AppropriatenessTestTakeTestFormValues,
  sections: readonly TestSectionDto[]
): AppropriatenessTestTakeTestFormValues => {
  const cleanSelectedAnswers = getCleanedAnswers(values.selectedAnswers, sections)
  const cleanFreeAnswers = getCleanedAnswers(values.freeAnswers, sections)

  return {
    ...values,
    selectedAnswers: cleanSelectedAnswers,
    freeAnswers: cleanFreeAnswers,
  }
}

const FinancialInfoStepFormComponent = withFormik<
  OuterProps,
  AppropriatenessTestTakeTestFormValues
>({
  mapPropsToValues: (props) => {
    const { data, values } = props

    return {
      clientId: '',
      dateTaken: '',
      category: data?.category || '',
      testId: data?.id || '',
      selectedAnswers: values?.selectedAnswers || {},
      freeAnswers: values?.freeAnswers || {},
      documents: values?.documents || {},
    }
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      if (isLastSection(props.data, props.step)) {
        await props.onSubmit(dataToSubmit(values, props.data.sections))
      } else {
        props.goNextStep(dataToSubmit(values, props.data.sections))
      }

      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  },
  enableReinitialize: false,
  validateOnMount: false,
  isInitialValid: true,
  validateOnChange: true,
  validateOnBlur: true,
})(FinancialInfoFormUI)

export const FinancialInfoForm = forwardRef(
  (props: Omit<OuterProps, 'forwardedRef'>, ref: ForwardedRefType) => {
    return <FinancialInfoStepFormComponent {...props} forwardedRef={ref} />
  }
)

const isLastSection = (data: ClientTestDto, step: string) => {
  const lastSection = data.sections[data.sections.length - 1]
  return lastSection.code === step
}

const isOther = (
  values: AppropriatenessTestTakeTestFormValues,
  name: string,
  arrayAnswers: TestSectionAnswerDto[],
  id: string
) => {
  const otherKey = 'other'
  const value = getIn(values, name)
  const answer = arrayAnswers.find((x) => x.id === id)

  const isSelected = value === id
  const isOtherInQuestion = answer?.key === otherKey

  return isOtherInQuestion && isSelected
}

const isFieldShow = (
  props: QuestionFieldFactoryProps,
  values: AppropriatenessTestTakeTestFormValues
) => {
  const { section, answers: arrayAnswers = [], widget } = props

  const options = getOptions(widget.options)
  const answerWidgetKeys = Object.keys(values.selectedAnswers)

  if (isAppropriatenessTestPersonalInformation(section.code)) {
    const foundPersonalInfo = answerWidgetKeys.find((x) => x === options?.depend)
    return foundPersonalInfo
  }

  if (isAppropriatenessTestTradingKnowledge(section.code)) {
    const foundTradingKnowledgeKey = answerWidgetKeys.find((x) => x === options?.depend)

    const foundTradingKnowledgeValue =
      foundTradingKnowledgeKey && values.selectedAnswers?.[foundTradingKnowledgeKey]

    const foundTradingKnowledgeCode =
      foundTradingKnowledgeKey &&
      foundTradingKnowledgeValue &&
      arrayAnswers.find((x) => x?.id === foundTradingKnowledgeValue)?.code === '1'

    return foundTradingKnowledgeCode
  }

  return false
}

const isDepend = (values: AppropriatenessTestTakeTestFormValues, name: string, id: string) => {
  const value = getIn(values, name)
  const isSelected = value === id

  return isSelected
}

const isRadioWidgetType = (
  values: AppropriatenessTestTakeTestFormValues,
  props: {
    option: OnChangeOption
  } & {
    name: string
    section: TestSectionDto
  } & TestSectionQuestionDto
) => {
  const { name, answers, widget } = props
  const value = getIn(values, name)

  if (isTestQuestionWidgetRadio(widget?.type)) {
    const selectedRadioCode = getRadioWidgetCode(answers, value)
    return isOne(selectedRadioCode)
  }

  return false
}

const isRadioWidgetCode = (
  options: WidgetOptions,
  answers: TestSectionAnswerDto[],
  value: string
) => {
  const radioWidgetCode = getRadioWidgetCode(answers, value)
  const isDocumentUploadAllowed = (options?.upload?.values || [])
    ?.map((x) => Number.parseInt(x.toString(), 10))
    ?.includes(radioWidgetCode)
  return isDocumentUploadAllowed
}

const getRadioWidgetCode = (answers: TestSectionAnswerDto[], value: string) => {
  const answer = answers.find((x) => x?.id === value)
  const selectedRadioCode = answer ? Number.parseInt(answer.code, 10) : -1

  return selectedRadioCode
}

const isOptionsHide = (options: WidgetOptions) => {
  return options && 'hide' in options && 'depend' in options && options?.hide === true
}

const getWidgetByWidgetName = (
  section: TestSectionDto,
  widgetName?: string
): TestSectionQuestionDto | undefined => {
  return (section?.questions || [])?.find((x) => x.question.widgetName === widgetName)
}
