import { WarningPageStatus } from '../WarningAccount/WarningAccountPage'
import { OnboardingFlowTypeEnum } from '../pages/Signup/Components/SignupStep'
import {
  hasProductClosedStatus,
  hasProductDormantStatus,
} from '../utils/AccountAccess/accountProductStatuses'
import { TickmillCompany } from '../utils/companyName.utils'
import { AccountDetailedStatus, isAccountDetailedActivatedStatus } from './AccountDetailedStatus'
import { AccountType } from './AccountType'
import { NameDto } from './NameDto'
import { TickmillProductDto } from './TickmillProductDto'
import { TickmillProductType } from './TickmillProductType'
import { CurrencyType } from './WalletDto'

export type AccountDetailedDto = {
  id: string
  clientAreaId?: string
  firstname: string
  middlename: string | null
  lastname: string
  type: NameDto<number>
  tickmillCompany: TickmillCompany
  status: NameDto<AccountDetailedStatus>
  nativeName: string | null
  gender: NameDto<number>
  communicationLanguage: NameDto<string>
  username: string | null
  nationalClientIdentifier: string | null
  nciType: NameDto<number> | null
  assignee?: {
    id: string
    name: string
    status?: NameDto<number>
  } | null
  affiliateToken: string | null
  birthday: string
  salesStatus?: string | null
  classification: NameDto<number>
  personalId: string | null
  dateFormatType?: NameDto<number> | null
  documentType: NameDto<number> | null
  documentId: string | null
  documentExpirationDate: string | null
  documentIdInternal: string | null
  educationLevel: NameDto<number> | null
  preferredLandingPage: PreferredLandingPage | null
  clientAmlSummary: {
    score: number
    amlRiskLevel: NameDto
  }
  marketDataClassification: NameDto<MarketDataClassificationStatus> | null
  multiTierRegistrationAllowed: boolean
  mainIbScheme: NameDto
  employer: string | null
  isEmployed: boolean | null
  businessNature: NameDto<number> | null
  isUsCitizen: boolean
  isPoliticallyExposedPerson: boolean
  taxId: string | null
  taxIdAvailable: boolean
  taxIdAvailabilityStatus: NameDto<number>
  introducingBroker: null | NameDto<string>
  registrationFinishDate: string | null
  isSwapAccount?: boolean
  loginFailedCount: number | null
  lastClientAreaLoginDate: string | null
  hasSentWelcomeEmail: boolean | null
  validationCompletedDate: string | null
  isAddressInfoLocked: boolean
  isPaymentInfoLocked: boolean
  isBrexitTransferAccepted: boolean
  isFIXApiUser: boolean
  isPersonalInfoLocked: boolean
  isAdditionalInfoLocked: boolean
  isCompanyInfoLocked: boolean | null
  isKYCUpdateNeeded: boolean
  isDormantUpdateNeeded: boolean
  isInternalTestAccount: boolean
  isSwapFreeAccount: boolean
  isWelcomeBonusAccount: boolean
  isVerificationExpedited: boolean
  shouldReceiveNewsletter: boolean
  introducingBrokerRegistrationAllowed: boolean
  hasUploadedDocuments: boolean
  approachedByRep: boolean
  defaultTradingAccountBook?: NameDto<number> | null
  maxLeverage: NameDto<number> | null
  brokerIntroductionMethod: NameDto<number> | null
  riskSet: null | any
  vulnerableGroup: null | any
  detailsUpdateAvailableUntil: string | null
  leadId?: string
  kycLastUpdatedDate: null | string
  detailsLastUpdatedDate: string | null
  kycUpdateAvailableUntil: string | null
  companyDetails: null | CompanyDetails
  nationalities: ClientNationalityDto[]
  phoneNumbers: ClientPhoneNumberDto[]
  emails: ClientEmailDto[]
  teams: ClientLeadTeamDto[]
  addresses: ClientAddressDto[]
  restrictions: NameDto[]
  clientAnswers: ClientAnswerDto[]
  clientPaymentAgent: null | { id: string; name: string; isActive: boolean }
  clientIntroducingBroker: null | { id: string; name: string }
  externalSystems?: ExternalSystem[]
  isFavorite: boolean
  createdDate: string | null
  updatedDate: string | null
  updatedAt: string | null
  truliooWatchlistStatus?: NameDto<number> | null
  worldCheckStatus?: NameDto<number> | null
  preferredCurrency: NameDto<CurrencyType> | null
  displayCurrency: NameDto<CurrencyType> | null

  tickmillPublicDomain: null | string
  tickmillProducts: TickmillProductDto[]
  companyConfiguration: CompanyConfigurationDto
  actionRestrictions: ActionRestrictionsConfigurationDto
  visibilityConfiguration: VisibilityConfigurationDto
  affiliate: null | NameDto<string>
  isAppropriatenessTestAccountFailed: {
    [TickmillProductType.CFD]: boolean
    [TickmillProductType.ETD]: boolean
  }
  firstDeposit?: string | null
  clientProductStates?: ClientProductState[]
  agreedLegalDocuments: NameDto<string>[]
}

export interface CompanyConfigurationDto {
  hasRegulatoryOptions: boolean // not used
  hasDynamicLeverage: boolean // not used
  hasTraderRoom: boolean
  hasIBRoom: boolean
  hasPARoom: boolean
  hasForexTools: boolean // not used
  hasMultiTier: boolean
  hasAutochartist: boolean
  hasStocks: boolean
  hasCorporateStocks: boolean
  hasClassification: boolean
  appropriatenessTestCooldownPeriod: number
  hasNCI: boolean
  showProductSwitcher: boolean
  hasPlacedDeposit: boolean
  skipDocuments: boolean
  poiVerified: boolean
  onboardingFlowType: NameDto<OnboardingFlowTypeEnum>

  // ETD temp workarround from BE
  hasEtdForexTools: boolean
  hasEtdMarketDataClassification: boolean
  hasEtdTraderRoom: boolean
}

interface ActionRestrictionsConfigurationDto {
  tradersRoomRestricted: boolean
  introducingBrokerRestricted: boolean
  openAccountRestricted: boolean
}

type VisibilityConfigurationDto = CompanyConfigurationDto

interface PreferredLandingPage extends NameDto {
  tickmillProductId: TickmillProductType
}

export interface ClientProductState {
  tickmillProduct: {
    id: number
    name: TickmillProductType
  }
  calculatedStatus: {
    id: number
    name: AccountDetailedStatus
  }
  passedAppropriatenessTest: boolean
  agreedToLegalDocuments: boolean
  setMarketDataClassification: boolean
  submittedAppropriatenessTest: boolean
  appropriatenessTestMaximumRetakeLimitReached: boolean
  allRequirementsMet: boolean
}

export const isAccountIndividualType = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return accountDetailed?.type?.id === AccountType.Individual || false
}

export const isAccountCompanyType = (accountDetailed: AccountDetailedDto | undefined): boolean => {
  return accountDetailed?.type?.id === AccountType.Corporate || false
}

export const accountHasIntroducingBroker = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return !!accountDetailed?.introducingBroker?.id || !!accountDetailed?.clientIntroducingBroker?.id
}

export const isAccountDetailedActivated = (
  accountDetailed: AccountDetailedDto | undefined
): boolean => {
  return isAccountDetailedActivatedStatus(accountDetailed?.status?.id)
}

export interface ClientNationalityDto {
  id: string
  name: string
  isPrimary: boolean
}

export interface ClientPhoneNumberDto {
  id: string
  tempId?: number
  type: string
  countryCode: string
  number: string
  isPrimary: boolean
  isVerified: boolean
  createdDate?: string
  shouldVerify: boolean
  isUsedIn2FA: boolean
}

export interface ClientEmailDto {
  id?: string
  tempId?: number
  emailType: string
  address: string
  isPrimary: boolean
}

export interface ClientLeadTeamDto {
  id: string
  name: string
  isPrimary: boolean
}

export interface ClientAddressDto {
  id?: string
  tempId?: number
  type: string
  street: string | null
  city: string | null
  state: string | null
  streetHouseNo: string | null
  postalCode: string | null
  country: {
    id: string
    name: string
  }
  isPrimary: boolean
}

interface ClientAnswerDto {
  question: string
  answer: string
}

interface CompanyDetails {
  name: string
  registrationDate: string
  registrationNumber: string
  website: string
  email: {
    emailType: string
    address: string
    isPrimary: boolean
  }
  phoneNumber: {
    type: string
    countryCode: string
    number: string
    isPrimary: boolean
  }
  legalRepresentationBasisId: {
    id: number
    name: string
  }
  legalRepresentationBasis: {
    name: string
    id: number
  }
  ownershipStructure: NameDto<number>
  ownersCount: number
  address: {
    type: string
    street: string
    city: string
    state: string
    postalCode: string
    country: string
    isPrimary: boolean
  }
  legalEntityIdentifier: string
  owners: Owner[]
  companyType: {
    id: number
    name: string
  } | null
}

export interface Owner {
  id?: string
  name: string
  ownership: number
}

export interface ExternalSystem {
  id: string
  externalSystem: {
    id: 1 | 2 | 3
    name: 'SugarCRM' | 'HubSpot' | 'Legacy SugarCRM'
  }
  externalId: string
  externalUrl: string
}

export enum MarketDataClassificationStatus {
  Private = 1,
  Professional = 2,
}

export const getVerifiedPhoneNumbers = (
  account: AccountDetailedDto | undefined
): ClientPhoneNumberDto[] | undefined => {
  return account?.phoneNumbers.filter((phone) => phone.isVerified)
}

export const hasVerifiedPhoneNumbers = (account: AccountDetailedDto | undefined): boolean => {
  return account?.phoneNumbers.some((phone) => phone.isVerified) ?? false
}

export const isClientOnCoolOff = (
  account: AccountDetailedDto | undefined,
  isProductTypeCFD: boolean
): boolean => {
  if (!account) {
    return false
  }

  const targetProductType = isProductTypeCFD ? TickmillProductType.ETD : TickmillProductType.CFD

  const productState = account?.clientProductStates?.find(
    (productState) => productState.tickmillProduct.id === targetProductType
  )

  return !!(
    productState?.appropriatenessTestMaximumRetakeLimitReached &&
    productState.calculatedStatus.id === AccountDetailedStatus.ValidationDenied
  )
}

export const isFuturesValidationDenied = (account: AccountDetailedDto | undefined): boolean => {
  if (!account) {
    return false
  }

  const etdProductState = account.clientProductStates?.find(
    (productState) => productState.tickmillProduct.id === TickmillProductType.ETD
  )

  if (
    etdProductState &&
    etdProductState.calculatedStatus.id === AccountDetailedStatus.ValidationDenied
  ) {
    return true
  }

  return false
}

export const showClosedAccountPage = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean => {
  const Status: WarningPageStatus = 'Closed'

  return !!(
    account &&
    hasProductClosedStatus(account, product) &&
    !localStorage.getItem(`hasVisited${Status}AccountPage`)
  )
}

export const showInactiveAccountPage = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType
): boolean => {
  const Status: WarningPageStatus = 'Inactive'

  return !!(
    account &&
    hasProductDormantStatus(account, product) &&
    !localStorage.getItem(`hasVisited${Status}AccountPage`)
  )
}

export const isVerificationExpedited = (account: AccountDetailedDto | undefined) =>
  account?.isVerificationExpedited ?? false
