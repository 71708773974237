export enum DocumentStatus {
  None = 0,
  PendingApproval = 1,
  Approved = 2,
  Rejected = 3,
  Expired = 4,
  Delete = 5,
}

export const isDocumentStatusPendingApproval = (documentStatus: number | undefined): boolean => {
  return documentStatus === DocumentStatus.PendingApproval
}

export const isDocumentStatusExpired = (documentStatus: number | undefined): boolean => {
  return documentStatus === DocumentStatus.Expired
}

export const isDocumentStatusRejected = (documentStatus: number | undefined): boolean => {
  return documentStatus === DocumentStatus.Rejected
}

export const isDocumentStatusApproved = (documentStatus: number | undefined): boolean => {
  return documentStatus === DocumentStatus.Approved
}

export const getDocumentStatusName = (value: DocumentStatus): string => {
  if (value === DocumentStatus.PendingApproval) {
    return 'Pending Approval'
  }

  if (value === DocumentStatus.Approved) {
    return 'Approved'
  }

  if (value === DocumentStatus.Rejected) {
    return 'Rejected'
  }

  if (value === DocumentStatus.Expired) {
    return 'Expired'
  }

  if (value === DocumentStatus.Delete) {
    return 'Delete'
  }

  return 'None'
}
