import { useEffect } from 'react'

export const useKeyPressed = (key: string, callback: () => void): void => {
  useEffect(() => {
    const onKeydown = (event: KeyboardEvent) => event.key === key && callback()
    window.addEventListener('keydown', onKeydown)

    return () => window.removeEventListener('keydown', onKeydown)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
