import { ReactElement } from 'react'

import styles from './Icons.module.scss'

export const PlusIconPrimary = (props: { size?: number }): ReactElement => {
  const size = props.size || 24
  return (
    <svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <rect width='40' height='40' rx='20' fill={styles.error} />
      <path
        d='M20 13C19.4477 13 19 13.4477 19 14V19H14C13.4477 19 13 19.4477 13 20V20C13 20.5523 13.4477 21 14 21H19V26C19 26.5523 19.4477 27 20 27V27C20.5523 27 21 26.5523 21 26V21H26C26.5523 21 27 20.5523 27 20V20C27 19.4477 26.5523 19 26 19H21V14C21 13.4477 20.5523 13 20 13V13Z'
        fill='white'
      />
    </svg>
  )
}
