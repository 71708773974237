import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import * as XLSX from 'xlsx'

import { Loading } from '../../global/Loading/Loading'
import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { FilterQueryProps } from '../../global/filter/FilterQueryModal'
import { ExportModal } from '../../global/modal/ExportModal'
import { Modal } from '../../global/modal/Modal'
import { SortByModal } from '../../global/modal/SortByModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../hooks/useScrollToElementIds'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { SearchTermState } from '../../ui/Table/Header/PageHeaderParts'
import { PageQuery, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { useDateFilterWriteContext } from '../../utils/DateFilterContext'
import { ClientApiClient } from '../../utils/clientApi'
import { dateFormat } from '../../utils/config'
import { formatDate } from '../../utils/date.utils'
import { useWindowResize } from '../../utils/domUtils'
import { IDateFilter, getAppliedFiltersLength, setQuery } from '../../utils/filter.utils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { getScrollToCardId } from '../../utils/getItemId'
import { filterObjectByKeys } from '../../utils/object-filter'
import { generatePDFTable } from '../../utils/prepare.pdf.utils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { StockDividendsCard } from './StockDividendsCard'
import { StockDividendsFilterModal } from './StockDividendsFilterModal'
import { StockDividendsTable } from './StockDividendsTable'

const exportFilename = 'stock_dividends_report'

export const StockDividendsPage: React.FC = () => {
  useScrollToTop()
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const { formatNumber } = useFormatNumber()
  const { t } = useTranslation()

  const [optionsModal, setOptionsModal] = useState(false)
  const [filterModal, setFilterModalOpen] = useState<boolean>(false)
  const [sortModalOpen, setSortModalOpen] = useState<boolean>(false)
  const { clearFilter } = useDateFilterWriteContext()
  const isMobile = useWindowResize()
  const [auth] = useContext(AuthSessionContext)
  const userDateFormat = auth?.dateFormatType?.name

  const oneWeekAgo = format(new Date(new Date().setDate(new Date().getDate() - 7)), dateFormat)
  const oneWeekFromNow = format(new Date(new Date().setDate(new Date().getDate() + 7)), dateFormat)

  const [search, setSearch] = useState<FilterQueryProps | undefined>({
    ca_search_DateFrom: oneWeekAgo,
    ca_search_DateTo: oneWeekFromNow,
  })

  const sortOptions = [{ id: 'date', name: t('Date') }]
  const [searchTerm, showSearchTerm] = useState<SearchTermState>({
    show: false,
    searchTerm: undefined,
  })

  const { callback } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      return await apiClient.getCorporateActions({
        ...query,
        ca_search_Type: 1,
        ca_search_GrossDividendAmountFrom: 0.01,
        ...search,
      })
    },
    [search, locale]
  )

  const { data, meta, setPageQuery, pageQuery, isLoading, hasInitialResults } =
    useFetchAppendablePage(callback)

  useEffect(() => {
    const timer = setTimeout(
      () =>
        searchTerm.searchTerm !== undefined &&
        setPageQuery!({
          ...pageQuery,
          pageIndex: 1,
          ca_search_TextSearch: searchTerm.searchTerm,
        }),
      500
    )
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm?.searchTerm])

  const tableBody = () => {
    const headerCsvData = [
      [
        t('Stock Dividends.Name'),
        t('Stock Dividends.Symbol'),
        t('Stock Dividends.Dividend Ex Date'),
        t('Stock Dividends.Gross Dividend Amount'),
      ],
    ]
    return data.reduce((previousValue, currentValue) => {
      return previousValue.concat([
        [
          currentValue.instrument.name ?? '',
          currentValue.instrument.symbol ?? '',
          currentValue.date ? formatDate(currentValue.date, { formatType: userDateFormat }) : '',
          formatNumber(currentValue.corporateActionDividend?.rate),
        ],
      ])
    }, headerCsvData)
  }

  const onGenerateExcel = () => {
    const table = tableBody()
    const wb = XLSX.utils.book_new()
    const ws = XLSX.utils.aoa_to_sheet(table)

    XLSX.utils.book_append_sheet(wb, ws, exportFilename)
    XLSX.writeFile(wb, `${exportFilename}.xlsx`)
  }

  const onGeneratePDF = () => {
    const data = tableBody()
    generatePDFTable({ data, title: t('Tabs.Stock Dividends'), fileName: exportFilename })
    setOptionsModal(false)
  }

  const clearSearch = () => {
    setSearch({})
    clearFilter()
  }

  const handleFilter = (currentFilter?: IDateFilter, searchFilters?: FilterQueryProps) => {
    let query: FilterQueryProps = {
      ...searchFilters,
      ...setQuery(currentFilter),
    }

    if (!currentFilter) {
      query = filterObjectByKeys(query)
    }
    setSearch(query)

    setPageQuery?.({
      ...pageQuery,
      pageIndex: 1,
    })
  }

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.StockDividendsHeader,
    isPaginationEntrySelected,
    isLoading,
    setIsPaginationEntrySelected
  )
  useScrollAfterLoad(ScrollToIds.StockDividendsHeader, isLoading, meta?.pageSize)

  return (
    <>
      {hasInitialResults ? (
        <PageHeader
          id={ScrollToIds.StockDividendsHeader}
          title={t('Tabs.Stock Dividends')}
          optionsToggle={() => setOptionsModal(true)}
          search={{
            show: searchTerm.show,
            placeholder: t('Search name, symbol'),
            searchTerm: searchTerm.searchTerm,
            setShow: (v) => showSearchTerm({ ...searchTerm, show: v }),
            setSearchTerm: (v) => showSearchTerm({ ...searchTerm, searchTerm: v }),
          }}
          filterToggles={{
            openFilterModal: () => setFilterModalOpen(true),
            resetFilters: clearSearch,
            activeFilters: getAppliedFiltersLength({
              ...search,
            }),
            openSortModal: () => setSortModalOpen(true),
            sortLabel: normalizeSortLabel(t, sortOptions, pageQuery?.sort),
          }}
        />
      ) : (
        <PageHeader title={t('Tabs.Stock Dividends')} id={ScrollToIds.StockDividendsHeader} />
      )}
      <Loading isLoading={isLoading} showLoadingIcon>
        <StockDividendsTable data={data} isLoading={isLoading} />
        {isMobile &&
          data.map((corporateAction, index) => (
            <StockDividendsCard
              key={corporateAction.id}
              data={corporateAction}
              cardId={getScrollToCardId(index, data.length, 'stock-dividends')}
            />
          ))}
      </Loading>
      {sortModalOpen && (
        <Modal
          closeModal={() => setSortModalOpen(false)}
          render={({ closeModal }) => (
            <SortByModal
              onCancel={closeModal}
              options={sortOptions}
              onConfirm={(option, sortBy) => {
                setPageQuery?.({
                  ...pageQuery,
                  sort: option,
                  sortOrder: sortBy,
                })
                closeModal()
              }}
            />
          )}
        />
      )}
      {filterModal && (
        <StockDividendsFilterModal
          onConfirm={handleFilter}
          searchFilters={search}
          onClose={() => setFilterModalOpen(false)}
        />
      )}
      {optionsModal && (
        <Modal
          render={({ closeModal }) => (
            <ExportModal
              fileName={exportFilename}
              onCloseModal={closeModal}
              csvData={tableBody()}
              onExportToCSV={closeModal}
              onExportToPdf={onGeneratePDF}
              onExportToExcel={onGenerateExcel}
            />
          )}
          closeModal={() => setOptionsModal(false)}
        />
      )}
      {meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.StockDividendsHeader}
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsPaginationEntrySelected(true)
            }
            return setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </>
  )
}
