import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { Loading } from '../../global/Loading/Loading'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { LegalDocumentDto } from '../../model/CompanyLegalDocumentDto'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { LegalDocumentsForm, LegalDocumentsValues } from '../LegalDocuments/LegalDocumentsForm'
import { useProductLegalDocumentsStepPage } from '../LegalDocuments/ProductLegalDocumentsPage'

export const ProductLegalDocumentsStepPage: React.FC = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const locale = useSessionLanguage()
  const { refreshAccount } = useAccountWriteContext()
  const { isDefaultCFDProductType } = useProductReadContext()

  const { documents, mutation, marketDataClassificationId } = useProductLegalDocumentsStepPage()
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()

  useEffect(() => {
    const getCurrentProduct = account?.clientProductStates?.find(
      (productState) => productState?.tickmillProduct?.id === product
    )

    if (getCurrentProduct?.agreedToLegalDocuments) {
      navigate('/dashboard/product-registration/appropriateness-test')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmitDocuments = async (values: LegalDocumentsValues) => {
    await mutation.mutate(values, async () => {
      await refreshAccount(locale)
      navigate('/dashboard/product-registration/appropriateness-test')
    })
  }

  return (
    <Loading isLoading={documents.isLoading} showLoadingIcon>
      <LegalDocumentsForm
        title={isDefaultCFDProductType() ? t('Legal') : t('Sign up.Registration Futures Trading')}
        documents={documents.data}
        groupReferences={groupReferences(documents.data)}
        marketDataClassificationId={marketDataClassificationId}
        onSubmit={handleSubmitDocuments}
      />
    </Loading>
  )
}

const groupReferences = (documents: LegalDocumentDto[] = []): (string | null)[] => {
  const groupReferences = documents.map((x) => x.groupReference)

  return [...new Set(groupReferences)]
}
