import React from 'react'

import { Carousel, CarouselProps } from './Carousel'

export type CarouselProviderProps<T> = {
  items: CarouselChildProps[]
  loop?: boolean
} & Omit<CarouselProps, 'children'> &
  T

type CarouselChildProps = {
  component: React.ReactElement
}

export function CarouselProvider<T>(props: Omit<CarouselProviderProps<T>, 'children'>) {
  const { items } = props

  const renderChildren = (props?: Omit<CarouselProviderProps<T> | undefined, 'children'>) => {
    return items.map((x) => React.cloneElement(x.component, props))
  }

  return (
    <Carousel {...props}>
      {(carouselProps) => renderChildren({ ...props, ...carouselProps })}
    </Carousel>
  )
}
