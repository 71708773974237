import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { WalletWithdrawTransactionFailedPage } from '../../Traders-Room/Wallets/WalletWithdraw/WalletWithdrawTransactionPage/WalletWithdrawTransactionFailedPage'

export const PaymentProviderWalletWithdrawTransactionFailedPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleCancel = () => {
    navigate('/dashboard/payment-agent/wallets')
  }

  return (
    <WalletWithdrawTransactionFailedPage
      title={t('Transactions.Funds not withdrawn')}
      onCancel={handleCancel}
    />
  )
}
