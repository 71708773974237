import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { WalletWithdrawTransactionFailedPage } from './WalletWithdrawTransactionFailedPage'

export const TradersRoomWalletWithdrawTransactionFailedPage: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const handleCancel = () => {
    navigate('/dashboard/traders-room/wallets')
  }

  return (
    <WalletWithdrawTransactionFailedPage
      title={t('Transactions.Funds not withdrawn')}
      onCancel={handleCancel}
    />
  )
}
