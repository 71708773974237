import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { isAccountPendingDocumentVerificationStatus } from '../../model/AccountDetailedStatus'
import { Text, TextH3, TextStrong } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'

import styles from './DocumentsPending.module.scss'

interface DocumentsPendingProps {
  className?: string
  hideButton?: boolean
}

export const DocumentsPending: React.FC<DocumentsPendingProps> = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { account } = useAccountReadContext()
  const apiClient = useApiClient(ClientApiClient)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(() => apiClient.getRequiredDocuments(), [])
  const { data: documents = [] } = useFetchOne(callback)

  const allDocumentsSubmitted = documents.every((doc) => !doc.required)
  const someDocumentsPending = isAccountPendingDocumentVerificationStatus(account?.status.id || -1)

  const getContent = useMemo(() => {
    if (someDocumentsPending && allDocumentsSubmitted) {
      return {
        title: t('Verification Pending'),
        body: t('Documents are currently being reviewed'),
      }
    } else if (someDocumentsPending) {
      return {
        title: t('Missing Documentation'),
        body: t('Please upload remaining documents'),
      }
    } else {
      return {
        title: t('Documents Required'),
        body: t('Please upload the required documents'),
      }
    }
  }, [t, someDocumentsPending, allDocumentsSubmitted])

  return (
    <div className={classNames(styles.documentsPending, props.className)}>
      <TextH3>{getContent.title}</TextH3>
      <TextStrong isParagraph className={styles.subtitle}>
        {t('Thank you for registering with Tickmill')}
      </TextStrong>
      <Text isParagraph className={styles.text}>
        {getContent.body}
      </Text>
      {!props.hideButton && (
        <Button
          appearance='primary'
          size='M'
          onClick={() => navigate('/profile/document-management')}
          className={styles.button}
        >
          {t('Profile.Upload Documents')}
        </Button>
      )}
    </div>
  )
}
