import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'

import { WalletDto } from '../model/WalletDto'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { useLocallyPersistedState } from '../utils/useStorage'

interface UseHasUserAnyWalletsValue {
  hasUserAnyWallets: boolean | null
  refetch: () => Promise<WalletDto[]>
  setHasUserAnyWallets: Dispatch<SetStateAction<boolean | null>>
  isLoading?: boolean
}

interface UseHasUserAnyWalletsProps {
  initiate?: boolean
}

export const useHasUserAnyWallets = (
  props?: UseHasUserAnyWalletsProps
): UseHasUserAnyWalletsValue => {
  const { initiate = true } = props || {}
  const apiClient = useApiClient(ClientApiClient)
  const [isLoading, setIsLoading] = useState<boolean>(initiate)
  const [hasUserAnyWallets, setHasUserAnyWallets] = useLocallyPersistedState<boolean | null>(
    'hasUserAnyWallets',
    null
  )

  const { account } = useAccountReadContext()

  const fetchWallets = useCallback(async () => {
    const wallets = await apiClient.getWallets()
    setHasUserAnyWallets(wallets.length > 0)
    setIsLoading(false)
    return wallets
  }, [apiClient, setHasUserAnyWallets])

  useEffect(() => {
    if (!account?.id || hasUserAnyWallets !== null || !initiate) {
      return
    }
    fetchWallets()
  }, [hasUserAnyWallets, account?.id, fetchWallets, initiate])

  return {
    hasUserAnyWallets,
    refetch: fetchWallets,
    setHasUserAnyWallets,
    isLoading,
  }
}
