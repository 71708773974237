import { TestSectionAnswerDto } from '../model/TestSectionsDto'

export const getDocFromTest = (testCode: string): string | undefined => {
  switch (testCode) {
    case 'ib_fees_agreed_sc':
      return 'sc_introducing_broker_fees'
    case 'ib_fees_agreed_as':
      return 'as_introducing_broker_fees'
    case 'ib_fees_agreed_pa':
      return 'pa_introducing_broker_fees'
    case 'ib_agreement_confirmed_sc':
      return 'sc_introducing_broker_agreement'
    case 'ib_agreement_confirmed_as':
      return 'as_introducing_broker_agreement'
    case 'ib_agreement_confirmed_pa':
      return 'pa_introducing_broker_agreement'
    case 'ib_multitier_agreement_confirmed_sc':
      return 'sc_introducing_broker_agreement_multi_tier'
    case 'ib_multitier_agreement_confirmed_as':
      return 'as_introducing_broker_agreement_multi_tier'
    case 'ib_multitier_agreement_confirmed_pa':
      return 'pa_introducing_broker_agreement_multi_tier'
    case 'ib_multitier_fees_agreed_sc':
      return 'sc_introducing_broker_fees_multi_tier'
    case 'ib_multitier_fees_agreed_as':
      return 'as_introducing_broker_fees_multi_tier'
    case 'ib_multitier_fees_agreed_pa':
      return 'pa_introducing_broker_fees_multi_tier'
  }
  return undefined
}

export const getIBTestAnswerId = (answers: TestSectionAnswerDto[]): string => {
  return answers.find((answer) => answer.code === '1')?.id ?? ''
}
