import { IconProps, iconColor } from './IconProps'

export function VerticalLineIcon(props: IconProps) {
  const size = props.size || 10

  return (
    <svg
      {...props}
      width={size / 6}
      height={size}
      viewBox='0 0 2 34'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 1.16699L1 32.8337'
        stroke={iconColor(props)}
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}
