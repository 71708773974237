import React from 'react'
import { useTranslation } from 'react-i18next'

import { DocumentFileUploads } from './DocumentFileUploads'
import { FileUploadsProps } from './FileUploads'

export const DoubleSidedDocumentFileUploads: React.FC<FileUploadsProps> = (props) => {
  const { t } = useTranslation()

  return (
    <DocumentFileUploads
      {...props}
      title={[t('Document front side'), t('Document back side')]}
      maxPlaceholders={2}
      maxFiles={2}
    />
  )
}
