import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'

import { copyToClipboard } from '../../utils/navigator.utils'
import { useSnackbar } from '../context/SnackbarContext'

import styles from './CopyLink.module.scss'

interface Props {
  value: string | undefined
  label?: string
  onCopy?: () => void
}

export const CopyLink: React.FunctionComponent<PropsWithChildren<Props>> = ({
  value,
  children,
  label,
  onCopy,
}) => {
  const { addSnackbar } = useSnackbar()
  const { t } = useTranslation()

  const handleOnClick = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation()
    copyToClipboard(value)
    addSnackbar.success({ message: label ? label : t('Text/link copied!') })

    onCopy?.()
  }

  return (
    <span className={styles.wrapper} onClick={handleOnClick}>
      {children}
    </span>
  )
}
