export interface CreatePhoneNumberVerification {
  leadId: string
  countryCode: string
  phoneNumber: string
  countryId: string
  workflowId: PhoneVerificationWorkflowEnum | null
}

export interface RestartPhoneNumberVerification {
  workflow: PhoneVerificationWorkflowEnum | null
}

export enum PhoneVerificationWorkflowEnum {
  WorkflowSMS = 6,
  WorkFlowTTS = 7,
}
