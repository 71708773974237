export enum TransactionCategoryType {
  TRANSACTION_TYPE = 'TRANSACTION_TYPE',
  DEPOSIT_TRANSACTION_TYPE = 'DEPOSIT_TRANSACTION_TYPE',
}

export const isTransactionCategory = (value: string) => {
  return value === TransactionCategoryType.TRANSACTION_TYPE
}

export const isTransactionDepositCategory = (value: string) => {
  return value === TransactionCategoryType.DEPOSIT_TRANSACTION_TYPE
}
