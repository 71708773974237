import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { useHomePageUrl } from '../../hooks/useHomePageUrl'
import { isTickmillProductTypeETD } from '../../model/TickmillProductType'
import { AuthSessionContext } from '../../utils/AuthContext'
import { isTickmillEUType, isTickmillUKType } from '../../utils/companyName.utils'
import { useWindowResize } from '../../utils/domUtils'
import { isSignUpPath } from '../../utils/path'
import { ExternalLink } from '../ExternalLink'
import { useSessionEntity } from '../context/EntityContext'
import { useProductReadContext } from '../context/ProductContext'
import { BlogModal } from './BlogModal'

import styles from './NavBarMenu.module.scss'

export const NavBarMenu: React.FC = () => {
  const { product } = useProductReadContext()
  const location = useLocation()
  const isSignUp = isSignUpPath(location.pathname)
  return (
    <div
      className={classNames(styles.navbarItemWrapper, {
        [styles.isETDSelected]: isTickmillProductTypeETD(product) && !isSignUp,
      })}
    >
      <TickmillBlogLink />
      <SupportLink />
    </div>
  )
}

const SupportLink: React.FC = () => {
  const { t } = useTranslation()

  const [auth] = useContext(AuthSessionContext)

  const link = auth ? '/contact-us' : '/support'

  return <NavTabLink to={link}>{t('Support.Support')}</NavTabLink>
}

const TickmillBlogLink = () => {
  const { t } = useTranslation()

  const entity = useSessionEntity()
  const isMobile = useWindowResize()
  const homePageUrl = useHomePageUrl()
  const { product } = useProductReadContext()

  const [isBlogModalVisible, setBlogModalVisible] = useState(false)

  if (isTickmillProductTypeETD(product) && !isMobile) {
    return null
  }

  if (isTickmillEUType(entity) || isTickmillUKType(entity)) {
    return (
      <div onClick={() => setBlogModalVisible(true)}>
        {isBlogModalVisible && <BlogModal setModalVisible={setBlogModalVisible} />}

        <span className={styles.navbarItem}>{isMobile ? t('Tickmill Blog') : t('Blog')}</span>
      </div>
    )
  }

  return (
    <ExternalLink url={homePageUrl + '/blog'} className={styles.navbarItem}>
      {isMobile ? t('Tickmill Blog') : t('Blog')}
    </ExternalLink>
  )
}

const NavTabLink: React.FC<NavLinkProps> = (props) => {
  const { children } = props
  return (
    <NavLink
      {...props}
      className={({ isActive }) => classNames(styles.navbarItem, { 'is-active': isActive })}
    >
      {children}
    </NavLink>
  )
}
