import styles from './Icons.module.scss'

export function TickmillLogoIcon() {
  return (
    <svg width='71' height='80' viewBox='0 0 71 80' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.236 31.5334C18.262 31.071 18.396 30.6211 18.6271 30.2197C18.8582 29.8182 19.18 29.4763 19.567 29.2211L34.1688 20.7545C34.5808 20.5453 35.0364 20.4363 35.4986 20.4363C35.9608 20.4363 36.4164 20.5453 36.8284 20.7545L49.5712 28.1418L67.5539 17.7887C67.4288 17.6857 67.2963 17.5922 67.1573 17.509L38.7494 0.788591C37.7452 0.270407 36.6313 0 35.5011 0C34.3708 0 33.257 0.270407 32.2528 0.788591L3.83989 17.504C2.87835 18.1536 2.08113 19.0175 1.51117 20.0276C0.941212 21.0377 0.614103 22.1662 0.555664 23.3242V56.3639L18.236 46.194V31.5334Z'
        fill={styles.primary}
      />
      <path
        d='M52.7642 33.8151V48.4658C52.7385 48.9286 52.6048 49.3789 52.3736 49.7809C52.1425 50.1828 51.8204 50.5251 51.4332 50.7805L36.8289 59.2471C36.4169 59.4563 35.9613 59.5653 35.4991 59.5653C35.0369 59.5653 34.5813 59.4563 34.1693 59.2471L21.4166 51.8599L3.44629 62.2129C3.57365 62.3128 3.70601 62.4062 3.84288 62.4927L32.2533 79.2106C33.2575 79.7288 34.3713 79.9992 35.5016 79.9992C36.6319 79.9992 37.7457 79.7288 38.7499 79.2106L67.1603 62.4927C68.1218 61.8431 68.919 60.9791 69.489 59.9691C70.059 58.959 70.3861 57.8305 70.4445 56.6725V23.6328L52.7642 33.8151Z'
        fill={styles.primary}
      />
    </svg>
  )
}
