type SubscriptionRequestStateKey =
  | 'Pending'
  | 'DeductingFunds'
  | 'InProcess'
  | 'Completed'
  | 'Failed'
  | 'InProcessCancel'
  | 'Cancelled'
  | 'Active'

export type SubscriptionRequestStateValue =
  | 'Pending'
  | 'Deducting Funds'
  | 'In Process'
  | 'Completed'
  | 'Failed'
  | 'In Process Cancel'
  | 'Cancelled'
  | 'Active'

export const SubscriptionRequestState: Record<
  SubscriptionRequestStateKey,
  SubscriptionRequestStateValue
> = {
  Pending: 'Pending',
  DeductingFunds: 'Deducting Funds',
  InProcess: 'In Process',
  Completed: 'Completed',
  Failed: 'Failed',
  InProcessCancel: 'In Process Cancel',
  Cancelled: 'Cancelled',
  Active: 'Active',
}
