import { useProductReadContext } from '../../global/context/ProductContext'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { isAccountDetailedActivatedStatus } from '../../model/AccountDetailedStatus'
import { TickmillProductType } from '../../model/TickmillProductType'
import { useAccountReadContext } from '../AccountContextContext'
import { isTickmillUK } from '../companyName.utils'
import { hasProductPendingDocumentVerificationStatus } from './accountDocumentStatuses'
import {
  hasProductActivatedStatus,
  hasProductAppropriatenessTestSubmittedStatus,
  hasProductEmailVerifiedStatus,
} from './accountProductStatuses'

export const useAccountAccessStatuses = () => {
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()

  return getAccountAccessStatuses(account, product)
}

export const getAccountAccessStatuses = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType | undefined
) => {
  const { isAccountActivatedStatus: accountActivated } = isAccountActivationStatus(account, product)
  const { isProductActivatedStatus: productActivated } = isProductActivationStatus(account, product)
  const { isAccountPendingStatus: accountPending } = isAccountPendingStatus(account, product)

  return {
    isAccountActivatedStatus: accountActivated,
    isProductActivatedStatus: productActivated,
    isAccountPendingStatus: accountPending,
  }
}

const isAccountActivationStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType | undefined
) => {
  const isTickmillUKType = isTickmillUK(account)
  const isProductActivated = isProductActivationStatus(account, product)
  const isAccountActivated = isTickmillUKType
    ? isProductActivated.isProductActivatedStatus
    : isAccountDetailedActivatedStatus(account?.status.id || -1)

  return { isAccountActivatedStatus: isAccountActivated }
}

const isAccountPendingStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType | undefined
) => {
  const isAccountPending =
    hasProductAppropriatenessTestSubmittedStatus(account, product) ||
    hasProductPendingDocumentVerificationStatus(account, product) ||
    hasProductEmailVerifiedStatus(account, product)

  return { isAccountPendingStatus: isAccountPending }
}

const isProductActivationStatus = (
  account: AccountDetailedDto | undefined,
  product: TickmillProductType | undefined
) => {
  const isProductActivated = hasProductActivatedStatus(account, product)

  return { isProductActivatedStatus: isProductActivated }
}
