import { InitOrCreatePspToWalletDepositTransactionCommand } from './InitOrCreatePspToWalletDepositTransactionCommand'
import {
  FormItemDto,
  isFormItemInputDto,
  isFormItemSelectDto,
} from './WalletPaymentProviderParametersDto'

export interface CreatePspToWalletDepositTransaction {
  fromPaymentProvider: Wallet
  toWallet: Wallet
  tradingAccount?: TradingAccount
  amount: number | undefined
  comment: string
  terms: boolean
  fields?: { [key: string]: string }
  form: FormItemDto[]
}

interface Wallet {
  id: string | undefined
  currency: { id: string | undefined }
}

interface TradingAccount {
  id: string | undefined
  campaign: {
    id: string | undefined
  }
}

export const denormalizeCreatePspToWalletDepositTransaction = (
  values: CreatePspToWalletDepositTransaction,
  locale: string
): Omit<InitOrCreatePspToWalletDepositTransactionCommand, 'caller' | 'clientIpAddress'> => {
  return {
    languageId: locale,
    fromPspId: getFromPspId(values),
    toWalletId: getToWallet(values),
    pspCurrencyId: getCurrencyValue(values),
    amount: getAmount(values),
    comment: getComment(values),
    details: getDetails(values),
  }
}

const getAmount = (values: CreatePspToWalletDepositTransaction): string => {
  return values.amount?.toString() || '0'
}

const getFromPspId = (values: CreatePspToWalletDepositTransaction): string => {
  return values.fromPaymentProvider.id || ''
}

const getToWallet = (values: CreatePspToWalletDepositTransaction): string => {
  return values.toWallet.id || ''
}

const getComment = (values: CreatePspToWalletDepositTransaction): string => {
  return values.comment || ''
}

const getTerms = (values: CreatePspToWalletDepositTransaction): string => {
  return values.terms ? '1' : '0'
}

const getTradingAccountId = (values: CreatePspToWalletDepositTransaction): string => {
  return values?.tradingAccount?.id || ''
}

const getTradingAccountCampaignId = (values: CreatePspToWalletDepositTransaction): string => {
  return values?.tradingAccount?.campaign?.id || ''
}

const getCurrencyValue = (values: CreatePspToWalletDepositTransaction): string => {
  const paymentCurrencyField = 'payment_currency_id'
  const paymentCurrencyForm = (values.form || []).find((x) => x.field === paymentCurrencyField)

  if (isFormItemSelectDto(paymentCurrencyForm)) {
    return values.fromPaymentProvider.currency.id || ''
  }

  if (isFormItemInputDto(paymentCurrencyForm)) {
    return paymentCurrencyForm.value
  }

  return values.toWallet.currency.id || ''
}

const getDetails = (
  values: CreatePspToWalletDepositTransaction
): { name: string; value: string }[] => {
  const details = Object.entries(values?.fields || []).map(([name, value]) => ({ name, value }))

  return [
    { name: 'amount', value: getAmount(values) },
    { name: 'additionalComment', value: getComment(values) },
    { name: 'terms', value: getTerms(values) },
    { name: 'tradingAccountId', value: getTradingAccountId(values) },
    { name: 'campaignId', value: getTradingAccountCampaignId(values) },
    ...details,
  ].filter((x) => !!x.value)
}
