import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'

import { Button } from '../global/button/Button'
import { SelectField } from '../global/field/SelectField'
import { OptionSelectModal } from '../global/modal/OptionSelectModal'
import { ScrollToIds, useScrollToElementIds } from '../hooks/useScrollToElementIds'
import { ReportIcon } from '../icons/ReportIcon'
import { StatementType } from '../model/Statements'
import { TextH3 } from '../ui/Typography/Typography'
import { useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import {
  getDateFromDay,
  getDateRangeOfLastYear,
  getDayOptions,
  getMonthOptions,
} from '../utils/date.utils'
import { getAvailableDates, getYearSplitter } from '../utils/statements'
import { useFetchOne } from '../utils/useFetch'
import { DownloadedStatement } from './DownloadedStatement'
import { useStatementsTAId } from './StatementsETD'

import styles from './Statement.module.scss'

interface StatementProps {
  type: StatementType
}

export const StatementPage: React.FC<StatementProps> = ({ type }) => {
  const { tradingAccountId } = useStatementsTAId()
  const apiClient = useApiClient(ClientApiClient)
  const { scrollIntoView } = useScrollToElementIds()

  const { t } = useTranslation()
  const [monthModalOpen, setMonthModalOpen] = useState(false)
  const [loading, setLoading] = useState(true)
  const [month, setMonth] = useState<number>()
  const [dayModalOpen, setDayModalOpen] = useState(false)
  const [day, setDay] = useState<number>()
  const [downloaded, setDownloaded] = useState(false)
  const isDaily = useMemo(() => type === StatementType.Daily, [type])

  const availableDatesCallback = useCallback(async () => {
    setLoading(true)
    if (!tradingAccountId) {
      return
    }
    try {
      const monthData = getDateRangeOfLastYear()
      const response = await apiClient.checkTradingAccountStatement(
        tradingAccountId,
        isDaily ? StatementType.Daily : StatementType.Monthly,
        {
          dateRangeStart: format(monthData.dateRangeStart, 'yyyy-MM-dd'),
          dateRangeEnd: format(monthData.dateRangeEnd, 'yyyy-MM-dd'),
        }
      )
      return response.dates
    } finally {
      setLoading(false)
    }
  }, [tradingAccountId, apiClient, isDaily])

  const { data: availableDatesFetch } = useFetchOne(availableDatesCallback)

  const availableDates = useMemo(
    () => getAvailableDates(t, availableDatesFetch),
    [availableDatesFetch, t]
  )

  const dayOptions = useMemo(
    () => availableDates?.availableDayOptions.find((option) => option.month === month)?.days || [],
    [availableDates, month]
  )

  const statementsCallback = useCallback(async () => {
    if (month && tradingAccountId) {
      try {
        setLoading(true)

        const fromDay = availableDates?.availableDayOptions.find((d) => d.month === month)
          ?.days?.[0].id
        const monthDate = getDateFromDay(month, day || fromDay || 1)
        const date = format(monthDate, 'yyyy-MM-dd')
        const response = await apiClient.getTradingAccountStatement(tradingAccountId, type, {
          date,
        })
        setDownloaded(true)
        window.location.assign(response.url)
      } finally {
        setLoading(false)
      }
    }
  }, [apiClient, availableDates?.availableDayOptions, day, month, tradingAccountId, type])

  useLayoutEffect(() => {
    scrollIntoView([ScrollToIds.StatementsETDHeader])
  }, [downloaded, scrollIntoView])

  if (downloaded) {
    return <DownloadedStatement goBack={() => setDownloaded(false)} />
  }

  return (
    <div className={styles.statement}>
      {monthModalOpen && (
        <OptionSelectModal
          splitter={getYearSplitter(availableDates?.availableMonthOptions || [])}
          options={availableDates?.availableMonthOptions || []}
          title={t('Statements.Select Month')}
          onClose={() => setMonthModalOpen(false)}
          onSelectOption={setMonth}
          selectedOption={month}
        />
      )}
      {dayModalOpen && month && (
        <OptionSelectModal
          options={dayOptions}
          title={t('Statements.Select Day')}
          onClose={() => setDayModalOpen(false)}
          onSelectOption={setDay}
          selectedOption={day}
        />
      )}
      <div className={styles.reportIconWrapper}>
        <ReportIcon size={64} color='disabled' />
      </div>
      <TextH3 className={styles.title}>{t('Statements.Download Statement')}</TextH3>
      <div className={styles.selectorsWrapper}>
        <SelectField
          options={getMonthOptions(t)?.map(({ id, label }) => ({
            value: id,
            label,
          }))}
          radio
          disabled={
            !availableDates?.availableMonthOptions.length || availableDates?.lockMonths || loading
          }
          value={month}
          className={styles.selectorField}
          onClick={() => setMonthModalOpen(true)}
          label={t('Statements.Select Month')}
        />
        {isDaily && (
          <SelectField
            options={getDayOptions(month)?.map(({ id, label }) => ({
              value: id,
              label,
            }))}
            radio
            value={day}
            disabled={!month || loading}
            className={styles.selectorField}
            onClick={() => setDayModalOpen(true)}
            label={t('Statements.Select Day')}
          />
        )}
      </div>
      <Button
        disabled={!month || (isDaily && !day) || !tradingAccountId}
        className={styles.button}
        appearance='primary'
        loading={loading}
        size='L'
        onClick={statementsCallback}
      >
        {t('Statements.Download Statement')}
      </Button>
    </div>
  )
}
