import React from 'react'
import { useTranslation } from 'react-i18next'

import { useSessionEntity } from '../../../global/context/EntityContext'
import {
  UploadDocumentTypeEnum,
  isClientClassificationDocumentType,
  isCryptoWalletTransactionStatementDocumentType,
  isDrivingLicenseDocumentType,
  isNationalIdentityCardDocumentType,
  isOtherDocumentType,
  isPassportDocumentType,
  isProofOfAddressDocumentType,
  isResidencePermitDocumentType,
  isTaxFormDocumentType,
} from '../../../model/UploadDocumentTypeEnum'
import { Text } from '../../../ui/Typography/Typography'
import { TickmillCompaniesEnum } from '../../../utils/companyName.utils'
import { DocumentManagementNonNCIFormFieldsFactoryProps } from './DocumentManagementNonNCIForm'

import styles from './DocumentManagementNonNCIForm.module.scss'

export const useDocumentText = (
  props: DocumentManagementNonNCIFormFieldsFactoryProps
): React.ReactNode | null => {
  const textType = useDocumentTextByType(props.documentId)
  const textTitle = useDocumentTextByTitle(props)

  return textType || textTitle || ''
}

export const useDocumentTextByType = (
  documentId?: UploadDocumentTypeEnum
): React.ReactNode | null => {
  const { t } = useTranslation()
  const entity = useSessionEntity()

  if (isDrivingLicenseDocumentType(documentId)) {
    return (
      <Text isParagraph>
        {t('Profile.Please upload an identification document displaying your full name Non NCI')}
      </Text>
    )
  }

  if (isNationalIdentityCardDocumentType(documentId)) {
    return (
      <Text isParagraph>
        {t('Profile.Please upload an identification document displaying your full name Non NCI')}
      </Text>
    )
  }

  if (isProofOfAddressDocumentType(documentId)) {
    if (entity === TickmillCompaniesEnum.TICKMILL_UK) {
      return (
        <Text isParagraph>
          {t('Profile.Proof of address must be no more than', {
            length: 90,
          })}
        </Text>
      )
    } else if (
      entity === TickmillCompaniesEnum.TICKMILL_EU ||
      entity === TickmillCompaniesEnum.TICKMILL_SC
    ) {
      return (
        <Text isParagraph>
          {t('Profile.Proof of address must be no more than', {
            length: 180,
          })}
        </Text>
      )
    }

    return (
      <Text isParagraph>
        {t('Profile.Proof of address must be no more than', {
          length: 180,
        })}
      </Text>
    )
  }

  if (isOtherDocumentType(documentId)) {
    return (
      <div>
        <Text isParagraph>
          {t(
            'Profile.For any additional documents or information required beyond the mentioned categories'
          )}
        </Text>
        <Text isParagraph>
          {t(
            'Profile.The images must be high quality, unobstructed and show the full document page'
          )}
        </Text>
      </div>
    )
  }

  if (isPassportDocumentType(documentId)) {
    return (
      <Text isParagraph>
        {t('Profile.Please upload an identification document displaying your full name Non NCI')}
      </Text>
    )
  }

  if (isResidencePermitDocumentType(documentId)) {
    return (
      <Text isParagraph>
        {t('Profile.Please upload an identification document displaying your full name Non NCI')}
      </Text>
    )
  }

  if (isClientClassificationDocumentType(documentId)) {
    return (
      <div className={styles.text}>
        <p className='mb-2'>
          {t('Profile.If you have applied to be reclassified as a Professional Client')}
        </p>
        <ul className='ml-4'>
          <li className='mb-2'>
            <div className='is-flex'>
              <span className='mr-1'>a.</span>
              <ul>
                <li>
                  {t(
                    'Profile.You have conducted an average of more than 10 significant trades per quarter over the last year'
                  )}
                </li>
              </ul>
            </div>
          </li>
          <li className='mb-2'>
            <div className='is-flex'>
              <span className='mr-1'>b.</span>
              <ul>
                <li>
                  {t(
                    'Profile.The current value of your investment portfolio is equal or greater than 500 000 EUR/USD/GBP'
                  )}
                </li>
              </ul>
            </div>
          </li>
          <li className='mb-2'>
            <div className='is-flex'>
              <span className='mr-1'>c.</span>
              <ul>
                <li>
                  <p>
                    {t(
                      'Profile.You have a minimum of one year of professional experience in the financial sector in a position that required knowledge of CFDs'
                    )}
                  </p>
                  <p>
                    {t(
                      'Profile.Trading statements, bank statements, employment confirmation letter'
                    )}
                  </p>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    )
  }

  if (isCryptoWalletTransactionStatementDocumentType(documentId)) {
    return <Text isParagraph>{t('Profile.Provide your Crypto Wallet Transaction statement')}</Text>
  }

  if (isTaxFormDocumentType(documentId)) {
    return (
      <Text isParagraph>{t('Profile.Upload the completed and signed W-8BEN-E/W-8BEN form')}</Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Certificate_of_Incorporation) {
    return (
      <Text isParagraph>
        {t(
          'Company.Must include the companys official full name registration number and registration location'
        )}
      </Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Certificate_of_Shareholders) {
    return (
      <Text isParagraph>
        {t(
          'Company.Must include the full ownership of the shares issued Provide the latest version of the certificate'
        )}
      </Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Proof_of_Deposit) {
    return (
      <Text isParagraph>
        {t('Profile.Please upload a Proof of Deposit displaying your latest deposit transaction')}
      </Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Certificate_of_Members_Directors) {
    return (
      <Text isParagraph>
        {t(
          'Company.Must include the details of all Directors Provide the latest version of the certificate'
        )}
      </Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Memorandum_and_Articles_of_Association) {
    return (
      <Text isParagraph>
        {t('Company.The image must be highquality clear and show the entire document page')}
      </Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Registration_Card) {
    return (
      <Text isParagraph>
        {t('Company.The image must be highquality clear and show the entire document page')}
      </Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Auditable_Accounts) {
    return (
      <Text isParagraph>
        {t('Company.Provide the latest Audited Financial Statements andor valuation reports')}
      </Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Board_Resolution) {
    return (
      <Text isParagraph>
        {t(
          'Company.Provide a Board Resolution stating the intention of opening an account with us and the authorized person responsible for operating the account'
        )}
      </Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Bank_Statement) {
    if (entity === TickmillCompaniesEnum.TICKMILL_UK) {
      return (
        <Text isParagraph>{t('Profile.Bank Statement must be no more than', { length: 90 })}</Text>
      )
    } else if (
      entity === TickmillCompaniesEnum.TICKMILL_SC ||
      entity === TickmillCompaniesEnum.TICKMILL_EU
    ) {
      return (
        <Text isParagraph>{t('Profile.Bank Statement must be no more than', { length: 180 })}</Text>
      )
    }

    return (
      <Text isParagraph>{t('Profile.Bank Statement must be no more than', { length: 180 })}</Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Company_proof_of_Address) {
    return (
      <Text isParagraph>
        {t(
          'Company.The Proof of Address must be no older than 180 days utility bill bank statement other government-issued document'
        )}
      </Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.Source_of_Funds) {
    return (
      <Text isParagraph>
        {t('Profile.Please upload a document as a proof of your source of funds')}
      </Text>
    )
  }

  if (documentId === UploadDocumentTypeEnum.GOODS_SERVICES_TAX_CERTIFICATE) {
    return (
      <Text isParagraph>{t('Profile.Please provide the Goods & Services Tax Certificate')}</Text>
    )
  }

  return null
}

const useDocumentTextByTitle = (
  props: DocumentManagementNonNCIFormFieldsFactoryProps
): React.ReactNode | null => {
  const { documentTitle } = props

  const { t } = useTranslation()

  const entity = useSessionEntity()

  if (documentTitle === t('Profile.Identification')) {
    return (
      <Text isParagraph>
        {t('Profile.Please upload an identification document displaying your full name Non NCI')}
      </Text>
    )
  }

  if (
    documentTitle === t('Sign up.Proof of Address') ||
    documentTitle === t('Sign up.Additional documents')
  ) {
    if (entity === TickmillCompaniesEnum.TICKMILL_UK) {
      return (
        <Text isParagraph>
          {t('Profile.Proof of address must be no more than', {
            length: 90,
          })}
        </Text>
      )
    } else if (
      entity === TickmillCompaniesEnum.TICKMILL_EU ||
      entity === TickmillCompaniesEnum.TICKMILL_SC
    ) {
      return (
        <Text isParagraph>
          {t('Profile.Proof of address must be no more than', {
            length: 180,
          })}
        </Text>
      )
    }

    return (
      <Text isParagraph>
        {t('Profile.Proof of address must be no more than', {
          length: 180,
        })}
      </Text>
    )
  }

  return null
}
