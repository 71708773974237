import { IconProps } from './IconProps'

export function MicrosoftStoreIcon(props: IconProps) {
  return (
    <svg
      {...props}
      width='117'
      height='40'
      viewBox='0 0 117 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect x='0.5' y='0.5' width='115.8' height='39' rx='6.5' fill='#0F0209' stroke='#D2D2D2' />
      <path d='M21.4277 7.75H10.3999V19.5567H21.4277V7.75Z' fill='#F25022' />
      <path d='M33.6006 7.75H22.5728V19.5567H33.6006V7.75Z' fill='#7FBA00' />
      <path d='M21.4277 20.7686H10.3999V32.5753H21.4277V20.7686Z' fill='#00A4EF' />
      <path d='M33.6006 20.7686H22.5728V32.5753H33.6006V20.7686Z' fill='#FFB900' />
      <path
        d='M54.8535 21.05C54.8535 20.6977 54.972 20.416 55.2088 20.1764C55.4457 19.9369 55.7221 19.8242 56.051 19.8242C56.3932 19.8242 56.6827 19.951 56.9064 20.1905C57.1301 20.4301 57.2486 20.7259 57.2486 21.05C57.2486 21.3881 57.1301 21.684 56.8933 21.9094C56.6564 22.1489 56.38 22.2616 56.0379 22.2616C55.6957 22.2616 55.4194 22.1489 55.1825 21.9094C54.972 21.6699 54.8535 21.3881 54.8535 21.05ZM57.0249 32.5749H55.0641V23.6283H57.0249V32.5749Z'
        fill='white'
      />
      <path
        d='M62.9995 31.0392C63.289 31.0392 63.618 30.9688 63.9733 30.8279C64.3286 30.687 64.6444 30.4897 64.9471 30.2502V32.2086C64.6313 32.4059 64.276 32.5468 63.8812 32.6454C63.4864 32.744 63.039 32.8004 62.5652 32.8004C61.3414 32.8004 60.3412 32.3777 59.5648 31.5464C58.7884 30.7152 58.4067 29.6444 58.4067 28.3623C58.4067 26.9252 58.8015 25.7417 59.5911 24.8118C60.3807 23.8819 61.4861 23.417 62.9337 23.417C63.3021 23.417 63.6706 23.4733 64.0523 23.572C64.4339 23.6706 64.7234 23.7974 64.9471 23.9242V25.9389C64.6444 25.6994 64.3286 25.5163 64.0128 25.3895C63.6969 25.2627 63.3679 25.1922 63.039 25.1922C62.2625 25.1922 61.644 25.4599 61.1571 25.9953C60.6834 26.5307 60.4465 27.2633 60.4465 28.1791C60.4465 29.0808 60.6702 29.7853 61.1308 30.2925C61.5914 30.7997 62.2099 31.0392 62.9995 31.0392Z'
        fill='white'
      />
      <path
        d='M70.5399 23.4873C70.6978 23.4873 70.8426 23.5014 70.961 23.5155C71.0794 23.5437 71.1979 23.5718 71.2768 23.6V25.7275C71.1715 25.6429 71.0268 25.5725 70.8294 25.5021C70.632 25.4316 70.3951 25.3893 70.1056 25.3893C69.6187 25.3893 69.2108 25.6007 68.8818 26.0374C68.5528 26.4742 68.3817 27.1364 68.3817 28.0522V32.5748H66.4209V23.6282H68.3817V25.0371H68.408C68.5922 24.544 68.8554 24.1636 69.2239 23.8959C69.5924 23.6282 70.0267 23.4873 70.5399 23.4873Z'
        fill='white'
      />
      <path
        d='M71.3818 28.2355C71.3818 26.7561 71.7766 25.5867 72.553 24.7132C73.3295 23.8538 74.4217 23.417 75.8035 23.417C77.1063 23.417 78.1328 23.8397 78.8697 24.6709C79.6066 25.5022 79.9751 26.6293 79.9751 28.0382C79.9751 29.4894 79.5803 30.6447 78.8039 31.5042C78.0275 32.3636 76.9615 32.7863 75.6193 32.7863C74.3296 32.7863 73.29 32.3777 72.5399 31.5605C71.7635 30.7574 71.3818 29.6444 71.3818 28.2355ZM73.4347 28.165C73.4347 29.0949 73.6321 29.8135 74.0269 30.3066C74.4217 30.7997 74.9876 31.0392 75.7245 31.0392C76.4352 31.0392 76.9879 30.7997 77.3563 30.3066C77.7248 29.8135 77.9222 29.0808 77.9222 28.1087C77.9222 27.1506 77.7248 26.418 77.3432 25.9249C76.9615 25.4317 76.4088 25.1922 75.7114 25.1922C74.9876 25.1922 74.4349 25.4458 74.0269 25.9671C73.6321 26.4884 73.4347 27.207 73.4347 28.165Z'
        fill='white'
      />
      <path
        d='M82.8968 25.9807C82.8968 26.2765 82.9889 26.516 83.16 26.6851C83.3442 26.8542 83.739 27.0655 84.3444 27.3332C85.1339 27.6714 85.6866 28.0518 86.0025 28.4744C86.3183 28.8971 86.4762 29.4043 86.4762 29.9961C86.4762 30.8414 86.1736 31.5177 85.5682 32.0249C84.9629 32.5321 84.147 32.7857 83.1205 32.7857C82.7783 32.7857 82.3967 32.7434 81.9756 32.6448C81.5545 32.5603 81.1992 32.4335 80.9097 32.3067V30.2356C81.265 30.5033 81.6466 30.7146 82.0677 30.8696C82.4757 31.0246 82.8573 31.1091 83.1863 31.1091C83.6337 31.1091 83.9627 31.0387 84.1733 30.9119C84.3838 30.7851 84.4891 30.5596 84.4891 30.2356C84.4891 29.9397 84.3838 29.7002 84.1601 29.5029C83.9364 29.3057 83.5284 29.0662 82.9099 28.8126C82.1862 28.4885 81.6729 28.1222 81.3703 27.7136C81.0676 27.305 80.9097 26.7837 80.9097 26.1638C80.9097 25.3607 81.2123 24.6985 81.8045 24.1772C82.3967 23.6559 83.1731 23.4023 84.1338 23.4023C84.4233 23.4023 84.7523 23.4305 85.1208 23.501C85.4892 23.5714 85.7919 23.6559 86.0288 23.7687V25.7693C85.7656 25.5862 85.4629 25.4312 85.1208 25.2903C84.7786 25.1635 84.4233 25.093 84.0943 25.093C83.7258 25.093 83.4232 25.1776 83.2258 25.3326C83.0021 25.4875 82.8968 25.713 82.8968 25.9807Z'
        fill='white'
      />
      <path
        d='M87.3447 28.2355C87.3447 26.7561 87.7395 25.5867 88.5159 24.7132C89.2924 23.8538 90.3846 23.417 91.7664 23.417C93.0692 23.417 94.0956 23.8397 94.8326 24.6709C95.5695 25.5022 95.938 26.6293 95.938 28.0382C95.938 29.4894 95.5432 30.6447 94.7668 31.5042C93.9904 32.3636 92.9244 32.7863 91.5822 32.7863C90.2925 32.7863 89.2529 32.3777 88.5028 31.5605C87.7264 30.7574 87.3447 29.6444 87.3447 28.2355ZM89.3845 28.165C89.3845 29.0949 89.5819 29.8135 89.9767 30.3066C90.3715 30.7997 90.9373 31.0392 91.6743 31.0392C92.3849 31.0392 92.9376 30.7997 93.3061 30.3066C93.6745 29.8135 93.8719 29.0808 93.8719 28.1087C93.8719 27.1506 93.6745 26.418 93.2929 25.9249C92.9113 25.4317 92.3586 25.1922 91.6611 25.1922C90.9373 25.1922 90.3846 25.4458 89.9767 25.9671C89.5819 26.4884 89.3845 27.207 89.3845 28.165Z'
        fill='white'
      />
      <path
        d='M102.426 25.3615V29.9545C102.426 30.9126 102.636 31.617 103.044 32.0961C103.452 32.5751 104.084 32.8005 104.913 32.8005C105.189 32.8005 105.479 32.7724 105.768 32.7019C106.058 32.6315 106.268 32.5751 106.387 32.4906V30.7435C106.268 30.8281 106.123 30.8985 105.966 30.9549C105.808 31.0112 105.663 31.0394 105.558 31.0394C105.163 31.0394 104.86 30.9267 104.676 30.7013C104.492 30.4758 104.4 30.0813 104.4 29.5319V25.3474H106.4V23.6285H104.413V20.9797L102.439 21.6279V23.6426H99.5041V22.5577C99.5041 22.0223 99.6094 21.6138 99.8331 21.332C100.057 21.0502 100.373 20.9234 100.781 20.9234C100.991 20.9234 101.175 20.9516 101.346 21.0079C101.504 21.0643 101.623 21.1206 101.689 21.1629V19.3454C101.544 19.2891 101.386 19.2609 101.202 19.2327C101.017 19.2045 100.807 19.1904 100.57 19.1904C99.6752 19.1904 98.9382 19.4863 98.3724 20.0921C97.8065 20.698 97.517 21.4588 97.517 22.4028V23.6426H96.1221V25.3615H97.517V32.5892H99.5041V25.3615H102.426Z'
        fill='white'
      />
      <path
        d='M53.169 20.0918V32.5748H51.1424V22.7969H51.1161L47.4971 32.5748H46.1549L42.4438 22.7969H42.4175V32.5748H40.5488V20.0918H43.444L46.7997 29.3484H46.8523L50.3923 20.0918H53.169Z'
        fill='white'
      />
      <path
        d='M45.8655 14.6952C45.168 15.1179 44.3784 15.3292 43.4967 15.3292C42.8256 15.3292 42.2334 15.1743 41.707 14.8502C41.1938 14.5403 40.7858 14.0894 40.5095 13.5117C40.2331 12.9341 40.0884 12.286 40.0884 11.5674C40.0884 10.8066 40.2463 10.1163 40.549 9.52452C40.8516 8.93278 41.2859 8.45375 41.8518 8.11561C42.4176 7.77747 43.0493 7.6084 43.7599 7.6084C44.1152 7.6084 44.4706 7.65067 44.8259 7.72111C45.1812 7.79156 45.4707 7.89018 45.6944 8.00289V9.13003C45.168 8.74962 44.4969 8.56646 43.681 8.56646C43.1941 8.56646 42.7598 8.69326 42.365 8.94687C41.9702 9.20047 41.6675 9.5527 41.457 9.98946C41.2464 10.4403 41.1412 10.9475 41.1412 11.5111C41.1412 12.3987 41.3649 13.1032 41.7991 13.6245C42.2334 14.1458 42.8388 14.3994 43.602 14.3994C44.0889 14.3994 44.5232 14.3007 44.8917 14.0894V12.1592H43.4046V11.2293H45.8655V14.6952Z'
        fill='white'
      />
      <path
        d='M51.5374 12.8214H48.0895C48.1027 13.3568 48.2606 13.7654 48.5238 14.0613C48.8001 14.3431 49.1555 14.498 49.6029 14.498C50.1951 14.498 50.7083 14.3149 51.1557 13.9486V14.8503C50.9583 14.9912 50.7083 15.118 50.4056 15.2025C50.103 15.287 49.7871 15.3293 49.4713 15.3293C48.7212 15.3293 48.129 15.0898 47.721 14.6108C47.3131 14.1317 47.1025 13.4554 47.1025 12.5819C47.1025 12.0465 47.2078 11.5534 47.4052 11.1307C47.6026 10.694 47.8921 10.3558 48.2474 10.1163C48.6027 9.8768 49.0107 9.75 49.445 9.75C50.0898 9.75 50.603 9.97543 50.9715 10.4263C51.34 10.8771 51.5242 11.4971 51.5242 12.3001V12.8214H51.5374ZM50.5767 12.0184C50.5767 11.5534 50.4714 11.2012 50.274 10.9617C50.0766 10.7222 49.8003 10.5953 49.4318 10.5953C49.1028 10.5953 48.8133 10.7222 48.5633 10.9758C48.3132 11.2294 48.1685 11.5816 48.0895 12.0184H50.5767Z'
        fill='white'
      />
      <path
        d='M55.2748 15.1466C55.1826 15.2029 55.0642 15.2452 54.8931 15.2734C54.7352 15.3015 54.5904 15.3156 54.4589 15.3156C53.5377 15.3156 53.0771 14.7662 53.0771 13.6813V10.7226H52.2349V9.87723H53.0771V8.60921L54.0377 8.28516V9.86314H55.2616V10.7085H54.0377V13.4981C54.0377 13.8504 54.0904 14.104 54.1957 14.2449C54.3009 14.3858 54.4852 14.4562 54.7615 14.4562C54.9458 14.4562 55.1168 14.3998 55.2616 14.2871V15.1466H55.2748Z'
        fill='white'
      />
      <path
        d='M60.0383 7.98892C60.0383 8.15799 59.9857 8.29888 59.8673 8.42568C59.7488 8.55249 59.6041 8.60884 59.433 8.60884C59.2619 8.60884 59.1171 8.55249 58.9987 8.42568C58.8803 8.31297 58.8276 8.15799 58.8276 7.97483C58.8276 7.79167 58.8934 7.63669 59.0119 7.52398C59.1303 7.41127 59.2751 7.34082 59.433 7.34082C59.5909 7.34082 59.7357 7.39718 59.8541 7.52398C59.9857 7.66487 60.0383 7.80576 60.0383 7.98892ZM59.9067 15.2026H58.9461V9.87686H59.9067V15.2026Z'
        fill='white'
      />
      <path
        d='M64.0785 15.1466C63.9863 15.2029 63.8679 15.2452 63.6968 15.2734C63.5389 15.3015 63.3942 15.3156 63.2626 15.3156C62.3414 15.3156 61.8808 14.7662 61.8808 13.6813V10.7226H61.0386V9.87723H61.8808V8.60921L62.8415 8.28516V9.86314H64.0653V10.7085H62.8415V13.4981C62.8415 13.8504 62.8941 14.104 62.9994 14.2449C63.1047 14.3858 63.2889 14.4562 63.5652 14.4562C63.7495 14.4562 63.9205 14.3998 64.0653 14.2871V15.1466H64.0785Z'
        fill='white'
      />
      <path
        d='M70.3688 8.18644C70.2109 8.1019 70.0398 8.04554 69.8424 8.04554C69.3029 8.04554 69.0397 8.39777 69.0397 9.10223V9.86304H70.1714V10.7084H69.0397V15.1887H68.079V10.7225H67.2368V9.87713H68.079V9.05996C68.079 8.4964 68.2238 8.04554 68.5265 7.70741C68.8291 7.36927 69.2371 7.2002 69.7635 7.2002C70.0267 7.2002 70.2241 7.22837 70.382 7.29882V8.18644H70.3688Z'
        fill='white'
      />
      <path
        d='M73.9086 10.9055C73.856 10.8632 73.7507 10.8209 73.6191 10.7786C73.4875 10.7505 73.3691 10.7223 73.2769 10.7223C72.9348 10.7223 72.6584 10.8914 72.4479 11.2154C72.2373 11.5395 72.132 11.9621 72.132 12.4834V15.2026H71.1714V9.87694H72.132V10.9477H72.1584C72.2636 10.5814 72.4347 10.2996 72.6584 10.0883C72.8821 9.87694 73.1453 9.77832 73.4349 9.77832C73.6323 9.77832 73.7902 9.8065 73.8954 9.84877V10.9055H73.9086Z'
        fill='white'
      />
      <path
        d='M79.3962 12.5115C79.3962 13.3709 79.1593 14.0613 78.6987 14.5685C78.2381 15.0757 77.6196 15.3293 76.8563 15.3293C76.1062 15.3293 75.5009 15.0757 75.0535 14.5826C74.606 14.0895 74.3823 13.4273 74.3823 12.596C74.3823 11.7084 74.6192 11.018 75.0798 10.5108C75.5404 10.0036 76.172 9.75 76.9748 9.75C77.7249 9.75 78.3171 9.98951 78.7382 10.4826C79.1856 10.9758 79.3962 11.652 79.3962 12.5115ZM78.4092 12.5396C78.4092 11.9056 78.2776 11.4266 78.0276 11.1026C77.7644 10.7785 77.4091 10.6094 76.9353 10.6094C76.4616 10.6094 76.0799 10.7785 75.8036 11.1307C75.5272 11.4689 75.3956 11.962 75.3956 12.5819C75.3956 13.1877 75.5272 13.6527 75.8036 13.9908C76.0799 14.329 76.4484 14.498 76.9353 14.498C77.4222 14.498 77.7907 14.329 78.0407 14.0049C78.2776 13.6527 78.4092 13.1737 78.4092 12.5396Z'
        fill='white'
      />
      <path
        d='M87.9898 15.2029H87.0291V12.1737C87.0291 11.6243 86.9501 11.2298 86.7922 10.9762C86.6343 10.7226 86.358 10.6099 85.9763 10.6099C85.6605 10.6099 85.3841 10.7648 85.1736 11.0748C84.9499 11.3848 84.8446 11.7652 84.8446 12.1878V15.2029H83.8839V12.0751C83.8839 11.103 83.5286 10.6099 82.8312 10.6099C82.5022 10.6099 82.2258 10.7648 82.0153 11.0607C81.8047 11.3566 81.6994 11.737 81.6994 12.2019V15.217H80.7388V9.87722H81.6994V10.7085H81.7126C82.0679 10.0604 82.5943 9.75042 83.2654 9.75042C83.5813 9.75042 83.8708 9.84904 84.134 10.0322C84.384 10.2154 84.5682 10.4831 84.6867 10.8212C84.8841 10.4549 85.1078 10.1872 85.3841 10.004C85.6605 9.83495 85.9763 9.73633 86.3448 9.73633C87.4371 9.73633 87.9766 10.4549 87.9766 11.9061V15.2029H87.9898Z'
        fill='white'
      />
    </svg>
  )
}
