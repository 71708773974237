import { IconProps, iconColor, strokeIconClassName } from './IconProps'

export function AnnouncementIcon(props: IconProps) {
  const size = props.size || 24
  const strokeWidth = props.strokeWidth || 1.5

  return (
    <svg
      width={size}
      height={size}
      className={strokeIconClassName(props)}
      viewBox={`0 0 ${size} ${size}`}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M34.0928 25.1144L49.3436 7.76082C49.6072 7.46085 50.0988 7.57573 50.2022 7.96147L63.0909 56.0628C63.1942 56.4485 62.826 56.7938 62.4477 56.6658L40.5632 49.2626M34.0928 25.1144L6.81139 35.7731C6.33678 35.9585 6.07749 36.4711 6.20937 36.9633L10.5362 53.1112C10.6476 53.527 11.0125 53.8248 11.4422 53.8506L20.5919 54.3998C20.9885 54.4236 21.3333 54.6799 21.4705 55.0528L27.2844 70.8592C27.4629 71.3446 27.9821 71.6137 28.4817 71.4799L36.2011 69.4115C36.7691 69.2593 37.0838 68.6522 36.8809 68.1003L31.282 52.8785C31.079 52.3266 31.3937 51.7196 31.9617 51.5674L40.5632 49.2626M34.0928 25.1144L40.5632 49.2626M64.9116 30.6602L71.3511 28.9348M61.892 19.3911L65.8589 14.8773M67.9311 41.9294L73.6235 43.855'
        stroke={iconColor(props)}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  )
}
