import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { Button } from '../../../../global/button/Button'
import { createFormField } from '../../../../global/formField/FormField'
import { TransactionFormActionButtonTemplate } from '../../../../global/templates/TransactionFormActionButtonTemplate'

interface PaymentAgentTransferClientFormValues {
  clientWallet: {
    name: string
  }
  client: {
    name: string
  }
}

interface PaymentAgentTransferClientFieldsProps {
  onSubmit(values: PaymentAgentTransferClientFormValues): void
  onCancel(): void
}

const FormField = createFormField<PaymentAgentTransferClientFormValues>()
export const PaymentAgentTransferClientFields: React.FC<PaymentAgentTransferClientFieldsProps> = (
  props
) => {
  const { onSubmit, onCancel } = props

  const { values, isValid } = useFormikContext<PaymentAgentTransferClientFormValues>()

  const handleSubmitForm = () => {
    onSubmit(values)
  }

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <FormField
        name='clientWallet.name'
        label={t('Wallet.Client Wallet Name')}
        placeholder={t('Wallet.Client Wallet Name')}
        required
      />
      <FormField
        name='client.name'
        label={t('Wallet.Client Name')}
        placeholder={t('Wallet.Client Name')}
        required
      />

      <TransactionFormActionButtonTemplate>
        <Button appearance='secondary' size='L' type='button' onClick={onCancel}>
          {t('Cancel')}
        </Button>
        <Button
          appearance='primary'
          size='L'
          type='button'
          disabled={!isValid}
          onClick={handleSubmitForm}
        >
          {t('Confirm')}
        </Button>
      </TransactionFormActionButtonTemplate>
    </React.Fragment>
  )
}
