import { IconProps, iconColor } from './IconProps'

export function ChevronLeftIcon(props: IconProps) {
  return (
    <svg
      width='8'
      height='12'
      viewBox='0 0 8 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className={props.className}
      onClick={props.onClick}
    >
      <path
        d='M6.7 2.1C7.0866 1.7134 7.0866 1.0866 6.7 0.7C6.3134 0.313401 5.6866 0.313401 5.3 0.7L0 6L5.3 11.3C5.6866 11.6866 6.3134 11.6866 6.7 11.3C7.0866 10.9134 7.0866 10.2866 6.7 9.9L2.8 6L6.7 2.1Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
