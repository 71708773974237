import styles from './Icons.module.scss'

export function BankIcon(props: { size?: number }) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 65 64'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M61.3976 58.1136H63.5304C63.7845 58.1111 64.0292 58.2093 64.2109 58.3869C64.3927 58.5644 64.4966 58.8068 64.5 59.0608V63.0608C64.4945 63.3134 64.3897 63.5537 64.2082 63.7295C64.0266 63.9054 63.7831 64.0025 63.5304 64H1.4696C1.21554 64.0025 0.970816 63.9043 0.78907 63.7267C0.607324 63.5492 0.503375 63.3068 0.5 63.0528V59.0528C0.505462 58.8002 0.610329 58.5599 0.791849 58.384C0.973368 58.2082 1.2169 58.111 1.4696 58.1136H3.6024V55.0449C3.60786 54.7922 3.71273 54.5519 3.89425 54.3761C4.07577 54.2002 4.3193 54.1031 4.572 54.1057H6.9V32.9395H3.5384C3.2857 32.9421 3.04217 32.845 2.86065 32.6691C2.67913 32.4933 2.57426 32.253 2.5688 32.0004V23.9797C2.57426 23.727 2.67913 23.4867 2.86065 23.3109C3.04217 23.135 3.2857 23.0379 3.5384 23.0405H7.2856L31.828 0.261549C32.0111 0.0933427 32.2506 0 32.4992 0C32.7478 0 32.9873 0.0933427 33.1704 0.261549L57.7144 23.0453H61.4616C61.7143 23.0427 61.9578 23.1398 62.1394 23.3157C62.3209 23.4915 62.4257 23.7318 62.4312 23.9845V31.8604C62.4257 32.1131 62.3209 32.3534 62.1394 32.5292C61.9578 32.705 61.7143 32.8022 61.4616 32.7996H58.1V54.1009H60.428C60.6807 54.0983 60.9242 54.1954 61.1058 54.3713C61.2873 54.5471 61.3921 54.7874 61.3976 55.0401V58.1136ZM4.508 24.9236V31.0612H60.4952V24.9236H49.1352C48.8861 24.9236 48.6472 24.8247 48.4711 24.6486C48.295 24.4724 48.196 24.2335 48.196 23.9845C48.196 23.7354 48.295 23.4965 48.4711 23.3203C48.6472 23.1442 48.8861 23.0453 49.1352 23.0453H54.9112L32.5 2.24552L10.0888 23.0453H44.3944C44.6435 23.0453 44.8824 23.1442 45.0585 23.3203C45.2346 23.4965 45.3336 23.7354 45.3336 23.9845C45.3336 24.2335 45.2346 24.4724 45.0585 24.6486C44.8824 24.8247 44.6435 24.9236 44.3944 24.9236H4.508ZM55.7 54.1057V32.7996H51.7V54.1057H55.7ZM49.3 54.1057V32.7996H45.3L45.3336 54.1057H49.3ZM42.9 54.1057V32.7996L22.1 32.9395V54.1057H42.9ZM19.7 54.1057V32.9395H15.7V54.1057H19.7ZM13.3 54.1057V32.9395H9.3V54.1057H13.3ZM59.4584 55.9793H5.5384V58.1088V60H2.4392V62.1216H62.556V60H59.4584V58.1088V55.9793Z'
        fill={styles.white}
      />
      <path
        d='M32.4992 9.13281C31.3075 9.13281 30.1425 9.48621 29.1516 10.1483C28.1607 10.8104 27.3884 11.7514 26.9323 12.8525C26.4762 13.9535 26.3569 15.165 26.5894 16.3339C26.8219 17.5027 27.3958 18.5763 28.2385 19.419C29.0812 20.2617 30.1548 20.8356 31.3237 21.0681C32.4926 21.3006 33.7041 21.1813 34.8051 20.7252C35.9062 20.2691 36.8472 19.4968 37.5093 18.5059C38.1714 17.515 38.5248 16.3501 38.5248 15.1583C38.5227 13.5609 37.8872 12.0295 36.7576 10.9C35.6281 9.77044 34.0967 9.13493 32.4992 9.13281ZM32.4992 19.2815C31.6837 19.2815 30.8866 19.0397 30.2085 18.5866C29.5305 18.1336 29.002 17.4896 28.6899 16.7362C28.3778 15.9828 28.2962 15.1538 28.4553 14.354C28.6144 13.5541 29.007 12.8195 29.5837 12.2428C30.1603 11.6662 30.895 11.2735 31.6948 11.1144C32.4947 10.9553 33.3237 11.037 34.0771 11.349C34.8305 11.6611 35.4745 12.1896 35.9276 12.8676C36.3806 13.5457 36.6224 14.3429 36.6224 15.1583C36.6212 16.2515 36.1863 17.2995 35.4134 18.0724C34.6404 18.8454 33.5924 19.2802 32.4992 19.2815Z'
        fill={styles.white}
      />
    </svg>
  )
}
