import { Trans } from 'react-i18next'

import { TickmillProductType } from '../../model/TickmillProductType'

export const faqRoutesData = () => {
  return {
    [TickmillProductType.CFD]: [
      {
        slug: '/contact-us/faq',
        label: <Trans i18nKey='Support.FAQ Routes.Top FAQs' />,
        type: TickmillProductType.CFD,
      },
      {
        slug: '/contact-us/faq/account-information',
        label: <Trans i18nKey='Support.FAQ Routes.Account Information' />,
        type: TickmillProductType.CFD,
      },
      {
        slug: '/contact-us/faq/deposits-and-withdrawals',
        label: <Trans i18nKey='Support.FAQ Routes.Deposits and withdrawals' />,
        type: TickmillProductType.CFD,
      },
      {
        slug: '/contact-us/faq/product-intervention-measures',
        label: <Trans i18nKey='Support.FAQ Routes.Product Intervention Measures' />,
        type: TickmillProductType.CFD,
      },
      {
        slug: '/contact-us/faq/trading-conditions-cfd',
        label: <Trans i18nKey='Support.FAQ Routes.Trading conditions' />,
        type: TickmillProductType.CFD,
      },
    ],
    [TickmillProductType.ETD]: [
      {
        slug: '/contact-us/faq/account-opening',
        label: <Trans i18nKey='Support.FAQ Routes.Account Opening' />,
        type: TickmillProductType.ETD,
      },
      {
        slug: '/contact-us/faq/deposit-and-withdrawal',
        label: <Trans i18nKey='Support.FAQ Routes.Deposits and withdrawals' />,
        type: TickmillProductType.ETD,
      },
      {
        slug: '/contact-us/faq/legal-and-compliance',
        label: <Trans i18nKey='Support.FAQ Routes.Legal and Compliance' />,
        type: TickmillProductType.ETD,
      },
      {
        slug: '/contact-us/faq/product',
        label: <Trans i18nKey='Support.FAQ Routes.Product' />,
        type: TickmillProductType.ETD,
      },
      {
        slug: '/contact-us/faq/trading-conditions-etd',
        label: <Trans i18nKey='Support.FAQ Routes.Trading conditions' />,
        type: TickmillProductType.ETD,
      },
    ],
  }
}
