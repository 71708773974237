import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Loading } from '../../global/Loading/Loading'
import { CopyButton } from '../../global/button/CopyButton'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { CheckCircledIcon } from '../../icons/CheckCircledIcon'
import { Text, TextH1, TextH3, TextStrong } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'

import styles from './ProfilePage.module.scss'

export const ProfilePage = (): JSX.Element => {
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const walletsCallback = useCallback(async () => {
    return apiClient.getWallets({ walletTypeId: 100 })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: wallets, isLoading: isLoadingWallets } = useFetchOne(walletsCallback)

  const paymentAgentCallback = useCallback(async () => {
    if (!account?.clientPaymentAgent?.id) {
      return
    }
    return apiClient.getPaymentAgent(account?.clientPaymentAgent?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: agent, isLoading: isLoadingAgent } = useFetchOne(paymentAgentCallback)
  const firstWallet = wallets?.[0]

  return (
    <Loading showLoadingIcon isLoading={isLoadingAgent && isLoadingWallets}>
      <div className={styles.header}>
        <div className={styles.title}>
          <TextH3>{t('Payment Agent.Payment Agent(PA) Profile Info')}</TextH3>
        </div>
      </div>
      <div className={styles.profileWrapper}>
        <div className={styles.logoWrapper}>
          <TextH1>{agent?.externalName}</TextH1>
          <div className={styles.active}>
            <div className={styles.icon}>
              <CheckCircledIcon circleColor={'success'} />
            </div>
            <Text isParagraph className={styles.grayText}>
              {t('Payment Agent.Verified Agent')}
            </Text>
          </div>
        </div>
        <div className={styles.infoWrapper}>
          <TextH3>{t('Payment Agent.Main info')}</TextH3>
          <div className={styles.row}>
            <Text isParagraph className={styles.grayText}>
              {t('Payment Agent.Balance')}
            </Text>
            <TextStrong isParagraph>
              {wallets && formatMoney(firstWallet?.balance, firstWallet?.currency.id)}
            </TextStrong>
          </div>
          <div className={styles.row}>
            <Text isParagraph className={styles.grayText}>
              {t('Payment Agent.Website')}
            </Text>
            <div className='is-flex'>
              <Text isParagraph className={styles.link}>
                {agent?.website}
              </Text>
            </div>
          </div>

          {agent && agent.profiles.length > 0 && (
            <div className={styles.profile}>
              <TextH3>{t('Payment Agent.Responsible person')} #1</TextH3>
              <div className={styles.row}>
                <Text isParagraph className={styles.grayText}>
                  {t('Payment Agent.Full Name')}
                </Text>
                <TextStrong isParagraph>
                  {agent.profiles[0].firstName} {agent.profiles[0].lastName}
                </TextStrong>
              </div>
              <div className={styles.row}>
                <Text isParagraph className={styles.grayText}>
                  {t('Payment Agent.Email')}
                </Text>
                <div className='is-flex'>
                  <Text isParagraph className={classNames(styles.flexText, 'is-link')}>
                    {agent.profiles[0].email}
                  </Text>
                  <CopyButton value={agent.profiles[0].email || ''} />
                </div>
              </div>

              <div className={styles.row}>
                <Text isParagraph className={styles.grayText}>
                  {t('Payment Agent.Phone')}
                </Text>
                <div className='is-flex'>
                  <Text isParagraph className={styles.flexText}>
                    {agent.profiles[0].phone}
                  </Text>
                  <CopyButton value={agent.profiles[0].phone || ''} />
                </div>
              </div>
            </div>
          )}

          {agent && agent.profiles.length > 1 && (
            <div className={styles.profile}>
              <TextH3>{t('Payment Agent.Responsible person')} #2</TextH3>
              <div className={styles.row}>
                <Text className={styles.grayText}>{t('Payment Agent.Full Name')}</Text>
                <TextStrong isParagraph>
                  {agent.profiles[1].firstName} {agent.profiles[1].lastName}
                </TextStrong>
              </div>
              <div className={styles.row}>
                <Text isParagraph className={styles.grayText}>
                  {t('Payment Agent.Email')}
                </Text>
                <div className='is-flex'>
                  <Text isParagraph className={classNames(styles.flexText, 'is-link')}>
                    {agent.profiles[1].email}
                  </Text>
                  <CopyButton value={agent.profiles[1].email || ''} />
                </div>
              </div>

              <div className={styles.row}>
                <Text isParagraph className={styles.grayText}>
                  {t('Payment Agent.Phone')}
                </Text>
                <div className='is-flex'>
                  <Text isParagraph className={styles.flexText}>
                    {agent.profiles[1].phone}
                  </Text>
                  <CopyButton value={agent.profiles[1].phone || ''} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Loading>
  )
}
