import React from 'react'
import classNames from 'classnames'

import styles from './Icons.module.scss'

export const ManageFundsIcon2 = ({ className }: { className?: string }) => (
  <svg
    width='163'
    height='161'
    className={classNames(className)}
    viewBox='0 0 163 161'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='39.8499' cy='39.0821' r='32.5684' stroke={styles.text} strokeWidth='2.5' />
    <path
      d='M51.2489 27.6832C48.9944 25.4287 46.122 23.8933 42.9949 23.2713C39.8678 22.6493 36.6265 22.9685 33.6808 24.1887C30.7352 25.4088 28.2175 27.475 26.4462 30.126C24.6748 32.777 23.7294 35.8938 23.7294 39.0821C23.7294 42.2705 24.6748 45.3872 26.4462 48.0382C28.2175 50.6892 30.7352 52.7554 33.6808 53.9756C36.6265 55.1957 39.8678 55.5149 42.9949 54.8929C46.122 54.2709 48.9944 52.7356 51.2489 50.4811'
      stroke={styles.primary}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M18.5586 43.382H41.7857'
      stroke={styles.primary}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <path
      d='M18.5586 33.7032H41.7857'
      stroke={styles.primary}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <circle cx='72.1658' cy='121.918' r='32.5684' stroke={styles.text} strokeWidth='2.5' />
    <path
      d='M81.7809 112.295C80.7569 108.68 75.9186 106.494 72.1639 106.522M72.1639 106.522C67.3555 106.559 61.3492 111.485 62.5507 116.144C64.5377 123.85 79.7943 119.987 81.7809 127.691C82.9825 132.351 76.9723 137.278 72.1639 137.314M72.1639 106.522L72.0102 100.748M72.1639 137.314C68.4092 137.343 63.5748 135.156 62.5507 131.54M72.1639 137.314V143.087'
      stroke={styles.primary}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
    <circle cx='123.229' cy='50.4358' r='32.5684' stroke={styles.text} strokeWidth='2.5' />
    <path
      d='M133 38.4447C133 38.4447 131.48 32.5246 125.4 32.5232C119.27 32.5217 116.281 35.4839 116.281 41.4055V48.8074M133 65.0916H113.61C113.476 65.0916 113.402 64.9243 113.492 64.8235C114.219 64.0014 116.281 61.3477 116.281 57.6897V48.8074M116.281 48.8074H126.92M116.281 48.8074H110.202'
      stroke={styles.primary}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
  </svg>
)
