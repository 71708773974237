import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { Modal } from '../../global/modal/Modal'
import { RestrictionActionModal } from '../../global/modal/RestrictionActionModal'
import { NameDto } from '../../model/NameDto'
import { WalletCurrency, WalletTypeEnum } from '../../model/WalletDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'
import { PaymentAgentWalletsCard } from './PaymentAgentWalletsCard'
import { PaymentAgentWalletsTable } from './PaymentAgentWalletsTable'

export interface PaymentAgentWalletMappedDto {
  currency: WalletCurrency
  id: string
  name: string
  balance: number
  reserved: number
  restrictions: NameDto[]
}

export const PaymentAgentWalletsPage: React.FC = () => {
  const apiClient = useApiClient(ClientApiClient)
  const isMobile = useWindowResize()
  const [modal, setModalVisible] = useState<boolean>(false)
  const { t } = useTranslation()
  const { callback: walletsCallback } = useCallbackWithForceRefresh(async () => {
    const response = await apiClient.getWallets({
      walletTypeId: 100,
    })
    return response
      .filter((x) => x.walletType.id === WalletTypeEnum.PAYMENT_AGENT)
      .map((w) => ({
        currency: w.currency,
        id: w.id,
        name: w.name,
        balance: w.balance,
        reserved: w.reserved,
        restrictions: w.restrictions,
      }))
  }, [])

  const { data: wallets = [], isLoading } = useFetchOne(walletsCallback)

  return (
    <React.Fragment>
      {modal && (
        <Modal
          closeModal={() => setModalVisible(false)}
          render={({ closeModal }) => (
            <RestrictionActionModal
              onCancel={closeModal}
              onConfirm={() => {
                setModalVisible(false)
              }}
            />
          )}
        />
      )}
      <PageHeader title={t('Payment Agent.Wallet')} />
      {isMobile ? (
        wallets.map((wallet) => (
          <PaymentAgentWalletsCard wallet={wallet} setModalVisible={setModalVisible} />
        ))
      ) : (
        <Loading showLoadingIcon isLoading={isLoading}>
          <PaymentAgentWalletsTable wallets={wallets} setModalVisible={setModalVisible} />
        </Loading>
      )}
    </React.Fragment>
  )
}
