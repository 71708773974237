import { FC, ReactNode, createContext, useEffect, useMemo, useState } from 'react'

import { useGuardedContext } from '../global/useGuardedContext'
import { useAccountReadContext } from './AccountContextContext'
import { useApiClient } from './ApiClient'
import { MaybeAuthSession } from './AuthContext'
import { ClientApiClient } from './clientApi'
import { TransactionType } from './transaction.utils'

interface FirstDepositContextProps {
  hasFirstDeposit: boolean | null
  setHasFirstDeposit(hasFirstDeposit: boolean): void
  isLoading: boolean
}
const FirstDepositContext = createContext<FirstDepositContextProps | undefined>(undefined)

FirstDepositContext.displayName = 'FirstDepositContext'

export const useFirstDepositContext = (): FirstDepositContextProps =>
  useGuardedContext(FirstDepositContext)

interface ProviderProps {
  children: ReactNode
  auth: MaybeAuthSession
}

export const FirstDepositContextProvider: FC<ProviderProps> = ({ children, auth }) => {
  const [hasFirstDeposit, setHasFirstDeposit] = useState<boolean | null>(null)
  const { account } = useAccountReadContext()
  const apiClient = useApiClient(ClientApiClient)
  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
    if (!auth) {
      setHasFirstDeposit(null)
    }

    ;(async () => {
      setIsLoading(true)
      try {
        if (account?.firstDeposit) {
          setHasFirstDeposit(true)
        } else if (auth) {
          const response = await apiClient.getTransactions({
            search: { ca_search_TransactionTypeId: TransactionType.WalletDeposit },
          })
          setHasFirstDeposit(!!response.items.length)
        } else {
          setHasFirstDeposit(null)
        }
      } catch (error: unknown) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    })()
  }, [auth, account?.firstDeposit, apiClient])

  const contextValue = useMemo(
    () => ({
      hasFirstDeposit,
      setHasFirstDeposit,
      isLoading,
    }),
    [hasFirstDeposit, setHasFirstDeposit, isLoading]
  )
  return (
    <FirstDepositContext.Provider value={contextValue}>{children}</FirstDepositContext.Provider>
  )
}
