import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { Loading } from '../../../../global/Loading/Loading'
import { DemoTradingAccount } from '../../../../model/TradingAccount'
import { PageData, useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { useCallbackWithForceRefresh } from '../../../../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage } from '../../../../utils/useFetch'
import {
  DemoTradingAccountDepositForm,
  DemoTradingAccountDepositFormValues,
} from './DemoTradingAccountDepositForm'

export const DemoTradingAccountDepositPage = () => {
  const { tradingAccountId } = useParams<{ tradingAccountId?: string }>()
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()

  const { callback } = useCallbackWithForceRefresh(async () => {
    if (tradingAccountId) {
      return apiClient.getDemoTradingAccounts({
        search: { Id: tradingAccountId },
      })
    }
    return {
      items: [],
      itemsCount: 0,
      totalPages: 0,
      pageIndex: 0,
      pageSize: 0,
      totalResults: 0,
    } as PageData<DemoTradingAccount>
  }, [tradingAccountId])

  const onSubmit = async (values: DemoTradingAccountDepositFormValues) => {
    await apiClient.addBalanceToDemoTradingAccount(values)

    navigate(`/dashboard/traders-room/trading-accounts`)
  }

  const { t } = useTranslation()

  const { data, isLoading } = useFetchAppendablePage(callback)
  return (
    <>
      <Loading isLoading={isLoading}>
        {data.length && (
          <DemoTradingAccountDepositForm
            demoTradingAccounts={data[0]}
            handleFormSubmit={onSubmit}
          />
        )}
      </Loading>
    </>
  )
}
