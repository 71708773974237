const stringComparator = (a: string | undefined | null, b: string | undefined | null) => {
  const isEmpty = (t: string) => t.trim() === ''
  if (!a && !b) {
    return 0
  }
  if (!a) {
    return -1
  }
  if (!b) {
    return 1
  }
  if (isEmpty(a) && isEmpty(b)) {
    return 0
  }

  if (isEmpty(a)) {
    return -1
  }
  if (isEmpty(b)) {
    return 1
  }
  const fielda = a.toLowerCase()
  const fieldb = b.toLowerCase()

  if (fielda < fieldb) {
    return -1
  }

  if (fielda > fieldb) {
    return 1
  }

  return 0
}

export default stringComparator
