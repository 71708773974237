import React, { useCallback, useContext } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { Loading } from '../../../../global/Loading/Loading'
import {
  MasterTransaction,
  isTransactionFormRedirect,
  isTransactionHistory,
  isTransactionSuccessful,
  isTransactionUnSuccessful,
  isTransactionUrlRedirect,
} from '../../../../model/MasterTransaction'
import { isPaymentProviderBankType } from '../../../../model/PaymentProviderType'
import { WalletTypeEnum } from '../../../../model/WalletDto'
import { usePathHistoryContext } from '../../../../utils/PathHistoryContext'
import { useFetchOne } from '../../../../utils/useFetch'
import { WalletDepositFormValues } from '../WalletDepositForm/WalletDepositForm'
import { usePaymentProviderFactory } from '../usePaymentProviderFactory'
import { WalletDepositPage, useWalletDepositFetch } from './WalletDepositPage'

interface TradersRoomWalletDepositPageProps {
  walletType: WalletTypeEnum
}
export const TradersRoomWalletDepositPage: React.FC<TradersRoomWalletDepositPageProps> = (
  props
) => {
  const { walletType } = props

  const { walletId } = useParams<{ walletId?: string }>()

  const location = useLocation()
  const { walletPaymentProvider } = location.state || {}
  const { createPaymentProvider } = usePaymentProviderFactory()
  const { navigateToPreviousPath } = usePathHistoryContext()

  const navigate = useNavigate()

  const { data, isLoading } = useWalletDepositFetch({
    onCancel: navigateToPreviousPath,
  })

  const paymentProviderCallback = useCallback(async () => {
    if (data && walletPaymentProvider) {
      return await createPaymentProvider(walletPaymentProvider, data)
    }
  }, [data, walletPaymentProvider])

  const { data: paymentProvider, isLoading: paymentProviderIsLoading } =
    useFetchOne(paymentProviderCallback)

  const handleSubmit = (data: MasterTransaction, values: WalletDepositFormValues) => {
    if (isTransactionHistory(data)) {
      navigate(`/dashboard/traders-room/transaction-history`)
    }

    if (isPaymentProviderBankType(values.paymentProvider.providerCategory.id, data)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/transaction/succeed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }

    if (isTransactionUrlRedirect(data)) {
      navigate(`/dashboard/traders-room/wallets/${walletId}/deposit/redirect`, {
        state: {
          transaction: data,
          values,
        },
      })
    }

    if (isTransactionFormRedirect(data)) {
      navigate(`/dashboard/traders-room/wallets/${walletId}/deposit/redirect`, {
        state: {
          transaction: data,
          values,
        },
      })
    }

    if (isTransactionSuccessful(data)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/transaction/succeed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }

    if (isTransactionUnSuccessful(data)) {
      navigate(`/dashboard/traders-room/wallets/${values.wallet.id}/deposit/transaction/failed`, {
        state: {
          data,
          providerCategoryId: values.paymentProvider.providerCategory.id,
        },
      })
    }
  }

  return (
    <Loading isLoading={isLoading || paymentProviderIsLoading}>
      {data && (
        <WalletDepositPage
          wallet={data}
          walletType={walletType}
          onSubmit={handleSubmit}
          onCancel={navigateToPreviousPath}
          paymentProvider={paymentProvider}
        />
      )}
    </Loading>
  )
}
