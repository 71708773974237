import React, { RefObject, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { getTransactionRedirectForm } from '../../model/MasterTransaction'
import { transactionStorage } from '../DepositRedirectFromProviderPage/DepositRedirectFromProviderPage'
import { useDepositRedirectToProvider } from './DepositRedirectToProviderPage'

const useDepositFormRedirect = () => {
  const data = useDepositRedirectToProvider()
  const form = getTransactionRedirectForm(data.transaction)

  return {
    data,
    form,
  }
}

export const DepositFormRedirectPage: React.FC = () => {
  const { form, data } = useDepositFormRedirect()

  const { t } = useTranslation()

  const divRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (divRef.current && form && data) {
      transactionStorage().addProviderDepositResponseTransaction(data)
      createRedirectForm(form, divRef)
    }
  }, [form, data, divRef])

  return (
    <React.Fragment>
      <Loading isLoading showLoadingIcon text={t('Wallet.Depositing ...')} />
      <div ref={divRef} />
    </React.Fragment>
  )
}

const createRedirectForm = (redirectForm: string, refObject: RefObject<HTMLDivElement>): void => {
  const html = document.createRange().createContextualFragment(redirectForm)
  applyRedirectForm(html, refObject)
}

const applyRedirectForm = (html: DocumentFragment, refObject: RefObject<HTMLDivElement>) => {
  if (html && refObject?.current) {
    refObject.current.innerHTML = ''
    refObject.current.appendChild(html)
  }
}
