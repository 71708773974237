import React, { FC, SyntheticEvent, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { Form, FormikErrors, FormikProps, withFormik } from 'formik'

import { Button } from '../../global/button/Button'
import { FormWrapper } from '../../global/form/FormWrapper'
import { createFormPasswordField } from '../../global/formField/FormPasswordField'
import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { Modal } from '../../global/modal/Modal'
import { ValidationOperationId } from '../../model/ValidationDto'
import { usePasswordValidation } from '../../ui/PasswordValidation/PasswordValidation'
import { Text } from '../../ui/Typography/Typography'
import { useApiClient } from '../../utils/ApiClient'
import { usePathHistoryContext } from '../../utils/PathHistoryContext'
import { ClientApiClient } from '../../utils/clientApi'
import { PasswordChangeMessageModal } from './PasswordChangeMessageModal'

import styles from './ChangeTradingAccountPasswordForm.module.scss'

export interface ChangeTradingAccountPasswordValues {
  tradingAccountId: string
  existingPassword: string
  password: string
  changeInvestorPassword: boolean
}

interface OuterProps {
  onSubmit: (values: ChangeTradingAccountPasswordValues) => void
  tradingAccountId: string
  changeInvestorPassword: boolean

  changeTickmillTraderPassword: boolean
}

const PasswordFormField = createFormPasswordField<ChangeTradingAccountPasswordValues>()

const ChangeTradingAccountPasswordFormUI: React.FC<
  FormikProps<ChangeTradingAccountPasswordValues> & OuterProps
> = (props) => {
  const {
    handleSubmit,
    setSubmitting,
    isValid,
    dirty,
    values,
    changeInvestorPassword,
    changeTickmillTraderPassword,
  } = props
  const { password } = values
  const { isPasswordValid, validationComponent } = usePasswordValidation({
    password,
    validationOperationId: ValidationOperationId.ValidateMetatraderAccountPassword,
  })
  const { navigateToPreviousPath } = usePathHistoryContext()

  const { t } = useTranslation()
  const [cancelModal, setCancelModal] = useState(false)

  const handleSubmitForm = (event: SyntheticEvent) => {
    event.preventDefault()
    setSubmitting(true)
    handleSubmit()
  }
  const oldPasswordLabel = useMemo(() => {
    if (changeInvestorPassword) {
      return t('Trading Account.Account Password')
    } else if (changeTickmillTraderPassword) {
      return t('Trading Account.Old User ID Password')
    }
    return t('Trading Account.Old Trading Account Password')
  }, [t, changeInvestorPassword, changeTickmillTraderPassword])

  const newPasswordLabel = useMemo(() => {
    if (changeInvestorPassword) {
      return t('Trading Account.New Investor Password')
    } else if (changeTickmillTraderPassword) {
      return t('Trading Account.New User ID Password')
    }
    return t('Trading Account.New Trading Account Password')
  }, [t, changeInvestorPassword, changeTickmillTraderPassword])

  const passwordLabel = useMemo(() => {
    if (changeInvestorPassword) {
      return t('Trading Account.Change Investor Password')
    } else if (changeTickmillTraderPassword) {
      return t('Trading Account.Change User ID Password')
    }
    return t('Trading Account.Change Trading Account Password')
  }, [t, changeInvestorPassword, changeTickmillTraderPassword])

  return (
    <FormWrapper label={passwordLabel} goBack={navigateToPreviousPath}>
      {cancelModal && (
        <Modal
          closeModal={() => setCancelModal(false)}
          render={() => (
            <CancelActionModal
              onConfirm={navigateToPreviousPath}
              onCancel={() => setCancelModal(false)}
            />
          )}
        />
      )}
      <Form className={styles.wrapper} onSubmit={handleSubmitForm}>
        {!changeInvestorPassword && (
          <ImportantInfo changeTickmillTraderPassword={changeTickmillTraderPassword} />
        )}
        <PasswordFormField
          name={'existingPassword'}
          showLabel={true}
          label={oldPasswordLabel}
          type='password'
          required
        />
        <PasswordFormField
          name={'password'}
          showLabel={true}
          label={newPasswordLabel}
          type='password'
          required
        />
        {validationComponent}
        <div className={styles.action}>
          <Button
            type='button'
            appearance='secondary'
            onClick={() => setCancelModal(true)}
            size='L'
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={!(isPasswordValid && isValid && dirty)}
            type='submit'
            appearance='primary'
            size='L'
          >
            {t('Confirm')}
          </Button>
        </div>
      </Form>
    </FormWrapper>
  )
}

const ChangeTradingAccountPasswordForm = withFormik<OuterProps, ChangeTradingAccountPasswordValues>(
  {
    mapPropsToValues: ({ tradingAccountId, changeInvestorPassword }) => {
      return {
        existingPassword: '',
        password: '',
        tradingAccountId,
        changeInvestorPassword,
      }
    },
    handleSubmit: async (values, { props, setSubmitting }) => {
      try {
        await props.onSubmit(values)
      } finally {
        setSubmitting(false)
      }
    },
    validate: ({ existingPassword }) => {
      const errors: FormikErrors<ChangeTradingAccountPasswordValues> = {}
      if (!existingPassword) {
        errors.existingPassword = ''
      }
      return errors
    },
  }
)(ChangeTradingAccountPasswordFormUI)

export const ChangeTradingAccountPasswordPage: React.FC = () => {
  const { tradingAccountId } = useParams<{ tradingAccountId?: string }>()
  const location = useLocation()
  const [modal, setModal] = useState(false)
  const params = new URLSearchParams(location.search)
  const changeInvestorPassword = params.get('changeInvestorPassword')?.toLowerCase() === 'true'
  const changeTickmillTraderPassword =
    params.get('changeTickmillTraderPassword')?.toLowerCase() === 'true'

  const apiClient = useApiClient(ClientApiClient)

  const onSubmit = async (values: ChangeTradingAccountPasswordValues) => {
    try {
      await apiClient.changeTradingAccountPassword(values)
      setModal(true)
    } catch (error: unknown) {
      const e = error
      console.error('Error:', (e as any).response.response?.data?.message)
    }
  }
  return (
    <>
      {modal && (
        <Modal
          closeModal={() => setModal(false)}
          render={() => (
            <PasswordChangeMessageModal
              changeInvestorPassword={changeInvestorPassword}
              changeTickmillTraderPassword={changeTickmillTraderPassword}
            />
          )}
        />
      )}
      {tradingAccountId && (
        <ChangeTradingAccountPasswordForm
          tradingAccountId={tradingAccountId}
          onSubmit={onSubmit}
          changeInvestorPassword={changeInvestorPassword}
          changeTickmillTraderPassword={changeTickmillTraderPassword}
        />
      )}
    </>
  )
}

export const ImportantInfo: FC<{ changeTickmillTraderPassword: boolean }> = ({
  changeTickmillTraderPassword,
}) => {
  const { t } = useTranslation()

  const message = changeTickmillTraderPassword
    ? t('Trading Account.Trading User ID Password is not your Tickmill account password')
    : t('Trading Account.Trading Account Password is not your Tickmill account password.')

  return (
    <Text className={classNames(styles.info)}>
      <span>{t('Trading Account.Important')}</span> {message}
    </Text>
  )
}
