import styles from './Icons.module.scss'

export function GettingStartedEmailIcon() {
  return (
    <svg
      width='592'
      height='248'
      viewBox='0 0 592 248'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='592' height='248' rx='16' fill={styles.background} />
      <path
        d='M282 88.4804C276.766 86.8576 273.952 85.8653 272.208 89.3462C270.464 92.8273 280.233 93.9778 281.128 96.8282C282 99.6081 279.383 100.999 279.383 100.999'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M271.691 106.057C276.925 107.679 279.739 108.672 281.483 105.191C283.228 101.71 273.458 100.559 272.564 97.7089C271.691 94.929 274.308 93.5381 274.308 93.5381'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />

      <path
        d='M310.988 75.917H264C261.791 75.917 260 77.7079 260 79.917V168.083C260 170.292 261.791 172.083 264 172.083H328C330.209 172.083 332 170.292 332 168.083V96.9289M310.988 75.917L332 96.9289M310.988 75.917V94.9289C310.988 96.0334 311.884 96.9289 312.988 96.9289H320.594H332M271.406 119.741H320.594M271.406 129.703H320.594M271.406 140.022H307.762M300.99 152.123L307.762 158.895L320.594 146.064'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}

export function GettingStartedEmailMobileIcon() {
  return (
    <svg
      width='312'
      height='177'
      viewBox='0 0 312 177'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect y='0.0234375' width='312' height='176.166' rx='16' fill={styles.background} />
      <path
        d='M142 52.5868C136.766 50.9641 133.952 49.9717 132.208 53.4527C130.464 56.9337 140.233 58.0842 141.128 60.9347C142 63.7145 139.383 65.1055 139.383 65.1055'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M131.691 70.1632C136.925 71.7859 139.739 72.7783 141.483 69.2973C143.228 65.8163 133.458 64.6658 132.564 61.8153C131.691 59.0355 134.308 57.6445 134.308 57.6445'
        stroke={styles.primary}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M170.988 40.0234H124C121.791 40.0234 120 41.8143 120 44.0234V132.19C120 134.399 121.791 136.19 124 136.19H188C190.209 136.19 192 134.399 192 132.19V61.0353M170.988 40.0234L192 61.0353M170.988 40.0234V59.0353C170.988 60.1399 171.884 61.0353 172.988 61.0353H180.594H192M131.406 83.8472H180.594M131.406 93.8096H180.594M131.406 104.128H167.762M160.99 116.229L167.762 123.002L180.594 110.17'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
      />
    </svg>
  )
}
