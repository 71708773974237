import { IconProps, iconColor } from './IconProps'

export function HistoryIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.47463 7.24549C2.95366 5.45772 4.03776 3.89135 5.54221 2.81327C7.04667 1.7352 8.87838 1.21212 10.7253 1.33317C12.5721 1.45422 14.3199 2.21191 15.6708 3.47713C17.0216 4.74235 17.892 6.43683 18.1336 8.27184C18.3752 10.1068 17.973 11.9688 16.9957 13.5406C16.0183 15.1123 14.5262 16.2966 12.7736 16.8915C11.0209 17.4864 9.11625 17.4553 7.38404 16.8033C5.65182 16.1513 4.19925 14.9189 3.27383 13.316M2.47463 7.24549L1 3M2.47463 7.24549L6.5 6M10 4.5L10 10L13 12'
        stroke={iconColor(props)}
        strokeWidth='1.5'
        strokeLinecap='round'
      />
    </svg>
  )
}
