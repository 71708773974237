import React from 'react'
import { useTranslation } from 'react-i18next'

import { isTickmillEUType } from '../../utils/companyName.utils'
import { useSessionEntity } from '../context/EntityContext'
import { BlogModal as BlogModalView } from '../modal/BlogModal'
import { Modal } from '../modal/Modal'

interface BlogModalProps {
  setModalVisible(value: boolean): void
}

export const BlogModal: React.FC<BlogModalProps> = (props) => {
  const { setModalVisible } = props

  const { t } = useTranslation()

  const entity = useSessionEntity()

  const handleClose = () => {
    setModalVisible(false)
  }

  const title = isTickmillEUType(entity) ? 'Tickmill Europe' : 'Tickmill UK'

  return (
    <Modal
      closeModal={handleClose}
      render={({ closeModal }) => (
        <BlogModalView
          title={title}
          onCancel={closeModal}
          onConfirm={handleClose}
          renderBody={() => (
            <React.Fragment>
              <p className='text-primary'>
                {t('Tickmill Blogs are for informational / educational purposes only.')}
              </p>
              <p className='text-primary'>{t('Tickmill does not provide investment advice.')}</p>
              <p className='text-primary'>
                {t('By proceeding you wil be redirected to www.tickmill.com blog')}
              </p>{' '}
            </React.Fragment>
          )}
        />
      )}
    />
  )
}
