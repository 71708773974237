import React from 'react'

import { FileUploads, FileUploadsProps } from './FileUploads'
import { DocumentFileExtensionType, getImageFileExtensions } from './types'

export const DocumentFileUploads: React.FC<FileUploadsProps> = (props) => {
  return (
    <FileUploads
      acceptType={[...getImageFileExtensions(), DocumentFileExtensionType.PDF]}
      {...props}
    />
  )
}
