import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { SelectModal, SelectModalOption } from '../../global/field/SelectModal'
import { Modal } from '../../global/modal/Modal'
import { NameDto } from '../../model/NameDto'
import { TradingAccountInfo } from '../../model/TradingAccountInfo'
import { CreateTradingAccountCampaignFormValues } from './CreateTradingAccountCampaignForm'

interface WalletModalProps {
  tradingAccountsAccountInfo: TradingAccountInfo[]
  demo?: boolean
  onClose(): void
  onSelectOption(platform: NameDto): void
}

export const TradingPlatformModal: React.FC<WalletModalProps> = (props) => {
  const { tradingAccountsAccountInfo, demo, onSelectOption, onClose } = props
  const { t } = useTranslation()
  const { values } = useFormikContext<CreateTradingAccountCampaignFormValues>()

  const allowedPlatforms = useMemo(
    () =>
      demo
        ? tradingAccountsAccountInfo.filter((x) => x.allowToOpenDemoAccount)
        : tradingAccountsAccountInfo,
    [tradingAccountsAccountInfo, demo]
  )

  return (
    <Modal
      closeModal={onClose}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          title={t('Trading Account.Platform Type')}
          renderOptions={() => (
            <div className='control'>
              {allowedPlatforms.map(({ platform }) => (
                <SelectModalOption
                  label={platform.name}
                  value={platform.id === values.platformType?.id}
                  onClick={() => onSelectOption(platform)}
                  key={platform.id}
                />
              ))}
            </div>
          )}
        />
      )}
    />
  )
}
