import { NameDto } from './NameDto'
import { QuestionDto, TestSectionQuestionDto } from './TestSectionsDto'

export interface TestAnswersDto {
  id: string
  name: string
  category: string
  dateTaken: string
  maxFailScore: number
  maxReferredScore: number
  subcategory: string | null
  documents: NameDto<string>[]
  sections: ReadonlyArray<TestAnswerSectionDto>
  tickmillCompanyId: number
  totalScore: number
  version: number
  testStatus: NameDto

  allowRetake: boolean
  outcome: TestAnswerOutcomeDto
  isClientVulnerable: boolean
}

interface TestAnswerOutcomeDto {
  outcome: string
  leverageType: NameDto | null
}

export interface TestAnswerSectionDto {
  id: string
  name: string
  order: number
  code: string
  totalScore: number
  answers: TestAnswerDto[]
  questions?: TestSectionQuestionDto[]
}

export interface TestAnswerDto {
  question: QuestionDto
  answer: AnswerDto
}

export enum TestStatusEnum {
  Pending = 1,
  Approved = 2,
  Declined = 3,
}

export interface AnswerDto {
  id: string
  name: string
  code: string | null
  score: number | null
  predefinedAnswerId: string
  key: string
}

export enum TestAnswerOutcomeStatus {
  FAILED = 'Failed',
  REFERRED = 'Referred',
  APPROVED = 'Approved ',
}

export const isTestAnswerOutcomeFailed = (answer: TestAnswersDto) => {
  return isTestAnswerOutcomeFailedStatus(answer?.outcome?.outcome)
}

export const isTestAnswerOutcomeFailedStatus = (status: string) => {
  return status === TestAnswerOutcomeStatus.FAILED
}
