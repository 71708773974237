import { Color } from './index'

import styles from './Icons.module.scss'

export function SupportEmailIcon(props: { color?: Color; size?: number; strokeWidth?: number }) {
  const size = props.size || 24
  const strokeWidth = props.strokeWidth || 1.5

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5 8L12 13.5L18.5 8M3.5 19H20.5C21.0523 19 21.5 18.5523 21.5 18V6C21.5 5.44772 21.0523 5 20.5 5H3.5C2.94772 5 2.5 5.44772 2.5 6V18C2.5 18.5523 2.94771 19 3.5 19Z'
        stroke={props.color ? styles[props.color] : styles.textSecondary}
        strokeWidth={strokeWidth}
        strokeLinecap='round'
      />
    </svg>
  )
}
