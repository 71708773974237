import { ReactNode, createContext, useMemo, useState } from 'react'

import { useGuardedContext } from '../global/useGuardedContext'
import { DocumentCategorySizeDto } from '../model/DocumentCategories'
import { useApiClient } from './ApiClient'
import { ClientApiClient } from './clientApi'

interface CategorySizesReadContextProps {
  categorySizes: DocumentCategorySizeDto[]
}

interface CategorySizesWriteContextProps {
  refreshCategorySizes: () => void
}

const CategorySizesReadContext = createContext<CategorySizesReadContextProps | undefined>(undefined)

CategorySizesReadContext.displayName = 'CategorySizesReadContext'

const CategorySizesWriteContext = createContext<CategorySizesWriteContextProps | undefined>(
  undefined
)

export function useCategorySizesReadContext(): CategorySizesReadContextProps {
  return useGuardedContext(CategorySizesReadContext)
}

export function useCategorySizesWriteContext() {
  return useGuardedContext(CategorySizesWriteContext)
}

interface Props {
  categorySizes: DocumentCategorySizeDto[]
  children: ReactNode
}

export function CategorySizesContextProvider({ categorySizes, children }: Props) {
  const [categorySizesState, setCategorySizesState] = useState(categorySizes)

  const apiClient = useApiClient(ClientApiClient)

  const writeContext = useMemo<CategorySizesWriteContextProps>(() => {
    return {
      async refreshCategorySizes(categorySizes?: DocumentCategorySizeDto[]) {
        if (!categorySizes) {
          setCategorySizesState(await apiClient.getAllDocumentCategories('en'))
        } else {
          setCategorySizesState(categorySizesState)
        }
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const readContext = useMemo<CategorySizesReadContextProps>(() => {
    return { categorySizes: categorySizesState }
  }, [categorySizesState])

  return (
    <CategorySizesReadContext.Provider value={readContext}>
      <CategorySizesWriteContext.Provider value={writeContext}>
        {children}
      </CategorySizesWriteContext.Provider>
    </CategorySizesReadContext.Provider>
  )
}
