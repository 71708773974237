import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { AppMarketLink } from '../../global/appMarketLink/mobile/AppMarketLink'
import { Button } from '../../global/button/Button'
import { useSessionEntity } from '../../global/context/EntityContext'
import { ChevronDownIcon } from '../../icons/ChevronDownIcon'
import { GettingStartedCreateAccountIcon } from '../../icons/GettingStartedCreateAccountIcon'
import { GettingStartedDepositIcon } from '../../icons/GettingStartedDepositIcon'
import { GettingStartedDownloadPlatformIcon } from '../../icons/GettingStartedDownloadPlatformIcon'
import { GettingStartedTransferIcon } from '../../icons/GettingStartedTransferIcon'
import { AppQRCode } from '../../ui/FirstTimeGuideCarousel/AppQRCode'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { Text, TextLargeStrong, TextStrong, TextTinyStrong } from '../../ui/Typography/Typography'
import { isEitherASTypeOrSCType, isTickmillUKType } from '../../utils/companyName.utils'
import { useWindowResize } from '../../utils/domUtils'

import styles from './GettingStarted.module.scss'

export const GettingStartedPage = (): JSX.Element => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const entity = useSessionEntity()
  const isASTypeOrSCType = isEitherASTypeOrSCType(entity)

  return (
    <div className={styles.gettingStarted}>
      <PageHeader title={t('Learning.Getting Started')} />
      <div className={styles.titleSection}>
        <h3>{t('Learning.Quick guidelines')}</h3>
        <Text>{t('Learning.Congratulations! You have registered with Tickmill')}</Text>
      </div>
      <div className={styles.accordionsBox}>
        <Accordion
          title={t('Learning.Fund your Wallet')}
          subtitle={t(
            'Learning.Add funds to your Wallet by choosing your preferred payment method.'
          )}
          icon={<GettingStartedDepositIcon />}
          buttonText={t('Learning.Start Deposit')}
          path={'/dashboard/traders-room/wallets/deposit'}
        />
        <Accordion
          title={t('Learning.Create Trading Account Step')}
          subtitle={t(
            'Learning.Open a live trading account in the available base currency of your choice.'
          )}
          icon={<GettingStartedCreateAccountIcon />}
          buttonText={t('Learning.Create Trading Account')}
          path={'/dashboard/traders-room/trading-accounts/new'}
        />
        <Accordion
          title={t('Learning.Make a Deposit')}
          subtitle={t('Learning.Transfer funds from your Wallet to your Trading Account.')}
          icon={<GettingStartedTransferIcon />}
          buttonText={t('Transactions.Start Transfer')}
          path={'/dashboard/traders-room/wallets/transfer'}
        />
        <Accordion
          title={
            isASTypeOrSCType
              ? t('Learning.Choose your Trading Platform')
              : t('Learning.Download MetaTrader 4/5')
          }
          subtitle={
            isASTypeOrSCType
              ? t('Choose your preferred Trading Platform from the following options:')
              : ''
          }
          text={
            isASTypeOrSCType ? (
              <span>
                <strong>{t('Tickmill Trader')}</strong>{' '}
                {t('User-friendly Mobile App for iOS and Android')}
                <br />
                <strong>{t('MT4MT5')}</strong> {t('Desktop and Mobile App')}
              </span>
            ) : (
              <span>
                <strong>{t('Download the MT4/MT5 platform and start trading!')}</strong>
              </span>
            )
          }
          icon={<GettingStartedDownloadPlatformIcon />}
          buttonText={t('Download your Platform')}
          path={'/dashboard/tools/platforms'}
        />
        <Accordion
          title={t('Learning.Tickmill Mobile App')}
          subtitle={t('Learning.Download the Tickmill Mobile App')}
          text={
            !isMobile
              ? t(
                  'Learning.Get the Tickmill Mobile App to deposit and withdraw funds, create trading accounts and keep an eye on your equity and balance at any time from your phone.'
                )
              : undefined
          }
          icon={
            isMobile ? (
              <div className={styles.appLinkBlock}>
                <AppMarketLink justIcons />
                {isTickmillUKType(entity) && (
                  <TextTinyStrong>
                    * {t('Only available for services related to CFD')}
                  </TextTinyStrong>
                )}
              </div>
            ) : (
              <div className={styles.QRCodeBlock}>
                <AppQRCode />
              </div>
            )
          }
        />
      </div>
    </div>
  )
}

export interface AccordionProps {
  title: string
  subtitle: string
  text?: string | React.ReactNode
  path?: string
  icon: React.ReactNode
  buttonText?: string
}

const Accordion: React.FC<AccordionProps> = (props) => {
  const { title, subtitle, path, icon, buttonText, text } = props

  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className={styles.accordion}>
      <div className={styles.titleBox} onClick={() => setIsOpen(!isOpen)}>
        <TextLargeStrong>{title}</TextLargeStrong>
        <div
          className={classNames(styles.chevron, {
            [styles.chevronUp]: isOpen,
            [styles.chevronDown]: !isOpen,
          })}
        >
          <ChevronDownIcon size={24} />
        </div>
      </div>
      <div
        className={classNames(styles.content, {
          [styles.open]: isOpen,
        })}
      >
        <div className={styles.innerContent}>
          <div className={styles.leftSection}>
            {subtitle && <TextStrong>{subtitle}</TextStrong>}
            {text && <Text>{text}</Text>}
            {buttonText && path && (
              <Link to={path}>
                <Button className={styles.button} appearance='primary' size='M'>
                  {buttonText}
                </Button>
              </Link>
            )}
          </div>
          <div className={styles.rigthSection}>{icon}</div>
        </div>
      </div>
    </div>
  )
}
