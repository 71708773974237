import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SelectModal, SelectModalOption } from '../../global/field/SelectModal'
import { Modal } from '../../global/modal/Modal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { Subscription, SubscriptionDetail } from '../../model/SubscriptionTypes'

import styles from './MarketDataPage.module.scss'

interface Props {
  handleOnSelectSubscriptionDetail(subscriptionDetail?: SubscriptionDetail): void
  closeModal(): void
  selectedSubscriptionDetail?: SubscriptionDetail
  subscription: Subscription
  isSelectEnabled?: boolean
}
export const SubscriptionDetailModal: React.FC<Props> = ({
  handleOnSelectSubscriptionDetail,
  closeModal,
  selectedSubscriptionDetail,
  subscription,
  isSelectEnabled,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const [subscriptionDetail, setSubscriptionDetail] = useState<SubscriptionDetail | undefined>(
    selectedSubscriptionDetail
  )

  return (
    <Modal
      closeModal={() => closeModal()}
      render={({ closeModal }) => (
        <SelectModal
          onCancel={closeModal}
          onConfirm={() => handleOnSelectSubscriptionDetail(subscriptionDetail)}
          title={t('Market Data.Choose Level')}
          renderOptions={() => (
            <div className='control'>
              {subscription.details.map((detail) => (
                <SelectModalOption
                  label={
                    <>
                      <span>{detail.name} - </span>
                      <span className={styles.textSmallStrong}>
                        {formatMoney(detail.amount)}/{t('Market Data.month')}
                      </span>
                    </>
                  }
                  hint={detail.description}
                  value={subscriptionDetail === detail}
                  disabled={!isSelectEnabled && detail.id !== subscriptionDetail?.id}
                  onClick={() => {
                    if (isSelectEnabled) {
                      setSubscriptionDetail(detail)
                    }
                  }}
                  key={detail.id}
                />
              ))}
            </div>
          )}
        />
      )}
    />
  )
}
