/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const PathHandler = ({ setCurrentPath }: { setCurrentPath: (path: string) => void }) => {
  const location = useLocation()
  useEffect(() => {
    setCurrentPath(location.pathname)
  }, [location])

  return null
}
