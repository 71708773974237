import { iconColor } from './IconProps'
import { Color } from './index'

import styles from './Icons.module.scss'

export function CartIcon(props: { size?: number; color?: Color }) {
  const size = props.size || 88
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 88 88'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width={size} height={size} rx='44' fill='#ECECEC' />
      <path
        d='M25.9746 30.2266H30.8106C30.906 30.2266 30.9881 30.2939 31.0068 30.3873L31.9746 35.2266M31.9746 35.2266L33.9468 47.0594C33.9628 47.1559 34.0463 47.2266 34.144 47.2266H37.9746H54.8305C54.9165 47.2266 54.993 47.1715 55.0202 47.0898L58.7552 35.8847C58.8632 35.5609 58.6222 35.2266 58.2809 35.2266H31.9746Z'
        stroke={props.color ? styles[props.color] : iconColor(props)}
        strokeWidth='3'
        strokeLinecap='round'
      />
      <path
        d='M41.4746 54.2266C41.4746 56.1596 39.9076 57.7266 37.9746 57.7266C36.0416 57.7266 34.4746 56.1596 34.4746 54.2266C34.4746 52.2936 36.0416 50.7266 37.9746 50.7266C39.9076 50.7266 41.4746 52.2936 41.4746 54.2266Z'
        stroke={props.color ? styles[props.color] : iconColor(props)}
        strokeWidth='3'
      />
      <path
        d='M55.4746 54.2266C55.4746 56.1596 53.9076 57.7266 51.9746 57.7266C50.0416 57.7266 48.4746 56.1596 48.4746 54.2266C48.4746 52.2936 50.0416 50.7266 51.9746 50.7266C53.9076 50.7266 55.4746 52.2936 55.4746 54.2266Z'
        stroke={props.color ? styles[props.color] : iconColor(props)}
        strokeWidth='3'
      />
    </svg>
  )
}
