import React from 'react'
import classNames from 'classnames'

import styles from './Icons.module.scss'

export const ManageFundsIcon1 = ({ className }: { className?: string }) => (
  <svg
    width='176'
    className={classNames(className)}
    height='156'
    viewBox='0 0 176 156'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M10.7956 84.4499C10.2848 84.9143 10.2471 85.7049 10.7115 86.2157C11.1758 86.7266 11.9664 86.7642 12.4772 86.2999L10.7956 84.4499ZM166.768 7.80867L168.001 8.01415C168.061 7.65169 167.959 7.28102 167.722 7.00065C167.484 6.72029 167.135 6.55864 166.768 6.55867L166.768 7.80867ZM137.681 6.56128C136.991 6.56135 136.431 7.12104 136.431 7.81139C136.431 8.50175 136.991 9.06134 137.681 9.06128L137.681 6.56128ZM160.687 36.6918C160.574 37.3727 161.034 38.0167 161.715 38.1302C162.396 38.2437 163.04 37.7837 163.153 37.1027L160.687 36.6918ZM112.932 51.2928L112.573 52.4901L112.932 51.2928ZM113.853 51.1089L113.061 50.1415L113.853 51.1089ZM65.5042 37.0644L65.8634 35.8672L65.5042 37.0644ZM64.5442 37.2823L63.7034 36.3573L64.5442 37.2823ZM166.768 6.55867L137.681 6.56128L137.681 9.06128L166.768 9.05867L166.768 6.55867ZM165.535 7.6032L160.687 36.6918L163.153 37.1027L168.001 8.01415L165.535 7.6032ZM65.145 38.2617L112.573 52.4901L113.291 50.0955L65.8634 35.8672L65.145 38.2617ZM114.644 52.0763L167.56 8.77607L165.976 6.84128L113.061 50.1415L114.644 52.0763ZM12.4772 86.2999L65.385 38.2073L63.7034 36.3573L10.7956 84.4499L12.4772 86.2999ZM112.573 52.4901C113.289 52.705 114.066 52.5499 114.644 52.0763L113.061 50.1415C113.125 50.0889 113.212 50.0717 113.291 50.0955L112.573 52.4901ZM65.8634 35.8672C65.1072 35.6403 64.2876 35.8263 63.7034 36.3573L65.385 38.2073C65.3201 38.2663 65.229 38.2869 65.145 38.2617L65.8634 35.8672Z'
      fill={styles.primary}
    />
    <path
      d='M113.439 86.5904V154.461M16.4817 154.461V115.678M64.9604 154.461V76.8946M161.918 154.461V76.8946M90.6644 103.294V154.461M40.6393 154.461V97.8226M137.563 125.962V154.461'
      stroke={styles.text}
      strokeWidth='2.5'
      strokeLinecap='round'
    />
  </svg>
)
