import React from 'react'
import classNames from 'classnames'

import { InfoIcon } from '../../icons/InfoIcon'

interface InfoIconWrapperProps {
  className?: string
  onClick: () => void
}

export const InfoIconWrapper: React.FC<InfoIconWrapperProps> = ({ onClick, className }) => {
  return (
    <div onClick={onClick} className={classNames('is-flex is-clickable', className)}>
      <InfoIcon />
    </div>
  )
}
