import React from 'react'
import { useTranslation } from 'react-i18next'

import { SuccessModal, SuccessModalItem } from '../../../global/modal/SuccessModal'
import { isWallet } from '../../../model/WalletDto'
import { PaymentAgentTransferFormValues } from './PaymentAgentTransferForm/PaymentAgentTransferForm'

interface PaymentAgentSuccessModalProps {
  data: PaymentAgentTransferFormValues

  onConfirm(): void
}

export const PaymentAgentSuccessModal: React.FC<PaymentAgentSuccessModalProps> = ({
  data,
  onConfirm,
}) => {
  const { t } = useTranslation()

  return (
    <SuccessModal
      title={t('Funds transferred')}
      body={[
        isWallet(data.clientWallet) && (
          <SuccessModalItem title={t('From')} value={`${data.clientWallet.currency.id} Wallet`} />
        ),
        isWallet(data.clientWallet) && (
          <SuccessModalItem
            title={t('Amount')}
            value={`${data.amount} ${data.clientWallet.currency.id}`}
          />
        ),
        isWallet(data.clientWallet) && (
          <SuccessModalItem title={t('To')} value={data.clientWallet.name} />
        ),
      ]}
      onConfirm={onConfirm}
    />
  )
}
