import React, { Fragment, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useFormatNumber } from '../../hooks/useFormatNumber'
import { DealDto } from '../../model/DealDto'
import { AuthSessionContext } from '../../utils/AuthContext'
import { getCompanyName } from '../../utils/companyName.utils'
import { formatDate } from '../../utils/date.utils'
import { getScrollToCardId } from '../../utils/getItemId'
import { addSwapFreePostfix } from '../../utils/text'
import { SearchResponseProps } from './IBIncomePage'
import { NoData } from './IBIncomeTable'

import styles from './IBIncomeCard.module.scss'

interface IBIncomeCardsProps {
  data: DealDto[]
  search: SearchResponseProps
}

export const IBIncomeCards: React.FC<IBIncomeCardsProps> = ({ search, data }) => {
  if (data.length <= 0) {
    return <NoData search={search} />
  }

  return (
    <React.Fragment>
      {data.map((x, index) => (
        <IBIncomeCard
          key={x.id}
          data={x}
          cardId={getScrollToCardId(index, data.length, 'ib-income')}
        />
      ))}
    </React.Fragment>
  )
}

interface IBIncomeCardProps {
  data: DealDto
  cardId: string
}

const IBIncomeCard: React.FC<IBIncomeCardProps> = ({ data, cardId }) => {
  const { t } = useTranslation()
  const [expanded, setExpanded] = useState(false)
  const [auth] = useContext(AuthSessionContext)
  const dateFormat = auth?.dateFormatType?.name
  const { formatNumber } = useFormatNumber()

  return (
    <div onClick={() => setExpanded((prevState) => !prevState)} className={styles.card} id={cardId}>
      <div className={styles.title}>
        <span className={styles.link}>{data.client.name}</span>
        <span>{addSwapFreePostfix(data.isSwapFree, data.tradingAccountType.name, t)}</span>
      </div>
      <div className={styles.subtitle}>
        <span>
          {t('IB.Volume')}: {formatNumber(data.volume)}
        </span>
        <span>
          {t('IB.Commission')}: {formatNumber(data.amount)}
        </span>
      </div>
      {expanded && (
        <Fragment>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('IB.Date & Time')}</span>
            <span>{formatDate(data.tradedAt, { formatType: dateFormat })}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('Trading Account.Trading Account')}</span>
            <span>{data.tradingAccount.name}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('IB.Currency')}</span>
            <span>{data.currency.id}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('IB.Instrument')}</span>
            <span>{data.instrument.name}</span>
          </div>
          <div className={styles.subtitle}>
            <span className={styles.secondary}>{t('IB.Entity')}</span>
            <span>{data.tickmillCompany ? getCompanyName(data.tickmillCompany?.id) : null}</span>
          </div>
        </Fragment>
      )}
    </div>
  )
}
