import React from 'react'
import { useTranslation } from 'react-i18next'

import { Badge } from '../../global/badge/Badge'
import {
  DocumentStatus,
  isDocumentStatusApproved,
  isDocumentStatusExpired,
  isDocumentStatusPendingApproval,
} from '../../model/DocumentStatus'
import { NameDto } from '../../model/NameDto'

interface DocumentBadgeStatusProps extends NameDto {
  type?: DocumentStatus
}

export const DocumentBadgeStatus: React.FC<DocumentBadgeStatusProps> = (props) => {
  const { id, name, type = DocumentStatus.None } = props
  const statusType = id | type

  const { t } = useTranslation()

  if (isDocumentStatusExpired(statusType)) {
    return <Badge text={name} appearance='danger' />
  }

  if (isDocumentStatusPendingApproval(statusType)) {
    return <Badge text={name} appearance='pending' />
  }

  if (isDocumentStatusApproved(statusType)) {
    return <Badge text={name} appearance='successNew' />
  }

  return <Badge text={name} appearance='declined' />
}
