import { IconProps, iconColor } from './IconProps'

export function UploadIcon(props: IconProps): JSX.Element {
  const size = props.size || 16

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g id='upload'>
        <path
          id='Line 13'
          d='M8.00008 13.3333V5.99995M8.00008 5.99995L5.66675 8.33328M8.00008 5.99995L10.3334 8.33328M2.66675 2.66662L13.3334 2.66662'
          stroke={iconColor(props)}
          stroke-width='1.5'
          stroke-linecap='round'
        />
      </g>
    </svg>
  )
}
