export enum TransactionStatus {
  None = 0,
  AwaitingApproval = 50,
  Pending = 100,
  Completed = 200,
  Cancelled = 300,
  Declined = 400,
  Failed = 500,
  Reversed = 600,
}

export const isTransactionStatusSuccessful = (value: number): boolean => {
  return (
    isTransactionStatusAwaitingApproval(value) ||
    isTransactionStatusPending(value) ||
    isTransactionStatusCompleted(value)
  )
}

export const isTransactionStatusUnSuccessful = (value: number): boolean => {
  return (
    isTransactionStatusCanceled(value) ||
    isTransactionStatusDeclined(value) ||
    isTransactionStatusFailed(value)
  )
}

export const isTransactionStatusAwaitingApproval = (value: number): boolean => {
  return value === TransactionStatus.AwaitingApproval
}

export const isTransactionStatusPending = (value: number): boolean => {
  return value === TransactionStatus.Pending
}

export const isTransactionStatusCompleted = (value: number): boolean => {
  return value === TransactionStatus.Completed
}

export const isTransactionStatusCanceled = (value: number): boolean => {
  return value === TransactionStatus.Cancelled
}

export const isTransactionStatusDeclined = (value: number): boolean => {
  return value === TransactionStatus.Declined
}

export const isTransactionStatusFailed = (value: number): boolean => {
  return value === TransactionStatus.Failed
}
