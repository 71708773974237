export enum CampaignType {
  ClientRebate = 0,
  ClientContest = 1,
  IbContest = 2,
  LuckyDraw = 3,
  LiveRebateAccountType = 4,
}

export const isCampaignClientRebateType = (campaignType: CampaignType | undefined) => {
  return CampaignType.ClientRebate === campaignType
}

export const isCampaignClientContestType = (campaignType: CampaignType | undefined) => {
  return CampaignType.ClientContest === campaignType
}

export const isCampaignLiveRebateAccountType = (campaignType: CampaignType | undefined) => {
  return CampaignType.LiveRebateAccountType === campaignType
}
