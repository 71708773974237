import { NameDto } from './NameDto'

export interface ClientGuideDto {
  id: string
  guideType: NameDto
  dismissed: boolean
  viewsCount: number
  shouldShow: boolean
  tickmillProduct: NameDto
}

export enum GuideTypes {
  IntroductionGuide = 1,
}
