import { IconProps, iconColor } from './IconProps'

export function ForwardIcon(props: IconProps) {
  const size = props.size || 16

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 7H12.2L7.3 2.1C6.9134 1.7134 6.9134 1.0866 7.3 0.7C7.6866 0.313401 8.3134 0.313401 8.7 0.7L16 8L8.7 15.3C8.3134 15.6866 7.6866 15.6866 7.3 15.3C6.9134 14.9134 6.9134 14.2866 7.3 13.9L12.2 9H0V7Z'
        fill={iconColor(props)}
      />
    </svg>
  )
}
