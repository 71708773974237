import React, { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useGetDepositCurrencyLimits } from '../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/hooks/useGetDepositCurrencyLimits'
import { useWalletDepositMutate } from '../../../../Traders-Room/Wallets/WalletDeposit/WalletDepositPage/WalletDepositPage'
import { Loading } from '../../../../global/Loading/Loading'
import { WalletSetupIndicator } from '../../../../global/WalletSetupLoader/WalletSetupIndicator'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { ToastContext, errorToast } from '../../../../global/toast/Toast'
import { useFormatNumber } from '../../../../hooks/useFormatNumber'
import { useHasUserAnyWallets } from '../../../../hooks/useHasUserAnyWallets'
import { isAccountActivatedStatus } from '../../../../model/AccountDetailedStatus'
import { WalletDto } from '../../../../model/WalletDto'
import { isAccountSkipCompanyDocuments } from '../../../../utils/AccountAccess/accountCompanyStatuses'
import {
  useAccountReadContext,
  useAccountWriteContext,
} from '../../../../utils/AccountContextContext'
import { CommonPaths } from '../../../../utils/path'
import { SignupFormWrapper } from '../../Components/SignupFormWrapper'
import { SignupSections, SignupStep } from '../../Components/SignupStep'
import { FirstTimeDepositForm } from './Components/FirstTimeDepositForm'
import { useDepositSubmit } from './hooks/useDepositSubmit'

import styles from './FirstTimeDepositPageSignup.module.scss'

export const FirstTimeDepositPageSignup: React.FC = () => {
  const setToast = useContext(ToastContext)
  const { t } = useTranslation()
  const { refetch, setHasUserAnyWallets } = useHasUserAnyWallets({ initiate: false })
  const [isWalletInitialized, setWalletInitialized] = useState(false)
  const [isWalletSetupComplete, setWalletSetupComplete] = useState(false)
  const { refreshAccount } = useAccountWriteContext()
  const locale = useSessionLanguage()
  const { account } = useAccountReadContext()
  const navigate = useNavigate()
  const [wallets, setWallets] = useState<WalletDto[]>([])
  const [walletsFetching, setWalletsFetching] = useState(false)

  const [isNextClicked, setIsNextClicked] = useState(false)

  const isButtonDisabled = useMemo(
    () => !isWalletInitialized || walletsFetching,
    [isWalletInitialized, walletsFetching]
  )

  const handleAccountStatus = () => {
    if (isAccountActivatedStatus(account)) {
      navigate(CommonPaths.FirstTimeDepositDashboard)
    } else {
      setWalletSetupComplete(true)
    }
  }

  useEffect(() => {
    if (isWalletInitialized && isNextClicked) {
      handleAccountStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, isNextClicked, isWalletInitialized])

  useEffect(() => {
    const timer = setTimeout(() => setWalletInitialized(true), 2000)

    return () => clearTimeout(timer)
  }, [])

  const handleClickNext = async () => {
    try {
      setWalletsFetching(true)
      await refreshAccount(locale)
      const fetchedWallets = await refetch()
      setHasUserAnyWallets(fetchedWallets.length > 0)

      if (!fetchedWallets.length && isWalletInitialized) {
        setToast(errorToast(t('errors.Something went wrong')))
      } else {
        setWallets(fetchedWallets)
        setIsNextClicked(true)
      }
    } finally {
      setWalletsFetching(false)
    }
  }

  const renderLoadingState = () => (
    <Loading
      wrapperClass={styles.loadingWrapper}
      disableIconAnimation={isWalletInitialized}
      showLoadingIcon
      renderText={() => (
        <WalletSetupIndicator
          onSetupComplete={handleClickNext}
          isButtonDisabled={isButtonDisabled}
        />
      )}
      isLoading={!isWalletSetupComplete}
    />
  )

  return isWalletSetupComplete ? <DepositForm wallets={wallets} /> : renderLoadingState()
}

const DepositForm: FC<{ wallets: WalletDto[] }> = ({ wallets }) => {
  const { t } = useTranslation()
  const { getDepositCurrencyLimits } = useGetDepositCurrencyLimits()

  const { account } = useAccountReadContext()
  const { refreshAccount } = useAccountWriteContext()
  const locale = useSessionLanguage()
  const { formatMoney } = useFormatNumber()

  const { handleDepositSubmit } = useDepositSubmit()

  const nextPath = isAccountSkipCompanyDocuments(account)
    ? '/sign-up/confirm'
    : '/sign-up/documents-info/' + account?.id

  const walletDepositMutate = useWalletDepositMutate({
    onSubmit: (data, values) => {
      handleDepositSubmit(data, values)
      refreshAccount(locale)
    },
  })

  return (
    <SignupFormWrapper label={t('Sign up.Sign up')}>
      <SignupStep section={SignupSections.FirstTimeDeposit} />
      <section className={styles.firstTimeDepositPage}>
        <FirstTimeDepositForm
          wallets={wallets ?? []}
          onSubmit={walletDepositMutate.mutate}
          formatMoney={formatMoney}
          skipButtonLink={nextPath}
          getDepositCurrencyLimits={getDepositCurrencyLimits}
        />
      </section>
    </SignupFormWrapper>
  )
}
