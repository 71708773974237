import React from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'

import { Button } from '../../../global/button/Button'
import { useSnackbar } from '../../../global/context/SnackbarContext'
import IconButton from '../../../global/iconButton/IconButton'
import { AlertFilledIcon } from '../../../icons/AlertFilledIcon'
import { CloseIcon } from '../../../icons/CloseIcon'
import { GoodIcon } from '../../../icons/GoodIcon'
import { InfoIcon } from '../../../icons/InfoIcon'
import { WarningIcon } from '../../../icons/WarningIcon'
import { useWindowResize } from '../../../utils/domUtils'
import { Text } from '../../Typography/Typography'

import styles from './Snackbar.module.scss'

export type SnackbarType = 'success' | 'info' | 'warning' | 'error'

export interface SnackbarData {
  message: string
  type?: SnackbarType
  id: string
  noTimeout?: boolean
  closable?: boolean
  action?: {
    label: string
    onClick: () => void
  }
}

const Snackbar: React.FC<SnackbarData> = (props) => {
  const { id, type, message, closable, action } = props
  const { removeSnackbar } = useSnackbar()
  const isMobile = useWindowResize()

  const snackbarVariants = {
    hidden: { opacity: 0, y: isMobile ? -20 : 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: isMobile ? -20 : 20 },
  }

  const SnackbarIcon = () => {
    switch (type) {
      case 'success':
        return <GoodIcon size={20} />
      case 'info':
        return <InfoIcon size={20} />
      case 'warning':
        return <WarningIcon color='warning' size={20} />
      case 'error':
        return <AlertFilledIcon size={24} />
      default:
        return null
    }
  }

  return (
    <motion.div
      id={id}
      className={classNames(styles.snackbar, type)}
      variants={snackbarVariants}
      initial='hidden'
      animate='visible'
      exit='exit'
    >
      <div className={styles.leftWrapper}>
        <SnackbarIcon /> <Text>{message}</Text>
        {action && (
          <Button appearance='plain' onClick={action.onClick}>
            <Text>{action.label}</Text>
          </Button>
        )}
      </div>
      {closable && (
        <IconButton onClick={() => removeSnackbar(id)}>
          <CloseIcon color='textInverse' />
        </IconButton>
      )}
    </motion.div>
  )
}

export const SnackbarContainer = (): JSX.Element => {
  const { snackbars } = useSnackbar()

  const containerVariants = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.5,
      },
    },
  }

  return (
    <motion.div
      className={styles.snackbarContainer}
      variants={containerVariants}
      animate='show'
      initial='hidden'
    >
      <AnimatePresence>
        {snackbars.map((snackbar) => (
          <Snackbar key={snackbar.id} {...snackbar} />
        ))}
      </AnimatePresence>
    </motion.div>
  )
}
