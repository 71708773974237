import { ReactNode, createContext, useEffect, useMemo, useState } from 'react'

import { LossPercentagesDto } from '../../model/LossPercentagesDto'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../utils/companyName.utils'
import { getLossPercentagesForCompany } from '../../utils/lossPercentages.utils'
import { useGuardedContext } from '../useGuardedContext'
import { useSessionEntity } from './EntityContext'

interface LossPercentageReadContextProps {
  percentages: LossPercentagesDto | undefined
}

interface RefreshLossPercentagesParams {
  lossPercentages?: LossPercentagesDto[]
  entity?: TickmillCompaniesEnum
}

interface LossPercentagesWriteContextProps {
  refreshLossPercentages: (params?: RefreshLossPercentagesParams) => void
}

const LossPercentagesReadContext = createContext<LossPercentageReadContextProps | undefined>(
  undefined
)

LossPercentagesReadContext.displayName = 'LossPercentagesReadContext'

const LossPercentagesWriteContext = createContext<LossPercentagesWriteContextProps | undefined>(
  undefined
)

export function useLossPercentagesReadContext(): LossPercentageReadContextProps {
  return useGuardedContext(LossPercentagesReadContext)
}

export function useLossPercentagesWriteContext() {
  return useGuardedContext(LossPercentagesWriteContext)
}

interface Props {
  percentages?: LossPercentagesDto
  children: ReactNode
}

export function LossPercentagesContextProvider({ children }: Props) {
  const entity = useSessionEntity()
  const [lossPercentage, setLossPercentage] = useState<LossPercentagesDto | undefined>(undefined)

  const apiClient = useApiClient(ClientApiClient)
  useEffect(() => {
    if (!lossPercentage) {
      apiClient.getLossPercentages().then((response) => {
        setLossPercentage(getLossPercentagesForCompany(response.items, entity))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, lossPercentage]) // reset

  const writeContext = useMemo<LossPercentagesWriteContextProps>(() => {
    return {
      async refreshLossPercentages(params?: RefreshLossPercentagesParams) {
        const { lossPercentages } = params || {}
        const _entity = params?.entity || entity

        if (!lossPercentages) {
          const response = await apiClient.getLossPercentages()
          setLossPercentage(getLossPercentagesForCompany(response.items, _entity))
        } else {
          const risk = getLossPercentagesForCompany(lossPercentages, _entity)
          setLossPercentage(risk)
        }
      },
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  const readContext = useMemo<LossPercentageReadContextProps>(() => {
    return { percentages: lossPercentage }
  }, [lossPercentage])

  return (
    <LossPercentagesReadContext.Provider value={readContext}>
      <LossPercentagesWriteContext.Provider value={writeContext}>
        {children}
      </LossPercentagesWriteContext.Provider>
    </LossPercentagesReadContext.Provider>
  )
}
