import { useCallback } from 'react'

import { useIBRoutesConditions } from '../IntroducingBroker/IntroducingBrokerRoutes'
import { useTraderRoomRoutesConditions } from '../Traders-Room/TradersRoomRoutes'
import { useProductReadContext } from '../global/context/ProductContext'
import { CFDLandingPageID, ETDLandingPageID, LandingPageMapping } from '../model/LandingPageDto'
import { TickmillProductType } from '../model/TickmillProductType'
import { isKYCUpdateDue } from './AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from './AccountContextContext'
import { useFirstDepositContext } from './FirstDepositContext'
import { isTickmillPartner } from './companyName.utils'

export const useLandingPage = (): {
  getLandingPage: (overWriteProduct?: TickmillProductType) => string
} => {
  const { account } = useAccountReadContext()
  const { hasFirstDeposit } = useFirstDepositContext()
  const { isTradersRoomVisible } = useTraderRoomRoutesConditions()
  const { isIBVisible } = useIBRoutesConditions()
  const { product } = useProductReadContext()

  const getLandingPage = useCallback(
    (overWriteProduct?: TickmillProductType) => {
      const tMProduct = overWriteProduct ?? product
      const FIRST_DEPOSIT_PAGE = '/dashboard/first-deposit'
      const KYC_UPDATE_PAGE = '/profile/kyc-update-details'
      const DEFAULT_ETD_PAGE = '/dashboard/traders-room/balances'

      const DEFAULT_CFD_PAGE = '/dashboard/traders-room/wallets'
      const DEFAULT_CFD_IB_PAGE = '/dashboard/introducing-broker/wallets'

      const hasDismissedFirstDepositPrompt = localStorage.getItem('hasDismissedFirstDepositPrompt')

      if (isKYCUpdateDue(account)) {
        return KYC_UPDATE_PAGE
      }

      if (
        account &&
        !hasFirstDeposit &&
        !hasDismissedFirstDepositPrompt &&
        tMProduct === TickmillProductType.CFD &&
        !isTickmillPartner(account)
      ) {
        return FIRST_DEPOSIT_PAGE
      }

      if (
        !account?.preferredLandingPage ||
        account?.preferredLandingPage.tickmillProductId !== tMProduct
      ) {
        if (tMProduct === TickmillProductType.CFD) {
          if (isTradersRoomVisible) {
            return DEFAULT_CFD_PAGE
          } else {
            if (isIBVisible) {
              return DEFAULT_CFD_IB_PAGE
            } else {
              return 'profile/document-management'
            }
          }
        }
        return DEFAULT_ETD_PAGE
      }

      const preferredLandingPage: ETDLandingPageID | CFDLandingPageID =
        account.preferredLandingPage.id

      return LandingPageMapping[preferredLandingPage]
    },
    [account, hasFirstDeposit, isIBVisible, isTradersRoomVisible, product]
  )

  return {
    getLandingPage,
  }
}
