import { IconProps } from './IconProps'

export function DummyIcon(props: IconProps) {
  const size = props.size || 24

  return (
    <svg
      {...props}
      width={size}
      height={size}
      viewBox='0 0 6 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    ></svg>
  )
}
