export enum ClientNciHistoryStatus {
  INACTIVE = 1,
  PENDING = 2,
  ACTIVE = 3,
}

export interface ClientCreateNciHistory {
  nciPriorityId: string
  statusId: number
  reason: string | null
}

export interface ClientCreateNciFinalHistory {
  statusId: number
  nciPriorityId: string
  documentTypeId: number
  nciDocumentNumber: string | null
  countryId: string | null
}
