import styles from './Icons.module.scss'

export function GettingStartedDownloadPlatformIcon(): JSX.Element {
  return (
    <svg
      width='592'
      height='344'
      viewBox='0 0 592 344'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <rect width='592' height='344' rx='16' fill={styles.background} />
      <path
        d='M355.123 185.609V209.019H365.494L357.472 219.424L349.427 229.829L341.381 219.424L333.359 209.019H343.707V185.609'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M356.759 163.05V138.906C356.759 131.079 350.398 124.765 342.566 124.765H285.108L265.551 103.861H223.876C216.233 103.861 210.039 110.01 210.039 117.624V206.064C210.039 215.002 217.301 222.214 226.249 222.214H306.42'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M349.425 240.163C367.409 240.163 381.988 225.637 381.988 207.719C381.988 189.801 367.409 175.275 349.425 175.275C331.442 175.275 316.863 189.801 316.863 207.719C316.863 225.637 331.442 240.163 349.425 240.163Z'
        stroke={styles.text}
        strokeWidth='3'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M255.773 164.918C255.773 164.209 256.271 163.357 256.888 163.003L269.016 155.979C269.633 155.625 270.606 155.625 271.223 155.979L281.808 162.104L296.736 153.52C296.736 153.52 296.523 153.355 296.404 153.284L272.813 139.45C271.318 138.575 268.897 138.575 267.426 139.45L243.835 153.284C242.34 154.159 241.105 156.334 241.105 158.108V185.467L255.773 177.025V164.894V164.918Z'
        fill={styles.primary}
      />
      <path
        d='M284.444 166.81V178.941C284.444 179.65 283.946 180.502 283.329 180.856L271.201 187.879C270.584 188.234 269.611 188.234 268.994 187.879L258.409 181.755L243.48 190.339C243.48 190.339 243.694 190.504 243.813 190.575L267.404 204.409C268.899 205.284 271.32 205.284 272.791 204.409L296.382 190.575C297.877 189.7 299.111 187.525 299.111 185.751V158.392L284.444 166.834V166.81Z'
        fill={styles.primary}
      />
    </svg>
  )
}
