import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Button } from '../../../global/button/Button'
import { ClientPhoneNumberDto } from '../../../model/AccountDetailedDto'
import { TextLargeStrong, TextStrong } from '../../../ui/Typography/Typography'

import styles from './PhoneNumberModal.module.scss'

interface PhoneNumberAddModalProps {
  onCancel: () => void
  onConfirm: () => void
  phoneNumber: ClientPhoneNumberDto
}

export const PhoneNumberDeleteModal: React.FC<PhoneNumberAddModalProps> = ({
  onCancel,
  onConfirm,
  phoneNumber,
}) => {
  const { t } = useTranslation()
  return (
    <div className={styles.deleteModal}>
      <header className={classNames('modal-card-head', styles.header)}>
        <TextLargeStrong isParagraph className={classNames('modal-card-title', styles.title)}>
          {t('Delete')} {phoneNumber.countryCode + phoneNumber.number}
        </TextLargeStrong>
      </header>
      <p className={styles.body}>{t('PhoneNumbers.Do you really want to delete this number?')}</p>
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <Button className={styles.delete} appearance='plain' onClick={onConfirm}>
          {t('Delete')}
        </Button>
        <Button appearance='plain' onClick={onCancel}>
          <TextStrong className={styles.cancel}>{t('Cancel')}</TextStrong>
        </Button>
      </footer>
    </div>
  )
}
