import { differenceInMinutes } from 'date-fns'

import { useProductReadContext } from '../../global/context/ProductContext'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { isAccountActivatedStatus } from '../../model/AccountDetailedStatus'
import { TickmillProductType } from '../../model/TickmillProductType'
import { OnboardingFlowTypeEnum } from '../../pages/Signup/Components/SignupStep'
import { useAccountReadContext } from '../AccountContextContext'
import { hasProductDormantStatus } from './accountProductStatuses'

export const useAccountKYCStatus = () => {
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()

  const isKYCUpdateNotAllowed = isAccountKycUpdateDetailsNotAllowed(account, product)
  const isKYCUpdateAllowed = isAccountKycUpdateDetailsAllowed(account, product)
  const canUpdateKYCOrDormant = allowKYCUpdate(account)
  const kYCUpdateDue = isKYCUpdateDue(account)
  const requiresDepositBeforeKYC = isDepositBeforeKYC(account)
  const mustDepositBeforeKYC = requireDepositBeforeKYC(account)

  return {
    isKYCUpdateNotAllowed,
    isKYCUpdateAllowed,
    canUpdateKYCOrDormant,
    isKYCUpdateDue: kYCUpdateDue,
    requiresDepositBeforeKYC,
    mustDepositBeforeKYC,
  }
}

export const isAccountKycUpdateDetailsNotAllowed = (
  accountDetailed: AccountDetailedDto | undefined,
  product: TickmillProductType | undefined
): boolean => {
  return !isAccountKycUpdateDetailsAllowed(accountDetailed, product, true)
}

export const isAccountKycUpdateDetailsAllowed = (
  accountDetailed: AccountDetailedDto | undefined,
  product: TickmillProductType | undefined,
  ignoreDateDifferenceInMinutes = false
): boolean => {
  if (!accountDetailed) {
    return false
  }

  if (ignoreDateDifferenceInMinutes) {
    return allowKYCUpdate(accountDetailed)
  }

  const dateDifference = differenceInMinutes(
    new Date(),
    new Date(accountDetailed.detailsUpdateAvailableUntil ?? '')
  )

  const canUpdateKYC = accountDetailed.isKYCUpdateNeeded && dateDifference >= 0
  const canUpdateDormant =
    accountDetailed.isDormantUpdateNeeded &&
    dateDifference >= 0 &&
    !hasProductDormantStatus(accountDetailed, product)

  return canUpdateKYC || canUpdateDormant
}

export const allowKYCUpdate = (account: AccountDetailedDto | undefined): boolean => {
  if (!account) {
    return false
  }
  return !!account.isKYCUpdateNeeded || !!account.isDormantUpdateNeeded
}

export const isKYCUpdateDue = (account: AccountDetailedDto | undefined): boolean => {
  if (!account || !allowKYCUpdate(account)) {
    return false
  }

  const currentDate = new Date()
  const kycUpdateDate = new Date(account.kycUpdateAvailableUntil ?? currentDate)
  const detailsUpdateDate = new Date(account.detailsUpdateAvailableUntil ?? currentDate)

  return kycUpdateDate < currentDate || detailsUpdateDate < currentDate
}

export const isDepositBeforeKYC = (account: AccountDetailedDto | undefined): boolean => {
  return (
    account?.visibilityConfiguration.onboardingFlowType.id ===
    OnboardingFlowTypeEnum.DepositBeforeKYC
  )
}
export const isDepositPlaced = (account: AccountDetailedDto | undefined) =>
  account?.visibilityConfiguration.hasPlacedDeposit ?? false

export const requireDepositBeforeKYC = (account: AccountDetailedDto | undefined): boolean => {
  return (
    !isAccountActivatedStatus(account) && isDepositBeforeKYC(account) && !isDepositPlaced(account)
  )
}
